import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import wrapperComponent from '../../Templates/wrapperComponent';
import './_NewHome.scss';
import { PREV_ICON, NEXT_ICON, TWITTER_ICON, COLLECTOR_PL, INSTAGRAM_ICON, FACEBOOK_ICON, SNAPCHAT_ICON, TIKTOK_ICON, LINK_ICON, LOGO, OOD_LOGO, USER_DROP, COLLECTOR_PRODUCT, QUOTE_START, QUOTE_END, WHITE_ARROW_DOWN, RIGHT_ARROW_ICON,MUSIC_IMG2,MUSIC_IMG3,MUSIC_IMG4, NATURE_IMG1, NATURE_IMG2, NATURE_IMG3,HD_Mobiles} from '../../../../constants/image';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../models';
import { EMAIL, MagicNumber, Notify_DROP, Notify_ERROR, USER_ID, getPlatformInfo } from '../../../../constants';
import { errorToast, FullPageLoader, successToast } from '../../Atoms';
import { NotifyModal } from '../../Molecules';
import Cookie from 'js-cookie';
import DropFaqList from '../faq/DropFaqList';
import DropTime from './DropTime';
import { FeedItemVideo } from '../feedListing/feedItem/FeedItemVideo';
import Skeleton from 'react-loading-skeleton';

const DropHome = observer(function DropHome() {
  const base = process.env.REACT_APP_ASSET_HOST;
  const history = useHistory();
  const rootStore = useStores();
  const {
    getHomeDrop,
    getUpcommingDrop,
    getDropFaq,
    homeDrop,
    homeDropProductList,
    homeDropProductListCount,
    homeDropProductListmobile,
    homeDropProductListmobileCount,
    homeUpcommingListCount,
    homeUpcommingDropListmobile,
    homeUpcommingDropList,
    homeDropFaqList,
  } = rootStore.homeStore;
  const { notifyUser } = rootStore.cartStore;
  const [loader, setLoader] = useState(true);
  const [isModalOpen, openModal] = useState<boolean>(false);
  const [isStartDrop, setStartDrop] = useState<boolean>(false);
  const [remainingDate, setRemainingDate] = useState<string>('');
  const [dropData, setDropData] = useState<any>();
  const myRef: any = useRef();


  const checkAvailabitity = async (data: any) => {
    let startOrEndDates = data && data.sale_start_date ? data.sale_start_date : new Date();
    const saleStartDate = new Date(startOrEndDates).getTime();
    const todatedate = new Date().getTime();
    let status = false;
    if (saleStartDate > todatedate) {
      setStartDrop(false);
      status = false;
    } else {
      startOrEndDates = data && data.sale_end_date ? data.sale_end_date : new Date();
      setStartDrop(true);
      status = true;
    }
    handleDropDate(startOrEndDates);
    dateCountDownTimer(data, status);
  };

  const dateCountDownTimer = (data: any, status: boolean) => {
    var upgradeTime: string;
    let flag = MagicNumber.ZERO;
    if (status) {
      upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
    } else {
      upgradeTime = data && data.sale_start_date ? data.sale_start_date : new Date();
    }
    const timer = () => {
      let diffTime = new Date(upgradeTime).valueOf() - new Date().valueOf();
      let days = diffTime / (MagicNumber.TWENTYFR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONE_TH);
      let hours = (days % MagicNumber.ONE) * MagicNumber.TWENTYFR;
      let minutes = (hours % MagicNumber.ONE) * MagicNumber.SIXTY;
      let secs = (minutes % MagicNumber.ONE) * MagicNumber.SIXTY;
      [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
      if (Math.sign(days) === -MagicNumber.ONE) {
        clearInterval(countdownTimer);
      }
    };
    var countdownTimer = setInterval(() => {
      let startOrEndDatess = data && data.sale_start_date ? data.sale_start_date : new Date();
      const saleStartDate = new Date(startOrEndDatess).getTime();
      const todatedate = new Date().getTime();
      if (saleStartDate > todatedate) {
        upgradeTime = startOrEndDatess;
        setStartDrop(false);
      } else {
        upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
        setStartDrop(true);
      }
      timer();
      flag = flag + MagicNumber.ONE;
      handleDropDate(upgradeTime);
    }, MagicNumber.ONE_TH);
  };

  const handleDropDate = (startOrEndDates: any) => {
    let diffTime = new Date(startOrEndDates).valueOf() - new Date().valueOf();
    let days = diffTime / (MagicNumber.TWENTYFR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONE_TH);
    let hours = (days % MagicNumber.ONE) * MagicNumber.TWENTYFR;
    let minutes = (hours % MagicNumber.ONE) * MagicNumber.SIXTY;
    let secs = (minutes % MagicNumber.ONE) * MagicNumber.SIXTY;
    [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
    let day_;
    let hour_;
    let minute_;
    if (days > MagicNumber.ZERO) {
      day_ = Math.abs(days) > MagicNumber.ONE ? `${Math.abs(days)}days` : `${Math.abs(days)}day`;
    }
    if (hours > MagicNumber.ZERO) {
      hour_ = Math.abs(hours) > MagicNumber.ONE ? `${Math.abs(hours)}hrs` : `${Math.abs(hours)}hr`;
    }
    if (minutes > MagicNumber.ZERO) {
      minute_ = Math.abs(minutes) > MagicNumber.ONE ? `${Math.abs(minutes)}mins` : `${Math.abs(minutes)}min`;
    }
    if (minutes === MagicNumber.ZERO && secs > MagicNumber.ZERO) {
      minute_ = `1min`;
    }
     setRemainingDate(`${day_ ? day_ : ''} ${hour_ ? hour_ : ''} ${minute_ ? minute_ : '0'}`);
  };

  const fetchDropById = async () => {
    await getHomeDrop().then(async (res) => {
      setDropData(res);
      await checkAvailabitity(res);
    });
    await getDropFaq();
    getUpcommingDrop();
    setLoader(false);
  };

  useEffect(() => {
    fetchDropById();
  }, []);

  const handleNotifyMe = async () => {
    openModal(true);
  };

  const handleNotifyUser = async (values: any) => {
    if (Cookie.get(USER_ID)) {
      setLoader(true);
      const data = {
        email: Cookie.get(EMAIL),
        entity_id: homeDrop && homeDrop.id,
        entity_type: 'drop',
        user_id: Cookie.get(USER_ID)
      };
      await notifyUser(data).then(res => {
        if (res.data.statusCode === MagicNumber.TWO_H) {
          successToast(Notify_DROP);
          openModal(false);
        }
      }).catch((err) => {
        setLoader(false);
      }).finally(() => {
        setLoader(false);
      });
      setLoader(false);
    } else {
      setLoader(false);
      errorToast(Notify_ERROR);
    }

  };

  const handleDropDetailsPage = (id: number) => {
    // if (!isStartDrop) {
    //   return false;
    // }
    history.push(`drop/${id}`);
  };

  const executeScroll = () => {
    if (myRef) {
      myRef.current.scrollIntoView();
    };
  };

  const copyUrl = () => {
    const url = window.location.origin;
    navigator.clipboard.writeText(url);
    successToast('Link copied to clipboard successfully!');
  };

  const getDropStartDate = (date: string) => {
    const monthAlpha = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = new Date(date).getMonth();
    const dates = new Date(date).getDate();
    return (
      <>
        <h3 className='title'>{monthAlpha[month]}</h3>
        <p className='date'>{('0' + dates).slice(-2)}</p>
      </>
    );
  };

  const daysDiff = (dates: string) => {
    const dateNow = new Date().getTime();
    const startDate = new Date(dates).getTime();
    const diffTime = Math.abs(startDate - dateNow);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <div className='countdown-banner-section'>   
      {(getPlatformInfo() === 'desktop')? <>
      <div className='home-banner-text'>
          <div className='home-banner-title'>Welcome to OOD</div>
          <div className='home-banner-subtitle'>OOD sells exclusive physical art and collectibles featuring authentication for collectors, and royalties for creators</div>
        </div></>:''}
        {(getPlatformInfo() === 'desktop')? <>
        <div className='countdown-image banner1' style={{ backgroundImage: `url(${homeDrop.filePath}?aspect_ratio=16:9)` }}>
        </div>
        </>: <>

<div className='container-image'>
  <div className='fixed-img' style={{ backgroundImage: `url(${homeDrop.filePathApp}?aspect_ratio=16:9)` }}></div>
  <h1 className='banner-text'>Exclusive physical art + collectables</h1>
  <span className='color-box purple'></span>
  <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' />
</div>
<div className='container-image'>
  <div className='fixed-img'></div>
  <h1 className='banner-text pad-text'>Featuring authentication for collectors</h1>
  <span className='color-box blue'></span>
  <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' />
</div>
<div className='container-image'>
  <div className='fixed-img'></div>
  <h1 className='banner-text pad-text'>And royalties for creators
  <br/>
  <br/>
  <span>On auction now</span>
  </h1>
  <span className='color-box yellow'></span>
  <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' />
</div>

        {/* <div className='countdown-image banner1' style={{ backgroundImage: `url(${homeDrop.filePathApp}?aspect_ratio=16:9)` }}>
            <h1 className='banner-text'>Exclusive physical art + collectables</h1>
            <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' />
            <span className='color-box purple'></span>
          </div> */}
          {/* <div className='countdown-image banner2' style={{ backgroundImage: `url(${homeDrop.filePathApp}?aspect_ratio=16:9)` }}>
                <h1 className='banner-text pad-text'>Featuring authentication for collectors</h1>
                <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' />
                <span className='color-box blue'></span>
            </div> */}
            {/* <div className='countdown-image banner3' style={{ backgroundImage: `url(${homeDrop.filePathApp}?aspect_ratio=16:9)` }}>
           <h1 className='banner-text pad-text'>And royalties for creators
           <br/>
           <br/>
              <span>On auction now</span>
            </h1>
            <span className='color-box yellow'></span>
            <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' />
          </div>  */}
          {/* <div className='our-story-section'> */}
        {/* <div className='head-story'>
          <h3 className='title-story'>New Drop</h3>
        </div> */}
      {/* </div>
      {/* {numbers && numbers.length ? numbers.map((card: any, index: number) => {
                      return <div className='countdown-image banner1' style={{ backgroundImage: `url(${MUSIC_IMG2}?aspect_ratio=16:9)` }}>
                      <h1 className='banner-text'>Exclusive physical art + collectables</h1>
                      <span className='color-box purple'></span>
                    </div>
                    }) : ''}
        {numbers && numbers.length ? numbers.map((card: any, index: number) => {
                      return <div className='countdown-image banner2' style={{ backgroundImage: `url(${MUSIC_IMG4}?aspect_ratio=16:9)` }}>
                      <h1 className='banner-text'> Featuring authentication for collectors</h1>
                      <span className='color-box blue'></span>
                    </div>
                    }) : ''}
          {numberstest && numberstest.length ? numberstest.map((card: any, index: number) => {
                      return <div className='countdown-image banner3' style={{ backgroundImage: `url(${MUSIC_IMG3}?aspect_ratio=16:9)` }}>
                        <h1 className='banner-text'>And royalties for creators
           <br/>
           <br/>
              <span>On auction now</span>
            </h1>
            <span className='color-box yellow'></span>
            <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' />
                    </div>
                    }) : ''}                           */}
          </>
          }
        <div className='content-section'>
          <div className='title-timer-section'>
            <div className='start-timer-text'>
              <h1 className='title-text'>{homeDrop.title}</h1>
              {remainingDate && remainingDate != '' ? <div className='button-timer'>
                {remainingDate && (remainingDate).trim() !== '0' ? <button type='button' className='btn primary'>{isStartDrop ? 'Ends in' : 'Starts in'} {remainingDate}</button> :
                  <button type='button' className='btn danger'>Drop Expired</button>}
              </div> : <Skeleton />}
            </div>
            <div className='notify-detail-section'>
              <div className='container-notify'>
                <p className='notify-pg'>{homeDrop.description}</p>
                {!isStartDrop ? <div className='notify-button'>
                  <button type='button'
                    className='btn primary'
                    onClick={handleNotifyMe}
                  >Notify Me</button>
                </div> : ''}
              </div>
            </div>
          </div>
        </div>
        <div className='responsive-title-timer-section'>

          <div className='title-timer-section'>
            <div className='start-timer-text'>
              {/* <div className='button-timer'>
                {remainingDate && (remainingDate).trim() !== '0' ? <button type='button' className='btn primary'>{isStartDrop ? 'Ends in' : 'Starts in'} {remainingDate}</button> :
                  <button type='button' className='btn danger'>Drop Expired</button>}
              </div> */}
              <div className='head-story'>
                <h3 className='title-story'>New drop</h3>
              </div>
              <div className='title-responsive'>
                <h1 className='title-text'>{homeDrop.title}</h1>
              </div>
            </div>
            <div className='notify-detail-section'>
              <div className='container-notify'>
                <p className='notify-pg'>{homeDrop.description}</p>
                {!isStartDrop ? <div className='notify-button'>
                  <button type='button'
                    className='btn primary'
                    onClick={handleNotifyMe}
                  >Notify Me</button>
                </div> : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* double dropping soon */}
      {/* {homeUpcommingListCount && remainingDate && (remainingDate).trim() == '0' ? <div className='product-carousel-section droppingsoon-carousel-section'>
        <div className='product-container'>
          <div className='head-product'>
            <h2 className='pg-title'>Dropping Soon Product</h2>
          </div>
          <div className='product-carousel-container'>
            <div className='product-section'>
              <div className='carousel-section'>
                <div id='destinationcontrols1' className='carousel slide' data-ride='carousel'>
                  <div className='carousel-inner'>
                    {homeUpcommingDropList && homeUpcommingDropList.length ? homeUpcommingDropList.map((card: any, index: number) => {
                      return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                        <div className='card-section'>
                          {card && card.length ? card.map((item: any) => {
                            return <div className='card' key={item.id}>
                              <div className='image-box'>
                                <img src={`${item.filename}?aspect_ratio=320:181`} className='card-image' alt='product' />
                              </div>
                              <div className='dropping-date-time'>
                                <div className='calendar-left'>
                                  <div className='box'>
                                    {getDropStartDate(item.sale_start_date)}
                                  </div>
                                </div>
                                <div className='days-right'>
                                  <h3 className='title'>{item.title}</h3>
                                  <p className='days'>{daysDiff(item.sale_start_date)} {daysDiff(item.sale_start_date) > MagicNumber.ONE ? 'days' : 'day'}</p>
                                </div>
                              </div>
                            </div>
                          }) : ''}
                        </div>
                      </div>
                    }) : ''}
                  </div>
                  {(homeUpcommingListCount > MagicNumber.THREE) || (getPlatformInfo() === 'mobile' && homeUpcommingListCount > MagicNumber.ONE) ? <> <a className='carousel-control-prev' href='#destinationcontrols1' role='button' data-slide='prev'>
                    <span className='carousel-prev-icon' aria-hidden='true'>
                      prev
                      <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                    </span>
                    <span className='sr-only'>Preview</span>
                  </a>
                    <a className='carousel-control-next' href='#destinationcontrols1' role='button' data-slide='next'>
                      <span className='carousel-next-icon' aria-hidden='true'>
                        next
                        <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                      </span>
                      <span className='sr-only'>Next</span>
                    </a></> : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> : ''} */}
      <div className='product-carousel-section' ref={myRef}>
        <div className='product-container'>
          {/* to be work on it ------------------####################--------------- */}
          <div className='head-product'>
            {/* <p className='pg-product'>THE DROP</p>
            <h2 className='pg-title'>Five artifacts from apivotal moment in Rock history.</h2> */}
            {(getPlatformInfo() === 'desktop')? <>
            <div className='product-carousel-container'>
            <div className='product-section'>
              <div className='carousel-section'>
                <div id='destinationcontrols2' className='carousel slide' data-ride='carousel'>
                  <div className='carousel-inner'>
                  
                  {homeDropProductList && homeDropProductList.length ? homeDropProductList.map((card: any, index: number) => {
                      return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                        <div className='card-section'>
                          {card && card.length ? card.map((item: any) => {
                            return <div className='card' key={item.id}>
                              <div className='image-box hand' onClick={() => handleDropDetailsPage(item.id)}>
                                <img src={`${item.product_image}?aspect_ratio=3:2`} className='card-image' alt='product' />
                                {item.type === 'auction' && !loader ? <p className='item-text'>
                                  {remainingDate && (remainingDate).trim() !== '0' ? 'Bid Now' : 'Auction Completed'}
                                </p> : ''}
                                {item.type === 'sale' ? <p>
                                  {item.quantity ? ''
                                  //  <span>{item.quantity} available</span>
                                   : <p className='item-text'>Sold Out</p>}
                                </p> : ''}
                              </div>
                              <p className='name-item'>{item.name}</p>
                            </div>;
                          }) : ''}
                        </div>
                      </div>;
                    }) : ''}
                  </div>
                  {(homeDropProductListCount >= MagicNumber.FOUR)  ? <> <a className='carousel-control-prev' href='#destinationcontrols2' role='button' data-slide='prev'>
                    <span className='carousel-prev-icon' aria-hidden='true'>
                      prev
                      <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                    </span>
                    <span className='sr-only'>Preview</span>
                  </a>
                    <a className='carousel-control-next' href='#destinationcontrols2' role='button' data-slide='next'>
                      <span className='carousel-next-icon' aria-hidden='true'>
                        next
                        <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                      </span>
                      <span className='sr-only'>Next</span>
                    </a></> : ''}
                </div>
              </div>
            </div> 
            {/* mobile view condition */}
          </div> </> : <><div className='product-carousel-container mobile-view-carousel'>
              {/* card section start here */}
              <div className='card-section'>
              {homeDropProductListmobile && homeDropProductListmobile.length ? homeDropProductListmobile.map((card: any, index: number) => {
                          return  <div className='card' key={card.id}>
                                <div className='image-box hand' onClick={() => handleDropDetailsPage(card.id)}>
                                  <img src={`${card.product_image}?aspect_ratio=3:2`} className='card-image' alt='product' />
                                  {card.type === 'auction' && !loader ? <p>
                                    {remainingDate && (remainingDate).trim() !== '0' ? <span className='item-text' style={{background:'rgb(174 40 195)',color:'#fff',padding: '6px 20px 21px 7px'}}>Bid Now</span> : <span className='item-text' style={{background:'yellow',color:'#000',padding: '6px 20px 21px 7px'}}>Auction Completed</span>}
                                  </p> : ''}
                                  {card.type === 'sale' ? <p>
                                    {card.quantity ? 
                                    <span className='item-text' style={{background:'#147bfc',color:'#fff',padding: '6px 47px 21px 7px'}}>Buy</span> 
                                    : <p className='item-text' style={{background:'yellow',color:'#000',padding: '6px 20px 21px 7px'}}>Sold Out</p>}
                                  </p> : ''}
                                </div>
                                <p className='name-item'>{card.name}</p>
                              </div>    ;
                        }) : ''}
                         </div>
              {/* card section end here */}

                {/* <div className='product-section'>
                  <div className='carousel-section'>
                    <div id='destinationcontrols2' className='carousel slide' data-ride='carousel'>
                      <div className='carousel-inner'>

                        {homeDropProductListmobile && homeDropProductListmobile.length ? homeDropProductListmobile.map((card: any, index: number) => {
                          return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                            <div className='card-section'>

                              <div className='card' key={card.id}>
                                <div className='image-box hand' onClick={() => handleDropDetailsPage(card.id)}>
                                  <img src={`${card.product_image}?aspect_ratio=3:2`} className='card-image' alt='product' />
                                  {card.type === 'auction' && !loader ? <p className='item-text'>
                                    {remainingDate && (remainingDate).trim() !== '0' ? 'Bid Now' : 'Auction Completed'}
                                  </p> : ''}
                                  {card.type === 'sale' ? <p>
                                    {card.quantity ? 
                                    <span className='item-text'>Buy</span> 
                                    : <p className='item-text'>Sold Out</p>}
                                  </p> : ''}
                                </div>
                                <p className='name-item'>{card.name}</p>
                              </div>

                            </div>
                          </div>;
                        }) : ''}
                      </div>
                      { (homeDropProductListCount > MagicNumber.ONE)  ? <> <a className='carousel-control-prev' href='#destinationcontrols2' role='button' data-slide='prev'>
                        <span className='carousel-prev-icon' aria-hidden='true'>
                          prev
                          <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                        </span>
                        <span className='sr-only'>Preview</span>
                      </a>
                        <a className='carousel-control-next' href='#destinationcontrols2' role='button' data-slide='next'>
                          <span className='carousel-next-icon' aria-hidden='true'>
                            next
                            <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                          </span>
                          <span className='sr-only'>Next</span>
                        </a></> : ''}
                    </div>
                  </div>
                </div> */}
              </div></> }
          </div>
          {/* <div className='view-all-button'>
            <button type='button' className='btn primary view-btn' onClick={() => history.push('/marketplace')}>
              View all items
              <img src={RIGHT_ARROW_ICON} alt='arrow' className='arrow-right-img' />
            </button>
          </div> */}
        </div>
      </div>
      <div className='drop-start-section'>
        {dropData ? <DropTime data={dropData}
          status={remainingDate && (remainingDate).trim() !== '0' ? true : false}
          handleNotifyMe={handleNotifyMe}
          executeScroll={executeScroll}
        /> : ''}
      </div>
      {/* <div className='share-moment-section'>
        <div className='share-moment-container'>
          <h2 className='moment-title'>Share a moment in collecting history.</h2>
          <div className='media-section'>
            <div className='media-line'>
              <div className='social-icon'>
                <div className='icon-box twitter-icon' onClick={() => copyUrl()}>
                  <img src={TWITTER_ICON} className='icon-image' alt='twitter' />
                </div>
                <div className='icon-box insta-icon' onClick={() => copyUrl()}>
                  <img src={INSTAGRAM_ICON} className='icon-image' alt='instagram' />
                </div>
                <div className='icon-box facebook-icon' onClick={() => copyUrl()}>
                  <img src={FACEBOOK_ICON} className='icon-image' alt='facebook' />
                </div>
                <div className='icon-box snapchat-icon' onClick={() => copyUrl()}>
                  <img src={SNAPCHAT_ICON} className='icon-image' alt='snapchat' />
                </div>
                <div className='icon-box tiktok-icon' onClick={() => copyUrl()}>
                  <img src={TIKTOK_ICON} className='icon-image' alt='tiktok' />
                </div>
                <div className='icon-box link-icon' onClick={() => copyUrl()}>
                  <img src={LINK_ICON} className='icon-image' alt='link' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className='about-collector-section'>
      {(getPlatformInfo() === 'desktop')? <>
      {homeDrop && homeDrop.drop_content && homeDrop.drop_content.length ? <div className='collector-head-bg'>
          <div className='logo'>
            <img src={OOD_LOGO} className='company-logo' alt='logo' />
          </div>
          <div className='contain-detail'>
            <div className='detail-left'>
              <div className='image-left'>
                <div className='image-box'>
                  <img src={homeDrop.drop_content[0].featureImagePath ? `${homeDrop.drop_content[0].featureImagePath}?aspect_ratio=1:1` : ''} className='collector-image' alt='collector' />
                </div>
              </div>
            </div>
            <div className='detail-right'>
              <p className='intro-text'>{homeDrop.drop_content[0]?.caption}</p>
              <h3 className='title-text'>{homeDrop.drop_content[0]?.heading}</h3>
            </div>
          </div>
        </div> : ''}
        {homeDrop && homeDrop.drop_content && homeDrop.drop_content.length ? <div className='collector-container'>
          <div className='about-collector'>
            <div className='pg-height'>
              <p className='collector-pg'>{homeDrop.drop_content[0]?.about}</p>
            </div>
          </div>
          <div className='digital-content-section'>
            {homeDrop.drop_content[0]?.media_desc ? <h3 className='head-digital'>{homeDrop.drop_content[0]?.media_desc}</h3> : ''}
            <div className='video-section'>
              {homeDrop.drop_content[0]?.medias && homeDrop.drop_content[0]?.medias.length && homeDrop.drop_content[0]?.medias.map((mediaElelment: any) => {
                return (
                  <>
                    {mediaElelment.type === 'image' && mediaElelment.fullPath ? <div className='video-box'>
                      <img src={`${mediaElelment.fullPath}?aspect_ratio=320:213`} className='media-file' alt='media' />
                    </div> : mediaElelment.fullPath ? <div className='video-box'>
                      <FeedItemVideo videoURL={`${base}${mediaElelment.filepath}`} />
                    </div> : ''}
                  </>
                );
              })}
            </div>
            {homeDrop.drop_content[0]?.quote ? <div className='quotes-section'>
              <div className='quote-start'>
                <img src={QUOTE_START} className='quote-image' alt='quote' />
              </div>
              <div className='quote-text'>
                <p className='pg-text'>{homeDrop.drop_content[0]?.quote}</p>
                {homeDrop.drop_content[0]?.quote_by ? <span>{homeDrop.drop_content[0]?.quote_by}</span> : ''}
              </div>
              <div className='quote-end'>
                <img src={QUOTE_END} className='quote-image' alt='quote' />
              </div>
            </div> : ''}
            <div className='more-about-collector'>
              <h3 className='about-title'>
                {homeDrop.drop_content[0]?.collector_image}
              </h3>
              {homeDrop.drop_content[0].imageUrl1Path || homeDrop.drop_content[0].imageUrl2Path || homeDrop.drop_content[0].imageUrl3Path ||
                homeDrop.drop_content[0].imageUrl4Path || homeDrop.drop_content[0].imageUrl5Path || homeDrop.drop_content[0].imageUrl6Path ? <div className='more-collector-image'>
                {homeDrop.drop_content[0].imageUrl1Path && !homeDrop.drop_content[0].imageUrl2Path && !homeDrop.drop_content[0].imageUrl3Path &&
                  !homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image1'>
                  <div className='box-container'>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                  </div>
                </div> : ''}
                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && !homeDrop.drop_content[0].imageUrl3Path &&
                  !homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image2'>
                  <div className='box-container'>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                  </div>
                </div> : ''}
                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                  !homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image3'>
                  <div className='box-container'>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container center-container3'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl3Path ? homeDrop.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                  </div>
                </div> : ''}

                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                  homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image4'>
                  <div className='box-container'>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container center-container4'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl3Path ? homeDrop.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl4Path ? homeDrop.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                  </div>
                </div> : ''}
                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                  homeDrop.drop_content[0].imageUrl4Path && homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image5'>
                  <div className='box-container'>
                    <div className='image-container container5'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl3Path ? homeDrop.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container container5'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl4Path ? homeDrop.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl5Path ? homeDrop.drop_content[0].imageUrl5Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                  </div>
                </div> : ''}
                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                  homeDrop.drop_content[0].imageUrl4Path && homeDrop.drop_content[0].imageUrl5Path && homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image6'>
                  <div className='box-container'>
                    <div className='image-container small-image'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl1Path ? `${homeDrop.drop_content[0].imageUrl1Path}?aspect_ratio=2:3` : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl2Path ? `${homeDrop.drop_content[0].imageUrl2Path}?aspect_ratio=4:5` : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container big-image'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl3Path ? `${homeDrop.drop_content[0].imageUrl3Path}?aspect_ratio=13:23` : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                  </div>
                  <div className='box-container'>
                    <div className='image-container small-image'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl4Path ? `${homeDrop.drop_content[0].imageUrl4Path}?aspect_ratio=4:5` : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl5Path ? `${homeDrop.drop_content[0].imageUrl5Path}?aspect_ratio=1:1` : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                    <div className='image-container big-image'>
                      <div className='images-box'>
                        <img src={homeDrop.drop_content[0].imageUrl6Path ? `${homeDrop.drop_content[0].imageUrl6Path}?aspect_ratio=13:23` : COLLECTOR_PL} className='collector-img' alt='collector' />
                      </div>
                    </div>
                  </div>
                </div> : ''}
              </div> : ''}
            </div>
          </div>
        </div> : ''}
        {homeDrop && homeDrop.drop_content && homeDrop.drop_content.length && (homeDrop.drop_content[0].collector_msg || homeDrop.drop_content[0].signatureUrlPath) ? <div className='collector-memory-section'>
          <div className='memory-container'>
            {homeDrop.drop_content[0].collector_msg ? <p className='memory-text'>{homeDrop.drop_content[0]?.collector_msg}</p> : ''}
            {homeDrop.drop_content[0].signatureUrlPath ? <div className='signature-text'>
              <img src={homeDrop.drop_content[0].signatureUrlPath ? `${homeDrop.drop_content[0].signatureUrlPath}?aspect_ratio=1:1` : ''} className='sign-image' alt='sign' />
            </div> : ''}
          </div>
        </div> : ''}

</>:''}
      
        {/* old droping product */}
                {/* {homeUpcommingListCount && remainingDate && (remainingDate).trim() !== '0' ? 
        <div className='product-carousel-section droppingsoon-carousel-section'>  
          <div className='product-container'>
            <div className='head-product'>
              <h2 className='pg-title'>Dropping Soon Running</h2>
            </div>
            <div className='product-carousel-container'>
              <div className='product-section'>
                <div className='carousel-section'>
                  <div id='destinationcontrols3' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      {homeUpcommingDropList && homeUpcommingDropList.length ? homeUpcommingDropList.map((card: any, index: number) => {
                        return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                          <div className='card-section'>
                            {card && card.length ? card.map((item: any) => {
                              return <div className='card' key={item.id}>
                                <div className='image-box'>
                                  <img src={`${item.filename}?height=360`} className='card-image' alt='product' />
                                </div>
                                <div className='dropping-date-time'>
                                  <div className='calendar-left'>
                                    <div className='box'>
                                      {getDropStartDate(item.sale_start_date)}
                                    </div>
                                  </div>
                                  <div className='days-right'>
                                    <h3 className='title'>{item.title}</h3>
                                    <p className='days'>{daysDiff(item.sale_start_date)} {daysDiff(item.sale_start_date) > MagicNumber.ONE ? 'days' : 'day'}</p>
                                  </div>
                                </div>
                              </div>
                            }) : ''}
                          </div>
                        </div>
                      }) : ''}
                    </div>
                    {homeUpcommingListCount > MagicNumber.THREE ? <> <a className='carousel-control-prev' href='#destinationcontrols3' role='button' data-slide='prev'>
                      <span className='carousel-prev-icon' aria-hidden='true'>
                        prev
                        <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                      </span>
                      <span className='sr-only'>Preview</span>
                    </a>
                      <a className='carousel-control-next' href='#destinationcontrols3' role='button' data-slide='next'>
                        <span className='carousel-next-icon' aria-hidden='true'>
                          next
                          <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                        </span>
                        <span className='sr-only'>Next</span>
                      </a></> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : ''} */}

{/* New updated dropping soon */}

{(getPlatformInfo() === 'desktop')? <>
{homeUpcommingListCount && remainingDate && (remainingDate).trim() !== '0' ? 
        <div className='product-carousel-section droppingsoon-carousel-section'>  
          <div className='product-container'>
            <div className='head-product'>
              <h2 className='pg-title'>Dropping Soon</h2>
            </div>
            {(getPlatformInfo() === 'desktop')? <>
            <div className='product-carousel-container'>
              <div className='product-section'>
                <div className='carousel-section'>
                  <div id='destinationcontrols3' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      {homeUpcommingDropList && homeUpcommingDropList.length ? homeUpcommingDropList.map((card: any, index: number) => {
                        return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                          <div className='card-section'>
                            {card && card.length ? card.map((item: any) => {
                              return <div className='card' key={item.id}>
                                <div className='image-box'>
                                  <img src={`${item.filename}?height=360`} className='card-image' alt='product' />
                                </div>
                                <div className='dropping-date-time'>
                                  <div className='calendar-left'>
                                    <div className='box'>
                                      {getDropStartDate(item.sale_start_date)}
                                    </div>
                                  </div>
                                  <div className='days-right'>
                                    <h3 className='title'>{item.title}</h3>
                                    <p className='days'>{daysDiff(item.sale_start_date)} {daysDiff(item.sale_start_date) > MagicNumber.ONE ? 'days' : 'day'}</p>
                                  </div>
                                </div>
                              </div>;
                            }) : ''}
                          </div>
                        </div>;
                      }) : ''}
                    </div>
                    {homeUpcommingListCount > MagicNumber.THREE ? <> <a className='carousel-control-prev' href='#destinationcontrols3' role='button' data-slide='prev'>
                      <span className='carousel-prev-icon' aria-hidden='true'>
                        prev
                        <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                      </span>
                      <span className='sr-only'>Preview</span>
                    </a>
                      <a className='carousel-control-next' href='#destinationcontrols3' role='button' data-slide='next'>
                        <span className='carousel-next-icon' aria-hidden='true'>
                          next
                          <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                        </span>
                        <span className='sr-only'>Next</span>
                      </a></> : ''}
                  </div>
                </div>
              </div>
            </div>
            </>:<>
            <div className='product-carousel-container'>
              <div className='product-section'>
                <div className='carousel-section'>
                  <div id='destinationcontrols3' className='carousel slide' data-ride='carousel'>
                    <div className='carousel-inner'>
                      {homeUpcommingDropListmobile && homeUpcommingDropListmobile.length ? homeUpcommingDropListmobile.map((card: any, index: number) => {
                        return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                          <div className='card-section'>
                             <div className='card' key={card.id}>
                                <div className='image-box'>
                                  <img src={`${card.filename}?height=360`} className='card-image' alt='product' />
                                </div>
                                <div className='dropping-date-time'>
                                  <div className='calendar-left'>
                                    <div className='box'>
                                      {getDropStartDate(card.sale_start_date)}
                                    </div>
                                  </div>
                                  <div className='days-right'>
                                    <h3 className='title'>{card.title}</h3>
                                    <p className='days'>{daysDiff(card.sale_start_date)} {daysDiff(card.sale_start_date) > MagicNumber.ONE ? 'days' : 'day'}</p>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>;
                      }) : ''}
                    </div>
                    {(homeUpcommingListCount > MagicNumber.ONE)  ? <> <a className='carousel-control-prev' href='#destinationcontrols3' role='button' data-slide='prev'>
                      <span className='carousel-prev-icon' aria-hidden='true'>
                        prev
                        <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                      </span>
                      <span className='sr-only'>Preview</span>
                    </a>
                      <a className='carousel-control-next' href='#destinationcontrols3' role='button' data-slide='next'>
                        <span className='carousel-next-icon' aria-hidden='true'>
                          next
                          <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                        </span>
                        <span className='sr-only'>Next</span>
                      </a></> : ''}
                  </div>
                </div>
              </div>
            </div>
            </>}
          </div>
        </div> : ''}
        </>:''}

        {(getPlatformInfo() === 'desktop')? <>          
        <div className='collector-workflow-section'>
          <div className='workflow-container'>
            <div className='head-section'>
            <div className='logo'>
                <img src={LOGO} className='logo-company' alt='logo' />
              </div>
              <h2 className='head-title'>WHY BUY ON OOD? </h2>
            </div>
            <div className="detail-container">
              <div className="workflow-detail">
                <div className="working-flow">
                  <div className="flow-pg-point"><b>Authentication</b>
                  <p className='lead'>  Easily verifiable authenticity, secured by blockchain technology – just scan the items you’ve purchased using the <span>ood</span> app and receive confirmation instantly. Never wonder if the items you’ve purchased and received are the real deal, you can check it for yourself.</p>
                    <p className="round-black"></p>
                  </div>
                  <div className='flow-pg-point'><b>Unique content</b>
                  <p className='lead'>Once your purchases are verified and accepted by you, exclusive bonus content is unlocked for your enjoyment only. Congratulations! You are now a VIP with access that no one else has and more content may be delivered to you at any time… stay tuned!</p>
                    <p className='round-black'></p>
                  </div>
                  <div className="flow-pg-point"><b>Community</b>
                  <p className='lead'>As a registered collector on <span>ood</span>, you are now part of the <span>ood</span> community. You will receive messages and notifications directly from the artists and creators whose works you’ve collected and can respond and converse with other members. Welcome!</p>
                    <p className="round-black"></p>
                  </div>
                  <div className="flow-pg-point"><b>Royalty</b>
                  <p className='lead'>We treat our partners like royalty, but more importantly, we pay the artists and creators who drop and list their works on <span>ood</span> a royalty each and every time their works are sold on <span>ood</span>. This has never been done before and we hope that, if you ever decide to sell something from your <span>ood</span> collection, you will list it in the <span>ood</span> marketplace so we can continue to pay the creators their due. We want to honor them and ensure that they are always rewarded for bringing us the stuff that we love. </p>
                    <p className="round-black"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div></> :''}
        {(getPlatformInfo() === 'desktop')? <>
        <div className='collector-workflow-section-timeline'>
          <div className='workflow-container'>
            <div className='head-section'>
              <div className='logo'>
                <img src={LOGO} className='logo-company' alt='logo' />
              </div>
              <h2 className='head-title'>For collectors, by collectors.</h2>
            </div>
            <div className='detail-container'>
              <div className='workflow-detail'>
                <h3>How it works</h3>
                <div className='working-flow'>
                  <div className='flow-pg'>Items are directly sourced and tagged
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Drop begins
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Items are purchased via FIAT
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Confirmations sent to purchasers
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Custom shipping arranged
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Items arrive
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg last-flow-pg'>Purchasers keep items or resell on <span>ood</span>
                    <p className='round-black'></p>
                  </div>
                </div>
              </div>
              <div className='workflow-image'>
                <img src={COLLECTOR_PRODUCT} className='product-collector-img' alt='collector' />
              </div>
            </div>
          </div>
        </div></> :    <div className='collector-workflow-section-timeline'>
          <div className='workflow-container'>
          <div className='our-story-section'>
        <div className='head-story'>
          <h3 className='title-story'>For collectors,</h3>
          <h3 className='title-story'>by collectors.</h3>
        </div>
      </div>
            <div className='detail-container'>
              <div className='workflow-detail'>
                <h1 style={{fontWeight:'revert'}}>How it works</h1>
                <br/>
                <div className='working-flow'>
                  <div className='flow-pg'>Items are directly sourced and tagged
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Drop begins
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Items are purchased via FIAT
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Confirmations sent to purchasers
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Custom shipping arranged
                    <p className='round-black'></p>
                  </div>
                  <div className='flow-pg'>Items arrive
                    <p className='round-black'></p>
                  </div>
                  <div className="flow-pg last-flow-pg">Purchasers keep items or resell on <span>ood</span>
                    <p className="round-black"></p>
                  </div>
                </div>
              </div>
              <div className='workflow-image'>
                <img src={COLLECTOR_PRODUCT} className='product-collector-img' alt='collector' />
              </div>
            </div>
          </div>
        </div>}

    
        {(getPlatformInfo() === 'desktop')? <>
        {homeDropFaqList && homeDropFaqList.length ? <DropFaqList list={homeDropFaqList} /> : ''}
        <div className='drop-start-section drop-start-step2'>
          {dropData ? <DropTime data={dropData}
            status={remainingDate && (remainingDate).trim() !== '0' ? true : false}
            handleNotifyMe={handleNotifyMe}
            executeScroll={executeScroll}
          /> : ''}
        </div>
        </> :''}
      </div>
      <div className='buy-now-button'>
        <button type='button' className='btn primary buy-now-btn' onClick={() => history.push('/marketplace')}>
          Buy now
          <img src={RIGHT_ARROW_ICON} alt='arrow' className='arrow-right-black' />
        </button>
      </div>
      <NotifyModal openModal={openModal}
        isOpen={isModalOpen}
        handleNotifyUser={handleNotifyUser}
        heading='Get notified as soon as drop is available.'
      />
    </>
  );
});

export default wrapperComponent(true, true)(DropHome);

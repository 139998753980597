import React, { useEffect, useState, FC } from 'react';
import './faq.scss';

type IProps = {
    list: any
};

const DropFaqList = ({ list }: IProps) => {

    return (
        <>
            {list && list.length ? <div className='home-faq-section'>
                <div className='faq-container'>
                    <h2 className='faq-title'>FAQ</h2>
                    <div id='accordion'>
                        {list.map((item: any, index: number) => {
                            return <div className='card' key={index}>
                                <div className='card-header' id={`headingEleven${index}`}>
                                    <h5 className='mb-0'>
                                        <button className={`${index === 0 ? 'btn btn-link' : 'btn btn-link collapsed'}`} data-toggle='collapse' data-target={`#collapseEleven${index}`}
                                            aria-expanded='true' aria-controls={`collapseEleven${index}`}>
                                            {item.question}
                                        </button>
                                    </h5>
                                </div>
                                <div id={`collapseEleven${index}`} className={`${index === 0 ? 'collapse show' : 'collapse'}`} aria-labelledby={`headingEleven${index}`} data-parent='#accordion'>
                                    <div className='card-body'>
                                        <p>{item.answer}</p>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
            </div> : ''}
        </>
    );
};

export default DropFaqList;

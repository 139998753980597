import Skeleton from 'react-loading-skeleton';

export const ProfileViewSkeleton = () => {
  return (
    <>
        <div className="search-user-detail-skeleton">
            <div className="left-wrapper">
                <div className="skeleton-profile-box">
                    <Skeleton />
                </div>
                <div className="skeleton-profile-detail">
                    <Skeleton count={3} />
                </div>
            </div>
            <div className="right-wrapper">
                <div className="skeleton-action-box">
                    <Skeleton count={2} />
                </div>
            </div>
        </div>
    </>
  )
};

import React, { useState } from 'react';
import Cookie from 'js-cookie';
import { useStores } from '../../../../../models';
import { USER_ID } from './../../../../../constants';
import { AddShippingAddress, RemoveAddress } from './../../../Molecules';
import './shippingAddress.scss';

type Iprops = {
  itemData: any;
}

export const ShippingAddress = ({ itemData }: Iprops) => {
  const [shippingAddressModal, setShippingAddressModal] = useState<boolean>(false);
  const [removeAddressModal, setRemoveAddressModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const rootStore = useStores();
  const {updateShippingAddress, getShippingAddress } = rootStore.settingStore
  const toggleShippingAddressModal = () => {
    setShippingAddressModal(!shippingAddressModal);
  }
  const toggleRemoveModal = () => {
    setRemoveAddressModal(!removeAddressModal);
  }
  const currentAddressValue = {
    user_id: Number(Cookie.get(USER_ID)),
    name: itemData.name,
    phone: itemData.phone,
    country: 'US',
    address_1: itemData.address_1,
    address_2: itemData.address_2,
    city: itemData.city,
    state: itemData.state,
    zip_code: itemData.zip_code,
    is_default: true,
  }
  const handleChange = async () => {
    setLoader(true);
    await updateShippingAddress(itemData.id, currentAddressValue);
    getShippingAddress(Number(Cookie.get(USER_ID)));
    setLoader(false);
  }

  return (
    <>
    {loader ? <div className="loader-main">
        <div className="spinner-border" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div> : ''}
      <div className="shipping-address-card">
        <div className="shipping-address-card-head">
          <h3>{itemData.name}</h3>
          <span className="icon icon-delete" onClick={() => toggleRemoveModal()} />
        </div>
        <div className="shipping-address-card-body">
        <p>{itemData.address_1} {`${itemData.address_2 && itemData.address_2}`} {itemData.city}, {itemData.state} - {itemData.zip_code}, {itemData.country}</p>
        {itemData.phone && <p className="mt-2">Mobile: +1 {itemData.phone}</p>
        }
        </div>
        <div className="shipping-address-card-footer">
          <div className="black-radio-custom">
            <input
              className="form-check-input"
              type="radio"
              name="Default"
              id={itemData.id}
              checked={itemData.is_default}
              onChange={handleChange}
              />
            <label className="form-check-label" id="black-label-form" htmlFor={itemData.id}>{itemData.is_default ? 'Default' : 'Make Default'}</label>
          </div>
          <button type="button" className="btn outline-primary" onClick={() => setShippingAddressModal(true)}>Edit</button>
        </div>
      </div>
      {removeAddressModal && (
        <RemoveAddress
          openModal={()=>{toggleRemoveModal();}}
          id={itemData.id}
          title="Remove Address"
          description="Are you sure you want to remove this address?"
          isOpen={removeAddressModal}
        />
      )}
      {shippingAddressModal && (
        <AddShippingAddress
          openModal={()=>{toggleShippingAddressModal();}}
          activeAddress={itemData}
          shippingAddressMode="Edit"
          title="Edit Shipping Address"
          isOpen={shippingAddressModal}
        />
      )}
    </>
  )
}

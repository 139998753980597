import Skeleton from 'react-loading-skeleton';

export const FeedItemUserCommentSkeleton = () => {
  return (
    <div className='user-comment-section'>
      <div className='user-image'>
        <div className='image-box' >
          <Skeleton circle width={40} height={40} />
        </div>
      </div>
      <div className='user-detail'>
        <div className='full-detail'>
          <div className='head-detail'>
            <h3 className='user-name'>
              <Skeleton width={200} height={16} />
            </h3>
            <p><Skeleton width={80} height={16} /></p>
          </div>
          <Skeleton count={3} height={15} />
          <div className='connection-line'></div>
        </div>
        <div className='replied-section'>
          <div className='image-box'>
            <Skeleton className='replier-image' />
          </div>
          <div className='replier-detail'>
            <Skeleton className='name' width={200} height={16} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const API_ROUTES = {
  LOGIN: 'api/v1/admin/users/login',
  LOGOUT: 'api/v1/users/log-out',
  BANNER_LIST: 'api/v1/admin/banner',
  PRODUCT_LIST: 'api/v1/admin/product',
  CATEGORIES_LIST: 'api/v1/category',
  ADD_ADDRESS: 'api/v1/addresses'
};

export const PAGE_ROUTES = {
  HOME: '/',
  PRODUCTS: '/products/',
  DROP: '/drop/',
  DROP_PREVIEW: '/drop-preview/',
  PRODUCTS_SCAN: '/scanned-products/',
  PRODUCTSS: '/search/test',
  SETTING: '/setting',
  PROFILE: '/profile',
  CART: '/cart',
  ART: '/art',
  MUSIC: '/music',
  MARKET_PLACE: '/marketplace',
  SPORTS: '/sports',
  MEDIA: '/media',
  ALL: '/all',
  TC: '/terms',
  AC:'/advance-terms',
  PP: '/privacy-policy',
  USER_PROFILE: '/profile/',
  ORDER_DETAIL: '/order-detail',
  FEED_LISTING: '/feeds',
  FEED_DETAIL: '/feeds/view',
  WISHLIST: '/wishlist',
  PAGE_NOT_FOUND: '/page-not-found',
  CHAT: '/chat',
  AUCTION: '/bids',
  COLLECTION_DETAIL: '/collection/detail',
  CONTACT: '/contact',
  FAQ: '/faq',
  About:'/about-us',
  OurStory:'/our-story'
};

export const CATEGORY_NAME = {
  MUSIC: 'music',
  ART: 'art',
  SPORTS: 'sports',
  MEDIA: 'media',
};

import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../../../models';
import LinkCardCustomStripe from '../customStripe/LinkCardCustomStripe';
import { LinkCard } from '../modal/LinkCard';
import { RemoveCard } from '../modal/RemoveCard';
import { TRASH_ICON, VISA_ICON, STARS_ICON, MagicNumber, MASTER_ICON, AMERICAN_ICON, LINKED_ICON, EDIT_ICON } from './../../../../constants';
import './savedcard.scss';

type LinkCardProps = {
  openModal: any;
};

export const LinkedCard = observer(function LinkedCard({ openModal }: LinkCardProps) {

  const rootStore = useStores();
  const { linkedCards, getLinkedCard, cardId } = rootStore.cardStore;
  const [editCardModal, setEditCardModal] = useState(false);

  const fetchSavedCard = async () => {
    await getLinkedCard();
  };

  useEffect(() => {
    fetchSavedCard();
  }, []);

  const cardExpiry = (expiry: string | null) => {
    return ('0' + expiry).slice(-MagicNumber.TWO);
  };

  const editLinkedCard = () => {
    setEditCardModal(!editCardModal);
  };

  const toggleEditModal = () => {
    setEditCardModal(!editCardModal);
  };

  const cardImage = (brand: string | null) => {
    let cardIcon = '';
    let classes = 'save-visa-icon';
    if (brand === 'MasterCard') {
      cardIcon = MASTER_ICON;
      classes = 'save-master-icon';
    } else if (brand === 'Visa') {
      cardIcon = VISA_ICON;
    } else if (brand === 'American Express') {
      cardIcon = AMERICAN_ICON;
    } else {
      classes = 'save-visa-icon';
      cardIcon = '';
    }
    return <img src={cardIcon} alt='' className={classes} />;
  };

  return (
    <>
      {linkedCards && linkedCards.cardData && linkedCards.cardData.card_id ?
        <div className='save-card-outside'>
          <div className='save-card'>
            <div className='visa-row'>
              {cardImage(linkedCards && linkedCards.cardData && linkedCards.cardData.brand)}
              <img className='trash'
                src={EDIT_ICON}
                alt='sold'
                onClick={() => editLinkedCard()}
              />
            </div>
            <div className='card-no'>
              <img src={STARS_ICON} alt='sold' /> <img src={STARS_ICON} alt='sold' /> <img src={STARS_ICON} alt='sold' /> {linkedCards.cardData.last_four_digit || ''}
            </div>
            <div className='expiry'>
              <div className='name'>Name<span>{linkedCards.cardData.account_holder_name || ''}</span></div>
              <div className='name'>Expiry Date<span>{`${cardExpiry(linkedCards.cardData.exp_month) || ''}/${cardExpiry(linkedCards.cardData.exp_year) || ''}`}</span></div>
            </div>
          </div>
        </div> :
        ''
      }
      {editCardModal && (
        <LinkCard
          openModal={() => toggleEditModal()}
          title='Link Card'
          isOpen={editCardModal}
          info='add'
        />
      )}
    </>
  );
});

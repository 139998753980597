import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Cookie from 'js-cookie';
import { useStores } from '../../../../models';
import { PROFILE_ICON, USER_ID } from '../../../../constants';

type IProps = {
    listItem: any;
    currentUserId: number;
    userFollowingMood: boolean;
  };
  
  export const FollowingUserList = observer(function TabContent({ listItem, currentUserId, userFollowingMood }: IProps) {
    const rootStore = useStores();
    const history = useHistory();
    const { unfollowUser, followUser, getFollowCount, getUnfollowStatus, setFollowUnfollowLoader } = rootStore.followerStore;
    const [userFollowingStatus, setUserFollowingStatus] = useState<boolean>(true);
    const [removeLoader, setRemoveLoader] = useState<boolean>(false);
    const loggedInUserId = Number(Cookie.get(USER_ID));
  
    const redirectToDetailPage = (id:number) => {
      history.push({
        pathname: id === loggedInUserId ? `/profile` : `/profile/${id}`,
      });
    };
  
    const userUnfollow = async(id:number) => {
      setRemoveLoader(true);
      setFollowUnfollowLoader(true);
      await unfollowUser(id).then(res => {
        if(res && res.data && res.data.data === true) {
          setUserFollowingStatus(false);
          getUnfollowStatus(true);
        }
      });
      await getFollowCount(currentUserId);
      setRemoveLoader(false);
      setFollowUnfollowLoader(false);
    };
  
    const userFollow = async(id:number) => {
      setRemoveLoader(true);
      setFollowUnfollowLoader(true);
      await followUser(id).then(res => {
        if(res && res.data && res.data.data === true) {
          setUserFollowingStatus(true);
          getUnfollowStatus(true);
        }
      });
      await getFollowCount(currentUserId);
      setRemoveLoader(false);
      setFollowUnfollowLoader(false);
    };
    
    useEffect(()=>{
      setUserFollowingStatus(true);
    }, [listItem]);
  
    return (
        <div className='list-item'>
          <div className='list-item-image'>
          <img
            src={listItem.profile_image}
            alt='user image'
            onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
          />
          </div>
          <div className='list-item-detail' onClick={()=>{redirectToDetailPage(listItem.id);}}>
            <h3>{listItem.firstname} {listItem.lastname}</h3>
            <p>{listItem.username}</p>
          </div>
          {userFollowingMood ? (
            <div className='list-item-action'>
              {removeLoader ? (<span className='loading' />
              ):(
                <>
                {userFollowingStatus ? (
                  <button type='button' onClick={()=>{userUnfollow(listItem.id);}} className='btn secondary-btn'>Unfollow</button>
                ):(
                  <button type='button' onClick={()=>{userFollow(listItem.id);}} className='btn secondary-btn fill'>Follow</button>
                )}
                </>
              )} 
            </div>
          ):(<></>)}
        </div>
    );
  });
import Skeleton from 'react-loading-skeleton';

export const AddCartSkeleton = () => {
  return (
    <>
        <div className="empty-cart-skeleton">
          <Skeleton circle={true} height={100} width={100} />
          <Skeleton count={1} height={25} className="skeleton-btn" />
          <Skeleton count={1} height={55} className="skeleton-btn"/>
        </div>
    </>
  )
};


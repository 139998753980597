import { Instance, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';

export const StaticPagesStoreModal = types
  .model('StaticPages')
  .extend(withEnvironment)
  .actions((self) => ({
    getFaqList: flow(function* () {
      const response = yield self.environment.api.get(`faq`);
      return response; 
    })
  }))
  .actions((self) => ({
    getPrivacyPolicy: flow(function* (){
      const response = yield self.environment.api.get(`static-pages/privacy-policy`);
      return response;
    })
  }))
  .actions((self) => ({
    getTerms: flow(function* (){
      const response = yield self.environment.api.get(`static-pages/terms-condition`);
      return response;
    })
  }))
  .actions((self) => ({
    getadvanceTerms: flow(function* (){
      const response = yield self.environment.api.get(`static-pages/advance-terms-condition`);
      return response;
    })
  }))
  .actions((self) => ({
    getabout: flow(function* (){
      const response = yield self.environment.api.get(`static-pages/about`);
      return response;
    })
  }));
type StaticPagesType = Instance<typeof StaticPagesStoreModal>;
export interface StaticPages extends StaticPagesType { }

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ProductSkeleton } from './ProductSkeleton';
import { ProductColorSkeleton } from './ProductColorSkeleton';
import { Collection, Filter } from '../../Molecules';
import {
  FILTERS,
  getParam,
  PARAMKEY,
  isEmpty,
  MagicNumber,
  filteredValues,
  priceIndex,
  getDate,
  VALIDATION_MESSAGES,
} from '../../../../constants';
import { Image, FilterBox, FullPageLoader } from '../../Atoms';
import { useStores } from '../../../../models';
import wrapperComponent from '../../Templates/wrapperComponent';
import './product.scss';
import { FILTER_ICON } from '../../../../constants/image';
import ProductCollectors from './ProductCollectors';
import { NoCollectiblesFound } from '../../Molecules/emptyScreens/EmptyScreens';
import Cookies from 'js-cookie';
import { toJS } from 'mobx';

type IProps = {
  from: string;
};

const Products = observer(function Products({ from }: IProps) {
  const rootStore = useStores();
  const history = useHistory();
  const scrollDiv = React.useRef<HTMLInputElement>(null);
  const { ZERO, ONE, TWO, THREE, FOUR, TEN_FIVE, TEN, FIVE } = MagicNumber;
  const { replaceCollectors, collectionData } = rootStore.collectionStore;
  const { getNotificationBadge } = rootStore.loginStore;
  const {
    fetchProductList,
    productList,
    fetchProductFilters,
    allFilters,
    categoriesFilters,
    totalProductPages,
    currentProductPage,
    isLoading,
    setProductIds,
    fetchCategory
  } = rootStore.homeStore;
  const { setCommentProductId } = rootStore.commentStore;
  const [productName, setProductName] = useState<string>('');
  const [isProductLoad, setProductLoad] = useState<boolean>(true);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [sortingItms, setSortingItem] = useState<Array<any>>([]);
  const [filters, setFilters] = useState<Array<Array<[] | any>>>([
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [filterCollapse, openFilterCollapse] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [isAll, isAllProduct] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchProduct, setSearchProduct] = useState<string>('');
  const [options, setOptions] = useState<any>();
  const [tabIndex, setTabIndex] = useState<string>('collectibles');
  const analytics = require('react-segment');

  const executeScroll = () => {
    if (scrollDiv.current) {
      return scrollDiv.current.scrollIntoView();
    }
  };

  const getProductListByCategory = async (location: any) => {
    await fetchCategory(location).then(async (res: any) => {
      getProductName(location);
    });
  };

  useEffect(() => {
    categoryPageViewed();
    setSortingItem([
      { title: 'Recently Added', value: 'sort_by=id&sort_dir=desc' },
      { title: 'Price - Low to high', value: 'sort_by=price&sort_dir=asc' },
      { title: 'Price - High to low', value: 'sort_by=price&sort_dir=desc' },
    ]);
    fetchProductFilters();
    getProductListByCategory(history.location);
    getProductName(history.location);
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      setProductLoad(true);
      getProductListByCategory(location);
      getProductName(location);
      allFilters.length === ZERO && fetchProductFilters();
      setTabIndex('collectibles');
      replaceCollectors();
      getNotificationBadge();
    });
  }, [history, filters[ZERO]]);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      categoryPageExited();
      replaceCollectors();
      getNotificationBadge();
    };
  }, []);

  const categoryPageViewed = () => {
    const userId = Cookies.get(VALIDATION_MESSAGES.USER_ID) || VALIDATION_MESSAGES.EMPTY;
    analytics.default.track('Category Page Viewed', {
      userId: userId,
      event: 'Category Page Viewed'
    });
  };
  const categoryPageExited = () => {
    analytics.default.track('Category Page Exited');
  };

  useEffect(() => {
    const collectionid = getParam(history.location.search).collection_id;
    const collection = findCollection(collectionid);
    if (collection) {
      handleFilterChange(
        {
          id: collection.id,
          title: collection.collection_name,
          value: collection.collection_name,
        },
        FIVE,
        ZERO,
        true,
        categoryId === TWO
      );
    }
    getNotificationBadge();
  }, [collectionData]);

  const isCollectionPage = () => {
    return !!getParam(history.location.search).collection_id;
  };

  const getProductName = async (loc: any) => {
    setProductLoad(true);
    let state = loc.state;
    if (!state) {
      await fetchCategory().then(async (res: any) => {
        const path = history.location.pathname.split('/');
        if (res && res.length) {
          res.forEach((element: any) => {
            if (element.slug === path[1]) {
              state = {
                id: element.id,
                name: element.name,
              };
            }
          });
        }
      });
    }

    setSearchParam(loc.search);
    if (state && state.id !== ZERO && state.name === 'All') {
      isAllProduct(true);
      setOptions(state);
      if (loc.search) {
        setSearchProduct(state.value);
        const param = getParameter(getParam(loc.search));
        if (Object.keys(param).length >= 1) {
          await fetchProductList(0, 'LOAD', ONE, TEN_FIVE, param, '', '', true);
          if (Object.keys(getParam(loc.search)).length > 1)
            setFiltersValue(getParam(loc.search));
          else {
            setFilters([[], [], [], [], [], []]);
            await fetchProductList(
              0,
              'LOAD',
              ONE,
              TEN_FIVE,
              param,
              '',
              '',
              true
            );
          }
          executeScroll();
        }
      } else
        fetchProductList(0, 'LOAD', ONE, TEN_FIVE, loc.search, '', '', true);
      setTimeout(() => {
        setProductLoad(false);
      }, MagicNumber.ONE_TH);
    } else {
      isAllProduct(false);
      setCategoryId(state && state.id);
      setProductName(state && state.name);

      if (Object.keys(getParam(loc.search)).length >= 1) {
        let parameter = getParameter(getParam(loc.search));
        await fetchProductList(
          state && state.id,
          'LOAD',
          ONE,
          TEN_FIVE,
          parameter
        );
        setFiltersValue(getParam(loc.search));
        executeScroll();
        setTimeout(() => {
          setProductLoad(false);
        }, MagicNumber.ONE_TH);
      } else {
        await fetchProductList(state && state.id, 'LOAD');
        await setFilters([[], [], [], [], [], []]);
        setTimeout(() => {
          setProductLoad(false);
        }, MagicNumber.ONE_TH);
      }
    }
  };

  const getParameter = (param: any) => {
    if (param['manufacture_Years']) {
      const updatedParam = JSON.stringify(param['manufacture_Years']);
      param['manufacture_Years'] = JSON.parse(updatedParam);
    }

    if (param.tab) delete param.tab;

    return Object.keys(param)
      .map((key) => `${key}=${param[key]}`)
      .join('&');
  };

  const findColor = (id: string) => {
    if (!allFilters) return null;

    for (let i = 0, length = allFilters.length; i < length; ++i) {
      if (allFilters[i].title === 'color') {
        const options = allFilters[i].Options;
        if (options) {
          for (let j = 0, colorLength = options.length; j < colorLength; ++j) {
            if (options[j].id === parseInt(id)) return options[j];
          }
        }
      }
    }

    return null;
  };

  const findCollection = (id: number) => {
    if (!collectionData) return null;

    for (let i = 0, length = collectionData.length; i < length; ++i) {
      if (collectionData[i].id === id) return collectionData[i];
    }

    return null;
  };

  const findManufactureYearTitle = (year: any) => {
    const years = FILTERS.manufacture_Years;
    for (let i = 0, length = years.length; i < length; ++i) {
      if (years[i].value.from == year.from && years[i].value.to == year.to)
        return years[i].title;
    }
    return '';
  };

  const setFiltersValue = (param: any) => {
    let filter = [...filters];
    Object.keys(param).map((e) => {
      if (e === 'color') {
        const value = param[e].split(',');
        filter[ONE] = value.map((ele: string, i: number) => {
          const color = findColor(ele);
          const title = color ? color.title : '';
          const value = color ? color.value : '';
          return { id: ele, title, value };
        });
      } else if (e === 'collection_id') {
        const value = param[e].split(',');
        filter[FIVE] = value.map((ele: string, i: number) => {
          const collection = findCollection(parseInt(ele));
          const title = collection ? collection.collection_name : '';
          const value = collection ? collection.collection_name : '';
          return { id: ele, title, value };
        });
      }
      if (e === 'max_price') {
        let price = priceIndex(Number(param[e]))[ZERO];
        filter[2] = [price];
      }
      if (e === 'manufacture_Years') {
        const selectedYears = JSON.parse(param[e]);
        filter[MagicNumber.THREE] = selectedYears.map((year: any) => {
          return {
            title: findManufactureYearTitle(year),
            value: year
          };
        });
      } else if (e === 'to_date') {
        let to_date = new Date(param['to_date']),
          from_date = new Date(param['from_date']);
        filter[MagicNumber.THREE] = [from_date, to_date];
      } else filter[MagicNumber.THREE] = [];
      if (param.sort_by === 'id') {
        filter[MagicNumber.FOUR] = [
          { title: 'Recently Added', value: 'sort_by=id&sort_dir=asc' },
        ];
      } else if (param.sort_by === 'price' && param.sort_dir === 'asc') {
        filter[MagicNumber.FOUR] = [
          { title: 'Price - Low to high', value: 'sort_by=price&sort_dir=asc' },
        ];
      } else if (param.sort_by === 'price' && param.sort_dir === 'desc') {
        filter[MagicNumber.FOUR] = [
          {
            title: 'Price - High to low',
            value: 'sort_by=price&sort_dir=desc',
          },
        ];
      }
    });
    setFilters(filter);
  };

  const splitProdcuts = () => {
    let a = productList.slice(); //Copy array so that the original is not modified
    let i = 0;
    let result: Array<[] | any> = [];
    while (a.length) {
      //Create array if needed
      if (typeof result[i] == 'undefined') {
        result[i] = [];
      }
      if (a) result[i].push(a.shift());
      i++;
      i = (i == 3) ? 0 : i; //Wrap around chunk selector
    }
    return result;
  };

  const fetchMoreData = () => {
    if (!isAll && currentProductPage < totalProductPages) {
      fetchProductList(
        categoryId,
        'SCROLL',
        currentProductPage + ONE,
        currentProductPage + TEN_FIVE,
        searchParam
      );
      setProductLoad(false);
    } else if (totalProductPages === TEN_FIVE) {
      fetchProductList(
        ZERO,
        'SCROLL',
        currentProductPage + ONE,
        totalProductPages + TEN_FIVE,
        searchParam,
        '',
        '',
        true
      );
    } else if (currentProductPage < totalProductPages) {
      let searchParamData = searchParam;
      if (searchParam.includes('&')) {
        searchParamData = searchParam.split('&')[0];
      }
      fetchProductList(
        ZERO,
        'SCROLL',
        currentProductPage + ONE,
        TEN_FIVE,
        searchParamData,
        '',
        '',
        true
      );
    }
    setProductLoad(false);
  };

  const getSearchParam = (data: any, isArt?: boolean) => {
    let searchParam: string[] = [];
    data.forEach((e: any, key: number) => {
      if (
        e.length &&
        (key === ZERO ||
          key === ONE ||
          key === FIVE ||
          (isArt && key === THREE))
      ) {

        if (isArt && key === THREE) {
          const years = e.reduce((a: any, b: any) => {
            const from = b.from ?? b.value.from;
            const to = b.to ?? b.value.to;
            return a + `{'from':${from}, 'to':${to}},`
          }, `manufacture_Years=[`);

          searchParam[key] = `${years.substring(0, years.length - 1)}]`;
        }
        else {
          searchParam[key] = e.reduce(
            (a: any, b: any) => a + b.id + ',',
            `${PARAMKEY[key]}=`
          );
          searchParam[key] = searchParam[key].substring(
            0,
            searchParam[key].length - 1
          );
        }
      } else if (e.length && key === TWO)
        searchParam[
          key
        ] = `max_price=${e[ZERO].max_price}&min_price=${e[ZERO].min_price}`;
      else if (!isArt && e.length && key === THREE) {
        let from, to;

        if (e[ZERO] && e[ZERO].value) {
          from = e[ZERO].value.from;
          to = e[ZERO].value.to;
        } else {
          from = e[ZERO] ? getDate(e[ZERO]) : '';
          to = e[ONE] ? getDate(e[ONE]) : '';
        }

        searchParam[key] = (from && to) ? `manufacture_Years=[{'from':${from}, 'to':${to}}]` : '';

      } else if (e.length && key === FOUR) {
        searchParam[key] = `${e[ZERO].value}`;
      }
    });
    return searchParam.reduce((a: any, b: any) => a + b + '&', '');
  };

  const handleFilterChange = async (
    ele: any,
    key: number,
    index: number = ZERO,
    status: boolean = true,
    isArt?: boolean
  ) => {
    let filter = [...filters];
    if (status) {
      if (key === ONE || key === ZERO || (isArt && key === THREE)) {
        filter[key].push(ele);
        filter[key] = filteredValues(filter[key]);
      } else if (key === FIVE) {
        if (filter[key].length) filter[key][ZERO] = ele;
        else filter[key].push(ele);

        filter[key] = filteredValues(filter[key]);
      } else {
        if (!ele && key === THREE && index === ZERO) filter[key] = [];
        filter[key][index] = ele;
        await setFilters(filter);
      }
      if (
        key === ZERO ||
        key === ONE ||
        key === TWO ||
        key === FOUR ||
        key === FIVE ||
        (isArt && key === THREE)
      ) {
        setProductLoad(true);
        await setFilters(filter);
        setTimeout(() => {
          updateHistory(filter, isArt);
        }, MagicNumber.ONE_TH);
      }
    } else {
      setProductLoad(true);
      await setFilters(filter);
      setTimeout(() => {
        updateHistory(filter);
      }, MagicNumber.ONE_TH);
    }
  };

  const updateHistory = (filter: any, isArt?: boolean) => {
    const param = getSearchParam(filter, isArt).slice(0, -1);

    if (isAll) {
      const search = `${param ? `&${param}` : ''}`;

      history.push({
        pathname: `${history.location.pathname}`,
        search: `search=${options.value}${search}`,
        state: options,
      });
    } else {
      const search = param;

      history.push({
        pathname: `${history.location.pathname}`,
        search: `${search}`,
        state: { id: categoryId, name: productName },
      });
    }

    setProductLoad(false);
  };

  const removeFilters = async (
    e: any,
    index: number,
    key: number,
    status: boolean = true,
    isDate: boolean = false
  ) => {
    e.preventDefault();
    setProductLoad(true);
    let filter = JSON.parse(JSON.stringify(filters));
    if (status) {
      filter[index].splice(key, ONE);
    } else if (isDate) {
      filter[MagicNumber.THREE] = [];
      disableDate(true);
    } else {
      setFilters([[], [], [], [], [], filter[FIVE]]);
      filter = [[], [], [], [], [], filter[FIVE]];
      disableDate(true);
    }
    await setFilters(filter);
    setTimeout(() => {
      updateHistory(filter, categoryId === TWO);
    }, MagicNumber.ONE_TH);
  };

  const handleRedirect = async (slug: string, id: number) => {
    setLoader(true);
    setCommentProductId(id);
    await setProductIds(id).then(() => {
      history.push({
        pathname: `/products/${slug || id}`,
        state: { id: categoryId, name: productName },
      });
    });
  };

  const disableDate = (status: boolean) => {
    setDisabled(status);
  };
  const toggleFilterCollapse = () => {
    openFilterCollapse(!filterCollapse);
  };

  function createMarkup(data: any) {
    return { __html: data };
  }

  const handleTab = (tab: string) => {
    setTabIndex(tab);
  };

  const isOnlyCollectionFilterApplied = () => {
    for (let i = 0, length = filters.length; i < length; ++i) {
      if (i !== FIVE && filters[i].length) return false;
    }
    return true;
  };

  return (
    <>
      {loader && <FullPageLoader />}
      {isAll && from ? (
        <div className='product-listing-collection'>
          <div ref={scrollDiv}></div>
          {!from ? (
            <div className='product-list-container'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/'>Home</Link>
                  </li>
                  <div className='divider-line'>|</div>
                  <li className='breadcrumb-item'>
                    <span>Search</span>
                  </li>
                  <div className='divider-line'>|</div>
                  <li
                    className={`${searchProduct === 'ood' ? 'head-line' : ''
                      } breadcrumb-item active`}
                    aria-current='page'
                  >
                    {searchProduct}
                  </li>
                </ol>
              </nav>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : isCollectionPage() ? null : (
        <Collection
          refValue={scrollDiv}
          onCollectionClicked={(collectionId) => {
            history.push(`all?collection_id=${collectionId}`);
          }}
        />
      )}
      <div className='category-list-header'>
        {!from ? (
          <nav className='list-header'>
            <div className='head-container'>
              {!isAll ? <h2 className='head-title'>Marketplace</h2> : ''}
              {isAll ? <h2
                className='head-title'
                dangerouslySetInnerHTML={createMarkup(
                  isAll
                    ? `<span class='total-count'> Browse</span > ${productList.length} <span class='total-count'>result</span>`
                    : isCollectionPage()
                      ? 'Collectibles'
                      : ''
                )}
              ></h2> : ''}
              {!isAll && !isCollectionPage() && (
                <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                  <a
                    onClick={() => handleTab('collectibles')}
                    className={
                      tabIndex === 'collectibles'
                        ? 'nav-item nav-link active'
                        : 'nav-item nav-link'
                    }
                    id='nav-collectible-tab'
                    data-toggle='tab'
                    href='#nav-collectible'
                    role='tab'
                    aria-controls='nav-collectible'
                    aria-selected='true'
                  >
                    Collectibles
                  </a>
                  <a
                    onClick={() => handleTab('collector')}
                    className={
                      tabIndex === 'collector'
                        ? 'nav-item nav-link active'
                        : 'nav-item nav-link'
                    }
                    id='nav-collector-tab'
                    data-toggle='tab'
                    href='#nav-collector'
                    role='tab'
                    aria-controls='nav-collector'
                    aria-selected='false'
                  >
                    Collector
                  </a>
                </div>
              )}
            </div>
          </nav>
        ) : (
          ''
        )}
        <div className='tab-content' id='nav-tabContent'>
          <div
            className={
              tabIndex === 'collectibles'
                ? 'tab-pane fade show active'
                : 'tab-pane fade'
            }
            id='nav-collectible'
            role='tabpanel'
            aria-labelledby='nav-collectible-tab'
          >
            <div className='collectible-preview'>
              {/* <div className='collectible-left'>
                <div className='category-container'>
                  <div className='filter-button'>
                    <Image
                      src={FILTER_ICON}
                      onClick={toggleFilterCollapse}
                      className='filter-icon'
                      alt='icon'
                    />
                  </div>
                  <div
                    className={`product-filter-wrapper ${filterCollapse && 'open'
                      } `}
                  >
                    <div className='product-filter-wrapper-head'>
                      <span
                        className='icon-close'
                        onClick={toggleFilterCollapse}
                      ></span>
                    </div> */}
                    {/* {categoriesFilters[ZERO] && <Filter property={categoriesFilters[ZERO]?.Options}
                      filters={filters} selFilter={handleFilterChange}
                      name='Category' proKey={ZERO} heading='Filters'
                      isDisabled={isDisabled} setDisabled={disableDate}
                      categoryId={categoryId}
                      removeFilters={removeFilters}
                      toggleFilterCollapse={toggleFilterCollapse}
                    />} */}
                    {/* <Filter
                      property={FILTERS.PRICE}
                      name='Price'
                      filters={filters}
                      removeFilters={removeFilters}
                      selFilter={handleFilterChange}
                      proKey={TWO}
                      isDisabled={isDisabled}
                      setDisabled={disableDate}
                      categoryId={categoryId}
                      toggleFilterCollapse={toggleFilterCollapse}
                    /> */}
                    {/* {allFilters[ZERO] ? (
                      <Filter
                        property={allFilters[ZERO]?.Options}
                        removeFilters={removeFilters}
                        selFilter={handleFilterChange}
                        name='Color'
                        proKey={ONE}
                        isColor={true}
                        isDisabled={isDisabled}
                        setDisabled={disableDate}
                        categoryId={categoryId}
                        toggleFilterCollapse={toggleFilterCollapse}
                      />
                    ) : isLoading ? (
                      <ProductColorSkeleton />
                    ) : (
                      <></>
                    )} */}
                    {/* <Filter
                      name={
                        categoryId !== TWO
                          ? 'Year of Manufacture'
                          : 'Time period'
                      }
                      property={FILTERS.manufacture_Years}
                      filters={filters}
                      selFilter={handleFilterChange}
                      proKey={THREE}
                      isDate={true}
                      removeFilters={removeFilters}
                      isDisabled={isDisabled}
                      setDisabled={disableDate}
                      categoryId={categoryId}
                      toggleFilterCollapse={toggleFilterCollapse}
                    /> */}
                  {/* </div>
                </div>
              </div> */}
              <div className='collectible-right'>
                <div className='dropdown-right-filter'>
                  {/* <div className='btn-group'>
                    <button
                      type='button'
                      className='btn outline-secondary'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      <span className='sort-text'>Sort by:</span>{' '}
                      {filters[FOUR][ZERO] && `${filters[FOUR][ZERO].title} `}
                    </button>
                    <div className='dropdown-menu'>
                      {sortingItms.map((e, index) => (
                        <a
                          className='dropdown-item'
                          onClick={() => handleFilterChange(e, FOUR)}
                        >
                          <div className='form-check'>
                            <div className='radio-custom'>
                              <p className='selected-space'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='flexRadioDefault'
                                  checked={
                                    filters &&
                                    filters[FOUR] &&
                                    filters[FOUR][ZERO] &&
                                    filters[FOUR][ZERO].title === e.title
                                  }
                                  id={`radio${index} `}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor={`radio${index} `}
                                >
                                  {e.title}
                                </label>
                              </p>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div> */}
                </div>
                <div className='apply-filter-section'>
                  <div className=''></div>
                  {isEmpty(filters) ||
                    (isCollectionPage() &&
                      isOnlyCollectionFilterApplied()) ? null : (
                    <div className='clear-button'>
                      <button
                        type='button'
                        className='btn outline-secondary'
                        onClick={(e) => removeFilters(e, ZERO, ZERO, false)}
                      >
                        Clear All
                      </button>
                    </div>
                  )}
                  {filters.map((value: any, index: number) => {
                    if (index === FIVE) return null;
                    if (index !== THREE || categoryId === TWO)
                      return value.map((e: any, key: number) => {
                        return (
                          <FilterBox
                            removeFilters={removeFilters}
                            index={index}
                            status={true}
                            isDate={false}
                            keyDel={key}
                            title={e.from ?? e.title}
                            value={e.from ? e : e.value}
                          />
                        );
                      });
                    else {
                      return (
                        value[0] && value[0].value && (
                          <FilterBox
                            removeFilters={removeFilters}
                            index={index}
                            status={false}
                            keyDel={THREE}
                            isDate={true}
                            title={`${value[0].value.from} to ${value[0].value.to}`}
                          />
                        )
                      );
                    }
                  })}
                </div>
                <div className='product-image-section'>
                  {isProductLoad || isLoading ? (
                    <ProductSkeleton />
                  ) : (
                    <InfiniteScroll
                      dataLength={productList.length}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={<h4></h4>}
                    >
                      {productList.length === ZERO ? (
                        <NoCollectiblesFound />
                      ) : (
                        <>
                          <div className='image-row'>
                            {splitProdcuts().map((product: any) => {
                              return (
                                <div
                                  className='container-half'
                                  style={{ cursor: 'pointer' }}
                                >
                                  {product.map((ele: any, index: number) => {
                                    return (
                                      <div className='image-box'>
                                        <>
                                          <Image
                                            src={
                                              ele.medias && ele.medias.length && ele.medias[ZERO].type === 'video'
                                                ? ele.medias.length && ele.medias[ZERO].filepathThumb
                                                : ele.medias.length && ele.medias[ZERO].filepath
                                            }
                                            quantity={ele && ele.quantity}
                                            parent_product_id={ele && ele.parent_product_id}
                                            key={`pro_${index} `}
                                            onClick={() =>
                                              handleRedirect(ele.slug, ele.id)
                                            }
                                            className='image-product'
                                            alt='products 1'
                                            caption={ele.type}
                                          />
                                        </>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </>
                      )}
                    </InfiniteScroll>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              tabIndex === 'collector'
                ? 'tab-pane fade show active'
                : 'tab-pane fade'
            }
            id='nav-collector'
            role='tabpanel'
            aria-labelledby='nav-collector-tab'
          >
            <div className='collector-preview'>
              <div className='collector-image-section'>
                {tabIndex === 'collector' ? (
                  <ProductCollectors isLoading={isLoading} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
export default wrapperComponent(false, false)(Products);

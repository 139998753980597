import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { FieldError } from '../../Atoms';
import './_text-confirmation-box.scss';
import { useState } from 'react';

type Props = {
  title: string,
  isShowing: boolean,
  validationSchema: any,
  cancelOptionName?: string,
  confirmOptionName?: string,
  onConfirmCB?: (text: string, confirmed: boolean) => Promise<boolean>,
};

type FormValue = {
  description: string
};

export const TextConfirmationBox = (props: Props) => {

  const [waitingForConfirmation, setIsWaitingForConfirmation] = useState<boolean>(false);

  const getInitialPostValues = (): FormValue => {
    return {
      description: ''
    };
  };

  const onSubmit = (values: FormValue, { resetForm }: any) => {
    if (props.onConfirmCB) {
      setIsWaitingForConfirmation(true);
      props.onConfirmCB(values.description, true)
        .then(response => {
          if (response)
            resetForm();
        })
        .finally(() => {
          setIsWaitingForConfirmation(false);
        });
    };
  };

  return (
    <>
      <Modal
        size='sm'
        show={props.isShowing}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog report-comment-dialog modal-dialog-centered'
        contentClassName='remove-modal-content report-post-content'
      >
        <Modal.Header id='report-header'>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={getInitialPostValues()}
          onSubmit={onSubmit}
          validateOnMount={false}
          validationSchema={props.validationSchema}
        >
          {formProps => (
            <Form onSubmit={formProps.handleSubmit}>
              <Modal.Body id='confirmation-body'>
                <div className='type-user'>
                  <textarea name='description'
                    className='form-control'
                    placeholder='Write your reason...'
                    onChange={formProps.handleChange}
                  ></textarea>
                  <FieldError name='description' />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <input type='button' className='btn btn-blank'
                  onClick={() => {
                    props.onConfirmCB?.('', false);
                  }} value={props.cancelOptionName ?? 'Cancel'} />
                <input type='submit' className='btn btn-blank remove'
                  disabled={waitingForConfirmation} onClick={() => { formProps.handleSubmit() }}
                  value={props.confirmOptionName ?? 'Confirm'} />
              </Modal.Footer>
            </Form>
          )}
        </Formik>

      </Modal>
    </>
  );
};

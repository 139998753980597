import { useEffect, useRef, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import {
    BLACK_ICON, LEFT_ARROW, MagicNumber,
    RIGHT_ARROW, PROFILE_ICON, USER_ID,
    Notify_SUCC, COLLECTOR_PRODUCT,
    PRODUCT_COMMENT_RESTRICTIONS, reportContentValidation, FunctionalityType,
    getFunctionalityBlockedMessage, UserType, UserTypeName, PAGE_ROUTES, getParam, SEND_ICON,
    SEND_ICON_BLACK, LOGO, QUOTE_START, QUOTE_END, getDetailsFormate,
    COLLECTOR_PL,
    USER_DATA,
    SIZE_ERR,
    COLOR_ERR
} from '../../../../constants';
import { useStores } from '../../../../models';
import { SignInModal } from './../../Molecules/signInModal/SignInModal';
import { NotifyModal, AccordionCard, ImageGalery, CompleteProfileModal } from '../../Molecules';
import { Loader, Image, FullPageLoader, successToast, errorToast, WishlistButton, FieldError } from '../../Atoms';
import wrapperComponent from '../../Templates/wrapperComponent';
import './Details.scss';
import './DropDetails.scss';
import { SignupModal } from '../../Molecules/signupModal/signupModal';
import { StatusCode } from '../../../../constants/apiStatusCode';
import { DetailSkeleton } from './DetailSkeleton';
import { ViewAllBids } from '../../Molecules/modal/ViewAllBids';
import Skeleton from 'react-loading-skeleton';
import { PlaceBids } from '../../Molecules/modal/PlaceBids';
import { TextConfirmationBox } from '../../Molecules/confirmationBoxModal/TextConfirmationBox';
import { ConfirmationInfos } from '../../../../constants/ConfirmationInfos';
import { ConfirmationBox } from '../../Molecules/confirmationBoxModal/ConfirmationBox';
import { CreatePrivateLink } from '../../Molecules/modal/CreatePrivateLink';
import { CommentItem } from '../../../../models/user-store/user-entity';
import { ResetAuctionButton } from '../../Molecules/modal/ResetAuctionButton';
import DropFaqList from '../faq/DropFaqList';
import DropTime from '../home/DropTime';
import { FeedItemVideo } from '../feedListing/feedItem/FeedItemVideo';
import moment from 'moment';

interface SkuIProp {
    sku: string;
    quantity: string;
}

export const DropDetails = observer(function DropDetails() {
    const base = process.env.REACT_APP_ASSET_HOST;
    const rootStore = useStores();
    const history = useHistory();
    const { TWO, ZERO, ONE } = MagicNumber;
    const { fetchProductDetail, productDetails,
        isDetailLoad, productImage,
        setProfileImage, inStockValue,
        setQuantity, productIdList, emptyProductDetails,
        setProductIds, variantCombination,
        isLoading,
        setProductItem,
        reportComment,
        homeDropFaqList,
        getDropFaq,
    } = rootStore.homeStore;
    const {
        fetchCommentItem,
        commentData,
        postComment,
        replyView,
        totalCount,
        postSubComment,
        fetchSubComment,
        commentShowMore,
        replyShowMore,
    } = rootStore.commentStore;
    const { isLoggedIn, isFunctionalityAllowed, getRoleTypeName } = rootStore.loginStore;
    const { setProductSku, buynow, setProductIdBuyNow, setCartIdBuyNow } = rootStore.cardStore;
    const {
        addProductToCart, getCartItem,
        cartItemList, notifyUser,
        addCartSubtotal, cartSubtotal,
        setCurrentStep,
        setProductCount, setBuyNowAmt
    } = rootStore.cartStore;
    const { forceRemoveContent } = rootStore.feedStreamStore;
    const [status, setProductStatus] = useState<boolean>(false);
    const [viewAll, setViewAll] = useState<boolean>(true);
    const [comments, setComments] = useState<string>('');
    let [count, setImageCount] = useState<number>(ZERO);
    const [isModalOpen, openModal] = useState<boolean>(false);
    const [signInModalOpen, setSignInModalOpen] = useState<boolean>(false);
    const [isProfileOpen, openProfileModal] = useState<boolean>(false);
    const [isSignUpOpen, openSignUpModal] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [productLoader, setProductLoader] = useState<boolean>(false);
    const [buyProductLoader, setBuyProductLoader] = useState<boolean>(false);
    const [isEmailBtn, disableEmailBtn] = useState<boolean>(false);
    const [reply_text, setReplyText] = useState<string>('');
    const [userImage, setUserImage] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [replyTotal, setReplyTotal] = useState<number>(4);
    const [colorVariant, setColorVariant] = useState<any>(null);
    const [sizeVariant, setSizeVariant] = useState<any>(null);
    const [colorVariErr, setColorErr] = useState<boolean>(false);
    const [sizeVariErr, setSizeErr] = useState<boolean>(false);
    const [variAvail, setAvil] = useState<boolean>(false);
    const [wislistVariant, setWislistVariant] = useState<boolean>(true);
    const [colorVariantList, setColorVariantList] = useState<any>([]);
    const [sizeVariantList, setSizeVariantList] = useState<any>([]);
    const [dropData, setDropData] = useState<any>();
    const [mediaList, setMediatList] = useState<any>([]);
    const [editFlag, setEditFlag] = useState<string>('');
    const [openBidsModel, setOpenBidsModel] = useState<boolean>(false);
    const [openCreatePrivateModel, setOpenCreatePrivateModel] = useState<boolean>(false);
    const [openPlaceBidsModel, setOpenPlaceBidsModel] = useState<boolean>(false);
    const [openResetPopup, setResetPopup] = useState<boolean>(false);
    const { getAllBids, productBids, isBidsLoading, addBid, saveBid, setHighestBid } = rootStore.userStore;

    const [isReportingPost, setIsReportingPost] = useState<boolean>(false);
    const [isShowingReportCommentConfirmation, setShowingReportCommentConfirmation] = useState<boolean>(false);
    const [reporteeUserId, setReporteeUserId] = useState<number>(MagicNumber.ZERO);
    const [reportedContent, setReportedContent] = useState<string>('');
    const [currentConfirmationInfo, setCurrentConfirmationInfo] = useState<any>({});

    const [isShowingDeletingCommentConfirmation, setShowingDeletingCommentConfirmation] = useState<boolean>(false);
    const [deletingCommentID, setDeletingCommentID] = useState<string>('');
    const [deletingCommentUserID, setDeletingCommentUserID] = useState<string>('');
    const [isDeletingComment, setIsDeletingComment] = useState<boolean>(false);
    const [checkDetailsType, setCheckDetailType] = useState<any>();
    const analytics = require('react-segment');
    const [timeCount, setTimeCount] = useState<string>();
    const [dropStatus, setDropStatus] = useState<boolean>(false);
    const componentWillUnmount = useRef(false);

    const dateCountDownTimer = (data: any) => {
        let upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
        const timer = () => {
            let diffTime = new Date(upgradeTime).valueOf() - new Date().valueOf();
            let days = diffTime / (MagicNumber.TWENTYFR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONE_TH);
            let hours = (days % MagicNumber.ONE) * MagicNumber.TWENTYFR;
            let minutes = (hours % MagicNumber.ONE) * MagicNumber.SIXTY;
            let secs = (minutes % MagicNumber.ONE) * MagicNumber.SIXTY;
            [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
            let hour_;
            let day_;
            let minute_;
            let secs_;
            if (days > MagicNumber.ZERO) {
                day_ = Math.abs(days) > MagicNumber.NINE ? Math.abs(days) : `0${Math.abs(days)}`;
            }
            if (hours > MagicNumber.ZERO) {
                hour_ = Math.abs(hours) > MagicNumber.NINE ? Math.abs(hours) : `0${Math.abs(hours)}`;
            }
            if (minutes > MagicNumber.ZERO) {
                minute_ = Math.abs(minutes) > MagicNumber.NINE ? Math.abs(minutes) : `0${Math.abs(minutes)}`;
            }
            if (secs > MagicNumber.ZERO) {
                secs_ = Math.abs(secs) > MagicNumber.NINE ? Math.abs(secs) : `0${Math.abs(secs)}`;
            }
            if (minutes === MagicNumber.ZERO && secs > MagicNumber.ZERO) {
                minute_ = `01`;
            }
            const timeDate = `${day_ ? (day_).toString() : '00'}Days : ${hour_ ? (hour_).toString() : '00'}:${minute_ ? (minute_).toString() : '00'}:${secs_ ? (secs_).toString() : '00'}`;
            setTimeCount(timeDate);
            if (Math.sign(days) === -MagicNumber.ONE) {
                setDropStatus(true);
                clearInterval(countdownTimer);
            }
        };
        var countdownTimer = setInterval(() => {
            upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
            timer();
        }, MagicNumber.ONE_TH);
    };

    const getDetails = async (path: any) => {
        getDropFaq();
        const params = !isNaN(path) && Number.isInteger(parseFloat(path));
        let isVidInPath = getParam(history.location.search).v;
        const isNan = params ? parseInt(path) : path;
        const idOrVid = isVidInPath ? isVidInPath : isNan;
        await fetchProductDetail(idOrVid, false, isVidInPath ? true : false).then(async (details) => {
            await setProductIds().then(async (id: string) => {
                await fetchCommentItem(id, MagicNumber.FOUR, MagicNumber.ONE);
                if (details && details.type === 'auction') {
                    setCheckDetailType(details);
                    await getAllBids(id, MagicNumber.ONE, MagicNumber.TWENTY);
                }
            });
        });
        setLoader(false);
    };

    useEffect(() => {
        if (colorVariant || sizeVariant) {
            const data = getFilteredValue(productDetails);
            if (data && data.length) {
                let medias = [...mediaList];
                medias[medias.length - ONE] = data[ZERO].media[ZERO];
                setImageCount(medias.length - ONE);
                setMediatList(medias);
                setQuantity(parseInt(data[ZERO].quantity));
                data[ZERO].media.length && setProfileImage(toJS(data[ZERO].media[ZERO]));
            } else {
                setQuantity(ZERO);
            }
        }
    }, [productDetails, colorVariant, sizeVariant]);

    useEffect(() => {
        if (productDetails && variantCombination) {
            if (productDetails.drop_data) {
                const dropDataTime = {
                    sale_start_date: productDetails.drop_data.sale_start_date,
                    sale_end_date: productDetails.drop_data.sale_end_date
                };
                dateCountDownTimer(dropDataTime);
                setDropData(dropDataTime);
            }
            if (productDetails.variantCombinations.length === TWO) {
                setSizeVariantList(getUniquieVariants('Size'));
                setSizeVariant(getUniquieVariants('Size')[ZERO]);
            } else if (productDetails.variantCombinations.length) {
                if (productDetails.variantCombinations[ZERO].includes('Color')) {
                    setColorVariantList(getUniquieVariants('Color'));
                } else {
                    const data = getUniquieVariants(productDetails.variantCombinations[ZERO], true);
                    setSizeVariantList(data);
                    setSizeVariant(data[ZERO]);
                }
            }
        }
    }, [productDetails, variantCombination]);

    useEffect(() => {
        if (productDetails && productDetails.variantCombinations.length === TWO) {
            setColorVariantList(getUniquieVariants('Color'));
        }
    }, [sizeVariant]);

    useEffect(() => {
        emptyStateValues();
        let path = history.location.pathname.split('/');
        getDetails(path[TWO]);
        const pic = Cookies.get('user_profile') || '';
        const user_id = Cookies.get('user_id') || '0';
        setUserId(user_id ? user_id : '0');
        setUserImage(pic);
    }, []);

    useEffect(() => {
        if (productDetails) {
            setProductStatus(true);
            let medias: Array<any> = [];
            if (productDetails.variants.length) {
                medias.push(productDetails.variants[ZERO].media[ZERO]);
            };
            medias = [...productDetails.medias, ...medias];
            setMediatList(medias);
        }
    }, [productDetails]);

    // This is componentWillUnmount
    useEffect(() => {
        return () => {
            componentWillUnmount.current = true;
            emptyProductDetails();
        };
    }, []);

    const emptyStateValues = () => {
        emptyProductDetails();
        setColorVariantList([]);
        setSizeVariantList([]);
        setProfileImage(null);
        setColorVariant(null);
        setSizeVariant(null);
        setColorErr(false);
        setSizeErr(false);
    };

    const getUniquieVariants = (key: string, status?: boolean) => {
        if (!productDetails)
            return [];
        let combination: Array<string | any> = productDetails.variants.map((e: any) => {
            if (key === 'Size') {
                return e[key];
            } else if (!status && (sizeVariant === e.Size)) {
                return e.Color_value;
            } else if (status) return e.Color_value;
        });
        let uniqueChars: Array<string | any> = [];
        combination.forEach((c) => {
            if (!uniqueChars.includes(c) && c !== (undefined)) {
                uniqueChars.push(c);
            }
        });
        if ((colorVariant && !uniqueChars.includes(colorVariant)) || (!colorVariant && key === 'Color'))
            setColorVariant(uniqueChars.sort()[ZERO]);
        return uniqueChars.sort();
    };

    const setPageCount = (key: string) => {
        if (productDetails) {
            if (key === 'NEXT' && mediaList.length - MagicNumber.ONE > count) {
                count = count + MagicNumber.ONE;
            } else if (key === 'PREV' && count > ZERO) {
                count = count - MagicNumber.ONE;
            }
            if (mediaList.length - MagicNumber.ONE >= count) {
                setProfileImage(toJS(mediaList[count]));
                setImageCount(count);
            }
        }
    };

    const getContent = () => {
        return isDetailLoad ?
            <div className='detail-container'>No product found</div> :
            <DetailSkeleton />;
    };

    const getMonth = (date: any) => {
        const datePast = new Date(date);
        const dateNow = new Date();
        let months = (dateNow.getFullYear() - datePast.getFullYear()) * MagicNumber.TWELVE;
        months -= datePast.getMonth();
        months += dateNow.getMonth();
        return months <= MagicNumber.ZERO ? MagicNumber.ZERO : months;
    };

    const getYears = (date: any) => {
        const datePast = new Date(date);
        const dateNow = new Date();
        const years = (dateNow.getFullYear() - datePast.getFullYear());
        return years;
    };

    const validateCommentDate = (date: any) => {
        let datePast = new Date(date).getTime();
        let dateNow = new Date().getTime();
        let diffInMilliSeconds = Math.abs(dateNow - datePast) / MagicNumber.ONE_TH;
        const minutes = Math.floor(diffInMilliSeconds / MagicNumber.SIXTY) % MagicNumber.SIXTY;
        diffInMilliSeconds -= minutes * MagicNumber.SIXTY;
        const hours = Math.floor(diffInMilliSeconds / MagicNumber.THIRTY_SIX_TH) % MagicNumber.TWENTYFR;
        diffInMilliSeconds -= hours * MagicNumber.THIRTY_SIX_TH;
        const days = Math.floor(diffInMilliSeconds / MagicNumber.EIGHTY_SIX_FR_TH);
        diffInMilliSeconds -= days * MagicNumber.EIGHTY_SIX_FR_TH;
        const months = getMonth(date);
        const years = getYears(date);
        if (years) {
            return years + `${years === MagicNumber.ONE ? ' year ago' : ' years ago'}`;
        } else if (months) {
            return months + `${months === MagicNumber.ONE ? ' month ago' : ' months ago'}`;
        } else if (days) {
            return days + `${days === MagicNumber.ONE ? ' day ago' : ' days ago'}`;
        } else if (hours) {
            return hours + `${hours === MagicNumber.ONE ? ' hour ago' : ' hours ago'}`;
        } else if (minutes) {
            return minutes + `${minutes === MagicNumber.ONE ? ' min ago' : ' mins ago'}`;
        } else {
            return `few seconds ago`;
        }
    };

    const addComment = async () => {
        const user = Cookie.get('token');
        if (user) {
            if (comments && comments !== '') {
                setLoader(true);
                const user_id = Cookies.get('user_id') || '';
                const data = {
                    user_id: parseInt(user_id),
                    product_id: +productIdList,
                    comment: comments
                };
                await postComment(data);
                await fetchCommentItem(productIdList.toString(), MagicNumber.FOUR, MagicNumber.ONE);
                setComments('');
                setLoader(false);
                setViewAll(true);
                analytics.default.track('Comment Made');
            }
        } else {
            setSignInModalOpen(true);
        }
    };

    const handleInputChange = (data: any) => {
        setComments(data.target.value);
    };

    const viewAllComments = async () => {
        setLoader(true);
        setReplyTotal(totalCount);
        await fetchCommentItem(productIdList.toString(), totalCount, MagicNumber.ONE);
        setViewAll(false);
        setLoader(false);
    };


    const getFilteredValue = (product: any) => {
        if (product && product.variants) {
            return toJS(product.variants).filter((e: any) => {
                if (product.variantCombinations.length === MagicNumber.ONE) {
                    if (colorVariant && (colorVariant === e.Color_value)) {
                        return e.sku;
                    } else if (sizeVariant && (sizeVariant === e.Size)) {
                        return e.sku;
                    }
                } else {
                    if (colorVariant === e.Color_value && sizeVariant === e.Size)
                        return e.sku;
                };
            });
        }
    };

    const addProduct = async (productData: any, status?: boolean) => {
        const cartItemData = {
            sku: productData,
            user_id: Number(Cookie.get(USER_ID))
        };
        setProductLoader(true);
        await addProductToCart(cartItemData);
        await getCartItem(Number(Cookie.get(USER_ID))).then((res: any) => {
            if (res && status) {
                setAvil(true);
                fetchProductDetail(productIdList, true);
            } else setAvil(true);
        });
        setProductLoader(false);
    };

    const enterPressed = (event: any) => {
        const code = event.keyCode || event.which;
        if (code === MagicNumber.THIRTEEN) {
            addComment();
        }
    };

    const onReply = (commentId: number) => {
        replyView(commentId);
    };

    const replyText = (event: any) => {
        
        setReplyText(event.target.value);
    };

    const postReply = async (parentId: number) => {
        
        const user = Cookie.get('token');
        if (user) {
            if (reply_text !== '') {
                setLoader(true);
                const user_id = Cookies.get('user_id') || '';
                const data = {
                    user_id: parseInt(user_id),
                    product_id: +productIdList,
                    comment: reply_text,
                    parent_id: parentId
                };
                await postSubComment(data);
                await fetchCommentItem(productIdList.toString(), replyTotal, MagicNumber.ONE);
                await fetchSubComment(parentId, MagicNumber.ONE, MagicNumber.FOUR, productIdList, false);
                setLoader(false);
            }
        } else {
            setSignInModalOpen(true);
        }
    };

    const enterPressedReply = (event: any, commentId: number) => {
        
        const code = event.keyCode || event.which;
        if (code === MagicNumber.THIRTEEN) {
            postReply(commentId);
        }
    };

    const plusMoreReply = (comment: any) => {
        return comment.sub_profilimage.length > MagicNumber.ONE ? `+${comment.sub_profilimage.length - 1}` : ' ';
    };

    const showRepliedText = (index: number, comment: any) => {
        const a = comment.sub_profilimage.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.id === v.id)) === i);
        return a.length < MagicNumber.THREE ? a.length === index + 1 ? true : false : index === MagicNumber.TWO;
    };

    const handleNotifyUser = async (values: any) => {
        if (Cookie.get(USER_ID))
            values.user_id = Cookie.get(USER_ID);
        values.entity_id = productIdList;
        values.entity_type = 'product';
        await notifyUser(values).then(res => {
            if (res.data.statusCode === MagicNumber.TWO_H) {
                successToast(Notify_SUCC);
                openModal(false);
            };
        });
    };


    const showReplies = async (id: number) => {
        setLoader(true);
        await fetchSubComment(id, MagicNumber.ONE, MagicNumber.FOUR, productIdList, true);
        setLoader(false);
    };

    const buyNow = async (sku: string | null, sale_price: string | null) => {
        setBuyProductLoader(true);
        const buyNowData = {
            sku: sku,
            quantity: MagicNumber.ONE
        };
        await buynow(buyNowData).then((data: any) => {
            if (data.kind === StatusCode.KIND && data && data.response && data.response.data && data.response.data.data) {
                const curency_subtotal = data.response.data.data.curency_subtotal;
                const curency_grand_total = data.response.data.data.curency_grand_total;
                const tax_amount = data.response.data.data.tax_total ? data.response.data.data.tax_total : 0;
                addCartSubtotal(curency_subtotal, curency_grand_total, tax_amount);
                setCartIdBuyNow(data.response.data.data.id);
            }
        });
        setProductCount();
        setBuyProductLoader(false);
        setProductSku(sku);
        setCurrentStep(MagicNumber.ONE);
        setTimeout(() => {
            history.push(`/cart/buynow`);
        });
    };

    const onReportComment = async (reason: string) => {
        if (isReportingPost || !productDetails) return;

        const path = history.location.pathname.split('/');
        if (!path || path.length < MagicNumber.THREE)
            return;

        setIsReportingPost(true);
        await reportComment(reporteeUserId, reportedContent, path[TWO], reason)
            .then(response => {
                successToast(response);
            })
            .catch(error => {
                errorToast(error);
                throw error;
            })
            .finally(() => {
                setIsReportingPost(false);
            });
    };

    const onDeleteComment = async () => {
        if (isDeletingComment || !productDetails) return;

        setIsDeletingComment(true);
        if (isFunctionalityAllowed(FunctionalityType.DeleteComment)) {
            forceRemoveContent(deletingCommentUserID, deletingCommentID.toString(), 'product',
                productDetails.name ? productDetails.name.toString() : '')
                .then(response => {
                    emptyStateValues();
                    let path = history.location.pathname.split('/');
                    getDetails(path[TWO]);
                })
                .catch(error => {
                    if (error && error.error && error.error.detail)
                        errorToast(error.error.detail);
                })
                .finally(() => {
                    setIsDeletingComment(false);
                });
        }
    };

    const toggleBidsModel = () => {
        setOpenBidsModel(!openBidsModel);
    };

    const togglePlaceBidsModel = () => {
        setOpenPlaceBidsModel(!openPlaceBidsModel);
    };

    const toggleResetModel = () => {
        setResetPopup(!openResetPopup);
    };
    const togglePrivateModel = (flag = '') => {
        if (openCreatePrivateModel) {
            let path = history.location.pathname.split('/');
            getDetails(path[TWO]);
        }
        setOpenCreatePrivateModel(!openCreatePrivateModel);
        setEditFlag(flag);
    };

    const setSignInModalOpens = async () => {
        setSignInModalOpen(false);
        await getAllBids(productIdList, MagicNumber.ONE, MagicNumber.TWENTY);
        emptyStateValues();
        let path = history.location.pathname.split('/');
        getDetails(path[TWO]);
        const pic = Cookies.get('user_profile') || '';
        const user_id = Cookies.get('user_id') || '0';
        setUserId(user_id ? user_id : '0');
        setUserImage(pic);
    };

    const isCommentAllowedForReporting = (commentItem: any) => {

        const userId = Cookie.get(USER_ID);
        return !(commentItem.user_role === UserType[UserType.admin] || (userId && commentItem.user_id === parseInt(userId)));
    };

    const handleBack = () => {
        emptyStateValues();
        if (history && history.length < MagicNumber.FIVE) {
            history.push('/');
        } else {
            history.goBack();
        }
    };

    const handleViewHistory = () => {
        if (!isLoggedIn) {
            setSignInModalOpen(true);
            return false;
        }
        setOpenBidsModel(true);
    };

    const placeBidHandle = (product: any) => {
        if (!isFunctionalityAllowed(FunctionalityType.PlaceBid)) {
            errorToast(getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user));
            return;
        }

        const user = Cookies.get('token');
        if (user) {
            if (productBids && productBids.highestBid) {
                addBid(productBids && productBids.auctionData && productBids.auctionData.min_bid, productBids.highestBid);
                saveBid(productBids && productBids.auctionData && productBids.auctionData.min_bid, productBids.highestBid);
                setHighestBid(productBids && productBids.currencyHighestBid);
            } else if (productBids) {
                addBid(productBids && productBids.auctionData && productBids.auctionData.base_price, 0);
                saveBid(productBids && productBids.auctionData && productBids.auctionData.base_price, 0);
                setHighestBid('$0.00');
            }
            setProductItem(product);
            setOpenPlaceBidsModel(true);
        } else {
            setSignInModalOpen(true);
        }
    };

    const getProductSku = (product: any) => {
        if (!sizeVariant && product.variantCombinations.includes('Size'))
            setSizeErr(true);
        else if (!colorVariant && product.variantCombinations.includes('Color'))
            setColorErr(true);
        else {
            let sktValue: [SkuIProp] = getFilteredValue(product);
            addProduct(sktValue[ZERO].sku, true);
        }
    };

    const getProductSkuBuy = (product: any) => {
        if (!sizeVariant && product.variantCombinations.includes('Size'))
            setSizeErr(true);
        else if (!colorVariant && product.variantCombinations.includes('Color'))
            setColorErr(true);
        else {
            let sktValue: [SkuIProp] = getFilteredValue(product);
            buyNow(sktValue[ZERO].sku, product.currency_price);
        }
    };

    const addProductForBuy = async (productData: any, product: any) => {
        setProductIdBuyNow(product.id);
        setBuyNowAmt(product.price);
        if (!isLoggedIn) {
            setSignInModalOpen(!signInModalOpen);
        }
        else if (!isFunctionalityAllowed(FunctionalityType.BuyProduct)) {
            errorToast(getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user));
        }
        else if (product.variantCombinations.length) {
            getProductSkuBuy(product);
        } else {
            buyNow(productData, product.currency_price);
        }
    };

    const addProductIntoCart = async (productData: any, product: any) => {
        if (!isLoggedIn) {
            setSignInModalOpen(!signInModalOpen);
        }
        else if (!isFunctionalityAllowed(FunctionalityType.AddToCart)) {
            errorToast(getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user));
        }
        else if (product.variantCombinations.length) {
            getProductSku(product);
        } else {
            addProduct(productData);
        }
    };

    const checkVarientValidity = () => {
        if (!productDetails)
            return false;
        if (!productDetails.variantCombinations.length)
            return isLoggedIn && productDetails.in_cart;
        const { variants, variantCombinations } = productDetails;
        let value: boolean = toJS(variants).some((e: any) => {
            if (variantCombinations.length === MagicNumber.ONE) {
                if (colorVariant && (colorVariant === e.Color_value) && e.in_cart) {
                    return true;
                } else if (sizeVariant && (sizeVariant === e.Size) && e.in_cart) {
                    return true;
                }
            } else {
                if (colorVariant === e.Color_value && sizeVariant === e.Size && e.in_cart)
                    return true;
            }
        });
        return value;
    };

    const viewCart = () => {
        if (!isFunctionalityAllowed(FunctionalityType.AddToCart)) {
            errorToast(getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user));
            return;
        }
        history.push(PAGE_ROUTES.CART);
    };

    const executeScroll = () => {
        history.push('/');
    };

    const checkAvailabitity = (data: string | null) => {
        if (!data) return '';
        let currDate = new Date().toISOString();
        return data > currDate;
    };

    const handleNotifyModal = () => {
        if (!isFunctionalityAllowed(FunctionalityType.NotifyMe)) {
            errorToast(getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user));
            return;
        }

        if (Cookie.get(USER_DATA)) {
            const jsonUserData: any = Cookie.get(USER_DATA);
            const userData = JSON.parse(jsonUserData);
            handleNotifyUser({ email: userData.email });
        } else openModal(true);
    };

    const getDate = (data: string | null) => {
        if (!data) return '';
        let date = new Date(data);
        const format = moment(date).format('MMM, Do YYYY, hh:mm A');
        const splits = format.split(',');
        return `${splits[0]}, ${splits[1]} at ${splits[2]}`;
    };

    const auctionPrice = () => {
        if ((productDetails && productDetails.auction && productDetails.auction.highestBid) && (productDetails && productDetails.auction && productDetails.auction.currency_min_bid)) {
            return `${productDetails.auction.currencyHighestBid} + ${productDetails.auction.currency_min_bid}`;
        } else if (productDetails) {
            return `${productDetails && productDetails.auction && productDetails.auction.currency_base_price}`;
        }
    };

    return (<>
        {loader && <FullPageLoader />}
        <div className='product-detail-page drop-product-page'>
            <div className='product-detail-container'>
                <div className='back-button' onClick={() => handleBack()}>
                    <Image src={BLACK_ICON} alt='back' className='back-icon' />
                    <p className='back-text' >Back</p>
                </div>
                <div className='responsive-bid-section'>
                    {!isBidsLoading && productDetails && productDetails.type === 'auction' && productDetails && productDetails.auction && productDetails.auction.bidCount ? <div className='bid-section'>
                        <div className='bid-container'>
                            <div className='left-wrap'>
                                <div className='content-box'>
                                    <p className='title'>No. of Bids</p>
                                    <h2 className='status'>{productDetails && productDetails.auction && productDetails.auction.bidCount}</h2>
                                </div>
                                <div className='content-box center-box'>
                                    <p className='title'>Highest Bid
                                        <div className='highlight-icon'></div>
                                    </p>
                                    <h2 className='status'>{productDetails && productDetails.auction && productDetails.auction.currencyHighestBid}</h2>
                                </div>
                            </div>
                            <div className='content-box view-bids'>
                                {productBids && productBids.total ? <button type='button' onClick={() => setOpenBidsModel(true)} className='btn outline-primary'>View All Bids</button> : ''}
                            </div>
                        </div>
                    </div> : ''}
                </div>
                <div className='detail-container'>
                    {status && productDetails ? <>
                        <div className='detail-left'>
                            <div className='container-left'>
                                <div className='product-carousel-section'>
                                    <div className='preview-carousel'>
                                        <div id='sportscontrol' className='carousel' data-interval='false'>
                                            <div className='carousel-inner'>
                                                {mediaList.length ? mediaList.map((e: any, index: number) =>
                                                    <div key={index} className={`carousel-item ${count == index && 'active'}`}>
                                                        <div className='carousel-box'>
                                                            <div className='product-item'>
                                                                <ImageGalery data={toJS(productImage)} classValue='product-image' isZoom />
                                                            </div>
                                                        </div>
                                                    </div>) : <div className='carousel-item active'>
                                                    <div className='carousel-box'>
                                                        <div className='product-item'>
                                                            <ImageGalery data={null} classValue='product-image' />
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                            {mediaList.length > MagicNumber.ONE && count !== MagicNumber.ZERO ? <a className='carousel-control-prev' onClick={() => setPageCount('PREV')} >
                                                <Image src={LEFT_ARROW} alt='arrow' className='icon-image' />
                                                <span className='sr-only'>Previous</span>
                                            </a> : ''}
                                            {mediaList.length > MagicNumber.ONE && count + MagicNumber.ONE !== mediaList.length ? <a className='carousel-control-next' onClick={() => setPageCount('NEXT')}>
                                                <Image src={RIGHT_ARROW} alt='arrow' className='icon-image' />
                                                <span className='sr-only'>Next</span>
                                            </a> : ''}
                                        </div>
                                        <div className='select-product-section thumb-product-selection'>
                                            {mediaList.map((e: any, index: number) =>
                                                <div key={`box_${index}`}
                                                    className={index == count ? 'product-box active-product' : 'product-box'}
                                                    onClick={() => { setImageCount(index); setProfileImage(toJS(e)) }}>
                                                    <ImageGalery data={e} classValue='select-product-img' />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {productDetails ? <div className='detail-right'>
                            {!productDetails.drop_data ? <div className='right-column'>
                                <div className='notification-icon'>
                                    {parseInt(userId) !== (productDetails && productDetails.user_id) && productDetails && productDetails.type !== 'auction' ?
                                        <WishlistButton
                                            product={productDetails}
                                            colorVariant={colorVariant}
                                            sizeVariant={sizeVariant}
                                            className={'wish-image'}
                                            setSizeErr={() => { setSizeErr(true) }}
                                            setColorErr={() => { setColorErr(true) }}
                                            chnageWislistVariant={wislistVariant}
                                            toggleWislistVariant={() => { setWislistVariant(true) }}
                                        /> : ''}
                                </div>
                                {/* for responsive icon */}
                                <div className='responsive-notification-icon'>
                                    <div className='notification-icon'>
                                        {parseInt(userId) !== (productDetails && productDetails.user_id) && productDetails && productDetails.type !== 'auction' ? <WishlistButton
                                            product={productDetails}
                                            colorVariant={colorVariant}
                                            sizeVariant={sizeVariant}
                                            className={'wish-image'}
                                            setSizeErr={() => { setSizeErr(true) }}
                                            setColorErr={() => { setColorErr(true) }}
                                            chnageWislistVariant={wislistVariant}
                                            toggleWislistVariant={() => { setWislistVariant(true) }}
                                        /> : ''}
                                    </div>
                                </div>
                            </div> : ''}
                            <div className='product-detail'>
                                <p className='detail-text'>{productDetails.drop_data && productDetails.drop_data.title}</p>
                                <h2 className='product-name'>{productDetails.name}</h2>
                                {dropStatus && productDetails.type === 'auction' ? <h3 className='expired-text'>Drop Expired</h3> : ''}
                                {productDetails.type === 'auction' ? <>

                                    {checkAvailabitity(productDetails && productDetails.auction && productDetails.auction.start_date_time) ?
                                        <>
                                            {productDetails && productDetails.type === 'auction' && (productDetails && productDetails.auction && productDetails.auction.status === 'pending') ?
                                                <p>Auction Starts On</p> : ''}
                                            <h4 className='available-product  auction-product'>
                                                {`${getDate(productDetails && productDetails.auction && productDetails.auction.start_date_time)}`}</h4>
                                        </> :
                                        <>
                                            <div className='time-bid-section'>
                                                {productDetails.auction && productDetails.auction.status === 'pending' && !dropStatus && timeCount ? <h3 className='time-text'>Time Remaining: {timeCount ? timeCount : <Skeleton style={{ height: '30px' }} />}</h3>
                                                    : ''}
                                                <h3 className='bid-text'>Current Bid: {productDetails.auction ? productDetails.auction.currencyHighestBid : '$0.00'}
                                                    {isLoggedIn && productDetails.auction && productDetails.auction.highestBidUserid === parseInt(userId) && productDetails.auction.highestBid ? <button type='button' className='btn primary'>Your Bid</button> : ''}
                                                </h3>
                                            </div>
                                            <div className='product-bid-button'>
                                                <button type='button' onClick={() => handleViewHistory()} className='btn outline-secondary'>BID HISTORY</button>
                                                {productDetails.auction && productDetails.auction.status === 'pending' && (!productDetails.drop_data || timeCount && !dropStatus) ? <button type='button'
                                                    className='btn secondary'
                                                    disabled={productDetails.auction ? productDetails.auction.status !== 'pending' : false}
                                                    onClick={() => placeBidHandle(productDetails)}
                                                >PLACE BID</button> : ''}
                                            </div>
                                        </>
                                    }
                                </> : <div className='product-bid-button submit-button'>
                                    <div className='time-bid-section'>
                                        {productDetails ? <h3 className='sale-text'>
                                            {productDetails.currency_price !== '' ? <><span className='small-dollar'>$</span><span>
                                                {(productDetails.currency_price).split('$')[1]}</span> </> :
                                                <><span className='small-dollar'>$</span><span className='small-dollar'>0</span></>}
                                        </h3> : ''}
                                    </div>

                                    <div className='size-section'>
                                        {sizeVariantList.length ? <>
                                            <p className='size-text'>Size</p>
                                            {sizeVariErr && <FieldError message={SIZE_ERR} />}
                                            <div className='select-size'>
                                                {sizeVariantList.map((ele: any) =>
                                                    <button type='button'
                                                        onClick={() => { setSizeVariant(ele); setSizeErr(false); setAvil(false); setWislistVariant(false); }}
                                                        className={`btn outline-secondary ${ele === sizeVariant && 'active-size'}`}>{ele}</button>
                                                )}
                                            </div>
                                        </> : ''}
                                        {colorVariantList.length ? <div className='color-section'>
                                            <p className='color-text'>Color</p>
                                            {colorVariErr && <FieldError message={COLOR_ERR} />}
                                            <div className='select-color'>
                                                {colorVariantList.map((ele: any, index: number) => <>
                                                    <div className='color-detail'>
                                                        <div className={`color-box ${ele === colorVariant && 'active-color'}`}
                                                            onClick={() => { setColorVariant(ele); setColorErr(false); setAvil(false); setWislistVariant(false); }} style={{ backgroundColor: ele }} key={index}></div>
                                                        <p key={`k` + index} className={`color-name ${ele === colorVariant && 'active-color'}`}>{ele}</p>
                                                    </div>
                                                </>)}
                                            </div>
                                        </div> : ''}
                                    </div>
                                    {/* {inStockValue && !dropStatus ? <div>
                                        {(variAvail || checkVarientValidity()) ? (
                                        <a onClick={viewCart} type='button' className='btn primary'>View Cart</a>
                                        )
                                        : (productDetails && productDetails.auction === null && (productDetails && !productDetails.is_private))
                                                || (productDetails && productDetails.auction && productDetails.auction.status === 'pending') ?
                                                <button type='button' disabled={inStockValue === ZERO}
                                                    onClick={() => addProductIntoCart(productDetails.sku, productDetails)}
                                                    className='btn outline-secondary'>Add to Cart
                                                    {productLoader ? <span className='loading' /> : ''}
                                                </button>
                                                 : ''}
                                        <button type='button'
                                            className='btn secondary'
                                            disabled={(inStockValue === ZERO) || ((productDetails && productDetails.auction && productDetails.auction.status === 'completed') || false)}
                                            onClick={() => addProductForBuy(productDetails.sku, productDetails)}>
                                            BUY NOW {buyProductLoader ? <span className='loading' /> : ''}
                                        </button>
                                    </div> : ''} */}
                                    {parseInt(userId) !== (productDetails && productDetails.user_id) && inStockValue && productDetails.type !== 'auction' || (productDetails.user_id !== parseInt(userId) &&
                                        productDetails.is_private && productDetails.price !== '0') && inStockValue ? <div className='submit-button buy-submit-btn'>
                                        {checkAvailabitity(productDetails.availability) ?
                                            <button className='btn primary' data-toggle='modal' data-target='#exampleModalCenter' onClick={() => handleNotifyModal()}>Notify Me</button> : <>
                                                <button type='button' disabled={(inStockValue === ZERO) || ((productDetails && productDetails.auction && productDetails.auction.status === 'completed') || false)}
                                                    onClick={() => addProductForBuy(productDetails.sku, productDetails)}
                                                    className='btn secondary'>Buy Now {buyProductLoader ? <span className='loading' /> : ''}</button>
                                                {(variAvail || checkVarientValidity()) ? (<a onClick={viewCart} href='' className='btn outline-secondary'>View Cart</a>)
                                                    : (productDetails && productDetails.auction === null && (productDetails && !productDetails.is_private)) || (productDetails && productDetails.auction &&
                                                        productDetails.auction.status === 'pending') ? (<button type='button' disabled={inStockValue === ZERO}
                                                            onClick={() => addProductIntoCart(productDetails.sku, productDetails)} className='btn outline-secondary'>Add to Cart
                                                            {productLoader ? <span className='loading' /> : ''}</button>) : ''}
                                            </>}
                                    </div> : ''}
                                    {!inStockValue ? <button type='button' className='btn primary stock-button'>Out of Stock</button> : ''}
                                </div>}
                            </div>
                        </div> : ''}</> : getContent()}
                </div>
                <div className='content-detail-section'>
                    {productDetails && productDetails.story ? <div className='detail-card'>
                        {/* <div className='product-header'>
                            <h5>Product Story</h5>
                        </div> */}
                        <div className='product-body'>
                            <AccordionCard data={productDetails.story} target='productstory' isHtml title='Product Story' isCollapse />
                        </div>
                    </div> : ''}
                    {productDetails && productDetails.digitalContent ? <div className='detail-card'>
                        {/* <div className='product-header'>
                            <h5>Digital Content</h5>
                        </div> */}
                        <div className='product-body'>
                            <AccordionCard data={productDetails.digitalContent} target='digitalcontent' title='Digital Content' isSoldOut={productDetails && productDetails.sold_at ? true : false} productUserId={productDetails && productDetails.user_id ? productDetails.user_id : MagicNumber.ZERO} isCollapse />
                        </div>
                    </div> : ''}
                    {productDetails ? <div className='detail-card'>
                        <div className='product-header'>
                            <h5>Details</h5>
                        </div>
                        <div className='product-body'>
                            <div className='detail-row'>
                                <div className='left-col'>
                                    <div className='item-row'>
                                        <div className='item-detail'>Dimensions:</div>
                                        <div className='item-info'>{productDetails.length}” x {productDetails.width}” x {productDetails.height}'</div>
                                    </div>
                                    <div className='item-row'>
                                        <div className='item-detail'>Weight</div>
                                        <div className='item-info'>{productDetails.weight} lbs</div>
                                    </div>
                                    <div className='item-row'>
                                        <div className='item-detail'>Date Created:</div>
                                        <div className='item-info'>{getDetailsFormate(productDetails.createdAt)}</div>
                                    </div>
                                </div>
                                <div className='right-col'>
                                    <div className='item-row'>
                                        <div className='item-detail'>Material:</div>
                                        <div className='item-info'>{productDetails.material ? productDetails.material : 'N/A'}</div>
                                    </div>
                                    <div className='item-row'>
                                        <div className='item-detail'>Condition:</div>
                                        <div className='item-info'>{productDetails.condition}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : ''}
                    <div className='detail-card'>
                        {/* <div className='product-header'>
                            <h5>Discussion</h5>
                        </div> */}
                        <div className='comment-section'>
                            <div className='comment-head'>
                                <p className='head-text'>Discussion</p>
                            </div>
                            <div className='enter-comment'>
                                <div className='form-group'>
                                    <input type='text'
                                        onChange={(value) => handleInputChange(value)}
                                        value={comments}
                                        className='form-control'
                                        placeholder='Enter your comment here…'
                                        onKeyPress={(e) => enterPressed(e)}
                                    />
                                    <button type='button' onClick={() => addComment()} className='btn primary'><Image src={SEND_ICON_BLACK} alt='send' className='send-icon' /></button>
                                </div>
                            </div>
                            {commentData && commentData.length ? commentData.map((commentItem, keys) => {
                                return (
                                    <>
                                        <div className='user-comment-section' key={keys}>
                                            <div className='user-image'>
                                                <div className='image-box'>
                                                    <img src={commentItem && commentItem.profile_image || PROFILE_ICON} className='image-user' alt='user'
                                                        onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='user-detail'>
                                                <div className='full-detail'>
                                                    <div className='head-detail'>
                                                        <h3 className='user-name'>{commentItem.username || 'anonymous'}</h3>
                                                        <p className='time-detail'>{validateCommentDate(commentItem && commentItem.createdAt)}</p>
                                                    </div>
                                                    {(commentItem.comment &&
                                                        commentItem.comment.length > PRODUCT_COMMENT_RESTRICTIONS.COMMENT_LENGTH) &&
                                                        !commentItem.showMore ?
                                                        <p className='user-comment'>{commentItem.comment.substring(ZERO, PRODUCT_COMMENT_RESTRICTIONS.COMMENT_LENGTH)}
                                                            <a className='show-text' onClick={() => commentShowMore(commentItem.id)}>...Show More</a>
                                                        </p> : <p className='user-comment height-auto'>
                                                            {commentItem.comment}
                                                            {commentItem && commentItem.comment && commentItem.comment.length > PRODUCT_COMMENT_RESTRICTIONS.COMMENT_LENGTH ?
                                                                <a className='show-text' onClick={() => commentShowMore(commentItem.id)}>...Show Less</a> : ''}
                                                        </p>}
                                                    <div className='reply-report-section'>
                                                        <a className='answer-text' onClick={() => onReply(commentItem.id)}>Reply</a>
                                                        {
                                                            isCommentAllowedForReporting(commentItem)
                                                                && isFunctionalityAllowed(FunctionalityType.ReportComment) ?
                                                                <>
                                                                    <div className='dot-round'></div>
                                                                    <a className='answer-text' onClick={() => {
                                                                        setReporteeUserId(commentItem.user_id ?? MagicNumber.ZERO);
                                                                        setReportedContent(commentItem.comment ?? '');
                                                                        setCurrentConfirmationInfo(ConfirmationInfos.REPORT_COMMENT);
                                                                        setShowingReportCommentConfirmation(true);
                                                                    }}>Report</a>
                                                                </> : null
                                                        }
                                                        {
                                                            isFunctionalityAllowed(FunctionalityType.DeleteComment) ?
                                                                <>
                                                                    <div className='dot-round'></div>
                                                                    <a className='answer-text' onClick={() => {
                                                                        setDeletingCommentUserID(commentItem.user_id ? commentItem.user_id.toString() : '');
                                                                        setDeletingCommentID(commentItem.id.toString());
                                                                        setCurrentConfirmationInfo(ConfirmationInfos.DELETE_COMMENT);
                                                                        setShowingDeletingCommentConfirmation(true);
                                                                    }}>Delete</a>
                                                                </> : null
                                                        }
                                                    </div>
                                                    {commentItem.sub_profilimage.length ? <div className='connection-line'></div> : ''}
                                                </div>
                                                {!loader && commentItem.sub_profilimage.length && !commentItem.isExpanded ? <div className='replied-section' key={keys}>
                                                    {commentItem.sub_profilimage.length ? commentItem.sub_profilimage.filter((v: any, i: any, a: any) => a.findIndex((t: any) => (t.id === v.id)) === i).slice(ZERO, 3).map((sub: any, index: number) => {
                                                        return (
                                                            <>
                                                                {index === MagicNumber.ZERO ? <div className='image-box'>
                                                                    <img src={sub.profile_image} className='replier-image' alt='user'
                                                                        onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                                                    />
                                                                </div> : ''}
                                                                {index === MagicNumber.ONE ? <div className='image-box center-box'>
                                                                    <img src={sub.profile_image} className='replier-image' alt='user'
                                                                        onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                                                    />
                                                                </div> : ''}
                                                                {index === MagicNumber.TWO ? <div className='image-box last-box'>
                                                                    <img src={sub.profile_image} className='replier-image' alt='user'
                                                                        onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                                                    />
                                                                </div> : ''}
                                                                {showRepliedText(index, commentItem) ? <>
                                                                    <div className='replier-detail'>
                                                                        <a className='detail'>
                                                                            <strong className='name'>{commentItem.sub_username}</strong></a>
                                                                    </div>
                                                                    {commentItem.sub_profilimage.length >= MagicNumber.ONE ? <a className='all-replies'
                                                                        onClick={() => showReplies(commentItem.id)}
                                                                    > {plusMoreReply(commentItem)} {commentItem.sub_profilimage.length === MagicNumber.ONE ? 'replied' : 'replies'} </a>
                                                                        : ''}
                                                                </>
                                                                    : ''}
                                                            </>
                                                        )
                                                    }) : ''}
                                                </div> :
                                                    <>{!loader && commentItem.replyList && commentItem.replyList.length ? commentItem.replyList.map((reply: any, ind: number) => {
                                                        return (
                                                            <>
                                                                <div className='reply-comment-section' key={ind}>
                                                                    <div className='user-image'>
                                                                        <div className='image-box'>
                                                                            <img src={reply.profile_image}
                                                                                onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                                                                className='image-user' alt='user' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='user-detail'>
                                                                        <div className='head-detail'>
                                                                            <h3 className='user-name'>{reply.username}</h3>
                                                                            <p className='time-detail'>{validateCommentDate(reply.createdAt)}</p>
                                                                        </div>
                                                                        {(reply.comment && reply.comment.length > PRODUCT_COMMENT_RESTRICTIONS.REPLY_LENGTH) && !reply.showMore ?
                                                                            <p className='user-comment'>{reply.comment.substring(ZERO, PRODUCT_COMMENT_RESTRICTIONS.REPLY_LENGTH)}
                                                                                <a className='show-text' onClick={() => replyShowMore(reply.id)}>...Show More</a>
                                                                            </p> : <p className='user-comment height-auto'>{reply.comment}
                                                                                {reply.comment && reply.comment.length > PRODUCT_COMMENT_RESTRICTIONS.REPLY_LENGTH ?
                                                                                    <a className='show-text' onClick={() => replyShowMore(reply.id)}>...Show Less</a> : ''}
                                                                            </p>}

                                                                        <div className='reply-report-section'>
                                                                            {
                                                                                isCommentAllowedForReporting(reply) &&
                                                                                    isFunctionalityAllowed(FunctionalityType.ReportComment) ?
                                                                                    <a className='answer-text' onClick={() => {
                                                                                        setReporteeUserId(reply.user_id ?? MagicNumber.ZERO);
                                                                                        setReportedContent(reply.comment ?? '');
                                                                                        setCurrentConfirmationInfo(ConfirmationInfos.REPORT_REPLY);
                                                                                        setShowingReportCommentConfirmation(true);
                                                                                    }}>Report</a> : null
                                                                            }
                                                                            {
                                                                                isFunctionalityAllowed(FunctionalityType.DeleteComment) ?
                                                                                    <>
                                                                                        {
                                                                                            isFunctionalityAllowed(FunctionalityType.ReportComment) ?
                                                                                                <div className='dot-round'></div> : null
                                                                                        }
                                                                                        <a className='answer-text' onClick={() => {
                                                                                            setDeletingCommentUserID(reply.user_id ? reply.user_id.toString() : '');
                                                                                            setDeletingCommentID(reply.id.toString());
                                                                                            setCurrentConfirmationInfo(ConfirmationInfos.DELETE_REPLY);
                                                                                            setShowingDeletingCommentConfirmation(true);
                                                                                        }}>Delete</a>
                                                                                    </> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {ind + 1 !== commentItem.replyList.length ? <div className='reply-connection-line'></div> : ''}
                                                                </div>
                                                            </>
                                                        );
                                                    })
                                                        : ''}
                                                    </>}
                                            </div>
                                        </div>
                                        {commentItem.reply ? <div className='write-reply-section'>
                                            <div className='user-box'>
                                                <img src={userImage}
                                                    className='user-image' alt='user'
                                                    onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                                />
                                            </div>
                                            <div className='user-comment'>
                                                <div className='form-group'>
                                                    <input type='text'
                                                        onChange={(event) => replyText(event)}
                                                        onKeyPress={(e) => enterPressedReply(e, commentItem.id)}
                                                        className='form-control'
                                                        placeholder='Write reply...' />
                                                    <button type='button' onClick={() => postReply(commentItem.id)}
                                                        className='btn primary'>
                                                        <Image src={SEND_ICON} alt='send' className='send-icon' />
                                                    </button>
                                                </div>
                                            </div>
                                        </div> : ''}
                                    </>
                                );
                            }) : ''}
                            {totalCount > 4 && viewAll ? <div className='all-view-comment'>
                                <a className='view-text' onClick={() => viewAllComments()}>View All Comments</a>
                            </div> : ''}
                        </div>
                    </div>
                </div>
            </div >

            {productDetails && productDetails.drop_data ? <div className='about-collector-section'>
                <div className='collector-container'>
                    <div className='digital-content-section'>
                        {productDetails.drop_data.drop_content[0]?.show_video_product && productDetails.drop_data.drop_content[0]?.media_desc ? <h3 className='head-digital'>{productDetails.drop_data.drop_content[0]?.media_desc}</h3> : ''}
                        {productDetails.drop_data.drop_content[0]?.show_video_product ? <div className='video-section'>
                            {productDetails.drop_data.drop_content[0]?.medias && productDetails.drop_data.drop_content[0]?.medias.length && productDetails.drop_data.drop_content[0]?.medias.map((mediaElelment: any) => {
                                return (
                                    <>
                                        {mediaElelment.type === 'image' && mediaElelment.fullPath ? <div className='video-box'>
                                            <img src={mediaElelment.fullPath} className='media-file' alt='media' />
                                        </div> : mediaElelment.fullPath ? <div className='video-box'>
                                            <FeedItemVideo videoURL={`${base}${mediaElelment.filepath}`} />
                                        </div> : ''}
                                    </>
                                );
                            })}
                        </div> : ''}
                        {productDetails.drop_data.drop_content[0]?.show_quote_product && productDetails.drop_data.drop_content[0]?.quote ? <div className='quotes-section'>
                            <div className='quote-start'>
                                <img src={QUOTE_START} className='quote-image' alt='quote' />
                            </div>
                            <div className='quote-text'>
                                <p className='pg-text'>{productDetails.drop_data.drop_content[0]?.quote}</p>
                                {productDetails.drop_data.drop_content[0]?.quote_by ? <span>{productDetails.drop_data.drop_content[0]?.quote_by}</span> : ''}
                            </div>
                            <div className='quote-end'>
                                <img src={QUOTE_END} className='quote-image' alt='quote' />
                            </div>
                        </div> : ''}
                        {productDetails.drop_data.drop_content[0]?.show_image_product ? <div className='more-about-collector'>
                            <h3 className='about-title'>
                                {productDetails.drop_data.drop_content[0]?.collector_image}
                            </h3>
                            {productDetails.drop_data.drop_content[0].imageUrl1Path || productDetails.drop_data.drop_content[0].imageUrl2Path || productDetails.drop_data.drop_content[0].imageUrl3Path ||
                                productDetails.drop_data.drop_content[0].imageUrl4Path || productDetails.drop_data.drop_content[0].imageUrl5Path || productDetails.drop_data.drop_content[0].imageUrl6Path ? <div className='more-collector-image'>
                                {/* for 1 image start*/}
                                {productDetails.drop_data.drop_content[0].imageUrl1Path && !productDetails.drop_data.drop_content[0].imageUrl2Path && !productDetails.drop_data.drop_content[0].imageUrl3Path &&
                                    !productDetails.drop_data.drop_content[0].imageUrl4Path && !productDetails.drop_data.drop_content[0].imageUrl5Path && !productDetails.drop_data.drop_content[0].imageUrl6Path ? <div className='box-outer image1'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl1Path ? productDetails.drop_data.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 1 image end */}
                                {/* for 2 image start*/}
                                {productDetails.drop_data.drop_content[0].imageUrl1Path && productDetails.drop_data.drop_content[0].imageUrl2Path && !productDetails.drop_data.drop_content[0].imageUrl3Path &&
                                    !productDetails.drop_data.drop_content[0].imageUrl4Path && !productDetails.drop_data.drop_content[0].imageUrl5Path && !productDetails.drop_data.drop_content[0].imageUrl6Path ? <div className='box-outer image2'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl1Path ? productDetails.drop_data.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl2Path ? productDetails.drop_data.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 2 image end */}
                                {/* for 3 image start*/}
                                {productDetails.drop_data.drop_content[0].imageUrl1Path && productDetails.drop_data.drop_content[0].imageUrl2Path && productDetails.drop_data.drop_content[0].imageUrl3Path &&
                                    !productDetails.drop_data.drop_content[0].imageUrl4Path && !productDetails.drop_data.drop_content[0].imageUrl5Path && !productDetails.drop_data.drop_content[0].imageUrl6Path ? <div className='box-outer image3'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl1Path ? productDetails.drop_data.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container center-container3'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl2Path ? productDetails.drop_data.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl3Path ? productDetails.drop_data.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 3 image end */}
                                {/* for 4 image start*/}
                                {productDetails.drop_data.drop_content[0].imageUrl1Path && productDetails.drop_data.drop_content[0].imageUrl2Path && productDetails.drop_data.drop_content[0].imageUrl3Path &&
                                    productDetails.drop_data.drop_content[0].imageUrl4Path && !productDetails.drop_data.drop_content[0].imageUrl5Path && !productDetails.drop_data.drop_content[0].imageUrl6Path ? <div className='box-outer image4'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl1Path ? productDetails.drop_data.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container center-container4'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl2Path ? productDetails.drop_data.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl3Path ? productDetails.drop_data.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl4Path ? productDetails.drop_data.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 4 image end */}
                                {/* for 5 image start*/}
                                {productDetails.drop_data.drop_content[0].imageUrl1Path && productDetails.drop_data.drop_content[0].imageUrl2Path && productDetails.drop_data.drop_content[0].imageUrl3Path &&
                                    productDetails.drop_data.drop_content[0].imageUrl4Path && productDetails.drop_data.drop_content[0].imageUrl5Path && !productDetails.drop_data.drop_content[0].imageUrl6Path ? <div className='box-outer image5'>
                                    <div className='box-container'>
                                        <div className='image-container container5'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl1Path ? productDetails.drop_data.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl2Path ? productDetails.drop_data.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl3Path ? productDetails.drop_data.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container container5'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl4Path ? productDetails.drop_data.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl5Path ? productDetails.drop_data.drop_content[0].imageUrl5Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 5 image end */}

                                {/* for 6 image start */}
                                {productDetails.drop_data.drop_content[0].imageUrl1Path && productDetails.drop_data.drop_content[0].imageUrl2Path && productDetails.drop_data.drop_content[0].imageUrl3Path &&
                                    productDetails.drop_data.drop_content[0].imageUrl4Path && productDetails.drop_data.drop_content[0].imageUrl5Path && productDetails.drop_data.drop_content[0].imageUrl6Path ? <div className='box-outer image6'>
                                    <div className='box-container'>
                                        <div className='image-container small-image'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl1Path ? productDetails.drop_data.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl2Path ? productDetails.drop_data.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container big-image'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl3Path ? productDetails.drop_data.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='box-container'>
                                        <div className='image-container small-image'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl4Path ? productDetails.drop_data.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl5Path ? productDetails.drop_data.drop_content[0].imageUrl5Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container big-image'>
                                            <div className='images-box'>
                                                <img src={productDetails.drop_data.drop_content[0].imageUrl6Path ? productDetails.drop_data.drop_content[0].imageUrl6Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 6 image end */}
                            </div> : ''}
                        </div> : ''}
                    </div>
                </div>
                {productDetails.drop_data && productDetails.drop_data.drop_content && productDetails.drop_data.drop_content.length && productDetails.drop_data.drop_content[0].show_signature_product && (productDetails.drop_data.drop_content[0].collector_msg || productDetails.drop_data.drop_content[0].signatureUrlPath) ? <div className='collector-memory-section'>
                    <div className='memory-container'>
                        {productDetails.drop_data.drop_content[0].collector_msg ? <p className='memory-text'>{productDetails.drop_data.drop_content[0]?.collector_msg}</p> : ''}
                        {productDetails.drop_data.drop_content[0].signatureUrlPath ? <div className='signature-text'>
                            <img src={productDetails.drop_data.drop_content[0].signatureUrlPath ? productDetails.drop_data.drop_content[0].signatureUrlPath : ''} className='sign-image' alt='sign' />
                        </div> : ''}
                    </div>
                </div> : ''}
                <div className='collector-workflow-section'>
                    <div className='workflow-container'>
                        <div className='head-section'>
                            <div className='logo'>
                                <img src={LOGO} className='logo-company' alt='logo' />
                            </div>
                            <h2 className='head-title'>For collectors, by collectors.</h2>
                        </div>
                        <div className='detail-container'>
                            <div className='workflow-detail'>
                                <h3>How it works</h3>
                                <div className='working-flow'>
                                    <div className='flow-pg'>Items are directly sourced and tagged
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Drop begins
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Items are purchased via FIAT
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Confirmations sent to purchasers
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Custom shipping arranged
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Items arrive
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg last-flow-pg'>Purchasers keep items or resell on OOD
                                        <p className='round-black'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='workflow-image'>
                                <img src={COLLECTOR_PRODUCT} className='product-collector-img' alt='collector' />
                            </div>
                        </div>
                    </div>
                </div>
                {homeDropFaqList && homeDropFaqList.length ? <DropFaqList list={homeDropFaqList} /> : ''}
                <div className='drop-start-section drop-start-step2'>
                    {dropData ? <DropTime data={dropData}
                        executeScroll={executeScroll}
                        status={!dropStatus}
                    /> : ''}
                </div>
            </div> : ''}
        </div >
        <ConfirmationBox
            isShowing={isShowingDeletingCommentConfirmation}
            title={currentConfirmationInfo.title}
            description={currentConfirmationInfo.description}
            cancelOptionName={currentConfirmationInfo.cancelOptionName}
            confirmOptionName={currentConfirmationInfo.confirmOptionName}
            onConfirmCB={async (confirmed) => {
                if (isShowingDeletingCommentConfirmation) {
                    if (confirmed) {
                        await onDeleteComment();
                    }
                    setShowingDeletingCommentConfirmation(false);
                    return true;
                }

                return confirmed;
            }}
        />
        <TextConfirmationBox
            isShowing={isShowingReportCommentConfirmation}
            title={currentConfirmationInfo.title}
            cancelOptionName={currentConfirmationInfo.cancelOptionName}
            confirmOptionName={currentConfirmationInfo.confirmOptionName}
            validationSchema={reportContentValidation}
            onConfirmCB={async (text, confirmed) => {
                if (confirmed) {
                    if (!isLoggedIn) {
                        setSignInModalOpen(true);
                        return false;
                    }

                    let errorOccured = false;
                    await onReportComment(text).catch(error => { errorOccured = true });

                    if (!errorOccured) {
                        setShowingReportCommentConfirmation(false);
                    }

                    return !errorOccured;
                }
                else {
                    setShowingReportCommentConfirmation(false);

                    return true;
                }
            }}
        />
        <NotifyModal openModal={openModal} isOpen={isModalOpen} handleNotifyUser={handleNotifyUser} />
        <SignInModal
            openModal={() => setSignInModalOpens()}
            isOpen={signInModalOpen}
            openProfileModal={openProfileModal}
            openSignUpModal={openSignUpModal}
            disableBtn={disableEmailBtn}
        />
        <CompleteProfileModal isOpen={isProfileOpen} openModal={openProfileModal} isEmailBtn={isEmailBtn} />
        <SignupModal openModal={openSignUpModal} isOpen={isSignUpOpen} />
        {
            openBidsModel && <ViewAllBids
                isOpen={openBidsModel}
                openModal={() => { toggleBidsModel(); }}
                productUserId={(productDetails && productDetails.user_id) ? productDetails.user_id : 0}
            />
        }
        {
            openPlaceBidsModel && <PlaceBids
                isOpen={openPlaceBidsModel}
                productImage={productImage && productImage.filepath}
                dropData={productDetails && productDetails.drop_data ? productDetails.drop_data : productDetails && productDetails.name}
                openModal={() => { togglePlaceBidsModel(); }}
            />
        }
        {
            openCreatePrivateModel && <CreatePrivateLink
                isOpen={openCreatePrivateModel}
                openModal={() => { togglePrivateModel(); }}
                productPrice={productDetails && productDetails.price}
                productId={(productDetails && productDetails.id) || 0}
                editFlag={editFlag}
            />
        }
        {
            openResetPopup && <ResetAuctionButton
                isOpen={openResetPopup}
                openModal={() => { toggleResetModel(); }}
                items_id={productDetails && productDetails.order_items_id ? productDetails.order_items_id : 0}
            />
        }
    </>
    );
});

export default wrapperComponent(true, false)(DropDetails);

import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import { useStores } from '../../../../models';
import FullPageLoader from '../../Atoms/FullPageLoader';
import './stripe.scss'
import { Input, Dropdown } from '../../Atoms';
import { StatusCode } from '../../../../constants/apiStatusCode';
import { MagicNumber, monthList, VALIDATION_MESSAGES } from '../../../../constants';

export const LinkCardCustomStripe = ({ openModals }) => {
  const rootStore = useStores();
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cvcNumber, setCvcNumber] = useState('');
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageCard, setErrorMessageCard] = useState('');
  const [errorMessageMonth, setErrorMessageMonth] = useState('');
  const [errorMessageCvc, setErrorMessageCvc] = useState('');
  const [errorMessageYear, setErrorMessageYear] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const { linkCardApi, getLinkedCard, getYears, yearList
  } = rootStore.cardStore;

  const initialValue = [];

  const loadStripe = async () => {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(process.env.REACT_APP_STRIPE_PUBISHABLE_KEY);
      }
      window.document.body.appendChild(s);
    }
  }

  const fetchYear = async () => {
    await getYears();
  }

  useEffect(() => {
    loadStripe();
    fetchYear();
  }, []);

  const pay = (e) => {
    e.preventDefault();
    let errorState = false;
    if (cardNumber === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageCard(VALIDATION_MESSAGES.CARD);
    }
    if (year === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageYear(VALIDATION_MESSAGES.EXPIRY_YEAR);
    }
    if (month === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageMonth(VALIDATION_MESSAGES.EXPIRY_MONTH)
    }
    if (cvcNumber === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageCvc(VALIDATION_MESSAGES.EXPIRY_CVC);
    }
    if (cvcNumber.length < MagicNumber.THREE) {
      errorState = true;
      setErrorMessageCvc(VALIDATION_MESSAGES.EXPIRY_CVCN);
    } else {
      setErrorMessageCvc('');
    }
    if (errorState) {
      return;
    }
    setLoader(true);
    const userId = Cookies.get(VALIDATION_MESSAGES.USER_ID) || VALIDATION_MESSAGES.EMPTY;

    window.Stripe.card.createToken({
      number: cardNumber,
      exp_month: month,
      exp_year: year,
      name: cardHolderName,
      currency: "USD"
    }, async (status, response) => {
      if (status === StatusCode.OK) {
        const data = {
          user_id: parseInt(userId),
          source_token: response.id
        }
        const apiRes = await linkCardApi(data);
        await getLinkedCard(data);
        setLoader(false);
        if (apiRes && apiRes.kind === 'ok')
          openModals(false)
      } else {
        setErrorMessage(response.error.message);
        setLoader(false)
      }
    });
  }

  const selectMonth = (e) => {
    setMonth(e.value);
  }
  const selectYear = (e) => {
    setYear(e.label);
  }

  const setFieldValue = (name, value) => {
    const card = value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
    let a = card.replace(/\s+/g, ' ').trim();
    setCardNumber(a);
  }

  const handleKeyPress = (evt) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > MagicNumber.THIRTY_ONE && (charCode < MagicNumber.FOURTY_EIGHT || charCode > MagicNumber.FIFTY_SEVEN)) {
      evt.preventDefault();
    }
    return true;
  }

  const setCardHolderNames = (value) => {
    const regex = /^[a-zA-Z ]*$/;
    if (regex.test(value)) {
      setCardHolderName(value);
    }
  }

  return (
    <div className="container">
      {loader && <FullPageLoader />}
      <div className="row">
        <form className="form-section" onSubmit={(e) => pay(e)}>
          <div className="col add-card-col">
            <div className="panel panel-default">
              <div className="form-card panel-body link-card-body">
                <div className="form-group floating-label-group">
                  <Input
                    value={cardNumber}
                    setFieldValue={(name, value) => setFieldValue(name, value)}
                    type="text"
                    id="Card Number"
                    name="Card Number"
                    placeholder="Card Number*"
                    maxLength={MagicNumber.NINETEEN}
                    handleKeyPress={handleKeyPress}
                  />
                  {!loader && cardNumber === '' && errorMessageCard !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageCard}
                  </label> : ''}
                </div>
                <div className="form-group floating-label-group last-form-margin">
                  <Input
                    value={cardHolderName}
                    setFieldValue={(name, value) => setCardHolderNames(value)}
                    type="text"
                    id="Card Holder"
                    name="Card Holder"
                    placeholder="Card Holder"
                    maxLength={MagicNumber.TWENTY}
                  />
                </div>
                <div className="form-group floating-label-group dropdown-margin">
                  <Dropdown
                    className="reactSelect"
                    classNamePrefix="react-select"
                    values={initialValue}
                    onChange={(e) => selectMonth(e)}
                    options={monthList}
                    placeholder="Expiry Month*"
                  />
                  <span className={`select-label ${month && 'selected'}`}>Expiry Month</span>
                  {!loader && month === '' && errorMessageMonth !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageMonth}
                  </label> : ''}
                </div>
                <div className="form-group floating-label-group year-margin">
                  <Dropdown
                    className="reactSelect"
                    classNamePrefix="react-select"
                    values={initialValue}
                    onChange={(e) => selectYear(e)}
                    options={yearList}
                    placeholder="Expiry Year*"
                  />
                  <span className={`select-label ${year && 'selected'}`}>Expiry Year</span>
                  {!loader && year === '' && errorMessageYear !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageYear}
                  </label> : ''}
                  <label htmlFor="" className="global-error-msg" >
                    {!loader && year && month && cardNumber ? errorMessage : ''}
                  </label>
                </div>
                <div className="form-group floating-label-group">
                  <Input
                    value={cvcNumber}
                    setFieldValue={(name, value) => setCvcNumber(value)}
                    type="text"
                    id="CVC"
                    name="CVC"
                    placeholder="CVC*"
                    maxLength={MagicNumber.FOUR}
                    handleKeyPress={handleKeyPress}
                  />
                  {!loader && (cvcNumber === '' || cvcNumber.length < 3) && errorMessageCvc !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageCvc}
                  </label> : ''}
                </div>
              </div>
              <button className="btn primary">Save</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LinkCardCustomStripe;

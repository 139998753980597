import React from 'react';

export const XButton = () => (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d={`M9.72472 8.39971L6.325 5L9.72472
      1.60029C10.0918 1.23324 10.0918 0.642327 9.72472
      0.275283C9.35767 -0.091761 8.76676 -0.091761 8.39971
      0.275283L5 3.675L1.60029 0.275283C1.23324 -0.091761
      0.642327 -0.091761 0.275283 0.275283C-0.091761
      0.642327 -0.091761 1.23324 0.275283 1.60029L3.675
      5L0.275283 8.39971C-0.091761 8.76676 -0.091761 9.35767
      0.275283 9.72472C0.642327 10.0918 1.23324 10.0918 1.60029
      9.72472L5 6.325L8.39971 9.72472C8.76676 10.0918 9.35767
      10.0918 9.72472 9.72472C10.0892 9.35767 10.0892 8.76415 9.72472 8.39971Z`}
      fill='white'
    />
  </svg>
);

// @ts-ignore
import ShowMoreText from 'react-show-more-text';
import { MouseEventHandler, useState } from 'react';
import { ADMIN_PROFILE_ICON, USER_PLACEHOLDER, USER_PLACEHOLDER_OPAQUE } from '../../../../../constants/image';
import { FunctionalityType, getFeedTime, MagicNumber, PAGE_ROUTES, UserType, USER_DATA, USER_IMAGE } from '../../../../../constants';
import { FeedItemUserReply } from './FeedItemUserReply';
import { FeedItemReplyInput } from './FeedItemReplyInput';
import Cookies from 'js-cookie';
import { SkeletonImage } from '../../../Atoms/SkeletonImage';
import Skeleton from 'react-loading-skeleton';
import { ConfirmationBox } from '../../../Molecules/confirmationBoxModal/ConfirmationBox';
import { useHistory } from 'react-router';
import { useStores } from '../../../../../models';
import { getActorCollectionID, isActorCommunityManager, isAdminId } from '../FeedListingUtil';
import { errorToast } from '../../../Atoms/Toast/Toast';

export const FeedItemUserComment = (props: {
  commentData: any,
  onReplyCB: (commentId: string, reply: string) => Promise<boolean>,
  onProfileOpenedAsGuestCB?: () => void,
  onReportCommentCB?: (userId: string, comment: string) => void,
  onReportReplyCB?: (userId: string, reply: string) => void,
  onDeleteCommentCB?: (userId: string, commentId: string) => void,
  onDeleteReplyCB?: (userId: string, replyId: string) => void,
}) => {

  const history = useHistory();
  const rootStore = useStores();
  const { userID } = rootStore.feedStreamStore;
  const { isLoggedIn, isFunctionalityAllowed ,fetchUserDetails} = rootStore.loginStore;

  const commentId = props.commentData.id;
  const userId = props.commentData.user.id;
  const userName = `${props.commentData.user.data.name ?? `${props.commentData.user.data.firstname} ${props.commentData.user.data.lastname}`}`;
  const userImageURL = `${props.commentData.user.data.profileImage}`;
  const comment = props.commentData.data.text;
  const postTime = getFeedTime(new Date(props.commentData.created_at));
  const commentHasReplies = props.commentData.children_counts && props.commentData.children_counts.comment > MagicNumber.ZERO;
  const replies = props.commentData.latest_children && props.commentData.latest_children.comment ?
    [...props.commentData.latest_children.comment] : [];

  const isPostUserAdmin = isAdminId(userId);
  const postUserCollectionID = getActorCollectionID(props.commentData.user);
  const isPostByCommunityManager = isActorCommunityManager(props.commentData.user);

  const [viewAllReplies, setViewAllReplies] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);

  const getUniqueUsers = (replies: any[], count: number) => {
    if (!replies || !replies.length) return {};

    const uniqueUsers: any = {};

    for (let i = 0, length = replies.length; i < length; ++i) {
      const userId = replies[i].user.id as string;
      if (!uniqueUsers[userId])
        uniqueUsers[userId] = replies[i].user;
      if (Object.keys(uniqueUsers).length > count)
        break;
    }

    return uniqueUsers;
  };

  const getUserName = (user: any) => {
    
    const data = user.data;
    return `${data.name ?? `${data.firstname} ${data.lastname}`}`;
  };

  const getAllRepliesText = (replies: any[]) => {
    
    const hasMoreRepliesThanOne = replies.length > MagicNumber.ONE;
    return `${hasMoreRepliesThanOne ? `+${replies.length - MagicNumber.ONE} replies` : ' has replied'}`;
  };

  const getLoggedInUserProfileImage = (): string => {
    
    const userImage = Cookies.get(USER_IMAGE);
    return userImage ?? '';
  };

  const canGoToUserProfile = () => {
    
    return !isPostUserAdmin && !(isPostByCommunityManager && !postUserCollectionID);
  };

  const goToUserProfile = async () => {
    
    await fetchUserDetails(userId).then((res: any) => {
      if (res.data.message == "This User doesn't exist!"){
       errorToast('user is not available');
       return;
     }else{
      if (!isLoggedIn) {
       props.onProfileOpenedAsGuestCB?.();
       return;
    }
    if (isPostByCommunityManager && postUserCollectionID) {
      history.push(`${PAGE_ROUTES.ALL}?collection_id=${postUserCollectionID}`);
    } else {
      history.push(`${PAGE_ROUTES.PROFILE}${userId !== userID ? `/${userId}` : ''}`);
    }
  }
  });
  
  };

  const UserReply = (props: { parentDiv: string, imageURL: string }) => {
    
    return (
      <div className={props.parentDiv}>
        <SkeletonImage
          imageURL={props.imageURL ?? USER_PLACEHOLDER_OPAQUE}
          classes='replier-image'
          alt='user'
          placeholder={USER_PLACEHOLDER_OPAQUE}
        />
      </div>
    );
  };

  const UserReplies = (props: { replies: any[] }) => {
    
    const users = getUniqueUsers(props.replies, MagicNumber.THREE);
    const userKeys = Object.keys(users);
    const userKeysLength = userKeys.length;

    return (
      <div className='replied-section'>
        {userKeys.map((userKey, index) => {
          return (
            <>
              {
                index === MagicNumber.ZERO ?
                  <UserReply imageURL={users[userKey].data.profileImage} parentDiv='image-box' /> : null
              }
              {
                index === MagicNumber.ONE ?
                  <UserReply imageURL={users[userKey].data.profileImage} parentDiv='image-box center-box' /> : null
              }
              {
                index === MagicNumber.TWO ?
                  <UserReply imageURL={users[userKey].data.profileImage} parentDiv='image-box last-box' /> : null
              }
              {
                index === userKeysLength - MagicNumber.ONE ?
                  <div className='replier-detail'>
                    <a className='detail' onClick={(e) => { setViewAllReplies(true); }}>
                      <strong className='name'>{getUserName(users[userKey])}</strong>
                    </a>
                  </div> : null
              }
            </>
          );
        })};
        <a className='all-replies' onClick={(e) => { setViewAllReplies(true); }}>
          {getAllRepliesText(props.replies)};
        </a>
      </div>
    );
  };

  return (
    <div className='user-comment-section'>
      <div className='user-image'>
        <div className='image-box'>
          <SkeletonImage
            imageURL={userImageURL}
            classes='image-user'
            alt='user'
            placeholder={USER_PLACEHOLDER}
            onClick={canGoToUserProfile() ? () => { goToUserProfile(); } : undefined}
          />
        </div>
      </div>
      <div className='user-detail'>
        <div className='full-detail'>
          <div className='head-detail'>
            <h3 className='user-name' role={canGoToUserProfile() ? 'button' : ''}
              onClick={canGoToUserProfile() ? () => { goToUserProfile(); } : undefined}>{userName}</h3>
            <p className='time-detail'>{postTime}</p>
          </div>
          <ShowMoreText
            lines={3}
            more='Show more'
            less='Show less'
            className='post-pg'
            anchorClass='show-more-text'
            expanded={false}
          >
            <p className='user-comment height-auto'>
              {comment}
            </p>
          </ShowMoreText>
          <div className='reply-report-section'>
            <a className='answer-text' onClick={() => { setIsReplying(!isReplying); }}>Reply</a>
            {
              userId !== userID && !isPostUserAdmin && isFunctionalityAllowed(FunctionalityType.ReportComment) ?
                <>
                  <div className='dot-round'></div>
                  <a className='answer-text' onClick={() => {
                    props.onReportCommentCB?.(userId, comment);
                  }}>Report</a>
                </> : null
            }
            {
              isFunctionalityAllowed(FunctionalityType.DeleteComment) ?
                <>
                  <div className='dot-round'></div>
                  <a className='answer-text' onClick={() => { props.onDeleteCommentCB?.(userId, commentId); }}>Delete</a>
                </> : null
            }
          </div>
          {commentHasReplies ? <div className='connection-line'></div> : null}
        </div>

        {commentHasReplies && replies.length && !viewAllReplies ?
          <UserReplies replies={replies} />
          : null
        }

        {commentHasReplies && replies.length && viewAllReplies ?
          replies.map((reply, index) => {
            return (
              <FeedItemUserReply
                replyData={reply}
                showConnectionLine={index !== replies.length - MagicNumber.ONE}
                onProfileOpenedAsGuestCB={props.onProfileOpenedAsGuestCB}
                onReportCB={() => { props.onReportReplyCB?.(reply.user.id, reply.data.text); }}
                onDeleteCB={() => { props.onDeleteReplyCB?.(reply.user.id, reply.id); }}
              />
            );
          })
          : null
        }

        {isReplying ?
          <FeedItemReplyInput profileImage={getLoggedInUserProfileImage()}
            onReplyCB={(reply) => {
              const onReply = props.onReplyCB(commentId, reply);
              onReply.then(successful => {
                if (successful)
                  setIsReplying(false);
              });
              return onReply;
            }} />
          : null
        }

      </div>
    </div>
  );
};

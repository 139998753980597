import React from 'react';
import DatePicker from 'react-date-picker';
import { CALENDAR_ICON } from '../../../constants';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

type IProps = {
  yearPlaceholder?: string;
  className?: string;
  onChange?: (e: any, key: number) => void;
  value?: moment.Moment;
  dateFormat?: string;
  showYearPicker?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  minDate?: moment.Moment | {};
  maxDate?: moment.Moment | {};
  disabled?: boolean;
};

//datepicker
const DateInput: React.FC<IProps> = (props: IProps | any) => {
  return (
    <DatePicker
      format='yyyy'
      maxDetail='decade'
      minDetail='decade'
      calendarIcon={<img src={CALENDAR_ICON} className='calendar-image' alt='calender icon' />}
      popperPlacement='top'
      popperModifiers={{
        flip: {
          behavior: ['top'] // don't allow it to flip to be above
        },
        preventOverflow: {
          enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
        },
        hide: {
          enabled: false // turn off since needs preventOverflow to be enabled
        }
      }}
      onKeyDown={(e: any) => e.preventDefault()}
      {...props} />
  );
};

export default DateInput;

import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router';
import { MagicNumber, MENU_ICON_CLOSE, PAGE_ROUTES, USER_PLACEHOLDER } from '../../../../constants';
import { useStores } from '../../../../models';
import { errorToast } from '../../Atoms';
import { SkeletonImage } from '../../Atoms/SkeletonImage';
import { getActorCollectionID, isActorCommunityManager, isAdminId } from '../../Pages/feedListing/FeedListingUtil';
import { ReactionUserSkeleton } from './skeletons/ReactionUserSkeleton';
import './_reactions-list.scss';

type Props = {
  feedStream: any,
  streamClient: any,
  feedId: string,
  kind: string,
  listTitle: string,
  isShowing: boolean,
  onProfileOpenedAsGuestCB?: () => void,
  onHideCB?: () => void
};

export const ReactionsList = (props: Props) => {

  const rootStore = useStores();
  const history = useHistory();
  const { getMoreReactions, userID } = rootStore.feedStreamStore;
  const { isLoggedIn,fetchUserDetails } = rootStore.loginStore;

  const [totalReactions, setTotalReactions] = useState<number>(MagicNumber.ZERO);

  const [reactionsList, setReactionsList] = useState<any[]>([]);
  const [pageLimit] = useState<number>(MagicNumber.TEN);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(false);

  useEffect(() => {
    if (props.isShowing) {
      setIsInitialLoad(true);
      retrieveMoreReactions([]);
    }
    return (() => {
      setReactionsList([]);
    });
  }, [props.isShowing]);

  const retrieveMoreReactions = async (currentFeed: any[]) => {
    if (!props.streamClient || !props.feedId) return;

    setIsLoading(true);
    try {
      const response = await getMoreReactions(props.streamClient,
        props.kind,
        props.feedId,
        reactionsList.length ? reactionsList[reactionsList.length - MagicNumber.ONE].id : '',
        pageLimit);

      const updatedList = [...currentFeed, ...response.results];
      const totalReactions = response.activity.reaction_counts[props.kind];

      setTotalReactions(totalReactions !== undefined ? totalReactions : MagicNumber.ZERO);
      setReactionsList(updatedList);
      setIsLoading(false);
      setIsInitialLoad(false);
    }catch (error) {
      errorToast(error);
      setIsLoading(false);
    }
  };

  const goToUserProfile = async (user: any) => {
    await fetchUserDetails(user.id).then((res: any) => {
     if (res.data.message == "This User doesn't exist!") {
       errorToast('user is not available');
       return;
     }else{
     if (!isLoggedIn) {
       props.onProfileOpenedAsGuestCB?.();
       return;
    }

    if (isActorCommunityManager(user) && getActorCollectionID(user)) {
      history.push(`${PAGE_ROUTES.ALL}?collection_id=${getActorCollectionID(user)}`);
    } else {
      history.push(`${PAGE_ROUTES.PROFILE}${user.id !== userID ? `/${user.id}` : ''}`);
    }

    history.push(`${PAGE_ROUTES.PROFILE}${user.id !== userID ? `/${user.id}` : ''}`);
  }
});
  };

  const canGoToUserProfile = (user: any) => {
    return !isAdminId(user.id) && !(isActorCommunityManager(user) && !getActorCollectionID(user));
  };

  return (
    <Modal
      size='sm'
      show={props.isShowing}
      onHide={props.onHideCB}
      backdrop={false}
      aria-labelledby='modal-sm'
      centered
      dialogClassName='feed-reaction-dialog modal-dialog-centered'
      contentClassName='feed-modal-content'
    >
      <div className='feed-likes-main'>
        <Modal.Header>
          <div className='feed-like-head'>
            <div className='close-icon' onClick={props.onHideCB}>
              <img src={MENU_ICON_CLOSE} className='close-image' alt='close' />
            </div>
            <Modal.Title className='contact-text' id='example-modal-sizes-title-sm' hidden={isInitialLoad}>
              {`${totalReactions} ${totalReactions === MagicNumber.ONE ? props.listTitle : `${props.listTitle}s`}`}
            </Modal.Title>
          </div>
        </Modal.Header>
        <div className='feed-like-body' id='reactions-model'>
          <div className='body-inside'>
            <div className='contact-user-section'>
              <InfiniteScroll
                dataLength={reactionsList.length}
                hasMore={true}
                next={() => { retrieveMoreReactions(reactionsList); }}
                loader={null}
                scrollableTarget='reactions-model'
              >
                {
                  reactionsList.map(reaction => {
                    return (
                      <div className='user-box'>
                        <div className='user-image'>
                          <SkeletonImage
                            imageURL={reaction.user.data.profileImage ?? USER_PLACEHOLDER}
                            placeholder={USER_PLACEHOLDER}
                            classes='user-icon'
                            alt='user'
                            onClick={canGoToUserProfile(reaction.user) ? () => { goToUserProfile(reaction.user); } : undefined}
                          />
                        </div>
                        <div className='contact-detail'>
                          <p className='name' role={canGoToUserProfile(reaction.user) ? 'button' : ''}
                            onClick={canGoToUserProfile(reaction.user) ? () => { goToUserProfile(reaction.user); } : undefined}>
                            {`${reaction.user.data.firstname} ${reaction.user.data.lastname} `}
                          </p>
                          {reaction.user.data.name ? <div className='contact-id'>{`${reaction.user.data.name ?? ''} `}</div> : null}
                        </div>
                      </div>
                    );
                  })
                }
                {isLoading ? <ReactionUserSkeleton /> : null}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
};

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import Cookie from 'js-cookie';
import { USER_ID, MagicNumber, tabInitialName } from './../../../../constants';
import { BLACK_ICON } from '../../../../constants/image';
import wrapperComponent from '../../Templates/wrapperComponent';
import { TabLinks, TabContent } from '../../Molecules';
import { useStores } from './../../../../models';
import { SettingSketelon } from './SettingSketelon';
import './settings.scss';
import {
  handleEnter,
  PASS_ICON_delete,
  PASSWORD_SHOW_delete,
  Delete_Account
} from '../../../../constants';

const Settings = observer(function Settings() {
  const rootStore = useStores();
  const history = useHistory();
  const [shippingAddressList, setShippingAddressList] = useState<any>([]);
  const [loading, serLoading] = useState<boolean>(false);
  const { getShippingAddress, savedAddressList, isLoading, } = rootStore.settingStore;
  const [activeResponsiveClass, setActiveResponsiveClass] = useState<any>(false);
  const [selectedTab, setSelectedTab] = useState<string>(tabInitialName.setting);
  const { isLoggedIn } = rootStore.loginStore;

  const fetchShippingAddress = async () => {
    serLoading(true);
    await getShippingAddress(Number(Cookie.get(USER_ID)));
    serLoading(false);
  };

  useEffect(() => {
    isLoggedIn && fetchShippingAddress();
  }, [isLoggedIn]);

  useEffect(() => {
    setShippingAddressList(savedAddressList);
  }, [savedAddressList]);

  const toggleResponsiveTabs = () => {
    setActiveResponsiveClass(!activeResponsiveClass);
  };

  const handleTabSelect = (key: any) => {
    setSelectedTab(key);
    history.replace({
      hash: `tab=${selectedTab}`
    });
  };

  useEffect(() => {
    history.replace({
      hash: `tab=${selectedTab}`
    });
  }, [selectedTab]);

  return (
    <>

      <div className='setting__wrapper'>
        <div className={`setting__container ${activeResponsiveClass ? 'showDetail' : ''}`}>
          <Link to='/profile' className='back-btn'><img src={BLACK_ICON} alt='back' className='back-icon' />Back</Link>
          <h1>Settings</h1>
          <div className='responsive-tab-actions'>
            <span onClick={toggleResponsiveTabs} className='back-btn'><span className='icon icon-back' />Back</span>
          </div>
          {loading ? <SettingSketelon /> : (
            <div className='setting__tab'>
              <Tab.Container defaultActiveKey='shippingAddress' onSelect={handleTabSelect}>
                <Nav variant='pills' className='setting__tab_links'>
                  <TabLinks title='Shipping Address' target='shippingAddress' iconName='shipping' responsiveClass={toggleResponsiveTabs} />
                  <TabLinks title='Saved Card' target='SavedCard' iconName='card' responsiveClass={toggleResponsiveTabs} />
                  <TabLinks title='Payout Card' target='LinkedCard' iconName='linkedCard' responsiveClass={toggleResponsiveTabs} />
                  <TabLinks title='Contact Us' target='ContactUs' iconName='contact' responsiveClass={toggleResponsiveTabs} />
                  <TabLinks title="FAQ's" target='FAQ' iconName='faq' responsiveClass={toggleResponsiveTabs} />
                  <TabLinks  title='Delete Account' target='Delete' iconName='deleteUser'  responsiveClass={toggleResponsiveTabs} />
                </Nav>
                <Tab.Content className='setting__tab_content'>
                  {shippingAddressList.length >= MagicNumber.ZERO ? (
                    <TabContent data={shippingAddressList} target='shippingAddress' />
                  ) : (<></>)}
                  <TabContent data='Second' target='SavedCard' />
                  <TabContent data='Sixth' target='LinkedCard' />
                  <TabContent data='Fourth' target='ContactUs' />
                  <TabContent data='Five' target='FAQ' />
                  <TabContent data='' target='Delete' />
                </Tab.Content>
              </Tab.Container>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default wrapperComponent(true, false)(Settings);

import { MagicNumber } from '../../../../../constants';
import { PRODUCT_PLACEHOLDER } from '../../../../../constants/image';
import { SkeletonImage } from '../../../Atoms/SkeletonImage';


const RenderImage = (props: { imageURL: string | undefined, onClick: () => void }) => {
  return (
    <SkeletonImage
      imageURL={props.imageURL ?? ''}
      classes='feed-image'
      alt='feed'
      placeholder={PRODUCT_PLACEHOLDER}
      onClick={props.onClick}
    />
  );
};

const RenderSingleImage = (props: { imageURL: string | undefined, onClick: () => void }) => {
  return (
    <div className='image-feed-section'>
      <div className='image-section'>
        <RenderImage imageURL={props.imageURL} onClick={props.onClick} />
      </div>
    </div>
  );
};

const RenderTwoImages = (props: { imageURLs: string[] | undefined, onClick: () => void }) => {
  if (!props.imageURLs || props.imageURLs.length !== MagicNumber.TWO)
    return null;

  return (
    <div className='image-container'>
      <div className='image-box big-box'>
        <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ZERO] : ''} onClick={props.onClick} />
      </div>
      <div className='image-box big-box'>
        <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ONE] : ''} onClick={props.onClick} />
      </div>
    </div>
  );
};

const RenderThreeImages = (props: { imageURLs: string[] | undefined, onClick: () => void }) => {
  if (!props.imageURLs || props.imageURLs.length !== MagicNumber.THREE)
    return null;

  return (
    <div className='image-container'>
      <div className='image-box small-box'>
        <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ZERO] : ''} onClick={props.onClick} />
      </div>
      <div className='image-box small-box'>
        <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ONE] : ''} onClick={props.onClick} />
      </div>
      <div className='image-box small-box'>
        <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.TWO] : ''} onClick={props.onClick} />
      </div>
    </div>
  );
};

const RenderFourImages = (props: { imageURLs: string[] | undefined, onClick: () => void }) => {
  if (!props.imageURLs || props.imageURLs.length !== MagicNumber.FOUR)
    return null;

  return (
    <>
      <div className='image-container'>
        <div className='image-box big-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ZERO] : ''} onClick={props.onClick} />
        </div>
        <div className='image-box big-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ONE] : ''} onClick={props.onClick} />
        </div>
      </div>
      <div className='image-container'>
        <div className='image-box big-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.TWO] : ''} onClick={props.onClick} />
        </div>
        <div className='image-box big-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.THREE] : ''} onClick={props.onClick} />
        </div>
      </div>
    </>
  );
};

const RenderFiveImages = (props: { imageURLs: string[] | undefined, onClick: () => void }) => {
  if (!props.imageURLs || props.imageURLs.length !== MagicNumber.FIVE)
    return null;

  return (
    <>
      <div className='image-container'>
        <div className='image-box big-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ZERO] : ''} onClick={props.onClick} />
        </div>
        <div className='image-box big-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.ONE] : ''} onClick={props.onClick} />
        </div>
      </div>
      <div className='image-container'>
        <div className='image-box small-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.TWO] : ''} onClick={props.onClick} />
        </div>
        <div className='image-box small-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.THREE] : ''} onClick={props.onClick} />
        </div>
        <div className='image-box small-box'>
          <RenderImage imageURL={props.imageURLs ? props.imageURLs[MagicNumber.FOUR] : ''} onClick={props.onClick} />
        </div>
      </div>
    </>
  );
};

const RenderMultipleImages = (props: { imageURLs: string[] | undefined, onClick: () => void }) => {

  if (!props.imageURLs || props.imageURLs.length < 2)
    return null;

  return (
    <div className='multi-image-feed'>
      <div className='image-section'>
        {
          props.imageURLs.length === MagicNumber.TWO ?
            <RenderTwoImages imageURLs={props.imageURLs} onClick={props.onClick} /> : null
        }
        {
          props.imageURLs.length === MagicNumber.THREE ?
            <RenderThreeImages imageURLs={props.imageURLs} onClick={props.onClick} /> : null
        }
        {
          props.imageURLs.length === MagicNumber.FOUR ?
            <RenderFourImages imageURLs={props.imageURLs} onClick={props.onClick} /> : null
        }
        {
          props.imageURLs.length === MagicNumber.FIVE ?
            <RenderFiveImages imageURLs={props.imageURLs} onClick={props.onClick} /> : null
        }
      </div>
    </div>
  );
};

export const FeedItemImages = (props: { imageURLs: string[] | undefined, onClick: () => void }) => {
  if (!props.imageURLs || !props.imageURLs.length)
    return null;

  if (props.imageURLs.length > MagicNumber.ONE) {
    return <RenderMultipleImages imageURLs={props.imageURLs} onClick={props.onClick} />;
  }
  else {
    return <RenderSingleImage imageURL={props.imageURLs[MagicNumber.ZERO]} onClick={props.onClick} />;
  }
};

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import S3FileUpload from 'react-s3';
import { Formik, Form, Field } from 'formik';
import { Modal, FieldError, Input, Image, errorToast } from '../../Atoms';
import {
  CAMERA_ICON,
  signUpFormValidation,
  initialRegisterVal,
  PASSWORD_SHOW,
  PASS_ICON,
  MagicNumber,
  handleEnter,
  UPLOAD_ICON,
  profilePic,
  USER_DIR_NAME,
  REGION,
  S3_IMAGE_ERROR,
  removeWhiteSpace,
} from '../../../../constants';
import { useStores } from '../../../../models';
import { OtpModal } from '../otpModal/otpModal';
import { Link } from 'react-router-dom';
import { PAGE_ROUTES } from '../../../../constants/Routes';

type IProps = {
  openModal: (e: boolean) => void;
  isOpen?: boolean;
};

export const SignupModal = observer(function Home({
  openModal,
  isOpen,
}: IProps) {
  const rootStore = useStores();
  const path = process.env.REACT_APP_ASSET_HOST;
  const {
    registerUser,
    getS3Config,
    checkEmailExist,
    isEmailExist,
    isUsernameExist,
  } = rootStore.loginStore;
  const [profileImage, setProfileImage] = useState<any>(
    path + profilePic[MagicNumber.ZERO]
  );
  const [isOtpModalOpen, openOtpModal] = useState<boolean>(false);
  const [imageValue, setImage] = useState<any>();
  const [passwordType, setPasswordType] = useState<boolean>(false);
  const [email, selEmail] = useState<string>('');
  const [tolltipClass, setTolltipClass] = useState<boolean>(false);
  const { ZERO, ONE, TWO } = MagicNumber;
  const analytics = require('react-segment');

  const registerOodUser = async (values: any) => {    
    let image = profileImage.split('.net/');
    selEmail(values.email);
    if (image.length === TWO) {
      values.profile_image = image[ONE];
      await registerUser(values).then((res) => {
        if (res) {
          analytics.default.track('Signup Completed');
          emptyState();
          openOtpModal(true);
        }
      });
    } else {
      fetchS3Config(values);
    }
  };

  const fetchS3Config = async (values: any) => {
    const s3Config = await getS3Config();
    const config = {
      bucketName: s3Config.data.data.s3.bucket_name,
      dirName: USER_DIR_NAME,
      region: REGION,
      accessKeyId: s3Config.data.data.s3.aws_access_key_id,
      secretAccessKey: s3Config.data.data.s3.aws_secret_access_key,
    };
    await S3FileUpload.uploadFile(imageValue, config)
      .then((data: any) => {
        values.profile_image = data.key;
        registerUser(values).then((res) => {
          if (res) {    
            emptyState();
            openOtpModal(true);
          }
        });
      })
      .catch((err: any) => {
        errorToast(S3_IMAGE_ERROR);
      });
  };

  const handleFileSelected = (event: any, status: boolean) => {
    let url = status ? URL.createObjectURL(event.target.files[ZERO]) : event;
    if (status) setImage(event.target.files[ZERO]);
    setProfileImage(url);
    setTolltipClass(false);
  };

  const emptyState = () => {
    openModal(false);
    setProfileImage(path + profilePic[MagicNumber.ZERO]);
    setTolltipClass(false);
  };

  useEffect(() => {
    window.addEventListener('click', (e: any) => {
      const doc: any = document;
      if (doc) {
        if (
          doc &&
          doc.getElementById('clickbox') &&
          doc.getElementById('clickbox').contains(e.target)
        ) {
          return;
        } else if (
          doc &&
          doc.getElementById('clickboxImg') &&
          doc.getElementById('clickboxImg').contains(e.target)
        ) {
          setTolltipClass(!tolltipClass);
        } else {
          setTolltipClass(false);
        }
      }
    });
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} openModal={emptyState} header='Sign Up' isSignUp>
        <Formik
          initialValues={initialRegisterVal}
          onSubmit={async (values) => {
            await registerOodUser(removeWhiteSpace(values));
          }}
          validationSchema={signUpFormValidation}
          render={({ handleSubmit, values, setFieldValue, dirty }) => (
            <Form className='form-section'>
              <div className='upload-image-section'>
                <div className='upload-box'>
                  <img
                    src={profileImage}
                    alt='upload'
                    className='placeholder-upload'
                  />
                  <div className='upload-icon tooltip'>
                    <div
                      className='upload-background'
                      style={{ display: tolltipClass ? 'block' : 'none' }}
                    >
                      <div className='upload-inside' id='clickbox'>
                        <div className='upload-box'>
                          <input
                            type='file'
                            className='file-input'
                            accept='image/x-png,image/jpg,image/jpeg'
                            onChange={(e) => handleFileSelected(e, true)}
                            id='input_upload'
                          />
                          <div className='upload-inside'>
                            <div className='icon-upload'>
                              <Image
                                src={UPLOAD_ICON}
                                className='upload-image'
                                alt='upload'
                              />
                              <p className='text-upload'>Upload picture</p>
                            </div>
                          </div>
                        </div>
                        <div className='profile-text'>
                          Default Profile Images
                        </div>
                        <div className='default-image-container'>
                          <div className='default-image-section'>
                            {profilePic.map((ele: any) => (
                              <div className='image-box'>
                                <img
                                  src={path + ele}
                                  onClick={() =>
                                    handleFileSelected(path + ele, false)
                                  }
                                  key={ele}
                                  className='image-default'
                                  alt='image'
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      id='clickboxImg'
                      src={CAMERA_ICON}
                      alt='upload'
                      className='image-upload'
                      onClick={() => setTolltipClass(true)}
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <Input
                  required
                  id='firstname'
                  name='firstname'
                  type='text'
                  placeholder='First Name'
                  value={values.firstname}
                  setFieldValue={setFieldValue}
                  handleEnter={handleEnter}
                  maxLength={MagicNumber.TEN_FIVE}
                />
                <FieldError name='firstname' />
              </div>
              <div className='form-group'>
                <Input
                  required
                  id='lastname'
                  name='lastname'
                  type='text'
                  placeholder='Last Name'
                  value={values.lastname}
                  setFieldValue={setFieldValue}
                  handleEnter={handleEnter}
                  maxLength={MagicNumber.TEN_FIVE}
                />
                <FieldError name='lastname' />
              </div>
              <div className='form-group'>
                <Input
                  required
                  id='username'
                  name='username'
                  type='text'
                  placeholder='Username'
                  value={values.username}
                  setFieldValue={setFieldValue}
                  handleEnter={handleEnter}
                  maxLength={MagicNumber.FIFTY}
                  isRegister
                  checkEmailExist={checkEmailExist}
                />
                <FieldError name='username' />
                {isUsernameExist && (
                  <FieldError message='Username already exist' />
                )}
              </div>
              <div className='form-group'>
                <Input
                  required
                  id='email'
                  name='email'
                  type='text'
                  placeholder='Email'
                  value={values.email}
                  setFieldValue={setFieldValue}
                  handleEnter={handleEnter}
                  isRegister
                  checkEmailExist={checkEmailExist}
                />
                <FieldError name='email' />
                {isEmailExist && <FieldError message='Email already exist' />}
              </div>
              <div className='form-group'>
                <Input
                  required
                  id='password'
                  name='password'
                  type={passwordType ? 'text' : 'password'}
                  placeholder='Password'
                  value={values.password}
                  setFieldValue={setFieldValue}
                  handleEnter={handleEnter}
                />
                <div className='paasword-section'>
                  <Image
                    src={!passwordType ? PASSWORD_SHOW : PASS_ICON}
                    alt='show'
                    className='password-icon'
                    onClick={() => setPasswordType(!passwordType)}
                  />
                </div>
                <FieldError name='password' />
              </div>
              <div className='agree-text'>
                <div className='text-section'>
                  <div className='checkbox-custom'>
                    <label className='label-text info-share-section'>
                      <Field
                        type='checkbox'
                        className='box-check'
                        id='term_condition'
                        name='term_condition'
                        onChange={() => {
                          setFieldValue(
                            'term_condition',
                            !values.term_condition
                          );
                          setFieldValue(
                            'privacy_policy',
                            !values.term_condition
                          );
                        }}
                      />
                      <strong></strong>
                      <p className='info-share-text'>
                        I agree with OOD{' '}
                        <a className='link-text' target='_blank' href={PAGE_ROUTES.TC}>Terms of Service</a>{' '}
                        and{' '}
                        <a className='link-text' target='_blank' href={PAGE_ROUTES.PP}> Privacy Policy</a>{' '}
                      </p>
                    </label>
                    <label className='label-text info-share-section'>
                      <Field
                        type='checkbox'
                        className='box-check'
                        id='share_persional_info'
                        name='share_persional_info'
                      />
                      <strong></strong>
                      <p className='info-share-text'>
                        Allow OOD to share personal information with 3rd
                        parties
                      </p>
                    </label>
                  </div>
                </div>
              </div>
              <div className='submit-button'>
                <button
                  type='button'
                  className='btn primary'
                  disabled={!dirty ? true : false}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        />
      </Modal>
      <OtpModal
        openModal={openOtpModal}
        isOpen={isOtpModalOpen}
        email={email}
        isRegister
      />
    </>
  );
});

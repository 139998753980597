export const MagicNumber = {
   ZERO: 0,
   ONE: 1,
   TWO: 2,
   THREE: 3,
   FOUR: 4,
   FIVE: 5,
   SIX: 6,
   SEVEN: 7,
   EIGHT: 8,
   NINE: 9,
   TEN: 10,
   NINETEEN: 19,
   TWENTY: 20,
   TWENTY_TWO: 22,
   TEN_FIVE: 25,
   TWO_FIFTY: 250,
   ONE_FIFTY: 150,
   FIVE_TH: 500,
   FIVE_H: 500,
   TWO_TH_ONE: 201,
   FIFTY: 50,
   NINTY: 90,
   FIFTY_ONE: 51,
   ELEVEN: 11,
   TWELVE: 12,
   THIRTEEN: 13,
   SIXTEEN: 16,
   FOURTEEN: 14,
   FIFTEEN: 15,
   ONE_FIFTEEN: 115,
   ONE_TWENTY: 120,
   ONE_TH: 1000,
   TWO_TH:2000,
   ONE_FIVE_H: 1500,
   FIVE_H_TH: 5000,
   TEN_TH: 10000,
   TWOFIVE_TH: 25000,
   FIFTY_TH: 50000,
   FOUR_TH: 400,
   THIRTY: 30,
   FOURTY: 30,
   FOUR_TH_FOUR: 404,
   EIGHTY_SIX_FR_TH: 86400,
   THIRTY_SIX_TH: 3600,
   TWENTYFR: 24,
   SIXTY: 60,
   TH: 100,
   THIRTY_TH: 3000,
   ONE_TH_MILLION: 1000000000,
   ONE__M_EIGHTY_TH: 1800000,
   FOUR_FIVE_TH: 4500,
   THIRTY_ONE: 31,
   THIRTY_TWO: 32,
   FOURTY_EIGHT: 48,
   FIFTY_SEVEN: 57,
   TWO_H: 200,
   FOUR_H_ONE: 401,
   FIVE_FIFTY: 550,
   SIX_TH: 6000,
   MINUS_ONE: -1,
   CONTACT_PHONE_CUSTOM_FIELD_ID: 1500005312262
}

import Skeleton from 'react-loading-skeleton';

export const SearchUserSkeleton = () => {
  return (
    <>
        <div className="empty-user-search-skeleton">
          <Skeleton count={4} />
        </div>
    </>
  )
};


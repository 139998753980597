import { Route, Redirect } from 'react-router-dom';
import { useStores } from '../../../models';

type IProps = {
  component: any;
  restricted: boolean;
  path: string;
  exact: any;
};

export const PublicRoutes = ({component: Component, restricted, ...rest}:IProps) => {
  const rootStore = useStores();
  const { isLoggedIn } = rootStore.loginStore;
    return (
        <Route {...rest} render={props => (
          isLoggedIn && restricted ?
                <Redirect to="/" />
            : <Component {...props} />
        )} />
    );
};

import * as Yup from 'yup';
import { FEED_POST_RESTRICTIONS, VALIDATION_MESSAGES } from './constants';
import { MagicNumber } from './MagicNumber';

const MIN_OTP_LENGTH = MagicNumber.SIX;
const userNameValidation = Yup.string()
  .required('Username is required')
  .matches(
    /^[a-zA-Z0-9#]*$/,
    'Username should be alphanumeric with minimum 4 characters with no space or special character (except #) in it.'
  )
  .min(
    MagicNumber.FOUR,
    'Username must be longer than or equal to 4 characters'
  )
  .max(
    MagicNumber.FIFTY,
    'Username must be less than or equal to 50 characters'
  );

const getPasswordValidation = (requiredMessage: string) => {
  return Yup.string()
    .required(requiredMessage)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character'
    );
};

export const initialRegisterVal = {
  firstname: '',
  lastname: '',
  email: '',
  username: '',
  password: '',
  term_condition: false,
  share_persional_info: false,
  privacy_policy: false,
};

export const validateContactForm = Yup.object().shape({
  name: Yup.string().trim().required('Name is Required'),
  email: Yup.string().required('Email is Required').email('Invalid Email'),
  phone: Yup.string()
    .min(MagicNumber.TEN, `Mobile number must be ten digits`)
    .max(MagicNumber.TEN, `Mobile number must be ten digits`),
  subject: Yup.string()
    .trim()
    .required('Subject is Required')
    .max(
      MagicNumber.FIVE_H,
      `${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 500 characters`
    ),
  reason: Yup.string()
    .trim()
    .required('Reason is Required')
    .max(
      MagicNumber.FIVE_H,
      `${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 500 characters`
    ),
});

export const validateDeleteForm = Yup.object().shape({
  email: Yup.string().required('Email is Required').email('Invalid Email'),
  passwordType: getPasswordValidation('Password is required'),
});

export const validateSiginForm = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

export const validateRegisterForm = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email')
    .trim()
    .required('Email is required'),
  firstname: Yup.string()
    .required('First Name is required')
    .trim()
    .matches(
      /^(?!\s+$)/,
      'First Name must be longer than or equal to 1 characters'
    ),
  lastname: Yup.string()
    .required('Last Name is required')
    .trim()
    .matches(
      /^(?!\s+$)/,
      'Last Name must be longer than or equal to 1 characters'
    ),
});

export const validateEmail = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Email is required'),
});

export const validateOtp = Yup.object().shape({
  otp: Yup.string()
    .required('OTP is required')
    .min(MIN_OTP_LENGTH, 'Invalid Otp'),
});

export const validateResetPass = Yup.object().shape({
  password: getPasswordValidation('New Password is required'),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password entries do not match')
    .required('Confirm New Password is required'),
});
export const validateShippingAddressForm = Yup.object().shape({
  name: Yup.string()
    .min(MagicNumber.TWO, `${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 2 characters`)
    .max(
      MagicNumber.FIFTY_ONE,
      `${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 51 characters`
    )
    .required(VALIDATION_MESSAGES.NAME),
  address_1: Yup.string()
    .min(MagicNumber.TWO, `${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 2 characters`)
    .max(MagicNumber.TH, `${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 100 characters`)
    .required(VALIDATION_MESSAGES.ADDRESS),
  address_2: Yup.string()
    .min(MagicNumber.TWO, `${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 2 characters`)
    .max(
      MagicNumber.TH,
      `${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 100 characters`
    ),
  city: Yup.string()
    .min(MagicNumber.TWO, `${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 2 characters`)
    .max(
      MagicNumber.THIRTY,
      `${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 30 characters`
    )
    .required(VALIDATION_MESSAGES.CITY)
    .matches(/^(?![0-9]+$)[a-zA-Z ]{2,}$/, VALIDATION_MESSAGES.ALPHABETS),
  state: Yup.string().required(VALIDATION_MESSAGES.STATE),
  zip_code: Yup.string()
    .matches(/^\d+$/, VALIDATION_MESSAGES.DIGITS)
    .min(MagicNumber.FIVE, `${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 5 digits`)
    .max(MagicNumber.FIVE, `${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 5 digits`)
    .required(VALIDATION_MESSAGES.ZIP_CODE),
  phone: Yup.string()
    .required(VALIDATION_MESSAGES.MOBILE_NUMBER)
    .min(MagicNumber.FOURTEEN, `Mobile number is not valid`),
});
//
export const signUpFormValidation = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Invalid Email')
    .required('Email is required'),
  firstname: Yup.string()
    .trim()
    .required('First Name is required')
    .matches(
      /^(?!\s+$)/,
      'First Name must be longer than or equal to 1 characters'
    )
    .min(
      MagicNumber.ONE,
      'First Name must be longer than or equal to 1 characters'
    )
    .max(
      MagicNumber.TEN_FIVE,
      'First Name must be shorter than or equal to 25 characters'
    ),
  lastname: Yup.string()
    .trim()
    .required('Last Name is required')
    .matches(
      /^(?!\s+$)/,
      'Last Name must be longer than or equal to 1 characters'
    )
    .min(
      MagicNumber.ONE,
      'Last Name must be longer than or equal to 1 characters'
    )
    .max(
      MagicNumber.TEN_FIVE,
      'Last Name must be shorter than or equal to 25 characters'
    ),
  password: getPasswordValidation('Password is required'),
  username: userNameValidation,
});
export const deleteUserFormValidation = Yup.object().shape({
 // delete_password: getPasswordValidation('This cannot be blank'),
  delete_password: Yup.string()
    .required("This can't be blank "),
});

export const editProfileValidation = Yup.object().shape({
  firstname: Yup.string()
    .required('First Name is required')
    .matches(
      /^(?!\s+$)/,
      'First Name must be longer than or equal to 1 characters'
    )
    .min(
      MagicNumber.ONE,
      'First Name must be longer than or equal to 1 characters'
    )
    .max(
      MagicNumber.TEN_FIVE,
      'First Name must be shorter than or equal to 25 characters'
    ),
  lastname: Yup.string()
    .required('Last Name is required')
    .matches(
      /^(?!\s+$)/,
      'Last Name must be longer than or equal to 1 characters'
    )
    .min(
      MagicNumber.ONE,
      'Last Name must be longer than or equal to 1 characters'
    )
    .max(
      MagicNumber.TEN_FIVE,
      'Last Name must be shorter than or equal to 25 characters'
    ),
  username: userNameValidation,
  email: Yup.string().email('Invalid Email').required('Email is required'),
});

export const createPostValidation = Yup.object().shape({
  description: Yup.string().when('files_type', {
    is: 'text',
    then: Yup.string().required(VALIDATION_MESSAGES.POST_DESCRIPTION),
  }),
  files: Yup.array()
    .when('files_type', {
      is: 'image',
      then: Yup.array()
        .required(VALIDATION_MESSAGES.POST_IMAGE)
        .min(MagicNumber.ONE, VALIDATION_MESSAGES.POST_IMAGE_MIN_LIMIT)
        .max(
          FEED_POST_RESTRICTIONS.MAX_IMAGES,
          VALIDATION_MESSAGES.POST_IMAGE_MAX_LIMIT
        ),
    })
    .when('files_type', {
      is: 'video',
      then: Yup.array()
        .when('duration', {
          is: (value: number) =>
            value > FEED_POST_RESTRICTIONS.MAX_VIDEO_DURATION,
          then: Yup.array().max(
            MagicNumber.ZERO,
            VALIDATION_MESSAGES.POST_VIDEO_MAX_DURATION
          ),
        })
        .min(MagicNumber.ONE, VALIDATION_MESSAGES.POST_VIDEO)
        .max(
          FEED_POST_RESTRICTIONS.MAX_VIDEOS,
          VALIDATION_MESSAGES.POST_VIDEO_MAX_LIMIT
        ),
    }),
});

export const createCommentValidation = Yup.object().shape({
  comment: Yup.string().required(VALIDATION_MESSAGES.COMMENT_REQUIRED),
});

export const sharePostValidation = Yup.object().shape({
  description: Yup.string().required(
    VALIDATION_MESSAGES.SHARE_POST_DESCRIPTION
  ),
});

export const changePasswordValidation = Yup.object().shape({
  old_password: Yup.string().required(
    VALIDATION_MESSAGES.CURRENT_PASSWORD_REQUIRED
  ),
  new_password: getPasswordValidation(
    VALIDATION_MESSAGES.NEW_PASSWORD_REQUIRED
  ),
  confirm_password: Yup.string()
    .required(VALIDATION_MESSAGES.CONFIRM_PASSWORD_REQUIRED)
    .oneOf(
      [Yup.ref('new_password'), null],
      VALIDATION_MESSAGES.PASSWORDS_MUST_MATCH
    ),
});

export const reportContentValidation = Yup.object().shape({
  description: Yup.string().required(
    VALIDATION_MESSAGES.REPORT_CONTENT_REASON_REQUIRED
  ),
});

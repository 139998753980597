import { types, Instance } from 'mobx-state-tree';
import { MagicNumber } from '../../constants';

export const UserList = types.model({
  firstname: types.maybeNull(types.string),
  lastname: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  verified: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  role: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean),
  followersCount: types.maybeNull(types.number),
  followingCount: types.maybeNull(types.number),
  collection_count: types.maybeNull(types.number),
});

export const UserModal = types.model({
  current_page: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  limit: types.maybeNull(types.string),
  data: types.optional(types.array(UserList), [])
});

export const BidsList = types.model({
  amount: types.maybeNull(types.number),
  currency_code: types.maybeNull(types.string),
  currency_amount: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  product_id: types.maybeNull(types.number),
  payment_method: types.maybeNull(types.string),
  sku: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  tax_amount: types.maybeNull(types.number),
  currency_tax_amount: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  currency_total: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  user_id: types.maybeNull(types.number),
  users: types.maybeNull(UserList)
});

export const AuctionList = types.model({
  currency_min_bid: types.maybeNull(types.string),
  currency_base_price: types.maybeNull(types.string),
  currency_reserve_price: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.boolean),
  end_date_time: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  min_bid: types.number,
  product_id: types.maybeNull(types.number),
  reserve_price: types.maybeNull(types.number),
  start_date_time: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  base_price: types.number,
});

export const BidsModal = types.model({
  current_page: types.maybeNull(types.number),
  highestBid: types.maybeNull(types.number),
  currencyHighestBid: types.maybeNull(types.string),
  limit: types.maybeNull(types.number),
  page_total: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
  results: types.optional(types.array(BidsList), []),
  auctionData: types.frozen(AuctionList)
});

export const ComModel = types.model({
  attribute: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  value: types.maybeNull(types.string),
});

export const subProfileItem = types.model({
  id: types.maybeNull(types.number),
  profile_image: types.maybeNull(types.string),
});

export const CommentsResultsItems = types.model({
  id: types.number,
  comment: types.maybeNull(types.string),
  comment_count: types.maybeNull(types.string),
  full_count: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  sub_profilimage: types.optional(types.array(subProfileItem), []),
  sub_username: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  commentLength: MagicNumber.ONE_FIFTEEN,
});

export const MediaModel = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  entity_id: types.maybeNull(types.number),
  entity_type: types.maybeNull(types.string),
  filepath: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const AttrModel = types.model({
  attribute_id: types.number,
  name: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  options_id: types.maybeNull(types.number),
  details: types.model({})
});

export const VarModel = types.model({
  Color: types.maybeNull(types.string),
  Color_value: types.maybeNull(types.string),
  Size: types.maybeNull(types.string),
  Size_value: types.maybeNull(types.string),
  combination: types.optional(types.array(ComModel), []),
  createdAt: types.maybeNull(types.string),
  currency_price: types.maybeNull(types.string),
  currency_sale_price: types.maybeNull(types.string),
  discount: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  deletedAt: types.maybeNull(types.string),
  media: types.optional(types.array(types.maybeNull(MediaModel)), []),
  price: types.maybeNull(types.number),
  product_id: types.maybeNull(types.number),
  quantity: types.maybeNull(types.string),
  sale_price: types.maybeNull(types.number),
  sku: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  in_cart: types.maybeNull(types.boolean),
  in_wish: types.maybeNull(types.boolean),
});

export const CommentItem = types.model({
  current_page: types.maybeNull(types.number),
  limit: types.maybeNull(types.number),
  page_total: types.maybeNull(types.number),
  results: types.optional(types.array(CommentsResultsItems), []),
  total: types.maybe(types.string)
});

export const FileModel = types.model({
  description: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
});

const Content = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  creator_message: types.maybeNull(types.string),
  discount_code: types.maybeNull(types.string),
  audios: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  documents: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  images: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  videos: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  product_id: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
});

export const DetailModel = types.model({
  id: types.maybeNull(types.number),
  manufacturer: types.maybeNull(types.string),
  provenance: types.maybeNull(types.string),
  medias: types.optional(types.array(MediaModel), []),
  story: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.string),
  sku: types.maybeNull(types.string),
  attribute_data: types.optional(types.array(AttrModel), []),
  availability: types.maybeNull(types.string),
  digitalContent: Content,
  manufacturerDate: types.maybeNull(types.string),
  quantity: types.maybeNull(types.number),
  sale_start_date: types.maybeNull(types.string),
  manufacturer_country: types.maybeNull(types.string),
  material: types.maybeNull(types.string),
  weight: types.maybeNull(types.string),
  weight_unit: types.maybeNull(types.string),
  width: types.maybeNull(types.string),
  height: types.maybeNull(types.string),
  length: types.maybeNull(types.string),
  condition: types.maybeNull(types.string),
  discount: types.maybeNull(types.number),
  variants: types.optional(types.array(VarModel), []),
  sale_price: types.maybeNull(types.number),
  comment: types.optional(types.maybe(CommentItem), {}),
  sale_end_date: types.maybeNull(types.string),
  currency_price: types.maybeNull(types.string),
  currency_sale_price: types.maybeNull(types.string),
  variantCombinations: types.optional(types.array(types.string), []),
  in_cart: types.maybeNull(types.boolean),
  dimension_unit: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  sub_category: types.optional(types.array(types.string), []),
  in_wish: types.maybeNull(types.boolean),
});

type UserModalType = Instance<typeof UserModal>;
export interface ProfileModalTypeI extends UserModalType { }


export { CommandIcon } from './CommandIcon';export { CreateChannelIcon } from './CreateChannelIcon';
export { HamburgerIcon } from './HamburgerIcon';
export { LightningBoltSmall } from './LightningBoltSmall';
export { SendIcon } from './SendIcon';
export { XButton } from './XButton';
export { XButtonBackground } from './XButtonBackground';

export const getCleanImage = (member) => {
  return member.user.profileImage;
};

import { flow, types } from 'mobx-state-tree';
import { MagicNumber } from '../../constants';
import { withEnvironment } from '../extensions/with-environment';
import { MediaModel, MediaModels } from '../home-store/home-entity';

export const PendingPurchaseStoreModel = types
  .model('PendingPurchaseStore')
  .props({
    inProcess: types.optional(types.boolean, false),
    productImage: types.optional(types.maybeNull(MediaModel), null),
    productDetail: types.optional(types.frozen({}), null)
  })
  .extend(withEnvironment)
  .actions(self => ({
    getMorePendingPurchases: flow(function* (
      pageNumber: number,
      pageLimit: number
    ) {
      try {
        if (self.inProcess)
          return;

        self.inProcess = true;
        const response = yield self.environment.api.get(`order?page=${pageNumber}&limit=${pageLimit}`);
        self.inProcess = false;

        return response.data;
      } catch (error) {
        self.inProcess = false;
        throw error;
      }
    })
  }))
  .actions(self => ({
    getOrderDetail: flow(function* (orderId: number, itemId: number, collectionId?: number) {
      try {
        self.inProcess = true;

        let payload = {
          id: orderId * MagicNumber.ONE,
          item_id: itemId * MagicNumber.ONE
        };
        payload = collectionId ? {
          ...payload,
          ...{ collection_product_id: collectionId }
        } : payload;

        const response = yield self.environment.api.post('order/order-detail', payload);
        self.inProcess = false;
        return response.response.data;

      } catch (error) {
        self.inProcess = false;
        throw error;
      }
    })
  }))
  .actions(self => ({
    getOrderDetailScanCodeProduct: flow(function* (productId: number) {
      try {
        const response = yield self.environment.api.get(`resell-products/0/product/${productId}`);
        self.productDetail = response;
        if (response && response.data && response.data.medias && response.data.medias.length) {
          self.productImage = response.data.medias[MagicNumber.ZERO];
        } else {
          self.productImage = null;
        }
        return response.data;
      } catch (error) {
        self.inProcess = true;
        throw error;
      }
    })
  }));

import React from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';

interface IProps {
  setToggle: (e: boolean) => void;
  show: boolean;
  description: string;
  subHeading: string
};

const DigitalContentModel: React.FC<IProps> = ({ show, subHeading, description, setToggle }) => {

  return (
    <Modal
      size='sm'
      show={show}
      onHide={() => setToggle(false)}
      aria-labelledby='modal-sm'
      centered
      dialogClassName='remove-modal-dialog modal-dialog-centered'
      contentClassName='remove-modal-content'
    >
      <Modal.Header>
        <Modal.Title id='example-modal-sizes-title-sm'>{subHeading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{description}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type='button'
          className='btn btn-blank btn-centered w-50'
          onClick={() => setToggle(false)}
        >Cancel</button>
        <button type='button'
          className='btn btn-blank remove btn-centered w-50'
          onClick={() => setToggle(true)}
        >ok</button>
      </Modal.Footer>
    </Modal>
  );
};
export default DigitalContentModel;

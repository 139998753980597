import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useStores } from '../../../../models';
import './modal.scss';

type IProps = {
    openModal: any;
    isOpen: boolean;
    title: string;
    description: string;
}

export const TermAndCondition = ({ openModal, isOpen, title, description }: IProps) => {

    const rootStore = useStores();
    const [terms, setTerms] = useState();
    const [heading, setHeading] = useState()
    const [loader, setLoader] = useState(true);
    const { getTerms } = rootStore.staticPagesStore;

    const fetchData = async () => {
        await getTerms().then((res: any) => {
            setTerms(res.data.data[0].description);
            setHeading(res.data.data[0].title);
            setLoader(false)
        });
    }

    useEffect(() => {
        fetchData()
    }, []);

    return (
        <>
            <Modal
                size="sm"
                show={isOpen}
                onHide={() => openModal('')}
                aria-labelledby="modal-sm"
                centered
                dialogClassName="terms-condition-page modal-dialog-centered"
                contentClassName="page-content"
            >
                <div className="body-content">
                    <Modal.Header>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            {loader ? (<div className="banner-skeleton"><Skeleton /></div>) :
                                <h2 className="heading">{title}</h2>}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="description">
                            <div dangerouslySetInnerHTML={{ __html: terms! || "" }} ></div>
                        </p>
                    </Modal.Body>
                </div>
                <Modal.Footer className="agree-section ">
                    <button type="button" className="btn primary" onClick={() => openModal('cancel')}>Disagree</button>
                    <button type="button" className="btn secondary remove" onClick={() => openModal('agree')}>Agree</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

import React from 'react';
import { PRODUCT_PLACEHOLDER } from '../../../constants';

interface IProps {
    alt: string;
    className?: string;
    src: string;
    key?: any;
    onClick?: () => void;
    caption?: string;
    quantity?: string;
    parent_product_id?: number | null;
};

export const Image: React.FC<IProps> = (props) => {
    return (
        <>
            {props.quantity && !parseInt(props.quantity) ? <p className='out-stock-box'><span className='stock-text'>{props.parent_product_id ? 'Out of Stock' : 'Sold'}</span></p> : ''}
            <img {...props} onError={(e: any) => (e.target.onerror = null, e.target.src = PRODUCT_PLACEHOLDER)} />
            {props.caption && props.caption !== 'sale' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
        </>
    );
};

import wrapperComponent from '../../Templates/wrapperComponent';
import { useHistory } from 'react-router-dom';
import BackArrow from '../../../../assets/images/back-arrow.png';
import { useStores } from '../../../../models';
import { useEffect, useState } from 'react';
import FaqCard from '../../Molecules/faqCard/FaqCard';

const GuestFAQ = () => {
  const history = useHistory();
  const rootStore = useStores();
  
  const {getFaqList} = rootStore.staticPagesStore;
  const [faqList, setFaqList] = useState<any[]>([]);

  const fetchData = async() => {
      await getFaqList().then(res => setFaqList(res.data));
  };

  useEffect(() => {
      fetchData();
  }, []);

  return (
    <>
      <div className='static-page'>
        <div
          className='go-back'
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={BackArrow} alt='back' className='back-arrow' />
          <h4>Back</h4>
        </div>
        <div className='content-wrapper'>
          <h2 className='contactUs-heading'>FAQs</h2>
          <form className='contactUs-form'>
          <div className="container-faq">
                {faqList.map((faq)=>{
                    return <FaqCard faq={faq}/>;
                })}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};



export default wrapperComponent(true, true)(GuestFAQ);

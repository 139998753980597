import { Instance, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { AddressModel, CardModel, CartIdModel, YearModel, BuyNowItemModel, IntentData } from './add-card-entity';
import { errorToast } from '../../components/ui/Atoms';
import { toJS } from 'mobx';
import { HTTPSTATUSCODE, MagicNumber } from '../../constants';

export const CardStoreModel = types
  .model('CartStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, true),
    savedCard: types.maybeNull(CardModel),
    linkedCards: types.maybeNull(CardModel),
    isModelOpen: true,
    isLoadCardList: false,
    isLoadPlaceOrder: true,
    isCvcError: false,
    yearList: types.optional(types.array(YearModel), []),
    cardAddress: types.maybeNull(AddressModel),
    paymentIntentData: types.maybeNull(IntentData),
    cartId: types.optional(types.number, 0),
    cardId: types.optional(types.number, 0),
    productIdBuyNow: types.optional(types.number, 0),
    cartIdBuyNow: types.optional(types.number, 0),
    cardIds: types.maybeNull(types.string),
    isOrderPlaced: false,
    productSku: types.maybeNull(types.string),
    savedCvc: types.maybeNull(types.string),
    buyNowCartId: types.maybeNull(types.number),
    buyNowItem: types.optional(types.frozen(BuyNowItemModel), {}),
    stripe: types.optional(types.frozen(), {}),
    cardCvcElement: types.optional(types.frozen(), {}),
    clientSecret: types.optional(types.maybeNull(types.string), null),
    cvcStatus: false,
    buynow_tax_amount: types.optional(types.number, 0),
    isTaxAdd: false,
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getSavedCard: flow(function* () {
      self.state = 'pending';
      try {
        self.isLoading = true;
        const response = yield self.environment.api.get(`cards`);
        if (response.status === 200) {
          self.savedCard = response.data;
          self.cardId = response.data.cardData.id;
          self.cardIds = response.data.cardData.card_id;
        }
        self.isLoadCardList = true;
        self.state = 'done';
        self.isLoading = false;
        return self.cardIds;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    getLinkedCard: flow(function* () {
      self.state = 'pending';
      try {
        self.isLoading = true;
        const response = yield self.environment.api.get(`cards?card_type=sell`);
        if (response.status === 200) {
          self.linkedCards = response.data;
        }
        self.isLoadCardList = true;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    getYears() {
      let year = new Date().getFullYear();
      for (let i = MagicNumber.ONE; i <= MagicNumber.TEN; i++) {
        let iyear = year++;
        let years = { label: iyear, value: iyear };
        self.yearList.push(years);
      }
    },
    setAddress(address: any) {
      self.cardAddress = address;
    },
    setCartId(cartId: any) {
      self.cartId = cartId;
    },
    hidePopup() {
      self.isOrderPlaced = false;
    },
    setProductSku(sku: string | null) {
      self.productSku = sku;
    },
    setProductIdBuyNow(id: number) {
      self.productIdBuyNow = id;
    },
    setCartIdBuyNow(id: number) {
      self.cartIdBuyNow = id;
    },
    async saveCvc(cvc: string | null) {
      self.savedCvc = cvc;
    },
    async checkCvc() {
      if (!self.savedCvc || self.savedCvc === '') {
        self.isCvcError = true;
        return true;
      } else if (self.savedCvc && self.savedCvc.length < MagicNumber.THREE) {
        self.isCvcError = true;
        return true;
      } else {
        self.isCvcError = false;
        return false;
      }
    }
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    addCard: flow(function* (cardDetails) {
      self.state = 'pending';
      try {
        const res = yield self.environment.api.post(`cards`, cardDetails);
        yield self.getSavedCard();
        if (res.kind === 'ok') {
          self.isModelOpen = false;
        }
        self.state = 'done';
        self.isLoading = false;
      } catch (error: any) {
        errorToast(error.data.message);
        self.state = 'error';
        throw error;
      }
    }),
    linkCardApi: flow(function* (linkCardDetails) {
      self.state = 'pending';
      try {
        const res = yield self.environment.api.post(`cards/debit-card`, linkCardDetails);
        yield self.getLinkedCard();
        if (res.kind === 'ok') {
          self.isModelOpen = false;
        } else if (res.status === HTTPSTATUSCODE.BAD_REQUEST) {
          errorToast(res.data.message);
        }
        self.state = 'done';
        self.isLoading = false;
        return res;
      } catch (error: any) {
        errorToast(error.data.message);
        self.state = 'error';
        throw error;
      }
    }),
    openMd() {
      self.isModelOpen = true;
    }
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    deleteCard: flow(function* (cardId: number) {
      self.state = 'pending';
      try {
        self.isLoading = true;
        const response = yield self.environment.api.delete(`cards/${cardId}`);
        self.savedCard = null;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    placeOrder: flow(function* (orderData) {
      self.state = 'pending';
      try {
        const res = yield self.environment.api.post(`order`, orderData);
        if (res && res.kind === 'ok') {
          self.isOrderPlaced = true;
        } else if (!res.ok) {
          errorToast(res.data.message);
        } else {
          errorToast('Internal server error.');
        }
        self.state = 'done';
        self.isLoading = false;
      } catch (error: any) {
        errorToast(error.data.message);
        self.state = 'error';
        throw error;
      }
    })
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
      self.isLoadPlaceOrder = true;
    },
    buynow: flow(function* (buyNowData) {
      self.state = 'pending';
      self.isLoadPlaceOrder = true;
      try {
        const res = yield self.environment.api.post(`carts/buy-now`, buyNowData);
        self.state = 'done';
        self.isLoadPlaceOrder = false;
        if (res && res.response && res.response.data && res.response.data.data) {
          if (!res.response.data.data.tax_total || res.response.data.data.tax_total === '0' || res.response.data.data.tax_total === 0) {
            self.isTaxAdd = true;
          } else {
            self.isTaxAdd = false;
          }
          self.buynow_tax_amount = res.response.data.data.tax_total;
          self.buyNowCartId = res.response.data.data.id;
          self.cartId = res.response.data.data.id;
          const cartResponseData = res.response.data.data.cart_items[0];
          let buyNowCartdata = {
            id: cartResponseData && cartResponseData.id,
            totalPrice: cartResponseData && cartResponseData.curency_price,
            name: cartResponseData && cartResponseData.name,
            condition: cartResponseData && cartResponseData.product_info && cartResponseData.product_info.condition,
            variant_data: cartResponseData && cartResponseData.variant_data,
            medias: cartResponseData && cartResponseData.product_info && cartResponseData.product_info.medias && cartResponseData.product_info.medias.map((item: any) => {
              let mediaValue = {
                filePath: item.filepath,
              };
              return mediaValue;
            })
          };
          self.buyNowItem = buyNowCartdata;
        }
        return res;
      } catch (error: any) {
        errorToast(error && error.data && error.data.message);
        self.state = 'error';
        throw error;
      }
    }),
    taxApiBuyNow: flow(function* (couponData) {
      self.state = 'pending';
      try {
        const res = yield self.environment.api.post(`avalara-tax/buy-now`, couponData);
        if (res && res.kind === 'ok') {
          if (res.response.data && typeof (res.response.data) === 'string') {
            self.buynow_tax_amount = parseFloat(res.response.data);
          } else if (typeof (res.response.data) === 'number') {
            self.buynow_tax_amount = res.response.data;
          } else {
            self.buynow_tax_amount = 0;
          }
        } else if (res.status === HTTPSTATUSCODE.BAD_REQUEST) {
          self.buynow_tax_amount = self.buynow_tax_amount;
          errorToast(res.data.message);
        }
        self.state = 'done';
        self.isLoading = false;
        return self.buynow_tax_amount;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
      self.isLoadPlaceOrder = true;
    },
    getPaymentIntent: flow(function* (userData) {
      self.state = 'pending';
      self.isLoadPlaceOrder = true;
      try {
        const res = yield self.environment.api.post(`cards/payment-intent`, userData);
        if (res && res.kind && res.kind === 'ok') {
          self.clientSecret = res && res.response && res.response.data && res.response.data.data && res.response.data.data.client_secret;
          const data = {
            client_secret: res && res.response && res.response.data && res.response.data.data && res.response.data.data.client_secret,
            id: res && res.response && res.response.data && res.response.data.data && res.response.data.data.id
          };
          self.paymentIntentData = data;
        }
        self.state = 'done';
        self.isLoadPlaceOrder = false;
        return { res, cardId: self.cardIds };
      } catch (error: any) {
        errorToast(error.data.message);
        self.state = 'error';
        throw error;
      }
    }),
    setStripe(str: any) {
      self.stripe = str;
    },
    setCvcElement(element: any) {
      self.cardCvcElement = element;
    },
    setCvcStatus(status: boolean) {
      self.cvcStatus = status;
    }
  }));

type CardStoreType = Instance<typeof CardStoreModel>;
export interface CardStore extends CardStoreType { }


import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { toJS } from 'mobx';
import { useStores } from '../../../../models';
import FullPageLoader from '../../Atoms/FullPageLoader';
import './stripe.scss'
import { Input, Dropdown, ZipInput } from '../../Atoms';
import { StatusCode } from '../../../../constants/apiStatusCode';
import { MagicNumber, monthList, VALIDATION_MESSAGES } from '../../../../constants';
import { PlaceOrder } from '../modal/PlaceOrder';
import { useParams, useHistory } from "react-router-dom";

export const CustomStripe = ({ info, currentStep }) => {
  const rootStore = useStores();
  const [cardNumber, setCardNumber] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cvcNumber, setCvcNumber] = useState('');
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageCard, setErrorMessageCard] = useState('');
  const [errorMessageMonth, setErrorMessageMonth] = useState('');
  const [errorMessageCvc, setErrorMessageCvc] = useState('');
  const [errorMessageYear, setErrorMessageYear] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [futureCheckboxValue, setFutureCheckboxValue] = useState(false);
  const [sameAsShippingAddress, setSameAsShippingAddress] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [stateList, setStateList] = useState([]);
  var UsaStates = require('usa-states').UsaStates;
  var usStates = new UsaStates();
  const [country] = useState('US');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [errorMessageAd1, setErrorAd1] = useState('');
  const [errorMessageCity, setErrorCity] = useState('');
  const [errorMessageZipcode, setErrorZipcode] = useState('');
  const [errorMessageState, setErrorState] = useState('');
  const history = useHistory();
  const { addCard, getSavedCard,
    getYears, yearList,
    cardAddress,
    hidePopup, isOrderPlaced,
    savedCard
  } = rootStore.cardStore;
  const { savedAddressList } = rootStore.settingStore;
  const params = useParams();

  const { cartId, addPaymentCardDetail, addPaymentCardInfo } = rootStore.cartStore;
  const initialValue = [];
  const { ZERO } = MagicNumber;

  const loadStripe = async () => {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(process.env.REACT_APP_STRIPE_PUBISHABLE_KEY);
      }
      window.document.body.appendChild(s);
    }
  }

  const fetchYear = async () => {
    await getYears();
  }

  useEffect(() => {
    loadStripe();
    fetchYear();
    const options = usStates.states;
    const updateStateOptions = options.map((states) => ({
      label: states.name,
      value: states.abbreviation,
    }));
    setStateList(updateStateOptions);
  }, []);

  const pay = (e) => {
    e.preventDefault();
    let errorState = false;
    if (cardNumber === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageCard(VALIDATION_MESSAGES.CARD);
    }
    if (year === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageYear(VALIDATION_MESSAGES.EXPIRY_YEAR);
    }
    if (month === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageMonth(VALIDATION_MESSAGES.EXPIRY_MONTH)
    }
    if (cvcNumber === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorMessageCvc(VALIDATION_MESSAGES.EXPIRY_CVC);
    }
    if (cvcNumber.length < MagicNumber.THREE) {
      errorState = true;
      setErrorMessageCvc(VALIDATION_MESSAGES.EXPIRY_CVCN);
    } else {
      setErrorMessageCvc('');
    }
    if (addressLine1 === VALIDATION_MESSAGES.EMPTY || addressLine1.length < MagicNumber.TWO || addressLine1.length > MagicNumber.TH) {
      errorState = true;
      if (addressLine1 === VALIDATION_MESSAGES.EMPTY) {
        setErrorAd1(VALIDATION_MESSAGES.ADDRESS);
      } else if (addressLine1.length < MagicNumber.TWO) {
        setErrorAd1(`${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 2 characters`);
      } else {
        setErrorAd1(`${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 100 characters`)
      }
    }
    if (city === VALIDATION_MESSAGES.EMPTY || city.length < MagicNumber.TWO || city.length > MagicNumber.THIRTY) {
      errorState = true;
      if (city === VALIDATION_MESSAGES.EMPTY) {
        setErrorCity(VALIDATION_MESSAGES.CITY);
      } else if (city.length < MagicNumber.TWO) {
        setErrorCity(`${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 2 characters`);
      } else {
        setErrorCity(`${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 30 characters`);
      }
    }
    if (state === VALIDATION_MESSAGES.EMPTY) {
      errorState = true;
      setErrorState(VALIDATION_MESSAGES.STATE);
    }
    if (zipcode === VALIDATION_MESSAGES.EMPTY || zipcode.length < MagicNumber.TWO || zipcode.length > MagicNumber.FIVE) {
      errorState = true;
      if (zipcode === VALIDATION_MESSAGES.EMPTY) {
        setErrorZipcode(VALIDATION_MESSAGES.ZIP_CODE);
      } else if (zipcode.length < MagicNumber.TWO) {
        setErrorZipcode(`${VALIDATION_MESSAGES.MIN_CHAR_LIMIT} 2 digits`);
      } else {
        setErrorZipcode(`${VALIDATION_MESSAGES.MAX_CHAR_LIMIT} 5 digits`)
      }
    }
    if (errorState) {
      return;
    }
    setLoader(true);
    const userId = Cookies.get(VALIDATION_MESSAGES.USER_ID) || VALIDATION_MESSAGES.EMPTY;
    let addressId;
    if (!cardAddress && savedAddressList.length) {
      savedAddressList.forEach((element) => {
        if (element.is_default) {
          addressId = element.id
        }
      })
    } else {
      addressId = cardAddress && cardAddress.address_id;
    }
    if (params.buy) {
      window.Stripe.card.createToken({
        number: cardNumber,
        exp_month: month,
        exp_year: year,
        name: cardHolderName,
        address_country: country,
        address_city: city,
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_state: state.label,
        address_zip: zipcode,
      }, async (status, response) => {
        if (status === StatusCode.OK) {
          const ad_id = addressId;
          const dataBuyNow = {
            user_id: parseInt(userId),
            cart_id: rootStore.cardStore.cartId,
            address_id: ad_id,
            source_token: response.id,
            card_id: null,
            is_card_save: futureCheckboxValue,
            accept_terms_condition: true,
            allow_share_info: true
          };
          const paymentCardInfo = {
            name: response && response.card.name,
            exp_month: response && response.card && response.card.exp_month,
            exp_year: response && response.card && response.card.exp_year,
            brand: response && response.card && response.card.brand,
            last4: response && response.card && response.card.last4
          }
          addPaymentCardDetail(dataBuyNow);
          addPaymentCardInfo(paymentCardInfo);
          currentStep(MagicNumber.FOUR);
          setOpenPopup(true);
          setLoader(false);
        } else {
          setErrorMessage(response.error.message);
          setLoader(false)
        }
      });
    } else {
      window.Stripe.card.createToken({
        number: cardNumber,
        exp_month: month,
        exp_year: year,
        name: cardHolderName,
        address_country: country,
        address_city: city,
        address_line1: addressLine1,
        address_line2: addressLine2,
        address_state: state.label,
        address_zip: zipcode,
      }, async (status, response) => {
        if (status === StatusCode.OK) {
          const data = {
            user_id: parseInt(userId),
            source_token: response.id,
            is_card_save: info === VALIDATION_MESSAGES.ADD ? true : futureCheckboxValue
          }
          if (info === VALIDATION_MESSAGES.CART) {
            const paymentData = {
              user_id: parseInt(userId),
              cart_id: cartId,
              address_id: addressId,
              source_token: response.id,
              card_id: null,
              is_card_save: futureCheckboxValue,
              accept_terms_condition: true,
              allow_share_info: true
            };
            const paymentCardInfo = {
              name: response && response.card.name,
              exp_month: response && response.card && response.card.exp_month,
              exp_year: response && response.card && response.card.exp_year,
              brand: response && response.card && response.card.brand,
              last4: response && response.card && response.card.last4
            }
            addPaymentCardDetail(paymentData);
            addPaymentCardInfo(paymentCardInfo);
            currentStep(MagicNumber.FOUR);
            setOpenPopup(true);
            setLoader(false);
          } else {
            await addCard(data);
            await getSavedCard(data);
            setLoader(false);
          }
        } else {
          setErrorMessage(response.error.message);
          setLoader(false)
        }
      });
    }
  }

  const selectMonth = (e) => {
    setMonth(e.value);
  }
  const selectYear = (e) => {
    setYear(e.label);
  }

  const setFieldValue = (name, value) => {
    const card = value.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
    let a = card.replace(/\s+/g, ' ').trim();
    setCardNumber(a);
  }

  const handleKeyPress = (evt) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > MagicNumber.THIRTY_ONE && (charCode < MagicNumber.FOURTY_EIGHT || charCode > MagicNumber.FIFTY_SEVEN)) {
      evt.preventDefault();
    }
    return true;
  }

  const addCardForFuture = (event) => {
    setFutureCheckboxValue(event.target.checked);
  }
  const defaultAddress = () => {
    return toJS(savedAddressList).filter((e) => e.is_default);
  }

  const fillShippingAddressData = (event) => {
    if (event.target.checked) {
      setAddressLine1(defaultAddress()[ZERO].address_1);
      setAddressLine2(defaultAddress()[ZERO].address_2);
      setCity(defaultAddress()[ZERO].city);
      setState({
        value: defaultAddress()[ZERO].state,
        label: defaultAddress()[ZERO].state,
      });
      setZipcode(defaultAddress()[ZERO].zip_code);
    }
    else {
      setAddressLine1('');
      setAddressLine2('');
      setCity('');
      setState('');
      setZipcode('');
    }
  }

  useEffect(() => {
    defaultAddress();
    setSameAsShippingAddress(!!defaultAddress().length)
  }, []);

  const toggleCard = () => {
    setOpenPopup(false);
    window.location.href = '/';
    hidePopup();
  }

  const setCardHolderNames = (value) => {
    const regex = /^[a-zA-Z ]*$/;
    if (regex.test(value)) {
      setCardHolderName(value);
    }
  }

  return (
    <div className="container">
      {loader && <FullPageLoader />}
      {isOrderPlaced ? <PlaceOrder
        isOpen={openPopup}
        openModal={() => { toggleCard(); }}
      /> : ''}
      {history && history.location && !history.location.pathname.includes('setting') && history.location.pathname.includes('bids') ? <p className="note-message">
        <span>Note: Your card will be validated but not charged. Amount will be charged when your bid is accepted by the seller.</span> </p> : ''}
      <div className="row">
        <form className="form-section" onSubmit={(e) => pay(e)}>
          <div className="col add-card-col">
            <div className="panel panel-default">
              <div className="form-card panel-body">
                <div className="form-group floating-label-group">
                  <Input
                    value={cardNumber}
                    setFieldValue={(name, value) => setFieldValue(name, value)}
                    type="text"
                    id="Card Number"
                    name="Card Number"
                    placeholder="Card Number*"
                    maxLength={MagicNumber.NINETEEN}
                    handleKeyPress={handleKeyPress}
                  />
                  {!loader && cardNumber === '' && errorMessageCard !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageCard}
                  </label> : ''}
                </div>
                <div className="form-group floating-label-group last-form-margin">
                  <Input
                    value={cardHolderName}
                    setFieldValue={(name, value) => setCardHolderNames(value)}
                    type="text"
                    id="Card Holder"
                    name="Card Holder"
                    placeholder="Card Holder"
                    maxLength={MagicNumber.TWENTY}
                  />
                </div>
                <div className="form-group floating-label-group">
                  <Dropdown
                    className="reactSelect"
                    classNamePrefix="react-select"
                    values={initialValue}
                    onChange={(e) => selectMonth(e)}
                    options={monthList}
                    placeholder="Expiry Month*"
                  />
                  <span className={`select-label ${month && 'selected'}`}>Expiry Month</span>
                  {!loader && month === '' && errorMessageMonth !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageMonth}
                  </label> : ''}
                </div>
                <div className="form-group floating-label-group">
                  <Dropdown
                    className="reactSelect"
                    classNamePrefix="react-select"
                    values={initialValue}
                    onChange={(e) => selectYear(e)}
                    options={yearList}
                    placeholder="Expiry Year*"
                  />
                  <span className={`select-label ${year && 'selected'}`}>Expiry Year</span>
                  {!loader && year === '' && errorMessageYear !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageYear}
                  </label> : ''}
                  <label htmlFor="" className="global-error-msg" >
                    {!loader && year && month && cardNumber ? errorMessage : ''}
                  </label>
                </div>
                <div className="form-group floating-label-group">
                  <Input
                    value={cvcNumber}
                    setFieldValue={(name, value) => setCvcNumber(value)}
                    type="text"
                    id="CVC"
                    name="CVC"
                    placeholder="CVC*"
                    maxLength={MagicNumber.FOUR}
                    handleKeyPress={handleKeyPress}
                  />
                  {!loader && (cvcNumber === '' || cvcNumber.length < 3) && errorMessageCvc !== '' ? <label htmlFor="" className="error-msg">
                    {errorMessageCvc}
                  </label> : ''}
                </div>
                {info === 'cart' && !(savedCard && savedCard.cardData && savedCard.cardData.card_id) ? <div className="save-check-section save-label-card">
                  <div className="checkbox-custom">
                    <label className="label-text">
                      <input
                        type="checkbox"
                        className="box-check"
                        id="share_persional_info"
                        value={futureCheckboxValue}
                        onChange={(event) => addCardForFuture(event)}
                        name="share_persional_info" />
                      <strong></strong>Save this card for the future payments
                    </label>
                  </div>
                </div> : ''}
              </div>
            </div>
          </div>
          <div className="billing-address-container">
            <h2>Add Billing Address</h2>
            {sameAsShippingAddress ? (
              <div className="pre-fill-address-data">
                <div className="checkbox-custom">
                  <label className="label-text">
                    <input
                      type="checkbox"
                      className="box-check"
                      id="share_persional_info"
                      onChange={(event) => fillShippingAddressData(event)}
                      name="share_persional_info" />
                    <strong></strong>Same as shipping.
                  </label>
                </div>
              </div>
            ) : (<></>)}
            <div className="form-group floating-label-group">
              <Input
                readOnly
                type="text"
                id="country"
                name="country"
                placeholder="Country"
                value={country}
              />
            </div>
            <div className="form-group floating-label-group">
              <Input
                type="text"
                id="address_1"
                name="address_1"
                placeholder="Address Line 1*"
                value={addressLine1}
                setFieldValue={(name, value) => setAddressLine1(value)}
              />
              {!loader && (addressLine1 === '' || addressLine1.length < 2 || addressLine1.length > 100) && errorMessageAd1 !== '' ? <label htmlFor="" className="error-msg">
                {errorMessageAd1}
              </label> : ''}
            </div>
            <div className="form-group floating-label-group">
              <Input
                type="text"
                id="address_2"
                name="address_2"
                placeholder="Address Line 2(Optional)"
                value={addressLine2}
                setFieldValue={(name, value) => setAddressLine2(value)}
              />
            </div>
            <div className="form-group floating-label-group">
              <Input
                type="text"
                id="city"
                name="city"
                placeholder="City*"
                value={city}
                setFieldValue={(name, value) => setCity(value)}
              />
              {!loader && (city === '' || city.length < 2 || city.length > 30) && errorMessageCity !== '' ? <label htmlFor="" className="error-msg">
                {errorMessageCity}
              </label> : ''}
            </div>
            <div className="form-group floating-label-group">
              <Select
                className="reactSelect"
                classNamePrefix="react-select"
                options={stateList}
                onChange={(value) => setState(value)}
                value={state}
                placeholder="State*"
              />
              <span className={`select-label ${state && 'selected'}`}>State</span>
              {!loader && state === '' && errorMessageState !== '' ? <label htmlFor="" className="error-msg">
                {errorMessageState}
              </label> : ''}
            </div>
            <div className="form-group floating-label-group">
              <ZipInput
                type="text"
                id="zip_code"
                name="zip_code"
                placeholder="Zip Code*"
                value={zipcode}
                setFieldValue={(name, value) => setZipcode(value)}
                maxLength={MagicNumber.FIVE}
              />
              {!loader && (zipcode === '' || zipcode.length < 2 || zipcode.length > 5) && errorMessageZipcode !== '' ? <label htmlFor="" className="error-msg">
                {errorMessageZipcode}
              </label> : ''}
            </div>
            {info !== "cart" ? <div className="modal-btn-group">
              <button
                className="btn primary"
              >Save
              </button>
            </div> : <div className="modal-btn-group">
              <button
                className="btn primary"
              >Continue
              </button>
            </div>}
          </div>
        </form>
      </div>
    </div>
  );
}

export default CustomStripe;

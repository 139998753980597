import { types } from 'mobx-state-tree';

export const CollectionData = types.model({
  category_ids: types.optional(types.array(types.number), []),
  collection_name: types.maybeNull(types.string),
  display_on_home: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  logo: types.maybeNull(types.string)
});
export const CollectorData = types.model({
  firstname: types.maybeNull(types.string),
  lastname: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
});

export const MediaModel = types.model({
  id: types.maybeNull(types.number),
  filePath: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});
export const MyCollectionData = types.model({
  order_id: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  product_id: types.maybeNull(types.number),
  collection_product_id: types.maybeNull(types.number),
  slug: types.maybeNull(types.string),
  product_type: types.maybeNull(types.string),
  is_private: types.maybeNull(types.boolean),
  medias: types.optional(types.array(MediaModel), []),
  product_image: types.maybeNull(types.string),
  auction_status: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  new_order_item_id: types.maybeNull(types.number),
  new_order_id: types.maybeNull(types.number),

});

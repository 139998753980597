import { types } from 'mobx-state-tree';

export const ProfileModel = types.model({
    statusCode: types.maybeNull(types.number),
    msg: types.maybeNull(types.string),
});

export const VerifiedModel = types.model({
    statusCode: types.maybeNull(types.number),
    message: types.maybeNull(types.string),
    kind: types.maybeNull(types.string),
});

export const GoogleModel = types.model({
    email: types.maybeNull(types.string),
    firstname: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    googleId: types.maybeNull(types.string),
    profile_image: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    provider: types.maybeNull(types.string),
    isSkip: types.maybeNull(types.boolean),
});

export const UserModel = types.model({
    active: types.maybeNull(types.boolean),
    apple_id: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.string),
    current_hashed_refresh_token: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    firstname: types.maybeNull(types.string),
    google_id: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    is_admin: types.maybeNull(types.boolean),
    is_deleted: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    privacy_policy: types.maybeNull(types.boolean),
    profile_image: types.maybeNull(types.string),
    role: types.maybeNull(types.string),
    share_persional_info: types.maybeNull(types.boolean),
    term_condition: types.maybeNull(types.boolean),
    updatedAt: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    verification_code_valid_till: types.maybeNull(types.string),
    verified: types.maybeNull(types.boolean),
    voucherify_customer_id: types.maybeNull(types.string),
});

export const NotificationArray = types.model({
    createdAt: types.maybeNull(types.string),
    deletedAt: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
    user_id: types.maybeNull(types.number),
    message: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    link: types.maybeNull(types.string),
    type_id: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    read: types.maybeNull(types.boolean),
});

export const NotificationModel = types.model({
    current_page: types.maybeNull(types.string),
    limit: types.maybeNull(types.string),
    page_total: types.optional(types.number, 0),
    total: types.optional(types.string, '0'),
    results: types.optional(types.array(NotificationArray), [])
});

import moment from 'moment';
import { MagicNumber } from './MagicNumber';
import { PUMA_LOGO, NIKE_LOGO, FRANKLIN_IMG, GAP_LOGO, LEVIS_LOGO, FEDEX_LOGO, UPS_LOGO, USPS_LOGO } from './image';
export * from './image';
export * from './MagicNumber';
export * from './validationScheme'; 
export * from './constants';
export * from './Routes';

export const collections = [PUMA_LOGO, NIKE_LOGO, FRANKLIN_IMG, GAP_LOGO, LEVIS_LOGO];

export const FILTERS = {
  PRICE: [
    { title: '$0-$1000', min_price: MagicNumber.ZERO, max_price: MagicNumber.ONE_TH, },
    { title: '$1k - $5k', min_price: MagicNumber.ONE_TH, max_price: MagicNumber.FIVE_H_TH, },
    { title: '$5k - $10k', min_price: MagicNumber.FIVE_H_TH, max_price: MagicNumber.TEN_TH, },
    { title: '$10k - $25k', min_price: MagicNumber.TEN_TH, max_price: MagicNumber.TWOFIVE_TH, },
    { title: '$25k - $50k', min_price: MagicNumber.TWOFIVE_TH, max_price: MagicNumber.FIFTY_TH, },
    { title: '$50k +', min_price: MagicNumber.FIFTY_TH, max_price: MagicNumber.ONE_TH_MILLION }
  ],
  manufacture_Years: [
    { title: '2020', value: { from: '2020', to: '2021' } },
    { title: '2010', value: { from: '2010', to: '2019' } },
    { title: '2000', value: { from: '2000', to: '2009' } },
    { title: '1990', value: { from: '1990', to: '1999' } },
    { title: '1980', value: { from: '1980', to: '1989' } },
    { title: '1970', value: { from: '1970', to: '1979' } },
    { title: '1960', value: { from: '1960', to: '1969' } },
    { title: '1950', value: { from: '1950', to: '1959' } },
    { title: '1940', value: { from: '1940', to: '1949' } },
    { title: '1930', value: { from: '1930', to: '1939' } },
    { title: '1920', value: { from: '1920', to: '1929' } },
    { title: '1910', value: { from: '1910', to: '1919' } },
    { title: '1900', value: { from: '1900', to: '1909' } },
    { title: 'Late 19th Century', value: { from: '1868', to: '1899' } },
    { title: 'Mid 19th Century', value: { from: '1830', to: '1867' } },
    { title: 'Early 19th Century', value: { from: '1800', to: '1829' } },
    { title: '18th Century and Earlier', value: { from: '901', to: '1800' } },
  ],
  SORT: ['Recently Added', 'Price - Low to high', 'Price - High to low']
};

export const getDate = (date: any, status?: boolean) => {
  return moment(date).format('YYYY');
};

export const priceIndex = (index: number) => {
  return FILTERS.PRICE.filter((e) => e.max_price === index);
};

export const PARAMKEY = ['category_id', 'color', '', 'manufacture_Years', '', 'collection_id'];

export const isEmpty = (a: any): boolean | undefined => {
  return Array.isArray(a) && a.every(isEmpty);
};

export const handleEnter = (event: any) => {
  if (event.keyCode === MagicNumber.THIRTEEN) {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    if (index === MagicNumber.ONE) {
      form.elements[index + MagicNumber.ONE].click();
    } else {
      form.elements[index + MagicNumber.ONE].focus();
    }
    event.preventDefault();
  }
};

export const getParam = (search: string | undefined) => {
  if (!search) return {};
  let param = search.substring(MagicNumber.ONE);
  return JSON.parse('{"' + decodeURI(param).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
};

export const filteredValues = (arr: any) => {
  if (arr.length === MagicNumber.ONE) return arr;
  return arr.reduce((acc: any, current: any) => {
    const x = acc.find((item: any) => item.title === current.title);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};

export const profilePic = [
  'icons/dummy1.png', 'icons/dummy2.png',
  'icons/dummy3.png', 'icons/dummy4.png', 'icons/dummy5.png',
  'icons/dummy6.png', 'icons/dummy7.png', 'icons/dummy8.png',
  'icons/dummy9.png', 'icons/dummy10.png', 'icons/dummy11.png',
  'icons/dummy12.png', 'icons/dummy13.png', 'icons/dummy14.png', 'icons/dummy15.png',
];
export const CONTENT = ['audios', 'documents', 'images', 'videos'];

export const TOKEN = 'token';
export const USER_ID = 'user_id';
export const USER_DATA = 'user_data';
export const ROLE = 'role';
export const USER_IMAGE = 'user_profile';
export const USER_NAME = 'user_name';
export const REFRESH_TOKEN = 'refresh';
export const FEED_USER_ID = 'feed_user_id';
export const STREAM_TOKEN = 'stream';
export const ERROR_MSG = 'Social Id not exist!';
export const ACCOUNT_EXIST = 'Account already exist with this email.';
export const INVALID_CRED = 'Invalid Credentials!';
export const INVALID_EMAIL = "Email does't exist kindly register!";
export const OTP_SENT = 'OTP has been sent successfully!';
export const USER_CREATED = 'User created successfully!';
export const OTP_RESEND = 'OTP has been resent successfully!';
export const PROFILE_SUCCESS = 'Profile created successfully!';
export const S3_IMAGE_ERROR = 'Unable to upload image on s3 bucket';
export const S3_VIDEO_ERROR = 'Unable to upload video on s3 bucket';
export const Notify_SUCC = 'We will notify you when this product is available!';
export const Notify_DROP = 'We will notify you when this drop is available!';
export const Notify_ERROR = 'Please login and then try again.';
export const SIZE_ERR = 'Please select size';
export const COLOR_ERR = 'Please select color';

export const EMAIL_SUCCESS = 'Your email has been verified successfully!';
export const OTP_VERIFIED = 'OTP_VERIFIED';
export const USER_VERIFIED = 'USER_VERIFIED';
export const NOT_VERIFIED = 'NOT_VERIFIED';
export const PROFILE_COM = 'PROFILE_COM';
export const UNAUTHORIZED = 'Unauthorized';
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';

export const USER_DIR_NAME = 'users/profile';
export const FEED_DIR_NAME_IMAGES = 'latest/images';
export const FEED_DIR_NAME_VIDEOS = 'latest/videos';
export const REGION = 'us-east-1';
// sale date age ki ho current date

export const ROUTE_PATH = {
  SETTING: '/setting',
};

export const EMAIL = 'email';
export const USERNAME = 'username';
export const ALL = 'All';
export const INACTIVE_USER = 'Your account has been deactivated. Please contact Admin to activate it.';

export const getShipmentLogo = (name: string) => {
  let logo = '';
  if (name === 'fedex') {
    logo = FEDEX_LOGO;
  } else if (name === 'ups') {
    logo = UPS_LOGO;
  } else if (name === 'usps') {
    logo = USPS_LOGO;
  } else {
    return false;
  }
  return logo;
};

export const getPlatformInfo = () => {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  if (isMobileDevice) {
    return 'mobile';
  } else {
    return 'desktop';
  }
};

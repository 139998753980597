import { observer } from 'mobx-react-lite';
import wrapperComponent from '../../Templates/wrapperComponent';
import { BLACK_ICON } from '../../../../constants/image';
import './_feed-detail.scss';
import { FeedDetailCarousel } from './FeedDetailCarousel';
import { FeedItemSocials } from '../feedListing/feedItem/FeedItemSocials';
import { FeedItemCommentInput } from '../feedListing/feedItem/FeedItemCommentInput';
import { FeedItemUserComment } from '../feedListing/feedItem/FeedItemUserComment';
import { useEffect, useState } from 'react';
import {
  FEED_POST_RESTRICTIONS, FunctionalityType, getFeedTime, MagicNumber,
  PAGE_ROUTES, reportContentValidation
} from '../../../../constants';
import { StreamClient, StreamFeed } from 'getstream';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchParameters } from '../../../../data/SearchParameters';
import { useStores } from '../../../../models';
import { errorToast, successToast } from '../../Atoms';
import { CompleteProfileModal, SignInModal } from '../../Molecules';
import { SignupModal } from '../../Molecules/signupModal/signupModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeedItemUserDetails } from '../feedListing/feedItem/FeedItemUserDetails';
import { SharePost } from '../../Molecules/sharePostModal/SharePost';
import { FeedItem } from '../feedListing/feedItem/FeedItem';
import { FeedDetailSkeleton } from '../feedListing/feedItem/skeletons/FeedDetailSkeleton';
import { FeedItemUserCommentSkeleton } from '../feedListing/feedItem/skeletons/FeedItemUserCommentSkeleton';
import { ReactionsList } from '../../Molecules/reactionsListModal/ReactionsList';
import { FeedItemSkeleton } from '../feedListing/feedItem/skeletons/FeedItemSkeleton';
import {
  ReactionType, getReactionTypeName, isFeedItemShared, FeedGroup, getFeedItemUserID,
  PostType, getFeedItemUserName, isAdminId
} from '../feedListing/FeedListingUtil';
import { ConfirmationBox } from '../../Molecules/confirmationBoxModal/ConfirmationBox';
import { ConfirmationInfos } from '../../../../constants/ConfirmationInfos';
import { TextConfirmationBox } from '../../Molecules/confirmationBoxModal/TextConfirmationBox';
import { string } from 'mobx-state-tree/dist/internal';

export enum ParameterType {
  feedId
}

const FeedDetail = observer(() => {

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const setURLParameters = (params: URLSearchParams) => {
    if (!params) return;

    searchParameters.addParamater(ParameterType[ParameterType.feedId],
      params.get(ParameterType[ParameterType.feedId]) ?? undefined);
  };

  const searchParameters = new SearchParameters();
  const pageQuery = useQuery();
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;

  setURLParameters(pageQuery);

  const rootStore = useStores();
  const { initializeStream, getPostDetail, getMoreReactions, addComment, addReply,
    postExists, reportPost, reportComment, deletePost, removeReaction, forceRemoveContent } = rootStore.feedStreamStore;

  const { isLoggedIn, streamTokenData, isFunctionalityAllowed } = rootStore.loginStore;
  const { isUserDiscontinue, changeStatusUserDiscontinue } = rootStore.profileStore;

  const [feedId, setFeedId] = useState<string>(
    searchParameters.getParameterValue(ParameterType[ParameterType.feedId]) ?? '');

  const [feedData, setFeedData] = useState<any>(null);
  const [isSharedFeedData, setIsSharedFeedData] = useState<boolean>(false);

  const [commentList, setCommentList] = useState<any[]>([]);
  const [pageLimit] = useState<number>(MagicNumber.TEN);

  const [feedStream, setFeedStream] = useState<StreamFeed>();
  const [streamClient, setStreamClient] = useState<StreamClient>();

  const [isRetrievingComments, setIsRetrievingComments] = useState<boolean>(true);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const [isRetrievingFeed, setIsRetrievingFeed] = useState<boolean>(true);
  const [isCheckingSharedItem, setIsCheckingSharedItem] = useState<boolean>(false);
  const [isSharedItemInvalid, setIsSharedItemInvalid] = useState<boolean>(false);

  const [isShowingReactionsList, setIfShowingReactionsList] = useState<boolean>(false);
  const [showingReactionType, setShowingReactionType] = useState<ReactionType>(ReactionType.like);

  const [signInModalOpen, setSignInModalOpen] = useState<boolean>(false);
  const [isProfileOpen, openProfileModal] = useState<boolean>(false);
  const [isSignUpOpen, openSignUpModal] = useState<boolean>(false);
  const [isEmailBtn, disableEmailBtn] = useState<boolean>(false);

  const [isSharingPost, setIfSharingPost] = useState<boolean>(false);
  const [isDeletingPost, setIsDeletingPost] = useState<boolean>(false);
  const [isReportingPost, setIsReportingPost] = useState<boolean>(false);
  const [isShowingDeletingPostConfirmation, setShowingDeletingPostConfirmation] = useState<boolean>(false);
  const [isShowingDeletingCommentConfirmation, setShowingDeletingCommentConfirmation] = useState<boolean>(false);
  const [isShowingReportPostConfirmation, setShowingReportPostConfirmation] = useState<boolean>(false);

  const [deletingPostID, setDeletingPostID] = useState<string>('');
  const [deletingPostUserID, setDeletingPostUserID] = useState<string>('');

  const [deletingCommentID, setDeletingCommentID] = useState<string>('');
  const [deletingCommentUserID, setDeletingCommentUserID] = useState<string>('');
  const [deletingCommentPostID, setDeletingCommentPostID] = useState<string>('');
  const [deletingCommentPostUserName, setDeletingCommentPostUserName] = useState<string>('');
  const [isDeletingComment, setIsDeletingComment] = useState<boolean>(false);

  const [isShowingReportCommentConfirmation, setShowingReportCommentConfirmation] = useState<boolean>(false);
  const [reporteeUserId, setReporteeUserId] = useState<number>(MagicNumber.ZERO);
  const [reportedPostID, setReportedPostID] = useState<string>('');
  const [reportedContent, setReportedContent] = useState<string>('');

  const [currentConfirmationInfo, setCurrentConfirmationInfo] = useState<any>({});

  useEffect(() => {
    isUserDiscontinue && changeStatusUserDiscontinue();
  }, []);

  useEffect(() => {
    initialize();
  }, [streamTokenData]);

  useEffect(() => {
    setIsRetrievingFeed(true);
    retrieveFeedDetail();
    return (() => {
      clearComments();
    });
  }, [feedStream, feedId]);

  useEffect(() => {
    setURLParameters(pageQuery);
    setFeedId(searchParameters.getParameterValue(ParameterType[ParameterType.feedId]) ?? '');
  }, [location]);

  useEffect(() => {
    checkIfSharedItemExists();
  }, [isSharedFeedData]);

  const initialize = async () => {
    initializeStream(FeedGroup[FeedGroup.timeline]).then(data => {
      setStreamClient(data.client);
      setFeedStream(data.feed);
    }).catch(error => {
      errorToast(error);
    });
  };

  const retrieveFeedDetail = async () => {
    if (!streamClient || !feedId)
      return false;

    await getPostDetail(streamClient, feedId)
      .then(results => {
        if (results && results.length) {
          const feedData = results[MagicNumber.ZERO];

          if (!!feedData.isDeleted) {
            history.push(PAGE_ROUTES.PAGE_NOT_FOUND);
            return;
          }

          setFeedData(feedData);
          setIsSharedFeedData(isFeedItemShared(feedData));
        }
        else {
          history.push(PAGE_ROUTES.PAGE_NOT_FOUND);
          return;
        }

        retrieveMoreComments([]);
        setIsRetrievingFeed(false);
      }).catch(error => {
        errorToast(error);
      });

    return true;
  };

  const checkIfSharedItemExists = async () => {
    if (!isSharedFeedData) return;

    const sharedFeedItem = feedData.object;
    setIsSharedItemInvalid(!!sharedFeedItem.isDeleted);
  };

  const retrieveMoreComments = async (commentList: any[]) => {
    if (!streamClient || !feedId) return;

    setIsRetrievingComments(true);
    const commentListLength = commentList.length;
    getMoreReactions(streamClient, 'comment', feedId,
      commentListLength ? commentList[commentListLength - MagicNumber.ONE].id : '',
      pageLimit)
      .then(response => {
        const updatedList = [...commentList, ...response.results];
        setCommentList(updatedList);
        setIsRetrievingComments(false);
      }).catch(error => {
        errorToast(error);
        setIsRetrievingComments(false);
      });
  };

  const addCommentToPost = async (comment: string) => {

    if (!streamClient)
      return false;

    if (!isLoggedIn) {
      setSignInModalOpen(true);
      return false;
    }

    setIsAddingComment(true);

    let errorEncountered = false;
    try {
      await addComment(streamClient, feedId, comment.trim());
    } catch (error) {
      errorToast(error);
      errorEncountered = true;
    }

    await retrieveFeedDetail();
    setIsAddingComment(false);
    return !errorEncountered;
  };

  const addReplyToComment = async (commentId: string, reply: string) => {
    if (!streamClient)
      return false;

    if (!isLoggedIn) {
      setSignInModalOpen(true);
      return false;
    }

    setIsAddingComment(true);

    let errorEncountered = false;
    try {
      await addReply(streamClient, commentId, reply);

    } catch (error) {
      errorToast(error);
      errorEncountered = true;
    }

    await retrieveFeedDetail();
    setIsAddingComment(false);
    return !errorEncountered;
  };

  const clearComments = () => {
    setCommentList([]);
  };

  const onViewFeed = (feedId: string) => {    
    searchParameters.addParamater(ParameterType[ParameterType.feedId], feedId);
    history.push(pathName + searchParameters.getParametersString());
  };

  const onGoBack = () => {
    history.goBack();
  };

  const onBackToListing = () => {
    history.push(PAGE_ROUTES.FEED_LISTING);
  };

  const onDeletePost = async () => {
    if (isDeletingPost) return;

    if (isFunctionalityAllowed(FunctionalityType.DeletePost) && !isAdminId(deletingPostUserID)) {
      forceRemoveContent(deletingPostUserID, deletingPostID, 'feed', 'feed')
        .then(response => {
          onBackToListing();
        })
        .catch(error => {
          if (error.data && error.data.message)
            errorToast(error.data.message);
        })
        .finally(() => {
          setIsDeletingPost(false);
        });
    } else {
      deletePost(deletingPostUserID, deletingPostID)
        .then(response => {
          onBackToListing();
        })
        .catch(error => {
          if (error.data && error.data.message)
            errorToast(error.data.message);
        })
        .finally(() => {
          setIsDeletingPost(false);
        });
    }
  };

  const onDeleteComment = async () => {
    if (isDeletingComment || !streamClient) return;

    setIsDeletingComment(true);
    if (isFunctionalityAllowed(FunctionalityType.DeleteComment) && !isAdminId(deletingCommentUserID)) {
      forceRemoveContent(deletingCommentUserID, deletingCommentID, 'comment', deletingCommentPostUserName)
        .then(async (response) => {
          await retrieveFeedDetail();
        })
        .catch(error => {
          if (error && error.error && error.error.detail)
            errorToast(error.error.detail);
        })
        .finally(() => {
          setIsDeletingComment(false);
        });
    } else {
      removeReaction(streamClient, deletingCommentID)
        .then(async (response) => {
          await retrieveFeedDetail();
        })
        .catch(error => {
          if (error && error.error && error.error.detail)
            errorToast(error.error.detail);
        })
        .finally(() => {
          setIsDeletingComment(false);
        });
    }
  };

  const onReportPost = async (reason: string) => {
    if (isReportingPost) return;

    setIsReportingPost(true);
    await reportPost(reporteeUserId, reportedContent, reportedPostID, reason)
      .then(response => {
        successToast(response);
      }).catch(error => {
        errorToast(error);
        throw error;
      }).finally(() => {
        setIsReportingPost(false);
      });
  };

  const onReportComment = async (reason: string) => {
    if (isReportingPost)
      return;

    setIsReportingPost(true);
    await reportComment(reporteeUserId, reportedContent, reportedPostID, reason)
      .then(response => {
        successToast(response);
      })
      .catch(error => {
        errorToast(error);
        throw error;
      })
      .finally(() => {
        setIsReportingPost(false);
      });
  };

  return (
    <div className='feed-detail-page'>
      {feedData && !isRetrievingFeed ?
        <div className='feed-detail-container' hidden={isRetrievingFeed}>
          <div className='detail-main-container'>
            <div className='detail-left'>
              <div className='left-container'>
                <div className='back-button'>
                  <a onClick={onGoBack}>
                    <img src={BLACK_ICON} alt='back' className='back-icon' />
                    <p className='back-text'>Back</p>
                  </a>
                </div>
                <div className='responsive-feed-post-section'>
                  <div className='feed-post-section'>
                    <FeedItemUserDetails
                      from=''
                      feedData={feedData}
                      onProfileOpenedAsGuestCB={() => { setSignInModalOpen(true); }}
                      onDeletePostCB={(feedUserId, feedId) => {
                        setDeletingPostUserID(feedUserId);
                        setDeletingPostID(feedId);
                        setCurrentConfirmationInfo(ConfirmationInfos.DELETE_POST);
                        setShowingDeletingPostConfirmation(true);
                      }}
                      onReportPostCB={(reportedContent) => {
                        setReporteeUserId(parseInt(getFeedItemUserID(feedData)));
                        setReportedPostID(feedId);
                        setReportedContent(reportedContent);
                        setCurrentConfirmationInfo(ConfirmationInfos.REPORT_POST);
                        setShowingReportPostConfirmation(true);
                      }}
                    />
                    {
                      !isSharedFeedData && feedData.digital && feedData.feed_type !== PostType[PostType.text] && feedData.tweet ?
                        <div className='post-pg'>
                          {feedData.tweet}
                        </div> : null
                    }
                  </div>
                </div>
                {
                  !isSharedFeedData && feedData.digital && feedData.feed_type !== PostType[PostType.text] ?
                    <FeedDetailCarousel medias={
                      feedData.digital.map((url: string) => {
                        return {
                          filepath: url,
                          type: feedData.feed_type
                        };
                      })} /> : null
                }
                {
                  isSharedFeedData || feedData.feed_type === PostType[PostType.text] ?
                    <div className='feed-detail-text'>
                      {isSharedFeedData ? feedData.reaction.data.text : feedData.tweet}
                    </div> : null
                }
                {
                  isSharedFeedData && !isCheckingSharedItem && isSharedItemInvalid ?
                    <div className='share-box-container'>
                      <div className='no-post-section'>
                        <p className='nodata'>{FEED_POST_RESTRICTIONS.POST_DOES_NOT_EXIST}</p>
                      </div>
                    </div> : null
                }
                {
                  isSharedFeedData ?
                    <div className={!isCheckingSharedItem && isSharedItemInvalid ? '' : 'share-box-container'}
                      role='button'
                      onClick={() => {
                        if (isCheckingSharedItem || isSharedItemInvalid)
                          return;
                        onViewFeed(feedData.object.id);
                      }}>
                      {
                        isCheckingSharedItem ? <FeedItemSkeleton /> : null
                      }
                      {
                        !isCheckingSharedItem && !isSharedItemInvalid ?
                          <FeedItem
                            feedItem={feedData.object}
                            streamClient={streamClient}
                            feed={feedStream}
                            onViewFeedCB={() => { onViewFeed(feedData.object.id) }}
                            commentsDisabled
                            reactionsDisabled
                          /> : null
                      }
                    </div> : null
                }
              </div>
            </div>
            <div className='detail-right'>
              <div className='feed-post-section'>
                <FeedItemUserDetails
                  from=''
                  feedData={feedData}
                  onProfileOpenedAsGuestCB={() => { setSignInModalOpen(true) }}
                  onDeletePostCB={(feedUserId, feedId) => {
                    setDeletingPostUserID(feedUserId);
                    setDeletingPostID(feedId);
                    setCurrentConfirmationInfo(ConfirmationInfos.DELETE_POST);
                    setShowingDeletingPostConfirmation(true);
                  }}
                  onReportPostCB={(reportedContent) => {
                    setReporteeUserId(parseInt(getFeedItemUserID(feedData)));
                    setReportedPostID(feedId);
                    setReportedContent(reportedContent);
                    setCurrentConfirmationInfo(ConfirmationInfos.REPORT_POST);
                    setShowingReportCommentConfirmation(true);
                  }}
                />
                {
                  !isSharedFeedData && feedData.digital && feedData.feed_type !== PostType[PostType.text] && feedData.tweet ?
                    <div className='post-pg'>
                      {feedData.tweet}
                    </div> : null
                }
              </div>
              <FeedItemSocials
                likeCount={feedData.reaction_counts?.like ?? MagicNumber.ZERO}
                commentCount={feedData.reaction_counts?.comment ?? MagicNumber.ZERO}
                shareCount={!isSharedFeedData ? feedData.reaction_counts?.repost ?? MagicNumber.ZERO :
                  feedData.object.reaction_counts?.repost ?? MagicNumber.ZERO}
                feedID={feedId}
                likeID={feedData.own_reactions && feedData.own_reactions.like
                  && feedData.own_reactions.like.length ?
                  feedData.own_reactions.like[MagicNumber.ZERO].id : ''}
                onLikeUpdatedCB={retrieveFeedDetail}
                onIllegalActionAsGuestCB={() => { setSignInModalOpen(!signInModalOpen) }}
                streamClient={streamClient}
                onViewLikesCB={() => {
                  setShowingReactionType(ReactionType.like);
                  setIfShowingReactionsList(true);
                }}
                onViewSharesCB={() => {
                  setShowingReactionType(ReactionType.repost);
                  setIfShowingReactionsList(true);
                }}
                onSharePostCB={() => {
                  setIfSharingPost(true);
                }}
              />
              <div className='comment-section'>
                <div className='comment-head'>
                  <p className='head-text'>{`${feedData.reaction_counts?.comment ?? MagicNumber.ZERO} 
                  Comment${feedData.reaction_counts &&
                      feedData.reaction_counts.comment > MagicNumber.ONE ? 's' : ''}`}</p>
                </div>
                <FeedItemCommentInput
                  placeholder='Enter your comment here...'
                  onCommentCB={addCommentToPost}
                />

                <InfiniteScroll
                  dataLength={commentList.length}
                  hasMore={true}
                  loader={null}
                  next={() => { retrieveMoreComments(commentList) }}
                >
                  {isAddingComment || isDeletingComment ?
                    <div className='feed-comment-section'>
                      <FeedItemUserCommentSkeleton />
                    </div> : null}
                  <div hidden={isAddingComment || isDeletingComment}>
                    {
                      commentList.map(comment => {
                        return (
                          <FeedItemUserComment
                            commentData={comment}
                            onReplyCB={addReplyToComment}
                            onProfileOpenedAsGuestCB={() => { setSignInModalOpen(true) }}
                            onReportCommentCB={(userId, reportedComment) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(reportedComment);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_COMMENT);
                              setShowingReportCommentConfirmation(true);
                            }}
                            onReportReplyCB={(userId, reply) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(reply);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_REPLY);
                              setShowingReportCommentConfirmation(true);
                            }}
                            onDeleteCommentCB={(userId, commentId) => {
                              setDeletingCommentPostID(feedId);
                              setDeletingCommentUserID(userId);
                              setDeletingCommentPostUserName(getFeedItemUserName(feedData));
                              setDeletingCommentID(commentId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_COMMENT);
                              setShowingDeletingCommentConfirmation(true);
                            }}
                            onDeleteReplyCB={(userId, replyId) => {
                              setDeletingCommentPostID(feedId);
                              setDeletingCommentUserID(userId);
                              setDeletingCommentPostUserName(getFeedItemUserName(feedData));
                              setDeletingCommentID(replyId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_REPLY);
                              setShowingDeletingCommentConfirmation(true);
                            }}
                          />
                        );
                      })
                    }
                    {isRetrievingComments ?
                      <div className='feed-comment-section'>
                        <FeedItemUserCommentSkeleton />
                      </div> : null}
                  </div>

                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div> : <FeedDetailSkeleton />
      }
      <ConfirmationBox
        isShowing={isShowingDeletingPostConfirmation || isShowingDeletingCommentConfirmation}
        title={currentConfirmationInfo.title}
        description={currentConfirmationInfo.description}
        cancelOptionName={currentConfirmationInfo.cancelOptionName}
        confirmOptionName={currentConfirmationInfo.confirmOptionName}
        onConfirmCB={async (confirmed) => {
          if (isShowingDeletingPostConfirmation) {
            if (confirmed) {
              if (!isLoggedIn) {
                setSignInModalOpen(!signInModalOpen);
                return false;
              }

              if (isShowingDeletingPostConfirmation) {
                await onDeletePost();
              }

              setShowingDeletingPostConfirmation(false);
              return true;
            }
            else {
              setShowingDeletingPostConfirmation(false);
              return true;
            }
          }
          else if (isShowingDeletingCommentConfirmation) {
            if (confirmed) {
              await onDeleteComment();
              await retrieveFeedDetail();
            }
            setShowingDeletingCommentConfirmation(false);
            return true;
          }

          return confirmed;
        }}
      />
      <TextConfirmationBox
        isShowing={isShowingReportPostConfirmation || isShowingReportCommentConfirmation}
        title={currentConfirmationInfo.title}
        cancelOptionName={currentConfirmationInfo.cancelOptionName}
        confirmOptionName={currentConfirmationInfo.confirmOptionName}
        validationSchema={reportContentValidation}
        onConfirmCB={async (text, confirmed) => {
          if (confirmed) {
            if (!isLoggedIn) {
              setSignInModalOpen(true);
              return false;
            }

            let errorOccured = false;
            if (isShowingReportPostConfirmation)
              await onReportPost(text).catch(error => { errorOccured = true });
            else if (isShowingReportCommentConfirmation)
              await onReportComment(text).catch(error => { errorOccured = true });

            if (!errorOccured) {
              setShowingReportPostConfirmation(false);
              setShowingReportCommentConfirmation(false);
            }

            return !errorOccured;
          }
          else {
            setShowingReportPostConfirmation(false);
            setShowingReportCommentConfirmation(false);

            return true;
          }
        }}
      />
      <SharePost
        feedId={feedData && isSharedFeedData ? feedData.object.id : feedId}
        isShowing={isSharingPost}
        streamClient={streamClient}
        onHideCB={() => { setIfSharingPost(false) }}
        feedStream={feedStream}
        onPostSharedCB={() => {
          setIfSharingPost(false);
          retrieveFeedDetail();
        }}
      />
      <ReactionsList
        feedId={feedData && showingReactionType === ReactionType.repost && isSharedFeedData ? feedData.object.id : feedId}
        isShowing={isShowingReactionsList}
        kind={ReactionType[showingReactionType]}
        listTitle={getReactionTypeName(showingReactionType)}
        streamClient={streamClient}
        feedStream={feedStream}
        onHideCB={() => { setIfShowingReactionsList(false) }}
        onProfileOpenedAsGuestCB={() => { setSignInModalOpen(true) }}
      />
      <SignInModal
        openModal={setSignInModalOpen}
        isOpen={signInModalOpen}
        openProfileModal={openProfileModal}
        openSignUpModal={openSignUpModal}
        disableBtn={disableEmailBtn}
      />
      <CompleteProfileModal isOpen={isProfileOpen} openModal={openProfileModal} isEmailBtn={isEmailBtn} />
      <SignupModal openModal={openSignUpModal} isOpen={isSignUpOpen} />
    </div>
  );
});

export default wrapperComponent(true, false, true)(FeedDetail);

import { useEffect, useState } from 'react';
import wrapperComponent from '../../Templates/wrapperComponent';
import './privacyAndPolicy.scss';
import { useHistory } from 'react-router-dom';
import BackArrow from '../../../../assets/images/back-arrow.png';
import { useStores } from '../../../../models';
import Skeleton from 'react-loading-skeleton';

const PrivacyAndPolicy = () => {
  const history = useHistory();
  const rootStore = useStores();
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [heading, setHeading] = useState()
  const [loader, setLoader] = useState<boolean>(false);
  const { getPrivacyPolicy } = rootStore.staticPagesStore;

  const fetchData = async () => {
    setLoader(true);
    await getPrivacyPolicy().then(res => { setPrivacyPolicy(res.data.data[0].description); setHeading(res.data.data[0].title) });
    setLoader(false);
  }

  useEffect(() => {
    fetchData()
  }, []);

  return (
    <>
      {loader ? (<div className="banner-skeleton"><Skeleton /></div>) :
        <div className="static-page">
          <div className="go-back" onClick={() => { history.push('/') }} >
            <img src={BackArrow} alt="back" className="back-arrow" />
            <h4>Back</h4>
          </div>
          <div className="content-wrapper" >
            <h2 className="heading">{heading}</h2>
            <p className="description">
              <div dangerouslySetInnerHTML={{ __html: privacyPolicy! || "" }} ></div>
            </p>
          </div>
        </div>
      }
    </>
  )
};

export default wrapperComponent(true, true)(PrivacyAndPolicy);

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { WHITE_CLOSE_ICON, SUCCESS_CHECKED } from '../../../../constants';
import './modal.scss';
import { observer } from 'mobx-react-lite';

type IProps = {
  openModal: any;
  isOpen: boolean;
};

export const PlaceOrder = observer(function PlaceOrder({ openModal, isOpen }: IProps) {

  const [loader, setLoader] = useState<boolean>(false);

  const navigate = () => {
    window.location.href = '/';
  };

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        backdrop={false}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='order-placed-page modal-dialog-centered'
        contentClassName='add-card-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>
            <div className='success-image'>
             <img src={SUCCESS_CHECKED} className='success-check-icon' alt='success' />
            </div>
            <h1 className='title-text order-title'>
              Order Placed!
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='order-body-inside'>
            <div className='success-pg'>
              Your order has been placed successfully! Thank you for shopping with us.
            </div>
            <form className='form-section'>
              <div className='submit-button'>
                <button type='button'
                  className='btn primary'
                  onClick={navigate}
                >
                  Continue Shopping</button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

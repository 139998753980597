import React, { useEffect, useState } from 'react';
import { Tab, Tabs, } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import { Posts } from './posts/Posts';
import { MyCollection } from './../../profile/profileTabs/myCollection/MyCollection';
import './ProfileTabs.scss';
import FeedListing from '../../feedListing/FeedListing';

type Props = {
  userId?: string
};

export const ProfileTabs = (props: Props) => {
  const params: any = useParams();
  const history = useHistory();
  const [key, setKey] = useState('myCollection');

  const handleTabSelect = (key: any) => {
    setKey(key);
    history.replace({
      hash: `tab=${key}`
    });
  };

  useEffect(() => {
    history.replace({
      hash: `tab=${key}`
    });
  }, [key]);

  return (
    <div className='step-2'>
      <Tabs
        id='profileTabs'
        activeKey={key}
        onSelect={(key: any) => handleTabSelect(key)}
      >
        <Tab eventKey='post' title='Post'>
          <FeedListing
            userId={props.userId}
            filtersDisabled
            postCreationDisabled
            titleDisabled
            noPostsHeading='Their feed is empty!'
            noPostsMessage={'They haven\'t added anything yet. When they do they\'ll show here.'}
            style={{ paddingTop: '40px', paddingBottom: '100px' }}
          />
        </Tab>
        <Tab eventKey='myCollection' title='My Collection'>
          <MyCollection userId={params.id} />
        </Tab>
      </Tabs>
    </div>
  );
};


import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toJS } from 'mobx';
import Cookie from 'js-cookie';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MyCollectionItem } from './MyCollectionItem';
import { useStores } from '../../../../../../models';
import { errorToast } from '../../../../Atoms';
import { MyCollectionSkeleton } from './MyCollectionSkeleton';
import { ParameterType } from '../../../orderDetail/OrderDetail';
import { MagicNumber, COLLECTION_ICON, PAGE_ROUTES, USER_ID } from '../../../../../../constants';
import './myCollection.scss';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';

type Iprops = {
  userId: number;
};

export const MyCollection = ({ userId }: Iprops) => {
  const rootStore = useStores();
  const history = useHistory();

  const [loader, setLoader] = useState<boolean>(false);
  const [user_id, setUserId] = useState<string>('');
  const [collectionList, setCollectionList] = useState<any>([]);
  const [paramId, setParamId] = useState<any>();
  const [currentPageCount, setCurrentPageCount] = useState<number>(MagicNumber.ONE);
  const { getMyCollectionList, MyCollectionList, myCollectionTotalCount, myPrivateItemCount, myCollectionTotalPage } = rootStore.collectionStore;
  const { isLoggedIn } = rootStore.loginStore;
  const loggedInUserId = Number(Cookie.get(USER_ID));
  const params: any = useParams();

  const fetchMyCollectionList = async () => {
    setLoader(true);
    await getMyCollectionList(userId);
    setCollectionList(toJS(MyCollectionList));
    setLoader(false);
  };

  const fetchMoreCollectionData = () => {
    if (currentPageCount < myCollectionTotalPage) {
      getMyCollectionList(userId, currentPageCount + MagicNumber.ONE, MagicNumber.EIGHT).then(res => {
        if (res) {
          const updatedList = [...collectionList, ...toJS(MyCollectionList)];
          setCollectionList(updatedList);
          setCurrentPageCount(currentPageCount + MagicNumber.ONE);
        }
      }).catch(error => {
        errorToast(error);
      });
    }
  };

  const redirectToCollectionDetail = (itemId: number, orderId: number, productId: number, type: string, collectionId: number, status: string, item: any) => {
    if (status === 'sold' || status === 'shipped') {
      history.push(`${PAGE_ROUTES.ORDER_DETAIL}?${ParameterType
      [ParameterType.orderId]}=${item.new_order_id}&${ParameterType[ParameterType.itemId]}=${item.new_order_item_id}&tabs=collection`);
    } else if ((!type) && isLoggedIn && itemId && !paramId) {
      history.push(`${PAGE_ROUTES.ORDER_DETAIL}?${ParameterType
      [ParameterType.orderId]}=${orderId}&${ParameterType[ParameterType.itemId]}=${itemId}&tabs=collection&status=${status}`);
    } else if (!itemId && !collectionId) {
      history.push({
        pathname: `/scanned-products/${productId}`
      });
    } else {
      history.push({
        pathname: `/products/${productId}`
      });
    }
  };

  useEffect(() => {
    fetchMyCollectionList(); 
    const user_id = Cookies.get('user_id') || '0';
    setUserId(user_id);
  }, []);

  useEffect(() => {
    setParamId(params.id);
  }, [params.id]);

  return (
    <>
      {loader ? <MyCollectionSkeleton /> : (
        <div className='profile-collection-list'>
          {collectionList.length ? (
            <h2><span>{userId !== parseInt(user_id) ? parseInt(myCollectionTotalCount) - myPrivateItemCount : myCollectionTotalCount}</span> {parseInt(myCollectionTotalCount) > MagicNumber.ONE ? 'items' : 'item'}</h2>
          ) : (<></>)}
          <div className='profile-collection-item-wrapper'>
            <InfiniteScroll
              dataLength={collectionList.length}
              hasMore={true}
              loader={<p></p>}
              next={fetchMoreCollectionData}
            >
              {collectionList.length ? (
                collectionList.map((item: any, index: number) => {
                  return (
                    <>
                      {userId === parseInt(user_id) || !item.is_private ? <MyCollectionItem
                        redirectToDetail={() => {
                          redirectToCollectionDetail(item.id, item.order_id,
                            item.collection_product_id ? item.collection_product_id : item.product_id, item.product_type, item.collection_product_id, item.status, item);
                        }}
                        item={item}
                        key={index}
                      /> : ''}
                    </>
                  );
                })
              ) : (
                <div className='empty-cart text-center mx-auto'>
                  <img src={COLLECTION_ICON} className='sold-image' alt='pending purchase' />
                  <p>You have no collections. Tab browse below to find
                    items, and they will show up here.</p>
                  <a className='btn primary' href='/'>Browse</a>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
};


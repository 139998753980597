import { PostType } from "../FeedListingUtil";
import { FeedItemImages } from "./FeedItemImages";
import { FeedItemVideo } from "./FeedItemVideo";

export const FeedMedia = (props: {
  postType: PostType,
  postMediaURLs: string[] | undefined
  onViewFeedCB: () => void
}) => {
  switch (props.postType) {
    case PostType.image:
      return <FeedItemImages imageURLs={props.postMediaURLs} onClick={props.onViewFeedCB} />
    case PostType.video:
      if (props.postMediaURLs && props.postMediaURLs.length)
        return <FeedItemVideo videoURL={props.postMediaURLs[0]} />
      else return null;
    default:
      return null
  }
}
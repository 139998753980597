import { toJS } from 'mobx';
import { Instance, types, flow } from 'mobx-state-tree';
import { MagicNumber } from '../../constants';
import { withEnvironment } from '../extensions/with-environment';
import { CollectionData, CollectorData, MyCollectionData } from './collection-entity';

interface priceUpdate {
  price: any;
}

export const CollectionStoreModal = types
  .model('CollectionStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, true),
    collectionData: types.optional(types.array(CollectionData), []),
    homeCollectionData: types.optional(types.array(CollectionData), []),
    homeCollectorData: types.optional(types.array(CollectorData), []),
    MyCollectionList: types.optional(types.array(MyCollectionData), []),
    myCollectionTotalCount: types.optional(types.string, '0'),
    myPrivateItemCount: types.optional(types.number, 0),
    myCollectionTotalPage: types.optional(types.number, 0),
    myCollectorTotalPage: types.optional(types.number, 0),
    currentPage: types.optional(types.number, 1),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getCollectionList: flow(function* (id?: number) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`${id ? `collections/?category_id=${id}` : 'collections'}`);
        const { data } = response.data;
        let filteredData = data.filter((item: any) => item.display_on_home);
        self.homeCollectionData = filteredData;
        self.collectionData = data;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    updateProduct: flow(function* (prices: any, id: number = 0) {
      self.state = 'pending';
      try {
        const data = {
          price: parseFloat(prices)
        }
        const response = yield self.environment.api.put(`admin/products/price/${id}`, data);
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    resetProduct: flow(function* (id: number = 0) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.put(`products/reset-auction/${id}`);
        self.state = 'done';
        self.isLoading = false;
        return response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    addShipment: flow(function* (data: any) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`orders-shipment`, data);
        self.state = 'done';
        self.isLoading = false;
        return response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getCollectorList: flow(function* (
      page: number = MagicNumber.ONE,
      limit: number = MagicNumber.FIFTEEN,
      onTabChange: boolean = false
    ) {
      self.state = 'pending';
      if (onTabChange) {
        self.homeCollectorData.replace([]);
      }
      try {
        const response = yield self.environment.api.get(`users/collector?page=${page}&limit=${limit}`);
        const { results, current_page, page_total } = response.data;
        if (results && results.length) {
          results.forEach((element: any) => {
            self.homeCollectorData.push(element);
          });
        }
        self.currentPage = parseInt(current_page);
        self.myCollectorTotalPage = page_total;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    replaceCollectors() {
      self.homeCollectorData.replace([]);
      self.currentPage = 1;
    },
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getMyCollectionList: flow(function* (
      id,
      page: number = MagicNumber.ONE,
      limit: number = MagicNumber.EIGHT
    ) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`order?page=${page}&limit=${limit}&order_type=my_collection&user_id=${id}`);
        const { results, total, page_total } = response.data;
        var privateCount = 0;
        if (results) {
          let myCollectionItem = results.map((value: any) => {
            let val = {
              id: value.id,
              order_id: value.order_id,
              product_id: value.product_id,
              is_private: value.is_private,
              product_image: value.product_image,
              collection_product_id: value.collection_product_id,
              slug: value.slug,
              status: value.status,
              product_type: value.product_type,
              auction_status: value.auction_status,
              new_order_item_id: value.new_order_item_id,
              new_order_id: value.new_order_id,
              medias: value && value.medias && value.medias.map((item: any) => {
                let mediaValue = {
                  filePath: item.filepath,
                  id: item.id,
                  type: item.type,
                  filepathThumb: item.filepathThumb
                };
                return mediaValue;
              })
            };
            return val;
          });
          self.MyCollectionList = myCollectionItem;
        }
        if (self.MyCollectionList && self.MyCollectionList.length) {
          self.MyCollectionList.forEach((items: any) => {
            if (items.is_private) {
              self.myPrivateItemCount = privateCount + 1;
            }
          });
        }
        self.myCollectionTotalCount = total;
        self.myCollectionTotalPage = page_total;
        self.state = 'done';
        self.isLoading = false;
        return response.data;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }));


type CollectionStoreType = Instance<typeof CollectionStoreModal>
export interface CollectionStore extends CollectionStoreType { };


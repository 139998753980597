import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MENU_ICON_CLOSE, DIGITAL_CONTENT_TYPE } from './../../../../constants';
import { Image } from './../../Atoms';
import './modal.scss';

type IProps = {
  openModal: () => void;
  isOpen: boolean;
  itemData: any;
  itemType: string;
};

export const DigitalContentView = ({ openModal, isOpen, itemData, itemType }: IProps) => {
  const renderDigitalContentView = () => {
    switch (itemType) {
      case DIGITAL_CONTENT_TYPE.DIGITAL_CODE:
        return <div className='digital-coupon-code'><span>{itemData}</span></div>;
      case DIGITAL_CONTENT_TYPE.AUDIO:
        return <audio controls><source src={itemData} type='audio/mpeg' /></audio>;
      case DIGITAL_CONTENT_TYPE.VIDEO:
        return <video controls><source src={itemData} type='video/mp4' /></video>;
      default:
        return <Image src={itemData} alt='Digital Content' />;
    }
  };

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='add-card-modal-dialog white-bg modal-dialog-centered digital-content-bg'
        contentClassName='view-digital-content'
      >
        <Modal.Header>
          <div className='close-icon' onClick={() => openModal()}>
            <img src={MENU_ICON_CLOSE} className='close-image' alt='close' />
          </div>
        </Modal.Header>
        <Modal.Body>
          {renderDigitalContentView()}
        </Modal.Body>
      </Modal>
    </>
  );
};


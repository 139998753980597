import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import AppleSignin from 'react-apple-signin-auth';
import GoogleLogin from 'react-google-login';
import { Formik, Form } from 'formik';
import { Modal, FieldError, Input, errorToast } from '../../Atoms';
import { ForgotPassModal } from '../forgotPassModal/forgotPassModal';
import {
  PASSWORD_SHOW,
  GOOGLE_ICON,
  APPLE_ICON,
  WH_ARROW_ICON,
  validateSiginForm,
  ERROR_MSG,
  ACCOUNT_EXIST,
  INVALID_CRED,
  INVALID_EMAIL,
  PASS_ICON,
  MagicNumber,
  handleEnter,
  NOT_VERIFIED,
  STREAM_TOKEN,
  USER_ID,
  FunctionalityType,
  getFunctionalityBlockedMessage,
  UserTypeName,
  PAGE_ROUTES,
  FEED_USER_ID,
} from '../../../../constants';
import { useStores } from '../../../../models';
import { OtpModal } from '../otpModal/otpModal';
import Cookies from 'js-cookie';
import { chatClient } from '../../../../utils/stream';
type IProps = {
  openModal: (e: boolean) => void;
  disableBtn: (e: boolean) => void;
  isOpen?: boolean;
  openProfileModal: (e: boolean) => void;
  openSignUpModal: (e: boolean) => void;
  cartClick?: boolean;
  closeCartClick?: any;
  closeMessagesClick?: any;
  messagesClick?: boolean;
  wishlistClick?: boolean;
  closeWishlistClick?: any;
  addProductToWishlist?: any;
};

export const SignInModal = observer(function Home({
  openModal,
  isOpen,
  openProfileModal,
  openSignUpModal,
  disableBtn,
  cartClick,
  closeCartClick,
  wishlistClick,
  addProductToWishlist,
  closeWishlistClick,
  messagesClick,
  closeMessagesClick,
}: IProps) {
  const rootStore = useStores();
  const history = useHistory();
  const {
    signinUser,
    checkSocialProfile,
    isLoggedIn,
    resendOtp,
    verifyAppleSignIn,
    setGoogleProfile,
    isFunctionalityAllowed,
    getRoleTypeName,
    sendFcmData
  } = rootStore.loginStore;
  const [passwordType, setPasswordType] = useState<boolean>(false);
  const [isForgotPasOpen, openForgotPassModal] = useState<boolean>(false);
  const [accErr, setAccErr] = useState<boolean>(false);
  const [credErr, setCredErr] = useState<boolean>(false);
  const [emailErr, setEmailErr] = useState<boolean>(false);
  const [isOtpModalOpen, openOtpModal] = useState<boolean>(false);
  const [email, selEmail] = useState<string>('');
  const [isLoading, setLoader] = useState<boolean>(false);
  const { FOUR_TH_FOUR } = MagicNumber;
  const analytics = require('react-segment');

  useEffect(() => {
    setGoogleProfile(null);
  }, []);

  useEffect(() => {
    createChatConnection();
    if (!isLoggedIn) return;
    openModal(false);
    setLoader(false);

    if (cartClick) {
      if (!isFunctionalityAllowed(FunctionalityType.AddToCart)) {
        errorToast(
          getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user)
        );
      } else {
        history.push(PAGE_ROUTES.CART);
      }
    }

    if (wishlistClick) {
      if (!isFunctionalityAllowed(FunctionalityType.AddToWishlist)) {
        errorToast(
          getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user)
        );
      } else {
        addProductToWishlist();
      }
    }
    isLoggedIn && messagesClick && history.push('/profile');
  }, [isLoggedIn]);

  const createChatConnection = () => {
    const streamToken = Cookies.get(STREAM_TOKEN);
    let userId = Cookies.get(FEED_USER_ID);
    if (!userId) {
      userId = Cookies.get('user_id');
    }
    const userProfile = Cookies.get('user_profile');
    if (Cookies.get('user_data') !== undefined) {
      var data = Cookies.get('user_data') || '';
      var userData = JSON.parse(data);
    }

    const userToken = streamToken;
    if (userId !== undefined && userData !== undefined) {
      var userToConnect = {
        id: userId,
        name: `${userData.firstname} ${userData.lastname}`,
        image: userProfile,
      };
      chatClient.connectUser(userToConnect, userToken);
      chatClient.on((event: any) => {
        if (event.total_unread_count !== undefined) {
          Cookies.set('count', event.total_unread_count);
        }
      });
    }
  };

  const responseGoogle = async (response: any) => {

    emptyState(true);
    if (response.profileObj) {
      const { email, googleId } = response.profileObj;
      setLoader(true);
      await checkSocialProfile({
        social_id: googleId,
        provider: 'google',
        email: email,
      }).then((res: any) => {
        if (res && res.id) {
          analytics.default.identify(res && res.id, {
            name: res.firstname,
            email: res.email
          });
        }
        analytics.default.track('Login Completed');
        const fcm = sessionStorage.getItem('fcmt');
        const data = {
          device_type: navigator.platform,
          device_name: navigator.userAgent,
          device_id: '891490373527',
          token: fcm
        };
        sendFcmData(data);
        if (res?.msg === ERROR_MSG) {
          const { email, givenName, familyName, imageUrl } =
            response.profileObj;
          setGoogleProfile({
            email,
            social_id: googleId,
            firstname: givenName,
            lastname: familyName,
            profile_image: imageUrl,
            provider: 'google',
            isSkip: false,
          });
          disableBtn(true);
          openModal(false);
          openProfileModal(true);
          setLoader(false);
        } else setLoader(false);
      });
    }
  };
  const handleLogin = async (values: any) => {

    emptyState(true);
    await signinUser(values).then((res: any) => {
      analytics.default.track('Login Completed');
      const fcm = sessionStorage.getItem('fcmt');
      const data = {
        device_type: navigator.platform,
        device_name: navigator.userAgent,
        device_id: '891490373527',
        token: fcm
      };
      if (res && res.id) {
        analytics.default.identify(res.id, {
          name: res.firstname,
          email: res.email
        });
        sendFcmData(data);
      }
      if (res.status === NOT_VERIFIED) {
        selEmail(res.email);
        openOtpModal(true);
        resendOtp({ email: res.email });
        emptyState();
      }
      if (res.statusCode === FOUR_TH_FOUR) setCredErr(true);
      if (res.kind === 'server') setEmailErr(true);
      setLoader(false);
    });
  };

  const emptyState = (status?: boolean) => {
    setAccErr(false);
    setCredErr(false);
    setEmailErr(false);
    !status && openModal(false);
  };

  const handleAppleLogin = async (res: any) => {
    let { id_token } = res.authorization;
    await verifyAppleSignIn({ id_token }).then((res) => {
      analytics.default.identify(res.id, {
        name: res.firstname,
        email: res.email
      });
      analytics.default.track(res.id, {
        event: 'Login Completed',
        userId: res.id
      });
      const fcm = sessionStorage.getItem('fcmt');
      const data = {
        device_type: navigator.platform,
        device_name: navigator.userAgent,
        device_id: '891490373527',
        token: fcm
      };
      sendFcmData(data);
      let email = res.data.email ? res.data.email : '';
      if (email) disableBtn(true);
      else disableBtn(false);
      setLoader(true);
      checkSocialProfile({
        social_id: res.data.sub,
        provider: 'apple',
        email: email,
      }).then((response: any) => {
        checkAppleRes(response, email, res.data.sub);
      });
    });
  };

  const checkAppleRes = (res: any, email: string, sub: string) => {
    if (res?.msg === ERROR_MSG) {
      setGoogleProfile({
        email,
        social_id: sub,
        firstname: '',
        lastname: '',
        profile_image: null,
        provider: 'apple',
        isSkip: false,
      });
      openModal(false);
      openProfileModal(true);
      setLoader(false);
    }
  };
  const setNextPath = () => {
    if (cartClick) {
      closeCartClick(false);
    }
    if (wishlistClick) {
      closeWishlistClick(false);
    }
    if (messagesClick) {
      closeMessagesClick(false);
    }
  };

  return (
    <>
      {isLoading && (
        <div className='loader-main'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'></span>
          </div>
        </div>
      )}
      <Modal
        isOpen={isOpen}
        openModal={emptyState}
        onModalExit={setNextPath}
        header='Welcome!'
      >
        <Formik
          initialValues={{ password: '', email: '' }}
          onSubmit={async (values) => {
            await handleLogin(values);
          }}
          validationSchema={validateSiginForm}
          render={({
            handleSubmit,
            values,
            setFieldValue,
            errors,
            touched,
            dirty,
          }) => (
            <Form className='form-section'>
              {accErr && <FieldError message={ACCOUNT_EXIST} />}
              {credErr && <FieldError message={INVALID_CRED} />}
              {emailErr && <FieldError message={INVALID_EMAIL} />}
              <div
                className={`form-group ${errors.email && touched.email ? 'form-group-error' : ''
                  }`}
              >
                <Input
                  required
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Email'
                  value={values.email}
                  setFieldValue={setFieldValue}
                  handleEnter={handleEnter}
                />
                <FieldError name='email' />
              </div>
              <div
                className={`form-group ${errors.password && touched.password ? 'form-group-error' : ''
                  }`}
              >
                <Input
                  type={passwordType ? 'text' : 'password'}
                  required
                  id='password'
                  name='password'
                  placeholder='Password'
                  value={values.password}
                  setFieldValue={setFieldValue}
                  handleEnter={handleEnter}
                />
                <div className='paasword-section'>
                  <img
                    src={!passwordType ? PASSWORD_SHOW : PASS_ICON}
                    alt='show'
                    className='password-icon'
                    onClick={() => setPasswordType(!passwordType)}
                  />
                </div>
                <FieldError name='password' />
              </div>
              <div className='submit-button'>
                <button
                  type='button'
                  className='btn primary'
                  disabled={!dirty ? true : false}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  Sign In
                  <div className='right-arrow'>
                    <img src={WH_ARROW_ICON} alt='arrow' className='arrow-icon' />
                  </div>
                </button>
              </div>
              <div className='forgot-text'>
                <a
                  className='forgot-link'
                  onClick={() => {
                    openModal(false);
                    openForgotPassModal(true);
                  }}
                >
                  Forgot Password?
                </a>
              </div>
              <div className='or-section'>
                <div className='line'>
                  <div className='or-text'>or Continue with</div>
                </div>
              </div>
              <div className='other-login'>
                <AppleSignin
                  authOptions={{
                    clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
                    scope: 'email',
                    redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL,
                    state: 'state',
                    nonce: 'nonce',
                    usePopup: true,
                  }} // REQUIRED
                  uiType='dark'
                  className='apple-auth-btn'
                  noDefaultStyle={false}
                  onSuccess={(response: any) => handleAppleLogin(response)} // default = undefined
                  onError={(error: any) =>
                    console.error('apple-login-error', error)
                  } // default = undefined
                  skipScript={false} // default = undefined
                  iconProp={{ style: { marginTop: '10px' } }} // default = undefined
                  render={(props: any) => (
                    <button type='button' className='btn secondary' {...props}>
                      <img
                        src={APPLE_ICON}
                        className='apple-icon'
                        alt='apple'
                      />
                      Apple
                    </button>
                  )}
                />
                <GoogleLogin
                  clientId={`${process.env.REACT_APP_GOOGLE_ID}`}
                  render={(renderProps) => (
                    <button
                      type='button'
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      className='btn secondary'
                    >
                      <img
                        src={GOOGLE_ICON}
                        className='google-icon'
                        alt='google'
                      />
                      Google
                    </button>
                  )}
                  fetchBasicProfile={true}
                  buttonText='Google'
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              </div>
              <div className='email-login'>
                <button
                  type='button'
                  onClick={() => {
                    openSignUpModal(true);
                    emptyState(false);
                  }}
                  className='btn outline-primary'
                >
                  Sign Up with Email
                </button>
              </div>
            </Form>
          )}
        />
      </Modal>
      <ForgotPassModal
        openModal={openForgotPassModal}
        isOpen={isForgotPasOpen}
        openSigninModal={openModal}
      />
      <OtpModal
        openModal={openOtpModal}
        isOpen={isOtpModalOpen}
        email={email}
        isRegister
      />
    </>
  );
});


import Select from 'react-select';

type IProps = {
  onChange: any;
  options: any;
  value: string;
  className: string;
  classNamePrefix: string;
  placeholder: string;
};
export const StateDropdown = ({ onChange, options, value, className, classNamePrefix, placeholder, }: IProps) => {

    const defaultValue = (options:any, value:any) => {
        return options ? options.find((option:any) => option.value === value) : '';
    };

    return (
      <>
        <Select
          className={className}
          classNamePrefix={classNamePrefix}
          defaultValue={value && {label:value}}
          value={defaultValue(options, value)}
          placeholder={placeholder}
          onChange={(value:any) => {
              onChange(value);
          }}
          options={options}
        />
      </>
    );
};

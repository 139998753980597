import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import S3FileUpload from 'react-s3';
import { Modal, FieldError, Input, Image, errorToast } from '../../Atoms';

import { OtpModal } from '../otpModal/otpModal';
import {
  CAMERA_ICON, validateRegisterForm, MagicNumber,
  handleEnter, UPLOAD_ICON, profilePic, USER_DIR_NAME, REGION, S3_IMAGE_ERROR, removeWhiteSpace
} from '../../../../constants';
import { useStores } from '../../../../models';

type IProps = {
  openModal: (e: boolean) => void;
  isOpen?: boolean;
  isEmailBtn?: boolean
};

export const CompleteProfileModal = observer(function Home({ openModal, isOpen, isEmailBtn }: IProps) {
  const rootStore = useStores();
  const path = process.env.REACT_APP_ASSET_HOST;
  const { completeSocialprofile, googleProfile, isLoggedIn, getS3Config, resendOtp } = rootStore.loginStore;
  const [profileImage, setProfileImage] = useState<string>(path + profilePic[MagicNumber.ZERO]);
  const [flag, setFlag] = useState<boolean>(false);
  const [tolltipClass, setTolltipClass] = useState<boolean>(false);
  const [imageValue, setImage] = useState<any>();
  const [isOtpModalOpen, openOtpModal] = useState<boolean>(false);
  const [email, selEmail] = useState<string>('');
  const [initialValues, setInitialValues] = useState<any>({
    email: '',
    firstname: '',
    lastname: '',
    isSkip: false
  });
  const { ONE, ZERO, TWO } = MagicNumber;
  useEffect(() => {
    if (isLoggedIn) {
      emptyState();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    
    if (Object.keys(googleProfile).length > MagicNumber.TWO && !flag) {
      setFlag(true);
      let image = googleProfile.profile_image ? googleProfile.profile_image : path + profilePic[MagicNumber.ZERO];
      setProfileImage(image);
      setImage(image);
      setInitialValues(googleProfile);
    } 
  }, [googleProfile]);


  const handleLogin = async (values: any) => {
    
    let image = profileImage.split('.net/');
    let data = !values.isSkip ? values : initialValues;
    if (image.length === TWO) {
      let value = { ...data };
      value.profile_image = image[ONE];
      delete value.isSkip;
      value.is_email_editable = !isEmailBtn;
      if (!data.firstname) value.firstname = values.firstname;
      if (!data.firstname) value.lastname = values.lastname;
      await completeSocialprofile(value, !isEmailBtn).then((res?: boolean) => {
        if (res && !isEmailBtn) {
          openModal(false);
          selEmail(values.email);
          resendOtp({ email: values.email });
          openOtpModal(true);
        }
      });
    } else {
      fetchS3Config(data);
    }
  };

  const handleFileSelected = (event: any, status: boolean) => {
    
    let url = status ? URL.createObjectURL(event.target.files[ZERO]) : event;
    if (status) setImage(event.target.files[ZERO]);
    setProfileImage(url);
    setTolltipClass(false);
  };

  const fetchImage = async (urls: string, fileName: string) => {
    
    return await fetch(urls)
      .then(async (response: any) => {
        const contentType: any = response.headers.get('content-type');
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: contentType });
        return file;
      });
  };

  const isFile = (input: any) => 'File' in window && input instanceof File;

  const fetchS3Config = async (values: any) => {
    
    const s3Config = await getS3Config();
    let imageData = imageValue;
    const config = {
      bucketName: s3Config.data.data.s3.bucket_name,
      dirName: USER_DIR_NAME,
      region: REGION,
      accessKeyId: s3Config.data.data.s3.aws_access_key_id,
      secretAccessKey: s3Config.data.data.s3.aws_secret_access_key
    };
    if (!isFile(imageValue)) {
      const name = imageValue.split('/');
      imageData = await fetchImage(imageValue, name[name.length - MagicNumber.ONE]);
    }
    await S3FileUpload.uploadFile(imageData, config)
      .then((data: any) => {
        
        let value = { ...values };
        value.profile_image = data.key;
        delete value.isSkip;
        value.is_email_editable = !isEmailBtn;
        if (!data.firstname) value.firstname = values.firstname;
        if (!data.firstname) value.lastname = values.lastname;
        completeSocialprofile(value, !isEmailBtn).then((res?: boolean) => {
          if (res && !isEmailBtn) {
            openModal(false);
            selEmail(values.email);
            resendOtp({ email: values.email });
            openOtpModal(true);
          }
        });
      })
      .catch((err: any) => {
        
        errorToast(S3_IMAGE_ERROR);
      });
  };

  const emptyState = () => {
    setFlag(false);
    openModal(false);
    setProfileImage(profilePic[MagicNumber.ZERO]);
    setTolltipClass(false);
    setInitialValues({
      email: '',
      firstname: '',
      lastname: '',
      username: '',
      profile_image: ''
    });
  };

  return (<>
    <Modal
      isOpen={isOpen}
      openModal={emptyState}
      header='Complete Profile' >
      <Formik
        initialValues={initialValues}
        onSubmit={async values => {
          await handleLogin(removeWhiteSpace(values));
        }}
        enableReinitialize={true}
        validationSchema={validateRegisterForm}
        render={({ handleSubmit, values, setFieldValue, dirty }) => (
          <Form className='form-section'>
            <div className='upload-image-section'>
              <div className='upload-box'>
                <img src={profileImage} alt='upload' className='placeholder-upload' />
                <div className='upload-icon tooltip'>
                  <div className='upload-background' style={{ display: tolltipClass ? 'block' : 'none' }}>
                    <div className='upload-inside'>
                      <div className='upload-box'>
                        <input type='file' className='file-input' accept='image/x-png,image/jpg,image/jpeg' onChange={(e) => handleFileSelected(e, true)} id='input_upload' />
                        <div className='upload-inside'>
                          <div className='icon-upload'>
                            <Image src={UPLOAD_ICON} className='upload-image' alt='upload' />
                            <p className='text-upload'>Upload picture</p>
                          </div>
                        </div>
                      </div>
                      <div className='profile-text'>
                        Default Profile Images
                      </div>
                      <div className='default-image-container'>
                        <div className='default-image-section'>
                          {profilePic.map((ele: any) =>
                            <div className='image-box'>
                              <img src={path + ele} onClick={() => handleFileSelected(path + ele, false)} key={ele} className='image-default' alt='image' />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src={CAMERA_ICON} alt='upload' className='image-upload' onClick={() => setTolltipClass(!tolltipClass)} />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <Input
                required
                id='firstname'
                name='firstname'
                type='text'
                placeholder='First name'
                value={values.firstname}
                setFieldValue={setFieldValue}
                handleEnter={handleEnter}
              />
              <FieldError name='firstname' />
            </div>
            <div className='form-group'>
              <Input
                required
                id='lastname'
                name='lastname'
                type='text'
                placeholder='Last name'
                value={values.lastname}
                setFieldValue={setFieldValue}
                handleEnter={handleEnter}
              />
              <FieldError name='lastname' />
            </div>
            <div className='form-group'>
              <Input
                required
                id='email'
                name='email'
                type='text'
                placeholder='Email'
                disabled={isEmailBtn}
                value={values.email}
                setFieldValue={setFieldValue}
                handleEnter={handleEnter}
              />
              <FieldError name='email' />
            </div>
            <div className='submit-button'>
              <button type='button' className='btn primary' onClick={() => {
                setFieldValue('isSkip', false); handleSubmit(values);
              }}>Submit</button>
              {isEmailBtn && <button type='button' className='btn outline-primary' id='skip-button'
                onClick={() => {
                  setFieldValue('isSkip', true);
                  handleSubmit(values);
                }}>Skip</button>}
            </div>
          </Form>
        )} />
    </Modal>
    <OtpModal openModal={openOtpModal} isOpen={isOtpModalOpen} email={email} isRegister={true} social_id={initialValues.social_id} />
  </>
  );
});

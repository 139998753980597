import { useState } from 'react';
import { IMAGE_ICON, LEFT_ARROW, MagicNumber, RIGHT_ARROW } from '../../../../constants';
import { ImageGalery } from '../../Molecules';

export type FeedMedia = {
  filepath: string,
  type: string
};

type Props = {
  medias: FeedMedia[]
};

export const FeedDetailCarousel = (props: Props) => {

  const [pageCount, setPageCount] = useState<number>(MagicNumber.ZERO);
  const mediasLength = props.medias.length;

  const onPreviousPressed = () => {
    if (!mediasLength) return;

    let newPageCount = pageCount - MagicNumber.ONE;
    newPageCount = newPageCount < MagicNumber.ZERO ? mediasLength - MagicNumber.ONE : newPageCount;
    setPageCount(newPageCount);
  };

  const onNextPressed = () => {
    if (!mediasLength) return;

    let newPageCount = pageCount + MagicNumber.ONE;
    newPageCount = newPageCount >= mediasLength ? MagicNumber.ZERO : newPageCount;
    setPageCount(newPageCount);
  };

  return mediasLength ? (
    <div className='product-carousel-section'>
      <div className='preview-carousel'>
        <div id='sportscontrol' className='carousel' data-ride='false'>
          <div className='carousel-inner'>
            {props.medias.map((media, index) => {
              return (
                <div className={`carousel-item ${index === pageCount ? 'active' : ''}`}>
                  <div className='carousel-box'>
                    <div className='product-item'>
                      <ImageGalery data={media} classValue='product-image' isZoom={media.type !== 'video'} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <a className='carousel-control-prev' role='button' onClick={onPreviousPressed}>
            <img src={LEFT_ARROW} alt='arrow' className='icon-image' />
            <span className='sr-only'>Previous</span>
          </a>
          <a className='carousel-control-next' role='button' onClick={onNextPressed}>
            <img src={RIGHT_ARROW} alt='arrow' className='icon-image' />
            <span className='sr-only'>Next</span>
          </a>
        </div>
      </div>
      <div className='select-product-section'>
        {
          props.medias.map((media, index) => {
            return (
              <a href='#sportscontrol' role='button' data-slide-to={index} onClick={() => { setPageCount(index); }}>
                <div className={`product-box ${index === pageCount ? 'active-product' : ''}`}>
                  <ImageGalery data={media} classValue='select-product-img' />
                </div>
              </a>
            );
          })
        }
      </div>
    </div>
  ) : null
};

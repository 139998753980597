import { LOGO, MagicNumber, PAGE_ROUTES, UserType } from '../../../../constants';
import { ParameterType } from '../feedDetail/FeedDetail';

export enum ReactionType {
  like = 'like',
  comment = 'comment',
  repost = 'repost'
}

export enum FeedGroup {
  timeline = 'timeline',
  user = 'user'
}

export enum PostType {
  text = 'text',
  image = 'image',
  video = 'video'
}

export enum RoleType {
  user = 'user',
  admin = 'admin'
}

export const AdminIDS = ['oddAdmin', 'oodAdmin', 'oddadmin', 'oodadmin'];

export const getReactionTypeName = (reaction: ReactionType) => {
  switch (reaction) {
    case ReactionType.like:
      return 'Like';
    case ReactionType.comment:
      return 'Comment';
    case ReactionType.repost:
      return 'Share';
  }
};

export const isFeedItemFromAdmin = (feedItem: any): boolean => {
  if (!feedItem || !feedItem.actor) return false;
  return AdminIDS.includes(feedItem.actor.id);
};

export const isActorCommunityManager = (actor: any): boolean => {
  if (!actor || !actor.data) return false;
  return actor.data.user_role === UserType[UserType.community_manager];
};

export const getActorCollectionID = (actor: any): number | null => {
  if (!actor || !actor.data) return null;
  return actor.data.collection_id;
};

export const isAdminId = (userId: any): boolean => {
  return AdminIDS.includes(userId);
};

export const isFeedItemShared = (feedItem: any): boolean => {
  if (!feedItem) return false;
  return feedItem.verb === ReactionType[ReactionType.repost];
};

export const getFeedItemUserName = (feedItem: any): string => {
  if (isFeedItemFromAdmin(feedItem)) return 'ood';

  if (!feedItem || !feedItem.actor || !feedItem.actor.data) return '';
  return `${`${feedItem.actor.data.firstname} ${feedItem.actor.data.lastname}`}`;
};

export const getLinkToFeedItem = (feedId: string): string => {
  return `${PAGE_ROUTES.FEED_DETAIL}?${ParameterType[ParameterType.feedId]}=${feedId}`;
};

export const getFeedItemUserImage = (feedItem: any): string => {
  if (isFeedItemFromAdmin(feedItem)) { return LOGO; }
  if (!feedItem || !feedItem.actor || !feedItem.actor.data) return '';
  return feedItem.actor.data.profileImage;
};

export const getFeedItemUserID = (feedItem: any): string => {
  if (!feedItem || !feedItem.actor) return '';
  return feedItem.actor.id;
};


import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { errorToast, FullPageLoader } from '../../Atoms';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../models';
import { MagicNumber, VALIDATION_MESSAGES, WH_ARROW_ICON } from '../../../../constants';
import Cookies from 'js-cookie';
import { SuccessBid } from '../../Molecules/modal/SuccessBid';
import { BidConfirm } from '../../Molecules/modal/BidConfirm';
import { toJS } from 'mobx';
import { TermAndCondition } from '../../Molecules/modal/TermAndCondition';

type IProps = {
  setNextStep: any;
  hideActionButton: number;
  loader: boolean
};
export interface param {
  buy: string;
}

export const BidsSummary = observer(function BidsSummary({ loader, setNextStep, hideActionButton }: IProps) {
  const rootStore = useStores();
  let [step, setStep] = useState<number>(2);
  let [isLoading, setLoader] = useState<boolean>(false);
  let [onSuccess, setOnSuccess] = useState<boolean>(false);
  const [confirmBid, setConfirmBid] = useState<boolean>(false);
  const [showTCModal, setShowTCModal] = useState<boolean>(false);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);
  const [agreed, setIsAgree] = useState<boolean>(true);
  const [tcChecked, setTcChecked] = useState<boolean>(false);
  const [allowPersonalInfo, isAllowPI] = useState<boolean>(false);
  const params: param = useParams();
  const { bid, currentBidStep, postBids, setBidCurrentStep } = rootStore.userStore;
  const { productIdList } = rootStore.homeStore;
  const { savedCard, cardAddress, checkCvc,
    getPaymentIntent, stripe, cardCvcElement, setCvcStatus } = rootStore.cardStore;
  const { addPaymentCardDetail, addPaymentCardInfo,
    paymentCard, cartSubtotal, cartSubtotal_buy, taxApi, tax_amount } = rootStore.cartStore;
  const { savedAddressList } = rootStore.settingStore;

  const stripeCvc = async () => {
    setBtnLoader(true);
    const userId = Cookies.get(VALIDATION_MESSAGES.USER_ID) || VALIDATION_MESSAGES.EMPTY;
    const taxAmt = (tax_amount ? tax_amount : 0);
    const dollar: any = params.buy ? cartSubtotal_buy && cartSubtotal_buy.split('$') : cartSubtotal && cartSubtotal.split('$');
    const data: any = {
      'user_id': parseInt(userId),
      'amount': parseInt(bid)
    };
    await getPaymentIntent(data).then((res: any) => {
      if (res.res.kind == 'ok') {
            // setCvcStatus(false);
            // addPaymentCardDetail({});
            // setBidCurrentStep(MagicNumber.FOUR);
            // addPaymentCardInfo(null);
            setBtnLoader(false);
        // stripe.confirmCardPayment(res && res?.res?.response?.data?.data?.client_secret, {
        //   payment_method: res.cardId,
        //   payment_method_options: {
        //     card: {
        //       cvc: cardCvcElement
        //     }
        //   },
        // }).then((result: any) => {
        //   if (result.error) {
        //     setBtnLoader(false);
        //     setCvcStatus(true);
        //     console.log('error = ', result.error);
        //   } else {
        //     setCvcStatus(false);
        //     addPaymentCardDetail({});
        //     setBidCurrentStep(MagicNumber.FOUR);
        //     addPaymentCardInfo(null);
        //     setBtnLoader(false);
        //     console.log('success == ', result);
        //   }
        // });
      }
    });
  };

  const handleBidTaxApi = async () => {
    const getDefaultAddress = toJS(savedAddressList).filter((data) => data.is_default);
    setLoader(true);
    const data = {
      address_obj: {
        line1: getDefaultAddress[MagicNumber.ZERO].address_1,
        city: getDefaultAddress[MagicNumber.ZERO].city,
        country: getDefaultAddress[MagicNumber.ZERO].country,
        postalCode: getDefaultAddress[MagicNumber.ZERO].zip_code
      },
      product_id: productIdList,
      amount: parseFloat(bid)
    };
    await taxApi(data);
    setLoader(false);
  };

  const continueMethod = async () => {
    setNextStep(step + MagicNumber.ONE);
    if (currentBidStep === MagicNumber.TWO) {
      handleBidTaxApi();
      if (paymentCard && paymentCard.source_token) {
        setBidCurrentStep(MagicNumber.FOUR);
      } else {
        if (savedCard && savedCard.cardData && savedCard.cardData.id) {
          setBidCurrentStep(MagicNumber.FOUR);
          // await stripeCvc();
        }
      }
      return;
    }
    if (currentBidStep === MagicNumber.THREE) {
      if (savedCard && savedCard.cardData && savedCard.cardData.id) {
        // await stripeCvc();
            setCvcStatus(false);
            addPaymentCardDetail({});
            setBidCurrentStep(MagicNumber.FOUR);
            addPaymentCardInfo(null);
            setBtnLoader(false);
      }else {
        errorToast(VALIDATION_MESSAGES.NO_CARD);
      }
    } else {
      if (savedAddressList && savedAddressList.length && (savedCard && savedCard.cardData && savedCard.cardData.id)) {
        setBidCurrentStep(currentBidStep + MagicNumber.ONE);
      } else if (savedAddressList && savedAddressList.length) {
        setBidCurrentStep(MagicNumber.THREE);
      }
    }
  };

  const currencyFormate = () => {
    const l10nUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return l10nUSD.format(parseInt(bid) || 0);
  };

  const taxAmount = () => {
    let taxAmt = (tax_amount ? tax_amount : 0);
    const l10nUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return l10nUSD.format(taxAmt || 0);
  };

  const totalBidAmt = () => {
    let taxAmt = (tax_amount ? tax_amount : 0);
    const l10nUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return l10nUSD.format(parseInt(bid) + taxAmt || 0);
  };

  const handleBid = async () => {
    const userId = Cookies.get(VALIDATION_MESSAGES.USER_ID) || VALIDATION_MESSAGES.EMPTY;
    if (savedCard && savedCard.cardData && savedCard.cardData.id || (paymentCard && paymentCard.source_token)) {
      setLoader(true);
      let taxAmt = (tax_amount ? tax_amount : 0);
      const data = {
        accept_terms_condition: !agreed,
        allow_share_info: allowPersonalInfo,
        bid_amount: parseInt(bid) + taxAmt,
        actual_bid_amount:parseInt(bid),
        user_id: parseInt(userId),
        product_id: productIdList,
        address_id: cardAddress && cardAddress.address_id,
        source_token: savedCard && savedCard.cardData && savedCard.cardData.id ? '' : paymentCard.source_token,
        card_id: savedCard && savedCard.cardData && savedCard.cardData.id ? savedCard.cardData.id : '',
        is_card_save: savedCard && savedCard.cardData && savedCard.cardData.id ? '' : paymentCard.is_card_save
      };
      const res: any = await postBids(data);
      if (res && res.status === MagicNumber.FOUR_TH) {
        errorToast(res && res.data && res.data.message);
      } else if (res && res.kind === 'ok') {
        setOnSuccess(true);
      }
      setLoader(false);
    } else {
      errorToast('Please add a card.');
    }
  };

  const togglePlaceBidsModel = () => {
    setOnSuccess(!onSuccess);
  };

  const toggleModal = async (flag: string) => {
    if (flag === 'confirm') {
      await stripeCvc();
      handleBid();
    }
    setConfirmBid(!confirmBid);
  };

  const handleTC = () => {
    setShowTCModal(true);
  };

  const getStatus = (flag: string) => {
    if (flag === 'agree') {
      setIsAgree(false);
      setTcChecked(true);
    } else {
      setIsAgree(true);
      setTcChecked(false);
    }
    setShowTCModal(false);
  };

  const handleTCchange = (event: any) => {
    if (event.target.checked) {
      handleTC();
    } else {
      setIsAgree(true);
      setTcChecked(event.target.checked);
    }
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <div className='price-sum'>
        <h4>Bid Details</h4>
        <ul>
          <li>Bid Amount<span>{currencyFormate()}</span></li>
          <li>Sales Tax <span>{hideActionButton > MagicNumber.THREE ? taxAmount() : <span style={{ fontFamily: 'sans-serif' }} >Calculated Later</span>}</span></li>
        </ul>
        <ul className='total-price bid-price'>
          <li>Total Amount <span>{hideActionButton > MagicNumber.THREE ? totalBidAmt() : currencyFormate()}</span></li>
        </ul>
        {currentBidStep > MagicNumber.THREE ? <ul className='check-section'>
          <li className='agree-list'>
            <div className='checkbox-custom'>
              <label className='label-text info-share-section'>
                <input name='term_condition' type='checkbox'
                  className='box-check' id='term_condition'
                  onChange={handleTCchange}
                  checked={tcChecked}
                  value='false' />
                <strong></strong>
                <p className='info-share-text'>I agree with OOD <a className='link-text' onClick={handleTC}>Terms and Conditions.</a> </p>
              </label>
            </div>
            <div className='checkbox-custom'>
              <label className='label-text info-share-section'>
                <input name='term_condition' type='checkbox'
                  className='box-check' id='term_condition'
                  onChange={(event) => isAllowPI(event.target.checked)}
                  value='false' />
                <strong></strong>
                <p className='info-share-text'>
                  Allow OOD to share contact information with creators/brands
                </p>
              </label>
            </div>
          </li>
        </ul> : ''}
        <div className='card-submit'>
          {currentBidStep < MagicNumber.FOUR ? <button className='btn primary bid-primary' onClick={() => continueMethod()}>Continue<div className='right-arrow'>
            <img src={WH_ARROW_ICON} alt='arrow' className='arrow-icon' />
          </div>
          </button> : <button className='btn primary bid-primary' disabled={agreed} onClick={() => toggleModal('open')}>Confirm Bid<div className='right-arrow'>
            <img src={WH_ARROW_ICON} alt='arrow' className='arrow-icon' />
          </div>
          </button>}
        </div>
      </div>
      {onSuccess &&
        <SuccessBid
          isOpen={onSuccess}
          openModal={() => { togglePlaceBidsModel(); }}
        />
      }
      {confirmBid && (
        <BidConfirm
          openModal={(flag: string) => { toggleModal(flag); }}
          title='Confirm Bid'
          description={`Please confirm if your entered details are correct. Clicking on this will confirm your bid (${totalBidAmt()}) for the selected product`}
          isOpen={confirmBid}
        />
      )}
      {showTCModal && (
        <TermAndCondition
          openModal={(flag: string) => { getStatus(flag); }}
          title='Terms and Conditions'
          description={`Please confirm if your entered details are correct. Clicking on this will confirm your bid (${totalBidAmt()}) for the selected product`}
          isOpen={showTCModal}
        />
      )}
    </>
  );
});


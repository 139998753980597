import moment from 'moment';

type IProps = {
    data: string;
    isDate?: boolean;
    title: string
};
export const Row = ({ data, isDate, title }: IProps) => {
    const getDate = (data: string | null) => {
        if (!data) return '';
        let date = new Date(data);
        return moment(date).format('MMM, Do YYYY');
    };
    if (!data) {
        return <></>;
    } else return (
        <div className='detail-row'>
            <div className='item-detail'>{title}</div>
            <div className='item-info'>{isDate ? getDate(data) : data}</div>
        </div>
    );
};

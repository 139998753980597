import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../models';
import { errorToast, FullPageLoader, successToast } from '../../Atoms';

type IProps = {
    openModal: any;
    isOpen: boolean;
    items_id: number;
};

export const ResetAuctionButton = ({ openModal, isOpen, items_id }: IProps) => {

    const history = useHistory();
    const rootStore = useStores();
    const [loader, setLoader] = useState<boolean>(false);

    const handleReset = async () => {
        setLoader(true);
        const response = await rootStore.collectionStore.resetProduct(items_id);
        if (response && response.kind === 'ok') {
            successToast('Reset successfully done!');
            history.push('/profile');
        } else {
            errorToast('Something went wrong');
        }
        setLoader(false);
    };

    return (
        <>
            {loader && <FullPageLoader />}
            <Modal
                size='sm'
                show={isOpen}
                onHide={() => openModal()}
                aria-labelledby='modal-sm'
                centered
                dialogClassName='remove-modal-dialog modal-dialog-centered'
                contentClassName='remove-modal-content'
            >
                <Modal.Header>
                    <Modal.Title id='example-modal-sizes-title-sm'>
                        Reset Product
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to reset this product?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className='btn btn-blank' onClick={() => openModal()}>Cancel</button>
                    <button type='button'
                        className='btn btn-blank remove'
                        onClick={() => handleReset()}
                    >Reset</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

import React from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';

type IProps = {
  openModal: any;
  isOpen: boolean;
  title: string;
  description: string;
};

export const BidConfirm = ({ openModal, isOpen, title, description }: IProps) => {

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog modal-dialog-centered'
        contentClassName='remove-modal-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-blank' onClick={() => openModal('cancel')}>Cancel</button>
          <button type='button' className='btn btn-blank remove' onClick={() => openModal('confirm')}>Confirm</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};


type IProps = {
    quantityDecrease: (e:any) => void;
    quantityIncrease: (e:any) => void;
    decreaseDisabled: boolean;
    increaseDisabled: boolean;
    inputValue: any;
};

export const QuantityButtons = ({ quantityDecrease, quantityIncrease, decreaseDisabled, increaseDisabled, inputValue }: IProps) => {
    return (
        <>
        <button onClick={quantityDecrease} disabled={decreaseDisabled}>-</button>
        <input type='text' value={inputValue} readOnly />
        <button onClick={quantityIncrease} disabled={increaseDisabled}>+</button>
        </>
    );
};

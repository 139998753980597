import Select from 'react-select';

type IProps = {
  onChange: any;
  options: any;
  values: any;
  className: string;
  classNamePrefix: string;
  placeholder: string;
};
export const Dropdown = ({ onChange, options, values, className, classNamePrefix, placeholder, }: IProps) => {

    return (
      <>
        <Select
          className={className}
          classNamePrefix={classNamePrefix}
          value={values.label}
          placeholder={placeholder}
          onChange={(value:any) => {
              onChange(value);
          }}
          options={options}
        />
      </>
    );
};

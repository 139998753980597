import ReactPlayer from 'react-player/lazy';
import { Image } from '../../Atoms';
import ReactImageMagnify from 'react-image-magnify';
import './imageGalery.scss';
import { toJS } from 'mobx';
import { PRODUCT_PLACEHOLDER } from '../../../../constants';

type IProps = {
  data: any,
  classValue: string
  isZoom?: boolean
};

export const ImageGalery = ({ data, classValue, isZoom }: IProps) => {
  if (data && isZoom && data.type !== 'video')
    return (
      <ReactImageMagnify
        className='image-container'
        imageClassName='imageClassName'
        enlargedImageClassName='enlargedImageClassName'
        enlargedImageContainerClassName='zoomImageContainer'

        {...{
          smallImage: {
            alt: 'Wristwatch by Ted Baker London',
            isFluidWidth: false,
            height: 445,
            width: 564,
            src: `${data.filepath}?aspect_ratio=8:6`,
            className: 'image-container_img'
          },
          largeImage: {
            src: `${data.filepath}?aspect_ratio=8:6`,
            width: 2200,
            height: 1000
          },
          enlargedImageStyle: {
            objectFit: 'contain'
          }
        }} />);
  else if (data) {
    return (<>
      {data.type === 'video' ?
        <ReactPlayer
          url={data.filepath}
          width='100%'
          height='100%'
          controls={true}
          muted={true}
          playing={true}
          loop={true} /> :
        <Image src={`${data.filepath}?aspect_ratio=8:6`} className={classValue} alt='product' />}
    </>
    );
  } else return (<>
    <img src={PRODUCT_PLACEHOLDER} className={classValue} alt='product' />
  </>);
};

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import wrapperComponent from '../../Templates/wrapperComponent';
import { useStores } from '../../../../models';
import { FullPageLoader } from '../../Atoms';
import { ProfileView } from './profileView/ProfileView';
import { PublicProfileView } from './profileView/PublicProfileView';
import { ProfileViewSkeleton } from './profileView/ProfileViewSkeleton';
import { ProfileTabs } from './profileTabs/ProfileTabs';
import PageNotFound from './../pageNotFound/PageNotFound';
import './FollowingAndFollowers.scss';

const FollowimgAndFollowers = observer(function FollowimgAndFollowers() {
  const params: any = useParams();
  const rootStore = useStores();
  const [loader, setLoader] = useState<boolean>(false);
  const { getUserProfile, userProfileDetail, isUserDiscontinue } =
    rootStore.profileStore;
  const { isLoggedIn } = rootStore.loginStore;
  const { unfollowStatus, totalFollowCount, getUnfollowStatus } =
    rootStore.followerStore;
  const analytics = require('react-segment');

  const fetchUserProfile = async (id: any) => {
    setLoader(true);
    await getUserProfile(id);
    setLoader(false);
  };
  const collectorPageViewed = () => {
    analytics.default.track('Collector Page Viewed');
  };

  const collectorPageExited = () => {
    analytics.default.track('Collector Page Exited');
  };

  useEffect(() => {
    collectorPageViewed();
    fetchUserProfile(params.id);
    getUnfollowStatus(false);
  }, [params.id]);

  useEffect(() => {
    return () => {
      collectorPageExited();
    };
  }, []);

  return (
    <>
      {isUserDiscontinue ? (
        <PageNotFound />
      ) : (
        <div className='profile-container follows-profile'>
          <div className='container'>
            {loader ? (
              <ProfileViewSkeleton />
            ) : (
              <>
                {userProfileDetail && userProfileDetail.id ? (
                  <>
                    {isLoggedIn ? (
                      <ProfileView
                        data={userProfileDetail && userProfileDetail}
                        followCount={
                          userProfileDetail && userProfileDetail.followers
                        }
                        unfollowStatus={unfollowStatus}
                        totalFollowCount={totalFollowCount}
                      />
                    ) : (
                      <PublicProfileView
                        data={userProfileDetail && userProfileDetail}
                        followCount={
                          userProfileDetail && userProfileDetail.followers
                        }
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            <ProfileTabs userId={params.id ? params.id.toString() : ''} />
          </div>
        </div>
      )}
    </>
  );
});

export default wrapperComponent(true, true)(FollowimgAndFollowers);

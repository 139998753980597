import { toJS } from 'mobx';
import { Instance, types, flow } from 'mobx-state-tree';
import { errorToast } from '../../components/ui/Atoms';
import { HTTPSTATUSCODE } from '../../constants';
import { withEnvironment } from '../extensions/with-environment';
import { CartItemModel, paymentCardData, paymentCardDetail } from './cart-entity';

export const CartStoreModel = types
  .model('CartStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, true),
    cartItemList: types.optional(types.array(CartItemModel), []),
    cartSubtotal: types.maybeNull(types.string),
    cartGrandtotal: types.maybeNull(types.string),
    cartDiscountAmt: types.maybeNull(types.string),
    cartMainAmt: types.maybeNull(types.string),
    cartGrandtotal_buy: types.maybeNull(types.string),
    cartSubtotal_buy: types.maybeNull(types.string),
    tax_charges: types.maybeNull(types.string),
    tax_amount: types.optional(types.maybeNull(types.number), 0),
    cartItemCount: types.optional(types.number, 0),
    cartItemCountBuy: types.optional(types.number, 0),
    cartId: types.optional(types.number, 0),
    currentStep: types.optional(types.number, 1),
    buyNowAmt: types.maybeNull(types.string),
    appliedCoupon: false,
    isBuy: false,
    paymentCard: types.optional(types.frozen(paymentCardData), {}),
    paymentCardInfo: types.optional(types.frozen(paymentCardDetail), {}),
    addressStep: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    addProductToCart: flow(function* (cartItemData) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`carts`, cartItemData);
        if (response.data) {
          if (response.data && response.data.statusCode === HTTPSTATUSCODE.NOT_FOUND) {
            errorToast(response.data.message)
          };
        }
        else if (response.response) {
          if (response && response.response.data.statusCode === HTTPSTATUSCODE.BAD_REQUEST) {
            errorToast(response.response.data.msg);
          }
        }
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    addCartSubtotal(price: string | null, grand_total: string | null, tax_amount: number) {
      self.cartMainAmt = price;
      self.cartGrandtotal_buy = grand_total;
      self.cartSubtotal_buy = price;
      self.tax_amount = tax_amount;
    },
    setCurrentStep(step: number) {
      self.currentStep = step;
    },
    setBuyNowAmt(amt: any) {
      self.buyNowAmt = amt;
    },
    setProductCount() {
      self.cartItemCountBuy = 1;
    },
    setBuy(buy: boolean) {
      self.isBuy = buy;
    },
    addPaymentCardDetail(cardDetail: any) {
      self.paymentCard = cardDetail;
    },
    addPaymentCardInfo(cardInfo: any) {
      let data = {};
      if (cardInfo) {
        data = {
          brand: cardInfo && cardInfo.brand,
          exp_month: cardInfo && (cardInfo.exp_month).toString(),
          exp_year: cardInfo && (cardInfo.exp_year).toString(),
          last4: cardInfo && cardInfo.last4,
          name: cardInfo && cardInfo.name
        };
      }
      self.paymentCardInfo = data;
    },
    checkAddressStep(statue: boolean) {
      self.addressStep = statue;
    }
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getCartItem: flow(function* (userId) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`carts?userId=${userId}`);
        const { cart_items, curency_grand_total, curency_subtotal, curency_subtotal_with_discount, item_count, id, tax_total } = response.data.data;
        self.isLoading = false;
        if (typeof (tax_total) === 'string') {
          self.tax_amount = tax_total ? parseFloat(tax_total) : 0;
        } else {
          self.tax_amount = tax_total ? tax_total : 0;
        }
        if (cart_items) {
          let cartItem = cart_items.map((value: any) => {
            let val = {
              id: value.id,
              productId: value.product_id,
              quantity: value.qty,
              totalPrice: value.curency_price,
              name: value.name,
              sku: value.sku,
              outOfStock: value.outOfStock,
              in_wish: value.in_wish,
              lowStock: value.lowStock,
              drop_active: value && value.dropInfo && value.dropInfo.is_active ? true : value && value.dropInfo === null ? true : false,
              availableQuantity: value.avilable_quantity,
              condition: value && value.product_info && value.product_info.condition,
              slug: value && value.product_info && value.product_info.slug,
              variant_data: value.variant_data,
              dropInfo: value.dropInfo,
              medias: value && value.product_info && value.product_info.medias && value.product_info.medias.map((item: any) => {
                let mediaValue = {
                  filePath: item.filepath,
                  entityId: item.entity_id,
                  id: item.id,
                  type: item.type,
                  filepathThumb: item.filepathThumb
                };
                return mediaValue;
              })
            };
            return val;
          });
          if (!self.isBuy) {
            self.cartItemList = cartItem;
            self.cartSubtotal = curency_subtotal_with_discount;
            self.cartGrandtotal = curency_grand_total;
            self.cartItemCount = item_count;
            self.cartId = id;
            self.cartMainAmt = curency_subtotal_with_discount;
            self.appliedCoupon = false;
            self.cartDiscountAmt = null;
          }
          return true;
        } else {
          self.state = 'done';
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    addItemQuantity: flow(function* (itemQty) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.put(`carts`, itemQty);
        self.state = 'done';
        self.isLoading = false;
        return response.response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  })).actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    notifyUser: flow(function* (formData: any) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`products/notify-me`, formData);
        return response.response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    deleteCartItem: flow(function* (id, cartItemId) {
      self.state = 'pending';
      try {
        yield self.environment.api.delete(`carts/${id}/item/${cartItemId}`)
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    applyCoupon: flow(function* (couponData) {
      self.state = 'pending';
      try {
        const res = yield self.environment.api.post(`promotions/apply-coupon`, couponData);
        if (res && res.kind === 'ok') {
          self.cartGrandtotal_buy = res.response.data.data.curency_grand_total;
          self.cartSubtotal_buy = res.response.data.data.curency_subtotal;
          self.cartGrandtotal = res.response.data.data.curency_grand_total;
          self.tax_amount = res.response.data.data.tax_total;
          self.cartSubtotal = res.response.data.data.curency_grand_total;
          self.appliedCoupon = true;
          self.cartDiscountAmt = res.response.data.data.currency_discount;
          self.cartMainAmt = res.response.data.data.curency_subtotal_with_discount;
        } else if (res.status === HTTPSTATUSCODE.BAD_REQUEST) {
          self.appliedCoupon = false;
          self.cartDiscountAmt = null;
          self.cartGrandtotal_buy = self.cartMainAmt;
          self.cartSubtotal_buy = self.cartMainAmt;
          self.tax_amount = self.tax_amount;
          self.cartSubtotal = self.cartMainAmt;
          errorToast(res.data.message);
        }
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    taxApi: flow(function* (couponData) {
      self.state = 'pending';
      try {
        const res = yield self.environment.api.post(`avalara-tax`, couponData);
        if (res && res.kind === 'ok') {
          self.tax_amount = res.response.data;
        } else if (res.status === HTTPSTATUSCODE.BAD_REQUEST) {
          self.tax_amount = self.tax_amount;
          errorToast(res.data.message);
        }
        self.state = 'done';
        self.isLoading = false;
        return self.tax_amount;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    removeCoupons() {
      self.appliedCoupon = false;
      self.cartDiscountAmt = null;
      self.cartGrandtotal_buy = self.cartMainAmt;
      self.cartSubtotal_buy = self.cartMainAmt;
      self.tax_amount = self.tax_amount;
      self.cartSubtotal = self.cartMainAmt;
    }
  }));

type CartStoreType = Instance<typeof CartStoreModel>;
export interface CartStore extends CartStoreType { }


import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { useStores } from '../../../models';
import { SignInModal } from '../Molecules/signInModal/SignInModal';
import { WISHLIST_ICON, WISHLIST_ICON_ACTIVE, PRODUCT_ENTITY_TYPE, MagicNumber, HTTPSTATUSCODE, VALIDATION_MESSAGES, VARIANT_COMBINATION, FunctionalityType, getFunctionalityBlockedMessage, UserTypeName } from '../../../constants';
import { errorToast, successToast } from './Toast/Toast';

type IProps = {
  product: any;
  sizeVariant?: any;
  colorVariant?: any;
  className: string;
  setSizeErr?: (() => void) | any;
  setColorErr?: (() => void) | any;
  toggleWislistVariant?: (() => void) | any;
  chnageWislistVariant?: boolean;
};

interface SkuIProp {
  sku: string;
  quantity: string;
}

export const WishlistButton = ({ setSizeErr, setColorErr, product, sizeVariant, colorVariant, className, chnageWislistVariant, toggleWislistVariant }: IProps) => {
  const rootStore = useStores();
  const [signInModalOpen, setSignInModalOpen] = useState<boolean>(false);
  const [isProfileOpen, openProfileModal] = useState<boolean>(false);
  const [isSignUpOpen, openSignUpModal] = useState<boolean>(false);
  const [isEmailBtn, disableEmailBtn] = useState<boolean>(false);
  const [wishlistClick, setWishlistClick] = useState<boolean>(false);
  const [disabledWishlist, setDisabledWishlist] = useState<boolean>(false);
  const [inWishlist, setInWishlist] = useState<boolean>(false);
  const { isLoggedIn, isFunctionalityAllowed, getRoleTypeName } = rootStore.loginStore;
  const { addWishList, deleteWishlistBySku } = rootStore.wishlistStore;
  const { fetchProductDetail } = rootStore.homeStore;

  const checkVarientValidity = () => {
    if (!product)
      return false;
    if (!product.variantCombinations.length)
      return isLoggedIn && product.in_wish;
    const { variants, variantCombinations } = product;
    let value: boolean = toJS(variants).some((e: any) => {
      if (variantCombinations.length === MagicNumber.ONE) {
        if (colorVariant && (colorVariant === e.Color_value) && e.in_wish) {
          return true;
        } else if (sizeVariant && (sizeVariant === e.Size) && e.in_wish) {
          return true;
        }
      } else {
        if (colorVariant === e.Color_value && sizeVariant === e.Size && e.in_wish)
          return true;
      }
    });
    return value;
  };

  const isProductAddedOnWishlist = ((inWishlist && chnageWislistVariant) || checkVarientValidity());

  const deleteWishlistitem = (wishlistFormdata: any) => {
    setDisabledWishlist(true);
    deleteWishlistBySku(wishlistFormdata.sku).then((res: any) => {
      if (res && res.data && res.data.statusCode === HTTPSTATUSCODE.SUCCESS_REQUEST) {
        errorToast(VALIDATION_MESSAGES.REMOVED_WISHLIST);
        fetchProductDetail(wishlistFormdata.product_id, true);
        setInWishlist(false);
      }
      setDisabledWishlist(false);
    });
  };

  const getFilteredValue = (product: any) => {
    if (product && product.variants) {
      return toJS(product.variants).filter((e: any) => {
        if (product.variantCombinations.length === MagicNumber.ONE) {
          if (colorVariant && (colorVariant === e.Color_value)) {
            return e.sku;
          } else if (sizeVariant && (sizeVariant === e.Size)) {
            return e.sku;
          }
        } else {
          if (colorVariant === e.Color_value && sizeVariant === e.Size)
            return e.sku;
        }
      });
    };
  };

  const getProductSkuWishlist = (product: any) => {
    if (!sizeVariant && product.variantCombinations.includes(VARIANT_COMBINATION.SIZE))
      setSizeErr();
    else if (!colorVariant && product.variantCombinations.includes(VARIANT_COMBINATION.COLOR))
      setColorErr();
    else {
      let sktValue: [SkuIProp] = getFilteredValue(product);
      const wishlistFormdata = {
        product_id: product.id,
        entity_type: PRODUCT_ENTITY_TYPE.VARIANT,
        sku: sktValue[MagicNumber.ZERO].sku
      };
      if (isProductAddedOnWishlist) {
        deleteWishlistitem(wishlistFormdata);
      }else {
        setDisabledWishlist(true);
        addWishList(wishlistFormdata).then((res: any) => {
          if (res && res.data && res.data.statusCode === HTTPSTATUSCODE.SUCCESS_REQUEST) {
            successToast(VALIDATION_MESSAGES.ADDED_WISHLIST);
            fetchProductDetail(wishlistFormdata.product_id, true);
            setInWishlist(true);
            toggleWislistVariant();
          }
          setDisabledWishlist(false);
        });
      }
    }
  };


  const addProductToWishlist = async () => {
    if (!isLoggedIn) {
      setWishlistClick(true);
      setSignInModalOpen(!signInModalOpen);
    }else if (!isFunctionalityAllowed(FunctionalityType.AddToWishlist)) {
      errorToast(getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user));
      return;
    }else if (product && product.variantCombinations && product.variantCombinations.length) {
      getProductSkuWishlist(product);
    } else {
      const wishlistFormdata = {
        product_id: product.id,
        entity_type: PRODUCT_ENTITY_TYPE.PRODUCT,
        sku: product.sku
      };
      if (isProductAddedOnWishlist) {
        deleteWishlistitem(wishlistFormdata);
      }else {
        setDisabledWishlist(true);
        addWishList(wishlistFormdata).then((res: any) => {
          if (res && res.data && res.data.statusCode === HTTPSTATUSCODE.SUCCESS_REQUEST) {
            successToast(VALIDATION_MESSAGES.ADDED_WISHLIST);
            fetchProductDetail(wishlistFormdata.product_id, true);
            setInWishlist(true);
            toggleWislistVariant();
          }
          setDisabledWishlist(false);
        });
      }
    }
  };

  return (
    <>
      <img
        src={isProductAddedOnWishlist ? WISHLIST_ICON_ACTIVE : WISHLIST_ICON}
        onClick={addProductToWishlist}
        className={`${className} ${disabledWishlist ? 'disabled' : ''}`}
        alt='wishlist'
      />
      <SignInModal
        openModal={setSignInModalOpen}
        isOpen={signInModalOpen}
        openProfileModal={openProfileModal}
        openSignUpModal={openSignUpModal}
        disableBtn={disableEmailBtn}
        wishlistClick={wishlistClick}
        closeWishlistClick={setWishlistClick}
        addProductToWishlist={addProductToWishlist}
      />
    </>
  );

};

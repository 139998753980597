import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import MaskedInput from 'react-text-mask';
import Cookie from 'js-cookie';
import * as Yup from 'yup';
import { StateDropdown, Input, FieldError, ZipInput } from './../../Atoms';
import { validateShippingAddressForm, USER_ID, USER_DATA, USER_NAME, MagicNumber, phoneNumberMask } from './../../../../constants';
import { WHITE_CLOSE_ICON, stringToUpperCase } from '../../../../constants';
import { useStores } from '../../../../models';
import './modal.scss';
type IProps = {
  openModal: (e: boolean) => void;
  isOpen: boolean;
  title: string;
  shippingAddressMode: string;
  activeAddress: any;
};


export const AddShippingAddress = ({ openModal, isOpen, title, activeAddress, shippingAddressMode }: IProps) => {
  const [stateList, setStateList] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  var UsaStates = require('usa-states').UsaStates;
  var usStates = new UsaStates();
  const rootStore = useStores();

  const jsonUserData: any = Cookie.get(USER_DATA);
  const userData = JSON.parse(jsonUserData);

  useEffect(() => {
    const options = usStates.states;
    const updateStateOptions = options.map((state: any) => ({
      label: state.name,
      value: state.abbreviation,
    }));
    setStateList(updateStateOptions);
  }, []);

  const userInitialName = {
    firstName: stringToUpperCase(userData.firstname),
    lastName: stringToUpperCase(userData.lastname),
  };

  const getInitialValue = () => {
    const { name, address_1, address_2, city, state, zip_code, is_default, phone } = activeAddress;
    return {
      user_id: Number(Cookie.get(USER_ID)),
      name: name || `${userInitialName.firstName} ${userInitialName.lastName}`,
      phone: phone || '',
      country: 'US',
      address_1: address_1 || '',
      address_2: address_2 || '',
      city: city || '',
      state: state || '',
      zip_code: zip_code || '',
      is_default: is_default || false
    };
  };

  return (
    <>
      {loader ? <div className='loader-main'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'></span>
        </div>
      </div> : ''}
      <Modal
        size='sm'
        show={isOpen}
        onHide={openModal}
        backdrop={false}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='theme-modal-dialog modal-dialog-centered'
        contentClassName='theme-modal-content'
      >
        <Modal.Header>
          <div className='close-icon' onClick={() => openModal(false)}>
            <img src={WHITE_CLOSE_ICON} className='close-image' alt='close' />
          </div>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={getInitialValue()}
            onSubmit={async values => {
              setLoader(true);
              if (shippingAddressMode === 'Add') {
                await rootStore.settingStore.addShippingAddress(values);
              } else {
                await rootStore.settingStore.updateShippingAddress(activeAddress.id, values);
              }
              setLoader(false);
              rootStore.settingStore.getShippingAddress(Number(Cookie.get(USER_ID)));
              openModal(false);
            }}
            validationSchema={validateShippingAddressForm}
            render={({ handleSubmit, values, setFieldValue, dirty, handleChange }) => (
              <Form>
                <div className='form-group floating-label-group'>
                  <Input
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Name'
                    value={values.name}
                    setFieldValue={setFieldValue}
                  />
                  <FieldError name='name' />
                </div>
                <div className='form-group floating-label-group phone-input-include-code'>
                  <MaskedInput
                    mask={phoneNumberMask}
                    className='form-control'
                    value={values.phone}
                    guide={false}
                    name='phone'
                    id='phone'
                    onChange={handleChange}
                  />
                  <FieldError name='phone' />
                  <span className='select-label selected'>Mobile Number</span>
                  <em className='country-code'>+1</em>
                </div>
                <div className='form-group floating-label-group'>
                  <Input
                    readOnly
                    type='text'
                    id='country'
                    name='country'
                    placeholder='Country'
                    value={values.country}
                    setFieldValue={setFieldValue}
                  />
                  <FieldError name='country' />
                </div>
                <div className='form-group floating-label-group'>
                  <Input
                    type='text'
                    id='address_1'
                    name='address_1'
                    placeholder='Address Line 1'
                    value={values.address_1}
                    setFieldValue={setFieldValue}
                  />
                  <FieldError name='address_1' />
                </div>
                <div className='form-group floating-label-group'>
                  <Input
                    type='text'
                    id='address_2'
                    name='address_2'
                    placeholder='Address Line 2 (Optional)'
                    value={values.address_2}
                    setFieldValue={setFieldValue}
                  />
                  <FieldError name='address_2' />
                </div>
                <div className='form-group floating-label-group'>
                  <Input
                    type='text'
                    id='city'
                    name='city'
                    placeholder='City'
                    value={values.city}
                    setFieldValue={setFieldValue}
                  />
                  <FieldError name='city' />
                </div>
                <div className='form-group floating-label-group'>
                  <StateDropdown
                    className='reactSelect'
                    classNamePrefix='react-select'
                    onChange={(value: any) => setFieldValue('state', value.label)}
                    value={values.state}
                    options={stateList}
                    placeholder='State'
                  />
                  <span className={`select-label ${values.state && 'selected'}`}>State</span>
                  <FieldError name='state' />
                </div>
                <div className='form-group floating-label-group'>
                  <ZipInput
                    type='text'
                    id='zip_code'
                    name='zip_code'
                    placeholder='Zip Code'
                    value={values.zip_code}
                    setFieldValue={setFieldValue}
                    maxLength={MagicNumber.FIVE}
                  />
                  <FieldError name='zip_code' />
                </div>
                <div className='modal-btn-group'>
                  <button
                    type='submit'
                    className='btn primary'
                    disabled={!dirty ? true : false}>
                    {shippingAddressMode === 'Add' ? 'Save' : 'Update'}
                  </button>
                </div>
              </Form>
            )} />
        </Modal.Body>
      </Modal>
    </>
  );
};

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import Cookies from 'js-cookie';
import moment from 'moment';
import {
    BLACK_ICON, LEFT_ARROW, MagicNumber,
    RIGHT_ARROW, MESSAGE_ICON, COLOR_ERR, SIZE_ERR, accordianTab,
} from '../../../../constants';
import { useStores } from '../../../../models';
import { AccordionCard, ImageGalery } from '../../Molecules';
import { Image, FullPageLoader, FieldError, WishlistButton, errorToast } from '../../Atoms';
import wrapperComponent from '../../Templates/wrapperComponent';
import './Details.scss';
import { DetailSkeleton } from './DetailSkeleton';
import { AccordionSellerCard } from '../../Molecules/accordionCard/AccordionSellerCard';
import { DownloadPopup } from '../../Molecules/downloadPopup/downloadPopup';

export const ScannedProduct = observer(function ScannedProduct() {
    const rootStore = useStores();
    const history = useHistory();
    const { TWO, ZERO, ONE } = MagicNumber;
    const { isDetailLoad,
        setProfileImage,
        setQuantity, emptyProductDetails,
        setProductIds, variantCombination,
    } = rootStore.homeStore;

    const { inProcess, getOrderDetailScanCodeProduct, productImage } = rootStore.pendingPurchaseStore;

    const [status, setProductStatus] = useState<boolean>(false);
    let [count, setImageCount] = useState<number>(ZERO);
    const [loader, setLoader] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const [productDetails, setProductDetails] = useState<any>();
    const [colorVariant, setColorVariant] = useState<any>(null);
    const [sizeVariant, setSizeVariant] = useState<any>(null);
    const [colorVariErr, setColorErr] = useState<boolean>(false);
    const [sizeVariErr, setSizeErr] = useState<boolean>(false);
    const [wislistVariant, setWislistVariant] = useState<boolean>(true);
    const [colorVariantList, setColorVariantList] = useState<any>([]);
    const [sizeVariantList, setSizeVariantList] = useState<any>([]);
    const [mediaList, setMediatList] = useState<any>([]);
    const [isdownlodalModalOpen, opendownloadPopupModal] =
        useState<boolean>(false);

    const analytics = require('react-segment');

    const getDetails = async (path: any) => {
        const params = !isNaN(path) && Number.isInteger(parseFloat(path));
        const isNan = params ? parseInt(path) : path;
        await getOrderDetailScanCodeProduct(isNan)
            .then(response => {
                if (response && response.id) {
                    setProductDetails(response);
                }
            }).catch(error => {
                errorToast(error);
            });
        await setProductIds();
        setLoader(false);
    };

    useEffect(() => {
        if (colorVariant || sizeVariant) {
            const data = getFilteredValue(productDetails);
            if (data && data.length) {
                let medias = [...mediaList];
                medias[medias.length - ONE] = data[ZERO].media[ZERO];
                setImageCount(medias.length - ONE);
                setMediatList(medias);
                setQuantity(parseInt(data[ZERO].quantity));
                data[ZERO].media.length && setProfileImage(toJS(data[ZERO].media[ZERO]));
            } else {
                setQuantity(ZERO);
            }
        }
    }, [productDetails, colorVariant, sizeVariant]);

    useEffect(() => {
        if (productDetails && variantCombination) {
            if (productDetails.variantCombinations && productDetails.variantCombinations.length === TWO) {
                setSizeVariantList(getUniquieVariants('Size'));
                setSizeVariant(getUniquieVariants('Size')[ZERO]);
            } else if (productDetails && productDetails.variantCombinations && productDetails.variantCombinations.length) {
                if (productDetails.variantCombinations[ZERO].includes('Color')) {
                    setColorVariantList(getUniquieVariants('Color'));
                } else {
                    const data = getUniquieVariants(productDetails.variantCombinations[ZERO], true);
                    setSizeVariantList(data);
                    setSizeVariant(data[ZERO]);
                }
            }
        }
    }, [productDetails, variantCombination]);

    useEffect(() => {
        if (productDetails && productDetails.variantCombinations.length === TWO) {
            setColorVariantList(getUniquieVariants('Color'));
        }
    }, [sizeVariant]);

    useEffect(() => {
        emptyStateValues();
        let path = history.location.pathname.split('/');
        getDetails(path[TWO]);
        const user_id = Cookies.get('user_id') || '0';
        setUserId(user_id);
    }, []);

    useEffect(() => {
        if (productDetails) {
            setProductStatus(true);
            let medias: Array<any> = [];
            if (productDetails.variants.length) {
                medias.push(productDetails.variants[ZERO].media[ZERO]);
            }
            medias = [...productDetails.medias, ...medias];
            setMediatList(medias);
        }
    }, [productDetails]);

    const emptyStateValues = () => {
        emptyProductDetails();
        setColorVariantList([]);
        setSizeVariantList([]);
        setProfileImage(null);
        setColorVariant(null);
        setSizeVariant(null);
        setColorErr(false);
        setSizeErr(false);
    };

    const getUniquieVariants = (key: string, status?: boolean) => {
        if (!productDetails)
            return [];
        let combination: Array<string | any> = productDetails.variants.map((e: any) => {
            if (key === 'Size') {
                return e[key];
            } else if (!status && (sizeVariant === e.Size)) {
                return e.Color_value;
            } else if (status) return e.Color_value;
        });
        let uniqueChars: Array<string | any> = [];
        combination.forEach((c) => {
            if (!uniqueChars.includes(c) && c !== (undefined)) {
                uniqueChars.push(c);
            }
        });
        if ((colorVariant && !uniqueChars.includes(colorVariant)) || (!colorVariant && key === 'Color'))
            setColorVariant(uniqueChars.sort()[ZERO]);
        return uniqueChars.sort();
    };

    const setPageCount = (key: string) => {
        if (productDetails) {
            if (key === 'NEXT' && mediaList.length - MagicNumber.ONE > count) {
                count = count + MagicNumber.ONE;
            } else if (key === 'PREV' && count > ZERO) {
                count = count - MagicNumber.ONE;
            }
            if (mediaList.length - MagicNumber.ONE >= count) {
                setProfileImage(toJS(mediaList[count]));
                setImageCount(count);
            }
        }
    };

    const getContent = () => {
        return inProcess ?
            <div className='detail-container'>No product found</div> :
            <DetailSkeleton />;
    };

    const getFilteredValue = (product: any) => {
        if (product && product.variants) {
            return toJS(product.variants).filter((e: any) => {
                if (product.variantCombinations.length === MagicNumber.ONE) {
                    if (colorVariant && (colorVariant === e.Color_value)) {
                        return e.sku;
                    } else if (sizeVariant && (sizeVariant === e.Size)) {
                        return e.sku;
                    }
                } else {
                    if (colorVariant === e.Color_value && sizeVariant === e.Size)
                        return e.sku;
                }
            });
        }
    };

    return (<>
        {loader && <FullPageLoader />}
        <div className='product-detail-page'>
            <div className='product-detail-container'>
                <div className='back-button' onClick={() => {
                    history.goBack();
                    emptyStateValues();
                }}>
                    <Image src={BLACK_ICON} alt='back' className='back-icon' />
                    <p className='back-text' >Back</p>
                </div>
                <div className='detail-container'>
                    {productDetails ? <>
                        <div className='detail-left'>
                            <div className='container-left'>
                                <div className='product-carousel-section'>
                                    <div className='preview-carousel'>
                                        <div id='sportscontrol' className='carousel' data-interval='false'>
                                            <div className='carousel-inner'>
                                                {mediaList.length ? mediaList.map((e: any, index: number) =>
                                                    <div key={index} className={`carousel-item ${count == index && 'active'}`}>
                                                        <div className='carousel-box'>
                                                            <div className='product-item'>
                                                                <ImageGalery data={toJS(productImage)} classValue='product-image' isZoom />
                                                            </div>
                                                        </div>
                                                    </div>) : <div className='carousel-item active'>
                                                    <div className='carousel-box'>
                                                        <div className='product-item'>
                                                            <ImageGalery data={null} classValue='product-image' />
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                            {mediaList.length > MagicNumber.ONE ? <a className='carousel-control-prev' onClick={() => setPageCount('PREV')} >
                                                <Image src={LEFT_ARROW} alt='arrow' className='icon-image' />
                                                <span className='sr-only'>Previous</span>
                                            </a> : ''}
                                            {mediaList.length > MagicNumber.ONE ? <a className='carousel-control-next' onClick={() => setPageCount('NEXT')}>
                                                <Image src={RIGHT_ARROW} alt='arrow' className='icon-image' />
                                                <span className='sr-only'>Next</span>
                                            </a> : ''}
                                        </div>
                                        <div className='select-product-section thumb-product-selection'>
                                            {mediaList.map((e: any, index: number) =>
                                                <div key={`box_${index}`}
                                                    className={index == count ? 'product-box active-product' : 'product-box'}
                                                    onClick={() => { setImageCount(index); setProfileImage(toJS(e)); }}>
                                                    <ImageGalery data={e} classValue='select-product-img' />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='detail-right'>
                            <div className='product-content-section'>
                                <div className='product-container'>
                                    <div className='available-detail'>
                                        <div className='right-column'>
                                            <div className='notification-icon'>
                                            </div>
                                        </div>
                                    </div>
                                    <h2 className='about-product'>{productDetails.name}</h2>
                                    {/* <>{getPrice()} </> */}
                                    <div className='size-section'>
                                        {sizeVariantList.length ? <>
                                            <p className='size-text'>Size</p>
                                            {sizeVariErr && <FieldError message={SIZE_ERR} />}
                                            <div className='select-size'>
                                                {sizeVariantList.map((ele: any) =>
                                                    <button type='button'
                                                        className={`btn outline-secondary ${ele === sizeVariant && 'active-size'}`}>{ele}</button>
                                                )}
                                            </div>
                                        </> : ''}
                                        {colorVariantList.length ? <div className='color-section'>
                                            <p className='color-text'>Color</p>
                                            {colorVariErr && <FieldError message={COLOR_ERR} />}
                                            <div className='select-color'>
                                                {colorVariantList.map((ele: any, index: number) => <>
                                                    <div className='color-detail'>
                                                        <div className={`color-box ${ele === colorVariant && 'active-color'}`}
                                                            style={{ backgroundColor: ele }} key={index}></div>
                                                        <p key={`k` + index} className={`color-name ${ele === colorVariant && 'active-color'}`}>{ele}</p>
                                                    </div>
                                                </>)}
                                            </div>
                                        </div> : ''}
                                    </div>
                                </div>
                                {productDetails && productDetails.in_my_collection ? <div className='submit-button'>
                                    <>
                                        <button
                                            type='button'
                                            className='btn primary'
                                            onClick={() => opendownloadPopupModal(true)}
                                        >
                                            Auction
                                        </button>
                                        <button
                                            type='button'
                                            className='btn outline-primary'
                                            onClick={() => opendownloadPopupModal(true)}
                                        >
                                            Sell Now
                                        </button>
                                    </>
                                </div> : ''}
                                <AccordionCard data={productDetails.story} target='productstory' isHtml title='Product Story' isCollapse />
                                <AccordionCard data={productDetails.digitalContent} target='digitalcontent' title='Digital Content' from='scanned' isCollapse />
                                {productDetails && productDetails.seller_story && productDetails.seller_story.length ? <AccordionSellerCard data={productDetails.seller_story} target='sellerstory' title='Seller Story' isCollapse show='show' /> : ''}
                                <AccordionCard data={productDetails.digitalContent.creator_message} target={accordianTab.CREATER_MESSAGE} title='Message from Creator' isCollapse />
                                <AccordionCard data={productDetails} show='show' target='details' title='Details' />
                                <AccordionCard data={productDetails} show='show' target='trackinfo' title='Track Info' />
                                {
                                    productDetails.manufacturer ? <AccordionCard data={productDetails} show='show' target='factoryinfo' title='Factory Info' /> : null
                                }
                            </div>
                        </div></> : getContent()}
                </div>
            </div>
            <DownloadPopup
                openModal={opendownloadPopupModal}
                isOpen={isdownlodalModalOpen}
            ></DownloadPopup>
        </div>
    </>
    );
});

export default wrapperComponent(true, false)(ScannedProduct);

import { StreamClient, StreamFeed } from 'getstream';

import { FeedItemDescription } from './FeedItemDescription';
import { FeedItemSocials } from './FeedItemSocials';
import { FeedItemUserDetails } from './FeedItemUserDetails';
import { FeedItemComments } from './FeedItemComments';
import React, { useEffect, useState } from 'react';
import { FEED_POST_RESTRICTIONS, MagicNumber } from '../../../../../constants';
import { errorToast } from '../../../Atoms';
import { useStores } from '../../../../../models';
import { getFeedItemUserID, getFeedItemUserName, isFeedItemShared } from '../FeedListingUtil';

export type Props = {
  feedItem: any,
  streamClient: StreamClient | undefined,
  feed: StreamFeed | undefined,
  optionsDisabled?: boolean,
  reactionsDisabled?: boolean,
  commentsDisabled?: boolean,
  refreshItem?: boolean,
  children?: React.ReactNode,
  postUpdateAttemptedByGuestCB?: () => void,
  onViewFeedCB?: (feedId: string) => void,
  onViewFeedLikesCB?: (feedItem: any) => void,
  onViewFeedSharesCB?: (feedItem: any) => void,
  onShareFeedCB?: (feedId: string) => void,
  onDeleteFeedCB?: (feedUserId: string, feedId: string) => void,
  onReportCommentCB?: (feedId: string, userId: string, comment: string) => void,
  onReportReplyCB?: (feedId: string, userId: string, reply: string) => void,
  onReportPostCB?: (feedId: string, userId: string, reportedContent: string) => void,
  onDeleteCommentCB?: (feedId: string, userId: string, commentId: string, postUserName: string) => void,
  onDeleteReplyCB?: (feedId: string, userId: string, replyId: string, postUserName: string) => void,
};

export const BaseFeedItem = (props: Props) => {

  const rootStore = useStores();
  const { getPostDetail, getMoreReactions } = rootStore.feedStreamStore;

  const postID = props.feedItem.id ?? '';
  const postUserName = getFeedItemUserName(props.feedItem);
  const postDescription = !isFeedItemShared(props.feedItem) ? props.feedItem.tweet ?? '' : props.feedItem.reaction.data.text;

  const [latestCommentList, setLatestCommentList] = useState<any[]>([...(
    props.feedItem.latest_reactions && props.feedItem.latest_reactions.comment ?
      props.feedItem.latest_reactions.comment : [])]);

  const [postLikeID, setPostLikeID] = useState<string>(props.feedItem.own_reactions &&
    props.feedItem.own_reactions.like &&
    props.feedItem.own_reactions.like.length ?
    props.feedItem.own_reactions.like[MagicNumber.ZERO].id : '');

  const [likesCount, setLikesCount] = useState<number>(props.feedItem.reaction_counts?.like ?? MagicNumber.ZERO);
  const [commentsCount, setCommentsCount] = useState<number>(props.feedItem.reaction_counts?.comment ?? MagicNumber.ZERO);
  const [sharesCount, setSharesCount] = useState<number>(props.feedItem.reaction_counts?.repost ?? MagicNumber.ZERO);

  const [isRetrievingLatestComments, setIsRetrievingLatestComments] = useState<boolean>(false);

  useEffect(() => {
    refreshItem();
  }, [props.refreshItem]);

  const refreshItem = async () => {
    await retrieveLatestComments();
    await retrieveSocials();
  };

  const retrieveLatestComments = async () => {
    if (!props.streamClient) return;

    setIsRetrievingLatestComments(true);
    getMoreReactions(props.streamClient, 'comment', postID, '', FEED_POST_RESTRICTIONS.MAX_LATEST_COMMENTS)
      .then(response => {
        setLatestCommentList(response.results);
      }).catch(error => {
        errorToast(error);
      }).finally(() => {
        setIsRetrievingLatestComments(false);
      });
  };

  const retrieveSocials = async () => {
    if (!props.streamClient)
      return false;

    await getPostDetail(props.streamClient, postID)
      .then(results => {
        const reactions = (results[MagicNumber.ZERO] as any).reaction_counts;
        const ownReactions = (results[MagicNumber.ZERO] as any).own_reactions;

        const sharedObject = props.feedItem.object;
        const sharesCount = sharedObject && sharedObject.reaction_counts ?
          sharedObject.reaction_counts.repost ?? MagicNumber.ZERO : reactions.repost ?? MagicNumber.ZERO;

        setLikesCount(reactions.like ?? MagicNumber.ZERO);
        setCommentsCount(reactions.comment ?? MagicNumber.ZERO);
        setSharesCount(sharesCount);
        setPostLikeID(ownReactions.like && ownReactions.like.length ?
          ownReactions.like[MagicNumber.ZERO].id : '');
      })
      .catch(error => {
        errorToast(error);
      });

    return true;
  };

  const onViewFeed = () => {
    props.onViewFeedCB?.(postID);
  };

  return (
    <div className='feed-user-detail'>
      <FeedItemUserDetails
        from='left'
        feedData={props.feedItem}
        onDeletePostCB={props.onDeleteFeedCB}
        onProfileOpenedAsGuestCB={props.postUpdateAttemptedByGuestCB}
        onReportPostCB={(reportedContent) => {
          props.onReportPostCB?.(postID, getFeedItemUserID(props.feedItem), reportedContent);
        }}
        optionsDisabled={props.optionsDisabled}
      />
      <FeedItemDescription description={postDescription} />
      {props.children}
      {
        !props.reactionsDisabled ?
          <FeedItemSocials
            feedID={postID}
            streamClient={props.streamClient}
            likeCount={likesCount}
            commentCount={commentsCount}
            shareCount={sharesCount}
            likeID={postLikeID}
            onLikeUpdatedCB={retrieveSocials}
            onIllegalActionAsGuestCB={props.postUpdateAttemptedByGuestCB}
            onViewFeedCB={onViewFeed}
            onViewLikesCB={() => { props.onViewFeedLikesCB?.(postID); }}
            onViewSharesCB={() => { props.onViewFeedSharesCB?.(!isFeedItemShared(props.feedItem) ? postID : props.feedItem.object.id); }}
            onSharePostCB={() => { props.onShareFeedCB?.(!isFeedItemShared(props.feedItem) ? postID : props.feedItem.object.id); }}
          /> : null
      }
      {
        !props.commentsDisabled ?
          <FeedItemComments
            feedID={postID}
            streamClient={props.streamClient}
            commentList={latestCommentList}
            showOnlySkeleton={isRetrievingLatestComments}
            onCommentAddedCB={async () => {
              await refreshItem();
              return true;
            }}
            onCommentingAsGuestCB={props.postUpdateAttemptedByGuestCB}
            onProfileOpenedAsGuestCB={props.postUpdateAttemptedByGuestCB}
            onViewFeedCB={onViewFeed}
            onReportCommentCB={(userId, comment) => {
              props.onReportCommentCB?.(postID, userId, comment);
            }}
            onReportReplyCB={(userId, reply) => {
              props.onReportReplyCB?.(postID, userId, reply);
            }}
            onDeleteCommentCB={(userId, commentId) => {
              props.onDeleteCommentCB?.(postID, userId, commentId, postUserName);
            }}
            onDeleteReplyCB={(userId, replyId) => {
              props.onDeleteReplyCB?.(postID, userId, replyId, postUserName);
            }}
          /> : null
      }
    </div>
  );
};


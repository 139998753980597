import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import Carousel from 'react-bootstrap/Carousel';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useStores } from '../../../../models';
import { MagicNumber, ALL, NIKE_LOGO, getParam, BANNER_RESTRICTIONS } from '../../../../constants';
import './banner.scss';

type IProps = {
  isLoader?: boolean;
};

export const Banner = observer(function Banner({ isLoader }: IProps) {
  const rootStore = useStores();
  const [loader, setLoader] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { bannerList, getCollection, fetchCollectionProducts } = rootStore.homeStore;
  const history = useHistory();

  const [collectionBannerImage, setCollectionBannerImage] = useState<string>('');
  const [collectionLogoImage, setCollectionLogoImage] = useState<string>('');
  const [collectionName, setCollectionName] = useState<string>('');
  const [collectionTotalItems, setCollectionTotalItems] = useState<number>(MagicNumber.ZERO);

  const getBannerList = async () => {
    const param: any = history.location.state;
    setLoader(true);
    if (param && (param.name !== ALL)) {
      await bannerList(param.id, MagicNumber.ONE, BANNER_RESTRICTIONS.MAX_BANNERS_TO_SHOW);
      setLoader(false);
    } else {
      await bannerList('', MagicNumber.ONE, BANNER_RESTRICTIONS.MAX_BANNERS_TO_SHOW);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (isCollectionPage())
      fetchCollectionData();
    else
      getBannerList();
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (isCollectionPage())
        fetchCollectionData();
      else
        getBannerList();
    });
  }, [history]);

  const fetchCollectionData = async () => {
    setLoader(true);

    const search = history.location.search;

    const collectionId = getParam(search).collection_id;
    await getCollection(collectionId).then(data => {
      setCollectionBannerImage(data.data.banner);
      setCollectionLogoImage(data.data.logo);
      setCollectionName(data.data.collection_name);
    });

    await fetchCollectionProducts(search.replace('?', '&'))
      .then(data => {
        const total = data.data.total;
        setCollectionTotalItems(total ? parseInt(total) : MagicNumber.ZERO);
      });
    setLoader(false);
    setIsInitialLoad(false);
  };

  const calculateDate = (date: Date, flag: string) => {
    let dateFuture = new Date(date).getTime();
    let dateNow = new Date().getTime();
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / MagicNumber.ONE_TH;
    // calculate days
    const days = Math.floor(diffInMilliSeconds / MagicNumber.EIGHTY_SIX_FR_TH);
    diffInMilliSeconds -= days * MagicNumber.EIGHTY_SIX_FR_TH;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / MagicNumber.THIRTY_SIX_TH) % MagicNumber.TWENTYFR;
    diffInMilliSeconds -= hours * MagicNumber.THIRTY_SIX_TH;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / MagicNumber.SIXTY) % MagicNumber.SIXTY;
    diffInMilliSeconds -= minutes * MagicNumber.SIXTY;
    let difference = '';
    if (days > MagicNumber.ZERO) {
      difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    }
    difference += (hours === MagicNumber.ZERO || hours === MagicNumber.ONE) ? `${hours} hour, ` : `${hours} hours, `;
    difference += (minutes === MagicNumber.ZERO || hours === MagicNumber.ONE) ? `${minutes} minutes` : `${minutes} minutes`;
    if (flag === 'days') {
      return days;
    } else if (flag === 'hours') {
      return hours;
    } else {
      return minutes;
    }
  };

  const compareDate = (date: Date) => {
    const isComming = new Date(date).getTime();
    return isComming >= new Date().getTime();;
  };

  const redirectOnUrl = (url: string): any => {
    history.replace(url);
  };

  const isCollectionPage = () => {
    return !!getParam(history.location.search).collection_id;
  };

  return (<>
    {loader && isLoader ? (<div className='banner-skeleton'><Skeleton /></div>) : (
      <div className='carousel-section banner-carousel-section' style={{ paddingTop: isCollectionPage() ? 0 : MagicNumber.NINTY }}>
        {
          isCollectionPage() ?
            <Carousel pause={false} indicators={false}>
              <Carousel.Item>
                <div className='banner-image collection-banner-section'
                  style={{ backgroundImage: 'url(' + collectionBannerImage + ')' }}>
                  <div className='banner-detail'>
                    <div className='logo-detail'>
                      <div className='logo-box'>
                        <img src={collectionLogoImage} alt='banner-logo' className='logo-banner-img' />
                      </div>
                    </div>
                    {
                      isInitialLoad ? null :
                        <div className='text-detail'>
                          <h2 className='title'>{collectionName}</h2>
                          <p className='items'>{collectionTotalItems}
                            <span>{` item${collectionTotalItems === MagicNumber.ONE ? '' : 's'}`}</span></p>
                        </div>
                    }
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
            : null
        }
        {isCollectionPage() ? null :
          rootStore.homeStore.bannerAll.length ? (
            <Carousel pause={false}>
              {rootStore.homeStore.bannerAll.map((item: any, keys: number) => {
                return (
                  <Carousel.Item>
                    {item && item.type === 'image' ? <>
                      <a href={item.link}>
                        <div className='banner-image' style={{ backgroundImage: 'url(' + item.filename + ')' }}>
                        </div>
                      </a>
                      {compareDate(item.sale_start) ? <div className='timing-alert'>
                        <div className='current-section'>
                          <p className='digit-detail'>{calculateDate(item.sale_start, 'days')}</p>
                          <p className='word-detail'>Days</p>
                        </div>
                        <div className='space-section'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                        <div className='current-section'>
                          <p className='digit-detail'>{calculateDate(item.sale_start, 'hours')}</p>
                          <p className='word-detail'>Hours</p>
                        </div>
                        <div className='space-section'>
                          <div className='box'></div>
                          <div className='box'></div>
                        </div>
                        <div className='current-section'>
                          <p className='digit-detail'>{calculateDate(item.sale_start, '')}</p>
                          <p className='word-detail'>Minutes</p>
                        </div>
                      </div> : ''}
                    </> :
                      <div className='banner-image' onClick={() => history.push(`/${item.link}`)}>
                        <ReactPlayer url={item.filename}
                          width='100%'
                          height='100%'
                          controls={true}
                          muted={true}
                          playing={true}
                          loop={true}
                          config={{
                            file: {
                              attributes: {
                                controlsList: 'nodownload',
                                disablePictureInPicture: true
                              }
                            }
                          }}
                        />
                        {compareDate(item.sale_start) ? <div className='timing-alert'>
                          <div className='current-section'>
                            <p className='digit-detail'>{calculateDate(item.sale_start, 'days')}</p>
                            <p className='word-detail'>Days</p>
                          </div>
                          <div className='space-section'>
                            <div className='box'></div>
                            <div className='box'></div>
                          </div>
                          <div className='current-section'>
                            <p className='digit-detail'>{calculateDate(item.sale_start, 'hours')}</p>
                            <p className='word-detail'>Hours</p>
                          </div>
                          <div className='space-section'>
                            <div className='box'></div>
                            <div className='box'></div>
                          </div>
                          <div className='current-section'>
                            <p className='digit-detail'>{calculateDate(item.sale_start, '')}</p>
                            <p className='word-detail'>Minutes</p>
                          </div>
                        </div> : ''}
                      </div>
                    }
                  </Carousel.Item>
                );
              })}

            </Carousel>)
            : (<></>)}
      </div>
    )}
  </>
  );
});

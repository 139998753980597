import { StreamClient } from "getstream";
import { useEffect, useState } from "react";
import { getFeedTime, MagicNumber, USER_ID } from "../../../../../constants";
import { FeedItemUserComment } from "./FeedItemUserComment";
import { FeedItemCommentInput } from './FeedItemCommentInput';
import { useStores } from "../../../../../models";
import { errorToast } from "../../../Atoms";
import { FeedItemUserCommentSkeleton } from "./skeletons/FeedItemUserCommentSkeleton";
import Cookies from "js-cookie";

export const FeedItemComments = (props: {
  feedID: string,
  streamClient: StreamClient | undefined,
  commentList: any[],
  showOnlySkeleton?: boolean,
  onCommentAddedCB?: () => Promise<boolean>,
  onCommentingAsGuestCB?: () => void,
  onProfileOpenedAsGuestCB?: () => void,
  onViewFeedCB?: () => void,
  onReportCommentCB?: (userId: string, comment: string) => void,
  onReportReplyCB?: (userId: string, reply: string) => void,
  onDeleteCommentCB?: (userId: string, commentId: string) => void,
  onDeleteReplyCB?: (userId: string, replyId: string) => void
}) => {

  const rootStore = useStores();

  const { isLoggedIn } = rootStore.loginStore;
  const { addComment, addReply, handleFeed } = rootStore.feedStreamStore;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addCommentToPost = async (comment: string) => {

    if (!props.streamClient)
      return false;

    if (!isLoggedIn) {
      props.onCommentingAsGuestCB?.();
      return false;
    }

    setIsLoading(true);
    let errorEncountered = false;

    try {
      await addComment(props.streamClient, props.feedID, comment);
      const user_id = Cookies.get(USER_ID);
      if (props && props.streamClient && props.streamClient.userId !== user_id) {
        handleFeed('comment', props.feedID);
      }
    } catch (error) {
      errorToast(error);
      errorEncountered = true;
    }

    await props.onCommentAddedCB?.();
    setIsLoading(false);
    return !errorEncountered;
  };

  const addReplyToComment = async (commentId: string, reply: string) => {
    if (!props.streamClient)
      return false;

    if (!isLoggedIn) {
      props.onCommentingAsGuestCB?.();
      return false;
    }

    setIsLoading(true);
    let errorEncountered = false;

    try {
      await addReply(props.streamClient, commentId, reply);
    } catch (error) {
      errorToast(error);
      errorEncountered = true;
    }

    await props.onCommentAddedCB?.();

    setIsLoading(false);
    return !errorEncountered;
  };

  return (
    <div className="feed-comment-section">
      {
        props.commentList && props.commentList.length ?
          <div hidden={isLoading || props.showOnlySkeleton}>
            <a onClick={props.onViewFeedCB}>
              <h3 className="comment-title">View All Comments</h3>
            </a>
            {props.commentList.map(comment => {
              return (
                <FeedItemUserComment
                  commentData={comment}
                  onReplyCB={addReplyToComment}
                  onReportCommentCB={props.onReportCommentCB}
                  onReportReplyCB={props.onReportReplyCB}
                  onDeleteCommentCB={props.onDeleteCommentCB}
                  onDeleteReplyCB={props.onDeleteReplyCB}
                  onProfileOpenedAsGuestCB={props.onProfileOpenedAsGuestCB}
                />
              );
            })}
          </div> : null
      }
      {isLoading || props.showOnlySkeleton ? <>
        <FeedItemUserCommentSkeleton />
        <FeedItemUserCommentSkeleton />
      </> : null}
      <FeedItemCommentInput placeholder="Enter your comment here…" onCommentCB={addCommentToPost} />
    </div>

  );
};

import * as React from 'react';
import { FACEBOOK, INSTAGRAM, LINKED_IN, LOGO_WHITE, TWITTER, WHATSAPP, FULL_SCREEN, APPLE_STORE, GOOGLE_STORE ,INSTA_SVG,TWITTER_SVG,LINKDIN_SVG} from '../../../../constants/image';
import './Footer.scss';
import {Link} from 'react-router-dom';
import { getPlatformInfo } from '../../../../constants';
import {PAGE_ROUTES} from '../../../../constants/Routes';

export const Footer = () => {
  const navigate = () => {
    window.location.href = '/setting#tab=FAQ';
  };
  return (
    <div className='footer-section'>
      {(getPlatformInfo() === 'desktop')? <><div className='footer-container'>
        <div className='link-main-section'>
          <div className='box-section  first-box'>
            <ul className='link-section'>
              <li className='link-text'>
                <a>
                  <img src={LOGO_WHITE} className='logo-icon' alt='logo' />
                </a>
              </li>
              <li className='link-text address'>
              440 S. Roxbury Drive, Unit 201 Beverly Hills, California 90212
            </li>
              {/* <li className='email-text'>
                <a>Email:<strong className='bold-text'>youremail@site.com</strong></a>
              </li>
              <li className='link-text'>Phone:<strong className='bold-text'>+1 408 996 1010</strong></li> */}
            </ul>
          </div>
          <div className='box-section'>
            <ul className='link-section'>
              {/* <li className='foot-head'>
                <p>COMPANY</p>
              </li> */}
              {/* <li className='link-text'>
                <a>About Us</a>
              </li>
              <li className='link-text'>
                <a>Career</a>
              </li> */}
              {/* <li className='link-text'>
                <a>Affilates</a>
              </li>
              <li className='link-text'>
                <a>Blog</a>
              </li> */}
              <li>
                <Link to={PAGE_ROUTES.CONTACT} className='link-text'> <a>Contact Us</a> </Link> 
              </li>
              <li>
                <Link to={PAGE_ROUTES.FAQ} className='link-text'> <a>FAQs</a> </Link> 
              </li>
              <li>
                <Link to={PAGE_ROUTES.OurStory} className='link-text'> <a>About Us</a> </Link> 
              </li>
            </ul>
          </div>
          {/* <div className='box-section'>
            <ul className='link-section'>
              <li className='foot-head'>
                <p>SHOP</p>
              </li>
              <li className='link-text'>
                <a>New Arrivals</a>
              </li>
              <li className='link-text'>
                <a>Sports Collection</a>
              </li>
              <li className='link-text'>
                <a>Music Collection</a>
              </li>
              <li className='link-text'>
                <a>Media Collection</a>
              </li>
              <li className='link-text'>
                <a>Art Collection</a>
              </li>
            </ul>
          </div> */}
          {/* <div className='box-section'>
            <ul className='link-section'>
              <li className='foot-head'>
                <p>HELP </p>
              </li>
              <li className='link-text'>
                <a>Customer Service</a>
              </li>
              <li className='link-text'>
                <a>Conditions of Sales</a>
              </li>
              <li className='link-text'>
                <a>Returns and Refunds</a>
              </li>
              <li className='link-text'>
                <a>Dispute Resolution</a>
              </li>
              <li className='link-text'>
                <a>Business Development</a>
              </li>
            </ul>
          </div> */}
          <div className='box-section last-box'>
            <ul className='link-section'>
              <li className='foot-head'>
                <p>FOLLOW US</p>
              </li>
              <li className='social-section'>
                {/* <a className='social-icon'><img src={FACEBOOK} alt='icon' className='fb-image' /></a> */}
                <a href='https://twitter.com/theoodapp/status/1559356210234761216?s=20&t=uIeNs_dVoRAuxTomVRnezw' target='_blank' className='social-icon'><img src={TWITTER} alt='icon' className='twitter-image' /></a>
                <a href='https://www.linkedin.com/company/oodholdings/' target='_blank' className='social-icon'><img src={LINKED_IN} alt='icon' className='social-image' /></a>
                <a href='https://www.instagram.com/theoodapp/' target='_blank' className='social-icon'><img src={INSTAGRAM} alt='icon' className='social-image' /></a>
                {/* <a className='social-icon'><img src={WHATSAPP} alt='icon' className='social-image' /></a> */}
              </li>
            </ul>
            <ul className='link-section'>
              <li className='social-section'>
              <a className='social-icon' href='https://apps.apple.com/us/app/ood-collectibles-marketplace/id1556575683' target='_blank'>
                <img src={APPLE_STORE} alt='icon' className='google-image' />
              </a>
              <a className='social-icon'  href='https://play.google.com/store/apps/details?id=com.oodapp.marketplace' target='_blank'>
                <img src={GOOGLE_STORE} alt='icon' className='google-image' />
              </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer-line'></div>
        <div className='footer-bottom'>
          <div className='text-detail'>
            <p className='copyright-text'>Copyright 2022 OOD. All Rights Reserved.</p>
          </div>
          <div className='text-detail'>
            <ul className='link-text'>
              <li className='list-text'>
                <Link to={PAGE_ROUTES.PP} className='link' > <a>Privacy Policy</a> </Link>
              </li>
              <li className='divider'></li>
              <li className='list-text'>
                <Link to={PAGE_ROUTES.TC} className='link' > <a>Terms of Use</a> </Link>
              </li>
              <li className='divider'></li>
              <li className='list-text'>
                <Link to={PAGE_ROUTES.AC} className='link' > <a>Additional Terms</a> </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </>: <>
      <div className='footer-container'>
          <div className='link-main-section'>
          <div className='box-section last-box'>
              <ul className='link-section'>
                {/* <li className='foot-head'>
                  <p>FOLLOW US</p>
                </li> */}
                <li className='social-section'>
                  {/* <a className='social-icon'><img src={FACEBOOK} alt='icon' className='fb-image' /></a> */}
                  <a href='https://www.instagram.com/theoodapp/' target='_blank' className='social-icon'><img src={INSTA_SVG} alt='icon' className='social-image' /></a>
                  <a href='https://www.linkedin.com/company/oodholdings/' target='_blank' className='social-icon'><img src={LINKDIN_SVG} alt='icon' className='social-image' /></a>
                  <a href='https://twitter.com/theoodapp/status/1559356210234761216?s=20&t=uIeNs_dVoRAuxTomVRnezw' target='_blank' className='social-icon'><img src={TWITTER_SVG} alt='icon' className='twitter-image' /></a>
                  {/* <a className='social-icon'><img src={WHATSAPP} alt='icon' className='social-image' /></a> */}
                </li>
              </ul>

            </div>
            <div className='box-section'>
              <ul className='link-section'>
  
                <li>
                  <Link to={PAGE_ROUTES.CONTACT} className='link-text'> <a>Contact us</a> </Link>
                </li>
                <li>
                  <Link to={PAGE_ROUTES.FAQ} className='link-text'> <a>FAQ</a> </Link>
                </li>
                <li>
                  <Link to={PAGE_ROUTES.OurStory} className='link-text'> <a>About us</a> </Link>
                </li>
                <li>
                  <Link to={PAGE_ROUTES.PP} className='link-text'> <a>Privacy policy</a> </Link>
                </li>
                <li>
                  <Link to={PAGE_ROUTES.TC} className='link-text'> <a>Terms of use </a> </Link>
                </li>
                <li>
                  <Link to={PAGE_ROUTES.AC} className='link-text'> <a>Additional terms</a> </Link>
                </li>
              </ul>
              <ul className='link-section'>
                <li className='link-text'>
                  <a>
                    <img src={LOGO_WHITE} className='logo-icon' alt='logo' />
                  </a>
                </li>
                
                <li className='link-text address'>
                Beverly Hills, CA 90212
  </li>
  <li className='link-text address'>
  ©2022 ood.
  </li>
  <li className='link-text address'>
                All rights reserved
  </li>
  {/* <li className='link-text address'>
     Beverly Hills, CA 90212 @ 2022 ood All rights reserved
  </li> */}
    {/* <li className='email-text'>
      <a>Email:<strong className='bold-text'>youremail@site.com</strong></a>
    </li>
    <li className='link-text'>Phone:<strong className='bold-text'>+1 408 996 1010</strong></li> */}
              </ul>
            </div>
            <div className='box-section'>
            <ul className='link-section'>
              <li className='social-section'>
              <a className='social-icon' href='https://apps.apple.com/us/app/ood-collectibles-marketplace/id1556575683' target='_blank'>
                <img src={APPLE_STORE} alt='icon' className='apple-image' />
              </a>
              <a className='social-icon' href='https://play.google.com/store/apps/details?id=com.oodapp.marketplace' target='_blank'>
                <img src={GOOGLE_STORE} alt='icon' className='apple-image' />
              </a>
              </li>
            </ul>
          </div>

          </div>
        </div></>
          }

    </div>
  );
};

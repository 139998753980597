import { MouseEventHandler, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

type Props = {
  imageURL: string,
  placeholder: string,
  key?: string,
  alt?: string,
  classes?: string,
  product_type?: string,
  type?: string,
  onClick?: MouseEventHandler,
  isPrivate?: boolean;
  auctionStatus?: string;
  status?: string;
};

export const SkeletonImage = (props: Props) => {

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const handleStatus = () => {
    return (
      props.status !== 'sold' ? props.status === 'shipped' ? 'Shipped' : 'On Sale' : props.status === 'sold' ? 'Sold' : 'Shipped'
    );
  };

  return (
    <>
      {!isLoaded ? <Skeleton className={props.classes} /> : null}
      <img
        key={props.key}
        className={props.classes}
        alt={props.alt}
        role={props.onClick ? 'button' : ''}
        onLoad={e => { setIsLoaded(true); }}
        onError={e => { (e.target as HTMLImageElement).src = props.placeholder; }}
        onClick={props.onClick}
        src={`${props.imageURL}?aspect_ratio=1:1`} />
      {props.isPrivate ? <>
        {props.product_type && props.product_type === 'sale' ? <p className='sale-auction-box'><span className='auction-text'>Private Sale</span></p> : props.product_type && props.product_type === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Private Sale</span></p> : props.type && props.type === 'sale' ? <p className='sale-auction-box'><span className='auction-text'>Private Sale</span></p> : ''}</> :
        <>
          {props.product_type && props.product_type === 'sale' ? <p className={props.status === 'shipped' ? 'sale-auction-box shipped-box' : 'sale-auction-box'}><span className='auction-text'>{handleStatus()}</span></p> : props.product_type && props.product_type === 'auction' && props.auctionStatus === 'cancelled' ? <p className='sale-auction-box red-box'><span className='auction-text'>Auction Expired</span></p> : props.product_type && props.product_type === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}</>}
    </>
  );
};

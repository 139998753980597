import Cookie from 'js-cookie';
import { TOKEN, REFRESH_TOKEN } from '../../constants';
/**
 * The options used to configure the API.
 */
const URL_API = process.env.REACT_APP_SERVER_HOST + `/api/v1/` || '/';
const Api_Key = process.env.REACT_APP_SERVER_API_KEY || '/';
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number
  /**
   * apiKey
   */
  api_key: string
  /**
     * authorization
     */
  authorization: string
  /**
   * refresh token
   */
  refresh: string | undefined
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: URL_API,
  timeout: 10000,
  api_key: Api_Key,
  authorization: 'Bearer ' + Cookie.get(TOKEN),
  refresh: Cookie.get(REFRESH_TOKEN),
};

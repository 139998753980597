import { Link , useHistory } from 'react-router-dom';
import './pageNotFound.scss';
import { LOGO } from '../../../../constants';

const PageNotFound = () => {

  const history = useHistory();

  const navigateToHome = () => {
    history.push('/');
  };

  return (
    <>
      <div className='pageNotFound-wrapper'>
        <div className='header-section'>
          <nav className='navbar navbar-expand-lg navbar-light ng-star-inserted'>
            <div className='nav-wrap'>
              <div className='left-header' onClick={() => navigateToHome()}>
                <div className='logo'>
                  <img src={LOGO} alt='logo' className='logo-image' />
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className='pageNotFound-content'>
          <h1>404</h1>
          <h4>Oops! Page not found<span>We can't seem to find the page you're looking for.</span></h4>
          <Link to='/' className='btn primary'>Go To Home</Link>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;

import { Instance, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { FollowCountModal, FollowingUserModal } from './follower-entity';
import { MagicNumber } from './../../constants';

export const FollowerStoreModal = types
  .model('FollowerStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, true),
    totalFollowCount: types.optional(types.frozen(FollowCountModal), {}),
    followingList: types.optional(types.array(FollowingUserModal), []),
    followersList: types.optional(types.array(FollowingUserModal), []),
    followingListTotalpage: types.optional(types.number, 0),
    followersListTotalpage: types.optional(types.number, 0),
    unfollowStatus: types.optional(types.boolean, false),
    followUnfollowLoader: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getFollowCount: flow(function* (id) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`followers/count/${id}`);
        const {data} = response.data;
        self.totalFollowCount = data;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    getUnfollowStatus(status:boolean){
      self.unfollowStatus = status;
    },
    setFollowUnfollowLoader(status:boolean){
      self.followUnfollowLoader = status;
    }
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getFollowingList: flow(function* (
        id,
        page: number = MagicNumber.ONE,
        limit: number = MagicNumber.FIFTEEN, 
        search?: string
    ) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`/followers/following/${id}?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`);
        const { results, page_total, total } = response.data;
        self.followingList = results;
        self.followingListTotalpage = page_total;
        self.state = 'done';
        self.isLoading = false;
        return response.data;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getFollowersList: flow(function* (
        id,
        page: number = MagicNumber.ONE,
        limit: number = MagicNumber.FIFTEEN, 
        search?: string
    ) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`/followers/followers/${id}?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`);
        const { results, page_total, total } = response.data;
        self.followersList = results;
        self.followersListTotalpage = page_total;
        self.state = 'done';
        self.isLoading = false;
        return response.data;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    followUser: flow(function* (id) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`followers/follow/${id}`);
        self.state = 'done';
        self.isLoading = false;
        return response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    unfollowUser: flow(function* (id) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`followers/unfollow/${id}`);
        self.state = 'done';
        self.isLoading = false;;
        return response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    removeFollower: flow(function* (id) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.delete(`followers/${id}`);
        self.state = 'done'
        self.isLoading = false;
        return response.response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))

type FollowerStoreType = Instance<typeof FollowerStoreModal>
export interface FollowerStore extends FollowerStoreType { }


import Skeleton from 'react-loading-skeleton';

export const SettingSketelon = () => {
  return (
    <>
        <div className='setting-page-skeleton'>
           <div className='tabs-sektelon'>
               <div className='tabs-sektelon-left'>
                <Skeleton count={5} />
               </div>
               <div className='tabs-sektelon-right'>
                <Skeleton />
               </div>
           </div>
        </div>
    </>
  );
};

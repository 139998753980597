import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';
import './_viewAllBids.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../models';
import { SUCCESS_CHECKED } from '../../../../constants';
import { useHistory } from 'react-router-dom';

type IProps = {
  openModal: any;
  isOpen: boolean;
};

export const SuccessBid = observer(function ViewAllBids({ openModal, isOpen }: IProps) {

  const history = useHistory();
  const rootStore = useStores();
  const { bid, checkBid } = rootStore.userStore;
  const { tax_amount } = rootStore.cartStore;

  const addBids = () => {
    history.push('/');
  };

  const currencyF = () => {
    const l10nUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return l10nUSD.format(parseInt(bid) || 0);
  };

  const totalBidAmt = () => {
    let taxAmt = (tax_amount ? tax_amount : 0);
    const l10nUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return l10nUSD.format(parseInt(bid) + taxAmt || 0);
  };

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        backdrop={false}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='view-bids-page modal-dialog-centered'
        contentClassName='view-bids-content'
      >
        <div className='view-bids-background place-bids-bg'>
          <Modal.Body className='order-placed-body'>
            <img src={SUCCESS_CHECKED} className='success-image' alt='success' />
            <h2 className='title-order'>{totalBidAmt()}</h2>
            <h3 className='placed-text'>Bid Placed</h3>
            <p className='placed-pg'>Your bid has been placed successfully!</p>
            <button type='button' onClick={() => addBids()} className='btn outline-primary place-btn'>Browse</button>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
});

import { useEffect, useState } from 'react';
import wrapperComponent from '../../Templates/wrapperComponent';
import { useHistory } from 'react-router-dom';
import BackArrow from '../../../../assets/images/back-arrow.png';
import { useStores } from '../../../../models';
import Skeleton from 'react-loading-skeleton';
 import './OurStory.scss';
 import {getPlatformInfo, RIGHT_ARROW_ICON, WHITE_ARROW_DOWN } from '../../../../constants';
//  import { WHITE_ARROW_DOWN } from '../../../../constants/image';
import {HD_Mobiles, MUSIC_IMG2,MUSIC_IMG3,MUSIC_IMG4} from '../../../../constants/image';

const OurStory = () => {
  const history = useHistory();
  const rootStore = useStores();
  const [terms, setTerms] = useState();
  const [heading, setHeading] = useState();
  const [loader, setLoader] = useState(true);
  const [collapse,setcollapse]=useState(true);
  const [Authcollapse,setAuthcollapse]=useState(false);
  const [Communitycollapse,setCommunitycollapse]=useState(true);
  const [Royalcollapse,setRoyalitycollapse]=useState(true);
  const { getabout } = rootStore.staticPagesStore;
  const fetchData = async () => {
    await getabout().then(res => {
       setTerms (res.data.data[0].description); 
        setHeading(res.data.data[0].title); 
        setLoader(false);
     });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const collapsed = () =>{
if(collapse === true)
{
 setcollapse(false);
 setAuthcollapse(true);
 setCommunitycollapse(true);
 setRoyalitycollapse(true);
} 
else if( collapse === false)
{
  setcollapse(true);
}
else{
setcollapse(true);
setAuthcollapse(false);
setCommunitycollapse(false);
setRoyalitycollapse(false);
}  };

const collapsedCommunity= ()=>{
  if(Communitycollapse === true)
{
  setCommunitycollapse(false);
  setAuthcollapse(true);
  setcollapse(true);
  setRoyalitycollapse(true);
} 
else if(Communitycollapse === false)
{
  setCommunitycollapse(true);
}
else{
  setCommunitycollapse(true);
  setAuthcollapse(false);
  setcollapse(false);
  setRoyalitycollapse(false);
} 
};

const collapsedRoyality= ()=>{
  if(Royalcollapse === true)
{
  setRoyalitycollapse(false);
  setAuthcollapse(true);
  setcollapse(true);
  setCommunitycollapse(true);
} 
else if(Royalcollapse === false)
{
  setRoyalitycollapse(true);
}
else{
  setRoyalitycollapse(true);
  setAuthcollapse(false);
  setcollapse(false);
  setCommunitycollapse(false);
} 
};
const collapsedAuth= ()=>{
  if(Authcollapse === true)
{
  setRoyalitycollapse(true);
  setAuthcollapse(false);
  setcollapse(true);
  setCommunitycollapse(true);
} 
else if(Authcollapse === false)
{
  setAuthcollapse(true);
}
else{
  setRoyalitycollapse(false);
  setAuthcollapse(true);
  setcollapse(false);
  setCommunitycollapse(false);
} 
};
  return (
    <>
      <div className='home-main-container'>
      <div className='story-banner-section'>
      {(getPlatformInfo() === 'desktop')? <>
      <div className='story-image banner1'>
          </div>
          </>:    <><div className='story-image banner1'>
          </div>
              {/* <img src={WHITE_ARROW_DOWN} alt='arrow' className='icon-image' /> */}
            </>}
    
      </div>
      <div className='our-story-section'>
        <div className='head-story'>
          <h3 className='title-story'>Our story</h3>
        </div>
        <div className="story-detail">
          <p className="pg">A jump in the pulse. A tightening in the belly. The imagination kicked into overdrive. Tell tale signs that you’re on the hunt— for something new, something rare. An object of desire alive with possibility. You’re not alone. The fire of collecting catches each of us. Because at <span>ood</span>, we’re not just collecting. We’re igniting the collector in all of us.</p>
          <div className="left-border"></div>
        </div>
      </div>
      <div className='buy-section'>
        <div className='head-buy'>
          <h3 className='title-buy'>Why buy on ood?</h3>
        </div>
        <div className='buy-detail'>
          {/* for desktop view */}
          <div className='desktop-buy-detail'>
            <div className='card'>
              <h1 className='title-buy titleOne'>
                Authentication
              </h1>
              <div className="card-body">
                <p className="pg">Easily verifiable authenticity, secured by blockchain technology – just scan the items you’ve purchased using the <span>ood</span> app and receive confirmation instantly. Never wonder if the items you’ve purchased and received are the real deal, you can check it for yourself.</p>
              </div>
            </div>
            <div className='card'>
              <h1 className='title-buy titleTwo'>
                Unique content
              </h1>
              <div className='card-body'>
                <p className='pg'>Once your purchases are verified and accepted by you, exclusive bonus content is unlocked for your enjoyment only. Congratulations! You are now a VIP with access that no one else has and more content may be delivered to you at any time... stay tuned!</p>
              </div>
            </div>
            <div className='card'>
              <h1 className='title-buy titleThree'>
                Community
              </h1>
              <div className="card-body">
                <p className="pg">  As a registered collector on <span>ood</span>, you are now part of the <span>ood</span> community. You will receive messages and notifications directly from the artists and creators whose works you’ve collected and can respond and converse with other members. Welcome!</p>
              </div>
            </div>
            <div className='card'>
              <h1 className='title-buy titleFour'>
                Royalty
              </h1>
              <div className="card-body">
                 <p className="pg"> We treat our partners like royalty, but more importantly, we pay the artists and creators who drop and list their works on <span>ood</span> a royalty each and every time their works are sold on <span>ood</span> This has never been done before and we hope that, if you ever decide to sell something from your <span>ood</span> collection, you will list it in the <span>ood</span> marketplace so we can continue to pay the creators their due. We want to honor them and ensure that they are always rewarded for bringing us the stuff that we love.</p>
               </div>
            </div>
          </div>

          {/* for mobie view */}
          <div id='accordion'>
            <div className='card'>
              <div className='card-header' id='headingOne'>
                <h5 className='mb-0'>
                  <button className='btn btn-link' data-toggle='collapse' data-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne' onClick={collapsedAuth}>
                    Authentication
                  </button>
                   </h5>
                   {(Authcollapse === true)? <>
                  <span className='heading'>Easily verifiable authenticity, secured by...</span>
  </>:
   <span className='heading'>Easily verifiable authenticity, secured by</span>}
              </div>
              <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                  <p>blockchain technology – just scan the items you’ve purchased using the <span>ood</span> app and receive confirmation instantly. Never wonder if the items you’ve purchased and received are the real deal, you can check it for yourself.</p>
                </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-header' id='headingTwo'>
                <h5 className='mb-0'>
                  <button className='btn btn-link collapsed' data-toggle='collapse' data-target='#collapseTwo' aria-expanded='false' aria-controls='collapseTwo'  onClick={collapsed}>
                    Unique content
                  </button>
                </h5>
                {(collapse === true)? <>
                  <span className='heading'>Once your purchases are verified and ...</span>
  </>:
   <span className='heading'>Once your purchases are verified and</span>}
                </div>
              <div id='collapseTwo' className='collapse' aria-labelledby='headingTwo' data-parent='#accordion'>
                <div className='card-body'>
                  <p>accepted by you,exclusive bonus content is unlocked for your enjoyment only. Congratulations! You are now a VIP with access that no one else has and more content may be delivered to you at any time… stay tuned!</p>
                   </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-header' id='headingThree'>
                <h5 className='mb-0'>
                  <button className='btn btn-link collapsed' data-toggle='collapse' data-target='#collapseThree' aria-expanded='false' aria-controls='collapseThree' onClick={collapsedCommunity}>
                    Community
                  </button>
                </h5>
                {(Communitycollapse === true)? <>
                  <span className='heading'>As a registered collector on ood, you are...</span>
  </>:
   <span className='heading'>As a registered collector on ood, you are</span>}
              </div>
              <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div className="card-body">
                <p>now part of the <span>ood</span> community. You will receive messages and notifications directly from the artists and creators whose works you’ve collected and can respond and converse with other members. Welcome!</p>
                      </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-header' id='headingFour'>
                <h5 className='mb-0'>
                  <button className='btn btn-link collapsed' data-toggle='collapse' data-target='#collapseFour' aria-expanded='false' aria-controls='collapseFour' onClick={collapsedRoyality}>
                    Royalty
                  </button>
                </h5>
                {(Royalcollapse === true)? <>
                  <span className='heading'>We treat our partners like royalty, but...</span>
  </>:
   <span className='heading'>We treat our partners like royalty, but</span>}
              </div>
              <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                <div className="card-body">
                 <p>more importantly , we pay the artists and creators who drop and list their works on <span>ood</span> a royalty each and every time their works are sold on <span>ood</span> This has never been done before and we hope that, if you ever decide to sell something from your <span>ood</span> collection, you will list it in the <span>ood</span> marketplace so we can continue to pay the creators their due. We want to honor them and ensure that they are always rewarded for bringing us the stuff that we love. </p>
                </div>
              </div>
            </div>
          </div>
          <button type='button' className='btn primary buy-now-btn' onClick={() => history.push('/marketplace')}>
              Buy now
               <img src={RIGHT_ARROW_ICON} alt='arrow' className='arrow-icon-right' /> 
            </button>
        </div>
      </div>
    </div> 
    </>
  );
};

export default wrapperComponent(true, true)(OurStory);

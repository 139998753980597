import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';
import './_viewAllBids.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../models';
import { MagicNumber, MENU_ICON_CLOSE, PAGE_ROUTES } from '../../../../constants';
import Skeleton from 'react-loading-skeleton';
import { toJS } from 'mobx';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

type IProps = {
  openModal: any;
  isOpen: boolean;
  productUserId?: number;
};

export const ViewAllBids = observer(function ViewAllBids({ openModal, isOpen, productUserId }: IProps) {

  const rootStore = useStores();
  const { getAllBids, productBids, isLoading } = rootStore.userStore;
  const { productIdList } = rootStore.homeStore;
  const [userId, setUserId] = useState<string>('');
  const history = useHistory();

  const fetchData = async () => {
    await getAllBids(productIdList, MagicNumber.ONE, MagicNumber.TWENTY, 'view');
  }; 

  const DisplayCurrentTime = (dates: Date) => {
    const date = new Date(dates);
    const hours = date.getHours() < MagicNumber.TEN ? '0' + date.getHours() : date.getHours();
    const converthours = date.getHours() % MagicNumber.TWELVE < MagicNumber.TEN ? '0' + date.getHours() % MagicNumber.TWELVE : date.getHours() % MagicNumber.TWELVE;
    const minutes = date.getMinutes() < MagicNumber.TEN ? '0' + date.getMinutes() : date.getMinutes();
    const time = converthours + ':' + minutes + (hours < MagicNumber.TWELVE ? ` am` : ` pm`);
    return time;
  };

  const bidsDates = (dates: Date) => {
    const date = new Date(dates);
    const shortYr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = date.getMonth();
    const bidsDate = date.getDate() < MagicNumber.TEN ? '0' + date.getDate() : date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours() < MagicNumber.TEN ? '0' + date.getHours()  : date.getHours();
    const converthours = date.getHours() % MagicNumber.TWELVE < MagicNumber.TEN ? '0' + date.getHours() % MagicNumber.TWELVE : date.getHours() % MagicNumber.TWELVE;
    const minutes = date.getMinutes() < MagicNumber.TEN ? '0' + date.getMinutes() : date.getMinutes();
    const time = minutes + (hours < MagicNumber.TWELVE ? ` am` : ` pm`);
    return `${shortYr[month]}, ${bidsDate} ${year}, ${converthours}:${time}`;
  };

  const calculateDate = (date: Date) => {
    const bidsDate = new Date(date).getDate();
    const todayDate = new Date().getDate();
    const dateFuture = new Date(date).getTime();
    const dateNow = new Date().getTime();
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / MagicNumber.ONE_TH;
    // calculate days
    const days = Math.floor(diffInMilliSeconds / MagicNumber.EIGHTY_SIX_FR_TH);
    diffInMilliSeconds -= days * MagicNumber.EIGHTY_SIX_FR_TH;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / MagicNumber.THIRTY_SIX_TH) % MagicNumber.TWENTYFR;
    diffInMilliSeconds -= hours * MagicNumber.THIRTY_SIX_TH;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / MagicNumber.SIXTY) % MagicNumber.SIXTY;
    diffInMilliSeconds -= minutes * MagicNumber.SIXTY;
    if (bidsDate + MagicNumber.ONE === todayDate) {
      return `Yesterday, ${DisplayCurrentTime(date)}`;
    } else if (bidsDate + 1 < todayDate) {
      return bidsDates(date);
    } else if (hours) {
      return `${hours} hrs`;
    } else if (minutes) {
      return `${minutes} Mins`;
    } else {
      return `few second ago`;
    }
  };

  useEffect(() => {
    fetchData();
    const user_id = Cookies.get('user_id') || '0';
    setUserId(user_id);
  }, []);

  const gotoUserProfile = (id: number) => {
    history.push(`${PAGE_ROUTES.PROFILE}/${id}`);
  };

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        backdrop={false}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='view-bids-page view-responsive-bg modal-dialog-centered' id='detail-bids-page'
        contentClassName='view-bids-content'
      >
        <div className='view-bids-background'>
          <Modal.Header>
            <Modal.Title id='example-modal-sizes-title-sm'>
              <h1 className='title-text order-title'>
                {isLoading && <Skeleton height={MagicNumber.FIFTY} width={MagicNumber.TWO_FIFTY} />}
                {!isLoading && `${productBids && productBids.total} ${(productBids && productBids.total && productBids.total > 1) ? 'Bids' : 'Bid'}`}
              </h1>
            </Modal.Title>
            <div className='close-icon' onClick={() => openModal(false)}>
              <img src={MENU_ICON_CLOSE} className='close-image' alt='close' />
            </div>
          </Modal.Header>
          <Modal.Body>
            {isLoading ? <Skeleton height={MagicNumber.FIFTY} width={MagicNumber.FOUR_H_ONE} /> : productBids && productBids.total ? <div className='bids-body-inside'>
              {productBids && productBids.results && productBids.results.length && productBids.results.map((bids: any) => {
                return (<>
                  <div className='detail-container'>
                    <div className='wrap-detail'>
                      {parseInt(userId) === productUserId ? <div className='left-detail pointer-hand' onClick={() => gotoUserProfile(bids && bids.user_id)}>
                        <div className='image-box'>
                          <img src={bids && bids.users && bids.users.profile_image} alt='seller' className='seller-image' />
                        </div>
                      </div> : ''}
                      <div className='right-detail'>
                        {parseInt(userId) === productUserId ? <h2 className='title-text'>{bids && bids.users && bids.users.firstname} {bids && bids.users && bids.users.lastname}</h2> : ''}
                        <p className='bid-time'>{calculateDate(bids.createdAt)}</p>
                      </div>
                    </div>
                    <div className='box'>
                      {parseInt(userId) === productUserId ? <p className='bid-text'>Bid</p> : ''}
                      <h2 className='bid-price'>{bids.currency_amount}</h2>
                    </div>
                  </div>
                </>
                );
              })}
            </div> : 'Bids not found'}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
});

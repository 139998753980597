import { createCommentValidation, SEND_ICON } from '../../../../../constants';
import { Form, Formik } from 'formik';
import React from 'react';
import { FieldError } from '../../../Atoms';

type Props = {
  placeholder: string,
  onCommentCB: (comment: string) => Promise<boolean>
};

type FormValue = {
  comment: string
};

export const FeedItemCommentInput = (props: Props) => {

  const onSubmit = (values: FormValue, { resetForm }: any) => {
    props.onCommentCB(values.comment).then(successful => {
      if (successful)
        resetForm();
    });
  };

  const getInitialValues = (): FormValue => {
    return {
      comment: ''
    };
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={createCommentValidation}
    >
      {formProps => (
        <Form onSubmit={formProps.handleSubmit} className='comment-form'>
          <div className='enter-comment'>
            <div className='form-group'>
              <input name='comment' type='text' className='form-control' value={formProps.values.comment}
                placeholder={props.placeholder} onChange={formProps.handleChange} autoComplete='off' />
              <a type='button' className='btn primary' onClick={() => { formProps.handleSubmit(); }}>
                <img src={SEND_ICON} alt='send' className='send-icon' />
              </a>
            </div>
          </div>
        </Form>)}
    </Formik>

  );
};

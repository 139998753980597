import { useState, useEffect } from 'react';
import { Formik, Form, } from 'formik';
import { observer } from 'mobx-react';
import OtpInput from 'react-otp-input';
import { Modal, FieldError, successToast, Image } from '../../Atoms';
import { ResetPassModal } from '../resetPassModal/resetPassModal';
import { validateOtp, OTP_RESEND, OTP_VERIFIED, USER_VERIFIED, MagicNumber, RESEND_ICON, EMAIL_VERIFIED } from '../../../../constants';
import { useStores } from '../../../../models';


type IProps = {
    openModal: (e: boolean) => void;
    isOpen?: boolean;
    email: string;
    openSigninModal?: (e: boolean) => void;
    isRegister?: boolean;
    social_id?: string
    isEmailUpdate?: boolean;
};

export const OtpModal = observer(function Home({ openModal, isOpen, email, openSigninModal, isRegister, isEmailUpdate, social_id, }: IProps) {
    const rootStore = useStores();
    const { resendOtp, verifyOtp, forgotPassword } = rootStore.loginStore;
    const { getProfileDetail } = rootStore.profileStore;
    const [isPassOpen, openPassModal] = useState<boolean>(false);
    const [otp, setOtpValue] = useState<string | undefined>('');
    const [isDisabled, disableButton] = useState<boolean>(false);

    useEffect(() => {
        disableButton(false);
    }, []);

    const verifyEnteredOtp = async (values: any) => {
        setOtpValue(values.otp);
        let formdata;
        if (social_id)
            formdata = {
                email,
                otp: values.otp,
                social_id: social_id,
                'provider': 'apple',
                'is_email_editable': true
            };
        else formdata = {
            email,
            otp: values.otp
        };
        await verifyOtp(formdata, isRegister, isEmailUpdate).then((res) => {
            if (res === EMAIL_VERIFIED) {
                openModal(false);
                getProfileDetail();
            }
            if (res === OTP_VERIFIED) {
                openModal(false);
                openPassModal(true);
            }
            if (res === USER_VERIFIED)
                openModal(false);
        });
    };

    const handleResendOtp = () => {
        if (isRegister)
            resendOtp({ email });
        else if (isEmailUpdate) resendOtp({ email });
        else forgotPassword({ email });
        disableButton(true);
        successToast(OTP_RESEND);
        setTimeout(() => {
            disableButton(false);
        }, MagicNumber.FOUR_FIVE_TH);
    };

    const emptyState = () => {
        disableButton(false);
        openModal(false);
    };

    return (<>
        <Modal
            isOpen={isOpen}
            openModal={emptyState}
            header={`${isEmailUpdate ? 'Email' : 'Account'} Verification`}
            isSmall
            subHeading={`OTP has been sent successfully. Verify your email <a className='email-text'>${email}</a>${(isRegister || isEmailUpdate) ? '' : ' to set a new password'}.`} >
            <Formik
                initialValues={{ otp: '' }}
                onSubmit={async values => {
                    await verifyEnteredOtp(values);
                }}
                validationSchema={validateOtp}
                render={({ handleSubmit, errors, touched, values, setFieldValue, dirty, resetForm }) => (
                    <Form className='otp-section'>
                        <div className='form-group'>
                            <OtpInput
                                value={values.otp}
                                onChange={(otp: any) => setFieldValue('otp', otp)}
                                numInputs={6}
                                isInputNum
                                placeholder='-'
                                inputStyle='form-control'
                                containerStyle='otpContainer'
                            />
                            {errors.otp && touched.otp && (
                                <FieldError message={errors.otp} />
                            )}
                        </div>
                        <div className='resend-otp'>
                            <button className='btn primary' type='button' disabled={isDisabled} onClick={() => { resetForm(); handleResendOtp(); }}>
                                <Image src={RESEND_ICON} className='resend-icon' alt='resent-icon' /> Resend OTP</button>
                        </div>
                        <div className='submit-button'>
                            <button type='button' className='btn primary' disabled={!dirty ? true : false} onClick={(e: any) => handleSubmit(e)}>Continue</button>
                        </div>
                    </Form>
                )} />
        </Modal>
        <ResetPassModal openModal={openPassModal} isOpen={isPassOpen} otp={otp} email={email} openSigninModal={openSigninModal} />
    </>
    );
});

import { date } from 'yup/lib/locale';
import moment from 'moment';

export enum FunctionalityType {
  BuyProduct,
  AddToCart,
  AddToWishlist,
  NotifyMe,
  PlaceBid,
  DeleteComment,
  DeletePost,
  ReportPost,
  ReportComment,
  ShowOptionsOnAdminPost
}

export enum UserType {
  user,
  admin,
  community_manager
}

export const BANNER_RESTRICTIONS = {
  MAX_BANNERS_TO_SHOW: 4
};

export const UserTypeName = {
  user: 'User',
  admin: 'Admin',
  community_manager: 'Community Manager'
};

export const FEED_POST_RESTRICTIONS = {
  MAX_IMAGES: 5,
  MAX_VIDEOS: 1,
  MAX_VIDEO_DURATION: 3,
  MAX_LATEST_COMMENTS: 2,
  COPIED_LINK_MESSAGE: 'Copied link to clipboard.',
  POST_DOES_NOT_EXIST: 'Shared post has been deleted!'
};

export const VALIDATION_MESSAGES = {
  NAME: 'Name is required',
  ADDRESS: 'Address is required',
  CITY: 'City is required',
  STATE: 'State is required',
  ZIP_CODE: 'Zip code is required',
  MOBILE_NUMBER: 'Mobile Number is required',
  MIN_CHAR_LIMIT: 'Should have min.',
  MAX_CHAR_LIMIT: `Can't be more than`,
  ALPHABETS: 'Only alphabets are allowed',
  DIGITS: 'Only digits are allowed',
  CARD: 'Please enter valid card number.',
  EXPIRY_YEAR: 'Please select card expiry year.',
  EXPIRY_MONTH: 'Please select card expiry month.',
  EXPIRY_CVC: 'Please enter valid cvc number.',
  CARD_HOLDER: 'Please enter card holder name.',
  EXPIRY_CVCN: 'Card cvc number should not be less than 3 digit.',
  NO_CARD: 'No card saved yet!',
  EMPTY: '',
  ADD: 'add',
  CART: 'cart',
  USER_ID: 'user_id',
  USER_DATA: 'user_data',
  OUT_OF_STOCK: 'Added quantity is not available in stock. Please decrease the quantity or remove product.',
  POST_DESCRIPTION: 'Some text is required',
  POST_IMAGE: 'Some images are required',
  POST_IMAGE_MIN_LIMIT: `At least 1 image is required`,
  POST_IMAGE_MAX_LIMIT: `At max ${FEED_POST_RESTRICTIONS.MAX_IMAGES} images can be added`,
  POST_VIDEO: 'Video is required',
  POST_VIDEO_MAX_LIMIT: `At max ${FEED_POST_RESTRICTIONS.MAX_VIDEOS} video can be added`,
  POST_VIDEO_MAX_DURATION: `Video can only be of ${FEED_POST_RESTRICTIONS.MAX_VIDEO_DURATION} mins`,
  ADDED_WISHLIST: 'Product is added to Wishlist',
  REMOVED_WISHLIST: 'Product is removed from Wishlist',
  VERIFIED_WISHLIST: 'Product is already in Wishlist',
  SELECT_CHECKBOX: 'Please select minimum 1 product',
  COMMENT_REQUIRED: 'Some text is required',
  SHARE_POST_DESCRIPTION: 'Some text is required',
  REPORT_CONTENT_REASON_REQUIRED: 'Reason required',
  CURRENT_PASSWORD_REQUIRED: 'Current password is required',
  NEW_PASSWORD_REQUIRED: 'New password is required',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm new password',
  PASSWORDS_MUST_MATCH: 'Passwords must match'
};

export const STRINGS = {
  PRICE_HIGH_TO_LOW: 'Price - High to low',
  PRICE_LOW_TO_HIGH: 'Price - Low to high',
  RECENTLY_ADDED: 'Recently Added',
  CARD: 'card',
  MONTH: 'month',
  YEAR: 'year',
  NAME: 'name',
  IN_COLLECTERS: 'collecters',
  IN_COLLECTIBLES: 'collectibles'
};

export const HTTPSTATUSCODE = {
  UNAUTHORISED: 401,
  BAD_REQUEST: 400,
  SUCCESS_REQUEST: 200,
  CREATED: 201,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const SKELETON_COUNT = {
  PRODUCT: 9,
  PRODUCT_FIVE: 5,
  FILTER: 7,
  COLOR: 10,
};

export const stringToUpperCase = (data: string) => {
  return data[0].toUpperCase() + data.substring(1);
};

export const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const FILETYPE = {
  'VIDEO': 'video',
  'PDF': 'pdf',
  'MP3': 'mp3',
  'JPG': 'jpg',
  'PNG': 'png',
  'JPEG': 'jpeg',
};

export const monthList = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];
export const ITEM_MODIFICATION = {
  REMOVE: 'remove',
  ADD: 'add',
};

export const TOAST_TEXT = {
  QUANTITY_COUNT: 'This product have only',
  QUANTITY_AVAILABLE: 'quantity available',
};

export const PROFILE_EDIT_MOOD = {
  CHANGE_PROFILE: 'Change Profile Image',
  CHANGE_USER_DATA: 'Edit Profile',
};

export const PRODUCT_COMMENT_RESTRICTIONS = {
  COMMENT_LENGTH: 115,
  REPLY_LENGTH: 90
};

export const scrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'center',
  inline: 'nearest'
};

export const tabInitialName = {
  setting: 'shippingAddress'
};

export const accordianTab = {
  CREATER_MESSAGE: 'createrMessage',
  PRODUCT_STORY: 'productstory'
};

export const PRODUCT_ENTITY_TYPE = {
  PRODUCT: 'product',
  VARIANT: 'variant'
};

export const VARIANT_COMBINATION = {
  SIZE: 'Size',
  COLOR: 'Color'
};

export const contentToggleTitle = {
  more: 'Show More',
  less: 'Show Less'
};

export const ORDER_DETAIL_STATUS = {
  IN_COLLECTIONS: 'in_collection',
};

export const DIGITAL_CONTENT_TYPE = {
  DIGITAL_CODE: 'digitalCode',
  AUDIO: 'audio',
  VIDEO: 'video',
  DOCUMENT: 'document',
  IMAGE: 'image'
};

export const getOrderTime = (date: Date) => {
  const dateformat = require('dateformat');
  return dateformat(date, 'mmmm, dS yyyy');
};

export const getDetailsFormate = (date: string | null) => {
  if (!date) return '';
  let dates = new Date(date);
  return moment(dates).format('MMM, Do YYYY');
};

export const removeWhiteSpace = (data: any) => {
  let obj = { ...data };
  for (let key in obj) {
    if (typeof obj[key] == 'string') {
      obj[key] = obj[key].replace(/\s{2,}/g, ' ').trim();
    }
  }
  return obj;
};

export const getFeedTime = (date: Date): string => {

  const currentDate: Date = new Date();

  const diffTime = Math.abs(date.getTime() - currentDate.getTime());

  const dateformat = require('dateformat');

  const diffSeconds = Math.ceil(diffTime / 1000);
  const diffMinutes = Math.ceil(diffSeconds / 60);
  const diffHours = Math.ceil(diffMinutes / 60);
  const diffDays = Math.ceil(diffHours / 24);

  if (diffSeconds < 60) {
    return `just now`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes} ${diffMinutes > 1 ? 'mins' : 'min'} ago`;
  } else if (diffHours < 24) {
    return `${diffHours} ${diffHours > 1 ? 'hrs' : 'hr'} ago`;
  } else if (diffDays > 1 && diffDays <= 2) {
    return `Yesterday, ${dateformat(date, 'hh:MM tt')}`;
  } else {
    return dateformat(date, 'mmmm dd, yyyy, hh:MM tt');
  }
};

export const getFunctionalityBlockedMessage = (currentAccount: string, requiredAccount: string) => {
  return `${currentAccount} is not allowed to perform this feature. Please login with ${requiredAccount} account to access this functionality.`;
};

export const getSavedCardAddress = (savedCard: any) => {
  if (!savedCard || !savedCard.cardData) return null;

  const cardData = savedCard.cardData;

  return `${cardData.address_1}, ${cardData.address_2 ? `${cardData.address_2}, ` : ''}
    ${cardData.city}, ${cardData.state} - ${cardData.zip_code}, ${cardData.country}`;
};

export const KEYS = {
  KEY_UP: 38,
  KEY_DOWN: 40,
  ENTER: 13
};

import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Cookie from 'js-cookie';
import { toJS } from 'mobx';
import { PRODUCT_ENTITY_TYPE, USER_ID } from './../../../../constants';
import { useStores } from '../../../../models';

import './modal.scss';

type IProps = {
  openModal: any;
  isOpen: boolean;
  title: string;
  description: string;
  cartData: any;
};

export const MoveToWishlist = ({ openModal, isOpen, title, description, cartData}: IProps) => {
  const rootStore = useStores();
  const { addWishList, getWishList, wishlistData} = rootStore.wishlistStore;
  const { deleteCartItem, getCartItem, cartId } = rootStore.cartStore;
  const [loader, setLoader] = useState<boolean>(false);

  const addProductToWishlist = async () => {
    const wishlistFormdata = {
        product_id: cartData.productId,
        entity_type: cartData && cartData.variant_data && cartData.variant_data.length ? PRODUCT_ENTITY_TYPE.VARIANT : PRODUCT_ENTITY_TYPE.PRODUCT,
        sku: cartData.sku
    };
    setLoader(true);
    await deleteCartItem(cartId, cartData.id);
    await addWishList(wishlistFormdata);
    await getCartItem(Number(Cookie.get(USER_ID)));
    openModal();
    setLoader(false);
  };

  useEffect(()=> {
    getWishList();
  }, []);

  return (
    <>
      {loader ? <div className='loader-main'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'></span>
        </div>
      </div> : ''}
      <Modal
        size='sm'
        show={isOpen}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog modal-dialog-centered'
        contentClassName='remove-modal-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-blank' onClick={openModal}>Cancel</button>
          <button type='button' className='btn btn-blank remove' onClick={addProductToWishlist}>Move</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

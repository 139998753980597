import { MagicNumber, PRODUCT_PLACEHOLDER } from '../../../../../../constants';
import { SkeletonImage } from '../../../../Atoms/SkeletonImage';

type Iprops = {
  redirectToDetail: () => void;
  item: any;
  key: number;
};
export const MyCollectionItem = ({ redirectToDetail, item, key }: Iprops) => {
  return (
    <div className="profile-collection-item" key={key} onClick={redirectToDetail}>
      <SkeletonImage
        imageURL={item.product_image !== null ? item.product_image : item.medias[MagicNumber.ZERO].filePath}
        classes='image-product'
        alt='product'
        placeholder={PRODUCT_PLACEHOLDER}
        product_type={item.product_type}
        isPrivate={item.is_private}
        auctionStatus={item.auction_status}
        status={item.status}
      />
    </div>
  );
};


import { StreamClient } from 'getstream';
import { useEffect, useState } from 'react';
import { COMMENT_ICON, LIKE_ICON, LIKE_ICON_ACTIVE, SHARE_ICON } from '../../../../../constants';
import { useStores } from '../../../../../models';
import { errorToast, FullPageLoader } from '../../../Atoms';

export const FeedItemSocials = (props: {
  feedID: string,
  streamClient: StreamClient | undefined,
  likeCount: number,
  commentCount: number,
  shareCount: number,
  likeID: string,
  onViewFeedCB?: () => void
  onLikeUpdatedCB?: () => Promise<boolean>,
  onIllegalActionAsGuestCB?: () => void,
  onViewLikesCB?: () => void,
  onViewSharesCB?: () => void,
  onSharePostCB?: () => void
}) => {

  const rootStore = useStores();
  const { isLoggedIn } = rootStore.loginStore;
  const { addLike, removeReaction: removeLike, handleFeed } = rootStore.feedStreamStore;

  const [isPostLiked, setIsPostLiked] = useState<boolean>(props.likeID ? true : false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsPostLiked(props.likeID ? true : false);
  }, [props.likeID]);

  const onLikeIconClicked = async () => {
    if (!props.streamClient)
      return;

    setIsLoading(true);
    try {
      if (props.likeID) {
        setIsPostLiked(false);
        await removeLike(props.streamClient, props.likeID);
      }
      else {
        setIsPostLiked(true);
        await addLike(props.streamClient, props.feedID);
      }

      if (props.onLikeUpdatedCB)
        await props.onLikeUpdatedCB();
      handleFeed('like', props.feedID);
      setIsLoading(false);
    } catch (error) {
      setIsPostLiked(props.likeID ? true : false);
      errorToast(error);
      setIsLoading(false);
    }
  };

  return (
    <div className='social-share-section'>
      <div className='share-container'>
        <div className='social-box'>
          <img src={isPostLiked ? LIKE_ICON_ACTIVE : LIKE_ICON}
            className='media-icon' alt='like'
            onClick={() => {
              if (isLoading) return;

              if (!isLoggedIn) {
                props.onIllegalActionAsGuestCB?.();
                return;
              }
              onLikeIconClicked();
            }}
          />
          <div className='active-status' role='button'
            onClick={() => {
              if (isLoading) return;

              if (!isLoggedIn) {
                props.onIllegalActionAsGuestCB?.();
                return;
              }
              props.onViewLikesCB?.();
            }}>{props.likeCount}</div>
        </div>
        <div className='social-box'>
          <img src={COMMENT_ICON} className='media-icon' alt='comment'
            onClick={() => {
              props.onViewFeedCB?.();
            }} />
          <div className='active-status'
            role='button'
            onClick={() => {
              props.onViewFeedCB?.();
            }}>{props.commentCount}</div>
        </div>
      </div>
      <div className='share-container'>
        <img src={SHARE_ICON} className='media-icon' alt='share' role='button'
          onClick={() => {
            if (!isLoggedIn) {
              props.onIllegalActionAsGuestCB?.();
              return;
            }
            props.onSharePostCB?.();
          }} />
        <div className='active-status' role='button'
          onClick={() => {
            if (!isLoggedIn) {
              props.onIllegalActionAsGuestCB?.();
              return;
            }
            props.onViewSharesCB?.();
          }}>{props.shareCount}</div>
      </div>
    </div>
  );
};

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Avatar, ChatContext } from 'stream-chat-react';
import _debounce from 'lodash.debounce';
import { useHistory } from 'react-router-dom';
import { XButton, XButtonBackground } from '../../assets';
import {
  KEYS,
  USER_ID,
  TOKEN,
  REFRESH_TOKEN,
  PAGE_ROUTES,
} from '../../../../../../constants';
import Cookie from 'js-cookie';
import './CreateChannel.css';

const UserResult = ({ user }) => (
  <li className='messaging-create-channel__user-result'>
    <Avatar image={user.profileImage} size={40} />
    {user.online && (
      <div className='messaging-create-channel__user-result-online' />
    )}
    <div className='messaging-create-channel__user-result__details'>
      <span className='username'>
        {user.firstname} {user.lastname}
      </span>
      {user.connected ? (
        <span className='detail'>Follower/Following</span>
      ) : null}
    </div>
  </li>
);

const CreateChannel = ({ onClose, toggleMobile, id }) => {
  const { client, setActiveChannel } = useContext(ChatContext);
  const [focusedUser, setFocusedUser] = useState(undefined);
  const [inputText, setInputText] = useState('');
  const [resultsOpen, setResultsOpen] = useState(false);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const inputRef = useRef();
  const history = useHistory();
  const analytics = require('react-segment');
  const clearState = () => {
    setInputText('');
    setResultsOpen(false);
    setSearchEmpty(false);
  };

  useEffect(() => {
    chatInit();
  }, [id]);

  const chatInit = async () => {
    if (id) {
      const conversation = await client.channel('messaging', {
        members: [id, client.userID],
      });

      await conversation.watch();
      setActiveChannel(conversation);
      history.push(`${PAGE_ROUTES.CHAT}`);
      setSelectedUsers([]);
      setUsers([]);
      onClose();
    }
  };

  useEffect(() => {
    const clickListener = () => {
      if (resultsOpen) clearState();
    };
    document.addEventListener('click', clickListener);
    return () => document.removeEventListener('click', clickListener);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const findUsers = async () => {
    if (searching) return;
    setSearching(true);

    try {
      fetch(
        `${process.env.REACT_APP_SERVER_HOST}/api/v1/users/${Number(
          Cookie.get(USER_ID)
        )}/chat-list/${inputText}`,
        {
          headers: {
            'content-type': 'application/JSON',
            api_key: process.env.REACT_APP_SERVER_API_KEY,
            authorization: 'Bearer ' + Cookie.get(TOKEN),
            refresh: Cookie.get(REFRESH_TOKEN),
          },
          method: 'GET',
        }
      )
        .then((response) => response.json())
        .then((data) => {
          var response = data;
          if (!response.users.length) {
            setSearchEmpty(true);
          } else {
            setSearchEmpty(false);
            setUsers(response.users);
          }

          setResultsOpen(true);
        });
    } catch (error) {
      console.log({ error });
    }

    setSearching(false);
  };

  const findUsersDebounce = _debounce(findUsers, 100, {
    trailing: true,
  });

  useEffect(() => {
    if (inputText) {
      findUsersDebounce();
    }
  }, [inputText]); // eslint-disable-line react-hooks/exhaustive-deps

  const startChat = () => {
    analytics.default.track('Chats Started');
  };

  const createChannel = async () => {
    startChat();
    const selectedUsersIds = selectedUsers.map((u) => String(u.id));

    if (!selectedUsersIds.length) return;

    const conversation = await client.channel('messaging', {
      members: [...selectedUsersIds, client.userID],
    });

    await conversation.watch();

    setActiveChannel(conversation);
    setSelectedUsers([]);
    setUsers([]);
    onClose();
  };

  const addUser = (u) => {
    const isAlreadyAdded = selectedUsers.find((user) => user.id === u.id);
    if (isAlreadyAdded) return;

    setSelectedUsers([u]);
    setResultsOpen(false);
    setInputText('');
    inputRef.current.focus();
  };

  const removeUser = (user) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id);
    setSelectedUsers(newUsers);
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.which === KEYS.KEY_UP) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? users.length - 1 : prevFocused - 1;
        });
      }
      if (e.which === KEYS.KEY_DOWN) {
        setFocusedUser((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === users.length - 1 ? 0 : prevFocused + 1;
        });
      }
      if (e.which === KEYS.ENTER) {
        e.preventDefault();
        if (focusedUser !== undefined) {
          addUser(users[focusedUser]);
          return setFocusedUser(undefined);
        }
      }
    },
    [users, focusedUser] // eslint-disable-line
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return (
    <div className='messaging-create-channel'>
      <header>
        <div className='messaging-create-channel__left'>
          <div className='messaging-create-channel__left-text'>To: </div>
          <div className='users-input-container'>
            {!!selectedUsers?.length && (
              <div className='messaging-create-channel__users'>
                {selectedUsers.map((user) => (
                  <div
                    className='messaging-create-channel__user'
                    onClick={() => removeUser(user)}
                    key={user.id}
                  >
                    <div className='messaging-create-channel__user-text'>
                      {user.username}
                    </div>
                    <XButton />
                  </div>
                ))}
              </div>
            )}
            {selectedUsers?.length === 0 ? (
              <form onSubmit={addUser}>
                <input
                  autoFocus
                  ref={inputRef}
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  placeholder={
                    !selectedUsers.length ? 'Start typing username for suggestions' : ''
                  }
                  type='text'
                  className='messaging-create-channel__input'
                />
              </form>
            ) : null}
          </div>
          <div className='close-mobile-create' onClick={() => toggleMobile()}>
            <XButtonBackground />
          </div>
        </div>
        <button className='create-channel-button' onClick={createChannel}>
          Start chat
        </button>
      </header>
      {inputText && (
        <main>
          <ul className='messaging-create-channel__user-results'>
            {!!users?.length && !searchEmpty && (
              <div>
                {users.map((user, i) => (
                  <div
                    className={`messaging-create-channel__user-result ${focusedUser === i && 'focused'
                      }`}
                    onClick={() => addUser(user)}
                    key={user.id}
                  >
                    <UserResult user={user} />
                  </div>
                ))}
              </div>
            )}
            {searchEmpty && (
              <div
                onClick={() => {
                  inputRef.current.focus();
                  clearState();
                }}
                className='messaging-create-channel__user-result empty'
              >
                No people found...
              </div>
            )}
          </ul>
        </main>
      )}
    </div>
  );
};

export default React.memo(CreateChannel);

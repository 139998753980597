import { flow, types } from 'mobx-state-tree';
import { MagicNumber } from '../../constants';
import { withEnvironment } from '../extensions/with-environment';

export const SoldItemStoreModel = types
  .model('SoldItemStore')
  .props({
    inProcess: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions(self => ({
    getMoreSoldItems: flow(function* (
      pageNumber: number,
      pageLimit: number,
      userId?: string
    ) {
      try {
        if (self.inProcess)
          return;

        self.inProcess = true;
        let query = `page=${pageNumber}&limit=${pageLimit}&order_type=sold_items`;
        query = userId ? `${query}&user_id=${userId}` : query;

        const response = yield self.environment.api.get(`order?${query}`);
        self.inProcess = false;

        return response.data;
      } catch (error) {
        self.inProcess = false;
        throw error;
      }
    })
  }));


import React, { Component, ComponentClass, ComponentType } from "react";
import { Footer, Header, Banner } from '../Molecules';

function wrapperComponent(hidden?: boolean, isLoader?: boolean, hideFooter?: boolean, header?: boolean): <P extends object>(WrappedComponent: ComponentType<P>) => ComponentClass<P> {
  return <P extends object>(WrappedComponent: ComponentType<P>) =>
    class BadgedComponent extends Component<P> {
      public render() {
        return (
          <div className="home-main-container">
            {!header ? <Header /> : ''}
            {window.location.pathname.includes('/All') ? '' : !hidden ? <Banner isLoader={isLoader} /> : ''}
            <WrappedComponent {...this.props} />
            {!hideFooter ? <Footer /> : ''}
          </div>
        );
      }
    };
}

export default wrapperComponent;

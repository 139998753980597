import firebase from 'firebase/compat/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import 'firebase/firestore';
import Cookies from 'js-cookie';

var appConfig = {
    apiKey: 'AIzaSyC0x-wnYdu6Ie2Pn7KFWbzmOIcaU0QuSA4',
    authDomain: 'ood-marketplace.firebaseapp.com',
    projectId: 'ood-marketplace',
    storageBucket: 'ood-marketplace.appspot.com',
    messagingSenderId: '891490373527',
    appId: '1:891490373527:web:887f9f3639ad3fc42efed2',
    measurementId: 'G-MQW7MQFPGW'
};
// firebase.initializeApp(appConfig);
// export default firebase;
let messaging = null;

function detectBrowser() {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
        return true;
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
        return true;
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
        return false;
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
        return true;
    } else if ((navigator.userAgent.indexOf('MSIE') != -1) || (!!document.documentMode == true)) {
        return true;
    } else {
        return false;
    }
}

if (detectBrowser()) {
    firebase.initializeApp(appConfig)
    messaging = getMessaging();
} else {
    console.log('no-support :(');
}

async function sendFcmData(token) {
    if (!detectBrowser()) {
        return;
    }
    const userToken = Cookies.get('token');
    if (userToken) {
        const url = `https://api.dev.oodapp.com/api/v1/users/device-token`;
        const data = {
            'device_type': 'web',
            'device_name': 'chrome',
            'device_id': '891490373527',
            'token': token
        }
        const obj = JSON.stringify(data);
        const other_params = {
            headers: { Authorization: `Bearer ${userToken}`, 'Api-Key': 'WCPA32ACA2JK90E949A14DD5B8CBCAD942', 'content-type': 'application/json; charset=UTF-8' },
            body: obj,
            method: 'POST',
            mode: 'cors'
        };
        fetch(url, other_params)
            .then(function (response) {
                if (response.ok) {
                    console.log(response.json());
                } else {
                    throw new Error('Could not reach the API: ' + response.statusText);
                }
            }).then(function (data) {
                console.log('success');
            }).catch(function (error) {
                console.log('error in device token');
            });
        return false;
    } else {
        return true;
    }
}

export const getTokens = async () => {
    if (!detectBrowser()) {
        return;
    }
    return getToken(messaging, { vapidKey: 'BPp2OzfoMUutrvQQUsZj7FyzWhl4zDeii42CpqEgm2UpV0mvqCe4wihWVxTNqkMTTj_u159cnsxov1lX7y7vx44' }).then((currentToken) => {
        if (currentToken) {
            window.sessionStorage.setItem('fcmt', currentToken);
            sendFcmData(currentToken);
            // setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. testb ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = async () => {
    await onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
        return payload;
    });
}

if (detectBrowser()) {
    onMessage(messaging, (payload) => {
        console.log('Message received. ', payload);
    });
}

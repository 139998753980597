import Skeleton from 'react-loading-skeleton';
type Iprops = {
    skeletonBg: string;
  };
export const HomeSkeleton = ({ skeletonBg }: Iprops) => {
    return (
        <>
            <div className={`category-main-section category-Skeleton-section ${skeletonBg}`}>
                <div className='category-container'>
                    <div className='section-category carousel-section-category'>
                        <div className='home-skeleton'>
                            <div className='home-skeleton-product'>
                                <Skeleton count={1} />
                            </div>
                            <div className='home-skeleton-product product-sm'>
                                <Skeleton count={1} />
                                <div className='home-skeleton-product'>
                                    <Skeleton count={1} />
                                    <Skeleton count={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section-category'>
                        <div className='detail-box'>
                            <h3 className='title-text'><Skeleton count={1} /></h3>
                            <p className='title-detail'><Skeleton count={1} /></p>
                            <div className='view-button'>
                                <button type='button' className='btn'><Skeleton count={1} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


import { types } from 'mobx-state-tree';

export const FollowCountModal = types.model({
    followerCount: types.maybeNull(types.string),
    followingCount: types.maybeNull(types.string)
});

export const FollowingUserModal = types.model({
    firstname: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    profile_image: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    active: types.maybeNull(types.boolean),
    id: types.maybeNull(types.number),
    email: types.maybeNull(types.string),
    full_count: types.maybeNull(types.string),
});

import Skeleton from 'react-loading-skeleton';

export const CartShippingAddressSkeleton = () => {
  return (
    <div className="shipping-address-section">
        <div className="address-head address-head-skeleton">
            <div className="head-left">
              <Skeleton />
            </div>
            <div className="head-right">
              <Skeleton />
            </div>
          </div>
          <div className="address-card-container address-card-skeleton">
            <div className="card-space">
              <div className="address-card">
                <div className="address-card-head">
                  <Skeleton count={2} />
                </div>
                <div className="address-card-body">
                  <Skeleton count={2} />
                </div>
                <div className="address-card-action">
                  <Skeleton count={1} />
                </div>
              </div>
              <div className="address-card">
                <div className="address-card-head">
                  <Skeleton count={2} />
                </div>
                <div className="address-card-body">
                  <Skeleton count={2} />
                </div>
                <div className="address-card-action">
                  <Skeleton count={1} />
                </div>
              </div>
              <div className="address-card">
                <div className="address-card-head">
                  <Skeleton count={2} />
                </div>
                <div className="address-card-body">
                  <Skeleton count={2} />
                </div>
                <div className="address-card-action">
                  <Skeleton count={1} />
                </div>
              </div>
            </div>
        </div>
    </div>
  )
};

import React, { useEffect } from 'react';
import { PROFILE_ICON, VIDEO_ICON_WHITE } from '../../../../constants';


type IProps = {
    title: string;
    data?: any;
    target?: string,
    show?: string;
    isCollapse?: boolean;
};

export const AccordionSellerCard = ({ title, data, show, target, isCollapse }: IProps) => {

    useEffect(() => {
    }, []);

    return (
        <div className='product-accordion'>
            <div className='accordion-section'>
                <div className='accordion'>
                    <div id='accordion'>
                        <div className='card'>
                            <div className='card-header' id='headingproduct'>
                                <h5 className='mb-0'>
                                    <button className={`btn ${show ? '' : 'collapsed'}`}
                                        data-toggle='collapse'
                                        data-target={`#${target}`}
                                        aria-expanded='true' aria-controls={target}>
                                        {title}
                                    </button>
                                </h5>
                            </div>
                            <div id={target} className={`collapse ${show}`} aria-labelledby='headingproduct' data-parent='#accordion'>
                                <div className='card-body'>
                                    <div className='view-more-section'>
                                        {data && data.length && data.map((item: any) => {
                                            return (
                                                <>
                                                    <div className='detail-container'>
                                                        <div className='left-detail'>
                                                            <div className='image-box'>
                                                                <img src={item && item.sellerInfo && item.sellerInfo.profile_image || PROFILE_ICON} alt='sellers' className='seller-image'
                                                                    onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='right-detail'>
                                                            <h2 className='title-text'>{item && item.sellerInfo && item.sellerInfo.firstname} {item && item.sellerInfo && item.sellerInfo.lastname}</h2>
                                                            <p className='detail-text'>{item && item.description}</p>
                                                        </div>
                                                    </div>
                                                    {item && item.files && item.files.length ? <div className='digital-content-section'>
                                                        {item.files.map((fileData: any) => {
                                                            return (
                                                                <div className='content-box'>
                                                                    <img src={(fileData && fileData.file_type === 'image') ? fileData.filepath : fileData.filepathThumb} alt='seller' className='content-image' />
                                                                    {fileData && fileData.file_type !== 'image' ? <div className='lock-icon'>
                                                                        <img src={VIDEO_ICON_WHITE} alt='seller' className='lock-image' />
                                                                    </div> : ''}
                                                                </div>
                                                            );
                                                        })}
                                                    </div> : ''}
                                                </>);
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        </div >
    );
};

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Avatar, ChannelContext } from 'stream-chat-react';
import Cookies from 'js-cookie';
import { DeleteConversation } from './../../../../Molecules';
import { getCleanImage, HamburgerIcon } from '../../assets';
import { MagicNumber } from '../../../../../../constants/MagicNumber';
import { ConfirmationInfos } from "../../../../../../constants/ConfirmationInfos";
import { DOT_DROP_ICON, USER_ID, reportContentValidation, PAGE_ROUTES } from '../../../../../../constants';
import { TextConfirmationBox } from "./../../../../Molecules/confirmationBoxModal/TextConfirmationBox";
import { successToast, errorToast } from './../../../../Atoms';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../../models';
import { useHistory } from "react-router-dom";
import './MessagingChannelHeader.css';


const getAvatarGroup = (members) => {

  if (members.length === MagicNumber.ONE) {
    return (
      <div className='messaging__channel-header__avatars'>
        <Avatar image={getCleanImage(members[MagicNumber.ZERO])} size={40} />;
      </div>
    );
  }

  if (members.length === 2) {
    return (
      <div className='messaging__channel-header__avatars two'>
        <span>
          <Avatar image={getCleanImage(members[0])} shape='square' size={40} />
        </span>
        <span>
          <Avatar image={getCleanImage(members[1])} shape='square' size={40} />
        </span>
      </div>
    );
  }

  if (members.length === 3) {
    return (
      <div className='messaging__channel-header__avatars three'>
        <span>
          <Avatar image={getCleanImage(members[0])} shape='square' size={40} />
        </span>
        <span>
          <Avatar image={getCleanImage(members[1])} shape='square' size={20} />
          <Avatar image={getCleanImage(members[2])} shape='square' size={20} />
        </span>
      </div>
    );
  }

  if (members.length >= 4) {
    return (
      <div className='messaging__channel-header__avatars four'>

        <span>
          <Avatar image={getCleanImage(members[members.length - 3])} shape='square' size={20} />
          <Avatar image={getCleanImage(members[members.length - 4])} shape='square' size={20} />
        </span>
      </div>
    );
  }

  return null;
};

const MessagingChannelHeader = observer(function MessagingChannelHeader(props) {
  const rootStore = useStores();
  const {
    reportChat
  } = rootStore.homeStore;
  const { channel, client } = useContext(ChannelContext);
  const [channelName, setChannelName] = useState(channel?.data.name || '');
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');
  const [openDeleteConversationModal, setDeleteConversationModal] = useState(false);
  const [isShowingReportConfirmation, setShowingReportConfirmation] = useState(false);
  const [currentConfirmationInfo, setCurrentConfirmationInfo] = useState({});
  const [reporteeUserId, setReporteeUserId] = useState(MagicNumber.ZERO);

  const inputRef = useRef();
  const history = useHistory();

  const members = Object.values(channel.state?.members || {}).filter(
    (member) => member.user?.id !== client?.user?.id,
  );

  const updateChannel = async (e) => {
    if (e) e.preventDefault();

    if (channelName && channelName !== channel.data.name) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` },
      );
    }

    setIsEditing(false);
  };

  const deleteConversation = async () => {
    await channel.hide(null, true);
    reload();
  }

  const reload = () => {
    window.location.reload();
  }

  const openModal = () => {
    setDeleteConversationModal(true);
  }

  const closeModal = () => {
    setDeleteConversationModal(false);
  }

  const onReportChat = async (reason) => {
    await reportChat(reporteeUserId, reason)
      .then(response => {
        successToast(response);
      })
      .catch(error => {
        errorToast(error);
        throw error;
      })
  }

  const routeToProfile = () => {
    history.push(`${PAGE_ROUTES.PROFILE}/${members[MagicNumber.ZERO].user_id}#${'tab'}=${'myCollection'}`);
  }

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (!channelName) {
      setTitle(
        members.map((member) => member.user?.name || member.user?.id || 'Unnamed User').join(', '),
      );
    }
  }, [channelName, members]);

  const EditHeader = () => (
    <form
      style={{ flex: 1 }}
      onSubmit={(e) => {
        e.preventDefault();
        inputRef.current.blur();
      }}
    >
      <input
        autoFocus
        className='channel-header__edit-input'
        onBlur={updateChannel}
        onChange={(e) => setChannelName(e.target.value)}
        placeholder='Type a new name for the chat'
        ref={inputRef}
        value={channelName}
      />
    </form>
  );

  return (

    <div className='messaging__channel-header'>
      {openDeleteConversationModal && (
        <DeleteConversation
          closeModal={() => { closeModal(); }}
          deleteConversation={() => { deleteConversation(); }}
          title="Delete Conversation"
          description="Are you sure you want to delete this conversation?"
          isOpen={openDeleteConversationModal}
        />
      )}
      {isShowingReportConfirmation && (
        <TextConfirmationBox
          isShowing={isShowingReportConfirmation}
          title={currentConfirmationInfo.title}
          cancelOptionName={currentConfirmationInfo.cancelOptionName}
          confirmOptionName={currentConfirmationInfo.confirmOptionName}
          validationSchema={reportContentValidation}
          onConfirmCB={async (text, confirmed) => {
            if (confirmed) {

              let errorOccured = false;
              await onReportChat(text).catch(error => { errorOccured = true });

              if (!errorOccured) {
                setShowingReportConfirmation(false);
              }

              return !errorOccured;
            }
            else {
              setShowingReportConfirmation(false);
              return true;
            }
          }}
        />
      )}
      <div id='mobile-nav-icon' className={`${props.theme}`} onClick={() => props.toggleMobile()}>
        <HamburgerIcon />
      </div>
      <div onClick={routeToProfile} className='cursor'>
        {getAvatarGroup(members)}
      </div>
      {!isEditing ? (
        <div className='channel-header__name'>{channelName || title}</div>
      ) : (
          <EditHeader />
        )}
      <div className="delete-dropdown">
        {channel.state.messages.length > MagicNumber.ZERO ?
          <div className="dropdown-button">
            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src={DOT_DROP_ICON} className="dropdown-icon" alt="icon" />
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a className="dropdown-item" onClick={() => openModal()}>Delete Conversation </a>
              <a className="dropdown-item" onClick={() => {
                setReporteeUserId(Cookies.get(USER_ID)
                  ?? MagicNumber.ZERO);
                setCurrentConfirmationInfo(ConfirmationInfos.REPORT_CHAT);
                setShowingReportConfirmation(true);
              }}>Report</a>
            </div>
          </div>
          : null
        }
      </div>
    </div>
  );
});

export default React.memo(MessagingChannelHeader);

import Skeleton from 'react-loading-skeleton';

export const ReactionUserSkeleton = () => {
  return (
    <div className='user-box'>
      <div className='user-image'>
        <Skeleton className='user-icon' circle={true} />
      </div>
      <div className='contact-detail'>
        <Skeleton className='name' />
        <Skeleton className='contact-id' />
      </div>
    </div>
  );
};

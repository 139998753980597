import { ApisauceInstance, create, ApiResponse } from 'apisauce';
import { getGeneralApiProblem, GeneralApiProblem } from './api-problem';
import Cookie from 'js-cookie';
import { TOKEN, REFRESH_TOKEN, HTTPSTATUSCODE, MagicNumber, USER_DATA } from '../../constants';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import * as Types from './api.types';
import { errorToast } from '../../components/ui/Atoms';
import jwt_decode from 'jwt-decode';

export interface SPError {
  code: number
  message: string
  payload?: any
}

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce!: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }
  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance    
    if (Cookie.get(TOKEN)) {
      this.apisauce = create({
        baseURL: this.config.url,
        timeout: this.config.timeout,
        headers: {
          Accept: 'application/json',
          authorization: 'Bearer ' + Cookie.get(TOKEN),
          api_key: process.env.REACT_APP_SERVER_API_KEY,
          refresh: Cookie.get(REFRESH_TOKEN)
        },
      });
    } else this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
        api_key: process.env.REACT_APP_SERVER_API_KEY,
      },
    });
  }

  async setHeaders(token: string) {
    this.apisauce.setHeaders({
      Authorization: `Bearer ${token}`,
    });
  }

  setHeader(key: string, value: string) {
    this.apisauce.setHeader(key, value);
  }

  isResponseError(response: ApiResponse<any>): SPError | null {
    if (response.data && response.data.statusCode) {
      const { statusCode, message, error } = response.data;
      if (statusCode !== HTTPSTATUSCODE.SUCCESS_REQUEST && statusCode !== HTTPSTATUSCODE.CREATED) {
        return {
          code: statusCode,
          message: message || error || 'Something went Wrong',
        };
      }
    }
    return null;
  }

  async checkResponseData(response: ApiResponse<any>): Promise<boolean> {
    const error = this.isResponseError(response);
    if (error) {
      throw error;
    }
    return true;
  }


  async isTokenExpired(token: any) {
    var decoded: any = await jwt_decode(token);
    var a = decoded.exp * MagicNumber.ONE_TH;
    if (Date.now() > a) {
      return true;
    } else {
      return false;
    }
  }

  async updateWithNewToken(rtoken: any) {
      let token = await Cookie.get(TOKEN);
    if (token != '' && token != undefined && token != null) {
      if (await this.isTokenExpired(token)) {
        await this.apisauce.setHeaders({});
        return await fetch(`${this.config.url}users/refresh`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'api_key': `${this.config.api_key}`,
              'refresh': rtoken
            },
          })
          .then(response => response.json())
          .then(async data => {
            if (data.token != undefined && data.token != null && data.token != '') {
              Cookie.set(TOKEN, data.token);
              Cookie.set(USER_DATA, data.user);
              this.setHeaders(data.token);
              return true;
            } else {
              Cookie.remove(TOKEN);
              Cookie.remove(USER_DATA);
              setTimeout(() => {
                // Most recent value
                errorToast('Login session expired');
          
              }, 2000);
              window.location.href = '/';
            }
          }).catch((error) => {
            Cookie.remove(TOKEN);
            Cookie.remove(USER_DATA);
            setTimeout(() => {
              // Most recent value
              errorToast('Login session expired');
        
            }, MagicNumber.TWO_TH);
            window.location.href = '/';
          });
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  async get(endPoint: string): Promise<Types.GetResult> {   
    let rtoken = await Cookie.get(REFRESH_TOKEN);
    // const response: ApiResponse<any> = await this.apisauce.get(endPoint);
    // if (!response.ok) {
    //   throw {
    //     code: response.status,
    //     message: 'Something went Wrong',
    //   }
    // }
    if (await this.updateWithNewToken(rtoken)) {
      const response: ApiResponse<any> = await this.apisauce.get(endPoint);
      const isProblem: GeneralApiProblem | void = getGeneralApiProblem(response);
      if (isProblem) {
        return isProblem;
        throw { code: HTTPSTATUSCODE.INTERNAL_SERVER_ERROR, message: 'Internal server error' ? 'Internal server error' : 'Something went wrong',response };
      }
      const result = await this.checkResponseData(response);
      if (result) {
        return response;
      }
      return null;
    } else {
      return null;
    }
  }

  async post(endPoint: string, data: any): Promise<any> {
    let rtoken = await Cookie.get(REFRESH_TOKEN);
    const headers = {
      'x-gigawatts': '1.21'
    };
    if (await this.updateWithNewToken(rtoken)) {
      const response: ApiResponse<any> = await this.apisauce.post(endPoint, data, { headers });
      const getRole = Cookie.get('role');
      if (getRole !== 'user') {
        if(response.data.statusCode == 401 && response.data.message== 'Unauthorized')
        {}
        else{errorToast(response.data.message);}
      }
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: 'ok', response: response };
    }
  }


  async delete(endPoint: string, data?: any): Promise<any> {
    let rtoken = await Cookie.get(REFRESH_TOKEN);
    const headers = {
      'x-gigawatts': '1.21'
    };
    if (await this.updateWithNewToken(rtoken)) {
      const response: ApiResponse<any> = await this.apisauce.delete(endPoint, {}, { headers, data });
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: 'ok', response: response };
    }
  }
  async put(endPoint: string, data?: any): Promise<any> {
    let rtoken = await Cookie.get(REFRESH_TOKEN);
    const headers = {
      'x-gigawatts': '1.21'
    };
    if (await this.updateWithNewToken(rtoken)) {
      const response: ApiResponse<any> = await this.apisauce.put(endPoint, data, { headers });
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: 'ok', response: response };
    }
  }
}

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MagicNumber, PAGE_ROUTES, ORDER_DETAIL_STATUS, USER_ID } from '../../../../constants';
import { SearchParameters } from '../../../../data/SearchParameters';
import { useStores } from '../../../../models';
import { OrderDetailType, OtherItemEntity } from './OrderDetailType';
import { ParameterType as ProfileTabParameterType, TabType as ProfileTabType } from '../profile/profileTabs/ProfileTabs';
import wrapperComponent from '../../Templates/wrapperComponent';
import { errorToast } from '../../Atoms';
import { PRODUCT_PLACEHOLDER } from '../../../../constants/image';
import { BLACK_ICON } from '../../../../constants/image';
import Cookie from 'js-cookie';

import './order-detail.scss';
import {
  RenderOrderDetail, RenderOtherItems, RenderPriceSection, RenderItemDetail,
  RenderShipmentTrack, RenderShippingAddress, RenderBuyerDetails
} from './OrderDetailComponents';
import { OrderDetailSkeleton } from './skeletons/OrderDetailSkeleton';
import { OrderDetailDigitalContent } from './orderDetailDigitalContent/OrderDetailDigitalContent';

export enum ParameterType {
  orderId,
  itemId,
  collectionId,
  status
}

const OrderDetail = observer(() => {

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const setURLParameters = (params: URLSearchParams) => {
    if (!params) return;

    searchParameters.addParamater(ParameterType[ParameterType.orderId],
      params.get(ParameterType[ParameterType.orderId]) ?? undefined);

    searchParameters.addParamater(ParameterType[ParameterType.itemId],
      params.get(ParameterType[ParameterType.itemId]) ?? undefined);

    searchParameters.addParamater(ParameterType[ParameterType.collectionId],
      params.get(ParameterType[ParameterType.collectionId]) ?? undefined);
    searchParameters.addParamater(ParameterType[ParameterType.status],
      params.get(ParameterType[ParameterType.status]) ?? undefined);
  };

  const searchParameters = new SearchParameters();
  const pageQuery = useQuery();

  setURLParameters(pageQuery);

  const rootStore = useStores();
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;
  const { inProcess, getOrderDetail, getOrderDetailScanCodeProduct } = rootStore.pendingPurchaseStore;

  const [orderId, setOrderId] = useState<number>(
    searchParameters.getParameterValue(ParameterType[ParameterType.orderId]) ??
    MagicNumber.ZERO);

  const [itemId, setItemId] = useState<number>(
    searchParameters.getParameterValue(ParameterType[ParameterType.itemId]) ??
    MagicNumber.ZERO);

  const [collectionId] = useState<number>(
    parseInt(searchParameters.getParameterValue(ParameterType[ParameterType.collectionId]) ?? MagicNumber.ZERO));

  const [orderDetail, setOrderDetail] = useState<OrderDetailType>();
  const [status, setProductStatus] = useState<string>(searchParameters.getParameterValue(ParameterType[ParameterType.status]) ??
    MagicNumber.ZERO);
  const [orderItem, setOrderItem] = useState<OtherItemEntity | null>(null);
  const [orderAddress, setOrderAddress] = useState<any>([]);

  const retrieveOrderDetailsScanCodeProduct = async () => {
    getOrderDetailScanCodeProduct(orderId)
      .then(response => {
        const orderDetail = response;
        setOrderAddress(orderDetail && orderDetail.actual_address);
        setOrderDetail(orderDetail);
        setOrderItem(orderDetail);
      }).catch(error => {
        errorToast(error);
      });
  };

  const retrieveOrderDetails = async () => {
    getOrderDetail(orderId, itemId, collectionId !== MagicNumber.ZERO ? collectionId : undefined)
      .then(response => {
        const orderDetail = response as OrderDetailType;
        const productOrder = orderDetail.default_item;
        setOrderAddress(orderDetail && orderDetail.actual_address);
        setOrderDetail(orderDetail);
        setOrderItem(productOrder);
      }).catch(error => {
        errorToast(error);
      });
  };

  useEffect(() => {
    setURLParameters(pageQuery);
    setItemId(
      searchParameters.getParameterValue(ParameterType[ParameterType.itemId]) ??
      MagicNumber.ZERO);
    setOrderId(
      searchParameters.getParameterValue(ParameterType[ParameterType.orderId]) ??
      MagicNumber.ZERO);
    setProductStatus(searchParameters.getParameterValue(ParameterType[ParameterType.status]) ??
      MagicNumber.ZERO);
    if (itemId != 0 || (itemId == 0 && orderId == 0 && collectionId)) {
      retrieveOrderDetails();
    } else {
      retrieveOrderDetailsScanCodeProduct();
    }
  }, [orderId, itemId, location]);

  const handleApiCallOnHide = () => {
    if (itemId != 0 || (itemId == 0 && orderId == 0 && collectionId)) {
      retrieveOrderDetails();
    } else {
      retrieveOrderDetailsScanCodeProduct();
    }
  };

  const getOrderAddress = (orderDetail: OrderDetailType | undefined) => {
    if (!orderDetail || !orderDetail.actual_address)
      return '';

    const address = orderDetail.actual_address;
    return `${address.address_1}${address.address_2 ? `, ${address.address_2}` : ''}, 
    ${address.city}-${address.zip_code}, ${address.country}`;
  };

  const itemHasAdminDiscount = (orderItem: OtherItemEntity): boolean => {
    return orderItem.discount_percentage > MagicNumber.ZERO;
  };

  const getItemAdminDiscountPercent = (orderItem: OtherItemEntity): number => {
    if (!itemHasAdminDiscount(orderItem))
      return MagicNumber.ZERO;

    return orderItem.discount_percentage;
  };

  const getItemOriginalPriceString = (orderItem: OtherItemEntity): string => {
    if (!itemHasAdminDiscount(orderItem))
      return '';

    const totalPriceFormatted = (Math.round(orderItem.total_base_price * 100) / 100).toFixed(MagicNumber.TWO);
    return `$${totalPriceFormatted}`;
  };

  const getItemAdminDiscountPercentFormatted = (orderItem: OtherItemEntity): string => {
    if (!itemHasAdminDiscount(orderItem))
      return '';

    const discount = getItemAdminDiscountPercent(orderItem);
    return (Math.round(discount * 100) / 100).toFixed(MagicNumber.TWO);
  };

  const getItemAdminDiscountPercentString = (orderItem: OtherItemEntity) => {
    return itemHasAdminDiscount(orderItem) ? `${getItemAdminDiscountPercentFormatted(orderItem)}% off` : '';
  };

  const goToUserProfile = (userId: number) => {
    const userID = Cookie.get(USER_ID);
    history.push(`${PAGE_ROUTES.PROFILE}${userId.toString() !== userID ? `/${userId}` : ''}`);
  };

  const areItemsLocked = () => {
    if (orderItem && orderItem.digitalContentAccess && orderItem.status === 'shipped') {
      return true;
    }
    if (!orderItem || !orderDetail) {
      return true;
    }
    const userId = Cookie.get(USER_ID);
    if (!userId) {
      return false;
    }
    if (orderItem && orderItem.digitalContentAccess && orderItem.status !== 'pending') {
      return false;
    }
    return !(status === ORDER_DETAIL_STATUS.IN_COLLECTIONS &&
      (orderDetail && orderDetail.user_id === parseInt(userId)));
  };

  const onProductClicked = (productId: number) => {
    searchParameters.addParamater(ParameterType[ParameterType.itemId], productId);
    history.push(pathName + searchParameters.getParametersString());
  };

  const onBackPressed = () => {
    history.goBack();
  };

  return (
    <div className='main-container order-detail-main'>
      <div className='filled-cart' >
        {orderItem && orderItem.status === ORDER_DETAIL_STATUS.IN_COLLECTIONS ? (
          <span onClick={() => { history.goBack(); }} className='back-btn'>
            <div className='arrow'>
              <img src={BLACK_ICON} alt='back' className='back-icon' />Back
            </div>
          </span>
        ) : (
          <a className='back-btn' onClick={onBackPressed}>
            <div className='arrow'>
              <img src={BLACK_ICON} alt='back' className='back-icon' />Back
            </div>
          </a>
        )}
        <div className='cart-header'>
          <h3>Order Details</h3>
        </div>
        {inProcess ? <OrderDetailSkeleton /> : null}
        <div className='cart-outer' hidden={inProcess}>
          <div className='cart-items'>
            <div className='item'>
              {itemId != 0 ? <RenderOrderDetail
                orderNumber={orderDetail ? orderDetail.order_no : ''}
                orderCreationTime={orderDetail ? orderDetail.createdAt : ''}
                itemId={orderItem ? orderItem.id : MagicNumber.ZERO}
              /> : ''}
              <RenderItemDetail
                itemId={orderItem ? orderItem.id : MagicNumber.ZERO}
                itemName={orderItem ? orderItem.name : ''}
                itemDiscount={orderItem ? getItemAdminDiscountPercentString(orderItem) : ''}
                itemDiscountedPrice={orderItem ? orderItem.currency_row_total : ''}
                itemOriginalPrice={orderItem ? getItemOriginalPriceString(orderItem) : ''}
                itemImage={orderItem ? orderItem.product_image ?? PRODUCT_PLACEHOLDER : PRODUCT_PLACEHOLDER}
                itemImageScan={(orderItem && orderItem.medias && orderItem.medias.length) ? orderItem.medias[0].filepath : PRODUCT_PLACEHOLDER}
                itemVariants={orderItem ?
                  orderItem.order_Items_Options ?
                    [orderItem.current_condition, ...orderItem.order_Items_Options.map((option: any) => { return option.code as string; })]
                    : [orderItem.current_condition]
                  : []}
                itemCount={orderItem ? parseInt(orderItem.qty || orderItem.quantity) : MagicNumber.ZERO}
                orderType={orderItem && orderItem.status}
                itemIdScan={itemId}
              />
            </div>
            {orderItem
              && orderItem.digitalContent ? (
              <OrderDetailDigitalContent
                data={orderItem.digitalContent}
                itemsLocked={areItemsLocked()}
              />
            ) : null}
            {orderItem && orderItem.buyer_info && orderAddress ?
              <RenderBuyerDetails
                buyerImage={orderItem.buyer_info.profile_image}
                buyerUserID={orderItem.buyer_info.id}
                buyerFullName={`${orderItem.buyer_info.firstname} ${orderItem.buyer_info.lastname} `}
                buyerUserName={orderItem.buyer_info.username}
                buyerAddress={orderAddress}
                onBuyerClick={goToUserProfile}
                itemId={orderItem ? orderItem.id : MagicNumber.ZERO}
              /> : itemId != 0 ?
                <RenderShippingAddress
                  placerName={orderDetail ? `${orderDetail.userInfo.firstname} ${orderDetail.userInfo.lastname}` : ''}
                  placerAddress={getOrderAddress(orderDetail)}
                  itemId={orderItem ? orderItem.id : MagicNumber.ZERO}
                /> : ''
            }
            {orderItem && orderItem.status !== ORDER_DETAIL_STATUS.IN_COLLECTIONS && itemId != 0 ? (
              <>
                {!orderItem.buyer_info && status !== 'in_collection' ? <RenderShipmentTrack orderDetails={orderItem} /> : null}
                {orderDetail && orderDetail.other_item ?
                  <RenderOtherItems
                    otherItems={orderDetail.other_item.filter(item => { return item.id !== (itemId * MagicNumber.ONE); })}
                    getItemDiscountPercent={getItemAdminDiscountPercentString}
                    getItemOriginalPrice={getItemOriginalPriceString}
                    onClickCB={onProductClicked}
                  /> : null
                }
              </>
            ) : null}
          </div>
          <RenderPriceSection
            deliveryCharges='$0.00'
            itemCount={orderDetail ? parseInt(orderDetail.item_count) : MagicNumber.ZERO}
            totalPriceBeforeDiscount={orderDetail ? orderDetail.curency_subtotal_with_discount : '$0.00'}
            discount={orderDetail ? `-${orderDetail?.currency_discount}` : ''}
            voucherCode={orderDetail && orderDetail.coupon_code ? orderDetail.coupon_code : ''}
            totalPriceAfterDiscount={orderDetail ? orderDetail.curency_grand_total : '$0.00'}
            orderType={orderItem && orderItem.status}
            enableTracking={orderItem && !orderItem.buyer_info}
            enableSellingAndAuction={orderItem && !orderItem.buyer_info}
            enableScanning={orderItem && !orderItem.buyer_info}
            itemId={orderItem ? orderItem.id : MagicNumber.ZERO}
            orderId={orderItem ? orderItem.order_id : MagicNumber.ZERO}
            qty={orderItem ? orderItem.qty : MagicNumber.ZERO}
            status={orderItem && orderItem.status}
            orderStatus={status}
            closeModal={handleApiCallOnHide}
            seller_id={orderItem && orderItem.seller_id ? orderItem.seller_id : 0}
            total_tax={orderDetail ? orderDetail.curency_tax_total : '$0.00'}
          />
        </div>
      </div>
    </div>
  );
});

export default wrapperComponent(true, true, false)(OrderDetail);


import React, { useEffect, useState } from 'react';
import { useStores } from '../../../../../models';

import {
  MagicNumber
} from '../../../../../constants';
import { Image } from '../../../Atoms';
import { Link } from 'react-router-dom';
import { CollectionAndCollectorsHome } from './CollectionAndCollectorsHome';

export const Collections = () => {
  const rootStore = useStores();
  const [homeCollection, setHomeCollection] = useState<any>([])
  const [loader, setLoader] = useState<boolean>(false)
  const { getCollectionList, homeCollectionData, isLoading } = rootStore.collectionStore;
  const { ZERO, FIVE } = MagicNumber;
  const fetchCollectionList = async () => {
    setLoader(true);
    await getCollectionList();
    filterCollectionData();
    setLoader(false);
  }
  const filterCollectionData = () => {
    setHomeCollection(homeCollectionData.slice(ZERO, FIVE))
  }

  useEffect(() => {
    fetchCollectionList();
  }, []);

  const getCollectionProductsPath = (collectionId: number) => {
    return `all?collection_id=${collectionId}`;
  }

  return (
    (loader || isLoading) ? (
      <div className="collection-section">
        <CollectionAndCollectorsHome />
      </div>
    ) : (
      <div className="collection-section">
        <h2 className="head-text">Featured Brands</h2>
        {homeCollection.length ? (
          <>
            <div className="collection-listing-section">
              <div className="listing-row">
                {homeCollection.map((item: any, key: any) => {
                  return (
                    <Link to={getCollectionProductsPath(item.id)} className="collection-detail">
                      <div className="logo-box">
                        <Image src={item.logo} className="logo-image" alt="logo" />
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          </>
        ) : (<h6>No Brands added!</h6>)}
      </div>
    )
  )
};


import React from 'react'

type MyProps = {
  message: string;
};
type MyState = {
  count: number;
};
export class SignIn extends React.Component<MyProps, MyState> {
  state: MyState = {
    // optional second annotation for better type inference
    count: 0
  };
  render() {
    return (
      <div>
        {this.props.message} {this.state.count}
      </div>
    );
  }
}

import './deleteUser.scss';
import { Formik, Form } from 'formik';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';
import { MagicNumber } from '../../../../constants';
import { successToast, errorToast } from '../../Atoms/Toast/Toast';
import { useStores } from '../../../../models';
import {  validateDeleteForm } from '../../../../constants/validationScheme';
import { useState } from 'react';
import {  FieldError, Input, Image } from '../../Atoms';
import {
  handleEnter,
  PASS_ICON_delete,
  PASSWORD_SHOW_delete,delete_icon,
  deleteUserFormValidation
} from '../../../../constants';
declare var $: any;

type FormValue = {
  delete_password: string
};

  function DeleteUser() {
  let userData = JSON.parse(Cookies.get('user_data')!);
  var userEmail = userData.email;
  const rootStore = useStores();
  const {
    signinUser,
    deleteUser,
    logoutUser
  } = rootStore.loginStore;
  const [passwordType, setPasswordType] = useState<boolean>(false);
  const analytics = require('react-segment');
  const { FOUR_TH_FOUR } = MagicNumber;

  const initialValues = {
    email: userEmail,
    passwordType
  };

  const handleClose = () => {
    $('#addModalCenter').modal('hide');
   logoutUser();
  };

  const handledelete = async (values: any) =>{
    
    if(values.password === 'DELETE')
    {
      await deleteUser().then((res: any) => {
        analytics.default.track('delete Completed');
       console.log(res);
       if (res.statusCode === FOUR_TH_FOUR) 
       {
         errorToast(res.message);
       }
       else if(res.kind === 'ok')
       {
        $('#addModalCenter').modal('show');
       }
      });
    }
    else{
      errorToast('Invalid text');
    }

  };
  const onSubmit = (values: any, { resetForm }: any) => {
  };
  const getInitialPostValues = (): FormValue => {
    return {
      delete_password: ''
    };
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validateDeleteForm,
    onSubmit,
  });

return (
  <>
    <div className='delete-account-main'>
      <h2 className='title-delete'>Delete Account</h2>
      <div className='delete-inside'>
        <p className='enter-text'>Enter the following confirmation text in the below field to proceed-- 'DELETE'</p>
        <div className='form-group'>
        <label className='email'>Email</label>
        <p className='emailinput'>{formik.values.email}</p> 
        </div>
        <Formik
            initialValues={getInitialPostValues()}
            validationSchema={deleteUserFormValidation}
            onSubmit={async (formProps) => {
              const vlaue={email:formik.values.email,
                password:formProps.delete_password
              };
              await handledelete(vlaue);
            }}
          >
            {formProps => (
              <Form onSubmit={formProps.handleSubmit} className='form-section'>
                <div className='form-group' id='outerdiv'>
                  <Input
                    type={passwordType ? 'text' : 'text'}
                    required
                    id='delete_password'
                    name='delete_password'
                    placeholder='Enter Text'
                    value={formProps.values.delete_password}
                    setFieldValue={formProps.setFieldValue}
                    handleEnter={handleEnter}
                  />
                  <FieldError name='delete_password' />
                </div>
                <button type='submit' className='btn primary add-button'
                disabled={!(formProps.dirty )}>Delete Account</button>
              </Form>
            )}
          </Formik>
        <h3 className='info-delete'>When you delete the account</h3>
        <p className='pg-delete'>You won’t be able to access your wishlist.</p>
        <p className='pg-delete'>You will not get updates about the latest offers & discounts.</p>
        <p className='pg-delete'>You won’t be able to check your past orders.</p>
      </div>
    </div>
    {/* modal start here */}
    <div className='modal fade' id='addModalCenter' role='dialog' aria-labelledby='addModalCenterTitle' aria-hidden='true'>
      <div className='modal-dialog background-main modal-dialog-centered' role='document'>
        <div className='modal-content background-container'>
          <div className='delete-ac-page'>
          <img src={delete_icon} className='img-delete' alt='delete' />
            <h3 className='title'>Account Deleted</h3>
            <p className='info'>Your account has been deleted successfully!</p>
            <button type='button' className='btn primary close-button' data-dismiss='modal' onClick={handleClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    {/* modal end here */}
  </>
);
}

export default DeleteUser;

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { USER_ID } from './../../../../constants';
import { useStores } from './../../../../models';
import { FullPageLoader } from './../../Atoms';
import Cookie from 'js-cookie';
import './modal.scss';

type IProps = {
  openModal: any;
  isOpen: boolean;
  title: string;
  description: string;
  cartId: number;
  cartItemId: number;
};

export const RemoveCartItem = ({ openModal, isOpen, title, description, cartId, cartItemId }: IProps) => {
  const [loader, setLoader] = useState<boolean>(false);
  const rootStore = useStores();
  const { deleteCartItem, getCartItem } = rootStore.cartStore;

  const deleteItem =  async () => {
    setLoader(true);
    await deleteCartItem(cartId, cartItemId);
    await getCartItem(Number(Cookie.get(USER_ID)));
    openModal();
    setLoader(false);
  };

  return (
    <>
      {loader && <FullPageLoader /> }
      <Modal
        size='sm'
        show={isOpen}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog modal-dialog-centered'
        contentClassName='remove-modal-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-blank' onClick={openModal}>Cancel</button>
          <button type='button' className='btn btn-blank remove' onClick={deleteItem}>Remove</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

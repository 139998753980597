import { types, Instance } from 'mobx-state-tree';

export const CardData = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  user_id: types.maybeNull(types.number),
  card_token: types.maybeNull(types.string),
  source_token: types.maybeNull(types.string),
  card_id: types.maybeNull(types.string),
  exp_month: types.maybeNull(types.string),
  exp_year: types.maybeNull(types.string),
  last_four_digit: types.maybeNull(types.string),
  account_holder_name: types.maybeNull(types.string),
  brand: types.maybeNull(types.string),
  address_1: types.maybeNull(types.string),
  address_2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  zip_code: types.maybeNull(types.string),
});

export const CardModel = types.model({
  statusCode: types.maybeNull(types.number),
  cardData: types.maybeNull(CardData),
  message: types.maybeNull(types.string)
});

export const YearModel = types.model({
  label: types.maybe(types.number),
  value: types.maybe(types.number)
});

export const AddressModel = types.model({
  address_id: types.maybeNull(types.number)
});

export const IntentData = types.model({
  client_secret: types.maybeNull(types.string),
  id: types.maybeNull(types.string)
});

export const CartIdModel = types.model({
  cart_id: types.maybeNull(types.number)
});

export const MediaModel = types.model({
  filePath: types.maybeNull(types.string),
});
export const AttrModel = types.model({
  attribute: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

export const BuyNowItemModel = types.model({
  id: types.maybeNull(types.number),
  totalPrice: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  variant_data: types.optional(types.array(AttrModel), []),
  medias: types.optional(types.array(MediaModel), []),
  condition: types.maybeNull(types.string),
});

type CardItemModelType = Instance<typeof CardModel>;
export interface CardItemModelTypeI extends CardItemModelType { }

import {
  CROSS_ICON, MagicNumber, FILTER, SORT_ICON
} from '../../../constants';
import { Image } from './Image';

type IProps = {
  index: number;
  title: string;
  keyDel: number;
  removeFilters: (e: any, index: number, key: number, status?: boolean, isDate?: boolean) => void;
  value?: string;
  isDate?: boolean;
  status?: boolean;
};

export const FilterBox = ({ index, title, value, keyDel, removeFilters, isDate, status }: IProps) => {
  const { ONE, FOUR } = MagicNumber;
  return (
    <div className='apply-button'>
      <button type='button' className='btn outline-secondary'>
        <div className='filter-icon' onClick={(e) => removeFilters(e, index, keyDel, status, isDate)}>
          <Image src={index === FOUR ? SORT_ICON : FILTER} className='close-image' alt='closed' />
        </div>
        {index === ONE &&
          <div style={{ backgroundColor: value }} className='filter-color'></div>}
        {title}
        <div className='close-icon' onClick={(e) => removeFilters(e, index, keyDel, status, isDate)}>
          <Image src={CROSS_ICON} className='close-image' alt='closed' />
        </div>
      </button>
    </div>
  );
};

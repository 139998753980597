import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from './extensions/with-environment';

/**
 * Model description here for TypeScript hints.
 */
export const MessageStoreModel = types
  .model('MessageStore')
  .props({
    updateCount: types.optional(types.number, 0),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    updateCountAction(counts: number) {
      self.updateCount = counts;
    },
  })); // eslint-disable-line @typescript-eslint/no-unused-vars

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(['password', 'socialSecurityNumber', 'creditCardNumber']))
  */

type MessageStoreType = Instance<typeof MessageStoreModel>;
export interface MessageStore extends MessageStoreType {}
type MessageStoreSnapshotType = SnapshotOut<typeof MessageStoreModel>;
export interface MessageStoreSnapshot extends MessageStoreSnapshotType {}

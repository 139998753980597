import { Formik, Form } from 'formik';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { WHITE_CLOSE_ICON, changePasswordValidation, PASSWORD_SHOW, PASS_ICON, handleEnter, MagicNumber } from '../../../../constants';
import { useStores } from '../../../../models';
import { errorToast, FieldError, FullPageLoader, Image, Input, successToast } from '../../Atoms';
import './_change-password.scss';

type Props = {
  isShowing: boolean,
  onHideCB?: () => void
};

type FormValue = {
  old_password: string,
  new_password: string,
  confirm_password: string
};

export const ChangePassword = (props: Props) => {

  const rootStore = useStores();
  const { changePassword } = rootStore.profileStore;
  const { logoutUser } = rootStore.loginStore;

  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const getInitialPostValues = (): FormValue => {
    return {
      old_password: '',
      new_password: '',
      confirm_password: ''
    };
  };
  const onSubmit = async (values: FormValue, { resetForm }: any) => {
    setIsChangingPassword(true);
    changePassword(values.old_password, values.new_password, values.confirm_password)
      .then(async (response) => {
        successToast(response);
        props.onHideCB?.();
        resetForm();
        await logoutUser();
      })
      .catch(error => {
        errorToast(error, MagicNumber.ONE_FIVE_H);
      })
      .finally(() => {
        setIsChangingPassword(false);
      });
  };

  return (
    <Modal
      size='sm'
      show={props.isShowing}
      onHide={props.onHideCB}
      backdrop={false}
      aria-labelledby='modal-sm'
      centered
      dialogClassName='change-password-dialog modal-dialog-centered'
      contentClassName='change-password-content'
    >
      {isChangingPassword ? <FullPageLoader /> : null}
      <div className='password-main'>
        <Modal.Header className='feed-like-head'>
          <div className='close-icon' onClick={props.onHideCB}>
            <img src={WHITE_CLOSE_ICON} className='close-image' alt='close' />
          </div>
          <Modal.Title className='contact-text' id='example-modal-sizes-title-sm'>
            Change Password
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={getInitialPostValues()}
          onSubmit={onSubmit}
          validateOnMount={false}
          validationSchema={changePasswordValidation}
        >
          {formProps => (
            <Form onSubmit={formProps.handleSubmit} className='form-section'>
              <div className='form-group'>
                <Input
                  type={showOldPassword ? 'text' : 'password'}
                  required
                  id='old_password'
                  name='old_password'
                  placeholder='Current Password'
                  value={formProps.values.old_password}
                  setFieldValue={formProps.setFieldValue}
                  handleEnter={handleEnter}
                />
                <div className='paasword-section'>
                  <Image
                    src={!showOldPassword ? PASSWORD_SHOW : PASS_ICON}
                    alt='show'
                    className='password-icon'
                    onClick={() => setShowOldPassword(!showOldPassword)} />
                </div>
                <FieldError name='old_password' />
              </div>
              <div className='form-group'>
                <Input
                  type={showNewPassword ? 'text' : 'password'}
                  required
                  id='new_password'
                  name='new_password'
                  placeholder='New Password'
                  value={formProps.values.new_password}
                  setFieldValue={formProps.setFieldValue}
                  handleEnter={handleEnter}
                />
                <div className='paasword-section'>
                  <Image
                    src={!showNewPassword ? PASSWORD_SHOW : PASS_ICON}
                    alt='show'
                    className='password-icon'
                    onClick={() => setShowNewPassword(!showNewPassword)} />
                </div>
                <FieldError name='new_password' />
              </div>
              <div className='form-group'>
                <Input
                  type={showConfirmPassword ? 'text' : 'password'}
                  required
                  id='confirm_password'
                  name='confirm_password'
                  placeholder='Confirm New Password'
                  value={formProps.values.confirm_password}
                  setFieldValue={formProps.setFieldValue}
                  handleEnter={handleEnter}
                />
                <div className='paasword-section'>
                  <Image
                    src={!showConfirmPassword ? PASSWORD_SHOW : PASS_ICON}
                    alt='show'
                    className='password-icon'
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                </div>
                <FieldError name='confirm_password' />
              </div>
              <div className='submit-button'>
                <button type='button'
                  className='btn primary'
                  onClick={(e: any) => formProps.handleSubmit(e)}>Change Password</button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import wrapperComponent from '../../Templates/wrapperComponent';
import { useStores } from '../../../../models';
import { errorToast, FullPageLoader, Image } from '../../Atoms';
import { WishlistSkeleton } from './WishlistSkeleton';
import { BLACK_ICON, MagicNumber, ICON_MEDIA, VALIDATION_MESSAGES, COLLECTION_ICON, PAGE_ROUTES, LIKE_ICON } from './../../../../constants';
import './wishlist.scss';
import { NoWishlistItems } from '../../Molecules/emptyScreens/EmptyScreens';

const Wishlist = () => {
  const rootStore = useStores();
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);
  const [removeLoader, setRemoveLoader] = useState<any>(null);
  const { getWishList, deleteWishlist, wishlistData } = rootStore.wishlistStore;

  const fetchWishlist = async () => {
    setLoader(true);
    await getWishList();
    setLoader(false);
  };

  const toggleWishlistAction = async (id: any, index: number) => {
    const wishlistId = {
      'ids': [id]
    };
    setRemoveLoader(index);
    await deleteWishlist(wishlistId);
    await getWishList();
    setRemoveLoader(null);
  };


  const redirectToProductDetail = (slug: string, productId: number) => {
    history.push({
      pathname: `products/${slug || productId}`
    });
  };

  const redirectToHome = () => {
    history.push(PAGE_ROUTES.MARKET_PLACE);
  };

  useEffect(() => {
    fetchWishlist();
  }, []);

  return (
    <>
      <div className='wishlist-wrapper'>
        <div className='wishlist-container'>
          <span onClick={() => { history.goBack(); }} className='back-btn'><img src={BLACK_ICON} alt='back' className='back-icon' />Back</span>
          <div className='wishlist-container-head'>
            <h1>Wishlist</h1>
          </div>
          {loader ? (<WishlistSkeleton />) : (
            <div className='wishlist-item-wrapper'>
              {wishlistData.length ? (
                <>
                  <div className='wishlist-item-container'>
                    {wishlistData.map((item: any, index: any) => {
                      return (
                        <div
                          className={`wishlist-image-wrapper ${removeLoader ? 'pointer-none' : 'cursor-pointer'}`}
                          key={index}
                        >
                          <Image
                            src={item.medias[MagicNumber.ZERO]?.type === 'video' ? item.medias[MagicNumber.ZERO]?.filepathThumb : `${item.medias[MagicNumber.ZERO]?.filePath}?aspect_ratio=67:60`}
                            onClick={() => { redirectToProductDetail(item.product_slug, item.product_id); }}
                            alt='wishlist'
                          />
                          <span onClick={() => { toggleWishlistAction(item.id, index); }} className='icon icon-wishlist' />
                          <div className={`loading-wrapper ${removeLoader === index ? 'show' : 'd-none'}`}><span className='loading' /></div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) :
                <NoWishlistItems onClick={redirectToHome} />
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default wrapperComponent(true, true)(Wishlist);

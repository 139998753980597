import { useState } from 'react';
import { PROFILE_ICON } from './../../../constants';
import { EditProfile } from './../Molecules';

type IProps = {
  changeImage: (e: any) => void;
  imageClassName: string;
  data: any;
};

export const ProfileImage = ({ changeImage, imageClassName, data }: IProps) => {


  return (
    <>
      <div className='profile-upload-box'>
        <div className={`profile-image-wrapper ${imageClassName}`}>
          <img
            src={`${data.profile_image}?aspect_ratio=1:1` || PROFILE_ICON}
            alt='upload'
            className='placeholder-upload'
            onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
          />
        </div>
        <span onClick={changeImage} className='icon icon-edit icon-modal-toggle' />
      </div>
    </>
  );
};

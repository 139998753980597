import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CommentStoreModel } from '../comment-store/comment-store';
import { HomeStoreModel } from '../home-store/home-store';
import { LoginStoreModel } from '../login-store/login-store';
import { SettingStoreModel } from '../setting-store/setting-store';
import { CartStoreModel } from '../cart-store/cart-store';
import { CardStoreModel } from '../add-card/add-card';
import { ProfileStoreModal } from '../profile-store/profile-store';
import { CollectionStoreModal } from '../collection-store/collection-store';
import { PendingPurchaseStoreModel } from '../pending-purchase-store/pending-purchase-store';
import { FeedStreamStoreModel } from '../feed-stream-store/feed-stream-store';
import { WishlistStoreModal } from '../wishlist-store/wishlist-store';
import { UserStoreModal } from '../user-store/user-store';
import { FollowerStoreModal } from '../follower-store/follower-store';
import { MessageStoreModel } from '../unreadCount';
import { SoldItemStoreModel } from '../ sold-items-store/sold-item-store';
import { StaticPagesStoreModal } from '../staticPages-store/staticPages-store';
import { ContactUsStoreModal } from '../contactForm/contactUs-store';
/**
 * A RootStore model.
 */

export const RootStoreModel = types.model('RootStore').props({
  /**
   * Add modles from different folders
   */
  homeStore: types.optional(HomeStoreModel, {} as any),
  loginStore: types.optional(LoginStoreModel, {} as any),
  settingStore: types.optional(SettingStoreModel, {} as any),
  commentStore: types.optional(CommentStoreModel, {} as any),
  cartStore: types.optional(CartStoreModel, {} as any),
  cardStore: types.optional(CardStoreModel, {} as any),
  profileStore: types.optional(ProfileStoreModal, {} as any),
  collectionStore: types.optional(CollectionStoreModal, {} as any),
  pendingPurchaseStore: types.optional(PendingPurchaseStoreModel, {} as any),
  soldItemStore: types.optional(SoldItemStoreModel, {} as any),
  feedStreamStore: types.optional(FeedStreamStoreModel, {} as any),
  wishlistStore: types.optional(WishlistStoreModal, {} as any),
  userStore: types.optional(UserStoreModal, {} as any),
  followerStore: types.optional(FollowerStoreModal, {} as any),
  messageStore: types.optional(MessageStoreModel, {} as any),
  staticPagesStore: types.optional(StaticPagesStoreModal, {} as any),
  contactUsStore: types.optional(ContactUsStoreModal, {} as any)
});
/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>;
/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Cookie from 'js-cookie';
import { useStores } from '../../../../models';
import { PROFILE_ICON, USER_ID } from '../../../../constants';


type IProps = {
    listItem: any;
    currentUserId: number;
    userFollowersMood: boolean;
    removeUser: () => void;
    removeLoader: boolean;
    targetId: number;
};
  
  export const FollowerUserList = observer(function TabContent({ listItem, removeUser, userFollowersMood, removeLoader, targetId}: IProps) {
    const history = useHistory();
    const loggedInUserId = Number(Cookie.get(USER_ID));
  
    const redirectToDetailPage = (id:number) => {
      history.push({
        pathname: id === loggedInUserId ? `/profile` : `/profile/${id}`,
      });
    };
  
    return (
        <div className='list-item loading-container'>
          <div className='list-item-image'>
          <img
            src={listItem.profile_image}
            alt='user image'
            onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
          />
          </div>
          <div className='list-item-detail' onClick={()=>{redirectToDetailPage(listItem.id); }}>
            <h3>{listItem.firstname} {listItem.lastname}</h3>
            <p>{listItem.username}</p>
          </div>
            {removeLoader && targetId === listItem.id ? (<span className='loading' />
            ):(<>
                {userFollowersMood ? (
                <div className='list-item-action'>
                  <button type='button' disabled={removeLoader} onClick={removeUser} className='btn secondary-btn'>Remove</button>
                </div>
                ):(<></>)}
            </>)}
        </div>
    );
  });

import { Instance, SnapshotOut, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import Cookie from 'js-cookie';
import {
  TOKEN, USER_ID, MagicNumber, EMAIL_SUCCESS, OTP_VERIFIED,
  USER_VERIFIED, NOT_VERIFIED, EMAIL_VERIFIED, PROFILE_SUCCESS, USER_DATA, REFRESH_TOKEN, INACTIVE_USER, STREAM_TOKEN, FEED_USER_ID, ROLE, UserType, UserTypeName, FunctionalityType, EMAIL
} from '../../constants';
import { errorToast, successToast } from '../../components/ui/Atoms';
import {
  ProfileModel,
  GoogleModel,
  VerifiedModel,
  UserModel,
  NotificationModel
} from './login-entity';
import Cookies from 'js-cookie';

export const isAuthorized = () => {
  return Cookie.get(TOKEN) !== undefined;
};

/**
 * Model description here for TypeScript hints.
 */

export const LoginStoreModel = types
  .model('LoginStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoggedIn: types.optional(types.boolean, isAuthorized()),
    verifiedProfile: types.optional(types.frozen(ProfileModel), {}),
    googleProfile: types.optional(types.frozen(GoogleModel), {}),
    isVerifiedUser: types.optional(types.frozen(VerifiedModel), {}),
    notificationList: types.maybeNull(NotificationModel),
    forgotPassMsg: types.optional(types.frozen(VerifiedModel), {
      statusCode: MagicNumber.ZERO,
      message: ''
    }),
    tokenData: types.optional(types.string, ''),
    refreshTokenData: types.optional(types.string, ''),
    userData: types.optional(types.frozen(UserModel), {}),
    isEmailExist: types.optional(types.boolean, false),
    isUsernameExist: types.optional(types.boolean, false),
    streamTokenData: types.optional(types.string, ''),
    feedUserId: types.optional(types.string, ''),
    role: types.optional(types.string, ''),
    badgeCount: types.optional(types.maybeNull(types.number), 0),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    registerAuthToken: (token: string, user: any, refreshToken: string, feedUserId: string) => {
      self.tokenData = token;
      self.userData = user;
      self.feedUserId = feedUserId;
      self.role = user.role;

      Cookie.set(TOKEN, token);
      Cookie.set(USER_ID, user.id);
      Cookie.set(EMAIL, user.email);
      Cookie.set(USER_DATA, user);
      Cookie.set(ROLE, user.role);
      Cookie.set(REFRESH_TOKEN, refreshToken);
      Cookie.set(FEED_USER_ID, feedUserId);
    }
  }))
  .actions((self) => ({
    registerStreamToken: (streamToken: any) => {
      self.streamTokenData = streamToken;

      Cookie.set(STREAM_TOKEN, streamToken);
    }
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    signinUser: flow(function* (formData: any) {
      
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`users/login`, formData);
        if (response.response) {
          const { token, user, refresh_token, streamToken, feeduserid } = response.response.data;
          if (user && user.profile_image) {
            Cookie.set('user_profile', user.profile_image);
          }
          if (user.verified) {
            self.registerAuthToken(token, user, refresh_token, feeduserid.toString());
            self.registerStreamToken(streamToken);
            yield self.environment.api.setHeaders(token);
            self.isLoggedIn = true;
            return response.response.data.user;
          } else return { status: NOT_VERIFIED, email: user.email };
        } else if (response.data) {
          
          if (response.data.statusCode === MagicNumber.FOUR_TH)
            errorToast(INACTIVE_USER);
          else return response.data;
        } else return response;
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    sendFcmData: flow(function* (fcmData: any) {
      
      self.state = 'pending';
      try {
        
        const response = yield self.environment.api.post(`users/device-token`, fcmData);
        self.state = 'done';
        if(response.kind == 'unauthorized'){
          return;
        }
      } catch (error) {
        
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getNotification: flow(function* (
      page: number = MagicNumber.ONE,
      limit: number = MagicNumber.TEN,
      read: string = 'true'
    ) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`notifications?page=${page}&limit=${limit}&read=${read}`);
        if (response && response.data) {
          self.notificationList = response.data;
        }
        self.state = 'done';
        return response.data;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getNotificationBadge: flow(function* () {
      self.state = 'pending';
      const checkUserToken = Cookies.get('token');
      if (checkUserToken) {
        try {
          const response = yield self.environment.api.get(`notifications/badge`);
          if (response)
            self.badgeCount = response.data ? response.data : 0;
          self.state = 'done';
        } catch (error) {
          self.state = 'error';
          throw error;
        }
      } else {
        return false;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    checkSocialProfile: flow(function* (formData: any) {
      
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`users/check-socialprofile`, formData);
        if (response.response) {
          const { statusCode, msg, token } = response.response.data;
          if (token) {
            const { token, user, refresh_token, streamToken, feeduserid } = response.response.data;
            self.registerAuthToken(token, user, refresh_token, feeduserid.toString());
            self.registerStreamToken(streamToken);
            yield self.environment.api.setHeaders(token);
            self.isLoggedIn = true;
          } else {
            return { statusCode, msg };
          }
        } else {
          return response.data;
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    completeSocialprofile: flow(function* (formData: any, isAppleLogin?: boolean) {
      
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`users/complete-socialprofile`, formData);
        if (response.response) {
          const { data } = response.response.data;
          if (isAppleLogin) {
            self.tokenData = data.token;
            self.userData = data.user;
            self.streamTokenData = data.streamToken;
            self.feedUserId = data.feeduserid.toString();
            return true;
          } else {
            self.registerAuthToken(data.token, data.user, self.refreshTokenData, self.feedUserId);
            self.registerStreamToken(data.streamToken);
            successToast(PROFILE_SUCCESS);
            yield self.environment.api.setHeaders(data.token);
            self.isLoggedIn = true;
            return true;
          }
        } else if (response.data.message.length && Array.isArray(response.data.message)) {
          
          errorToast(response.data.message[MagicNumber.ZERO]);
        } else if (response.data.message) errorToast(response.data.message);
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    logoutUser: flow(function* () {
      self.state = 'pending';
      try {
        Cookie.remove(TOKEN);
        Cookie.remove(USER_ID);
        Cookie.remove(USER_DATA);
        Cookie.remove(REFRESH_TOKEN);
        Cookie.remove(STREAM_TOKEN);
        Cookie.remove(FEED_USER_ID);
        Cookie.remove(ROLE);
        Cookie.remove(EMAIL);
        isAuthorized();
        self.isLoggedIn = false;
        self.state = 'done';
        window.location.href = '/';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    sendOtpToEmail: flow(function* (formData: any) {
      self.state = 'pending';
      try {
        yield self.environment.api.post(`users/complete-socialprofile`, formData);
        self.isLoggedIn = false;
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    forgotPassword: flow(function* (formData: any) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`/users/forgot-password`, formData);
        const { data, status } = response.response;
        if (data.statusCode === MagicNumber.FOUR_TH_FOUR)
          self.forgotPassMsg = { statusCode: data.statusCode, message: data.msg };
        else
          self.forgotPassMsg = { statusCode: status, message: data };
        return self.forgotPassMsg;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    resetPassword: flow(function* (formData: any) {
      
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`/users/reset-password`, formData);
        if (response.response) {
          const { status } = response.response;
          if (status === MagicNumber.TWO_TH_ONE) {
            return true;
          }
        } else errorToast(response.data.message);
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    resendOtp: flow(function* (formData: any) {
      
      self.state = 'pending';
      try {
        yield self.environment.api.post(`/users/resend-otp`, formData);
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions(self => ({
    setGoogleProfile(data: any) {
      
      self.googleProfile = { ...data };
    }
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    registerUser: flow(function* (formData: any) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`/users`, formData);
        if (response.response) {
          const { data } = response.response.data;
          self.tokenData = data.token;
          self.userData = data.user;
          self.streamTokenData = data.streamToken;
          self.feedUserId = data.feeduserid;
          return true;
        } 
        // else if (response.data.message.length && Array.isArray(response.data.message))
        //   errorToast(response.data.message[MagicNumber.ZERO])
        // else errorToast(response.data.message)
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    verifyOtp: flow(function* (formData: any, isRegister?: boolean, isEmailUpdate?: boolean) {
      
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`/users/verify-otp`, formData);
        let { statusCode, msg } = response.response.data;
        if (statusCode === MagicNumber.FOUR_TH_FOUR)
          errorToast(msg);
        else if (isRegister) {
          successToast(EMAIL_SUCCESS);
          self.registerAuthToken(self.tokenData, self.userData, self.refreshTokenData, self.feedUserId);
          self.registerStreamToken(self.streamTokenData);
          yield self.environment.api.setHeaders(self.tokenData);
          self.isLoggedIn = true;
          return USER_VERIFIED;
        } else if (isEmailUpdate) {
          successToast(EMAIL_SUCCESS);
          return EMAIL_VERIFIED;
        }
        else if (!statusCode && !isRegister && !isEmailUpdate) return OTP_VERIFIED;
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    verifyAppleSignIn: flow(function* (formData: any) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`/users/verify-apple-signin`, formData);
        self.state = 'done';
        return response.response.data;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getS3Config: flow(function* () {
      
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`/configuration/s3-auth`);
        self.state = 'done';
        return response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))

  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    deleteUser: flow(function* () {
      try {
        
        self.state = 'pending';
        const requestData = yield self.environment.api.delete(`users`);
        self.state = 'done';
        return requestData;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))


  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    refreshUser: flow(function* () {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`/users/refresh`);
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    setUserName() {
      self.isUsernameExist = false;
    },
    setUserEmail() {
      self.isEmailExist = false;
    },
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    checkEmailExist: flow(function* (email: string, username: string) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`/users?email=${email}&username=${username}`);
        self.state = 'done';
        if (response.data.statusCode === MagicNumber.TWO_H) {
          if (email) self.isEmailExist = false;
          else self.isUsernameExist = false;
        }
      } catch (error: any) {
        if (error.code === MagicNumber.FOUR_TH) {
          if (email) self.isEmailExist = true;
          else self.isUsernameExist = true;
        }
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    fetchUserDetails: flow(function* (userId: any) {
      self.state = 'pending';
        try {
        const response = yield self.environment.api.get(`users/${userId}`);
        self.state = 'done';
        return response;
      } catch (error) {    
        self.state = 'error';
        throw error;
      }
    })
  }))
  .views((self) => ({
    getRoleType() {
      const role = Cookie.get(ROLE);
      return !role ? UserType.user : UserType[role as keyof typeof UserType];
    }
  }))
  .views((self) => ({
    getRoleTypeName() {
      const role = self.getRoleType();
      switch (role) {
        case UserType.user:
          return UserTypeName.user;
        case UserType.admin:
          return UserTypeName.admin;
        case UserType.community_manager:
          return UserTypeName.community_manager;
      }
    }
  }))
  .views((self) => ({
    isFunctionalityAllowed(functionality: FunctionalityType) {
      const profileRole = self.getRoleType();

      switch (functionality) {
        case FunctionalityType.AddToCart:
          return profileRole === UserType.user;
        case FunctionalityType.AddToWishlist:
          return profileRole === UserType.user;
        case FunctionalityType.BuyProduct:
          return profileRole === UserType.user;
        case FunctionalityType.DeleteComment:
          return profileRole === UserType.admin;
        case FunctionalityType.DeletePost:
          return profileRole === UserType.admin;
        case FunctionalityType.NotifyMe:
          return profileRole === UserType.user;
        case FunctionalityType.PlaceBid:
          return profileRole === UserType.user;
        case FunctionalityType.ReportComment:
          return profileRole !== UserType.admin;
        case FunctionalityType.ReportPost:
          return profileRole !== UserType.admin;
        case FunctionalityType.ShowOptionsOnAdminPost:
          return profileRole === UserType.admin;
      }
    }
  }));

type LoginStoreType = Instance<typeof LoginStoreModel>;
export interface HomeStore extends LoginStoreType { }
type LoginStoreSnapshotType = SnapshotOut<typeof LoginStoreModel>;
export interface LoginStoreSnapshot extends LoginStoreSnapshotType { }

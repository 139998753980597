import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import Skeleton from 'react-loading-skeleton';
import { FollowingList, FollowersList } from './../../../Molecules';
import { useStores } from '../../../../../models';
import { USER_ID, PROFILE_ICON, STREAM_TOKEN, BLACK_ICON, PAGE_ROUTES, MagicNumber, FEED_USER_ID } from '../../../../../constants';

type Iprops = {
  data: any;
  followCount: any;
  unfollowStatus: boolean;
  totalFollowCount: any;
};

export const ProfileView = ({ data, followCount, unfollowStatus, totalFollowCount }: Iprops) => {
  const history = useHistory();
  const rootStore = useStores();
  const [loader, setLoader] = useState<boolean>(false);
  const [initializeLoader, setInitializeLoader] = useState<boolean>(true);
  const [statusLoader, setStatusLoader] = useState<boolean>(false);
  const [isUserFollowed, setUserFollowed] = useState<boolean>(false);
  const [followingModal, setFollowingModal] = useState<boolean>(false);
  const [followerModal, setFollowerModal] = useState<boolean>(false);
  const { followUser, unfollowUser, getFollowCount, getUnfollowStatus } = rootStore.followerStore;
  const currentUserId = data.id;
  const usersFollowersCount = parseInt(unfollowStatus ? totalFollowCount.followerCount : followCount.followerCount);
  const usersFollowingCount = parseInt(unfollowStatus ? totalFollowCount.followingCount : followCount.followingCount);
  const stream = require('getstream');
  const streamToken = Cookie.get(STREAM_TOKEN);
  const client = stream.connect(
    process.env.REACT_APP_STREAM_API_KEY ?? '',
    streamToken
  );
  const userId = Cookie.get(FEED_USER_ID);
  const userfeed = client.feed('timeline', userId, streamToken);

  const toggleFollowingModal = () => {
    setFollowingModal(!followingModal);
  };
  const toggleFollowerModal = () => {
    setFollowerModal(!followerModal);
  };

  const followsStatusCheck = async () => {
    setStatusLoader(true);
    const response = await userfeed.following({ offset: 0, limit: 1, filter: [`timeline:${currentUserId}`] });
    const { results } = response;
    if (results.length) {
      setUserFollowed(true);
    }
    setStatusLoader(false);
  };

  const userFollowingAction = async (id: any, userStatus: boolean) => {
    setLoader(true);
    if (userStatus) {
      await unfollowUser(id).then(res => {
        if (res && res.data && res.data.data === true) {
          setUserFollowed(false);
          getUnfollowStatus(true);
        }
      });
    }else {
      await followUser(id).then(res => {
        if (res && res.data && res.data.data === true) {
          setUserFollowed(true);
          getUnfollowStatus(true);
        }
      });
    }
    await getFollowCount(currentUserId);
    setLoader(false);
  };

  const onConnect = (id: number) => {
    history.push(`${PAGE_ROUTES.CHAT}?userId=${id}`);
  };

  useEffect(() => {
    followsStatusCheck();
    setInitializeLoader(false);
  }, []);

  return (
    <>
      <span onClick={() => { history.goBack(); }} className='back-btn'><img src={BLACK_ICON} alt='back' className='back-icon' />Back</span>
      <div className='profile-inline step-1'>
        <div className='profile-pic user-profile'>
          <img
            src={data.profile_image}
            alt='user image'
            onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
          />
        </div>
        <div className='profile-content'>
          <div className='left-item'>
            <div className='name'>
              {data && data.firstname} {data && data.lastname}
              <span className='nameid'>{data && data.username}</span>
            </div>
            <ul className='follow-content'>
              {loader ? (<Skeleton />) : (
                <>
                  {usersFollowersCount === MagicNumber.ZERO ? (
                    <li className='cursor-default'><span className='count'>
                      {usersFollowersCount}</span> Follower
                    </li>
                  ) : (
                    <li onClick={toggleFollowerModal}><span className='count'>
                      {usersFollowersCount}</span>
                      {usersFollowersCount > MagicNumber.ONE ? 'Followers' : 'Follower'}
                    </li>
                  )}

                  {usersFollowingCount === MagicNumber.ZERO ? (
                    <li className='cursor-default'><span className='count'>
                      {usersFollowingCount}</span> Following
                    </li>
                  ) : (
                    <li onClick={toggleFollowingModal}><span className='count'>
                      {usersFollowingCount}</span> Following
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
          {currentUserId.toString() === userId ? (<></>) : (
            <div className='right-item'>
              <button type='button' className='btn primary-btn' onClick={() => onConnect(data.id)}>
                <Link
                  to={{
                    pathname: PAGE_ROUTES.CHAT,
                  }}
                >Message
                </Link>
              </button>
              {statusLoader || initializeLoader ? (<Skeleton />) : (
                <button
                  type='button'
                  className='btn secondary-btn'
                  onClick={() => { userFollowingAction(data.id, isUserFollowed); }}
                >
                  {isUserFollowed ? 'Unfollow' : 'Follow'}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {followingModal &&
        <FollowingList
          openModal={() => { toggleFollowingModal(); }}
          title='Following'
          isOpen={followingModal}
          userId={data.id}
          followingMood={false}
        />
      }
      {followerModal &&
        <FollowersList
          openModal={() => { toggleFollowerModal(); }}
          title='Followers'
          isOpen={followerModal}
          userId={data.id}
          followersMood={false}
        />
      }
    </>
  );
};


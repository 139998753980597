import { ErrorMessage } from 'formik';

type IProps = {
    name?: string;
    message?: string | null | undefined;
};
export const FieldError = ({ name, message }: IProps) => {
    if (name) {
        return (
            <ErrorMessage name={name}>{message => <div className='error-msg'>{message}</div>}</ErrorMessage>
        );
    } else return (
        <div className='error-msg'>{message}</div>
    );
};

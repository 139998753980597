import React, { useContext, useState, useEffect } from 'react';
import { Avatar, ChatContext } from 'stream-chat-react';
import { SkeletonLoader } from './SkeletonLoader';
import { CreateChannelIcon } from '../../assets';
import _debounce from 'lodash.debounce';
import { MESSAGE_EDIT, PAGE_ROUTES, CROSS_ICON, searchblackIcon, BACK_ARROW, NO_MESSAGING, FEED_USER_ID } from '../../../../../../constants';
import { Link } from "react-router-dom";
import { MagicNumber } from '../../../../../../constants/MagicNumber';
import { Footer } from '../../../../Molecules';
import './MessagingChannelList.css';
import Cookie from 'js-cookie';

const MessagingChannelList = ({ children, error = false, loading, onCreateChannel }) => {

  const [inputText, setInputText] = useState('');
  const { client, setActiveChannel } = useContext(ChatContext);
  const [searchResults, setSearchResult] = useState([]);

  if (client !== undefined) {
    var { profileImage = require('../../assets/stream.png'), name = 'Example User' } =
      client.user || {};
  }

  const findMessages = () => {
    if (inputText.length > MagicNumber.ZERO) {
      const filters = { members: { $in: [Cookie.get(FEED_USER_ID)] } };
      const search = client.search(
        filters,
        inputText,
        { limit: MagicNumber.HUNDRED, offset: MagicNumber.ZERO },
      );
      search.then(search => {
        const filterMessages = search.results.filter(msg => msg.message.user.id !== Cookie.get(FEED_USER_ID))
        setSearchResult(filterMessages);
      })
    } else {
      setSearchResult([]);
      setInputText('');
    }
  };

  const findUsersDebounce = _debounce(findMessages, MagicNumber.TH, {
    trailing: true,
  });

  useEffect(() => {
    findUsersDebounce()
  }, [inputText])

  const goToMessages = async (id) => {
    const conversation = await client.channel('messaging', {
      members: [id, client.userID],
    });

    await conversation.watch();
    setActiveChannel(conversation);
  };

  const clearSearch = () => {
    setSearchResult([]);
    setInputText('');
  }

  const ListHeaderWrapper = ({ children }) => {
    return (
      <div className='messaging__channel-list'>
        <div className="back-button-box">
          <div className="back-button">
            <p className="back-text">
              <Link
                to={{
                  pathname: PAGE_ROUTES.HOME
                }} replace
              >
                <img src={BACK_ARROW} alt="back" className="back-icon" />
                Back
              </Link>
            </p>
          </div>
        </div>
        <div className='messaging__channel-list__header'>
          <Avatar image={profileImage} name={name} size={40} />
          <div className='messaging__channel-list__header__name'>Messages</div>
          <button className='messaging__channel-list__header__button' onClick={onCreateChannel}>
            <CreateChannelIcon />
            <img src={MESSAGE_EDIT} className="search-image" alt="search" />
          </button>
        </div>
        <div className="list-box">
          <div className="search-section">
            <input
              type="text"
              autoFocus
              className="form-control"
              placeholder="Search Message..."
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
            />
            <div className="search-icon">
              <img src={searchblackIcon} className="search-image" alt="search" />
            </div>
          </div>
          {
            searchResults && searchResults.length > MagicNumber.ZERO ?
              <a className="close-icon"><img src={CROSS_ICON} className="close-image" alt="search" onClick={clearSearch} /></a> : null
          }
        </div>
        {
          searchResults && searchResults.length > MagicNumber.ZERO ?
            <div className="messages-secrch-section">
              <div className="search-contain">
                <p className="history-title">Messages</p>
              </div>
              <div className="search-message-history">
                {searchResults.map((search, index) => {
                  return (
                    <div className="box-messages" key={index} onClick={() => goToMessages(search.message.user.id)}>
                      <Avatar image={search.message.user.image} name={name} size={40} />
                      <div className="right-detail">
                        <div className="name-text">{search.message.user.firstname} {search.message.user.lastname}</div>
                        <div className="message-text">{search.message.text}</div>
                      </div>
                    </div>)
                })}
              </div>
            </div>
            : ''
        }
        {
          searchResults && searchResults.length > MagicNumber.ZERO ?
            <div className="search-contain">
              <p className="history-title">USERS</p>
            </div> : null
        }

        {client && client.connecting && Object.keys(client.activeChannels).length === MagicNumber.ZERO ?
          <div>
            <p>No message history</p>
            <div className="preview-no-data">
              <img src={NO_MESSAGING} className="no-data-image" alt="nodata" />
              <p className="empty-text">No Message</p>
            </div>
          </div>
          : children
        }
        <Footer></Footer>
      </div>
    );
  };

  if (error) {
    return (
      <ListHeaderWrapper>
        <div className='messaging__channel-list__message'>
          Error loading conversations, please try again momentarily.
        </div>
      </ListHeaderWrapper>
    );
  }

  if (loading && client && !client.connecting) {
    return (
      <ListHeaderWrapper>
        <div className='messaging__channel-list__message'>
          <SkeletonLoader />
        </div>
      </ListHeaderWrapper>
    );
  }

  if (loading) {
    return (
      <div className='messaging__channel-list__messag-center'>
        <SkeletonLoader />
      </div>
    );
  }

  return <ListHeaderWrapper>{children}</ListHeaderWrapper>;
};

export default React.memo(MessagingChannelList);

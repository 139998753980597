import Skeleton from 'react-loading-skeleton';

export const CartListingSkeleton = () => {
  return (
    <>
        <div className="cart-list-skeleton">
          <div className="cart-list-skeleton-image">
            <Skeleton />
          </div>
          <div className="cart-list-skeleton-detail">
            <Skeleton count={1} />
            <Skeleton count={3} />
            <Skeleton count={1} />
          </div>
          <div className="cart-list-skeleton-action">
            <Skeleton count={2} />
            <Skeleton count={1} />
            <Skeleton count={1} />
          </div>
        </div>
    </>
  )
};


import Loader from 'react-loader-spinner';

const FullPageLoader: React.FC<{}> = () => {
    return (
        <div className='full-page-loader'>
            <div className='sub-loader'>
                <Loader
                    type='TailSpin'
                    color='#fff'
                    height={50}
                    width={50}
                />
            </div>
        </div>
    );
};

export default FullPageLoader;

// @ts-ignore
import ShowMoreText from 'react-show-more-text';

export const FeedItemDescription = (props: { description: string | undefined }) => {
  return (
    <ShowMoreText
      lines={3}
      more='Show more'
      less='Show less'
      className='post-pg'
      anchorClass='show-more-text'
      expanded={false}
    >
      {props.description}
    </ShowMoreText>
  );
};

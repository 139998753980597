import { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import wrapperComponent from '../../Templates/wrapperComponent';
import './_NewHome.scss';
import { PREV_ICON, NEXT_ICON, TWITTER_ICON, COLLECTOR_PL, INSTAGRAM_ICON, FACEBOOK_ICON, SNAPCHAT_ICON, TIKTOK_ICON, LINK_ICON, LOGO, OOD_LOGO, USER_DROP, COLLECTOR_PRODUCT, QUOTE_START, QUOTE_END } from '../../../../constants/image';
import { useHistory, useLocation,useParams } from 'react-router-dom';
import { useStores } from '../../../../models';
import { EMAIL, MagicNumber, Notify_DROP, Notify_ERROR, USER_ID } from '../../../../constants';
import { errorToast, FullPageLoader, successToast } from '../../Atoms';
import { NotifyModal } from '../../Molecules';
import Cookie from 'js-cookie';
import DropFaqList from '../faq/DropFaqList';
import DropTime from './DropTime';
import { FeedItemVideo } from '../feedListing/feedItem/FeedItemVideo';
import Skeleton from 'react-loading-skeleton';

const PreviewDrop = observer(function PreviewDrop() {

    const base = process.env.REACT_APP_ASSET_HOST;
    const history = useHistory();
    const rootStore = useStores();
    const {
        getHomeDropById,
        getUpcommingDrop,
        getDropFaq,
        homeDrop,
        homeDropProductList,
        homeDropProductListCount,
        homeUpcommingListCount,
        homeUpcommingDropList,
        homeDropFaqList,
    } = rootStore.homeStore;
    const { notifyUser } = rootStore.cartStore;
    const [loader, setLoader] = useState(true);
    const [isModalOpen, openModal] = useState<boolean>(false);
    const [isStartDrop, setStartDrop] = useState<boolean>(false);
    const [remainingDate, setRemainingDate] = useState<string>('');
    const [dropData, setDropData] = useState<any>();
    const myRef: any = useRef();
    const params: any = useParams();
    const location = useLocation();

    const checkAvailabitity = async (data: any) => {
        let startOrEndDates = data && data.sale_start_date ? data.sale_start_date : new Date();
        const saleStartDate = new Date(startOrEndDates).getTime();
        const todatedate = new Date().getTime();
        let status = false;
        if (saleStartDate > todatedate) {
            setStartDrop(false);
            status = false;
        } else {
            startOrEndDates = data && data.sale_end_date ? data.sale_end_date : new Date();
            setStartDrop(true);
            status = true;
        }
        handleDropDate(startOrEndDates);
        dateCountDownTimer(data, status);
    };

    const dateCountDownTimer = (data: any, status: boolean) => {
        var upgradeTime: string;
        let flag = MagicNumber.ZERO;
        if (status) {
            upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
        } else {
            upgradeTime = data && data.sale_start_date ? data.sale_start_date : new Date();
        }
        const timer = () => {
            let diffTime = new Date(upgradeTime).valueOf() - new Date().valueOf();
            let days = diffTime / (MagicNumber.TWENTYFR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONE_TH);
            let hours = (days % MagicNumber.ONE) * MagicNumber.TWENTYFR;
            let minutes = (hours % MagicNumber.ONE) * MagicNumber.SIXTY;
            let secs = (minutes % MagicNumber.ONE) * MagicNumber.SIXTY;
            [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
            if (Math.sign(days) === -MagicNumber.ONE) {
                clearInterval(countdownTimer);
            }
        };
        var countdownTimer = setInterval(() => {
            let startOrEndDatess = data && data.sale_start_date ? data.sale_start_date : new Date();
            const saleStartDate = new Date(startOrEndDatess).getTime();
            const todatedate = new Date().getTime();
            if (saleStartDate > todatedate) {
                upgradeTime = startOrEndDatess;
                setStartDrop(false);
            } else {
                upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
                setStartDrop(true);
            }
            timer();
            flag = flag + MagicNumber.ONE;
            handleDropDate(upgradeTime);
        }, MagicNumber.ONE_TH);
    };

    const handleDropDate = (startOrEndDates: any) => {
        let diffTime = new Date(startOrEndDates).valueOf() - new Date().valueOf();
        let days = diffTime / (MagicNumber.TWENTYFR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONE_TH);
        let hours = (days % MagicNumber.ONE) * MagicNumber.TWENTYFR;
        let minutes = (hours % MagicNumber.ONE) * MagicNumber.SIXTY;
        let secs = (minutes % MagicNumber.ONE) * MagicNumber.SIXTY;
        [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
        let day_;
        let hour_;
        let minute_;
        if (days > MagicNumber.ZERO) {
            day_ = Math.abs(days) > MagicNumber.ONE ? `${Math.abs(days)}days` : `${Math.abs(days)}day`;
        }
        if (hours > MagicNumber.ZERO) {
            hour_ = Math.abs(hours) > MagicNumber.ONE ? `${Math.abs(hours)}hrs` : `${Math.abs(hours)}hr`;
        }
        if (minutes > MagicNumber.ZERO) {
            minute_ = Math.abs(minutes) > MagicNumber.ONE ? `${Math.abs(minutes)}mins` : `${Math.abs(minutes)}min`;
        }
        if (minutes === MagicNumber.ZERO && secs > MagicNumber.ZERO) {
            minute_ = `1min`;
        }
        setRemainingDate(`${day_ ? day_ : ''} ${hour_ ? hour_ : ''} ${minute_ ? minute_ : '0'}`);
    };

    const fetchDropById = async (id: any) => {
        await getHomeDropById(id).then(async (res: any) => {
            setDropData(res);
            await checkAvailabitity(res);
            history.replace({
                search: ''
            });
        });
        await getDropFaq();
        getUpcommingDrop();
        setLoader(false);
    };

    useEffect(() => {
        const token = location.search.split('?tauth=')[MagicNumber.ONE];
        const getToken = Cookie.get('token');
        if (token === getToken || !token) {
            fetchDropById(params.id);
        } else {
            Cookie.set('token', token);
            setTimeout(() => {
                window.location.reload();
            });
        }
    }, [params.id]);

    const handleNotifyMe = async () => {
        openModal(true);
    };

    const handleNotifyUser = async (values: any) => {
        if (Cookie.get(USER_ID)) {
            setLoader(true);
            const data = {
                email: Cookie.get(EMAIL),
                entity_id: homeDrop && homeDrop.id,
                entity_type: 'drop',
                user_id: Cookie.get(USER_ID)
            };
            await notifyUser(data).then(res => {
                if (res.data.statusCode === MagicNumber.TWO_H) {
                    successToast(Notify_DROP);
                    openModal(false);
                }
            }).catch((err) => {
                setLoader(false);
            }).finally(() => {
                setLoader(false);
            });
            setLoader(false);
        } else {
            setLoader(false);
            errorToast(Notify_ERROR);
        }

    };

    const handleDropDetailsPage = (id: number) => {
        if (!isStartDrop) {
            return false;
        }
        history.push(`drop/${id}`);
    };

    const executeScroll = () => {
        if (myRef) {
            myRef.current.scrollIntoView()
        };
    };

    const copyUrl = () => {
        const url = window.location.origin;
        navigator.clipboard.writeText(url);
        successToast('Link copied to clipboard successfully!');
    };

    const getDropStartDate = (date: string) => {
        const monthAlpha = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = new Date(date).getMonth();
        const dates = new Date(date).getDate();
        return (
            <>
                <h3 className='title'>{monthAlpha[month]}</h3>
                <p className='date'>{('0' + dates).slice(-2)}</p>
            </>
        );
    };

    const daysDiff = (dates: string) => {
        const dateNow = new Date().getTime();
        const startDate = new Date(dates).getTime();
        const diffTime = Math.abs(startDate - dateNow);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    return (
        <>
            {loader && <FullPageLoader />}
            <div className='drop-preview-section'>
                <div className='admin-header'>
                    <div className='logo-left'>
                        <img src={LOGO} className='logo' alt='logo' />
                    </div>
                </div>
                <div className='preview-section'>
                    <div className='text-preview'>
                        <h2 className='title'>Preview</h2>
                    </div>
                </div>
            </div>
            <div className='countdown-banner-section'>
                <div className='countdown-image banner1' style={{ backgroundImage: `url(${homeDrop.filePath})` }}>
                </div>
                <div className='content-section'>
                    <div className='title-timer-section'>
                        <div className='start-timer-text'>
                            <h1 className='title-text'>{homeDrop.title}</h1>
                            {remainingDate && remainingDate != '' ? <div className='button-timer'>
                                {remainingDate && (remainingDate).trim() !== '0' ? <button type='button' className='btn primary'>{isStartDrop ? 'Ends in' : 'Starts in'} {remainingDate}</button> :
                                    <button type='button' className='btn danger'>Drop Expired</button>}
                            </div> : <Skeleton />}
                        </div>
                        <div className='notify-detail-section'>
                            <div className='container-notify'>
                                <p className='notify-pg'>{homeDrop.description}</p>
                                {!isStartDrop ? <div className='notify-button'>
                                    <button type='button'
                                        className='btn primary'
                                        onClick={handleNotifyMe}
                                    >Notify Me</button>
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='responsive-title-timer-section'>
                    <div className='title-timer-section'>
                        <div className='start-timer-text'>
                            <h1 className='title-text'>{homeDrop.title}</h1>
                            <div className='button-timer'>
                                {remainingDate && (remainingDate).trim() !== '0' ? <button type='button' className='btn primary'>{isStartDrop ? 'Ends in' : 'Starts in'} {remainingDate}</button> :
                                    <button type='button' className='btn danger'>Drop Expired</button>}
                            </div>
                        </div>
                        <div className='notify-detail-section'>
                            <div className='container-notify'>
                                <p className='notify-pg'>{homeDrop.description}</p>
                                {!isStartDrop ? <div className='notify-button'>
                                    <button type='button'
                                        className='btn primary'
                                        onClick={handleNotifyMe}
                                    >Notify Me</button>
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {homeUpcommingListCount && remainingDate && (remainingDate).trim() == '0' ? <div className='product-carousel-section droppingsoon-carousel-section'>
                <div className='product-container'>
                    <div className='head-product'>
                        <h2 className='pg-title'>Dropping Soon</h2>
                    </div>
                    <div className='product-carousel-container'>
                        <div className='product-section'>
                            <div className='carousel-section'>
                                <div id='destinationcontrols1' className='carousel slide' data-ride='carousel'>
                                    <div className='carousel-inner'>
                                        {homeUpcommingDropList && homeUpcommingDropList.length ? homeUpcommingDropList.map((card: any, index: number) => {
                                            return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                                                <div className='card-section'>
                                                    {card && card.length ? card.map((item: any) => {
                                                        return <div className='card' key={item.id}>
                                                            <div className='image-box'>
                                                                <img src={item.filename} className='card-image' alt='product' />
                                                            </div>
                                                            <div className='dropping-date-time'>
                                                                <div className='calendar-left'>
                                                                    <div className='box'>
                                                                        {getDropStartDate(item.sale_start_date)}
                                                                    </div>
                                                                </div>
                                                                <div className='days-right'>
                                                                    <h3 className='title'>{item.title}</h3>
                                                                    <p className='days'>{daysDiff(item.sale_start_date)} {daysDiff(item.sale_start_date) > MagicNumber.ONE ? 'days' : 'day'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }) : ''}
                                                </div>
                                            </div>;
                                        }) : ''}
                                    </div>
                                    {homeUpcommingListCount > MagicNumber.THREE ? <> <a className='carousel-control-prev' href='#destinationcontrols1' role='button' data-slide='prev'>
                                        <span className='carousel-prev-icon' aria-hidden='true'>
                                            prev
                                            <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                                        </span>
                                        <span className='sr-only'>Preview</span>
                                    </a>
                                        <a className='carousel-control-next' href='#destinationcontrols1' role='button' data-slide='next'>
                                            <span className='carousel-next-icon' aria-hidden='true'>
                                                next
                                                <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                                            </span>
                                            <span className='sr-only'>Next</span>
                                        </a></> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : ''}
            <div className='product-carousel-section' ref={myRef}>
                <div className='product-container'>
                    {/* to be work on it ------------------####################--------------- */}
                    {/* <div className='head-product'>
            <p className='pg-product'>THE DROP</p>
            <h2 className='pg-title'>Five artifacts from apivotal moment in Rock history.</h2>
          </div> */}
                    <div className='product-carousel-container'>
                        <div className='product-section'>
                            <div className='carousel-section'>
                                <div id='destinationcontrols2' className='carousel slide' data-ride='carousel'>
                                    <div className='carousel-inner'>
                                        {homeDropProductList && homeDropProductList.length ? homeDropProductList.map((card: any, index: number) => {
                                            return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                                                <div className='card-section'>
                                                    {card && card.length ? card.map((item: any) => {
                                                        return <div className='card' key={item.id}>
                                                            <div className={`${!isStartDrop ? 'image-box hand' : 'image-box'}`}>
                                                                <img src={item.product_image} className='card-image' alt='product' />
                                                                {item.type === 'auction' ? <p className='item-text'>
                                                                    {remainingDate && (remainingDate).trim() !== '0' ? 'Bid Now' : 'Auction Completed'}
                                                                </p> : ''}
                                                                {item.type === 'sale' ? <p className='item-text'>
                                                                    {item.quantity ? <span>{item.quantity} available
                                                                    </span> : 'Sold Out'}
                                                                </p> : ''}
                                                            </div>
                                                            <p className='name-item'>{item.name}</p>
                                                        </div>;
                                                    }) : ''}
                                                </div>
                                            </div>
                                        }) : ''};
                                    </div>
                                    {homeDropProductListCount > MagicNumber.THREE ? <> <a className='carousel-control-prev' href='#destinationcontrols2' role='button' data-slide='prev'>
                                        <span className='carousel-prev-icon' aria-hidden='true'>
                                            prev
                                            <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                                        </span>
                                        <span className='sr-only'>Preview</span>
                                    </a>
                                        <a className='carousel-control-next' href='#destinationcontrols2' role='button' data-slide='next'>
                                            <span className='carousel-next-icon' aria-hidden='true'>
                                                next
                                                <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                                            </span>
                                            <span className='sr-only'>Next</span>
                                        </a></> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='drop-start-section'>
                {dropData ? <DropTime data={dropData}
                    status={remainingDate && (remainingDate).trim() !== '0' ? true : false}
                    handleNotifyMe={handleNotifyMe}
                    executeScroll={executeScroll}
                /> : ''}
            </div>
            <div className='share-moment-section'>
                <div className='share-moment-container' onClick={() => copyUrl()}>
                    <h2 className='moment-title'>Share a moment in collecting history.</h2>
                    <div className='media-section'>
                        <div className='media-line'>
                            <div className='social-icon'>
                                <div className='icon-box twitter-icon' onClick={() => copyUrl()}>
                                    <img src={TWITTER_ICON} className='icon-image' alt='twitter' />
                                </div>
                                <div className='icon-box insta-icon' onClick={() => copyUrl()}>
                                    <img src={INSTAGRAM_ICON} className='icon-image' alt='instagram' />
                                </div>
                                <div className='icon-box facebook-icon' onClick={() => copyUrl()}>
                                    <img src={FACEBOOK_ICON} className='icon-image' alt='facebook' />
                                </div>
                                <div className='icon-box snapchat-icon' onClick={() => copyUrl()}>
                                    <img src={SNAPCHAT_ICON} className='icon-image' alt='snapchat' />
                                </div>
                                <div className='icon-box tiktok-icon' onClick={() => copyUrl()}>
                                    <img src={TIKTOK_ICON} className='icon-image' alt='tiktok' />
                                </div>
                                <div className='icon-box link-icon' onClick={() => copyUrl()}>
                                    <img src={LINK_ICON} className='icon-image' alt='link' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-collector-section'>
                {homeDrop && homeDrop.drop_content && homeDrop.drop_content.length ? <div className='collector-head-bg'>
                    <div className='logo'>
                        <img src={OOD_LOGO} className='company-logo' alt='logo' />
                    </div>
                    <div className='contain-detail'>
                        <div className='detail-left'>
                            <div className='image-left'>
                                <div className='image-box'>
                                    <img src={homeDrop.drop_content[0].featureImagePath ? homeDrop.drop_content[0].featureImagePath : ''} className='collector-image' alt='collector' />
                                </div>
                            </div>
                        </div>
                        <div className='detail-right'>
                            <p className='intro-text'>{homeDrop.drop_content[0]?.caption}</p>
                            <h3 className='title-text'>{homeDrop.drop_content[0]?.heading}</h3>
                        </div>
                    </div>
                </div> : ''}
                {homeDrop && homeDrop.drop_content && homeDrop.drop_content.length ? <div className='collector-container'>
                    <div className='about-collector'>
                        <div className='pg-height'>
                            <p className='collector-pg'>{homeDrop.drop_content[0]?.about}</p>
                        </div>
                    </div>
                    <div className='digital-content-section'>
                        {homeDrop.drop_content[0]?.media_desc ? <h3 className='head-digital'>{homeDrop.drop_content[0]?.media_desc}</h3> : ''}
                        <div className='video-section'>
                            {homeDrop.drop_content[0]?.medias && homeDrop.drop_content[0]?.medias.length && homeDrop.drop_content[0]?.medias.map((mediaElelment: any) => {
                                return (
                                    <>
                                        {mediaElelment.type === 'image' && mediaElelment.fullPath ? <div className='video-box'>
                                            <img src={mediaElelment.fullPath} className='media-file' alt='media' />
                                        </div> : mediaElelment.fullPath ? <div className='video-box'>
                                            <FeedItemVideo videoURL={`${base}${mediaElelment.filepath}`} />
                                        </div> : ''}
                                    </>
                                );
                            })}
                        </div>
                        {homeDrop.drop_content[0]?.quote ? <div className='quotes-section'>
                            <div className='quote-start'>
                                <img src={QUOTE_START} className='quote-image' alt='quote' />
                            </div>
                            <div className='quote-text'>
                                <p className='pg-text'>{homeDrop.drop_content[0]?.quote}</p>
                                {homeDrop.drop_content[0]?.quote_by ? <span>{homeDrop.drop_content[0]?.quote_by}</span> : ''}
                            </div>
                            <div className='quote-end'>
                                <img src={QUOTE_END} className='quote-image' alt='quote' />
                            </div>
                        </div> : ''}
                        <div className='more-about-collector'>
                            <h3 className='about-title'>
                                {homeDrop.drop_content[0]?.collector_image}
                            </h3>
                            {homeDrop.drop_content[0].imageUrl1Path || homeDrop.drop_content[0].imageUrl2Path || homeDrop.drop_content[0].imageUrl3Path ||
                                homeDrop.drop_content[0].imageUrl4Path || homeDrop.drop_content[0].imageUrl5Path || homeDrop.drop_content[0].imageUrl6Path ? <div className='more-collector-image'>
                                {/* for 1 image start*/}
                                {homeDrop.drop_content[0].imageUrl1Path && !homeDrop.drop_content[0].imageUrl2Path && !homeDrop.drop_content[0].imageUrl3Path &&
                                    !homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image1'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 1 image end */}
                                {/* for 2 image start*/}
                                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && !homeDrop.drop_content[0].imageUrl3Path &&
                                    !homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image2'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 2 image end */}
                                {/* for 3 image start*/}
                                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                                    !homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image3'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container center-container3'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl3Path ? homeDrop.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 3 image end */}
                                {/* for 4 image start*/}
                                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                                    homeDrop.drop_content[0].imageUrl4Path && !homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image4'>
                                    <div className='box-container'>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container center-container4'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl3Path ? homeDrop.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl4Path ? homeDrop.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 4 image end */}
                                {/* for 5 image start*/}
                                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                                    homeDrop.drop_content[0].imageUrl4Path && homeDrop.drop_content[0].imageUrl5Path && !homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image5'>
                                    <div className='box-container'>
                                        <div className='image-container container5'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl3Path ? homeDrop.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container container5'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl4Path ? homeDrop.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl5Path ? homeDrop.drop_content[0].imageUrl5Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 5 image end */}

                                {/* for 6 image start */}
                                {homeDrop.drop_content[0].imageUrl1Path && homeDrop.drop_content[0].imageUrl2Path && homeDrop.drop_content[0].imageUrl3Path &&
                                    homeDrop.drop_content[0].imageUrl4Path && homeDrop.drop_content[0].imageUrl5Path && homeDrop.drop_content[0].imageUrl6Path ? <div className='box-outer image6'>
                                    <div className='box-container'>
                                        <div className='image-container small-image'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl1Path ? homeDrop.drop_content[0].imageUrl1Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl2Path ? homeDrop.drop_content[0].imageUrl2Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container big-image'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl3Path ? homeDrop.drop_content[0].imageUrl3Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='box-container'>
                                        <div className='image-container small-image'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl4Path ? homeDrop.drop_content[0].imageUrl4Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl5Path ? homeDrop.drop_content[0].imageUrl5Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                        <div className='image-container big-image'>
                                            <div className='images-box'>
                                                <img src={homeDrop.drop_content[0].imageUrl6Path ? homeDrop.drop_content[0].imageUrl6Path : COLLECTOR_PL} className='collector-img' alt='collector' />
                                            </div>
                                        </div>
                                    </div>
                                </div> : ''}
                                {/* for 6 image end */}
                            </div> : ''}
                        </div>
                    </div>
                </div> : ''}
                {homeDrop && homeDrop.drop_content && homeDrop.drop_content.length && (homeDrop.drop_content[0].collector_msg || homeDrop.drop_content[0].signatureUrlPath) ? <div className='collector-memory-section'>
                    <div className='memory-container'>
                        {homeDrop.drop_content[0].collector_msg ? <p className='memory-text'>{homeDrop.drop_content[0]?.collector_msg}</p> : ''}
                        {homeDrop.drop_content[0].signatureUrlPath ? <div className='signature-text'>
                            <img src={homeDrop.drop_content[0].signatureUrlPath ? homeDrop.drop_content[0].signatureUrlPath : ''} className='sign-image' alt='sign' />
                        </div> : ''}
                    </div>
                </div> : ''}
                {homeUpcommingListCount && remainingDate && (remainingDate).trim() !== '0' ? <div className='product-carousel-section droppingsoon-carousel-section'>
                    <div className='product-container'>
                        <div className='head-product'>
                            <h2 className='pg-title'>Dropping Soon</h2>
                        </div>
                        <div className='product-carousel-container'>
                            <div className='product-section'>
                                <div className='carousel-section'>
                                    <div id='destinationcontrols3' className='carousel slide' data-ride='carousel'>
                                        <div className='carousel-inner'>
                                            {homeUpcommingDropList && homeUpcommingDropList.length ? homeUpcommingDropList.map((card: any, index: number) => {
                                                return <div key={index} className={`${index === 0 ? 'carousel-item active' : 'carousel-item'}`}>
                                                    <div className='card-section'>
                                                        {card && card.length ? card.map((item: any) => {
                                                            return <div className='card' key={item.id}>
                                                                <div className='image-box'>
                                                                    <img src={item.filename} className='card-image' alt='product' />
                                                                </div>
                                                                <div className='dropping-date-time'>
                                                                    <div className='calendar-left'>
                                                                        <div className='box'>
                                                                            {getDropStartDate(item.sale_start_date)}
                                                                        </div>
                                                                    </div>
                                                                    <div className='days-right'>
                                                                        <h3 className='title'>{item.title}</h3>
                                                                        <p className='days'>{daysDiff(item.sale_start_date)} {daysDiff(item.sale_start_date) > MagicNumber.ONE ? 'days' : 'day'}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }) : ''};
                                                    </div>
                                                </div>
                                            }) : ''};
                                        </div>
                                        {homeUpcommingListCount > MagicNumber.THREE ? <> <a className='carousel-control-prev' href='#destinationcontrols3' role='button' data-slide='prev'>
                                            <span className='carousel-prev-icon' aria-hidden='true'>
                                                prev
                                                <img src={PREV_ICON} alt='arrow' className='arrow-img' />
                                            </span>
                                            <span className='sr-only'>Preview</span>
                                        </a>
                                            <a className='carousel-control-next' href='#destinationcontrols3' role='button' data-slide='next'>
                                                <span className='carousel-next-icon' aria-hidden='true'>
                                                    next
                                                    <img src={NEXT_ICON} alt='arrow' className='arrow-img' />
                                                </span>
                                                <span className='sr-only'>Next</span>
                                            </a></> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''}
                <div className='collector-workflow-section'>
                    <div className='workflow-container'>
                        <div className='head-section'>
                            <div className='logo'>
                                <img src={LOGO} className='logo-company' alt='logo' />
                            </div>
                            <h2 className='head-title'>For collectors, by collectors.</h2>
                        </div>
                        <div className='detail-container'>
                            <div className='workflow-detail'>
                                <h3>How it works</h3>
                                <div className='working-flow'>
                                    <div className='flow-pg'>Items are directly sourced and tagged
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Drop begins
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Items are purchased via FIAT
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Confirmations sent to purchasers
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Custom shipping arranged
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg'>Items arrive
                                        <p className='round-black'></p>
                                    </div>
                                    <div className='flow-pg last-flow-pg'>Purchasers keep items or resell on OOD
                                        <p className='round-black'></p>
                                    </div>
                                </div>
                            </div>
                            <div className='workflow-image'>
                                <img src={COLLECTOR_PRODUCT} className='product-collector-img' alt='collector' />
                            </div>
                        </div>
                    </div>
                </div>
                {homeDropFaqList && homeDropFaqList.length ? <DropFaqList list={homeDropFaqList} /> : ''}
                <div className='drop-start-section drop-start-step2'>
                    {dropData ? <DropTime data={dropData}
                        status={remainingDate && (remainingDate).trim() !== '0' ? true : false}
                        handleNotifyMe={handleNotifyMe}
                        executeScroll={executeScroll}
                    /> : ''}
                </div>
            </div>
            <NotifyModal openModal={openModal}
                isOpen={isModalOpen}
                handleNotifyUser={handleNotifyUser}
                heading='Get notified as soon as drop is available.'
            />
        </>
    );
});

export default wrapperComponent(true, true, true, true)(PreviewDrop);

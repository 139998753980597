import { types } from 'mobx-state-tree';

export const MediaModel = types.model({
  entity_type: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
  type: types.maybeNull(types.string)
});

export const WishlistData = types.model({
  id: types.maybeNull(types.number),
  medias: types.optional(types.array(MediaModel), []),
  product_id: types.maybeNull(types.number),
  product_sku: types.maybeNull(types.string),
  product_slug: types.maybeNull(types.string),
});

import './contactUs.scss';
import Cookies from 'js-cookie';
import ContactUsInput from '../../Molecules/contactUsInput/ContactUsInput';
import { useFormik } from 'formik';
import { MagicNumber } from '../../../../constants';
import { successToast, errorToast } from '../../Atoms/Toast/Toast';
import { useStores } from '../../../../models';
import { validateContactForm } from '../../../../constants/validationScheme';

function ContactUs() {
  let userData = JSON.parse(Cookies.get('user_data')!);
  let firstName = userData.firstname;
  let lastName = userData.lastname;
  var fullName = firstName + ' ' + lastName;
  var userEmail = userData.email;
  const rootStore = useStores();
  const { createTicket } = rootStore.contactUsStore;

  const initialValues = {
    name: fullName,
    email: userEmail,
    subject: '',
    phone: '',
    reason: '',
  };

  const onSubmit = (values: any, { resetForm }: any) => {
    let formData = JSON.stringify({
      ticket: {
        subject: values.subject,
        comment: { body: values.reason },
        requester: {
          locale_id: MagicNumber.ONE,
          name: values.name,
          email: values.email,
        },
        custom_fields: values.phone
          ? [
              {
                id: MagicNumber.CONTACT_PHONE_CUSTOM_FIELD_ID,
                value: JSON.stringify(values.phone),
              },
            ]
          : [],
      },
    });

    createTicket(formData).then((res) => {
      if (res.status) {
        errorToast('Error in creating ticket');
      } else {
        successToast('Your query has been sent to ood team successfully!');
        resetForm();
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validateContactForm,
    onSubmit,
  });

  const nameInput = {
    mainClass: 'name-input',
    inputType: 'text',
    inputValue: formik.values.name,
    handleChange: formik.handleChange,
    inputName: 'name',
    labelName: 'Name',
    errorMessage: formik.errors.name,
    handleBlur: formik.handleBlur,
    checkBlur: formik.touched.name,
  };
  const emailInput = {
    mainClass: 'email-input',
    inputType: 'email',
    inputValue: formik.values.email,
    handleChange: formik.handleChange,
    inputName: 'email',
    labelName: 'Email',
    errorMessage: formik.errors.email,
    handleBlur: formik.handleBlur,
    checkBlur: formik.touched.email,
  };
  const subjectInput = {
    mainClass: 'subject-input',
    inputType: 'text',
    inputValue: formik.values.subject,
    handleChange: formik.handleChange,
    inputName: 'subject',
    labelName: 'Subject',
    errorMessage: formik.errors.subject,
    handleBlur: formik.handleBlur,
    checkBlur: formik.touched.subject,
  };
  const phoneInput = {
    mainClass: 'number-input',
    inputType: 'number',
    inputValue: formik.values.phone,
    handleChange: formik.handleChange,
    inputName: 'phone',
    labelName: 'Contact Number (Optional)',
    errorMessage: formik.errors.phone,
    handleBlur: formik.handleBlur,
    checkBlur: formik.touched.phone,
  };

  return (
    <>
      <h2 className='contactUs-heading'>Contact Us</h2>
      <form className='contactUs-form' onSubmit={formik.handleSubmit}>
        <ContactUsInput props={nameInput} />
        <ContactUsInput props={emailInput} />
        <ContactUsInput props={subjectInput} />
        <ContactUsInput props={phoneInput} />
        <div className='reason-input'>
          <textarea
            name='reason'
            value={formik.values.reason}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          <label
            className={`label-textarea ${
              formik.values.reason ? 'label-top' : ''
            }`}
          >
            Reason
          </label>
          {formik.touched.reason && formik.errors.reason ? (
            <div className='error textarea-error'>{formik.errors.reason}</div>
          ) : (
            <div className='error textarea-error'></div>
          )}
        </div>
        <button className='form-submit' type='submit'>
          SUBMIT
        </button>
      </form>
    </>
  );
}

export default ContactUs;

import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import wrapperComponent from '../../Templates/wrapperComponent';
import './product.scss';
import { MagicNumber, PAGE_ROUTES, PLACEHOLDER, USER_ID } from '../../../../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStores } from '../../../../models';
import { ProductSkeleton } from './ProductSkeleton';
import { toJS } from 'mobx';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { NoCollectorsFound } from '../../Molecules/emptyScreens/EmptyScreens';

type IProps = {
  isLoading: boolean;
};

const ProductCollectors = observer(function ProductCollectors({ isLoading }: IProps) {
  const [loader, setLoader] = useState<boolean>(false);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const rootStore = useStores();
  const { getCollectorList, homeCollectorData, currentPage, myCollectorTotalPage, replaceCollectors } = rootStore.collectionStore;
  const componentWillUnmount = useRef(false);

  useEffect(() => {
    fetchCollectors();
  }, []);

  const fetchMoreData = async () => {
    if (myCollectorTotalPage > currentPage) {
      setLoadMore(true);
      await getCollectorList(currentPage + MagicNumber.ONE, MagicNumber.FIFTEEN, isLoading);
      setLoadMore(false);
    }
  };

  const fetchCollectors = async () => {
    setLoader(true);
    await getCollectorList(currentPage, MagicNumber.FIFTEEN, isLoading);
    setLoader(false);
  };

  const getUserPath = (userId: string) => {
    const userID = Cookies.get(USER_ID);
    return `${PAGE_ROUTES.PROFILE}${userId !== userID ? `/${userId}` : ''}`;
  };
  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
      replaceCollectors();
    }
  }, []);

  const splitProdcuts = () => {
    let a = homeCollectorData.slice(); //Copy array so that the original is not modified
    let i = 0;
    let result: Array<[] | any> = [];
    while (a.length) {
      //Create array if needed
      if (typeof result[i] == 'undefined') {
        result[i] = [];
      }
      if (a) result[i].push(a.shift());
      i++;
      i = (i == 4) ? 0 : i; //Wrap around chunk selector
    }
    return result;
  };

  return (<>
    {!loader && <InfiniteScroll
      dataLength={homeCollectorData.length}
      next={fetchMoreData}
      hasMore={true}
      loader={< h4 ></h4>} >
      <div className='image-row'>
        {splitProdcuts().map((collectors: any) => {
          return (
            <div className='container-half'>
              {collectors && collectors.length ? collectors.map((user: any) => {
                return (
                  <Link
                    to={getUserPath(user.id)}
                  >
                    <div className='image-box'>
                      <img src={user && user.profile_image}
                        alt='' className='image-product'
                        onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />
                    </div>
                  </Link>
                );
              }) : ''}
            </div>);
        })}
      </div>
    </InfiniteScroll >
    }
    {homeCollectorData && homeCollectorData.length || loader ? '' : <NoCollectorsFound />}
    {(loader || loadMore) && <ProductSkeleton />}
  </>
  );
});


export default wrapperComponent(true, false, true)(ProductCollectors);

import { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import wrapperComponent from '../../Templates/wrapperComponent';
import { Collections } from './collections/Collections';
import { Collectors } from './collectors/Collectors';
import { HomeSkeleton } from './HomeSkeleton';
import { DELIVERY, GAURANTEE, LEFT_ARROW, RIGHT_ARROW, SUPPORT, PLACEHOLDER } from '../../../../constants/image';
import './Home.scss';
import { useStores } from '../../../../models';
import { MagicNumber } from '../../../../constants';

export interface productList {
  id: number,
  slug: any,
  category_name: string,
  productList: Array<any>,
  description: string
}

const Home = observer(function Home() {
  const history = useHistory();
  const redirectToProduct = (e: any, id: number) => {
    history.push({
      pathname: `/${e.slug || e.name}`,
      state: { id: e.id, name: e.name },
    });
  };

  const rootStore = useStores();
  const { replaceCollectors } = rootStore.collectionStore;
  const { getNotificationBadge } = rootStore.loginStore;
  const [loader, setLoader] = useState(true);

  const fetchProductData = async () => {
    setLoader(true);
    await rootStore.homeStore.fetchCategoryHome();
    setLoader(false);
    await getNotificationBadge();
  };

  const redirectToProductDetails = (productId: any, list: productList) => {
    history.push({
      pathname: `products/${productId}`,
      state: { id: list.id, name: list.category_name },
    });
  };

  useEffect(() => {
    fetchProductData();
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      replaceCollectors();
    };
  }, []);

  return (
    <>
      <div className='brand-collector-section'>
        <div className='collector-container'>
          <Collectors />
          <Collections />
        </div>
      </div>
      {loader || rootStore.homeStore.isLoading ? (
        <>
          <HomeSkeleton skeletonBg='sports-section' />
          <HomeSkeleton skeletonBg='order-category white-bg' />
          <HomeSkeleton skeletonBg='sports-section' />
          <HomeSkeleton skeletonBg='order-category white-bg' />
        </>
      ) : (<>
        {rootStore.homeStore.productListAllHome && rootStore.homeStore.productListAllHome.length ? rootStore.homeStore.productListAllHome.map((product: productList, keys: number) => {
          return (
            <>
              {product.productList.length ? <div key={keys} className={keys % MagicNumber.TWO == MagicNumber.ZERO ? 'category-main-section sports-section' : 'category-main-section order-category white-bg'} >
                <div className='category-container'>
                  {keys % MagicNumber.TWO == MagicNumber.ZERO ? <> <div className='section-category'>
                    <div className='detail-box'>
                      <h3 className='title-text'>{product && product.category_name}</h3>
                      <p className='title-detail'>{product && product.description}</p>
                      <div className='view-button'>
                        <button type='button' onClick={() => redirectToProduct(product, product && product.id)} className='btn outline-primary'>View All</button>
                      </div>
                    </div>
                  </div> <div className='section-category carousel-section-category'>
                      <div className='category-carousel'>
                        <div id={`sportscontrol${keys}`} className='carousel slide' data-ride='carousel'>
                          <div className='carousel-inner'>
                            {product && product.productList && product.productList.length ? product.productList.map((list: any, key: number) => {
                              return (
                                <> {list && list.first.filepath !== '' ? <div key={key} className={key === 0 ? 'carousel-item active' : 'carousel-item'}>
                                  <div className='carousel-box'>
                                    <div className='product-item-left'>
                                      <div className='product-item' onClick={() => redirectToProductDetails(list.first.id, product)} >
                                        <img src={list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />
                                        {list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                      </div>
                                    </div>
                                    <div className='product-item-right'>
                                      <div className='item-container'>
                                        <div className='product-item' onClick={() => redirectToProductDetails(list.second.id || list.first.id, product)}>
                                          {list.second.type !== 'video' ? <img src={list.second.filepath || (list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath)} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} /> : <img src={list.second.filepathThumb || list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />}
                                          {list.second.filepath !== '' && list.second.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : list.second.productType !== 'sale' && list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                        </div>
                                        <div className='small-product-item'>
                                          <div className='product-item' onClick={() => redirectToProductDetails(list.third.id || list.first.id, product)}>
                                            {list.third.type !== 'video' ? <img src={list.third.filepath || (list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath)} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} /> : <img src={list.third.filepathThumb || list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />}
                                            {list.third.filepath !== '' && list.third.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : list.third.productType !== 'sale' && list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                          </div>
                                          <div className='product-item' onClick={() => redirectToProductDetails(list.fourth.id || list.first.id, product)}>
                                            {list.fourth.type !== 'video' ? <img src={list.fourth.filepath || (list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath)} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} /> : <img src={list.fourth.filepathThumb || list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />}
                                            {list.fourth.filepath !== '' && list.fourth.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : list.fourth.productType !== 'auction' && list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> : ''}
                                </>
                              );
                            }) : ''}
                          </div>
                          {product.productList[MagicNumber.ONE].first.filepath !== '' ? <a className='carousel-control-prev' href={`#sportscontrol${keys}`} role='button' data-slide='prev'>
                            <img src={LEFT_ARROW} alt='arrow' className='icon-image' />
                            <span className='sr-only'>Previous</span>
                          </a> : ''}
                          {product.productList[MagicNumber.ONE].first.filepath !== '' ? <a className='carousel-control-next' href={`#sportscontrol${keys}`} role='button' data-slide='next'>
                            <img src={RIGHT_ARROW} alt='arrow' className='icon-image' />
                            <span className='sr-only'>Next</span>
                          </a> : ''}
                        </div>
                      </div>
                    </div> </> : <>
                    <div className='section-category carousel-section-category'>
                      <div className='category-carousel'>
                        <div id={`sportscontrol${keys}`} className='carousel slide' data-ride='carousel'>
                          <div className='carousel-inner'>
                            {product && product.productList && product.productList.length ? product.productList.map((list: any, key: number) => {
                              return (
                                <> {list && list.first.filepath !== '' ? <div key={key} className={key === 0 ? 'carousel-item active' : 'carousel-item'}>
                                  <div className='carousel-box'>
                                    <div className='product-item-left' onClick={() => redirectToProductDetails(list.first.id, product)}>
                                      <div className='product-item'>
                                        <img src={list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />
                                        {list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                      </div>
                                    </div>
                                    <div className='product-item-right'>
                                      <div className='item-container'>
                                        <div className='product-item' onClick={() => redirectToProductDetails(list.second.id || list.first.id, product)}>
                                          {list.second.type !== 'video' ? <img src={list.second.filepath || (list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath)} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} /> : <img src={list.second.filepathThumb || list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />}
                                          {list.second.filepath !== '' && list.second.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : list.second.productType !== 'sale' && list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                        </div>
                                        <div className='small-product-item'>
                                          <div className='product-item' onClick={() => redirectToProductDetails(list.third.id || list.first.id, product)}>
                                            {list.third.type !== 'video' ? <img src={list.third.filepath || (list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath)} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} /> : <img src={list.third.filepathThumb || list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />}
                                            {list.third.filepath !== '' && list.third.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : list.third.productType !== 'sale' && list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                          </div>
                                          <div className='product-item' onClick={() => redirectToProductDetails(list.fourth.id || list.first.id, product)}>
                                            {list.fourth.type !== 'video' ? <img src={list.fourth.filepath || (list.first.type === 'video' ? list.first.filepathThumb : list.first.filepath)} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} /> : <img src={list.fourth.filepathThumb || list.first.filepath} alt='product' className='product-image' onError={(e: any) => (e.target.onerror = null, e.target.src = PLACEHOLDER)} />}
                                            {list.fourth.filepath !== '' && list.fourth.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : list.fourth.productType !== 'auction' && list.first.productType === 'auction' ? <p className='sale-auction-box'><span className='auction-text'>Bid Now</span></p> : ''}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> : ''}
                                </>
                              );
                            }) : ''}
                          </div>
                          <a className='carousel-control-prev' href={`#sportscontrol${keys}`} role='button' data-slide='prev'>
                            <img src={LEFT_ARROW} alt='arrow' className='icon-image' />
                            <span className='sr-only'>Previous</span>
                          </a>
                          <a className='carousel-control-next' href={`#sportscontrol${keys}`} role='button' data-slide='next'>
                            <img src={RIGHT_ARROW} alt='arrow' className='icon-image' />
                            <span className='sr-only'>Next</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='section-category'>
                      <div className='detail-box'>
                        <h3 className='title-text'>{product && product.category_name}</h3>
                        <p className='title-detail'>{product && product.description}</p>
                        <div className='view-button'>
                          <button type='button' onClick={() => redirectToProduct(product, product && product.id)} className='btn outline-primary'>View All</button>
                        </div>
                      </div>
                    </div>
                  </>}
                </div>
              </div>
                : ''}</>
          );
        }) : ''}
      </>
      )}

      <div className='support-section'>
        <div className='support-container'>
          <div className='divider-line'></div>
          <div className='support-box-section'>
            <div className='support-box'>
              <div className='support-icon'>
                <img src={DELIVERY} alt='support' className='delivery-image' />
              </div>
              <h3 className='support-title'>Fast and Free Delivery</h3>
              <p className='detail-text'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            </div>
            <div className='support-box'>
              <div className='support-icon'>
                <img src={SUPPORT} alt='support' className='support-image' />
              </div>
              <h3 className='support-title'>24/7 Customer Support</h3>
              <p className='detail-text'>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea</p>
            </div>
            <div className='support-box'>
              <div className='support-icon'>
                <img src={GAURANTEE} alt='support' className='gaurantee-image' />
              </div>
              <h3 className='support-title'>Money Back Guarantee</h3>
              <p className='detail-text'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default wrapperComponent(false, true)(Home);

import { SOLD_ICON } from '../../../../../../constants/image';
import FeedListing from '../../../feedListing/FeedListing';
import { FeedGroup } from '../../../feedListing/FeedListingUtil';

export const Posts = () => {
  return (
    <FeedListing
      feedGroup={FeedGroup.user}
      filtersDisabled
      postCreationDisabled
      titleDisabled
      style={{ paddingTop: '0', paddingBottom: '100px' }}
    />
  )
};

import React, { useEffect, useState, useRef } from 'react';
import { DigitalContent, Image, Row, ConditionRow } from '../../Atoms';
import { MagicNumber, contentToggleTitle, accordianTab, LOCK_ICON, INFO_HELP, DISCOUNT_CODE_OVERLAY, USER_ID, DIGITAL_CONTENT_TYPE } from '../../../../constants';
import './AccordionCard.scss';
import DigitalContentModel from '../../Atoms/Modal/digitalContentModel';
import Cookies from 'js-cookie';
import { DigitalContentView } from '../modal/DigitalContentView';

type IProps = {
  title: string;
  data?: any;
  isList?: boolean;
  target?: string,
  isHtml?: boolean;
  show?: string;
  isCollapse?: boolean;
  from?: string;
  productUserId?: number;
  isSoldOut?: boolean;
};

export const AccordionCard = ({ title, data, show, target, isHtml, isCollapse, productUserId, from, isSoldOut }: IProps) => {
  const ref: any = useRef(null);
  const [hideContent, setHideContent] = useState<boolean>(false);
  const [contentShow, setContentShow] = useState<boolean>(false);
  const [toggleMain, setMainToggle] = useState(false);
  const [showPopup, togglePopup] = useState(false);

  const [htmlContentShow, setHtmlContentShow] = useState<boolean>(false);
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const [discountCode, setDiscountValue] = useState<string>();
  const analytics = require('react-segment');

  function createMarkup(data: any) {
    return { __html: data };
  }
  const { ZERO } = MagicNumber;

  const contentHideShow = () => {
    setContentShow(!contentShow);
  };

  const showMoreContent = () => {
    analytics.default.track('Product Page - Show More Content');
  };

  const htmlContentHideShow = () => {
    showMoreContent();
    setHtmlContentShow(!htmlContentShow);
  };

  const contentShowMoretoggle = () => {
    const elementHeight = ref && ref.current && ref.current.clientHeight;
    elementHeight >= MagicNumber.ONE_TWENTY && setShowMoreButton(true);
  };

  useEffect(() => {
    contentShowMoretoggle();
    const user_id = Number(Cookies.get(USER_ID));
    if (user_id) {
      setUserId(user_id);
    }
  }, []);

  useEffect(() => {
    if (target === 'digitalcontent') {
      const { images, audios, documents, videos } = data;
      if (images.length === ZERO && audios.length === ZERO &&
        documents.length === ZERO && videos.length === ZERO)
        setHideContent(true);
    }
    if (target === accordianTab.CREATER_MESSAGE || target === accordianTab.PRODUCT_STORY) {
      if (!data) setHideContent(true);
    }
  }, [target]);

  const handleLockModalOpen = () => {
    setMainToggle(true);
  };

  const showDiscountModal = (data: any) => {
    if (isSoldOut || productUserId !== userId) {
      return;
    }
    setDiscountValue(data);
    togglePopup(!showPopup);
  };

  const getCardData = () => {
    if (target === 'digitalcontent')
      return (<div className='digital-content-section'>
        {data && data.discount_code !== '' && data && data.discount_code ?
          <div className='content-box' onClick={() => showDiscountModal(data.discount_code)} style={{ backgroundImage: `url(${DISCOUNT_CODE_OVERLAY})` }}>
            {productUserId !== userId || isSoldOut ? <div className='lock-icon' onClick={() => handleLockModalOpen()}>
              <Image src={LOCK_ICON} className='lock-image' alt='lock' />
            </div> : ''}
          </div > : ''}
        <DigitalContent data={data.images} productUserId={productUserId} code={data && data.discount_code} from={from} isSoldOut={isSoldOut} />
        <DigitalContent data={data.audios} productUserId={productUserId} code={data && data.discount_code} from={from} isSoldOut={isSoldOut} />
        <DigitalContent data={data.documents} productUserId={productUserId} code={data && data.discount_code} from={from} isSoldOut={isSoldOut} />
        <DigitalContent data={data.videos} productUserId={productUserId} fileType='video' code={data && data.discount_code} from={from} isSoldOut={isSoldOut} />
        <DigitalContentModel
          show={toggleMain}
          subHeading='Unlock Digital Content'
          description='You have to buy the product to unlock the digital content.'
          setToggle={() => setMainToggle(!toggleMain)}
        />
        <DigitalContentView
          isOpen={showPopup}
          openModal={() => { showDiscountModal(''); }}
          itemData={discountCode}
          itemType={DIGITAL_CONTENT_TYPE.DIGITAL_CODE}
        />
      </div>);
    else if (target === 'details')

      return (<>
        {(data.attribute_data[0]?.name || data.attribute_data[0]?.value) &&
          data.attribute_data.map((e: any) => {
            return (
              <Row
                data={e.value}
                title={e.name}
              />
            );
          })
        }
        <Row data={data.material} title='Material' />
        <ConditionRow condition={data.condition} conditionDescription={data.condition_description} />
        <Row data={`${data.weight} ${data.weight_unit}`} title='Weight' />
        <Row
          data={`${data.length} x ${data.width} x ${data.height} ${data.dimension_unit}`}
          title='Dimensions (L x W x H)' />
        <Row data={data.availability} title='Date' isDate />
        {data && data.sub_category && data.sub_category.length ? (
          <div className='detail-row'>
            <div className='item-detail'>Sub Category</div>
            <div className='item-info'>
              {data.sub_category.map((e: any) => {
                return (<>{e}<em className='separator'>,</em></>);
              })}
            </div>
          </div>
        ) : (<></>)}
      </>);
    else if (target === 'trackinfo')
      return (<>
        <Row data={data && data.manufacturerDate ? data && data.manufacturerDate : data && data.manufacturer_date} title='Date of manufacture' isDate />
        <Row data={data && data.manufacturer_country} title='Manufacturer Country' />
        <Row data={data && data.provenance} title='More Info' />
      </>);
    else if (target === 'factoryinfo')
      return (<>
        <Row data={data.manufacturer} title='Manufacturer' />
      </>);
    else if (target === accordianTab.CREATER_MESSAGE)
      return (<>
        {data && data.length <= MagicNumber.TWO_H ? (<p>{data}</p>)
          : (<>
            {!contentShow ? (<p>{data && data.substring(MagicNumber.ZERO, MagicNumber.TWO_H)}....
              <em className='show-less-btn' onClick={contentHideShow}>{contentToggleTitle.more}</em></p>)
              : (<p>{data}<em className='show-less-btn' onClick={contentHideShow}>{contentToggleTitle.less}</em></p>)}
          </>)
        }

      </>);
    else return data;
  };

  if (isCollapse) {
    if (hideContent) return <></>;
    return (
      <div className='detail-card'>
        <div className='product-header'>
          <h5>{title}</h5>
        </div>
        {isHtml ? <div className={showMoreButton ? 'show-more-btn' : ''}>
          <div ref={ref} className={`${htmlContentShow ? 'AllShow' : ''} product-body font-listing list-type`}
            dangerouslySetInnerHTML={createMarkup(data)}></div>
          <div className='show-more-btn-wrapper'>
            <span className='show-less-btn' onClick={htmlContentHideShow}>{htmlContentShow ? 'Show Less' : 'Show More'}</span></div>
        </div>
          : <div className='product-body'>{getCardData()}</div>}
      </div>
    );
  }else return (
    <div className='product-accordion'>
      <div className='accordion-section'>
        <div className='accordion'>
          <div id='accordion'>
            <div className='card'>
              <div className='card-header' id='headingproduct'>
                <h5 className='mb-0'>
                  <button className={`btn btn-link ${show ? '' : 'collapsed'}`}
                    data-toggle='collapse'
                    data-target={`#${target}`}
                    aria-expanded='true' aria-controls={target}>
                    {title}
                  </button>
                </h5>
              </div>
              <div id={target} className={`collapse ${show}`} aria-labelledby='headingproduct' data-parent='#accordion'>
                {isHtml ? <div className='card-body' dangerouslySetInnerHTML={createMarkup(data)} ></div>
                  : <><div className='card-body'>{getCardData()}</div></>}
              </div>
            </div >
          </div>
        </div>
      </div>
    </div >
  );
};

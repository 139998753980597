import { toJS } from 'mobx';
import { Instance, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { BidsModal, DetailModel, UserModal } from './user-entity';

export const UserStoreModal = types
  .model('UserStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, true),
    isBidsLoading: types.optional(types.boolean, true),
    userList: types.optional(types.maybeNull(UserModal), null),
    productBids: types.optional(types.maybeNull(BidsModal), null),
    bid: types.optional(types.string, ''),
    checkBid: types.optional(types.number, 0),
    totalUser: types.optional(types.number, 0),
    currentBidStep: types.optional(types.number, 2),
    userListTotalPage: types.optional(types.number, 0),
    highestBid: types.maybeNull(types.string),
    currentSearchTab: types.optional(types.string, ''),
    productItem: types.optional(types.maybeNull(DetailModel), null),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    searchUserList: flow(function* (searchText: string, page: number, limit: number) {
      self.state = 'pending';
      self.isLoading = true;
      try {
        const response = yield self.environment.api.get(`/search/users?search=${searchText}&page=${page}&limit=${limit}`);
        const { data } = response;
        if (data) {
          self.userList = data;
          self.totalUser = data.total;
          self.userListTotalPage = Math.ceil(data.total / limit);
        };
        self.state = 'done';
        self.isLoading = false;
        return data;
      } catch (error) {
        self.state = 'error';
        self.isLoading = false;
        throw error;
      }
    }),
    setBidCurrentStep(step: number) {
      self.currentBidStep = step;
    },
    addBid(first: number, second: number) {
      self.bid = (first + second).toString();
    },
    saveBid(first: number, second: number) {
      self.checkBid = first + second;
    },
    setHighestBid(bid: string | null) {
      self.highestBid = bid;
    },
    changeBid(bids: any) {
      self.bid = (bids).toString();
    },
    setSearchTab(tab: string) {
      self.currentSearchTab = tab;
    },
    async setProductItem(product: any) {
      self.productItem = product;
    },
    getAllBids: flow(function* (productId: any, page: number, limit: number, flag?: string) {
      self.state = 'pending';
      self.isLoading = true;
      if (!flag)
        self.isBidsLoading = true;
      try {
        const response = yield self.environment.api.get(`/bids/products/${productId}?page=${page}&limit=${limit}`);
        const { data } = response;
        self.productBids = data;
        self.state = 'done';
        self.isLoading = false;
        if (!flag)
          self.isBidsLoading = false;
      } catch (error) {
        self.state = 'error';
        if (!flag)
          self.isLoading = false;
        self.isBidsLoading = false;
        throw error;
      }
    }),
    postBids: flow(function* (bidData: any) {
      self.state = 'pending';
      self.isLoading = true;
      try {
        const response = yield self.environment.api.post(`/bids`, bidData);
        self.state = 'done';
        self.isLoading = false;
        return response;
      } catch (error) {
        self.state = 'error';
        self.isLoading = false;
        throw error;
      }
    }),
  }));

type UserStoreType = Instance<typeof UserStoreModal>;
export interface ProfileStore extends UserStoreType { }


import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Modal } from 'react-bootstrap';
import { Image } from './Image';
import { DIGITAL_CONTENT_TYPE, INFO_HELP, USER_ID } from './../../../constants';
import { LOCK_ICON, PDF_ICON, MUSIC_ICON, MagicNumber, FILETYPE } from '../../../constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import DigitalContentModel from './Modal/digitalContentModel';
import { DownloadPopup } from '../Molecules/downloadPopup/downloadPopup';
import Cookies from 'js-cookie';
import { DigitalContentView } from '../Molecules';
import { useHistory } from 'react-router-dom';

interface IProp {
    description: string;
    file: string;
    filePath: string;
    fileType?: string;
    filepathThumb?: string;
    from?: any;
    productUserId?: number;
    isSoldOut?: boolean;
}

export const DigitalContent = ({ data, fileType, code, productUserId, from, isSoldOut }: any) => {
    const [show, setShow] = useState(false);
    const [description, setDescription] = useState(false);
    const [toggleMain, setMainToggle] = useState(false);
    const [isDigitalContentView, toggleDigitalContentView] = useState(false);
    const [userId, setUserId] = useState<number>();
    const [itemType, setItemType] = useState<string>('');
    const [itemValue, setItemValue] = useState<string>('');
    const [isdownlodalModalOpen, opendownloadPopupModal] =
        useState<boolean>(false);
    const history = useHistory();

    const handleModalOpen = (e: any) => {
        if (e && e.description) {
            setDescription(e.description);
            setShow(true);
        }
    };

    const getIcon = (src: string) => {
        return <Image src={src} className='content-image' alt='content' />;
    };

    const handleLockModalOpen = () => {
        setMainToggle(true);
    };

    const handleUnLockModalOpen = (event: any, type: string, data: any) => {
        if (from) {
            opendownloadPopupModal(true);
        } else if (productUserId === userId) {
            if (event && event.target && event.target.id === 'digi1' || !event) {
                if (type === FILETYPE.JPG || type === FILETYPE.PNG || type === FILETYPE.JPEG) {
                    setItemType(DIGITAL_CONTENT_TYPE.IMAGE);
                    setItemValue(data.filePath);
                } else if (type === FILETYPE.MP3) {
                    setItemType(DIGITAL_CONTENT_TYPE.AUDIO);
                    setItemValue(data.filePath);
                } else if (type === FILETYPE.PDF) {
                    window.open(data.filePath, '_blank');
                    return;
                } else if (fileType === FILETYPE.VIDEO && data.filepathThumb) {
                    setItemType(DIGITAL_CONTENT_TYPE.VIDEO);
                    setItemValue(data.filePath);
                }
                toggleDigitalContentView(true);
            }
        } else {
            return true;
        }
    };

    const handlePopUp = (event: any) => {
        setMainToggle(false);
        if (event) {
            opendownloadPopupModal(true);
        }
    };

    const setToggleDigitalContentView = () => {
        toggleDigitalContentView(false);
    };

    useEffect(() => {
        const user_id = Number(Cookies.get(USER_ID));
        if (user_id) {
            setUserId(user_id);
        }
    }, []);

    return (<>{
        data.map((e: IProp) => {
            let typeVal = e.file.split('.'),
                type = typeVal[MagicNumber.ONE];
            return (
                <div className='content-box on-link' id='digi1' key={e.description} onClick={(event) => handleUnLockModalOpen(event, type, e)}>
                    {(fileType === FILETYPE.VIDEO && e.filepathThumb) &&
                        <Image src={e.filepathThumb} className='content-image' alt='content' />}
                    {(type === FILETYPE.PDF) && getIcon(PDF_ICON)}
                    {(type === FILETYPE.MP3) && getIcon(MUSIC_ICON)}
                    {(type === FILETYPE.JPG || type === FILETYPE.PNG || type === FILETYPE.JPEG) && getIcon(e.filePath)}
                    {(from !== 'scanned' && productUserId !== userId) || isSoldOut ? <div className='lock-icon' onClick={handleLockModalOpen}>
                        <Image src={LOCK_ICON} className='lock-image' alt='lock' />
                    </div> : ''}
                    <div className='info-box'></div>
                    {e.description && <div id='digi2' className='info-box' onClick={() => handleModalOpen(e)}>
                        <img src={INFO_HELP} className='info-help-icon' alt='info' />
                    </div>}
                </div >);
        })}
        <DigitalContentModel
            show={toggleMain}
            subHeading='Unlock Digital Content'
            description='You have to buy the product to unlock the digital content.'
            setToggle={(event) => handlePopUp(event)}
        />
        <Modal
            size='sm'
            show={show}
            oonHide={() => setShow(false)}
            aria-labelledby='modal-sm'
            centered
            dialogClassName='remove-modal-dialog modal-dialog-centered'
            contentClassName='remove-modal-content'
        >
            <Modal.Header>
                <Modal.Title id='example-modal-sizes-title-sm'>Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{description}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type='button' className='btn btn-blank remove btn-centered' onClick={() => setShow(false)}>ok</button>
            </Modal.Footer>
        </Modal>
        <DownloadPopup
            openModal={opendownloadPopupModal}
            isOpen={isdownlodalModalOpen}
        ></DownloadPopup>
        <DigitalContentView
            isOpen={isDigitalContentView}
            openModal={() => { setToggleDigitalContentView(); }}
            itemData={itemValue}
            itemType={itemType}
        />
    </>);
};


import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Link, useHistory ,useParams} from 'react-router-dom';
import AsyncSelect, { components } from 'react-select';
import Cookie from 'js-cookie';
import { useStores } from '../../../../models';
import { errorToast, FullPageLoader } from './../../Atoms';
import { SignInModal } from '../signInModal/SignInModal';
import { CompleteProfileModal } from '../completeProfileModal/completeProfileModal';
import { SignupModal } from '../signupModal/signupModal';
import {
  LOGO,
  SEARCH,
  NAV_NOTIFICATIO,
  NAV_CART,
  USER_ICON,
  NAV_SEARCH,
  MESSAGE_ICON,
  HEADER_CART,
  NAV_PROFILE,
  USER_ID,
  MENU_ICON_CLOSE,
  MagicNumber,
  PAGE_ROUTES,
  getFunctionalityBlockedMessage,
  FunctionalityType,
  UserTypeName,
  NO_NOTIFICATION,
  USER_PLACEHOLDER_SMALL,
} from '../../../../constants';
import './Header.scss';
import useDebounce from '../../../../services/useDebounce';
import { ChangePassword } from '../changePasswordModel/ChangePassword';
import { chatClient } from '../../../../utils/stream';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SettingSketelon } from '../../Pages/settings/SettingSketelon';
import Cookies from 'js-cookie';
export interface param {
  buy: string;
}
export const Header = observer(function Home() {
  const rootStore = useStores();
  const history = useHistory();
  const [userId, setUserId] = useState<string>('');
  const {
    fetchCategory,
    categories,
    searchProducts,
    clearSearch,
    allProductSearch,
    searchedValue,
    setSearchedValue
  } = rootStore.homeStore;
  const {
    isLoggedIn,
    logoutUser,
    refreshUser,
    isFunctionalityAllowed,
    getRoleTypeName,
    getNotification,
    notificationList,
    getNotificationBadge,
    badgeCount,
    fetchUserDetails
  } = rootStore.loginStore;
  const { getCartItem, cartItemList, setCurrentStep, addCartSubtotal, setBuy } =
    rootStore.cartStore;
  const [categoryId, setCategoryId] = useState<number | null>(null);
  const [isOpen, openModal] = useState<boolean>(false);
  const [isProfileOpen, openProfileModal] = useState<boolean>(false);
  const [isSignUpOpen, openSignUpModal] = useState<boolean>(false);
  const [isEmailBtn, disableEmailBtn] = useState<boolean>(false);
  const [cartClick, setCartClick] = useState<boolean>(false);
  const [messagesClick, setMessagesClick] = useState<boolean>(false);
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [isOpenSearch, openSearch] = useState<boolean>(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(true);
  const [isFeedOpen, setIsFeedOpen] = useState<boolean>(false);
  const [isOurStory, setIsOpenStory] = useState<boolean>(false);
  const [isMarketOpen, setIsMarketOpen] = useState<boolean>(false);
  const [isMessagesOpen, setIsMsgOpen] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [skeltonLoader, setSkeltonLoader] = useState<boolean>(false);
  const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
  const notificationCount = Cookie.get('count');
  const [count, setCount] = useState<any>(notificationCount);
  const params: param = useParams();
  const debouncedSearchTerm = useDebounce(searchedValue, MagicNumber.FIVE_H);
  const { setSearchTab } = rootStore.userStore;
  const analytics = require('react-segment');
  const [currentPageCount, setCurrentPageCount] = useState<number>(MagicNumber.ONE);
  const [allData, setNotificationList] = useState<any>([]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      searchedValue && searchProducts(searchedValue);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const listener = chatClient?.on((e) => {
      if (e.total_unread_count !== undefined) {
        setCount(e.total_unread_count);
      } else if (e.me && e.me.total_unread_count !== undefined) {
        setCount(e.me.total_unread_count);
      }
    });
    return () => {
      if (listener) {
        // listener.unsubscribe()
      }
    };
  }, []);

  useEffect(() => {
    fetchCategory();
    const user_id = Cookies.get('user_id') || '0';
    setUserId(user_id ? user_id : '0');
    getCategoryId(history.location);
    getSearchValue(history.location);
    getIsFeedOpen(history.location);
    getIsOurStory(history.location);
    getIsMarketOpen(history.location);
    getIsMsgOpen(history.location);
    getNotificationBadge();
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      getCategoryId(location);
      getSearchValue(location);
      getIsFeedOpen(location);
      getIsOurStory(history.location);
      getIsMarketOpen(location);
      getIsMsgOpen(location);
    });
  }, [history]);

  const getSearchValue = (loc: any) => {
    if (loc.state && loc.state.value) {
      setSelectedOption(loc.state);
      loc.state && setSearchedValue(loc.state.value);
    }
    if (!loc.state || (loc.state && !loc.state.value)) {
      setSelectedOption(null);
      setSearchedValue('');
    }
  };

  useEffect(() => {
    if (params.buy) {
      setBuy(true);
    } else {
      setBuy(false);
    }

    if (!isLoggedIn) return;

    isFunctionalityAllowed(FunctionalityType.AddToCart) &&
      getCartItem(Number(Cookie.get(USER_ID)));
    setIsNavCollapsed(true);
  }, [isLoggedIn]);

  useEffect(() => {
    const pathName: any = history.location.pathname.split('/');
    if (!pathName[MagicNumber.ONE] ||
      isCategorySlug(pathName[MagicNumber.ONE])) {
      setIsMenuVisible(true);
    }
    else {
      setIsMenuVisible(false);
    }
  }, []);

  const isCategorySlug = (slug: string) => {
    if (!categories)
      return false;

    for (let i = 0, length = categories.length; i < length; ++i) {
      if (categories[i].slug === slug)
        return true;
    }

    return false;
  };

  const getIsFeedOpen = (location: any) => {
    setIsFeedOpen(location.state && location.state.isFeedOpen);
  };
  const getIsOurStory = (location: any) => {
    setIsOpenStory(location.state && location.state.isOurStory);
  };
  const getIsMarketOpen = (location: any) => {
    setIsMarketOpen(location.state && location.state.isMarketOpen);
  };

  const getIsMsgOpen = (location: any) => {
    setIsMsgOpen(location.state && location.state.isMessagesOpen);
  };

  const getCategoryId = async (location: any) => {
    if (location.state) setCategoryId(location.state.id);
    else {
      await fetchCategory().then((res: any) => {
        const path = location.pathname.split('/');
        if (res && res.length) {
          res.forEach((element: any) => {
            if (element.slug === path[1]) setCategoryId(element.id);
          });
        }
      });
    }
  };

  const disableBtn = (status: boolean) => {
    disableEmailBtn(status);
  };

  const modalOpenOnCart = () => {
    setCartClick(true);
    openModal(true);
  };

  const openCart = () => {
    if (!isFunctionalityAllowed(FunctionalityType.AddToCart)) {
      errorToast(
        getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user)
      );
      return;
    }

    history.push(PAGE_ROUTES.CART);
  };
  const modalOpenOnMessages = () => {
    setMessagesClick(true);
    openModal(true);
  };
  const handleChange = (value: any) => {
    setSearchedValue(value);
    if (!value || value === '') {
      clearSearch();
    }
  };

  const handleOptionChange = (option: any) => {
    setSearchedValue(option.label);
    setSelectedOption(option);
    history.push({
      pathname: `/All`,
      search: `?search=${option.value}&tab=${option.subLabel === 'in Collectibles'
        ? 'collectibles'
        : option.subLabel === 'in Feeds'
          ? 'feeds'
          : 'collecters'
        }`,
      state: option,
    });
    setSearchTab(
      option.subLabel === 'in Collectibles'
        ? 'collectibles'
        : option.subLabel === 'in Feeds'
          ? 'feeds'
          : 'collecters'
    );
  };

  const Option = (props: any) => {
    return (
      <components.Option {...props}>
        <label>{props.data.label}</label>
        <div style={{ display: 'block', color: 'gray', fontSize: 12 }}>
          {props.data.subLabel}
        </div>
      </components.Option>
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {selectedOption && selectedOption.value && (
            <div className='search-clear'>
              <img
                src={MENU_ICON_CLOSE}
                onClick={() => onClear(false)}
                alt='search'
                className='close-icon'
              />
            </div>
          )}
        </components.DropdownIndicator>
      )
    );
  };

  const handleSearchChange = async (event: any, status?: boolean) => {
    if (event.keyCode === MagicNumber.THIRTEEN || status) {
      let value = status ? searchedValue : event.target.value;
      event.preventDefault();
      setSearchedValue(value);
      let option = {
        value: value,
        label: value,
        name: 'All',
      };
      setSelectedOption(option);
      history.push({
        pathname: `/All`,
        search: `?search=${option.value}&tab=${allProductSearch &&
          allProductSearch.length &&
          allProductSearch[MagicNumber.ZERO].subLabel === 'in Collectibles'
          ? 'collectibles'
          : allProductSearch &&
            allProductSearch.length &&
            allProductSearch[MagicNumber.ZERO].subLabel === 'in Feeds'
            ? 'feeds'
            : 'collecters'
          }`,
        state: option,
      });
    }
  };

  const onClear = (status: boolean = false) => {
    history.push('/');
    setSearchedValue('');
    setSelectedOption(null);
    openSearch(false);
    status && openSearch(!isOpenSearch);
  };

  const cartTap = () => {
    setCurrentStep(MagicNumber.ONE);
    addCartSubtotal(null, null, 0);
  };

  const handleOnMenuClose = () => {
    setSearchedValue(searchedValue);
  };

  const onSignUpClick = () => {
    openSignUpModal(true);
    analytics.default.track('Signup Started');
  };

  const onSignInClick = () => {
    openModal(true);
    analytics.default.track('Login Started');
    localStorage.clear();
  };

  const handleNotification = async () => {
    setSkeltonLoader(true);
    await getNotification().then((res: any) => {
      setNotificationList(toJS(res.results));
    }).catch((error) => {
      throw (error);
    });
    getNotificationBadge();
    setSkeltonLoader(false);
  };

  const getYears = (date: any) => {
    const datePast = new Date(date);
    const dateNow = new Date();
    const years = (dateNow.getFullYear() - datePast.getFullYear());
    return years;
  };

  const getMonth = (date: any) => {
    const datePast = new Date(date);
    const dateNow = new Date();
    let months = (dateNow.getFullYear() - datePast.getFullYear()) * MagicNumber.TWELVE;
    months -= datePast.getMonth();
    months += dateNow.getMonth();
    return months <= MagicNumber.ZERO ? MagicNumber.ZERO : months;
  };

  const calculateData = (date: string) => {  
    let datePast = new Date(date).getTime();
    let dateNow = new Date().getTime();
    let diffInMilliSeconds = Math.abs(dateNow - datePast) / MagicNumber.ONE_TH;
    const minutes = Math.floor(diffInMilliSeconds / MagicNumber.SIXTY) % MagicNumber.SIXTY;
    diffInMilliSeconds -= minutes * MagicNumber.SIXTY;
    const hours = Math.floor(diffInMilliSeconds / MagicNumber.THIRTY_SIX_TH) % MagicNumber.TWENTYFR;
    diffInMilliSeconds -= hours * MagicNumber.THIRTY_SIX_TH;
    const days = Math.floor(diffInMilliSeconds / MagicNumber.EIGHTY_SIX_FR_TH);
    diffInMilliSeconds -= days * MagicNumber.EIGHTY_SIX_FR_TH;
    const months = getMonth(date);
    const years = getYears(date);
    if (years) {
      return years + `${years === MagicNumber.ONE ? ' year ago' : ' years ago'}`;
    } else if (months) {
      return months + `${months === MagicNumber.ONE ? ' month ago' : ' months ago'}`;
    } else if (days) {
      return days + `${days === MagicNumber.ONE ? ' day ago' : ' days ago'}`;
    } else if (hours) {
      return hours + `${hours === MagicNumber.ONE ? ' hour ago' : ' hours ago'}`;
    } else if (minutes) {
      return minutes + `${minutes === MagicNumber.ONE ? ' min ago' : ' mins ago'}`;
    } else {
      return `few seconds ago`;
    }
  };

  const fetchMoreCollectionData = async () => {
    const totalCount = notificationList ? notificationList.page_total : 0;
    if (currentPageCount < totalCount) {
      setSkeltonLoader(true);
      await getNotification(currentPageCount + MagicNumber.ONE, MagicNumber.TEN).then((res: any) => {
        if (res) {
          const updatedList = [...allData, ...toJS(res.results)];
          setNotificationList(updatedList);
          setSkeltonLoader(false);
          setCurrentPageCount(currentPageCount + MagicNumber.ONE);
        }
      }).catch((error: any) => {
        errorToast(error);
      }).finally(() => {
        setLoader(false);
        setSkeltonLoader(false);
      });
    }
  };

  const handleClick = async (data: any) => {
    await fetchUserDetails(data.type_id).then((res: any) => {
      if (res.data.message == "This User doesn't exist!") 
      {
        errorToast('user is not available');
      }
    else{
    if (parseInt(data.type_id) === parseInt(userId)) {
      history.push('profile');
    } else {
      history.push(data.link);
    }
  }
});
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <div className='header-section'>
        <nav className='navbar navbar-expand-lg navbar-light ng-star-inserted'>
          <div className='nav-wrap'>
            <div className='left-header'>
              <label
                key='menu-btn'
                id='nav-btn'
                data-toggle='collapse'
                data-target='#navbarNav'
                aria-controls='navbarNav'
                aria-label='Toggle navigation'
                aria-expanded={!isNavCollapsed ? true : false}
                onClick={() => {
                  setIsNavCollapsed(false);
                }}
                className={`navbar-toggler menu-icon ${isNavCollapsed ? 'collapsed' : ''}`}
                // className='navbar-toggler menu-icon collapsed'
              >
                <span className='navicon'></span>
              </label>
              <div className='logo' onClick={() => history.push('/')}>
                <img src={LOGO} alt='logo' className='logo-image' />
              </div>
              <div className='search-section search-lg-device header-search-section'>
                <AsyncSelect
                  value={null}
                  blurInputOnSelect={false}
                  closeMenuOnSelect={false}
                  onKeyDown={handleSearchChange}
                  onChange={handleOptionChange}
                  onInputChange={handleChange}
                  inputValue={searchedValue}
                  defaultInputValue={searchedValue}
                  options={toJS(allProductSearch)}
                  onFocus={() => {
                    selectedOption && setSearchedValue(selectedOption.value);
                  }}
                  onMenuClose={handleOnMenuClose}
                  clearValue={true}
                  noOptionsMessage={(e) => {
                    return e.inputValue ? null : null;
                  }}
                  components={{
                    Option,
                    DropdownIndicator,
                    IndicatorSeparator: null,
                  }}
                  className='search-input'
                  classNamePrefix='react-select'
                  placeholder='Search collectibles, collect...'
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                />
                <button
                  type='button'
                  className='btn primary'
                  onClick={(e) => handleSearchChange(e, true)}
                >
                  <img src={SEARCH} alt='search' className='search-icon' />
                </button>
                <span className='header-search-icon-sm' />
              </div>
            </div>
            <div className='collapse-navbar-wrapper'>
              <div
                id='navbarNav'
                className={`${isNavCollapsed ? 'collapse' : ''
                  } navbar-collapse`}
              >
                <ul className='navbar-nav mobile-sidebar'>
                  <li className='nav-item'>
                    <Link data-toggle='collapse' data-target='.navbar-collapse.show'
                      className={`nav-link ${isMarketOpen ? 'link-active' : ''}`}
                      to={{
                        pathname: PAGE_ROUTES.MARKET_PLACE,
                        state: { isMarketOpen: true },
                      }}
                      replace
                    >
                      Marketplace
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link data-toggle='collapse' data-target='.navbar-collapse.show'
                      className={`nav-link ${isFeedOpen ? 'link-active' : ''}`}
                      to={{
                        pathname: PAGE_ROUTES.FEED_LISTING,
                        state: { isFeedOpen: true },
                      }}
                      replace
                    >
                      Feed
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link data-toggle='collapse' data-target='.navbar-collapse.show'
                      className={`nav-link ${isOurStory ? 'link-active' : ''}`}
                      to={{
                        pathname: PAGE_ROUTES.OurStory,
                        state: { isOurStory: true },
                      }}
                      replace
                    >
                      Our Story
                    </Link>
                  </li>
                  {!isLoggedIn && (
                    <li className='button-right'>
                      <button
                        type='button'
                        className='btn outline-primary'
                        onClick={() => onSignInClick()}
                      >
                        Sign In
                      </button>
                      <p className='slash'>/</p>
                      <button
                        type='button'
                        className='btn outline-primary'
                        onClick={() => onSignUpClick()}
                      >
                        Sign Up
                      </button>
                    </li>
                  )}
                </ul>
                {!isLoggedIn && (
                  <div className='button-right'>
                    <button
                      type='button'
                      className='btn primary'
                      onClick={() => onSignInClick()}
                    >
                      Sign In
                    </button>
                    <p className='slash'>/</p>
                    <button
                      type='button'
                      className='btn outline-primary'
                      onClick={() => onSignUpClick()}
                    >
                      Sign Up
                    </button>
                  </div>
                )}
              </div>
              {!isOpenSearch && (
                <ul
                  className={`navbar-navs mobile-category ${!isMenuVisible ? 'mobile-menu-hide' : ''
                    }`}
                >
                  {categories &&
                    categories.map((e: any) => (
                      <li
                        className={`nav-item ${categoryId === e.id && 'active'
                          }`}
                      >
                        <Link
                          className={`nav-link ${categoryId === e.id && 'link-active'
                            }`}
                          to={{
                            pathname: `${e.id === MagicNumber.ZERO
                              ? '/'
                              : `/${e.slug || e.name}`
                              }`,
                            state: { id: e.id, name: e.name },
                          }}
                          replace
                        >
                          {e.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              )}
              <div className='icon-section'>
                <div className='icon-box'>
                  <img
                    src={NAV_SEARCH}
                    className='icon-image'
                    alt='search'
                    onClick={() => openSearch(!isOpenSearch)}
                  />
                  <div className='search-section mobile-search'>
                    {isOpenSearch && (
                      <>
                        <span
                          onClick={() => openSearch(!isOpenSearch)}
                          className='icon icon-back'
                        />
                        <AsyncSelect
                          value={selectedOption}
                          onChange={handleOptionChange}
                          inputValue={searchedValue}
                          defaultInputValue={searchedValue}
                          blurInputOnSelect
                          closeMenuOnSelect={false}
                          onInputChange={handleChange}
                          onFocus={() => {
                            selectedOption &&
                              setSearchedValue(selectedOption.value);
                          }}
                          options={toJS(allProductSearch)}
                          noOptionsMessage={(e) => (e.inputValue ? null : null)}
                          components={{ Option }}
                          onKeyDown={handleSearchChange}
                          className='search-input'
                          classNamePrefix='react-select'
                          placeholder='Search collectibles, collections & collectors'
                        />
                        <div className='search-clear'>
                          <img
                            src={MENU_ICON_CLOSE}
                            onClick={() => onClear(true)}
                            alt='search'
                            className='close-icon'
                          />
                        </div>
                      </>
                    )}
                    <span className='header-search-icon-sm' />
                  </div>
                </div>
                {!isLoggedIn && (
                  <li className='nav-item'>
                    <div className='nav-link web-message-item'>
                      <button
                        type='button'
                        className='btn outline-primary'
                        onClick={() => {
                          modalOpenOnMessages();
                          localStorage.clear();
                        }}
                      >
                        <img
                          src={NAV_PROFILE}
                          className='message-image'
                          alt='search'
                        />
                      </button>
                    </div>
                  </li>
                )}

                {isLoggedIn && (
                  <li className='nav-item'>
                    <Link
                      className={`nav-link web-message-item ${isMessagesOpen ? 'link-active' : ''
                        }`}
                      to={{
                        pathname: PAGE_ROUTES.CHAT,
                        state: { isMessagesOpen: true },
                      }}
                      replace
                    >
                      <img
                        src={MESSAGE_ICON}
                        className='message-image'
                        alt='search'
                      />
                      {count > 0 ? (
                        <span className='message-item-count'>{count}</span>
                      ) : null}
                    </Link>
                  </li>
                )}
                {isLoggedIn && (
                  <div
                    className='icon-box'
                    onClick={() => handleNotification()}
                  >
                    <div className='notification-dropdown'>
                      <div className='dropdown'>
                        <button className='btn dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                          <img
                            src={NAV_NOTIFICATIO}
                            className='icon-image'
                            alt='notification1'
                          />
                          {badgeCount ? <span className='item-count' style={{ marginRight: '8px' }}>
                            {badgeCount}
                          </span> : ''}
                        </button>
                        <div className='dropdown-menu' id='scrollableDiv' aria-labelledby='dropdownMenuButton'>
                          <InfiniteScroll
                            dataLength={allData.length}
                            hasMore={true}
                            loader={<h4></h4>}
                            next={fetchMoreCollectionData}
                            scrollableTarget='scrollableDiv'
                          >
                            {allData && allData.length ? allData.map((data: any) => {
                              return (
                                <a className={`${data.read ? 'dropdown-item' : 'dropdown-item unread-item'
                                  }`}
                                  onClick={() => handleClick(data)}
                                >
                                  <div className='image-box'>
                                    <img src={`${data.image}?aspect_ration=1:1`} onError={(e: any) => (e.target.onerror = null, e.target.src = USER_PLACEHOLDER_SMALL)} className='notify-img' alt='notification' />
                                  </div>
                                  <div className='notify-title'>
                                    <p className='name'> <span className='detail'>{data.message}</span></p>
                                    <p className='time'>{calculateData(data.createdAt)}</p>
                                  </div>
                                </a>
                              );
                            }) : !skeltonLoader ? <div className='no-notification-page'>
                              <div className='notification-content'>
                                <img src={NO_NOTIFICATION} className='notify-image' alt='notify' />
                                <p className='notify-title'>No Notification</p>
                              </div>
                            </div> : ''}
                            {skeltonLoader ? <div style={{ paddingLeft: '20px' }}>
                              <SettingSketelon />
                            </div> : ''}
                          </InfiniteScroll>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='icon-box'>
                  {!isLoggedIn ? (
                    <img
                      src={HEADER_CART}
                      className='icon-image'
                      alt='cart'
                      onClick={modalOpenOnCart}
                    />
                  ) : (
                    <a onClick={openCart} role='button'>
                      <img
                        src={HEADER_CART}
                        onClick={cartTap}
                        className='icon-image'
                        alt='cart'
                      />
                      {cartItemList.length ? (
                        <span className='item-count'>
                          {cartItemList.length}
                        </span>
                      ) : null}
                    </a>
                  )}
                </div>
                {isLoggedIn && (
                  <div className='dropdown profile icon-box'>
                    <img
                      src={NAV_PROFILE}
                      className='icon-image dropdown-toggle'
                      id='dropdownMenuButton'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      alt='cart'
                    />
                    <div
                      className='dropdown-menu'
                      aria-labelledby='dropdownMenuButton'
                    >
                      <Link to='/profile' className='dropdown-item'>
                        My Profile
                      </Link>
                      <a
                        className='dropdown-item'
                        onClick={() => {
                          setShowChangePassword(true);
                        }}
                      >
                        Change Password
                      </a>
                      <a className='dropdown-item' onClick={() => logoutUser()}>
                        Sign Out
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
      <ChangePassword
        isShowing={showChangePassword}
        onHideCB={() => {
          setShowChangePassword(false);
        }}
      />
      <SignInModal
        openModal={openModal}
        isOpen={isOpen}
        disableBtn={disableBtn}
        openProfileModal={openProfileModal}
        openSignUpModal={openSignUpModal}
        cartClick={cartClick}
        messagesClick={messagesClick}
        closeCartClick={setCartClick}
        closeMessagesClick={setMessagesClick}
      />
      <CompleteProfileModal
        isOpen={isProfileOpen}
        openModal={openProfileModal}
        isEmailBtn={isEmailBtn}
      />
      <SignupModal openModal={openSignUpModal} isOpen={isSignUpOpen} />
    </>
  );
});

import { MagicNumber } from './../../../constants';
import { useStores } from '../../../models';
type IProps = {
  activeStep: number;
  setPrevState: any;
  buynow?: string | null
};

export const CheckoutSteps = ({ activeStep, setPrevState, buynow }: IProps) => {
  
  const rootStore = useStores();
  const {checkAddressStep } = rootStore.cartStore;

  const manageAddressStep = () => {
    checkAddressStep(false);
  };

  return (
    <div className='checkout-steps'>
      {!buynow && (activeStep === MagicNumber.ONE || activeStep === MagicNumber.TWO || activeStep === MagicNumber.THREE) ? (
        <span onClick={()=>{setPrevState(MagicNumber.ONE); manageAddressStep();}} className='active'>My Cart</span>
      ): !buynow && (<span>Cart</span>)}
      {(activeStep === MagicNumber.TWO || activeStep === MagicNumber.THREE) ? (
        <span onClick={()=>{setPrevState(MagicNumber.TWO); manageAddressStep(); }} className='active'>Address</span>
      ):(<span>Address</span>)}
      <span className={activeStep === MagicNumber.THREE ? 'active' : ''}>Payment</span>
    </div>
  );
};

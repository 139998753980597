import Loader from 'react-loader-spinner';

const LoaderSpin: React.FC<{}> = () => {
    return (
        <div className='sub-loader-main'>
            <div className='sub-loader'>
                <Loader
                    type='TailSpin'
                    color='#000000'
                    height={50}
                    width={100}
                />
            </div>
        </div>
    );
};

export default LoaderSpin;



export const ConfirmationInfos = {
  DELETE_POST: {
    title: 'Delete Post',
    description: 'Are you sure you want to delete this post?',
    confirmOptionName: 'Delete',
    cancelOptionName: 'Cancel'
  },
  DELETE_COMMENT: {
    title: 'Delete Comment',
    description: 'Are you sure you want to delete this comment?',
    confirmOptionName: 'Submit',
    cancelOptionName: 'Cancel'
  },
  DELETE_REPLY: {
    title: 'Delete Reply',
    description: 'Are you sure you want to delete this reply?',
    confirmOptionName: 'Submit',
    cancelOptionName: 'Cancel'
  },
  DISCARD_POST: {
    title: 'Discard Post',
    description: 'Are you sure you want to discard this post?',
    confirmOptionName: 'Discard',
    cancelOptionName: 'Cancel'
  },
  REPORT_POST: {
    title: 'Report Post',
    description: 'Are you sure you want to report this post?',
    confirmOptionName: 'Submit',
    cancelOptionName: 'Cancel'
  },
  REPORT_COMMENT: {
    title: 'Report Comment',
    description: 'Are you sure you want to report this comment?',
    confirmOptionName: 'Submit',
    cancelOptionName: 'Cancel'
  },
  REPORT_REPLY: {
    title: 'Report Reply',
    description: 'Are you sure you want to report this reply?',
    confirmOptionName: 'Submit',
    cancelOptionName: 'Cancel'
  },
  REPORT_CHAT: {
    title: 'Report Chat',
    description: 'Are you sure you want to report this chat?',
    confirmOptionName: 'Submit',
    cancelOptionName: 'Cancel'
  },
}

import Cookies from 'js-cookie';
import { Instance, types, flow } from 'mobx-state-tree';
import Cookie from 'js-cookie';
import { TOKEN, USER_ID, MagicNumber, USER_DATA, REFRESH_TOKEN, STREAM_TOKEN, HTTPSTATUSCODE, FunctionalityType, UserType, UserTypeName } from '../../constants';
import { withEnvironment } from '../extensions/with-environment';
import { ProfileModal } from './profile-entity';

export const ProfileStoreModal = types
  .model('ProfileStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, false),
    profileDetail: types.optional(types.frozen(ProfileModal), {}),
    userProfileDetail: types.optional(types.frozen(ProfileModal), {}),
    isUserDiscontinue: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getProfileDetail: flow(function* () {
      self.state = 'pending';
      self.isLoading = true;
      try {
        const response = yield self.environment.api.get('users/profile');
        const { data } = response;
        if (data) {
          Cookies.set('user_profile', data.profile_image);
          Cookies.set('user_data', data);
          Cookies.set('user_name', {
            firstname: data.firstname,
            lastname: data.lastname,
          });
        }
        self.profileDetail = data;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        if (error.code === MagicNumber.FOUR_H_ONE) {
          self.state = 'done';
        }
        self.state = 'error';
        self.isLoading = false;
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    updateUserData: flow(function* (formData) {
      self.state = 'pending';
      self.isLoading = true;
      try {
        const response = yield self.environment.api.put('users', formData);
        self.state = 'done';
        self.isLoading = false;
        if (response.response) {
          return response.response;
        } else {
          return response;
        }
      } catch (error) {
        self.state = 'error';
        self.isLoading = false;
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getUserProfile: flow(function* (id: any) {
      self.state = 'pending';
      self.isLoading = true;
      try {
        const response = yield self.environment.api.get(`users/${id}`);
        const { data } = response;
        if (data) {
          self.isUserDiscontinue = false;
        }
        self.userProfileDetail = data;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        if (error.code === HTTPSTATUSCODE.BAD_REQUEST) {
          self.isUserDiscontinue = true;
        }
        self.state = 'error';
        self.isLoading = false;
        throw error;
      }
    }),
    changeStatusUserDiscontinue() {
      self.isUserDiscontinue = false;
    }
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    changePassword: flow(function* (old_password: string, new_password: string, confirm_password: string) {
      self.state = 'pending';
      self.isLoading = true;
      try {
        const user_id: number = parseInt(Cookies.get(USER_ID) ?? '');
        const response = yield self.environment.api.post(`users/change-password`, {
          old_password,
          new_password,
          confirm_password,
          user_id
        });
        self.state = 'done';
        self.isLoading = false;
        if (response.response)
          return response.response.data.data;
        else throw response.data.message;
      } catch (error) {
        self.state = 'error';
        self.isLoading = false;
        throw error;
      }
    }),
  }));

type ProfileStoreType = Instance<typeof ProfileStoreModal>;
export interface ProfileStore extends ProfileStoreType { }


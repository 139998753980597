import VisibilitySensor from 'react-visibility-sensor';
import ReactPlayer from 'react-player';
import { useState } from 'react';

export const FeedItemVideo = (props: { videoURL: string | undefined }) => {

  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <div className='video-feed-section'>
      <div className='video-section'>
        <VisibilitySensor onChange={(isVisible) => setIsVisible(isVisible)}>
          <ReactPlayer
            url={props.videoURL}
            width='100%'
            height='100%'
            controls={true}
            muted={true}
            playing={isVisible}
            loop={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  disablePictureInPicture: true
                }
              }
            }}
          />
        </VisibilitySensor>
      </div>
    </div >
  );
};

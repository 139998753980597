import { Instance, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { SavedAddressModel, SavedCardItem } from './setting-entity';

export const SettingStoreModel = types
  .model('SettingStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, true),
    savedAddressList: types.optional(types.array(SavedAddressModel), []),
    savedCard: types.maybe(SavedCardItem),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    addShippingAddress: flow(function* (formData) {
      self.state = 'pending';
      try {
        yield self.environment.api.post(`addresses`, formData);
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    getShippingAddress: flow(function* (userId) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`addresses`);
        const { data } = response;
        const newData = data.filter(function (item: any) {
          return item.user_id === userId;
        });
        self.savedAddressList = newData;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    deleteShippingAddress: flow(function* (id) {
      self.state = 'pending';
      try {
        yield self.environment.api.delete(`addresses/${id}`);
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    updateShippingAddress: flow(function* (id, formData) {
      self.state = 'pending';
      try {
        yield self.environment.api.put(`addresses/${id}`, formData);
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    getSavedcard: flow(function* () {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`cards`);
        const { data } = response;
        self.savedCard = data;
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }));

type SettingStoreType = Instance<typeof SettingStoreModel>;
export interface HomeStore extends SettingStoreType {}


import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Cookie from 'js-cookie';
import * as Yup from 'yup';
import { validateShippingAddressForm, WHITE_CLOSE_ICON, MagicNumber} from './../../../../constants';
import { StateDropdown, Input, FieldError, ZipInput, PhoneInput } from './../../Atoms';
import CustomStripe from '../customStripe/CustomStripe';
import { useStores } from '../../../../models';
import { observer } from 'mobx-react-lite';
import './modal.scss';

type IProps = {
  openModal: any;
  isOpen: boolean;
  title: string;
  info: string;
  currentStep?: any
};

export const AddCard = observer(function AddCard({ openModal, isOpen, title, info, currentStep }: IProps) {
  const [loader, setLoader] = useState<boolean>(false);
  const [stateList, setStateList] = useState<any>([]);
  var UsaStates = require('usa-states').UsaStates;
  var usStates = new UsaStates();
  const rootStore = useStores();

  useEffect(() => {
    const options = usStates.states;
    const updateStateOptions = options.map((state: any) => ({
      label: state.name,
      value: state.abbreviation,
    }));
    setStateList(updateStateOptions);
  }, []);

  return (
    <>
      {loader ? <div className="loader-main">
        <div className="spinner-border" role="status">
          <span className="visually-hidden"></span>
        </div>
      </div> : ''}
      <Modal
        size="sm"
        show={isOpen}
        backdrop={false}
        onHide={() => openModal()}
        aria-labelledby="modal-sm"
        centered
        dialogClassName="add-card-modal-dialog theme-modal-dialog modal-dialog-centered"
        contentClassName="theme-modal-content add-card-content"
      >
        <Modal.Header>
          <div className="close-icon" onClick={() => openModal(false)}>
            <img src={WHITE_CLOSE_ICON} className="close-image" alt="close" />
          </div>
          <Modal.Title id="example-modal-sizes-title-sm">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-card-container">
          <CustomStripe info={info} currentStep={currentStep}/>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

import React, {useEffect, useState, FC} from 'react';
import './faq.scss';
import { useStores } from '../../../../models';
import FaqCard from '../../Molecules/faqCard/FaqCard';

const Faq = ()=> {
    const rootStore = useStores();
    const {getFaqList} = rootStore.staticPagesStore;
    const [faqList, setFaqList] = useState<any[]>([]);

    const fetchData = async() => {
        await getFaqList().then(res => setFaqList(res.data));
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <h2 className='faq-heading'>FAQ's</h2>
            <div className='container-faq'>
                {faqList.map((faq)=>{
                    return <FaqCard faq={faq}/>;
                })}
            </div>
        </>
    );
};

export default Faq;

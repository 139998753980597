import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Cookie from 'js-cookie';
import wrapperComponent from '../../Templates/wrapperComponent';
import './searchUser.scss';
import { COLLECTION_ICON, MagicNumber, UserType, USER_ID } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import { useStores } from '../../../../models';
import { errorToast, FullPageLoader } from '../../Atoms';
import { SearchUserSkeleton } from './SearchUserSkeleton';
import { ProductSkeleton } from '../products/ProductSkeleton';
import { NoCollectorsFound } from '../../Molecules/emptyScreens/EmptyScreens';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toJS } from 'mobx';

type IProps = {
  search: string;
};

const SearchUser = observer(function SearchUser({ search }: IProps) {

  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(false);
  const [currentPageCount, setCurrentPageCount] = useState<number>(MagicNumber.ONE);
  const [collectorList, setCollectorList] = useState<any>([]);
  const [searchItems, setSearchItems] = useState<any>('');

  const rootStore = useStores();
  const { searchUserList, userList, userListTotalPage } = rootStore.userStore;
  const { changeStatusUserDiscontinue, isUserDiscontinue } = rootStore.profileStore;

  const fetchUser = async (myParam: any) => {
    await searchUserList(myParam ? myParam : searchItems, MagicNumber.ONE, MagicNumber.TEN_FIVE).then((res) => {
      if (res && res.data && res.data.length) {
        const data = res.data;
        setCollectorList(toJS(data));
      }
    });
    setLoader(false);
  };

  const redirectToProfilePage = (profileUser: any) => {
    if (profileUser.role === UserType[UserType.community_manager]) {
      if (!profileUser.collection_id)
        return;

      history.push(`all?collection_id=${profileUser.collection_id}`);
      history.go(MagicNumber.ZERO);
    }

    const id = profileUser.id;
    const userId = Number(Cookie.get(USER_ID));
    isUserDiscontinue && changeStatusUserDiscontinue();
    history.push({
      pathname: userId === id ? `profile` : `profile/${id}`,
      state: { id: id },
    });
  };

  useEffect(() => {
    return history.listen((location) => {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('search');
      setLoader(true);
      if (myParam) {
        setSearchItems(myParam);
        fetchUser(myParam);
      }
      else {
        setSearchItems('');
        fetchUser('');
      }
    });
  }, [history, []]);

  const fetchMoreCollectionData = async () => {
    if (currentPageCount < userListTotalPage) {
      await searchUserList(searchItems, currentPageCount + MagicNumber.ONE, MagicNumber.TEN_FIVE).then(res => {
        if (res && res.data && res.data.length) {
          const data = userList && userList.data && userList.data.length ? userList.data : [];
          const updatedList = [...collectorList, ...toJS(data)];
          setCollectorList(updatedList);
          setCurrentPageCount(currentPageCount + MagicNumber.ONE);
        }
      }).catch(error => {
        errorToast(error);
      });
    }
  };

  useEffect(() => {
    setLoader(true);
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('search');
    setSearchItems(myParam);
    fetchUser(myParam);
  }, []);

  return (<>
    <div className='collector-preview'>
      <div className='collector-image-section'>
        <div className='image-margin'>
          <InfiniteScroll
            dataLength={(collectorList && collectorList.length) || 0}
            hasMore={true}
            loader={<p></p>}
            next={fetchMoreCollectionData}
          >
            <div className='image-row'>
              <div className='container-half'>
                {collectorList && collectorList.length ? collectorList.map((user: any) => {
                  return (
                    <div className='card' onClick={() => { redirectToProfilePage(user) }}>
                      <div className='image-box'>
                        <img src={user.profile_image} className='image-product' alt='product' />
                      </div>
                      <div className='card-footer'>
                        <h2 className='name'>{user.name}</h2>
                        <h2 className='id'>{user.username}</h2>
                        <div className='follow-detail'>
                          <div className='container-follow'>
                            <h3 className='active-status'>{user.followersCount}</h3>
                            <p className='status-text'>{user.followersCount > MagicNumber.ONE ? 'Followers' : 'Follower'}</p>
                          </div>
                          <div className='container-follow'>
                            <h3 className='active-status'>{user.followingCount}</h3>
                            <p className='status-text'>Following</p>
                          </div>
                          <div className='container-follow'>
                            <h3 className='active-status'>{user.collection_count ?? MagicNumber.ZERO}</h3>
                            <p className='status-text'>Collectibles</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) : (
                  <>
                    {loader ? (<SearchUserSkeleton />) : <NoCollectorsFound />}
                  </>
                )}
              </div>
            </div>
          </InfiniteScroll>
          {loader && <ProductSkeleton />}
        </div>
      </div>
    </div>
  </>
  );
});


export default wrapperComponent(true, false)(SearchUser);

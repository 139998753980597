import Skeleton from 'react-loading-skeleton';

export const OrderDetailSkeleton = () => {
  return (
    <div className='filled-cart'>
      <div className='cart-outer'>
        <div className='cart-items'>
          <div className='item'>
            <div className='order-details-page'>
              <div className='detail-head-info'>
                <div className='section-detail'>
                  <div className='name'>
                    <Skeleton width={80} height={20} />
                  </div>
                  <div className='info'>
                    <Skeleton width={150} height={20} />
                  </div>
                </div>
                <div className='section-detail'>
                  <div className='name'>
                    <Skeleton width={120} height={20} />
                  </div>
                  <div className='info'>
                    <Skeleton width={120} height={20} />
                  </div>
                </div>
              </div>
            </div>
            <div className='detail-container'>
              <div className='image-section'>
                <Skeleton className='product-image' />
              </div>
              <div className='detail-section'>
                <div className='text-section'>
                  <Skeleton className='about' width={100} height={25} />
                </div>
                <div className='text-section'>
                  <div className='item-condition'>
                    <span><Skeleton width={50} /></span>
                    <span><Skeleton width={50} /></span>
                    <span><Skeleton width={50} /></span>
                  </div>
                </div>
                <div className='text-section'>
                  <Skeleton className='quality-text' width={40} />
                </div>
                <div className='text-section'>
                  <Skeleton className='quality-text' width={100} />
                </div>
              </div>
            </div>
          </div>
          <div className='item'>
            <div className='order-details-page'>
              <div className='detail-head-info'>
                <Skeleton className='detail-heading' width={150} height={30} />
              </div>
              <div className='address-section'>
                <Skeleton className='full-info' width={100} height={20} />
              </div>
              <Skeleton width={150} height={18} />
            </div>
          </div>
          <div className='item'>
            <div className='order-details-page'>
              <div className='detail-head-info'>
                <Skeleton className='detail-heading' width={150} height={30} />
              </div>
            </div>
          </div>
        </div>
        <div className='detail-pay-right'>
          <div className='price-sum'>
            <h4> <Skeleton width={100} height={30} /></h4>
            <ul>
              <li>
                <p>
                  <Skeleton width={100} height={20} />
                </p>
                <span><Skeleton width={80} height={20} /></span>
              </li>
              <li>
                <p>
                  <Skeleton width={120} height={20} />
                </p>
                <span><Skeleton width={80} height={20} /></span>
              </li>
            </ul>
            <ul className='total-price'>
              <li className='amount-list'>
                <p>
                  <Skeleton width={100} height={20} />
                </p>
                <span><Skeleton width={80} height={20} /></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { Thread } from 'stream-chat-react';
import { MagicNumber } from '../../../../../../constants/MagicNumber';

import './MessagingThread.css';

import { CustomMessage, MessagingInput } from '../index';


const ThreadHeader = ({ closeThread, thread }) => {
  const getReplyCount = () => {
    if (!thread?.reply_count) return '';
    if (thread.reply_count === MagicNumber.ONE) return `${MagicNumber.ONE} 'reply'`;
    return `${thread.reply_count} Replies`;
  };

  return (
    <div className='custom-thread-header'>
      <div className='custom-thread-header__left'>
        <p className='custom-thread-header__left-title'>Thread</p>
        <p className='custom-thread-header__left-count'>{getReplyCount()}</p>
      </div>
    </div>
  );
};

const MessagingThread = () => {
  return (
    <Thread Message={CustomMessage} MessageInput={MessagingInput} ThreadHeader={ThreadHeader} />
  );
};

export default MessagingThread;

import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import wrapperComponent from '../../Templates/wrapperComponent';
import { useStores } from '../../../../models';
import { USER_ID, MagicNumber, BACK_BTN_ICON } from './../../../../constants';
import { AddCart } from './addCart/AddCart';
import { CheckoutSteps, errorToast } from './../../Atoms';
import { CartListing } from './cartListing/CartListing';
import { PriceSummery } from './priceSummary/PriceSummery';
import { CartShippingAddress } from './cartShippingAddress/CartShippingAddress';
import { CardPaymentDetail } from './cardPaymentDetail/CardPaymentDetail';
import { CartListingSkeleton } from './cartListing/CartListingSkeleton';
import { PriceSummarySkeleton } from './priceSummary/PriceSummarySkeleton';
import { OrderSummary } from './orderSummary/OrderSummary';
import './cart.scss';
import { useParams, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';

export interface param {
  buy: string;
}

const Cart = observer(function Settings() {
  const history = useHistory();
  const [shippingAddressList, setShippingAddressList] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [userVerified, setUserVerified] = useState<boolean>(false);
  const rootStore = useStores();
  const { cartItemList, cartSubtotal,
    cartItemCount, cartSubtotal_buy,
    setCurrentStep, currentStep, removeCoupons, getCartItem, cartItemCountBuy, setBuy, paymentCard, paymentCardInfo } = rootStore.cartStore;
  const { getShippingAddress, savedAddressList, isLoading } = rootStore.settingStore;
  const { profileDetail, getProfileDetail } = rootStore.profileStore;
  const params: param = useParams();
  const componentWillUnmount = useRef(false);

  const validateParams = async () => {
    if (params.buy && currentStep <= MagicNumber.TWO) {
      setCurrentStep(MagicNumber.TWO);
      if (!cartSubtotal_buy) {
        history.push('/');
      }
    }
  };

  const fetchCart = async () => {
    setLoader(true);
    await getShippingAddress(Number(Cookies.get(USER_ID)));
    await setShippingAddressList(savedAddressList);
    await getCartItem(Number(Cookies.get(USER_ID)));
    await getProfileDetail();
    setLoader(false);
  };

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (params.buy) {
      setBuy(true);
    } else {
      setBuy(false);
    }
    validateParams();
    fetchCart();
    return () => {
      if (componentWillUnmount.current) {
        removeCoupons();
      }
    };

  }, [savedAddressList, currentStep]);

  const nextStep = (event: any) => {
    if (currentStep + event === MagicNumber.THREE && !savedAddressList.length) {
      errorToast('Please add address for delivery');
      return;
    }
    setCurrentStep(currentStep + event);
  };
  const prevStep = (event: any) => {
    setCurrentStep(event);
  };

  return (
    <>
      <div className='cart-page-wrapper'>
        {cartItemList.length || params.buy ? (
          <div className='filled-cart'>
            <div className='back-row'>
              <div className='back-btn'>
                {/* <img src='/assets/images/back-arrow.png' alt='back' /> */}
                <img src={BACK_BTN_ICON} alt='back' />
                <span>Back</span>
              </div>
            </div>
            {currentStep <= MagicNumber.THREE ? (
              <div className='cart-header pb-3'>
                {!params.buy ? <h3>My Cart</h3> : <h3>Buy Now</h3>}
                <CheckoutSteps activeStep={currentStep} setPrevState={prevStep} buynow={params.buy} />
              </div>
            ) : (
              <div className='cart-header'>
                <h3>Order Summary</h3>
              </div>
            )}
            <div className='cart-outer'>
              <div className='cart-items 3'>
                {currentStep === MagicNumber.ONE && (
                  cartItemList.map((item: any) => {
                    return (
                      loader && !params.buy ? (
                        <CartListingSkeleton />
                      ) : (
                        <CartListing
                          cartData={item}
                        />
                      )
                    );
                  })
                )
                }
                {currentStep === MagicNumber.TWO ?
                  <CartShippingAddress />
                  : ''}
                {currentStep === MagicNumber.THREE && (
                  <CardPaymentDetail
                    setPrevState={prevStep}
                  />
                )}
                {currentStep === MagicNumber.FOUR && (
                  <OrderSummary
                    buynow={params.buy}
                    setPrevState={prevStep}
                    isLoader={loader}
                  />
                )}
              </div>
              <div className='detail-pay-right'>
                {/* {currentStep >= MagicNumber.THREE && (
                  loader ? (<ApplyCouponSkeleton />
                    ):(<ApplyCoupon />)
                )} */}
                {loader ? (<PriceSummarySkeleton />) : (
                  <PriceSummery
                    subTotal={cartSubtotal}
                    itemCount={!params.buy ? cartItemCount : cartItemCountBuy}
                    setNextStep={nextStep}
                    loader={loader}
                    hideActionButton={currentStep}
                    isUserVerified={profileDetail.verified}
                    email={profileDetail.email}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <AddCart />
        )
        }

      </div>
    </>
  );
});

export default wrapperComponent(true, false, true)(Cart);

import Skeleton from 'react-loading-skeleton';

export const WishlistSkeleton = () => {
  return (
    <>
        <div className='wishlist-page-skeleton'>
           <div className='wishlist-skeleton'>
               <Skeleton count={8} />
           </div>
        </div>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Switch,
  Route,
  BrowserRouter,
  useLocation,
  withRouter,
} from 'react-router-dom';
import { RootStore, RootStoreProvider, setupRootStore } from './models';
import { ProtectedRoutes, PublicRoutes } from './services/routes';
import { PAGE_ROUTES } from './constants';
import Home from './components/ui/Pages/home/Home';
import Products from './components/ui/Pages/products/Products';
import Details from './components/ui/Pages/detail/details';
import Profile from './components/ui/Pages/profile/Profile';
import Settings from './components/ui/Pages/settings/Settings';
import Cart from './components/ui/Pages/cart/Cart';
import Wishlist from './components/ui/Pages/wishlist/Wishlist';
import followimgAndFollowers from './components/ui/Pages/followimgAndFollowers/FollowimgAndFollowers';
import PageNotFound from './components/ui/Pages/pageNotFound/PageNotFound';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './App.scss';
import PrivacyAndPolicy from './components/ui/Pages/privacyAndPolicy/PrivacyAndPolicy';
import TermsAndConditions from './components/ui/Pages/termsAndConditions/TermsAndConditions';
import OrderDetail from './components/ui/Pages/orderDetail/OrderDetail';
import FeedListing from './components/ui/Pages/feedListing/FeedListing';
import Chat from './components/ui/Pages/chat/chat';
import FeedDetail from './components/ui/Pages/feedDetail/FeedDetail';
import searchProducts from './components/ui/Pages/searchProducts/searchProducts';
import Auction from './components/ui/Pages/auction/Auction';
import GuestContact from './components/ui/Pages/guestContact/GuestContact';
import ScannedProduct from './components/ui/Pages/detail/ScannedProduct';
import Cookies from 'js-cookie';
import { getTokens, onMessageListener } from './firebase';
import DropHome from './components/ui/Pages/home/DropHome';
import DropDetails from './components/ui/Pages/detail/DropDetails';
import PreviewDrop from './components/ui/Pages/home/PreviewDrop';
import GuestFAQ from './components/ui/Pages/guestFAQ/GuestFAQ';
import AdvanceTermsAndConditions from './components/ui/Pages/advanceTermsAndConditions/AdvanceTermsAndConditions';
import AboutUs from './components/ui/Pages/aboutUs/AboutUs';
import OurStory from './components/ui/Pages/our-story/OurStory';

toast.configure();
const analytics = require('react-segment');

function _ScrollToTop(props: any) {
  const { pathname } = useLocation();
  const userData = Cookies.get('user_data');

  useEffect(() => {
    if (userData && JSON.parse(userData).id) {
      let data = JSON.parse(userData);
      analytics.default.identify(data.id, {
        name: data.firstname,
        email: data.email
      });
    }
    window.scrollTo(0, 0);
  }, [pathname]);

  if (userData) {
    let data = JSON.parse(userData);
    analytics.default.track(pathname, {
      userId: data.id,
      event: pathname
    });
  } else {
    let data = (new Date).getTime();
    analytics.default.track(pathname, {
      userId: data,
      event: pathname
    });
  }
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

export const App = () => {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  const [isTokenFound, setTokenFound] = useState(false);
  // Kick off initial async loading actions, like loading fonts and RootStore
  useEffect(() => {
    (() => {
      setupRootStore().then(setRootStore);
    })();
  }, []);

  const handleFcmToken = async () => {
    // return;
    await getTokens();
  };


  useEffect(() => {
    handleFcmToken();
  }, []);

  if (!rootStore) return null;
  return (
    <>
      <BrowserRouter>
        <RootStoreProvider value={rootStore}>
          <ScrollToTop>
            <Switch>
              <PublicRoutes
                restricted={false}
                component={DropHome}
                path={PAGE_ROUTES.HOME}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={Products}
                path={PAGE_ROUTES.ART}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={Products}
                path={PAGE_ROUTES.MUSIC}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={Products}
                path={PAGE_ROUTES.SPORTS}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={Products}
                path={PAGE_ROUTES.MEDIA}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={Products}
                path={PAGE_ROUTES.MARKET_PLACE}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={searchProducts}
                path={PAGE_ROUTES.ALL}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={DropDetails}
                path={`${PAGE_ROUTES.PRODUCTS}:id`}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={DropDetails}
                path={`${PAGE_ROUTES.DROP}:id`}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={PreviewDrop}
                path={`${PAGE_ROUTES.DROP_PREVIEW}:id`}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={ScannedProduct}
                path={`${PAGE_ROUTES.PRODUCTS_SCAN}:id`}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={PrivacyAndPolicy}
                path={PAGE_ROUTES.PP}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={TermsAndConditions}
                path={PAGE_ROUTES.TC}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={AdvanceTermsAndConditions}
                path={PAGE_ROUTES.AC}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={followimgAndFollowers}
                path={`${PAGE_ROUTES.USER_PROFILE}:id`}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={FeedListing}
                path={PAGE_ROUTES.FEED_LISTING}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={FeedDetail}
                path={PAGE_ROUTES.FEED_DETAIL}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={GuestContact}
                path={PAGE_ROUTES.CONTACT}
                exact
              />
              <PublicRoutes
                restricted={false}
                component={GuestFAQ}
                path={PAGE_ROUTES.FAQ}
                exact
              />
              <ProtectedRoutes
                component={Settings}
                path={PAGE_ROUTES.SETTING}
                exact
              />
              <ProtectedRoutes
                component={Profile}
                path={PAGE_ROUTES.PROFILE}
                exact
              />
              <ProtectedRoutes
                component={Wishlist}
                path={PAGE_ROUTES.WISHLIST}
                exact
              />
              <ProtectedRoutes component={Cart} path={PAGE_ROUTES.CART} exact />
              <ProtectedRoutes
                component={Cart}
                path={`${PAGE_ROUTES.CART}/:buy`}
                exact
              />
              <ProtectedRoutes
                component={OrderDetail}
                path={PAGE_ROUTES.ORDER_DETAIL}
                exact
              />
              <ProtectedRoutes component={Chat} path={PAGE_ROUTES.CHAT} exact />
              <ProtectedRoutes
                component={Auction}
                path={PAGE_ROUTES.AUCTION}
                exact
              />
                 <PublicRoutes
                restricted={false}
                component={AboutUs}
                path={PAGE_ROUTES.About}
                exact
              />
                 <PublicRoutes
                restricted={false}
                component={OurStory}
                path={PAGE_ROUTES.OurStory}
                exact
              />
              <Route component={PageNotFound} />
            </Switch>
          </ScrollToTop>
        </RootStoreProvider>
      </BrowserRouter>
    </>
  );
};

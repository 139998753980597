import * as Yup from 'yup';
import FloatingLabel from 'floating-label-react';
import { MagicNumber, EMAIL, USERNAME } from '../../../constants';
import 'floating-label-react/styles.css';

type IProps = {
    id: string;
    name: string;
    placeholder?: string;
    type: string;
    value?: any;
    required?: boolean;
    className?: string;
    readOnly?: boolean;
    maxLength?: any;
    handleEnter?: (e: any) => void
    handleKeyPress?: (e: any) => void
    setFieldValue: (key: string, value: any) => void
    disabled?: boolean
    isRegister?: boolean;
    checkEmailExist?: (email: string, username: string) => void
};

export const Input = (props: IProps) => {
    let { setFieldValue, name, handleEnter, handleKeyPress, isRegister, checkEmailExist } = props;

    const validateEmailId = (e: string) => {
        if (name === 'username') {
            let schema = Yup.string().matches(/^[a-z0-9£]+$/i).min(MagicNumber.FOUR);
            if (checkEmailExist && schema.isValidSync(e))
                checkEmailExist('', e);
        } else {
            let schema = Yup.string().email();
            if (checkEmailExist && schema.isValidSync(e))
                checkEmailExist(e, '');
        };
    };
    return (
        <FloatingLabel
            onChange={evt => {
                if (isRegister && (name === EMAIL || name === USERNAME))
                    validateEmailId(evt.currentTarget.value);
                setFieldValue(name, evt.currentTarget.value);
            }}
            onKeyDown={handleEnter}
            onKeyPress={handleKeyPress}
            {...props} />
    );

};

export const ZipInput = (props: IProps) => {
    let { setFieldValue, name, handleEnter } = props;
    function isNumberChange(evt: any) {
        if (!evt.currentTarget.value || (/^\d+$/.test(evt.currentTarget.value))) {
            setFieldValue(name, evt.currentTarget.value);
        }
    }
    return (
        <FloatingLabel
            onChange={isNumberChange}
            onKeyDown={handleEnter}
            {...props}
        />
    );

};

export const PhoneInput = (props: IProps) => {
    let { setFieldValue, name, handleEnter } = props;
    function isNumberChange(evt: any) {
        if (!evt.currentTarget.value || (/^\d+$/.test(evt.currentTarget.value))) {
            setFieldValue(name, evt.currentTarget.value);
        }
    }
    return (
        <FloatingLabel
            onChange={isNumberChange}
            onKeyDown={handleEnter}
            {...props}
        />
    );

};

export const InputPrivate = (props: IProps) => {
    let { handleEnter, handleKeyPress, setFieldValue, name } = props;

    return (
        <FloatingLabel
            onChange={evt => {
                setFieldValue(name, evt.currentTarget.value);
            }}
            onKeyDown={handleEnter}
            onKeyPress={handleKeyPress}
            {...props} />
    );

};

import Skeleton from 'react-loading-skeleton';

export const PriceSummarySkeleton = () => {
    return (
        <div className="price-sum price-sum-skeleton">
            <h4><Skeleton /></h4>
            <ul>
                <li><Skeleton /> <span><Skeleton /></span></li>
                <li><Skeleton /><span><Skeleton /></span></li>
            </ul>
            <ul className="total-price">
                <li><Skeleton /><span><Skeleton /></span></li>
            </ul>
            <div className="card-submit">
                <Skeleton />
            </div>
        </div>
    )
};


import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Cookie from 'js-cookie';
import wrapperComponent from './../../Templates/wrapperComponent';
import { useStores } from '../../../../models';
import { ProfileSkeleton } from './ProfileSkeleton';
import { ProfileView } from './profileView/ProfileView';
import { ProfileTabs } from './profileTabs/ProfileTabs';
import { USER_ID } from './../../../../constants';
import './Profile.scss';


const Profile = observer(function Profile() {
  const rootStore = useStores();
  const [loader, setLoader] = useState<boolean>(false);
  const { getProfileDetail, changeStatusUserDiscontinue, profileDetail, isUserDiscontinue} = rootStore.profileStore;
  const { unfollowStatus, totalFollowCount, getUnfollowStatus, followUnfollowLoader } = rootStore.followerStore;


  const fetchProfileDetail = async () => {
    setLoader(true);
    await getProfileDetail();
    setLoader(false);
  }

  const checkProfileData = Object.keys(profileDetail);

  useEffect(() => {
    fetchProfileDetail();
    getUnfollowStatus(false);
    isUserDiscontinue && changeStatusUserDiscontinue();
  }, []);

  return (
    <>
      <div className="profile-container">
        <div className="container profile-head-section">
            {checkProfileData.length && !loader ? (<ProfileView
              data={profileDetail && profileDetail}
              followCount={profileDetail && profileDetail.followers}
              unfollowStatus={unfollowStatus}
              totalFollowCount={totalFollowCount}
              isLoading={followUnfollowLoader}
            />) : (<ProfileSkeleton /> )}
        </div>
        <div className="container">
          <ProfileTabs />
        </div>
      </div>
    </>
  );
});

export default wrapperComponent(true, true)(Profile);


import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { errorToast, ProfileImage } from './../../../Atoms';
import { useStores } from '../../../../../models';
import { EditProfile, FollowingList, FollowersList } from './../../../Molecules';
import {
  GOOGLE_ICON, APPLE_ICON, WISHLIST_ICON, SETTING_ICON, PROFILE_EDIT_MOOD,
  PAGE_ROUTES, MagicNumber, FunctionalityType, getFunctionalityBlockedMessage, UserTypeName, PLUS_PINK_ICON
} from '../../../../../constants';
import { AddBio } from '../../../Molecules/modal/AddBio';

type Iprops = {
  data: any;
  followCount: any;
  unfollowStatus: boolean;
  totalFollowCount: any;
  isLoading: boolean;
};

export const ProfileView = ({ data, followCount, unfollowStatus, totalFollowCount, isLoading }: Iprops) => {
  const rootStore = useStores();
  const history = useHistory();
  const { isFunctionalityAllowed, getRoleTypeName } = rootStore.loginStore;
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [followingModal, setFollowingModal] = useState<boolean>(false);
  const [followerModal, setFollowerModal] = useState<boolean>(false);
  const [bioModal, setBioModal] = useState<boolean>(false);
  const [editModalTitle, setEditModalTitle] = useState<string>('');
  const ProfileFollowersCount = parseInt(unfollowStatus ? totalFollowCount.followerCount : followCount.followerCount);
  const ProfileFollowingCount = parseInt(unfollowStatus ? totalFollowCount.followingCount : followCount.followingCount);
  const toggleEditProfile = () => {
    setEditModalTitle(PROFILE_EDIT_MOOD.CHANGE_USER_DATA);
    setEditProfile(!editProfile);
  };

  const toggleFollowingModal = () => {
    setFollowingModal(!followingModal);
  };
  const toggleFollowerModal = () => {
    setFollowerModal(!followerModal);
  };

  const toggleBioModal = (flag?: any) => {
    setBioModal(!bioModal);
  };

  const toggleEditImage = () => {
    setEditModalTitle(PROFILE_EDIT_MOOD.CHANGE_PROFILE);
    setEditProfile(!editProfile);
  };

  const onAccessingWishlist = () => {
    if (!isFunctionalityAllowed(FunctionalityType.AddToWishlist)) {
      errorToast(getFunctionalityBlockedMessage(getRoleTypeName(), UserTypeName.user));
      return;
    }

    history.push(PAGE_ROUTES.WISHLIST);
  };

  return (
    <>
      <div className='profile-inline step-1'>
        <div className='profile-pic'>
          <ProfileImage
            imageClassName='image-lg'
            data={data && data}
            changeImage={toggleEditImage}
          />
        </div>
        <div className='profile-content'>
          <div className='left-item'>
            <div className='name'>
              {data && data.firstname} {data && data.lastname}
              <span className='nameid'>{data && data.username}</span>
            </div>
            <div className='email'>
              {data && data.apple_id ? (
                <img
                  src={APPLE_ICON}
                  className='google-icon'
                  alt='apple_icon' />
              ) : (<></>)}
              {data && data.google_id ? (
                <img
                  src={GOOGLE_ICON}
                  className='google-icon'
                  alt='google_icon' />
              ) : (<></>)}
              {data && data.email}
            </div>
            <ul className='follow-content'>
              {isLoading ? (<Skeleton />) : (
                <>
                  {ProfileFollowersCount === MagicNumber.ZERO ? (
                    <li className='cursor-default'><span className='count'>
                      {ProfileFollowersCount}</span> Follower
                    </li>
                  ) : (
                    <li onClick={toggleFollowerModal}><span className='count'>
                      {ProfileFollowersCount}</span>
                      {ProfileFollowersCount > MagicNumber.ONE ? 'Followers' : 'Follower'}
                    </li>
                  )}
                  {ProfileFollowingCount === MagicNumber.ZERO ? (
                    <li className='cursor-default'><span className='count'>
                      {ProfileFollowingCount}</span> Following
                    </li>
                  ) : (
                    <li onClick={toggleFollowingModal}><span className='count'>
                      {ProfileFollowingCount}</span> Following
                    </li>
                  )}
                </>
              )}
            </ul>
            <div className='profile-bio'>
              {data.bio ? <p className='bio-text'>{data.bio ? `'${data.bio}'` : ''}</p> : <> <button type='button' className='btn secondary-btn' onClick={() => toggleBioModal()}>
                <img src={PLUS_PINK_ICON} className='plus-icon' alt='plus' />Add Bio</button> <p className='bio-text'>Add a short bio to tell people about yourself</p></>}
            </div>
          </div>
          <div className='right-item'>
            <div className='icons'>
              <a onClick={onAccessingWishlist}><img src={WISHLIST_ICON} alt='wishlist' /></a>
              <Link to={PAGE_ROUTES.SETTING} className='ml-4'><img src={SETTING_ICON} alt='setting' /></Link>
            </div>
            <button type='button' className='btn secondary-btn' onClick={toggleEditProfile}>Edit Profile</button>
          </div>
        </div>
      </div>
      {editProfile && <EditProfile
        openModal={() => { toggleEditProfile(); }}
        userData={data}
        editMood={editModalTitle}
        isOpen={editProfile}
      />}
      {followingModal &&
        <FollowingList
          openModal={() => { toggleFollowingModal(); }}
          title='Following'
          isOpen={followingModal}
          userId={data.id}
          followingMood={true}
        />
      }
      {followerModal &&
        <FollowersList
          openModal={() => { toggleFollowerModal(); }}
          title='Followers'
          isOpen={followerModal}
          userId={data.id}
          followersMood={true}
        />
      }
      {AddBio && <AddBio
        openModal={(flag: any) => { toggleBioModal(flag); }}
        userData={data}
        isOpen={bioModal} />}
    </>
  );
};


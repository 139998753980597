import Skeleton from 'react-loading-skeleton';
import {SKELETON_COUNT, MagicNumber} from './../../../../constants';

export const ProductSkeleton = () => {

    let skeletonListItems = [];
    for (var i = MagicNumber.ZERO; i < SKELETON_COUNT.PRODUCT; i++) {
        skeletonListItems.push(<Skeleton count={MagicNumber.ONE} />);
    }
    return (
        <div className='product-list-skeleton'>
            {skeletonListItems}
        </div>
    );
};

export const ProductFilterSkeleton = () => {

    let skeletonBoxItems = [];
    for (var i = MagicNumber.ZERO; i < SKELETON_COUNT.FILTER; i++) {
        skeletonBoxItems.push(<div className='btn'><Skeleton count={MagicNumber.ONE} /></div>);
    }

    return (
        <>
            <div className='category-box category-box-skeleton'>
                <div className='filter-section'>
                    <h2 className='title-head'><Skeleton count={MagicNumber.ONE} /></h2>
                    <h3 className='head-text'><Skeleton count={MagicNumber.ONE} /></h3>
                    <div className='filter-button-section'>
                        {skeletonBoxItems}
                    </div>
                </div>
            </div>
        </>
    );
};

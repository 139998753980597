import React, { useState, useEffect } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import S3FileUpload from 'react-s3';
import * as Yup from 'yup';
import { ProfileImage, Input, FieldError, Image, errorToast, FullPageLoader, successToast } from './../../Atoms';
import { editProfileValidation, HTTPSTATUSCODE, PROFILE_EDIT_MOOD, MagicNumber, profilePic, WHITE_CLOSE_ICON, OTP_SENT, UPLOAD_ICON, USER_DIR_NAME, REGION, S3_IMAGE_ERROR, PROFILE_ICON } from './../../../../constants';
import { useStores } from '../../../../models';
import { OtpModal } from '../../Molecules/otpModal/otpModal';
import './modal.scss';
type IProps = {
  openModal: (e: boolean) => void;
  isOpen?: boolean;
  userData: any;
  editMood: string;
};

export const EditProfile = ({ openModal, isOpen, userData, editMood }: IProps) => {
  const path = process.env.REACT_APP_ASSET_HOST;
  const [loader, setLoader] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [imageValue, setImage] = useState<any>();
  const [userProfileImage, setUserProfileImage] = useState<string>(userData.profile_image || path + profilePic[MagicNumber.ZERO]);
  const [isOtpModalOpen, openOtpModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [userBio, setBio] = useState<string>('');
  const rootStore = useStores();
  const { getS3Config } = rootStore.loginStore;
  const { updateUserData, getProfileDetail } = rootStore.profileStore;

  useEffect(() => {
    if (userData && userData.bio) {
      setBio(userData.bio);
    }
  }, []);

  const getInitialValue = () => {
    const { firstname, lastname, username, email, bio } = userData;
    return {
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      bio: bio,
    };
  };

  const handleFileSelected = (event: any, status: boolean) => {
    let url = status ? URL.createObjectURL(event.target.files[MagicNumber.ZERO]) : path + event;
    if (status) setImage(event.target.files[MagicNumber.ZERO]);
    setUserProfileImage(url);
    setSubmitDisabled(true);
  };

  const uploadUserImage = (values: any) => {
    setLoader(true);
    updateUserData(values).then(res => {
      if (res.data) {
        if (res.data.statusCode === HTTPSTATUSCODE.BAD_REQUEST){
          errorToast(res.data.message);
        }else {
          if (userData.email !== values.email) {
            setEmail(values.email);
            successToast(OTP_SENT);
            openOtpModal(true);
          }
          openModal(false);
          getProfileDetail();
        }
      }
      setLoader(false);
    });
  };

  const updateUserDetail = async (values: any) => {
    let image = userProfileImage.split('.net/');
    if (image.length === MagicNumber.TWO) {
      values.profile_image = image[MagicNumber.ONE];
      values.bio = userBio;
      uploadUserImage(values);
    } else fetchS3Config(values);
  };

  const fetchS3Config = async (values: any) => {
    setLoader(true);
    const s3Config = await getS3Config();
    const config = {
      bucketName: s3Config.data.data.s3.bucket_name,
      dirName: USER_DIR_NAME,
      region: REGION,
      accessKeyId: s3Config.data.data.s3.aws_access_key_id,
      secretAccessKey: s3Config.data.data.s3.aws_secret_access_key
    };
    await S3FileUpload.uploadFile(imageValue, config)
      .then((data: any) => {
        values.profile_image = data.key;
        uploadUserImage(values);
      })
      .catch((err: any) => {
        errorToast(S3_IMAGE_ERROR);
      });
  };

  const handleInput = (event: any) => {
    setBio(event.target.value);
    setSubmitDisabled(true);
  };

  const handleKeyPress = (evt: any) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === MagicNumber.THIRTY_TWO && evt.target.value == '') {
      evt.preventDefault();
    }
    return true;
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <Modal
        size='sm'
        show={isOpen}
        onHide={openModal}
        backdrop={false}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='theme-modal-dialog modal-dialog-centered'
        contentClassName='theme-modal-content'
      >
        <Modal.Header>
          <div className='close-icon' onClick={() => openModal(false)}>
            <img src={WHITE_CLOSE_ICON} className='close-image' alt='close' />
          </div>
          <Modal.Title id='example-modal-sizes-title-sm' className='edit-profile-modal-title'>
            {editMood}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={getInitialValue()}
            onSubmit={async values => {
              await updateUserDetail(values);
            }}
            validationSchema={editProfileValidation}
            render={({ handleSubmit, values, setFieldValue, dirty }) => (
              <Form>
                <div className='form-group profile-edit-image'>
                  <div className='profile-upload-box'>
                    <div className='profile-image-wrapper image-sm'>
                      <img
                        src={`${userProfileImage}?aspect_ratio=1:1`}
                        alt='upload'
                        className='placeholder-upload'
                        onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                      />
                    </div>
                    <Dropdown>
                      <Dropdown.Toggle variant='success' id='dropdown-basic'>
                        <span className={`icon icon-edit`} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className='upload-icon tooltip'>
                          <div className='upload-background center'>
                            <div className='upload-inside'>
                              <div className='upload-box'>
                                <input type='file' className='file-input' accept='image/x-png,image/jpg,image/jpeg' onChange={(e) => handleFileSelected(e, true)} id='input_upload' />
                                <div className='upload-inside'>
                                  <div className='icon-upload'>
                                    <Image src={UPLOAD_ICON} className='upload-image' alt='upload' />
                                    <p className='text-upload'>Upload picture</p>
                                  </div>
                                </div>
                              </div>
                              <div className='profile-text'>Default Profile Images</div>
                              <div className='default-image-container'>
                                <div className='default-image-section'>
                                  {profilePic.map((ele: any, index: number) =>
                                    <div className='image-box' key={index}>
                                      <Dropdown.Item><img src={path + ele} onClick={() => handleFileSelected(ele, false)} key={ele} className='image-default' alt='image' /></Dropdown.Item>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                {editMood === PROFILE_EDIT_MOOD.CHANGE_USER_DATA && (
                  <>
                    <div className='form-group floating-label-group'>
                      <Input
                        type='text'
                        id='firstname'
                        name='firstname'
                        placeholder='First Name'
                        value={values.firstname}
                        setFieldValue={setFieldValue}
                      />
                      <FieldError name='firstname' />
                    </div>
                    <div className='form-group floating-label-group'>
                      <Input
                        type='text'
                        id='lastname'
                        name='lastname'
                        placeholder='Last Name'
                        value={values.lastname}
                        setFieldValue={setFieldValue}
                      />
                      <FieldError name='lastname' />
                    </div>
                    <div className='form-group floating-label-group'>
                      <Input
                        type='text'
                        id='username'
                        name='username'
                        placeholder='Username'
                        value={values.username}
                        setFieldValue={setFieldValue}
                      />
                      <FieldError name='username' />
                    </div>
                    <div className='form-group floating-label-group'>
                      <Input
                        type='email'
                        id='email'
                        name='email'
                        placeholder='Email'
                        value={values.email}
                        setFieldValue={setFieldValue}
                      />
                      <FieldError name='email' />
                    </div>
                    <div className='form-group floating-label-group bio-textarea-section'>
                      <textarea
                        id='bio'
                        className='bio-form'
                        name='bio'
                        value={userBio}
                        maxLength={MagicNumber.TWO_H}
                        onChange={(event) => {
                          handleInput(event);
                        }}
                        onKeyPress={(event) => handleKeyPress(event)}
                      ></textarea>
                      <label
                        className={`label-textarea ${userBio ? 'label-top' : ''
                          }`}
                      >Bio
                      </label>
                      <FieldError name='bio' />
                      <p className='count-text'>{userBio.length}/200</p>
                    </div>
                  </>
                )}
                <div className='modal-btn-group'>
                  <button
                    type='button'
                    className='btn primary'
                    onClick={(e: any) => handleSubmit(e)}
                    disabled={(!dirty && !submitDisabled) ? true : false}
                  >
                    Update
                  </button>
                </div>
              </Form>
            )} />
        </Modal.Body>
      </Modal>
      <OtpModal openModal={openOtpModal} isOpen={isOtpModalOpen} email={email} isRegister={false} isEmailUpdate={true} />
    </>
  );
};

import { Instance, SnapshotOut, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { values } from 'mobx';
import { postCommentEntity } from '../entity';
import { MagicNumber } from '../../constants';

/**
 * Model description here for TypeScript hints.
 */
export const ReplyListModel = types.model({
  id: types.number,
  user_id: types.union(types.undefined, types.maybeNull(types.number)),
  user_role: types.union(types.undefined, types.maybeNull(types.string)),
  full_count: types.union(types.undefined, types.maybeNull(types.string)),
  username: types.union(types.undefined, types.maybeNull(types.string)),
  comment: types.union(types.undefined, types.maybeNull(types.string)),
  profile_image: types.union(types.undefined, types.maybeNull(types.string)),
  createdAt: types.union(types.undefined, types.maybeNull(types.string)),
  showMore: false,
});

export const subProfileItem = types.model({
  id: types.maybeNull(types.number),
  profile_image: types.maybeNull(types.string),
});

export const CommentListData = types.model({
  id: types.number,
  full_count: types.union(types.undefined, types.maybeNull(types.string)),
  username: types.union(types.undefined, types.maybeNull(types.string)),
  sub_username: types.union(types.undefined, types.maybeNull(types.string)),
  comment: types.union(types.undefined, types.maybeNull(types.string)),
  comment_count: types.union(types.undefined, types.maybeNull(types.string)),
  parent_id: types.union(types.undefined, types.maybeNull(types.number)),
  createdAt: types.union(types.undefined, types.maybeNull(types.string)),
  isExpanded: false,
  reply: false,
  profile_image: types.union(types.undefined, types.maybeNull(types.string)),
  user_id: types.union(types.undefined, types.maybeNull(types.number)),
  user_role: types.union(types.undefined, types.maybeNull(types.string)),
  product_id: types.union(types.undefined, types.maybeNull(types.number)),
  updatedAt: types.union(types.undefined, types.maybeNull(types.string)),
  replyList: types.optional(types.array(ReplyListModel), []),
  sub_profilimage: types.optional(types.array(subProfileItem), []),
  showMore: false
});

export const CommentData = types.model('CommentData', {
  limit: types.optional(types.string, ''),
  current_page: types.optional(types.string, ''),
  page_total: types.optional(types.number, 0),
  total: types.optional(types.string, ''),
  results: types.optional(types.array(CommentListData), []),
});

export const CommentStoreModel = types
  .model('CommentData')
  .props({
    data: types.maybe(CommentData),
    postCommentData: types.maybe(CommentListData),
    state: types.optional(types.string, 'pending'),
    stateComment: types.optional(types.string, 'pending'),
    commentData: types.optional(types.array(CommentListData), []),
    totalCount: types.optional(types.number, MagicNumber.ZERO),
    commentProductId: types.optional(types.number, MagicNumber.ZERO),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    fetchCommentItem: flow(function* (productId: string, limit: number = 0, page: number = 0) {
      try {
        const response = yield self.environment.api.get(
          `comments?page=${page}&limit=${limit}&product_id=${productId}`,
        );
        const { results, total } = response.data;
        if (page === 1) {
          self.commentData.replace([]);
          self.totalCount = 0;
        }
        results.map((value: any) => {
          let data = value;
          data.replyList = [];
          self.commentData.push(value);
          return value;
        });
        var count: number = +total;
        self.totalCount = count;
        self.stateComment = 'done';
      } catch (error) {
        throw error;
      }
    }),
    postComment: flow(function* (request) {
      try {
        const commentUrl = 'comments';
        const response = yield self.environment.api.post(commentUrl, request);
        const { commentData } = response;
        self.postCommentData = commentData;
        self.state = 'done';
      } catch (error) {
        self.state = 'done';
        throw error;
      }
    }),

    postSubComment: flow(function* (request: postCommentEntity) {
      try {
        self.state = 'pending';
        const responseList = yield self.environment.api.post(`comments`, request);
        const { response } = responseList;
        values(self.commentData).map((commentItem, index) => {
          if (commentItem.id === request.parent_id) {
            self.commentData[index].isExpanded = true;
            self.commentData[index].replyList.push(response && response.data && response.data.data);
            return;
          }
          return;
        });
        self.state = 'done';
      } catch (error) {
        self.state = 'done';
        throw error;
      }
    }),

    collapseView(commentId: number) {
      values(self.commentData).map((commentItem, index) => {
        if (commentItem.id === commentId) {
          self.commentData[index].isExpanded = false;
          return;
        }
        return;
      });
      self.state = 'done';
    },
    replyView(commentId: number) {
      values(self.commentData).map((commentItem, index) => {
        if (commentItem.id === commentId) {
          self.commentData[index].reply = !self.commentData[index].reply;
          return;
        }
        return;
      });
      self.state = 'done';
    },
    commentShowMore(commentId: number) {
      values(self.commentData).map((commentItem, index) => {
        if (commentItem.id === commentId) {
          self.commentData[index].showMore = !self.commentData[index].showMore;
          return;
        }
        return;
      });
      self.state = 'done';
    },
    setCommentProductId(id: number) {
      self.commentProductId = id;
    },
    replyShowMore(replyId: number) {
      values(self.commentData).map((commentItem, index) => {
        values(commentItem.replyList).map((replyItem, key) => {
          if (replyItem.id === replyId) {
            if (commentItem && commentItem.replyList) {
              commentItem.replyList[key].showMore = !commentItem.replyList[key].showMore;
              return;
            }
            return;
          }
          return;
        });
      });
      self.state = 'done';
    },
    fetchSubComment: flow(function* (
      commentId: number,
      page: number = 1,
      limit: number = 10,
      product_id: number = 88,
      isShow: boolean = true
    ) {
      try {
        const response = yield self.environment.api.get(
          `comments/${commentId}?page=${page}&limit=${limit}&product_id=${product_id}`,
        );
        values(self.commentData).map((commentItem, index) => {
          if (commentItem.id === commentId && isShow) {
            self.commentData[index].isExpanded = true;
            self.commentData[index].replyList.replace([]);
            response.data.results.map((item: any) => {
              self.commentData[index].replyList.push(item);
            });
          }
        });
        self.state = 'done';
      } catch (error) {
        self.state = 'done';
        throw error;
      }
    }),
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

type ReplyListType = Instance<typeof ReplyListModel>;
export interface ReplyListStore extends ReplyListType { }
type CommentStoreType = Instance<typeof CommentStoreModel>;
export interface CommentStore extends CommentStoreType { }
type CommentStoreSnapshotType = SnapshotOut<typeof CommentStoreModel>;
export interface CommentStoreSnapshot extends CommentStoreSnapshotType { }

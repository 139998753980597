import { connect, StreamClient, StreamFeed } from 'getstream';
import Cookies from 'js-cookie';
import { flow, types } from 'mobx-state-tree';
import { errorToast } from '../../components/ui/Atoms';
import { FeedGroup } from '../../components/ui/Pages/feedListing/FeedListingUtil';
import { FEED_POST_RESTRICTIONS, FEED_USER_ID, MagicNumber, STREAM_TOKEN, USER_ID } from '../../constants';
import { withEnvironment } from '../extensions/with-environment';
import { FeedStreamModel } from './feed-stream-entity';

export const FeedStreamStoreModel = types
  .model('FeedStreamStore')
  .extend(withEnvironment)
  .props({
    userID: types.optional(types.string, ''),
    streamToken: types.optional(types.string, ''),
    totalPosts: types.optional(types.number, MagicNumber.ZERO)
  })
  .actions(self => ({
    clearTotalPostsCount: () => {
      self.totalPosts = MagicNumber.ZERO;
    }
  }))
  .actions(self => ({
    uploadPost: flow(function* (description: string, files: string[], files_type: string) {
      const response = yield self.environment.api.post('users/feed', { description, files, files_type });
      return response.response.data.data;
    })
  }))
  .actions(self => ({
    getGuestStream: flow(function* () {
      const response = yield self.environment.api.get('users/stream-user');
      return response.data.data;
    })
  }))
  .actions(self => ({
    getMorePosts: flow(function* (feed: StreamFeed, pageNumber: number, pageLimit: number) {
      if (!feed)
        return [];

      const response = yield feed.get({
        limit: pageLimit,
        withRecentReactions: true,
        withReactionCounts: true,
        withOwnReactions: true,
        recentReactionsLimit: FEED_POST_RESTRICTIONS.MAX_LATEST_COMMENTS,
        offset: pageNumber * pageLimit
      });

      return response.results;
    })
  }))
  .actions(self => ({
    getMorePostsByID: flow(function* (streamClient: StreamClient, postIds: string[]) {
      const response = yield streamClient.getActivities({
        ids: postIds,
        withReactionCounts: true,
        withOwnReactions: true,
        withRecentReactions: true,
        recentReactionsLimit: FEED_POST_RESTRICTIONS.MAX_LATEST_COMMENTS
      });

      return response.results;
    })
  }))
  .actions(self => ({
    getMoreReactions: flow(function* (streamClient: StreamClient, kind: string, feedId: string,
      lastReactionId: string, pageLimit: number) {
      const response = yield streamClient.reactions.filter({
        activity_id: feedId,
        kind: kind,
        limit: pageLimit,
        id_lt: lastReactionId,
        with_activity_data: true
      });

      return response;
    })
  }))
  .actions(self => ({
    getPostDetail: flow(function* (streamClient: StreamClient, feedId: string) {
      const response = yield streamClient.getActivities({
        ids: [feedId],
        withReactionCounts: true,
        withOwnReactions: true
      });

      return response.results;
    })
  }))
  .actions(self => ({
    initializeStream: flow(function* (feedSlug: string) {
      let streamToken: string = Cookies.get(STREAM_TOKEN) ?? '';
      let userID: string = Cookies.get(FEED_USER_ID) ?? '';

      if (!streamToken) {
        const data = yield self.getGuestStream();

        if (data && data.streamToken) {
          streamToken = data.streamToken;
          userID = data.id;
        }
        else {
          throw new Error('Unable to fetch token for guest');
        }
      }

      const streamKey = process.env.REACT_APP_STREAM_API_KEY ?? '';
      const streamID = process.env.REACT_APP_STREAM_APP_ID ?? '';

      const streamClient = connect(streamKey, streamToken, streamID.toString());
      const feed = streamClient.feed(
        feedSlug,
        userID,
        streamToken
      );

      self.userID = userID;
      self.streamToken = streamToken;

      return { client: streamClient, feed: feed };
    })
  }))
  .actions(self => ({
    addComment: flow(function* (streamClient: StreamClient, feedId: string, text: string) {
      const response = yield streamClient.reactions.add('comment', feedId, { text: text });
      return response;
    })
  }))
  .actions(self => ({
    addReply: flow(function* (streamClient: StreamClient, commentId: string, text: string) {
      const response = yield streamClient.reactions.addChild('comment', commentId, { text: text });
      return response;
    })
  }))
  .actions(self => ({
    addLike: flow(function* (streamClient: StreamClient, feedId: string) {
      const response = yield streamClient.reactions.add('like', feedId);
      return response;
    })
  }))
  .actions(self => ({
    removeReaction: flow(function* (streamClient: StreamClient, reactionId: string) {
      const response = yield streamClient.reactions.delete(reactionId);
      return response;
    })
  }))
  .actions(self => ({
    forceRemoveContent: flow(function* (contentUserId: string, contentId: string, contentType: string, contentUserName: string) {
      const body = {
        userid: contentUserId,
        id: contentId,
        type: contentType,
        contentname: contentUserName
      };
      const response = yield self.environment.api.delete(`users/post-comment`, body);
      return response;
    })
  }))
  .actions(self => ({
    sharePost: flow(function* (streamClient: StreamClient, feedId: string, text: string) {
      const response = yield streamClient.reactions.add('repost', feedId, { text: text },
        { targetFeeds: [`timeline:${self.userID}`, `user:${self.userID}`] });
      return response;
    })
  }))
  .actions(self => ({
    deletePost: flow(function* (userId: string, feedId: string) {
      const streamToken = Cookies.get(STREAM_TOKEN);
      if (streamToken)
        self.environment.api.setHeader('streamtoken', streamToken);

      const response = yield self.environment.api.delete(`users/${userId}/post/${feedId}`);
      return response;
    })
  }))
  .actions(self => ({
    postExists: flow(function* (streamClient: StreamClient, feedId: string) {
      const results = yield self.getPostDetail(streamClient, feedId);
      return !!results.length;
    })
  }))
  .actions(self => ({
    reportPost: flow(function* (feedUserId: number, feedContent: string, feedId: string, reason: string) {
      const response = yield self.environment.api.post('users/report-content', {
        reportee_id: feedUserId,
        content: feedContent,
        report_comment: reason,
        type_id: feedId,
        type: 'feed'
      });
      if (response.response)
        return response.response.data.data;
      else
        throw response.data.message;
    })
  }))
  .actions(self => ({
    reportComment: flow(function* (feedUserId: number, comment: string, feedId: string, reason: string) {
      const response = yield self.environment.api.post('users/report-content', {
        reportee_id: feedUserId,
        content: comment,
        report_comment: reason,
        type_id: feedId,
        type: 'comment'
      });
      if (response.response)
        return response.response.data.data;
      else
        throw response.data.message;
    })
  }))
  .actions(self => ({
    handleFeed: flow(function* (kind: string, activity_id: string) {
      const response = yield self.environment.api.post('users/feed/like-share', {
        kind: kind,
        activity_id: activity_id
      });
    })
  }))
  .actions(self => ({
    filterPosts: flow(function* (streamClient: StreamClient, page: number, limit: number, search: string, role: string, feedType: string, userId: string) {
      let query = `?page=${page + MagicNumber.ONE}&limit=${limit}`;

      if (search) query = `${query}&search=${search}`;
      if (role) query = `${query}&role=${role}`;
      if (feedType) query = `${query}&feed_type=${feedType}`;
      if (userId) query = `${query}&user_id=${userId}`;

      const response = yield self.environment.api.get(`search/feeds${query}`);
      self.totalPosts = response.data.total;

      const filteredPosts = yield self.getMorePostsByID(streamClient, response.data.data);
      return filteredPosts;
    })
  }));





import { observer } from 'mobx-react';
import Modal from './Modal/Modal';

type IProps = {
    openModal: (e: boolean) => void;
    isOpen?: boolean;
    openSigninModal?: (e: boolean) => void;
};

export const ToastModal = observer(function Home({ openModal, isOpen, openSigninModal }: IProps) {
    return (
        <Modal
            isOpen={isOpen}
            openModal={openModal}
            header='Great!'
            isSmall
            isImage
            subHeading='You have successfully reset your password. You can now sign in to ood with your new password.'>
            <form className='form-section'>
                <div className='submit-button'>
                    <button type='button' className='btn primary' onClick={() => {
                        openModal(false);
                        openSigninModal && openSigninModal(true);
                    }}>Back To Sign In</button>
                </div>
            </form>
        </Modal >
    );
});

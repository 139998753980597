
import React, { useState } from 'react';
import { USER_ID, VALIDATION_MESSAGES, WH_ARROW_ICON } from './../../../../../constants';
import { MagicNumber } from './../../../../../constants';
import { useParams } from 'react-router-dom';
import { useStores } from '../../../../../models';
import { errorToast, FullPageLoader } from '../../../Atoms';
import { OtpModal } from '../../../Molecules/otpModal/otpModal';
import Cookies from 'js-cookie';
import { PlaceOrder } from '../../../Molecules/modal/PlaceOrder';
import { observer } from 'mobx-react-lite';
import '../../detail/Details.scss';
import { toJS } from 'mobx';
import { TermAndCondition } from '../../../Molecules/modal/TermAndCondition';

type IProps = {
  setNextStep: any;
  subTotal: any;
  itemCount: any;
  hideActionButton: number;
  loader: boolean
  isUserVerified: any;
  email: any;
};
export interface param {
  buy: string;
}

export const PriceSummery = observer(function PriceSummery({ loader, setNextStep, hideActionButton, subTotal, itemCount, isUserVerified, email }: IProps) {
  const params: param = useParams();
  const rootStore = useStores();
  const [isOtpModalOpen, openOtpModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);
  const [showTCModal, setShowTCModal] = useState<boolean>(false);
  const [tcChecked, setTcChecked] = useState<boolean>(false);
  const [allowPersonalInfo, isAllowPI] = useState<boolean>(false);
  const [agreed, setIsAgree] = useState<boolean>(true);
  const { cartSubtotal_buy, cartGrandtotal_buy,
    cartSubtotal,
    cartDiscountAmt, cartMainAmt,
    cartItemList, cartId, paymentCard, addressStep,
    cartGrandtotal,
    addPaymentCardDetail, addPaymentCardInfo,
    tax_amount, getCartItem, buyNowAmt
  } = rootStore.cartStore;
  const { getProfileDetail } = rootStore.profileStore;
  const { resendOtp } = rootStore.loginStore;
  const { cardAddress, savedCard,
    placeOrder, isOrderPlaced,
    hidePopup, stripe, cardCvcElement,
    getPaymentIntent, setCvcStatus, buynow_tax_amount,
    taxApiBuyNow,
    productIdBuyNow,
    cartIdBuyNow,
    isTaxAdd
  } = rootStore.cardStore;
  const { savedAddressList } = rootStore.settingStore;

  const otpModalOpen = () => {
    getProfileDetail();
    resendOtp({ email });
    openOtpModal(true);
  };

  const handleBidTaxApi = async () => {
    const getDefaultAddress = toJS(savedAddressList).filter((data: any) => data.is_default);
    setIsLoading(true);
    const data = {
      address_obj: {
        line1: getDefaultAddress[MagicNumber.ZERO].address_1,
        city: getDefaultAddress[MagicNumber.ZERO].city,
        country: getDefaultAddress[MagicNumber.ZERO].country,
        postalCode: getDefaultAddress[MagicNumber.ZERO].zip_code
      },
      product_id: productIdBuyNow,
      cart_id: cartIdBuyNow,
      amount: buyNowAmt ? parseFloat(buyNowAmt) : 0
    };
    await taxApiBuyNow(data);
    setIsLoading(false);
  };

  const continueMethod = async () => {
    await getCartItem(Number(Cookies.get(USER_ID)));
    let checkStatus = false;
    if (params.buy !== 'buynow') {
      if (cartItemList && cartItemList.length) {
        cartItemList.forEach((element) => {
          if (element.lowStock) {
            checkStatus = true;
            errorToast(VALIDATION_MESSAGES.OUT_OF_STOCK);
          }
        });
      }
    }
    if (!checkStatus) {
      if (addressStep && hideActionButton === MagicNumber.TWO) {
        setNextStep(2);
      }
      else {
        if (savedAddressList && savedAddressList.length && params.buy) {
          await handleBidTaxApi();
        }
        setNextStep(1);
      }
    }
  };

  const toggleCard = () => {
    setOpenPopup(false);
    hidePopup();
    window.location.href = '/';
  };

  const stripeCvc = async () => {
    const userId = Cookies.get(VALIDATION_MESSAGES.USER_ID) || VALIDATION_MESSAGES.EMPTY;
    const dollar: any = params.buy ? cartSubtotal_buy && cartSubtotal_buy.split('$') : cartSubtotal && cartSubtotal.split('$');
    const data: any = {
      'user_id': parseInt(userId),
      'amount': parseInt(dollar && dollar[MagicNumber.ONE])
    };
    await getPaymentIntent(data).then((res) => {
      if (res.res.kind == 'ok') {
            setCvcStatus(false);
            setNextStep(MagicNumber.ONE);
            addPaymentCardDetail({});
            addPaymentCardInfo(null);
            setBtnLoader(false);
            console.log('success = ', res);
        // stripe.confirmCardPayment(res && res?.res?.response?.data?.data?.client_secret, {
        //   payment_method: res.cardId,
        //   payment_method_options: {
        //     card: {
        //       cvc: cardCvcElement
        //     }
        //   },
        // }).then((result: any) => {
        //   if (result.error) {
        //     setBtnLoader(false);
        //     setCvcStatus(true);
        //     console.log('error = ', result.error);
        //   } else {
        //     setCvcStatus(false);
        //     setNextStep(MagicNumber.ONE);
        //     addPaymentCardDetail({});
        //     addPaymentCardInfo(null);
        //     setBtnLoader(false);
        //     console.log('success = ', result);
        //   }
        // });
      }
    });
  };

  const orderPreviewMethod = async () => {
    if (savedCard && savedCard.cardData && savedCard.cardData.id) {
      setBtnLoader(true);
      stripeCvc();
    }
    else {
      errorToast(VALIDATION_MESSAGES.NO_CARD);
    }
  };

  const handlePlaceOrder = async () => {
    setIsLoading(true);
    const userId = Cookies.get('user_id') || '';
    let addressId;
    if (!cardAddress && savedAddressList.length) {
      savedAddressList.forEach((element) => {
        if (element.is_default) {
          addressId = element.id;
        }
      });
    } else {
      addressId = cardAddress && cardAddress.address_id;
    }
    if (params.buy) {
      const ad_id = addressId;
      const dataBuyNow = {
        user_id: parseInt(userId),
        cart_id: rootStore.cardStore.cartId,
        address_id: ad_id,
        source_token: '',
        card_id: savedCard && savedCard.cardData && savedCard.cardData.id,
        is_card_save: true,
        accept_terms_condition: !agreed,
        allow_share_info: allowPersonalInfo,
      };
      if (paymentCard && paymentCard.cart_id) {
        await placeOrder(paymentCard);
      }
      else {
        await placeOrder(dataBuyNow);
      }
      setOpenPopup(true);
      setIsLoading(false);
    } else {
      const data = {
        user_id: parseInt(userId),
        cart_id: cartId,
        address_id: addressId,
        source_token: '',
        card_id: savedCard && savedCard.cardData && savedCard.cardData.id,
        is_card_save: true,
        accept_terms_condition: !agreed,
        allow_share_info: allowPersonalInfo
      };
      if (paymentCard && paymentCard.cart_id) {
        await placeOrder(paymentCard);
      }
      else {
        await placeOrder(data);
      }
      setOpenPopup(true);
      setIsLoading(false);
    }
  };

  const handleTaxPrice = (price: any) => {
    var formatUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    let dollarToInt = 0;
    if (price) {
      dollarToInt = Number(price.replace(/[^0-9-]+/g, '')) / MagicNumber.TH;
    }
    let dollarCr;
    if (price) {
      let taxAmt = (tax_amount ? tax_amount : 0);
      if (!params.buy) {
        dollarCr = formatUSD.format(dollarToInt + taxAmt);
      } else if (isTaxAdd) {
        dollarCr = formatUSD.format(dollarToInt + buynow_tax_amount);
      } else {
        dollarCr = formatUSD.format(dollarToInt);
      }
      return dollarCr;
    }
    return price;
  };

  const handleTC = () => {
    setShowTCModal(true);
  };

  const getStatus = (flag: string) => {
    if (flag === 'agree') {
      setIsAgree(false);
      setTcChecked(true);
    } else {
      setIsAgree(true);
      setTcChecked(false);
    }
    setShowTCModal(false);
  };

  const handleTCchange = (event: any) => {
    if (event.target.checked) {
      handleTC();
    } else {
      setIsAgree(true);
      setTcChecked(event.target.checked);
    }
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <div className='price-sum'>
        <h4>Price Summary</h4>
        <ul>
          <li>Price ({itemCount} {itemCount >= MagicNumber.TWO ? 'Items' : 'Item'}) <span>{cartMainAmt ? cartMainAmt : subTotal}</span></li>
          <li>Delivery Charges <span>$0</span></li>
          {hideActionButton > MagicNumber.THREE ? <li>Sales Tax <span>${!params.buy ? tax_amount?.toFixed(MagicNumber.TWO) : buynow_tax_amount.toFixed(MagicNumber.TWO)}</span></li> : <li>Sales Tax <span style={{ fontFamily: 'sans-serif' }}>Calculated Later</span></li>}
          {cartDiscountAmt ? <li>Discount Applied <span>-{cartDiscountAmt}</span></li> : ''}
        </ul>
        <ul className='total-price'>
          <li>Total Amount {!params.buy ? <span>{hideActionButton > MagicNumber.THREE ? cartGrandtotal : cartSubtotal}</span> : <span>{hideActionButton > MagicNumber.THREE ? handleTaxPrice(cartGrandtotal_buy) : cartSubtotal_buy}</span>}</li>
        </ul>
        {hideActionButton > MagicNumber.THREE ? <ul className='check-section'>
          <li className='agree-list'>
            <div className='checkbox-custom'>
              <label className='label-text info-share-section'>
                <input name='term_condition' type='checkbox'
                  onChange={handleTCchange} className='box-check'
                  checked={tcChecked}
                  id='term_condition' value='false' />
                <strong></strong>
                <p className='info-share-text'>I agree with OOD
                  <a className='link-text' onClick={handleTC}>Terms and Conditions.</a> </p>
              </label>
            </div>
            <div className='checkbox-custom'>
              <label className='label-text info-share-section'>
                <input name='term_condition' type='checkbox'
                  className='box-check' id='term_condition'
                  onChange={(event) => isAllowPI(event.target.checked)}
                  value='false' />
                <strong></strong>
                <p className='info-share-text'>
                  Allow OOD to share contact information with creators/brands
                </p>
              </label>
            </div>
          </li>
        </ul> : ''}
        {hideActionButton <= MagicNumber.TWO && (
          <div className='card-submit'>
            {isUserVerified ? (
              <button className='btn primary' onClick={() => continueMethod()}>Continue<div className='right-arrow'>
                <img src={WH_ARROW_ICON} alt='arrow' className='arrow-icon' />
              </div>
              </button>
            ) : (<button className='btn primary' onClick={() => otpModalOpen()}>Continue<div className='right-arrow'>
              <img src={WH_ARROW_ICON} alt='arrow' className='arrow-icon' />
            </div>
            </button>
            )}
          </div>
        )}
        {hideActionButton === MagicNumber.THREE && (
          <div className='card-submit'>
            <button className='btn primary' onClick={() => orderPreviewMethod()}>Continue
              {btnLoader ? <span className='loading' /> : <div className='right-arrow'>
                <img src={WH_ARROW_ICON} alt='arrow' className='arrow-icon' />
              </div>}
            </button>
          </div>
        )}
        {hideActionButton >= MagicNumber.FOUR ? <div className='pay-detail'>
          <button type='button'
            className='btn primary'
            onClick={handlePlaceOrder}
            disabled={agreed}
          >Pay  {!params.buy ? <span>{cartGrandtotal}</span> : <span>{handleTaxPrice(cartGrandtotal_buy)}</span>}
          </button>
          <img src={WH_ARROW_ICON} className='arrow-image' alt='arrow' />
        </div> : ''}
      </div>
      <OtpModal openModal={openOtpModal} isOpen={isOtpModalOpen} email={email} isRegister={false} isEmailUpdate={true} />
      {isOrderPlaced ? <PlaceOrder
        isOpen={openPopup}
        openModal={() => { toggleCard(); }}
      /> : ''}
      {showTCModal && (
        <TermAndCondition
          openModal={(flag: string) => { getStatus(flag); }}
          title='Terms and Conditions'
          description={`Please confirm if your`}
          isOpen={showTCModal}
        />
      )}
    </>
  );
});


import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useStores } from '../../../../models';
import Skeleton from 'react-loading-skeleton';
import { Image } from '../../Atoms';
import { MagicNumber, CATEGORY_NAME } from './../../../../constants';
import './collection.scss';

type IProps = {
  refValue: React.Ref<HTMLInputElement>;
  onCollectionClicked?: (collectionId: number) => void
};

export const Collection = ({ refValue, onCollectionClicked }: IProps) => {
  const history = useHistory();
  const rootStore = useStores();
  const [loader, setLoader] = useState<boolean>(false);
  const { getCollectionList, collectionData } = rootStore.collectionStore;
  const { ONE, TWO, THREE, FOUR } = MagicNumber;

  const fetchCollectionList = async (id: any) => {
    setLoader(true);
    await getCollectionList(id);
    setLoader(false);
  };

  const currentCategory = () => {
    const pathName: any = history.location.pathname.split('/');
    switch (pathName[ONE]) {
      case CATEGORY_NAME.MUSIC:
        fetchCollectionList(ONE);
        break;
      case CATEGORY_NAME.ART:
        fetchCollectionList(TWO);
        break;
      case CATEGORY_NAME.SPORTS:
        fetchCollectionList(THREE);
        break;
      case CATEGORY_NAME.MEDIA:
        fetchCollectionList(FOUR);
        break;
      default:
        fetchCollectionList(null);
        break;
    }
  };

  useEffect(() => {
    currentCategory();
  }, [history.location.pathname]);

  return (
    <>
      {loader ? (
        <div className='product-listing-collection'>
          <div className='product-list-container skeleton-list'>
            <Skeleton />
          </div>
        </div>
      ) : (
        <>
          {collectionData.length ? (
            <div className='product-listing-collection'>
              <div className='product-list-container'>
                <h2 className='list-head'>Collections</h2>
                <div className='collection-wrapper'>
                  <div className='collection-section'>
                    <div ref={refValue}></div>
                    {collectionData.map((item: any, index: number) =>
                      <div className='collection-box' key={index} role='button' onClick={() => { onCollectionClicked?.(item.id); }}>
                        <Image src={item.logo} className='logo-image' alt='logo' />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div >
          ) : ('')}
        </>
      )}
    </>

  );

};


import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import { useStores } from '../../../../../models';
import { MagicNumber, USER_ID } from './../../../../../constants';
import { AddShippingAddress } from './../../../Molecules';
import { FullPageLoader } from '../../../Atoms';
import { toJS } from 'mobx';
import Cookies from 'js-cookie';
import { useParams } from 'react-router';
type IProps = {
  itemData: any;
};
export interface param {
  buy: string;
}
export const AddressListing = ({ itemData }: IProps) => {
  const [shippingAddressModal, setShippingAddressModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const rootStore = useStores();
  const { updateShippingAddress, getShippingAddress, savedAddressList } = rootStore.settingStore;
  const { setAddress, taxApiBuyNow, productIdBuyNow, cartIdBuyNow } = rootStore.cardStore;
  const { buyNowAmt, getCartItem } = rootStore.cartStore;
  const params: param = useParams();
  const toggleShippingAddressModal = () => {
    setShippingAddressModal(!shippingAddressModal);
  };
  const currentAddressValue = {
    user_id: Number(Cookie.get(USER_ID)),
    name: itemData.name,
    phone: itemData.phone,
    country: 'US',
    address_1: itemData.address_1,
    address_2: itemData.address_2,
    city: itemData.city,
    state: itemData.state,
    zip_code: itemData.zip_code,
    is_default: true,
  };

  const handleBidTaxApi = async () => {
    const getDefaultAddress = toJS(savedAddressList).filter((data: any) => data.is_default);
    setLoader(true);
    const data = {
      address_obj: {
        line1: getDefaultAddress[MagicNumber.ZERO].address_1,
        city: getDefaultAddress[MagicNumber.ZERO].city,
        country: getDefaultAddress[MagicNumber.ZERO].country,
        postalCode: getDefaultAddress[MagicNumber.ZERO].zip_code
      },
      product_id: productIdBuyNow,
      cart_id: cartIdBuyNow,
      amount: buyNowAmt ? parseFloat(buyNowAmt) : 0
    };
    await taxApiBuyNow(data);
    if (params.buy !== 'buynow') {
      await getCartItem(Number(Cookies.get(USER_ID)));
    }
    setLoader(false);
  };

  useEffect(() => {
    if (itemData && toJS(itemData).id && params.buy === 'buynow') {
      handleBidTaxApi();
    } else {
      getCartItem(Number(Cookies.get(USER_ID)));
    }
  }, []);

  const handleChange = async () => {
    setLoader(true);
    setAddress({ address_id: itemData.id });
    await updateShippingAddress(itemData.id, currentAddressValue);
    await getShippingAddress(Number(Cookie.get(USER_ID)));
    if (params.buy !== 'buynow') {
      getCartItem(Number(Cookies.get(USER_ID)));
    } else {
      handleBidTaxApi();
    }
    setLoader(false);
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <div className={`address-card ${itemData.is_default ? 'card-active' : ''}`}>
        <div className='select-head'>
          <div className='head-box'>
            <h2 className='text-head'>{itemData.name}</h2>
          </div>
          <div className='head-box'>
            <div className='black-radio-custom shipping-address-radio'>
              <p className='selected-space'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='Default'
                  id={itemData.id}
                  checked={itemData.is_default}
                  onChange={handleChange}
                />
                <label className='form-check-label' htmlFor={itemData.id}><p className='responsive-label-text'>Make Default</p></label>
              </p>
            </div>
          </div>
        </div>
        <p className='address-detail'>{itemData.address_1}, {`${itemData.address_2 !== '' ? `${itemData.address_2},` : ''}`} {itemData.city}, {itemData.state} - {itemData.zip_code}, {itemData.country}</p>
        {itemData.phone &&
          <p className='mt-2'>Mobile: {itemData.phone}</p>
        }
        <div className='edit-address'>
          <button type='button' onClick={toggleShippingAddressModal} className='btn outline-primary'>Edit</button>
        </div>
      </div>
      {shippingAddressModal && (
        <AddShippingAddress
          openModal={() => { toggleShippingAddressModal(); }}
          activeAddress={itemData}
          shippingAddressMode='Edit'
          title='Edit Shipping Address'
          isOpen={shippingAddressModal}
        />
      )}
    </>
  );
};



import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useStores } from '../../../../../models';
import { CartListing } from '../cartListing/CartListing';
import { CartListingSkeleton } from '../cartListing/CartListingSkeleton';
import { VISA_ICON, STARS_ICON, MagicNumber, MASTER_ICON, AMERICAN_ICON, ICON_MEDIA } from '../../../../../constants';
import './OrderSummary.scss';
import { useHistory } from 'react-router-dom';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

type IProps = {
  buynow: string | null;
  setPrevState: any;
  isLoader: boolean;
};

export const OrderSummary = observer(function OrderSummary({ buynow, setPrevState, isLoader }: IProps) {
  const rootStore = useStores();
  const history = useHistory();
  const { savedCard, getSavedCard, buyNowItem, saveCvc } = rootStore.cardStore;
  const { cartItemList, paymentCard, paymentCardInfo, checkAddressStep } = rootStore.cartStore;
  const { savedAddressList } = rootStore.settingStore;
  const { productItem } = rootStore.homeStore;
  const [url, checkUrl] = useState<string>('');

  const fetchSavedCard = async () => {
    await getSavedCard();
  };

  useEffect(() => {
    if (history) {
      checkUrl(history.location.pathname);
    }
    fetchSavedCard();
  }, []);

  const cardExpiry = (expiry: any) => {
    return ('0' + expiry).slice(-MagicNumber.TWO)
  };

  const cardImage = (brand: any) => {
    let cardIcon = '';
    let classes = 'save-visa-icon';
    if (brand === 'MasterCard') {
      cardIcon = MASTER_ICON;
      classes = 'save-master-icon';
    } else if (brand === 'Visa') {
      cardIcon = VISA_ICON;
    } else if (brand === 'American Express') {
      cardIcon = AMERICAN_ICON;
    } else {
      classes = 'save-visa-icon';
      cardIcon = '';
    }
    return <img src={cardIcon} alt='' className={classes} />;
  };

  const backtoAddress = () => {
    checkAddressStep(true);
  };

  const changeDetails = () => {
    saveCvc('');
    setPrevState(MagicNumber.THREE);
  };

  return (
    <>
      {isLoader ? (
        <>
          <CartListingSkeleton />
          <div className='order-summary-payment-detail-list'>
            <div className='payment-detail-box skeleton-box'>
              <Skeleton />
            </div>
            <div className='payment-detail-box skeleton-box'>
              <Skeleton />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='order-summary-cart-list'>
            {buynow && (buyNowItem && buyNowItem.id) ? (
              <div className='item'>
                <div
                  className='product-img'
                >
                  <img
                    src={buyNowItem?.medias?.length && buyNowItem.medias[MagicNumber.ZERO]?.filePath || ICON_MEDIA}
                    alt='Product image'
                    onError={(e: any) => (e.target.onerror = null, e.target.src = ICON_MEDIA)}
                  />
                </div>
                <div className='product-sum'>
                  <div className='name'>{buyNowItem && buyNowItem.name}</div>
                  <div className='condetion'>
                    <span>{buyNowItem && buyNowItem.condition}</span>
                    {buyNowItem?.variant_data?.length ? buyNowItem.variant_data.map((e: any, index: number) => <span key={index}>{e.title}</span>) : ''}
                  </div>
                  <div className='deliver-time'>
                    {/* <span>Delivery by</span> Mar, 25th 2021 */}
                  </div>
                </div>
                <div className='item-price readonly-price'>{buyNowItem && buyNowItem.totalPrice}</div>
              </div>
            ) : (
              <>
                {productItem && productItem.length && url.includes('/bids') ?
                  <CartListing
                    cartData={toJS(productItem)}
                    restriction={true}
                  />
                  : cartItemList && cartItemList.map((item: any) => {
                    return (
                      <CartListing
                        cartData={item}
                        restriction={true}
                      />
                    );
                  })}
              </>
            )}
          </div>
          <div className='order-summary-payment-detail-list'>
            {paymentCard && paymentCard.cart_id || (paymentCardInfo && paymentCardInfo.last4) ? (
              <div className='payment-detail-box'>
                <h3>Payment Details</h3>
                <div className='save-card-outside'>
                  <div className='save-card'>
                    <div className='visa-row'>
                      {cardImage(paymentCardInfo && paymentCardInfo.brand)}
                    </div>
                    <div className='card-no'>
                      <img src={STARS_ICON} alt='sold' /> <img src={STARS_ICON} alt='sold' /> <img src={STARS_ICON} alt='sold' /> {paymentCardInfo.last4 || ''}
                    </div>
                    <div className='expiry'>
                      <div className='name'>Name<span>{paymentCardInfo.name || ''}</span></div>
                      <div className='name'>Expiry Date<span>{`${cardExpiry(paymentCardInfo.exp_month) || ''}/${cardExpiry(paymentCardInfo.exp_year) || ''}`}</span></div>
                      <div className='shipping-address-card-footer cartpage-list-footer'>
                        <button type='button' className='btn outline-primary' onClick={() => { changeDetails(); }}>Change</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='payment-detail-box'>
                <h3>Payment Details</h3>
                {savedCard && savedCard.cardData && savedCard.cardData.card_id ?
                  <div className='save-card-outside'>
                    <div className='save-card'>
                      <div className='visa-row'>
                        {cardImage(savedCard && savedCard.cardData && savedCard.cardData.brand)}
                      </div>
                      <div className='card-no'>
                        <img src={STARS_ICON} alt='sold' /> <img src={STARS_ICON} alt='sold' /> <img src={STARS_ICON} alt='sold' /> {savedCard.cardData.last_four_digit || ''}
                      </div>
                      <div className='expiry'>
                        <div className='name'>Name<span>{savedCard.cardData.account_holder_name || ''}</span></div>
                        <div className='name'>Expiry Date<span>{`${cardExpiry(savedCard.cardData.exp_month) || ''}/${cardExpiry(savedCard.cardData.exp_year) || ''}`}</span></div>
                        <div className='shipping-address-card-footer cartpage-list-footer'>
                          <button type='button' className='btn outline-primary' onClick={() => { changeDetails(); }}>Change</button>
                        </div>
                      </div>
                    </div>
                  </div> : 'No card added!'}
              </div>
            )}
            <div className='shipping-detail-box'>
              <h3>Shipping Address</h3>
              {savedAddressList.length ? (
                savedAddressList.map((item: any, index: any) => {
                  if (item.is_default) {
                    return (
                      <div className='shipping-address-card'>
                        <div className='shipping-address-card-head'>
                          <h3>{item.name}</h3>
                        </div>
                        <div className='shipping-address-card-body'>
                          <p>{item.address_1} {`${item.address_2 && item.address_2}`} {item.city}, {item.state} - {item.zip_code}, {item.country}</p>
                          {item.phone && <p className='mt-2'>Mobile: +1 {item.phone}</p>}
                        </div>
                        <div className='shipping-address-card-footer cartpage-list-footer'>
                          <button type='button' className='btn outline-primary' onClick={() => { setPrevState(MagicNumber.TWO); backtoAddress(); }}>Change</button>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (<></>)}
            </div>
          </div>
        </>
      )}
    </>
  );
});

import { MouseEventHandler, useState } from 'react';
import { useHistory } from 'react-router';
import { DOT_DROP_ICON, FunctionalityType, getFeedTime, MagicNumber, PAGE_ROUTES, UserType, USER_PLACEHOLDER } from '../../../../../constants';
import { ConfirmationInfos } from '../../../../../constants/ConfirmationInfos';
import { useStores } from '../../../../../models';
import { errorToast, FullPageLoader } from '../../../Atoms';
import { SkeletonImage } from '../../../Atoms/SkeletonImage';
import { ConfirmationBox } from '../../../Molecules/confirmationBoxModal/ConfirmationBox';
import { FeedGroup, getFeedItemUserID, getFeedItemUserImage, getFeedItemUserName, isFeedItemFromAdmin, isActorCommunityManager, isFeedItemShared, PostType, getActorCollectionID } from '../FeedListingUtil';

export const FeedItemUserDetails = (props: {
  from: string;
  feedData: any,
  optionsDisabled?: boolean,
  onProfileOpenedAsGuestCB?: () => void,
  onReportPostCB?: (reportedContent: string) => void,
  onDeletePostCB?: (feedUserId: string, feedId: string) => void
}) => {

  const rootStore = useStores();
  const history = useHistory();
  const { userID } = rootStore.feedStreamStore;
  const { isLoggedIn, isFunctionalityAllowed ,fetchUserDetails} = rootStore.loginStore;

  const postID = props.feedData.id ?? '';
  const postUserID = getFeedItemUserID(props.feedData);
  const postUserCollectionID = getActorCollectionID(props.feedData.actor);
  const userName = getFeedItemUserName(props.feedData);
  const userImageURL = getFeedItemUserImage(props.feedData);
  const isPostByCommunityManager = isActorCommunityManager(props.feedData.actor);
  const isPostByAdmin = isFeedItemFromAdmin(props.feedData);
  const deletionEnabled = getFeedItemUserID(props.feedData) === userID || isFunctionalityAllowed(FunctionalityType.DeletePost);
  const reportingEnabled = getFeedItemUserID(props.feedData) !== userID && isFunctionalityAllowed(FunctionalityType.ReportPost);

  const postTime = !isFeedItemShared(props.feedData) ? new Date(props.feedData.started_at) :
    (props.feedData.reaction && props.feedData.reaction.created_at ? new Date(props.feedData.reaction.created_at)
      : new Date());

  const canGoToUserProfile = () => {
    return !(isPostByCommunityManager && !postUserCollectionID);
  };

  const getReportablePostContent = (): string => {
    if (isFeedItemShared(props.feedData))
      return props.feedData.reaction.data.text;

    const postType = props.feedData.feed_type ? PostType[props.feedData.feed_type as keyof typeof PostType] : PostType.text;
    const reportablePostContent = postType === PostType.text ? props.feedData.tweet ?? '' :
      props.feedData.digital && props.feedData.digital.length ? props.feedData.digital[MagicNumber.ZERO] : '';

    return reportablePostContent;
  };

  const goToUserProfile = async () => {
    await fetchUserDetails(postUserID).then((res: any) => {
      if (res.data.message == "This User doesn't exist!") {
        errorToast('user is not available');
        return;
      }else{
      if (!isLoggedIn) {
        props.onProfileOpenedAsGuestCB?.();
        return null;
    }

    if (isPostByCommunityManager && postUserCollectionID) {
      history.push(`${PAGE_ROUTES.ALL}?collection_id=${postUserCollectionID}`);
      history.go(MagicNumber.ZERO);
    }else {
      history.push(`${PAGE_ROUTES.PROFILE}${postUserID !== userID ? `/${postUserID}` : ''}`);
    }
  }
});
  };

  return (
    <div className='about-user'>
      <div className='user-info'>
        <div className='user-img'>
          {isPostByAdmin ?
            <div className='image-round'>
              <SkeletonImage
                imageURL={userImageURL}
                placeholder={USER_PLACEHOLDER}
                alt='user'
                classes='image-user'
              />
            </div> :
            <SkeletonImage
              imageURL={userImageURL}
              placeholder={USER_PLACEHOLDER}
              alt='user'
              classes='image-user'
              onClick={canGoToUserProfile() ? () => { goToUserProfile(); } : undefined}
            />
          }
        </div>
        <div className='name-detail'>
          {isPostByAdmin ?
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className={`head-line breadcrumb-item`} aria-current='page'>{userName}</li>
              </ol>
            </nav>
            :
            canGoToUserProfile() ?
              <a onClick={() => { goToUserProfile(); }}>
                <p className='name'>{userName}</p>
              </a> :
              <p className='name'>{userName}</p>
          }
          <p className='status'>{getFeedTime(postTime)}</p>
        </div>
      </div>
      {
        props.optionsDisabled || (isPostByAdmin && !isFunctionalityAllowed(FunctionalityType.ShowOptionsOnAdminPost)) ? null :
          <div className='report-post'>
            <div className='dropdown-button'>
              {props.from !== 'left' ? <a className='btn btn-secondary dropdown-toggle'
                type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                <img src={DOT_DROP_ICON} className='dropdown-icon' alt='icon' />
              </a> : ''}
              <div className='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                {reportingEnabled ?
                  <a className='dropdown-item'
                    onClick={() => {
                      props.onReportPostCB?.(getReportablePostContent());
                    }}>Report Post</a> : null}
                {deletionEnabled ?
                  <a className='dropdown-item'
                    onClick={() => {
                      props.onDeletePostCB?.(postUserID, postID);
                    }}>Delete Post</a> : null}
              </div>
            </div>
          </div>
      }
    </div>
  );
};

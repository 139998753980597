import { Instance, types, flow } from 'mobx-state-tree';
import { errorToast } from '../../components/ui/Atoms';
import { withEnvironment } from '../extensions/with-environment';
import { WishlistData } from './wishlist-entity';
import { HTTPSTATUSCODE } from '../../constants';

export const WishlistStoreModal = types
  .model('WishlistStore')
  .props({
    state: types.optional(types.string, 'done'),
    isLoading: types.optional(types.boolean, true),
    wishlistData: types.optional(types.array(WishlistData), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    addWishList: flow(function* (formData) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.post(`wishlist`, formData);
        self.state = 'done';
        self.isLoading = false;
        if (response.response) {
          return response.response;
        } else {
          const { data } = response;
          if (data && data.statusCode === HTTPSTATUSCODE.NOT_FOUND) {
            errorToast(data.message);
          }
        }
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getWishList: flow(function* () {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`wishlist`);
        const { data } = response;
        if (data) {
          let wishlistItem = data.map((value: any) => {
            let val = {
              id: value.id,
              product_id: value && value.product_info && value.product_info.id,
              product_sku: value && value.product_info && value.product_info.sku,
              product_slug: value && value.product_info && value.product_info.slug,
              medias: value && value.product_info && value.product_info.medias && value.product_info.medias.map((item: any) => {
                let mediaValue = {
                  filePath: item.filepath,
                  entity_type: item.entity_type,
                  filepathThumb: item.filepathThumb,
                  type: item.type
                };
                return mediaValue;
              })
            };
            return val;
          });
          self.wishlistData = wishlistItem;
          return true;
        } else {
          self.state = 'done';
        }
        self.state = 'done';
        self.isLoading = false;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    deleteWishlist: flow(function* (ids) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.delete('wishlist', ids);
        self.state = 'done';
        self.isLoading = false;
        return response.response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    deleteWishlistBySku: flow(function* (sku) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.delete(`wishlist/${sku}`);
        self.state = 'done';
        self.isLoading = false;
        return response.response;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }));

type WishlistStoreType = Instance<typeof WishlistStoreModal>;
export interface WishlistStore extends WishlistStoreType { }


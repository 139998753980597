import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import { useStores } from '../../../../../models';
import { USER_ID, ITEM_MODIFICATION, MagicNumber, HTTPSTATUSCODE, TOAST_TEXT, ICON_MEDIA, VALIDATION_MESSAGES } from './../../../../../constants';
import { Loader, QuantityButtons, Image, errorToast, successToast } from './../../../Atoms';
import { RemoveCartItem, MoveToWishlist } from './../../../Molecules';
import { toJS } from 'mobx';
import { CardData } from '../../../../../models/add-card/add-card-entity';

type IProps = {
  cartData: any;
  restriction?: boolean;
};

export const CartListing = ({ cartData, restriction }: IProps) => {
  const rootStore = useStores();
  const history = useHistory();
  const { addItemQuantity, getCartItem, cartId, cartSubtotal } = rootStore.cartStore;
  const { highestBid, productBids, bid } = rootStore.userStore;
  const [loader, setLoader] = useState<boolean>(false);
  const [removeCartItemModal, setRemoveCartItemModal] = useState<boolean>(false);
  const [moveToWishlistModal, setMoveToWishlistModal] = useState<boolean>(false);
  const [url, checkUrl] = useState<string>('');
  const totalQuantity = Number(cartData.quantity);
  const availableQuantity = Number(cartData.availableQuantity);
  const quantityCount = Number(cartData.quantity);
  const itemOutOfStock = cartData.outOfStock;
  const { deleteWishlistBySku } = rootStore.wishlistStore;

  const toggleCartItemRemove = () => {
    setRemoveCartItemModal(!removeCartItemModal);
  };

  const toggleMoveToWishlist = async (sku?: any) => {
    if (cartData.in_wish) {
      await deleteWishlistBySku(sku);
      await getCartItem(Number(Cookie.get(USER_ID)));
      // errorToast(VALIDATION_MESSAGES.VERIFIED_WISHLIST)
    }else {
      setMoveToWishlistModal(!moveToWishlistModal);
    }
  };

  const decreaseQuantityCount = () => {
    if (availableQuantity < quantityCount) {
      successToast(`${TOAST_TEXT.QUANTITY_COUNT} ${availableQuantity} ${TOAST_TEXT.QUANTITY_AVAILABLE}`);
      return quantityCount - availableQuantity;
    }else {
      return MagicNumber.ONE;
    }
  };

  const modifyQuantityToItem = async (itemAction: string) => {
    const cartItemData = {
      sku: cartData.sku,
      quantity: itemAction === ITEM_MODIFICATION.ADD ? quantityCount + MagicNumber.ONE : quantityCount - decreaseQuantityCount(),
      user_id: Number(Cookie.get(USER_ID))
    };
    if (cartItemData.quantity > availableQuantity) {
      errorToast(`You can't purchase more than ${availableQuantity} quantity of this product!`);
      return;
    }
    setLoader(true);
    await addItemQuantity(cartItemData).then(res => {
      if (res.data.statusCode === HTTPSTATUSCODE.BAD_REQUEST) {
        errorToast(res.data.msg);
      }
    });
    await getCartItem(Number(Cookie.get(USER_ID)));
    setLoader(false);
  };

  const redirectToProductDetail = (slug: any, productId: any) => {
    if (!slug && !productId) {
      history.push({
        pathname: `products/${productBids && productBids.auctionData && productBids.auctionData.product_id}`
      });
    } else {
      history.push({
        pathname: `products/${slug || productId}`
      });
    }
  };

  useEffect(() => {
    if (history) {
      checkUrl(history.location.pathname);
    }
  }, []);

  return (
    <>
      {cartData && <div className={`item ${itemOutOfStock ? 'out-of-stock' : ''}`}>
        <div
          className='product-img'
          onClick={() => { redirectToProductDetail(cartData.slug, cartData.productId); }}
        >
          {cartData && cartData.medias.length && cartData.medias[0].type === 'video' ? <img
            src={cartData.medias[0].filepathThumb}
            alt='Product image 1'
            onError={(e: any) => (e.target.onerror = null, e.target.src = ICON_MEDIA)}
          /> : ''}
          {cartData && cartData.medias.length && cartData.medias[0].type === 'image' ? <img
            src={url.includes('/bids') ? cartData.medias[0].filepath : `${cartData.medias[0].filePath}?aspect_ratio=1:1`}
            alt='Product image 2'
            onError={(e: any) => (e.target.onerror = null, e.target.src = ICON_MEDIA)}
          /> : ''}
          {itemOutOfStock && <span>Out of Stock</span>}
        </div>
        <div className='product-sum'>
          <div className='name'>{cartData.name}</div>
          <div className='condetion'>
            <span>{cartData.condition}</span>
            {cartData && cartData.variant_data && cartData.variant_data.length ? cartData.variant_data.map((e: any, index: number) => <span key={index}>{e.title}</span>) : ''}
          </div>
          {restriction ? (url !== '/bids' && <div className='item-qyt readonly-qyt'>Quantity: {cartData.quantity}</div>) : (<></>)}
          {itemOutOfStock ? (
            <div className='deliver-time' />
          ) : (url !== '/bids' && <div className='deliver-time'>
            {/* <span>Delivery by</span> Mar, 25th 2021 */}
          </div>
          )}
        </div>
        {restriction ? (<div className='item-price readonly-price'>
          {bid && bid !== '' ? <div className='highest-bid-section'>
            <p className='bid-text'>Highest Bid<div className='highlight-icon'></div></p>
            {url.includes('/bids') ? highestBid : cartData.totalPrice}
          </div> : cartData.totalPrice}</div>) : (
          <>
            <div className='item-action'>
              <div className='cart-action-item'>
                {!cartData.dropInfo ? <span
                  onClick={() => toggleMoveToWishlist(cartData.sku)}
                  className={`icon icon-wishlist ${cartData.in_wish ? 'active' : ''}`}
                /> : ''}
                <span onClick={toggleCartItemRemove} className='icon icon-delete' />
              </div>
              {itemOutOfStock ? (
                <div className='quantity-btn d-none' />
              ) : (
                <div className={`quantity-btn ${loader ? 'disabled-actions' : ''}`}>
                  {loader && <Loader />}
                  <QuantityButtons
                    quantityDecrease={() => { modifyQuantityToItem(ITEM_MODIFICATION.REMOVE); }}
                    quantityIncrease={() => { modifyQuantityToItem(ITEM_MODIFICATION.ADD); }}
                    decreaseDisabled={totalQuantity <= MagicNumber.ONE}
                    increaseDisabled={cartData.lowStock}
                    inputValue={cartData.quantity}
                  />
                </div>
              )}
              {(itemOutOfStock || cartData.lowStock) ? (
                <div className='item-price' />
              ) : (<div className='item-price'>{cartData.totalPrice}</div>
              )}
            </div>
            {(cartData.lowStock && !itemOutOfStock) && cartData.drop_active ? (
              <p className='available-qty-msg'>Added quantity is not available in stock. Please decrease the quantity or remove product</p>
            ) : ''}
          </>)}
      </div>}
      {removeCartItemModal && (
        <RemoveCartItem
          openModal={() => { toggleCartItemRemove(); }}
          cartId={cartId}
          cartItemId={cartData.id}
          title='Remove Item'
          description='Are you sure you want to remove this item?'
          isOpen={removeCartItemModal}
        />
      )}
      {moveToWishlistModal && (
        <MoveToWishlist
          openModal={() => { toggleMoveToWishlist(); }}
          cartData={cartData}
          title='Move To Wishlist'
          description='Do you want to move this product to Wishlist?'
          isOpen={moveToWishlistModal}
        />
      )}
    </>
  );
};


import React, { useEffect, useState } from 'react';
import { useStores } from '../../../../../models';
import { CollectorsSkeleton } from './CollectorsSkeleton';
import { MagicNumber, PAGE_ROUTES, PROFILE_ICON, USER_ID, COLLECTOR_IMG1, COLLECTOR_IMG2, KEYS } from '../../../../../constants';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { CollectionAndCollectorsHome } from '../collections/CollectionAndCollectorsHome';

export const Collectors = () => {
  const rootStore = useStores();
  const [loader, setLoader] = useState<boolean>(false);
  const { getCollectorList, homeCollectorData } = rootStore.collectionStore;

  const fetchCollectorList = async () => {
    setLoader(true);
    await getCollectorList(MagicNumber.ONE, MagicNumber.FIVE, true);
    setLoader(false);
  };

  useEffect(() => {
    fetchCollectorList();
  }, []);

  const getUserPath = (userId: string) => {
    const userID = Cookies.get(USER_ID);
    return `${PAGE_ROUTES.PROFILE}${userId !== userID ? `/${userId}` : ''}`;
  };

  return (
    <>
      {loader ? (
        <div className="collection-section">
          <CollectionAndCollectorsHome />
        </div>
      ) : (
        <div className="collector-section">
          <h2 className="head-text">Featured Collectors</h2>
          {homeCollectorData.length ? (
            <>
              <div className="image-section">
                <div className="image-row">
                  {/* collector listing section start here  */}
                  <div className="collector-listing-section">
                    {homeCollectorData.map((item: any, keys: any) => {
                      return (
                        <Link
                          to={getUserPath(item.id)}
                        ><div className="listing-row" key={keys}>
                            <div className="collector-detail">
                              <div className="image-box">
                                <img src={item.profile_image}
                                  className="collector-image"
                                  alt="collector"
                                  onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
                                />
                              </div>
                              <p className="name-text">{item.firstname} {item.lastname}</p>
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (<h6>No Collectors added!</h6>)}
        </div>
      )}
    </>
  );
};


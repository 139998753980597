import { Instance, types, flow } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';

export const ContactUsStoreModal = types
  .model('ContactUs')
  .extend(withEnvironment)
  .actions((self) => ({
    createTicket: flow(function* (formData: any) {
      const response = yield self.environment.api.post(`contact-us`, formData);
      return response; 
    })
  }));

type ContactUsType = Instance<typeof ContactUsStoreModal>;
export interface StaticPages extends ContactUsType { }

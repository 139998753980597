
import Skeleton from 'react-loading-skeleton';

export const MyCollectionSkeleton = () => {
    return (
        <div className='profile-collection-list'>
            <div className='profile-collection-skeleton-head'>
                <Skeleton count={1} />
            </div>
            <div className='profile-collection-skeleton-items'>
                <Skeleton count={8} />
            </div>
        </div>
    );
};


import { useEffect, useState } from 'react';
import { FEED_POST_RESTRICTIONS } from '../../../../../constants';
import { useStores } from '../../../../../models';
import { errorToast } from '../../../Atoms';
import { BaseFeedItem, Props } from './BaseFeedItem';
import { FeedItem } from './FeedItem';
import { FeedItemSkeleton } from './skeletons/FeedItemSkeleton';

export const SharedFeedItem = (props: Props) => {

  const rootStore = useStores();
  const { getMoreReactions, getPostDetail, postExists } = rootStore.feedStreamStore;

  const postID = props.feedItem.id ?? '';
  const sharedFeedItem = props.feedItem.object;

  const [isCheckingSharedItem, setIsCheckingSharedItem] = useState<boolean>(false);
  const [isSharedItemInvalid, setIsSharedItemInvalid] = useState<boolean>(false);

  useEffect(() => {
    checkIfSharedItemExists();
  }, []);

  const checkIfSharedItemExists = () => {
    setIsSharedItemInvalid(!!sharedFeedItem.isDeleted);
  };

  return (
    <BaseFeedItem
      feedItem={props.feedItem}
      feed={props.feed}
      streamClient={props.streamClient}
      commentsDisabled={props.commentsDisabled}
      optionsDisabled={props.optionsDisabled}
      reactionsDisabled={props.reactionsDisabled}
      refreshItem={props.refreshItem}
      onDeleteCommentCB={props.onDeleteCommentCB}
      onDeleteFeedCB={props.onDeleteFeedCB}
      onDeleteReplyCB={props.onDeleteReplyCB}
      onReportPostCB={props.onReportPostCB}
      onReportCommentCB={props.onReportCommentCB}
      onReportReplyCB={props.onReportReplyCB}
      onShareFeedCB={props.onShareFeedCB}
      onViewFeedCB={props.onViewFeedCB}
      onViewFeedLikesCB={props.onViewFeedLikesCB}
      onViewFeedSharesCB={props.onViewFeedSharesCB}
      postUpdateAttemptedByGuestCB={props.postUpdateAttemptedByGuestCB}>
      {
        !isCheckingSharedItem && isSharedItemInvalid ?
          <div className='share-box-container'>
            <div className='no-post-section'>
              <p className='nodata'>{FEED_POST_RESTRICTIONS.POST_DOES_NOT_EXIST}</p>
            </div>
          </div> : null
      }
      <div className={!isCheckingSharedItem && isSharedItemInvalid ? '' : 'share-box-container'}
        role='button'
        onClick={() => {
          if (isCheckingSharedItem || isSharedItemInvalid)
            return;
          props.onViewFeedCB?.(sharedFeedItem.id);
        }}>
        {
          isCheckingSharedItem ? <FeedItemSkeleton /> : null
        }
        {
          !isCheckingSharedItem && !isSharedItemInvalid ?
            <FeedItem
              feedItem={sharedFeedItem}
              streamClient={props.streamClient}
              feed={props.feed}
              commentsDisabled
              reactionsDisabled
              optionsDisabled
            /> : null
        }
      </div>
    </BaseFeedItem>
  );
};

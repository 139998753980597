import React, { useEffect, useState } from "react";
import { useStores } from '../../../../models';
import { RemoveCard } from '../modal/RemoveCard';
import { TRASH_ICON, VISA_ICON, STARS_ICON, MagicNumber, MASTER_ICON, AMERICAN_ICON, COLLECTION_ICON, getSavedCardAddress } from './../../../../constants';
import './savedcard.scss';

export const SavedCard = () => {

  const rootStore = useStores();
  const { savedCard, getSavedCard, cardId } = rootStore.cardStore;
  const [removeCardModal, setRemoveCardModal] = useState(false);

  const fetchSavedCard = async () => {
    await getSavedCard();
  }

  useEffect(() => {
    fetchSavedCard();
  }, []);

  const cardExpiry = (expiry: string | null) => {
    return ('0' + expiry).slice(-MagicNumber.TWO)
  }

  const deleteSavedCard = async (id: number | null) => {
    setRemoveCardModal(true);
  }

  const toggleRemoveModal = () => {
    setRemoveCardModal(!removeCardModal);
  }

  const cardImage = (brand: string | null) => {
    let cardIcon = '';
    let classes = 'save-visa-icon';
    if (brand === 'MasterCard') {
      cardIcon = MASTER_ICON;
      classes = 'save-master-icon';
    } else if (brand === 'Visa') {
      cardIcon = VISA_ICON
    } else if (brand === 'American Express') {
      cardIcon = AMERICAN_ICON
    } else {
      classes = 'save-visa-icon';
      cardIcon = '';
    }
    return <img src={cardIcon} alt="" className={classes} />;
  }

  return (
    <>
      {savedCard && savedCard.cardData && savedCard.cardData.card_id ?
        <div className="save-card-outside">
          <div className="save-card">
            <div className="visa-row">
              {cardImage(savedCard && savedCard.cardData && savedCard.cardData.brand)}
              <img className="trash"
                src={TRASH_ICON}
                alt="sold"
                onClick={() => deleteSavedCard(savedCard && savedCard.cardData && savedCard.cardData.id)}
              />
            </div>
            <div className="card-no">
              <img src={STARS_ICON} alt="sold" /> <img src={STARS_ICON} alt="sold" /> <img src={STARS_ICON} alt="sold" /> {savedCard.cardData.last_four_digit || ''}
            </div>
            <div className="expiry">
              <div className="name">Name<span>{savedCard.cardData.account_holder_name || ''}</span></div>
              <div className="name">Expiry Date<span>{`${cardExpiry(savedCard.cardData.exp_month) || ''}/${cardExpiry(savedCard.cardData.exp_year) || ''}`}</span></div>
            </div>
          </div>
        </div> : null
      }
      {savedCard && savedCard.cardData && savedCard.cardData.card_id ?
        <div className="billing-address-panel">
          <div className="inner-box">
            <div className="main-head">Billing Address</div>
            <div className="data-text">
              {getSavedCardAddress(savedCard)}
            </div>
          </div>
        </div> : null}
      {removeCardModal && (
        <RemoveCard
          openModal={() => { toggleRemoveModal(); }}
          id={cardId}
          title="Remove Card"
          description="Are you sure you want to remove this card?"
          isOpen={removeCardModal}
        />
      )}
    </>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import wrapperComponent from '../../Templates/wrapperComponent';
import './searchProducts.scss';
import Products from '../products/Products';
import { useHistory } from 'react-router-dom';
import SearchUser from '../searchUser/searchUser';
import { useStores } from '../../../../models';
import { FullPageLoader } from '../../Atoms';
import { getParam, MagicNumber, STRINGS } from '../../../../constants';
import { ProductSkeleton } from '../products/ProductSkeleton';
import Skeleton from 'react-loading-skeleton';
import FeedListing from '../feedListing/FeedListing';
import Cookies from 'js-cookie';

const SearchProducts = observer(function SearchProducts() {
  const [searchProduct, setSearchProduct] = useState<string>('');
  const [searchLoaded, setSearchLoaded] = useState<boolean>(false);
  const [tab, setTab] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('');
  const history = useHistory();
  const rootStore = useStores();
  const { totalUser, isLoading, setSearchTab, currentSearchTab } =
    rootStore.userStore;
  const { totalProduct } = rootStore.homeStore;
  const { totalPosts } = rootStore.feedStreamStore;
  const componentWillUnmount = useRef(false);
  const analytics = require('react-segment');

  const handleTab = (tabs: string, flag?: string) => {
    setTab(tabs);
    setSearchTab(tabs);
    if (!flag) {
      const hs = history.location.search.split('&');
      history.push({
        search: `${hs[MagicNumber.ZERO]}&tab=${tabs}`,
        state: {
          label: '',
          name: 'All',
          value: ''
        }
      });
    }
  };

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
      setSearchTab('');
      setActiveTab('');
      setTab('');
      collectionPageExited();
    };
  }, []);

  const collectionPageViewed = () => {
    analytics.default.track('Collection Page Viewed');
  };

  const collectionPageExited = () => {
    analytics.default.track('Collection Page Exited');
  };

  useEffect(() => {
    collectionPageViewed();
    return history.listen((location) => {
      const path: any = location.state;
      const splitPath = history.location.search.split('&tab=');
      if (path) {
        setActiveTab(splitPath[1]);
        setTab(splitPath[1]);
        setSearchProduct(path.value);
        setSearchLoaded(true);
      } else {
        setActiveTab(currentSearchTab);
        setTab(STRINGS.IN_COLLECTERS);
        setSearchLoaded(true);
      }
    });
  }, [history, []]);

  useEffect(() => {
    if (history.location.state) {
      const param: any = history.location.state;
      const path = history.location.search.split('&tab=');
      setActiveTab(path[1]);
      setSearchTab(path[1]);
      setTab(path[1]);
      setSearchProduct(param.value);
      setSearchLoaded(true);
    } else {
      setActiveTab(currentSearchTab);
      setTab(STRINGS.IN_COLLECTERS);
      setSearchLoaded(true);
    }
    if (isCollectionPage()) handleTab('collectibles', 'use');
  }, []);

  const isCollectionPage = () => {
    return !!getParam(history.location.search).collection_id;
  };

  return (
    <>
      {totalUser || totalUser === MagicNumber.ZERO ? '' : <ProductSkeleton />}
      <div className='collectors-container-main'>
        {isCollectionPage() ? null : (
          <div className='product-list-container'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a href='/'>Home</a>
                </li>
                <div className='divider-line'>|</div>
                <li className='breadcrumb-item'>
                  <span>Search</span>
                </li>
                <div className='divider-line'>|</div>
                <li className='breadcrumb-item active' aria-current='page'>
                  {searchProduct}
                </li>
              </ol>
            </nav>
          </div>
        )}
        {isCollectionPage() ? null : !isLoading ? (
          <div className='list-header'>
            <div className='head-container'>
              <h2 className='head-title'>
                <span className='total-count'> Browse</span>{' '}
                {totalProduct + totalUser + totalPosts}
                <span className='total-count'> result{`${totalProduct + totalUser + totalPosts === MagicNumber.ONE ? '' : 's'}`}</span>
              </h2>
            </div>
          </div>
        ) : (
          <div className='list-header'>
            <div className='head-container'>
              <Skeleton
                height={MagicNumber.FIFTY}
                width={MagicNumber.TWO_FIFTY}
              />
            </div>
          </div>
        )}
        <nav className='tab-list-section'>
          <div className='tab-container'>
            {isCollectionPage() ? null : (
              <ul className='nav nav-tabs' id='myTab' role='tablist'>
                <li
                  className='nav-item'
                  onClick={() => handleTab('collecters')}
                >
                  <a
                    className={
                      currentSearchTab === STRINGS.IN_COLLECTERS ||
                        !currentSearchTab
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    id='collector-tab'
                    data-toggle='tab'
                    href='#collector'
                    role='tab'
                    aria-controls='collector'
                    aria-selected='true'
                  >
                    Collectors
                  </a>
                </li>
                <li className='nav-item' onClick={() => handleTab('feeds')}>
                  <a
                    className={
                      currentSearchTab === 'feeds'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    id='post-tab'
                    data-toggle='tab'
                    href='#post'
                    role='tab'
                    aria-controls='post'
                    aria-selected='false'
                  >
                    Post
                  </a>
                </li>
                <li
                  className='nav-item'
                  onClick={() => handleTab('collectibles')}
                >
                  <a
                    className={
                      currentSearchTab === STRINGS.IN_COLLECTIBLES
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    id='Collectibles-tab'
                    data-toggle='tab'
                    href='#Collectibles'
                    role='tab'
                    aria-controls='Collectibles'
                    aria-selected='false'
                  >
                    Collectibles
                  </a>
                </li>
              </ul>
            )}
            <div
              className='tab-content'
              style={{ paddingBottom: '0px' }}
              id='myTabContent'
            >
              <div
                className={
                  currentSearchTab === STRINGS.IN_COLLECTERS ||
                    !currentSearchTab
                    ? 'tab-pane fade show active'
                    : 'tab-pane fade'
                }
                id='collector'
                role='tabpanel'
                aria-labelledby='collector-tab'
              >
                {searchLoaded ? <SearchUser search={searchProduct} /> : ''}
              </div>
              <div
                className={
                  currentSearchTab === 'feeds'
                    ? 'tab-pane fade show active'
                    : 'tab-pane fade'
                }
                id='post'
                role='tabpanel'
                aria-labelledby='post-tab'
              >
                <p className='post'>
                  <FeedListing
                    postCreationDisabled
                    postCreationOnNoPostsDisabled
                    titleDisabled
                    noPostsHeading='No result found!'
                    noPostsMessage=''
                    style={{ paddingTop: '0', marginTop: '-30px', paddingBottom: '100px' }}
                  />
                </p>
              </div>
              <div
                className={
                  currentSearchTab === STRINGS.IN_COLLECTIBLES
                    ? 'tab-pane fade show active'
                    : 'tab-pane fade'
                }
                id='Collectibles'
                role='tabpanel'
                aria-labelledby='Collectibles-tab'
              >
                <Products from='search' />
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
});

export default wrapperComponent(true, false, true)(SearchProducts);

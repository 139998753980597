import {
  getOrderTime,
  MagicNumber,
  PRODUCT_PLACEHOLDER,
  ORDER_DETAIL_STATUS,
  USER_PLACEHOLDER,
  getShipmentLogo,
} from '../../../../constants';
import { SkeletonImage } from '../../Atoms/SkeletonImage';
import { OtherItemEntity } from './OrderDetailType';
import { DownloadPopup } from '../../Molecules/downloadPopup/downloadPopup';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TRACK_ICON from '../../../../assets/images/track-icon.png';
import { ShipmentModel } from '../../Molecules/shipmentModel/ShipmentModel';
import Cookies from 'js-cookie';
import { CLOSE_BLACK, ART_IMG5, FULL_SCREEN, WHITE_CLOSE } from '../../../../constants/image';

export const RenderOrderDetail = (props: {
  orderNumber: string;
  orderCreationTime: string;
  itemId: number;
}) => {
  return (
    <div className='order-details-page'>
      <div className='detail-head-info'>
        <div className='section-detail'>
          <div className='name'>Order ID:</div>
          <div className='info'>{props.orderNumber}</div>
        </div>
        <div className='section-detail'>
          <div className='name'>Order Placed:</div>
          <div className='info'>
            {props.orderCreationTime
              ? getOrderTime(new Date(props.orderCreationTime))
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export const RenderItemDetail = (props: {
  itemId: number;
  itemName: string;
  itemDiscountedPrice: string;
  itemDiscount: string;
  itemOriginalPrice: string;
  itemImage: string;
  itemVariants: string[];
  itemCount?: number;
  onClickCB?: (productId: number) => void;
  orderType?: string | null;
  itemImageScan?: any;
  itemIdScan?: any;
}) => {
  return (
    <div className='detail-container'>
      <div className='image-section'>
        <button type='button' className='btn product-button' data-toggle='modal' data-target='#exampleModalCenter'>
          <SkeletonImage
            imageURL={props.itemIdScan != 0 ? props.itemImage : props.itemImageScan}
            classes='product-image'
            alt='product'
            onClick={() => {
              props.onClickCB?.(props.itemId);
            }}
            placeholder={PRODUCT_PLACEHOLDER}
          />
        </button>
        {/* <!-- Modal start --> */}
        <div className='modal fade' id='exampleModalCenter' role='dialog' aria-labelledby='exampleModalCenterTitle' aria-hidden='true'>
          <div className='modal-dialog full-screen-dialog modal-dialog-centered' role='document'>
            <div className='modal-content full-screen-content'>
              <div className='full-screen-page'>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                    <img src={CLOSE_BLACK} alt='closed' className='img-close' />
                </button>
                <div className='order-image-box'>
                  <img src={ART_IMG5} alt='product' className='order-image' />
                  <div className='full-screen-outer'>
                    <button type='button' className='btn full-screen-btn'>
                    <img src={FULL_SCREEN} alt='fullscreen' className='img-fullscreen' />
                      Full Screen
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* full preview */}
            <div className='full-preview-box d-none'>
              <img src={ART_IMG5} alt='product' className='image-product' />
              <div className='preview-close'>
                <button type='button' className='btn preview-close-btn'>
                <img src={WHITE_CLOSE} alt='close' className='img-closed' />
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- modal end --> */}
      </div>
      <div className='detail-section'>
        <div className='text-section'>
          <p className='about'>{props.itemName}</p>
          <div className='right-section'>
            {props.orderType === ORDER_DETAIL_STATUS.IN_COLLECTIONS ? (
              <h3 className='price'>{props.itemDiscountedPrice}</h3>
            ) : (
              <h3 className='price'>
                {props.itemDiscountedPrice}
                <span className='discount-text'>{props.itemOriginalPrice}</span>
                <span className='offter-text'>{props.itemDiscount}</span>
              </h3>
            )}
          </div>
        </div>
        {props.itemVariants.length ? (
          <div className='text-section'>
            <div className='item-condition'>
              {props.itemVariants.map((itemVariant) => {
                if (itemVariant) return <span>{itemVariant}</span>;
                else return null;
              })}
            </div>
          </div>
        ) : null}
        {props.itemCount ? (
          <div className='text-section'>
            <p className='quality-text'>
              Qty: <span>{props.itemCount}</span>
            </p>
          </div>
        ) : null}
        <div className='text-section'>
          <p className='quality-text'>
            {/* Delivery by <span>Mar, 25th 2021</span> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export const RenderShippingAddress = (props: {
  placerName: string;
  placerAddress: string;
  itemId: number;
}) => {
  return (
    <div className='item'>
      <div className='order-details-page'>
        <div className='detail-head-info'>
          <h2 className='detail-heading'>Shipping Address</h2>
        </div>
        <div className='address-section'>
          <h3 className='address-detail'>{props.placerName}</h3>
          <p className='full-info'>{props.placerAddress}</p>
        </div>
      </div>
    </div>
  );
};

export const RenderShipmentTrack = (orderData: any) => {
  const handleShipmentLogo = (name: string) => {
    const logo = getShipmentLogo(name);
    return logo ? <div className='logo-box'>
      <img src={logo} alt='logo' className='logo-icon' />
    </div> : '';
  };

  const trackShipment = (trackUrl: string) => {
    if (trackUrl) {
      window.open(trackUrl);
    }
  };

  return (
    <div className='item'>
      <div className='order-details-page'>
        <div className='detail-head-info'>
          <div className='section-detail'>
            <div className='detail-title'>Track Shipment </div>
          </div>
        </div>
      </div>
      {(orderData && orderData.orderDetails && orderData.orderDetails.status === 'shipped') && orderData && orderData.orderDetails && orderData.orderDetails.order_Shipments && orderData.orderDetails.order_Shipments.length ? <> <div className='delivery-detail'>
        {handleShipmentLogo(orderData.orderDetails.order_Shipments.length && orderData.orderDetails.order_Shipments[MagicNumber.ZERO].carrier_name)}
        <div className='detail-text'>
          {orderData.orderDetails.order_Shipments.length && orderData.orderDetails.order_Shipments[MagicNumber.ZERO].carrier_title}
        </div>
      </div>
        <div className='track-section'>
          <div className='track-number'>
            <h2>Tracking Number: <span>{orderData.orderDetails.order_Shipments.length && orderData.orderDetails.order_Shipments[MagicNumber.ZERO].tracking_no}</span></h2>
            <h2>Note: {orderData.orderDetails.order_Shipments.length && orderData.orderDetails.order_Shipments[MagicNumber.ZERO].note}</h2>
          </div>
          {orderData.orderDetails.order_Shipments.length && orderData.orderDetails.order_Shipments[MagicNumber.ZERO].track_url ? <div className='track-icon'>
            <img src={TRACK_ICON} alt='logo' onClick={() => trackShipment(orderData.orderDetails.order_Shipments[MagicNumber.ZERO].track_url)} className='logo-icon' />
          </div> : ''}
        </div> </> : ''}
      <div className='product-tracking-section'>
        <div className='sales-detail active'>
          <div className='point'></div>
        </div>
        <div className='sales-detail active'>
          <div className='point'></div>
        </div>
        <div className={orderData.orderDetails.status === 'shipped' ? 'sales-detail active' : 'sales-detail'}>
          <div className='point'></div>
        </div>
        <div className='sales-detail'>
          <div className='point'></div>
        </div>
      </div>
      <div className='tracking-text-section'>
        <div className='sales-status'>
          <p className='price'>Confirmed</p>
        </div>
        <div className='sales-status'>
          <p className='price'>Preparing your Order</p>
        </div>
        <div className='sales-status'>
          <p className='price'>Shipped</p>
        </div>
        <div className='sales-status'>
          <p className='price'>Delivered</p>
        </div>
      </div>
    </div>
  );
};

export const RenderOtherItems = (props: {
  otherItems: OtherItemEntity[];
  getItemDiscountPercent: (item: OtherItemEntity) => string;
  getItemOriginalPrice: (item: OtherItemEntity) => string;
  onClickCB?: (productId: number) => void;
}) => {
  if (!props.otherItems || !props.otherItems.length) return null;
  return (
    <div className='item'>
      <div className='order-details-page'>
        <div className='detail-head-info'>
          <div className='section-detail'>
            <div className='other-detail-title'>
              {`Other Item${props.otherItems.length > MagicNumber.ONE ? 's' : ''
                } in this Order`}
            </div>
          </div>
        </div>
      </div>
      {props.otherItems.map((item) => {
        return (
          <RenderItemDetail
            itemId={item.id}
            itemName={item.name}
            itemImage={item.product_image ?? PRODUCT_PLACEHOLDER}
            itemDiscount={props.getItemDiscountPercent(item)}
            itemOriginalPrice={props.getItemOriginalPrice(item)}
            itemDiscountedPrice={item.currency_row_total}
            itemVariants={
              item.variant_data
                ? [
                  item.current_condition,
                  ...item.variant_data.map((e: any) => {
                    return e.title as string;
                  }),
                ]
                : []
            }
            onClickCB={props.onClickCB}
            itemCount={parseInt(item.qty)}
          />
        );
      })}
    </div>
  );
};

export const RenderPriceSection = (props: {
  totalPriceBeforeDiscount: string;
  totalPriceAfterDiscount: string;
  discount: string;
  voucherCode: string;
  deliveryCharges: string;
  itemCount: number;
  orderType: string | null;
  enableTracking: boolean | null;
  closeModal: (e: boolean) => void;
  enableSellingAndAuction?: boolean | null;
  enableScanning?: boolean | null;
  itemId: number;
  status?: string | null;
  orderStatus?: string | null;
  orderId?: any;
  qty?: any;
  seller_id?: number;
  total_tax?: string;
}) => {
  const [isdownlodalModalOpen, opendownloadPopupModal] =
    useState<boolean>(false);
  const [handleShippedModel, setShippedModel] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<number>();
  const history = useHistory();

  // if (!props.itemCount) return null;

  const handleHide = (value: boolean) => {
    setShippedModel(value);
    props.closeModal(value);
  };

  useEffect(() => {
    const user_id = Cookies.get('user_id') || '0';
    if (user_id) {
      setUserId(parseInt(user_id));
    };
  });

  const handleTaxPrice = (tax: any) => {
    var formatUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    if (typeof (tax) === 'string') {
      return formatUSD.format(parseFloat(tax));
    } else {
      return formatUSD.format(tax);
    }
  };

  return (
    <div className='detail-pay-right'>
      <div className='price-sum'>
        {props.itemCount ? <h4>Price Summary</h4> : ''}
        {props.itemCount ? <ul>
          <li>
            {`Price (${props.itemCount} Item${props.itemCount > MagicNumber.ONE ? 's' : ''
              })`}
            <span>{props.totalPriceBeforeDiscount}</span>
          </li>
          {props.voucherCode && props.discount ? (
            <li>
              Discount Applied
              <br />
              {`(${props.voucherCode})`}
              <span>{props.discount}</span>
            </li>
          ) : null}
          <li>
            Delivery Charges<span>{props.deliveryCharges}</span>
          </li>
          <li>
            Sales Tax<span>{props.total_tax}</span>
          </li>
        </ul> : ''}
        <ul className='total-price'>
          {props.itemCount ? <li className='amount-list'>
            Total Amount <span>{props.totalPriceAfterDiscount}</span>
          </li> : ''}
          {(props.enableSellingAndAuction &&
            (props.orderType === ORDER_DETAIL_STATUS.IN_COLLECTIONS || props.orderStatus === ORDER_DETAIL_STATUS.IN_COLLECTIONS)) || !props.itemCount ? (
            <li className='submit-btn auction-btn'>
              <button
                type='button'
                className='btn secondary-btn'
                onClick={() => opendownloadPopupModal(true)}
              >
                Auction
              </button>
              <button
                type='button'
                className='btn primary'
                onClick={() => opendownloadPopupModal(true)}
              >
                Sell Now
              </button>
            </li>
          ) : props.enableTracking ? (
            <li className='submit-btn'>
            </li>
          ) : null}
          {props && props.status === 'shipped' && props.orderStatus !== ORDER_DETAIL_STATUS.IN_COLLECTIONS ? <div className='shipped-button'>
            <h3 className='title-sold'>Shipped</h3>
          </div> : ''}
          {props && props.status === 'processing' && props.seller_id === userId ? <div className='shipped-button'>
            <h3 className='title-sold'>Sold</h3>
            <button
              type='button'
              className='btn primary'
              onClick={() => setShippedModel(true)}
            >
              Mark As Shipped
            </button>
          </div> : ''}
          {(history.location.search.split('tabs=')[MagicNumber.ONE] === 'pending') && props.enableScanning && props.status === 'shipped' || props.status === 'pending' ? (
            <li id='scan-btn'>
              <div className='scan-product-section'>
                <div className='scan-text'>
                  <p>Scan the product barcode to claim ownership.</p>
                </div>
                <div className='scan-button'>
                  <button
                    type='button'
                    className='btn primary'
                    onClick={() => opendownloadPopupModal(true)}
                  >
                    Scan
                  </button>
                </div>
              </div>
            </li>
          ) : null}
        </ul>
      </div>
      <DownloadPopup
        openModal={opendownloadPopupModal}
        isOpen={isdownlodalModalOpen}
      ></DownloadPopup>
      <ShipmentModel
        openModal={handleHide}
        isOpen={handleShippedModel}
        qty={props.qty}
        orderId={props.orderId}
        itemId={props.itemId}
      ></ShipmentModel>
    </div>
  );
};

export const RenderBuyerDetails = (props: {
  buyerUserID: number;
  buyerFullName: string;
  buyerImage: string;
  buyerUserName: string;
  buyerAddress: any;
  itemId: number;
  onBuyerClick: (userId: number) => void;
}) => {
  return (
    <div className='item'>
      <div className='order-details-page'>
        <div className='detail-head-info'>
          <h2 className='detail-heading'>Buyer's Details</h2>
        </div>
        <div className='user-box'>
          <div className='user-image'>
            <SkeletonImage
              imageURL={props.buyerImage ?? USER_PLACEHOLDER}
              placeholder={USER_PLACEHOLDER}
              classes='user-icon'
              alt='user'
              onClick={() => {
                props.onBuyerClick?.(props.buyerUserID);
              }}
            />
          </div>
          <div className='contact-detail'>
            <a
              onClick={() => {
                props.onBuyerClick?.(props.buyerUserID);
              }}
            >
              <p className='name'>{props.buyerFullName}</p>
            </a>
            {props.buyerUserName ? (
              <div className='contact-id'>{`${props.buyerUserName ?? ''
                } `}</div>
            ) : null}
            {props && props.buyerAddress && props.buyerAddress.length ? <p>{`${props.buyerAddress[0].address_1}, ${props.buyerAddress[0].address_2 !== '' ? `${props.buyerAddress[0].address_2},` : ''} ${props.buyerAddress[0].city}, ${props.buyerAddress[0].state}, ${props.buyerAddress[0].country} - ${props.buyerAddress[0].zip_code}`}</p> : ''}
          </div>
        </div>
      </div>
    </div>
  );
};



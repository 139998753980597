
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from '../../../../../models';
import { NoCardFound } from '../../../Molecules/emptyScreens/EmptyScreens';
import { AddCard } from '../../../Molecules/modal/card';
import { CardListing } from './CardListing';
import './cardPaymentDetail.scss';

type IProps = {
  setPrevState: any;
};

export const CardPaymentDetail = observer(function CardPaymentDetail({ setPrevState }: IProps) {

  const rootStore = useStores();
  const { savedCard, isModelOpen, openMd } = rootStore.cardStore;
  const { bid } = rootStore.userStore;
  const [cardModel, setCardModel] = useState<boolean>(false);

  const handleCard = (flag: string) => {
    openMd();
    setCardModel(true);
  };

  const toggleCard = () => {
    setCardModel(false);
  };

  return (
    <>
      <div className="payment-detail-main">
        <div className="address-head">
          <div className="head-left">
            <h2 className="head-text">Payment Details</h2>
          </div>
          {savedCard && savedCard.cardData && savedCard.cardData.id ? <div className="head-right">
            <button type="button" className="btn outline-primary"
              onClick={() => handleCard('another')}>Use Another Card</button>
          </div> : null}
        </div>
        {bid && bid !== '' ? <div className="card-stripe-section">
          <div className="card-stripe">
            <p className="note-text">Note: <span className="note-message">
              Your card will be validated but not charged. Amount will be charged when your bid is accepted by the seller.</span></p>
          </div>
        </div> : ''}
        <div className="detail-container">
          <CardListing onAddCard={() => { handleCard('add'); }} />
        </div>
      </div>
      {cardModel && isModelOpen && (
        <AddCard
          openModal={() => { toggleCard(); }}
          title="Add Card"
          isOpen={cardModel}
          info="cart"
          currentStep={setPrevState}
        />
      )}
    </>
  );
});


import { observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import APPLE_STORE from '../../../../assets/images/apple-store.png';
import GOOGLE_STORE from '../../../../assets/images/google-store.png';
import '../../Atoms/Modal/modal.scss';

type IProps = {
  openModal: (e: boolean) => void;
  isOpen?: boolean;
};

export const DownloadPopup = observer(function Home({
  openModal,
  isOpen,
}: IProps) {
  return (
    <>
      <Modal show={isOpen} onHide={() => openModal(false)} className='modal'>
        <div className='download-app-page'>
          <div className='app-head'>
            <h5 className='title'>Download App</h5>
          </div>
          <div className='app-body'>
            <p className='pg-text'>
              Please download the Mobile App to access this feature.{' '}
            </p>
            <div className='store-icon'>
              <img src={APPLE_STORE} className='apple-image' />
              <img src={GOOGLE_STORE} className='google-image' />
            </div>
          </div>
          <div className='app-footer'>
            <button
              type='button'
              className='btn outline-primary'
              onClick={() => openModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
});

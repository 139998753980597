import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import S3FileUpload from 'react-s3';
import { MagicNumber, FEED_DIR_NAME_IMAGES, FEED_DIR_NAME_VIDEOS, REGION, S3_IMAGE_ERROR, S3_VIDEO_ERROR, createPostValidation, FEED_POST_RESTRICTIONS, VALIDATION_MESSAGES } from '../../../../constants';
import { MENU_ICON_CLOSE, VIDEO_ICON_2, TEXT_ICON, IMAGE_ICON, USER_PLACEHOLDER, ADD_VIDEO_ICON, REMOVE_ICON } from '../../../../constants/image';
import { useStores } from '../../../../models';
import { errorToast, FieldError, FullPageLoader } from '../../Atoms';
import './create-post.scss';
import { observer } from 'mobx-react-lite';
import { SkeletonImage } from '../../Atoms/SkeletonImage';

type Props = {
  title: string,
  isShowing: boolean,
  onHideCB?: () => void,
  onPostUploaded?: () => void,
  onPostDiscarding?: () => void
};

export const CreatePost = observer((props: Props) => {

  enum ContentType {
    text,
    image,
    video
  }

  type Media = {
    file: File,
    url: string
  };

  type FormValue = {
    description: string,
    files: Media[],
    files_type: string,
    duration: number
  };

  const rootStore = useStores();
  const { getProfileDetail, profileDetail, isLoading: isGettingProfile } = rootStore.profileStore;
  const { getS3Config, isLoggedIn } = rootStore.loginStore;
  const { uploadPost } = rootStore.feedStreamStore;

  const [isUploadingPost, setIfUploadingPost] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      description: '',
      files: [],
      files_type: ContentType[ContentType.text],
      duration: MagicNumber.ZERO
    },
    onSubmit: (values: FormValue, { resetForm }: any) => {
      setIfUploadingPost(true);
      const dirName = values.files_type === ContentType[ContentType.image] ? FEED_DIR_NAME_IMAGES : FEED_DIR_NAME_VIDEOS;
      uploadMedia(values.files, dirName)
        .then(async (fileKeys) => {
          await uploadPost(values.description, fileKeys, values.files_type);
          setIfUploadingPost(false);
          props.onPostUploaded?.();
          resetForm();
        })
        .catch(error => {
          setIfUploadingPost(false);
        });
    },
    validateOnMount: false,
    validationSchema: createPostValidation
  });

  useEffect(() => {
    if (isLoggedIn)
      getProfileDetail();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!props.isShowing)
      formik.resetForm();
  }, [props.isShowing]);

  const fetchImage = async (urls: string, fileName: string) => {
    return await fetch(urls)
      .then(async (response: any) => {
        const contentType: any = response.headers.get('content-type');
        const blob = await response.blob();
        const file = new File([blob], fileName, { type: contentType });
        return file;
      });
  };

  const uploadMedia = async (files: Media[], dirName: string) => {
    if (!files || !files.length) {
      return [];
    }

    const s3Config = await getS3Config();
    const config = {
      bucketName: s3Config.data.data.s3.bucket_name,
      dirName: dirName,
      region: REGION,
      accessKeyId: s3Config.data.data.s3.aws_access_key_id,
      secretAccessKey: s3Config.data.data.s3.aws_secret_access_key
    };
    const fileKeys: string[] = [];
    for (let i = 0, length = files.length; i < length; ++i) {
      const media = files[i];
      const name = media.url.split('/');
      const imageData = await fetchImage(media.url, name[name.length - MagicNumber.ONE]);
      await S3FileUpload.uploadFile(imageData, config)
        .then((data: any) => {
          fileKeys.push(data.location);
        }).catch((err: any) => {
          errorToast(dirName === FEED_DIR_NAME_VIDEOS ? S3_VIDEO_ERROR : S3_IMAGE_ERROR);
          throw err;
        });
    }

    return fileKeys;
  };

  const onFileUploaded = (formProps: any, files: FileList | null) => {
    if (!files)
      return;

    const isUploadingImage = formProps.values.files_type === ContentType[ContentType.image];

    if (files.length + formProps.values.files.length >
      (isUploadingImage ? FEED_POST_RESTRICTIONS.MAX_IMAGES : FEED_POST_RESTRICTIONS.MAX_VIDEOS)) {
      formProps.setFieldTouched('files', true, false);
      formProps.setFieldError('files',
        isUploadingImage ? VALIDATION_MESSAGES.POST_IMAGE_MAX_LIMIT : VALIDATION_MESSAGES.POST_VIDEO_MAX_LIMIT);
      return;
    }

    const fileList = [];
    for (let i = 0, length = files.length; i < length; ++i)
      fileList.push({ file: files[i], url: URL.createObjectURL(files[i]) });

    formProps.setFieldValue('files', [...formProps.values.files, ...fileList]);
  };

  const RenderMediaUploadSection = (
    props: {
      files: string[], contentType: ContentType,
      onFilesAdded: (files: FileList | null) => void,
      onFileRemoved: (index: number) => void,
      onDurationRetrieved: (duration: number) => void
    }) => {
    const acceptedFormats = props.contentType === ContentType.video ? 'video/mp4,video/x-m4v,video/*' : 'image/*';
    const uploadIcon = props.contentType === ContentType.video ? ADD_VIDEO_ICON : IMAGE_ICON;
    const multipleAllowed = props.contentType === ContentType.image;
    return (
      <>
        <div className='add-video'>
          <label htmlFor='file-input'>
            <div className='box' role='button' >
              <img src={uploadIcon} className='add-video-icon' alt='user' />
              <input name='files' id='file-input' type='file' hidden={true}
                accept={acceptedFormats} onChange={(e) => { props.onFilesAdded(e.target.files); }} multiple={multipleAllowed} />
            </div>
          </label>
          {
            props.files.map((file, index) => {
              return (
                <div className='box'>
                  {
                    props.contentType === ContentType.image ?
                      <img src={file} className='image-icon' alt='user' /> :
                      <video src={file} className='video-icon' title='video'
                        onLoadedMetadata={e => {
                          const durationInMins = (e.target as any).duration / 60;
                          props.onDurationRetrieved(durationInMins);
                        }}
                      />
                  }
                  <div className='remove-video'>
                    <img src={REMOVE_ICON} className='remove-icon' alt='delete'
                      onClick={() => { props.onFileRemoved(index); }} />
                  </div>
                </div>
              );
            })
          }
        </div>
      </>
    );
  };

  const handleKeyPress = (event: any) => {
    var key = event.charCode;
    if (key === MagicNumber.THIRTY_TWO && !event.target.value.trim().length) {
      event.preventDefault();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        size='sm'
        show={props.isShowing}
        onHide={props.onHideCB}
        backdrop={false}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='theme-modal-dialog create-post-modal-dialog modal-dialog-centered'
        contentClassName='create-modal-content'
      >
        <div className='create-post-page'>
          <Modal.Header>
            <div className='post-header'>
              <div className='close-icon' onClick={() => {
                if ((formik.values.description || formik.values.files.length)) {
                  props.onPostDiscarding?.();
                } else {
                  props.onHideCB?.();
                }
              }}>
                <img src={MENU_ICON_CLOSE} className='close-image' alt='close' />
              </div>
              <Modal.Title className='feed-modal-title' id='example-modal-sizes-title-sm'>
                {props.title}
                <div className='responsive-post-button'>
                  <button className='btn primary' onClick={() => { formik.handleSubmit(); }}>Post</button>
                </div>
              </Modal.Title>
              <div className='content-type-section'>
                <div className='type-container'>
                  <div className='type-box'>
                    <p className='type-text'>Content Type</p>
                  </div>
                </div>
                <div className='type-container'>
                  <div className='type-box'>
                    <div className='black-radio-custom'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='files_type'
                        id='text'
                        value={ContentType[ContentType.text]}
                        checked={formik.values.files_type === ContentType[ContentType.text]}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.values.files = [];
                        }}
                      />
                      <label id='text' htmlFor='text'
                        className={`content-label ${formik.values.files_type === ContentType[ContentType.text] ? 'post-active' : ''}`}>
                        <img src={TEXT_ICON} className='content-icon' alt='content' />
                        Text
                      </label>
                    </div>
                  </div>
                  <div className='type-box'>
                    <div className='black-radio-custom'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='files_type'
                        id='video'
                        value={ContentType[ContentType.video]}
                        checked={formik.values.files_type === ContentType[ContentType.video]}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.values.files = [];
                        }}
                      />
                      <label id='video' htmlFor='video'
                        className={`content-label ${formik.values.files_type === ContentType[ContentType.video] ? 'post-active' : ''}`}
                      >
                        <img src={VIDEO_ICON_2} className='content-icon' alt='content' />Video</label>
                    </div>
                  </div>
                  <div className='type-box'>
                    <div className='black-radio-custom'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='files_type'
                        id='image'
                        value={ContentType[ContentType.image]}
                        checked={formik.values.files_type === ContentType[ContentType.image]}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.values.files = [];
                        }}
                      />
                      <label id='' htmlFor='image'
                        className={`content-label ${formik.values.files_type === ContentType[ContentType.image] ? 'post-active' : ''}`}
                      >
                        <img src={IMAGE_ICON} className='content-icon' alt='content' />Image
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className='post-body'>
            <div className='about-user'>
              <div className='user-img-box'>
                <SkeletonImage
                  imageURL={profileDetail.profile_image ?? USER_PLACEHOLDER}
                  placeholder={USER_PLACEHOLDER}
                  classes='user-icon'
                  alt='user'
                />
              </div>
              <p className='user-detail'>{`${profileDetail.firstname} ${profileDetail.lastname}`}</p>
            </div>
            <div className='type-user'>
              <textarea
                name='description'
                className='form-control type-control'
                placeholder='Write Text...'
                onChange={formik.handleChange}
                onKeyPress={(event) => handleKeyPress(event)}
                value={formik.values.description}
              />
              <div className='error-msg'>{formik.errors.description}</div>
            </div>
            {
              formik.values.files_type !== ContentType[ContentType.text] ?
                <div className='video-add-section'>
                  {formik.values.files_type === ContentType[ContentType.video] ?
                    <p className='video-title'>Maximum {FEED_POST_RESTRICTIONS.MAX_VIDEO_DURATION} mins</p> : null}
                  <RenderMediaUploadSection
                    files={formik.values.files.map((media: Media) => { return media.url; })}
                    contentType={ContentType[formik.values.files_type as keyof typeof ContentType]}
                    onFilesAdded={(files) => {
                      onFileUploaded(formik, files);
                    }}
                    onFileRemoved={(index) => {
                      const updatedFiles = [...formik.values.files];
                      updatedFiles.splice(index, MagicNumber.ONE);
                      formik.values.files = updatedFiles;
                      formik.setFieldValue('files', updatedFiles);
                    }}
                    onDurationRetrieved={(duration) => {
                      formik.values.duration = duration;
                    }}
                  />
                  <div className='error-msg'>{formik.errors.files}</div>
                </div> : null
            }
            <div className='post-button'>
              <button className='btn primary' onClick={() => { formik.handleSubmit(); }}>Post</button>
            </div>
          </Modal.Body>
        </div>
      </Modal >
      {isUploadingPost ? <FullPageLoader /> : null}
    </form>
  );
});

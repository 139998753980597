import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { INFO_HELP } from './../../../constants';

type IProps = {
  condition: string,
  conditionDescription: string
};

export const ConditionRow = ({ condition, conditionDescription }: IProps) => {

  const [showDescription, setShowDescription] = useState<boolean>(false);

  if (!condition) {
    return <></>;
  } else return (
    <>
      <div className='detail-row'>
        <div className='item-detail'>Condition</div>
        <div className='item-info'>{condition}
          {
            conditionDescription ?
              <div className='info-box' onClick={() => { setShowDescription(true) }} role='button'>
                <img src={INFO_HELP} className='info-help-icon' alt='info' />
              </div> : null
          }
        </div>
      </div>
      <Modal
        size='sm'
        show={showDescription}
        onHide={() => setShowDescription(false)}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog modal-dialog-centered'
        contentClassName='remove-modal-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{conditionDescription}</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-blank remove btn-centered' onClick={() => setShowDescription(false)}>ok</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import Skeleton from 'react-loading-skeleton';
import { SKELETON_COUNT, MagicNumber } from './../../../../../constants';

export const CollectionAndCollectorsHome = () => {

    let skeletonListItems = [];

    for (var i = MagicNumber.ZERO; i < SKELETON_COUNT.PRODUCT_FIVE; i++) {
        skeletonListItems.push(<Skeleton count={MagicNumber.ONE} />);
    }
    return (
        <div className="product-list-skeleton-two">
            {skeletonListItems}
        </div>
    )
};

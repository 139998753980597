import { useState } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  title: string,
  isShowing: boolean,
  description?: string,
  cancelOptionName?: string,
  confirmOptionName?: string,
  onConfirmCB?: (confirmed: boolean) => Promise<boolean>,
};

export const ConfirmationBox = (props: Props) => {
  const [waitingForConfirmation, setIsWaitingForConfirmation] = useState<boolean>(false);

  return (
    <>
      <Modal
        size='sm'
        show={props.isShowing}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog modal-dialog-centered'
        contentClassName='remove-modal-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {props.title}
          </Modal.Title>
        </Modal.Header>
        {
          props.description ?
            <Modal.Body>
              <p>{props.description}</p>
            </Modal.Body> : null
        }
        <Modal.Footer>
          <button className='btn btn-blank'
            onClick={() => {
              props.onConfirmCB?.(false);
            }}>
            {props.cancelOptionName ?? 'Cancel'}
          </button>
          <button className='btn btn-blank remove'
            disabled={waitingForConfirmation}
            onClick={() => {
              if (props.onConfirmCB) {
                setIsWaitingForConfirmation(true);
                props.onConfirmCB(true).finally(() => {
                  setIsWaitingForConfirmation(false);
                });
              }
            }}>
            {props.confirmOptionName ?? 'Confirm'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import Skeleton from 'react-loading-skeleton';
import { FeedItemSkeleton } from './FeedItemSkeleton';

export const FeedDetailSkeleton = () => {
  return (
    <div className='feed-detail-container'>
      <div className='detail-main-container'>
        <div className='detail-left'>
          <div className='left-container'>
            <div className='back-button'>
              <Skeleton className='back-icon' />
              <Skeleton className='back-text' width={50} />
            </div>
            <div className='product-carousel-section'>
              <div className='preview-carousel'>
                <div className='carousel'>
                  <div className='carousel-inner'>
                    <div className={`carousel-item active`}>
                      <div className='carousel-box'>
                        <div className='product-item'>
                          <Skeleton className='product-image' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='select-product-section'>
                <div className={'product-box active-product'}>
                  <Skeleton className='select-product-img' />
                </div>
              </div>
            </div>
            <div className='feed-detail-text'>
              <Skeleton width={250} />
            </div>
          </div>
        </div>
        <div className='detail-right'>
          <FeedItemSkeleton />
        </div>
      </div>
    </div>
  );
};

function ContactUsInput(props:any) {
    const{mainClass, inputType, inputValue, inputName, handleChange, labelName, errorMessage, handleBlur, checkBlur} = props.props;
    return (
        <div className={mainClass}>
            <input  type={inputType} value={inputValue} name={inputName} onChange={handleChange} onBlur={handleBlur} />
            <label className={`label ${inputValue? 'label-top' : ''}`}> {labelName} </label>
            {checkBlur && errorMessage? <div className='error'> {errorMessage} </div>: <div className='error'></div>}
        </div>
    );
};

export default ContactUsInput;

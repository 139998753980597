import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';
import './_viewAllBids.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../models';
import { MagicNumber, WHITE_CLOSE_ICON, HTTPSTATUSCODE, OTP_SENT } from '../../../../constants';
import { errorToast, FullPageLoader, successToast } from '../../Atoms';
import { OtpModal } from '../otpModal/otpModal';

type IProps = {
  openModal: any;
  isOpen: boolean;
  userData: any;
};

export const AddBio = observer(function ViewAllBids({ openModal, isOpen, userData }: IProps) {

  const rootStore = useStores();
  const { updateUserData, getProfileDetail } = rootStore.profileStore;
  const [loader, setLoader] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [isOtpModalOpen, openOtpModal] = useState<boolean>(false);
  const [isBioAvailable, setBioAvailable] = useState<boolean>(false);
  const [userBio, setBio] = useState<any>('');

  useEffect(() => {
    if (userData && userData.bio) {
      setBio(userData.bio);
    }
  }, []);

  const updateUserBio = () => {
    if (!userBio) {
      setBioAvailable(true);
      return;
    }
    setLoader(true);
    const { firstname, lastname, username, email } = userData;
    const data = {
      firstname: firstname,
      lastname: lastname,
      username: username,
      email: email,
      bio: userBio.trim()
    };
    updateUserData(data).then(res => {
      if (res.data) {
        setBio('');
        if (res.data.statusCode === HTTPSTATUSCODE.BAD_REQUEST) {
          errorToast(res.data.message);
        }else {
          if (userData.email !== data.email) {
            setEmail(data.email);
            successToast(OTP_SENT);
            openOtpModal(true);
          }
          openModal(false);
          getProfileDetail();
        }
      }
      setLoader(false);
    });
  };

  const handleInput = (event: any) => {
    setBio(event.target.value);
  };

  const handleKeyPress = (evt: any) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode === MagicNumber.THIRTY_TWO && evt.target.value == '') {
      evt.preventDefault();
    }
    return true;
  };

  return (
    <>
      {loader && <FullPageLoader />}
      <Modal
        size='sm'
        show={isOpen}
        backdrop={false}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='edit-bio-page modal-dialog-centered'
        contentClassName='edit-bio-content'
      >
        <div className='edit-bio-background'>
          <Modal.Header>
            <Modal.Title id='example-modal-sizes-title-sm'>
              <h1 className='title-text bio-title'>
                Bio
              </h1>
            </Modal.Title>
            <div className='close-icon place-close-icon' onClick={() => openModal(false)}>
              <img src={WHITE_CLOSE_ICON} className='close-image' alt='close' />
            </div>
          </Modal.Header>
          <Modal.Body className='bio-body'>
            {isBioAvailable && !userBio.length ? <span className='error-bio'>Please enter bio.</span> : ''}
            <textarea
              className='form-control bio-textarea'
              value={userBio}
              placeholder='Write your bio...'
              maxLength={MagicNumber.TWO_H}
              onKeyPress={(evt: any) => handleKeyPress(evt)}
              onChange={(event) => handleInput(event)}> </textarea>
            <div className='text-limit'>
              <p className='limit-info'>{userBio.length}/200</p>
            </div>
            <div className='bio-submit-button'>
              <button type='button' className='btn primary' onClick={updateUserBio}>Save</button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <OtpModal openModal={openOtpModal} isOpen={isOtpModalOpen} email={email} isRegister={false} isEmailUpdate={true} />
    </>
  );
});

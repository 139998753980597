import Skeleton from 'react-loading-skeleton';

export const DetailSkeleton = () => {
    return (
        <div className="detail-container detail-container-skeleton">
            <div className="detail-left">
                <div className="large-image">
                    <Skeleton />
                </div>
                <div className="small-image">
                    <Skeleton count={4} />
                </div>
                <div className="detail-left-comment">
                    <div className="detail-left-comment-head">
                        <Skeleton />
                    </div>
                    <div className="detail-left-comment-input">
                        <Skeleton count={2} />
                    </div>
                    <div className="detail-left-comment-section">
                        <div className="detail-left-comment-image">
                            <Skeleton />
                        </div>
                        <div className="detail-left-comment-text">
                            <Skeleton count={2} />
                            <Skeleton count={1} />
                            <Skeleton count={1} />
                            <Skeleton count={2} />
                        </div>
                    </div>
                    <div className="detail-left-comment-section">
                        <div className="detail-left-comment-image">
                            <Skeleton />
                        </div>
                        <div className="detail-left-comment-text">
                            <Skeleton count={2} />
                            <Skeleton count={1} />
                            <Skeleton count={1} />
                            <Skeleton count={2} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="detail-right">
                <div className="detail-right-head">
                    <Skeleton count={2} />
                </div>
                <div className="detail-right-head md">
                    <Skeleton count={1} />
                </div>
                <div className="detail-right-head lg">
                    <Skeleton count={1} />
                </div>
                <div className="detail-right-size">
                    <Skeleton count={1} />
                    <div className="detail-right-size-list">
                        <Skeleton count={4} />
                    </div>
                </div>
                <div className="detail-right-color">
                    <Skeleton count={1} />
                    <div className="detail-right-size-list">
                        <Skeleton count={4} />
                    </div>
                </div>
                <div className="detail-right-action-btn">
                    <Skeleton count={2} />
                </div>
                <div className="detail-right-accordion">
                    <Skeleton count={8} />
                </div>
            </div>
        </div>
    )
};


import Skeleton from 'react-loading-skeleton';
import { MagicNumber } from '../../../../constants';

export const GridListSkeleton = () => {
  const totalItems = new Array(MagicNumber.EIGHT).fill(0);
  return (
    <div className='pending-purchase-section'>
      <nav className='list-header'>
        <div className='head-container'>
          <h4 className='head-title'>
            <Skeleton className='total-count' width={150} />
          </h4>
        </div>
      </nav>
      <div className='product-image-section'>
        <div className='image-row'>
          <div className='container-half' style={{ cursor: 'pointer' }}>
            {
              totalItems.map(item => {
                return (
                  <div className='image-box'>
                    <Skeleton className='image-product' width={300} height={300} />
                    <div className='product-status'>
                      <Skeleton width={110} height={25} />
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div >
    </div >
  );
};

import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { BLACK_ICON, MagicNumber, PROFILE_ICON } from '../../../../../constants';

type Iprops = {
  data: any;
  followCount: any;
};

export const PublicProfileView = ({data, followCount}: Iprops) => {

    const history = useHistory();

  return (
    <>
    <span onClick={()=>{history.goBack(); }} className='back-btn'><img src={BLACK_ICON} alt='back' className='back-icon' />Back</span>
      <div className='profile-inline step-1'>
        <div className='profile-pic user-profile'>
          <img
            src={data.profile_image}
            alt='user image'
            onError={(e: any) => (e.target.onerror = null, e.target.src = PROFILE_ICON)}
          />
        </div>
        <div className='profile-content'>
        <div className='left-item'>
            <div className='name'>
              {data && data.firstname} {data && data.lastname}
              <span className='nameid'>{data && data.username}</span>
            </div>
            <ul className='follow-content list-cursor-default'>
              <li>
                <span className='count'>
                  {followCount && followCount.followerCount}
                </span> {parseInt(followCount && followCount.followerCount) > MagicNumber.ONE ? 'Followers' : 'Follower'}</li>
              <li>
                <span className='count'>
                  {followCount && followCount.followingCount}
                </span> Following</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};


import Skeleton from 'react-loading-skeleton';

export const CardPaymentSkeleton = () => {
  return (
    <div className="payment-card-skeleton">
        <div className="card-box">
            <div className="detail">
              <Skeleton count={4} />
            </div>
            <div className="cvv">
              <Skeleton />
            </div>
        </div>
        <div className="billing-box">
            <div className="head-text">
              <Skeleton />
            </div>
            <div className="body-text">
              <Skeleton />
            </div>
        </div>
    </div>
  )
};

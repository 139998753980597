import { types, Instance } from 'mobx-state-tree';

export const SavedAddressModel = types.model({
  user_id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  address_1: types.maybeNull(types.string),
  address_2: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  zip_code: types.maybeNull(types.string),
  is_default: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
});

export const SavedCardItems = types.model({
  account_holder_name: types.maybeNull(types.string),
  card_id: types.maybeNull(types.string),
  card_token: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  exp_month: types.maybeNull(types.string),
  exp_year: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  last_four_digit: types.maybeNull(types.string),
  source_token: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  user_id: types.maybeNull(types.number),
});

export const SavedCardItem = types.model({
  statusCode: types.maybeNull(types.number),
  message: types.maybeNull(types.string),
  cardData: types.maybeNull(SavedCardItems)
});



type SavedAddressModelType = Instance<typeof SavedAddressModel>;
export interface SavedAddressModelI extends SavedAddressModelType { }

import React, { useState, useEffect } from 'react';
import { Tab, Nav, } from 'react-bootstrap';
import { SavedCard, AddShippingAddress } from './../../Molecules';
import { ShippingAddress } from './../../Pages';
import './tabs.scss';
import { AddCard } from '../modal/card';
import { useStores } from '../../../../models';
import { observer } from 'mobx-react-lite';
import ContactUs from '../../Pages/contactUs/ContactUs';
import  DeleteUser  from '../../Pages/DeleteUser/DeleteUser';
import Faq from '../../Pages/faq/Faq';
import { LinkCard } from '../modal/LinkCard';
import { LinkedCard } from '../savedCard/LinkedCard';
import { COLLECTION_ICON, LINKED_ICON } from '../../../../constants';
import { NoAddressFound, NoCardFound } from '../emptyScreens/EmptyScreens';

type TabLinksProps = {
  title: string;
  data?: any;
  target?: string,
  iconName: string;
  responsiveClass: any;
};
type TabContentProps = {
  data: any;
  target?: string,
};
export const TabLinks = observer(function TabLinks({ title, target, iconName, responsiveClass }: TabLinksProps) {

  return (
    <Nav.Item
      onClick={responsiveClass}
    >
      <Nav.Link
        eventKey={target}>
        <span className={`icon icon-${iconName}`} />
        {title}
      </Nav.Link>
    </Nav.Item>
  );
});

export const TabContent = observer(function TabContent({ data, target, }: TabContentProps) {
  const [shippingAddressModal, setShippingAddressModal] = useState<boolean>(false);
  const [cardModel, setCardModel] = useState<boolean>(false);
  const [linkCardModel, setLinkCardModel] = useState<boolean>(false);
  const rootStore = useStores();
  const { isModelOpen, openMd, getSavedCard, savedCard, getLinkedCard, linkedCards } = rootStore.cardStore;

  const fetchData = async () => {
    await getSavedCard();
    await getLinkedCard();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const toggle = () => {
    setShippingAddressModal(!shippingAddressModal);
  };
  const toggleCard = () => {
    setCardModel(!cardModel);
  };
  const linkToggleCard = () => {
    setLinkCardModel(!linkCardModel);
  };
  const initalvalues = {
    name: '',
    phone: '',
    country: 'US',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: '',
    is_default: false
  };

  const openModel = () => {
    openMd();
    setCardModel(true);
  };

  const openLinkCardModel = () => {
    openMd();
    setLinkCardModel(true);
  };

  const getTabData = () => {
    if (target === 'shippingAddress')
      return (
        <div className='shipping-address-wrapper'>
          <h2 className='responsive-heading'>Shipping Address</h2>
          {data.length ? (
            <div className='shipping-address-head'>
              <h2>{data.length} {data.length >= 2 ? 'Saved Addresses' : 'Saved Address'}</h2>
              {data.length <= 3 &&
                <button
                  type='button'
                  className='btn primary'
                  onClick={() => setShippingAddressModal(true)}
                >
                  Add New
                </button>
              }
            </div>
          ) : (
            <NoAddressFound onClick={() => setShippingAddressModal(true)} />
          )}
          <div className='shipping-address-body'>
            {data.map((item: any,) => {
              return (
                <ShippingAddress itemData={item} />
              )
            })}
            {shippingAddressModal && (
              <AddShippingAddress
                openModal={() => { toggle(); }}
                activeAddress={initalvalues}
                title='Add Shipping Address'
                shippingAddressMode='Add'
                isOpen={shippingAddressModal}
              />
            )}
          </div>
        </div>
      );
    else if (target === 'SavedCard')
      return (
        <>
          <div className='save-button-section'>
            <div className='add-button-head'>
              {
                savedCard && savedCard.cardData && savedCard.cardData.card_id ? null : <NoCardFound onClick={openModel} />
              }
            </div>
          </div>
          <SavedCard />
          {cardModel && isModelOpen && (
            <AddCard
              openModal={() => { toggleCard(); }}
              title='Add Card'
              isOpen={cardModel}
              info='add'
            />
          )}
        </>
      );
    else if (target === 'PublicPrivate')
      return (
        <div><p className='empty-text'>Coming Soon</p></div>
      );
    else if (target === 'ContactUs')
      return (
        <div><p className='empty-text'> <ContactUs /> </p></div>
      );
    else if (target === 'FAQ')
      return (
        <div><p className='empty-text'> <Faq /> </p></div>
      );
      else if (target === 'Delete')
      return (
        <div><p className='empty-text'> <DeleteUser/> </p></div>
      );
    else {
      return (
        <>
          <div className='save-button-section'>
            <div className='add-button-head'>
              <div className='left-section'>
                {linkedCards && linkedCards.cardData && linkedCards.cardData.card_id ? <p className='head-text'>Payout Card</p> : ''}
                {linkedCards && linkedCards.cardData && linkedCards.cardData.card_id ? '' :
                  <div className='no-linked-section'>
                    <img src={LINKED_ICON} className='no-link-icon' alt='link' />
                    <h2 className='link-text'>No Payout Card!</h2>
                    <h2 className='link-pg'>You haven’t linked any card yet. When you do they’ll show here.</h2>
                    <div className='submit-btn'>
                      <button type='button' onClick={() => openLinkCardModel()} className='btn primary'>Link Card</button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <LinkedCard openModal={() => { linkToggleCard(); }} />
          {linkCardModel && isModelOpen && (
            <LinkCard
              openModal={() => { linkToggleCard(); }}
              title='Link Card'
              isOpen={linkCardModel}
              info='add'
            />
          )}
        </>
      );
    }
  };

  return (
    <Tab.Pane eventKey={target}>
      <p className='table-list'>{getTabData()}</p>
    </Tab.Pane>
  );
});

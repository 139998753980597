import { useEffect, useState } from 'react';
import { PRODUCT_PLACEHOLDER, MUSIC_ICON, PDF_ICON, DISCOUNT_CODE_OVERLAY, DIGITAL_CONTENT_TYPE, LOCK_ICON } from '../../../../../constants';
import { DigitalContentView } from '../../../Molecules';
import { Image } from '../../../Atoms/Image';
import { INFO_HELP } from '../../../../../constants';
import { DownloadPopup } from '../../../Molecules/downloadPopup/downloadPopup';
import { Modal } from 'react-bootstrap';
import DigitalContentModel from '../../../Atoms/Modal/digitalContentModel';

export const DigitalItem = (props: {
  srcUrl: string,
  itemType: string,
  itemLocked: boolean,
  itemValue: string, key?: number,
  description?: string
}) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isdownlodalModalOpen, opendownloadPopupModal] =
    useState<boolean>(false);
  // const [description, setDescription] = useState(false);
  const [toggleMain, setMainToggle] = useState(false);
  const [show, setShow] = useState(false);


  const toggleDigitalContentView = (event?: any) => {
    if (event && event.target && event.target.id === 'digi1' || !event) {
      if (props.itemLocked)
        return;
      setOpen(!isOpen);
    }
  };

  const handlePopUp = (event?: any) => {
    if (event) {
      setMainToggle(false);
      opendownloadPopupModal(true);
    } else {
      setMainToggle(!toggleMain);
    }
  };

  const handleShowDescription = () => {
    if (props.description) {
      setShow(true);
    };
  };

  return (
    <>
      {props.itemType === DIGITAL_CONTENT_TYPE.DOCUMENT ? (
        <div className='digital-content-item digital-doc' key={props.key}>
          <img
            src={props.srcUrl}
            alt='digital content'
            onError={(e: any) => (e.target.onerror = null, e.target.src = PRODUCT_PLACEHOLDER)}
          />
          {
            props.itemLocked ?
              <div className='lock-icon' onClick={() => handlePopUp()}>
                <Image src={LOCK_ICON} className='lock-image' alt='lock3' />
              </div> :
              <a href={props.itemValue} target='_blank' className='doc-downlaod-icon' />
          }
          {props.description ? <div className='info-box'>
            <img src={INFO_HELP} className='info-help-icon' onClick={() => handleShowDescription()} alt='info' />
          </div> : ''}
        </div>
      ) : (
        <div className='digital-content-item' key={props.key} id='digi1' onClick={(event) => toggleDigitalContentView(event)}>
          <div><img
            src={props.srcUrl}
            alt='digital content1'
            onError={(e: any) => (e.target.onerror = null, e.target.src = PRODUCT_PLACEHOLDER)}
          /></div>
          {
            props.itemLocked ?
              <div className='lock-icon'>
                <Image src={LOCK_ICON} className='lock-image' alt='lockq' onClick={() => handlePopUp()} />
              </div> : null
          }
          {props.description ? <div className='info-box'>
            <img src={INFO_HELP} id='digi2' className='info-help-icon' onClick={() => handleShowDescription()} alt='info' />
          </div> : ''}
        </div>
      )
      }
      <DigitalContentView
        isOpen={isOpen}
        openModal={() => { toggleDigitalContentView(); }}
        itemData={props.itemValue}
        itemType={props.itemType}
      />
      <DownloadPopup
        openModal={opendownloadPopupModal}
        isOpen={isdownlodalModalOpen}
      ></DownloadPopup>
      <DigitalContentModel
        show={toggleMain}
        subHeading='Unlock Digital Content'
        description='Please scan and move the product to My Collection to access the Digital Content.'
        setToggle={(event) => handlePopUp(event)}
      />
      <Modal
        size='sm'
        show={show}
        oonHide={() => setShow(false)}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog modal-dialog-centered'
        contentClassName='remove-modal-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-blank remove btn-centered' onClick={() => setShow(false)}>ok</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const OrderDetailDigitalContent = (props: { data: any, itemsLocked: boolean }) => {
  const [isDigitalContent, setDigitalContent] = useState<boolean>(true);

  useEffect(() => {
    const { discount_code, audios, documents, images, videos } = props.data;
    if ((discount_code === '' || discount_code === null) &&
      !audios.length && !documents.length &&
      !images.length && !videos.length) {
      setDigitalContent(false);
    }
  }, []);

  return (
    <>
      {isDigitalContent ? (
        <div className='item'>
          <div className='order-details-page'>
            <div className='detail-head-info'>
              <h2 className='detail-heading'>Digital Content</h2>
            </div>
            <div className='digital-section'>
              {props.data && props.data.discount_code !== '' && props.data.discount_code ? (
                <DigitalItem
                  srcUrl={DISCOUNT_CODE_OVERLAY}
                  itemType={DIGITAL_CONTENT_TYPE.DIGITAL_CODE}
                  itemValue={props.data.discount_code}
                  itemLocked={props.itemsLocked}
                />
              ) : ('')}
              {props.data && props.data.audios.length ? props.data.audios.map((e: any, key: number) => {
                return (
                  <DigitalItem
                    srcUrl={MUSIC_ICON}
                    itemType={DIGITAL_CONTENT_TYPE.AUDIO}
                    itemValue={e.filePath}
                    itemLocked={props.itemsLocked}
                    description={e.description}
                  />
                );
              }) : ('')}
              {props.data && props.data.documents.length ? props.data.documents.map((e: any, key: number) => {
                return (
                  <DigitalItem
                    srcUrl={PDF_ICON}
                    itemType={DIGITAL_CONTENT_TYPE.DOCUMENT}
                    itemValue={e.filePath}
                    itemLocked={props.itemsLocked}
                    description={e.description}
                  />
                );
              }) : ('')}
              {props.data && props.data.images.length ? props.data.images.map((e: any, key: number) => {
                return (
                  <DigitalItem
                    srcUrl={e.filePath}
                    itemType={DIGITAL_CONTENT_TYPE.IMAGE}
                    itemValue={e.filePath}
                    itemLocked={props.itemsLocked}
                    description={e.description}
                  />
                );
              }) : ('')}
              {props.data && props.data.videos.length ? props.data.videos.map((e: any, key: number) => {
                return (
                  <DigitalItem
                    srcUrl={e.filepathThumb}
                    itemType={DIGITAL_CONTENT_TYPE.VIDEO}
                    itemValue={e.filePath}
                    itemLocked={props.itemsLocked}
                    description={e.description}
                  />
                );
              }) : ('')}
            </div>
          </div>
        </div>
      ) : ('')}
    </>
  );
};

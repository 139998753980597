import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import { validateEmail, OTP_SENT } from '../../../../constants';
import { Modal, FieldError, Input, successToast } from '../../Atoms';
import { OtpModal } from '../otpModal/otpModal';
import { useStores } from '../../../../models';

type IProps = {
    openModal: (e: boolean) => void;
    isOpen?: boolean;
    openSigninModal: (e: boolean) => void;
};

export const ForgotPassModal = observer(function Home({ openModal, isOpen, openSigninModal }: IProps) {
    const rootStore = useStores();
    const { forgotPassword, forgotPassMsg } = rootStore.loginStore;
    const [isOtpModalOpen, openOtpModal] = useState<boolean>(false);
    const [email, selEmail] = useState<string>('');
    const [errStatus, displayErr] = useState<boolean>(false);
    const [flag, setFlag] = useState<boolean>(false);

    useEffect(() => {
        setFlag(false);
    }, []);

    const handleLogin = async (values: any) => {
        displayErr(false);
        setFlag(true);
        selEmail(values.email);
        await forgotPassword(values).then((res) => {
            if (res.statusCode === 201) {
                openModal(false);
                openOtpModal(true);
                successToast(OTP_SENT);
                setFlag(false);
            }
            if (res.statusCode === 404) {
                displayErr(true);
                setFlag(false);
            }
        });
    };

    const emptyState = () => {
        setFlag(false);
        displayErr(false);
        openModal(false);
    };


    return (<>
        <Modal
            isOpen={isOpen}
            openModal={emptyState}
            header='Forgot Password'
            isSmall
            subHeading='To reset your password, enter the email associated with your account. We’ll send you a text verification code.' >
            <Formik
                initialValues={{ email: '' }}
                onSubmit={async values => {
                    await handleLogin(values);
                }}
                validationSchema={validateEmail}
                render={({ handleSubmit, values, setFieldValue, dirty }) => (
                    <Form className='form-section'>
                        <FieldError name='email' />
                        <div className='form-group'>
                            <Input
                                required
                                type='email'
                                id='email'
                                name='email'
                                placeholder='Email'
                                value={values.email}
                                setFieldValue={setFieldValue}
                            />
                            {errStatus && <FieldError message={forgotPassMsg.message} />}
                            
                        </div>
                        <div className='submit-button'>
                            <button type='button' className='btn primary' disabled={!dirty ? true : false} onClick={(e: any) => handleSubmit(e)}>Continue</button>
                        </div>
                    </Form>
                )} />
        </Modal>
        <OtpModal openModal={openOtpModal} isOpen={isOtpModalOpen} email={email} openSigninModal={openSigninModal} isRegister={false} />
    </>
    );
});

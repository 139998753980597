import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  WHITE_CLOSE_ICON,
  SUCCESS_ICON,
  SIGNIN_VERI,
  APPLE_STORE,
  GOOGLE_STORE,
} from '../../../../constants';
import { useStores } from '../../../../models';
import './modal.scss';

interface IProps {
  openModal: (e: boolean) => void;
  onModalExit?: any;
  isOpen?: boolean;
  header?: string;
  subHeading?: string;
  isSmall?: boolean;
  isImage?: boolean;
  isNotify?: boolean;
  isSignUp?: boolean;
}

const ModalContainer: React.FC<IProps> = ({
  children,
  subHeading,
  isOpen,
  openModal,
  header,
  isSmall,
  isImage,
  onModalExit,
  isNotify,
  isSignUp
}) => {
  function createMarkup(data: any) {
    return { __html: data };
  }

  const rootStore = useStores();
  const { setUserName, setUserEmail } = rootStore.loginStore;

  const handleClose = () => {
    setUserName();
    setUserEmail();
    openModal(false);
  };
  return (
    <Modal
      show={isOpen}
      onHide={() => openModal(false)}
      onExit={onModalExit}
      keyboard={false}
      className='modal'
    >
      <div className='background-main'>
        <div className='background-container'>
          <div
            className={
              isSmall
                ? `forgot-page ${isImage ? 'image-icon' : ''}`
                : 'signin-page'
            }
          >
            <div className='close-icon' onClick={() => handleClose()}>
              <img src={WHITE_CLOSE_ICON} className='close-image' alt='close' />
            </div>
            {isImage && (
              <div className='success-image'>
                <img src={SIGNIN_VERI} className='success-icon' alt='success' />
              </div>
            )}
            <h1 className={`title-text ${isNotify ? 'align-left' : ''} ${isSignUp ? 'signup-title-text' : ''}`}>
              {header}
            </h1>
            {subHeading && (
              <div
                className={`forgot-pg ${isNotify && 'align-left'}`}
                dangerouslySetInnerHTML={createMarkup(subHeading)}
              ></div>
            )}
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ModalContainer;

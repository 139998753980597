import { types, Instance } from "mobx-state-tree";

export const MediaModel = types.model({
  id: types.maybeNull(types.number),
  entityId: types.maybeNull(types.number),
  filePath: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
})

export const AttrModel = types.model({
  attribute: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
})

export const CartItemModel = types.model({
  id: types.maybeNull(types.number),
  productId: types.maybeNull(types.number),
  quantity: types.maybeNull(types.string),
  totalPrice: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  sku: types.maybeNull(types.string),
  outOfStock: types.maybeNull(types.boolean),
  in_wish: types.maybeNull(types.boolean),
  lowStock: types.maybeNull(types.boolean),
  availableQuantity: types.maybeNull(types.number),
  medias: types.optional(types.array(MediaModel), []),
  condition: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  variant_data: types.optional(types.array(AttrModel), []),
  drop_active: types.maybeNull(types.boolean),
  dropInfo: types.optional(types.frozen(), {}),
})

export const paymentCardData = types.model({
  address_id: types.maybeNull(types.number),
  card_id: types.maybeNull(types.null),
  cart_id: types.maybeNull(types.number),
  is_card_save: types.maybeNull(types.boolean),
  source_token: types.maybeNull(types.string),
  user_id: types.maybeNull(types.number),
})

export const paymentCardDetail = types.model({
  name: types.maybeNull(types.string),
  exp_month: types.maybeNull(types.string),
  exp_year: types.maybeNull(types.string),
  brand: types.maybeNull(types.string),
  last4: types.maybeNull(types.string),
})

type CartItemModelType = Instance<typeof CartItemModel>
export interface CartItemModelTypeI extends CartItemModelType { }

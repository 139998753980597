import React, { useEffect, useState } from 'react';
import { Tab, Tabs, } from 'react-bootstrap';
import Cookie from 'js-cookie';
import { Posts } from './posts/Posts';
import { MyCollection } from './myCollection/MyCollection';
import { PendingPurchase } from './pendingPurchase/PendingPurchase';
import { SoldItems } from './soldItems/SoldItems';
import { USER_ID } from '../../../../../constants';
import './profileTabs.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchParameters } from '../../../../../data/SearchParameters';
import { errorToast } from '../../../Atoms';

export enum ParameterType {
  tab
}

export enum TabType {
  myCollection,
  post,
  pendingPurchase,
  soldItems
}

export const ProfileTabs = () => {
  const userId = Number(Cookie.get(USER_ID));

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const setURLParameters = (params: URLSearchParams) => {
    if (!params) return;

    searchParameters.addParamater(ParameterType[ParameterType.tab],
      params.get(ParameterType[ParameterType.tab]) ?? undefined);
  };

  const searchParameters = new SearchParameters();
  const pageQuery = useQuery();

  setURLParameters(pageQuery);

  const history = useHistory();
  const location = history.location;

  const [tab, setTab] = useState<string>(
    searchParameters.getParameterValue(ParameterType[ParameterType.tab])
    ?? TabType[TabType.myCollection]
  );

  useEffect(() => {
    setURLParameters(pageQuery);
    setTab(searchParameters.getParameterValue(ParameterType[ParameterType.tab])
      ?? TabType[TabType.myCollection]);
  }, [location]);

  return (
    <div className='step-2'>
      <Tabs
        id='profileTabs'
        activeKey={tab}
        onSelect={(key: any) => {
          searchParameters.addParamater(ParameterType[ParameterType.tab], key);
          history.push(searchParameters.getParametersString());
          setTab(key);
        }}
      >
        <Tab eventKey={TabType[TabType.post]} title='Post'>
          <Posts />
        </Tab>
        <Tab eventKey={TabType[TabType.myCollection]} title='My Collection'>
          <MyCollection userId={userId} />
        </Tab>
        <Tab eventKey={TabType[TabType.pendingPurchase]} title='Pending Purchase'>
          <PendingPurchase />
        </Tab>
        <Tab eventKey={TabType[TabType.soldItems]} title='Sold Items'>
          <SoldItems />
        </Tab>
      </Tabs>
    </div>
  );
};


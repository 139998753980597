export class SearchParameters {
  params: any = {};

  addParamater(key: string, value: any): void {
    if (value === undefined)
      return;
    this.params[key] = value;
  }

  removeParameter(key: string): void {
    delete this.params[key];
  }

  getParameterValue(key: string): any {
    return this.params[key];
  }

  getParametersString(): string {
    let queryString: string = '';
    let firstCount: boolean = true;
    for (const key in this.params) {
      queryString += `${firstCount ? '?' : '&'}${key}=${this.params[key]}`;
      firstCount = false;
    }
    return queryString;
  }

  clear(): void {
    Object.keys(this.params).forEach(key => {
      this.removeParameter(key);
    });
  }
}

import { toast } from 'react-toastify';
import { MagicNumber } from '../../../../constants';
import './toast.scss';

export const successToast = (message: string) => {
    toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: MagicNumber.THIRTY_TH,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const errorToast = (message: string, timeout?: number) => {
    toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: timeout ? timeout : MagicNumber.THIRTY_TH,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};


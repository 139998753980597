export class HomeCategory {
  static ALL = "all"
  static LOAD = "LOAD"
  static SCROLL = "SCROLL"
  static OTHERS = "OTHERS"
}

export class CodeError {
  static NETWORK_ERROR = "NETWORK_ERROR"
}

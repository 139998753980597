import { MagicNumber } from '../../../../constants';
import { GridListSkeleton } from './GridListSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';

type Props = {
  isLoading: boolean,
  totalItems: number,
  items: any[],
  NoItemsComponent: JSX.Element,
  getGridItemComponent: (item: any, index: number) => JSX.Element,
  onScrollEnd: () => void
};

export const GridList = (props: Props) => {
  return (
    <div className='pending-purchase-section'>
      {!props.totalItems && props.isLoading ? <GridListSkeleton /> : null}
      <div hidden={!props.totalItems && props.isLoading}>
        {
          props.items.length ?
            <nav className='list-header'>
              <div className='head-container'>
                <h4 className='head-title'>
                  {props.totalItems}
                  <span className='total-count'> items</span>
                </h4>
              </div>
            </nav>
            : null
        }
        <div className='product-image-section'>
          <InfiniteScroll
            dataLength={props.items.length}
            hasMore={true}
            loader={null}
            next={props.onScrollEnd}
          >
            <div className='empty-cart text-center' hidden={props.isLoading || props.items.length > MagicNumber.ZERO}>
              {props.NoItemsComponent}
            </div>

            <div className='image-row' hidden={!props.isLoading && !props.items.length}>
              <div className='container-half' style={{ cursor: 'pointer' }}>
                {
                  props.items.map((item: any, index: number) => {
                    return props.getGridItemComponent(item, index);
                  })
                }
                {props.isLoading ? <GridListSkeleton /> : null}
              </div>
            </div>
          </InfiniteScroll>
        </div >
      </div>
    </div>
  );
};



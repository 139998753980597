import { PRODUCT_PLACEHOLDER } from '../../../../../../constants';
import { Image } from '../../../../Atoms';
import { SkeletonImage } from '../../../../Atoms/SkeletonImage';

type Props = {
  src: string,
  key: string,
  productDelivered: boolean,
  onClickCB: () => void,
  status?: string
};

export const PendingPurchaseItem = (props: Props) => {

  const handleStatus = () => {
    let showStatus = 'Track Order';
    if (props.status) {
      if (props.status === 'pending') {
        showStatus = 'Pending Fulfilment';
      } else if (props.status === 'shipped') {
        showStatus = 'Shipped';
      } else if (props.status === 'transferring_ownership') {
        showStatus = 'Transferring Ownership';
      } else if (props.status === 'failed') {
        showStatus = 'Transferring failed';
      } else {
        showStatus = 'Delivered';
      }
    }
    return (
      <button type='button' className='btn primary track'>{showStatus}</button>
    );
  };

  return (
    <div className='image-box 1' onClick={props.onClickCB}>
      <SkeletonImage
        imageURL={props.src}
        key={props.key}
        classes='image-product'
        alt='product'
        placeholder={PRODUCT_PLACEHOLDER}
      />
      <div className='product-status'>
        {handleStatus()}
      </div>
    </div>
  );
};

import { PRODUCT_PLACEHOLDER } from '../../../../../../constants';
import { SkeletonImage } from '../../../../Atoms/SkeletonImage';

type Props = {
  src: string,
  key: string,
  onClickCB: () => void
};

export const SoldItem = (props: Props) => {
  return (
    <>
      <div className='image-box 1 sold-item-box' onClick={props.onClickCB}>
        <SkeletonImage
          imageURL={props.src ?? PRODUCT_PLACEHOLDER}
          key={props.key}
          classes='image-product'
          alt='product'
          placeholder={PRODUCT_PLACEHOLDER}
        />
      </div>
    </>
  );
};

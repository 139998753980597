import { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Modal, ToastModal, FieldError, Input, Image } from '../../Atoms';
import { validateResetPass, PASSWORD_SHOW, PASS_ICON, handleEnter } from '../../../../constants';
import { useStores } from '../../../../models';

type IProps = {
    openModal: (e: boolean) => void;
    isOpen?: boolean;
    email: string;
    otp?: string;
    openSigninModal?: (e: boolean) => void;
    isRegister?: boolean;
};

export const ResetPassModal = observer(function Home({ openModal, isOpen, email, otp, openSigninModal, isRegister }: IProps) {
    const rootStore = useStores();
    const { resetPassword } = rootStore.loginStore;
    const [isToastOpen, openToastModal] = useState<boolean>(false);
    const [passwordType, setPasswordType] = useState<boolean>(false);
    const [confirmPassType, setConfirmPassType] = useState<boolean>(false);

    const resetPass = (values: any) => {
        let data = {
            'new_password': values.password,
            'confirm_password': values.passwordConfirm,
            'email': email,
            'user_otp': Number(otp)
        };
        resetPassword(data).then((res) => {
            if (res) {
                openModal(false);
                openToastModal(true);
            }
        });
    };
    return (<>
        <Modal
            isOpen={isOpen}
            openModal={openModal}
            header='Set New Password'
            isSmall
            subHeading={`For account linked to email <a className='email-text'>${email}</a>.`} >
            <Formik
                initialValues={{ password: '', passwordConfirm: '' }}
                onSubmit={async values => {
                    await resetPass(values);
                }}
                validationSchema={validateResetPass}
                render={({ handleSubmit, values, setFieldValue, dirty }) => (
                    <Form className='form-section'>
                        <div className='form-group'>
                            <Input
                                type={passwordType ? 'text' : 'password'}
                                required
                                id='password'
                                name='password'
                                placeholder='New Password'
                                value={values.password}
                                setFieldValue={setFieldValue}
                                handleEnter={handleEnter}
                            />
                            <div className='paasword-section'>
                                <Image src={!passwordType ? PASSWORD_SHOW : PASS_ICON} alt='show' className='password-icon' onClick={() => setPasswordType(!passwordType)} />
                            </div>
                            <FieldError name='password' />
                        </div>
                        <div className='form-group'>
                            <Input
                                type={confirmPassType ? 'text' : 'password'}
                                required
                                id='passwordConfirm'
                                name='passwordConfirm'
                                placeholder='Confirm New Password'
                                value={values.passwordConfirm}
                                setFieldValue={setFieldValue}
                                handleEnter={handleEnter}
                            />
                            <div className='paasword-section'>
                                <Image src={!confirmPassType ? PASSWORD_SHOW : PASS_ICON} alt='show' className='password-icon' onClick={() => setConfirmPassType(!confirmPassType)} />
                            </div>
                            <FieldError name='passwordConfirm' />
                        </div>
                        <div className='submit-button'>
                            <button type='button' className='btn primary' disabled={!dirty ? true : false} onClick={(e: any) => handleSubmit(e)}>Continue</button>
                        </div>
                    </Form>
                )} />
        </Modal>
        <ToastModal openModal={openToastModal} isOpen={isToastOpen} openSigninModal={openSigninModal} />
    </>
    );
});


import React, { useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import { AddressListing } from './AddressListing';
import { useStores } from '../../../../../models';
import { AddShippingAddress } from './../../../Molecules';
import { USER_ID } from './../../../../../constants';
import './cartShippingAddress.scss';
import { FullPageLoader } from '../../../Atoms';
import { observer } from 'mobx-react-lite';
import { CartShippingAddressSkeleton } from './CartShippingAddressSkeleton';
import { toJS } from 'mobx';
import { NoAddressFound } from '../../../Molecules/emptyScreens/EmptyScreens';

export const CartShippingAddress = observer(function CartShippingAddress(ShippingAddressData: any) {
  const rootStore = useStores();
  const [shippingAddressList, setShippingAddressList] = useState<any>([]);
  const { getShippingAddress, savedAddressList } = rootStore.settingStore;
  const [shippingAddressModal, setShippingAddressModal] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const { setAddress } = rootStore.cardStore;
  const initialValues = {
    name: '',
    phone: '',
    country: 'US',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: '',
    is_default: false
  };

  const fetchData = async () => {
    await getShippingAddress(Number(Cookie.get(USER_ID)));
    await setShippingAddressList(savedAddressList);
    if (savedAddressList && savedAddressList.length) {
      savedAddressList.forEach((add: any) => {
        if (add && add.is_default) {
          setAddress({ address_id: add.id });
        }
      });
    }
    setLoader(false);
  };

  const toggleShippingAddressModal = () => {
    setShippingAddressModal(!shippingAddressModal);
    fetchData();
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, [savedAddressList]);

  return (
    <>
      {loader ? (<CartShippingAddressSkeleton />) : (
        <div className='shipping-address-section'>
          {shippingAddressList.length <= 3 &&
            <div className='address-head'>
              <div className='head-left'>
                <h2 className='head-text'>Select Shipping Address</h2>
              </div>
              {
                shippingAddressList.length ?
                  <div className='head-right'>
                    <button type='button' onClick={toggleShippingAddressModal} className='btn outline-primary'>+ Add Address</button>
                  </div> : null
              }
            </div>
          }
          <div className='address-card-container'>
            <div className='card-space'>
              {shippingAddressList.map((item: any) => {
                return (
                  <AddressListing itemData={item} />
                );
              })
              }
            </div>
            {!shippingAddressList.length ?
              <NoAddressFound onClick={toggleShippingAddressModal} /> :
              null}
          </div>
        </div>
      )}
      {shippingAddressModal && (
        <AddShippingAddress
          openModal={() => { toggleShippingAddressModal(); }}
          activeAddress={initialValues}
          shippingAddressMode='Add'
          title='Add Shipping Address'
          isOpen={shippingAddressModal}
        />
      )}
    </>
  );
});


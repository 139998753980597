import { types, Instance } from 'mobx-state-tree';

export const FollowDetail = types.model({
  followerCount: types.maybeNull(types.string),
  followingCount: types.maybeNull(types.string),
});

export const ProfileModal = types.model({
  firstname: types.maybeNull(types.string),
  lastname: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  apple_id: types.maybeNull(types.string),
  google_id: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  verified: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  followers: types.maybeNull(FollowDetail),
  role: types.maybeNull(types.string)
});

type ProfileModalType = Instance<typeof ProfileModal>;
export interface ProfileModalTypeI extends ProfileModalType { }

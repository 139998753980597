import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { USER_ID } from './../../../../constants';
import { useStores } from './../../../../models';
import Cookie from 'js-cookie';

import './modal.scss';

type IProps = {
  openModal: any;
  isOpen: boolean;
  title: string;
  description: string;
  id: number | null;
};

export const RemoveCard = ({ openModal, isOpen, title, description, id }: IProps) => {
  const [loader, setLoader] = useState<boolean>(false);

  const rootStore = useStores();
  const deleteCard = async (id: any) => {
    setLoader(true);
    await rootStore.cardStore.deleteCard(id);
    rootStore.cardStore.getSavedCard();
    openModal();
    setLoader(false);
  };
  return (
    <>
      {loader ? <div className='loader-main'>
        <div className='spinner-border' role='status'>
          <span className='visually-hidden'></span>
        </div>
      </div> : ''}
      <Modal
        size='sm'
        show={isOpen}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='remove-modal-dialog modal-dialog-centered'
        contentClassName='remove-modal-content'
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-blank' onClick={openModal}>Cancel</button>
          <button type='button' className='btn btn-blank remove' onClick={() => { deleteCard(id); }}>Remove</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../../../../models';
import { MagicNumber, PAGE_ROUTES } from '../../../../../../constants';
import { PENDING_ICON } from '../../../../../../constants/image';
import { errorToast } from '../../../../Atoms';
import { PendingPurchaseItem } from './PendingPurchaseItem';
import './pending-purchase.scss';
import { useHistory } from 'react-router';
import { ParameterType } from '../../../orderDetail/OrderDetail';
import { GridList } from '../../../../Molecules/gridList/GridList';

export const PendingPurchase = observer(() => {
  const rootStore = useStores();
  const history = useHistory();

  const { inProcess, getMorePendingPurchases } = rootStore.pendingPurchaseStore;
  const [pendingPurchaseList, setPendingPurchaseList] = useState<any[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(MagicNumber.ONE);
  const [totalPages, setTotalPages] = useState<number>(MagicNumber.ONE);
  const [totalItems, setTotalItems] = useState<number>(MagicNumber.ZERO);
  const [pageLimit] = useState<number>(MagicNumber.EIGHT);

  useEffect(() => {
    retrievePurchases();
  }, []);

  const retrievePurchases = () => {
    if (currentPageNumber > totalPages)
      return;

    getMorePendingPurchases(currentPageNumber, pageLimit)
      .then(response => {
        const { results, page_total, total } = response;

        const updatedList = [...pendingPurchaseList, ...results];

        setPendingPurchaseList(updatedList);
        setCurrentPageNumber(currentPageNumber + MagicNumber.ONE);
        setTotalPages(page_total);
        setTotalItems(parseInt(total));
      }).catch(error => {
        errorToast(error);
      });
  };

  const onPendingItemClicked = (orderId: number, itemId: number) => {
    const query = `${ParameterType[ParameterType.orderId]}=${orderId}&${ParameterType[ParameterType.itemId]}=${itemId}&tabs=pending`
    history.push(`${PAGE_ROUTES.ORDER_DETAIL}?${query}`);
  };

  const GetItemUI = (item: any, index: number) => {
    return (
      <PendingPurchaseItem
        src={item.product_image}
        key={`pro_${index}`}
        status={item && item.status}
        productDelivered={false}
        onClickCB={() => { onPendingItemClicked(item.order_id, item.id) }}
      />
    )
  };

  const NoItems = () => {
    return (
      <>
        <img src={PENDING_ICON} className="sold-image" alt="pending purchase" />
        <p>You have no pending purchases. Tab browse below to find
          items to purchase, and they will show up here.</p>
        <a className="btn primary" href="/">Browse</a>
      </>
    )
  };

  return (
    <GridList
      isLoading={inProcess}
      totalItems={totalItems}
      NoItemsComponent={<NoItems />}
      getGridItemComponent={GetItemUI}
      items={pendingPurchaseList}
      onScrollEnd={retrievePurchases}
    />
  );
});



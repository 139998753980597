import React, { useState,useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';
import './_viewAllBids.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../models';
import { MagicNumber, WHITE_CLOSE } from '../../../../constants';
import { FullPageLoader, Input, InputPrivate } from '../../Atoms';
import { useHistory } from 'react-router-dom';

type IProps = {
    openModal: any;
    isOpen: boolean;
    productPrice: any;
    productId: number;
    editFlag: string;
};
export const CreatePrivateLink = observer(function ViewAllBids({ openModal, isOpen, productPrice, productId, editFlag }: IProps) {

    const history = useHistory();
    const rootStore = useStores();
    const { updateProduct } = rootStore.collectionStore;
    const [prices, setPrice] = useState<any>('');
    const [priceError, setPriceError] = useState<any>('');
    const [loader, setLoader] = useState<boolean>(false);

    const createLink = async () => {
        if (prices !== '' && prices !== '0') {
            setPriceError('');
            setLoader(true);
            await updateProduct(prices, productId);
            setLoader(false);
            openModal();
        } else {
            setPriceError('Price should be greater than $0.');
        }

    };

    const handleInput = (value: string) => {
        if (value) {
            setPrice(value);
        } else {
            setPrice('');
        }
    };

    const handleKeyPress = (evt: any) => {
        const charCode = (evt.which) ? evt.which : evt.keyCode;
        if (evt.target.value.split('.').length > MagicNumber.ONE && charCode > MagicNumber.THIRTY_ONE && (charCode < MagicNumber.FOURTY_EIGHT || charCode > MagicNumber.FIFTY_SEVEN)) {
            evt.preventDefault();
        }
        return true;
    };

    useEffect(() => {
        setPrice(productPrice);
    }, []);

    const currencyF = () => {
        const l10nUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
        return l10nUSD.format(parseInt(productPrice) || 0);
    };

    return (
        <>
            {loader && <FullPageLoader />}
            <Modal
                size='sm'
                show={isOpen}
                backdrop={false}
                onHide={() => openModal()}
                aria-labelledby='modal-sm'
                centered
                dialogClassName='view-bids-page modal-dialog-centered'
                contentClassName='view-bids-content'
            >
                <div className='view-bids-background place-bids-bg'>
                    <Modal.Header className='place-header'>
                        <Modal.Title id='example-modal-sizes-title-sm'>
                            <h1 className='title-text order-title place-title'>
                                {editFlag !== '' ? 'Edit Price' : 'Create Private Link'}
                            </h1>
                            {editFlag !== '' ? <p className='current-text'>Current Price <span>{currencyF()}</span></p> : ''}
                        </Modal.Title>
                        <div className='close-icon place-close-icon' onClick={() => openModal(false)}>
                            <img src={WHITE_CLOSE} className='close-image' alt='close' />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-group'>
                            <InputPrivate
                                type='text'
                                id='price'
                                name='price'
                                placeholder='Price'
                                value={prices}
                                setFieldValue={(name, value) => handleInput(value)}
                                handleKeyPress={handleKeyPress}
                            />
                            <p className='dollar-sign'>$</p>
                            <p className='select-label selected'>Price</p>
                            {priceError !== '' ? <p className='price-error'>{priceError}</p> : ''}
                            <button type='button' onClick={() => createLink()} className='btn outline-primary place-btn'>Create</button>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
});

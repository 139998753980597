import { Instance, SnapshotOut, types, flow, cast } from 'mobx-state-tree';
import { HomeCategory } from '../../constants/types';
import { MagicNumber } from '../../constants';
import { withEnvironment } from '../extensions/with-environment';
import {
  CategoryModel,
  ProductListModel,
  ProductAllCategoryModel,
  BannerModel,
  FilterModel,
  DetailModel,
  ProductModelHome,
  SearchModel,
  MediaModel,
  DropModel,
  DropProductModel,
  FaqModel
} from './home-entity';
import { toJS } from 'mobx';

const initialValue = {
  categories: [
    {
      id: 0,
      name: 'All',
      createdAt: '',
      updatedAt: '',
      description: '',
      meta_title: '',
      meta_description: '',
      status: true,
      parent_id: null,
      filename: '',
      slug: '',
    },
  ],
};

const digitalContentVal = {
  id: null,
  createdAt: null,
  creator_message: null,
  title: null,
  discount_code: null,
  audios: [],
  documents: [],
  images: [],
  videos: [],
  product_id: null,
  updatedAt: null,
};

/**
 * Model description here for TypeScript hints.
 */
export const HomeStoreModel = types
  .model('HomeStore')
  .props({
    categories: types.optional(types.array(CategoryModel), initialValue.categories),
    state: types.optional(types.string, 'done'),
    productListAll: types.optional(types.array(ProductListModel), []),
    productListAllHome: types.optional(types.array(ProductModelHome), []),
    productList: types.optional(types.array(ProductAllCategoryModel), []),
    totalProductPages: types.optional(types.number, 1),
    bannerAll: types.optional(types.array(BannerModel), []),
    bannerCategory: types.optional(types.array(BannerModel), []),
    allFilters: types.optional(types.array(FilterModel), []),
    categoriesFilters: types.optional(types.array(FilterModel), []),
    isLoading: types.optional(types.boolean, true),
    currentProductPage: types.optional(types.number, MagicNumber.ZERO),
    productDetails: types.optional(types.maybeNull(DetailModel), null),
    productItem: types.optional(types.maybeNull(DetailModel), null),
    isDetailLoad: types.optional(types.boolean, false),
    allProductSearch: types.optional(types.array(SearchModel), []),
    homeDrop: types.optional(types.frozen(DropModel), {}),
    homeDropProductList: types.optional(types.frozen([]), null),
    homeDropProductListmobile: types.optional(types.frozen([]), null),
    homeUpcommingDropList: types.optional(types.frozen([]), null),
    homeUpcommingDropListmobile:types.optional(types.frozen([]),null),
    homeUpcommingListCount: types.optional(types.number, 0),
    homeDropProductListCount: types.optional(types.number, 4),
    homeDropProductListmobileCount: types.optional(types.number, 0),
    productImage: types.optional(types.maybeNull(MediaModel), null),
    inStockValue: types.optional(types.number, MagicNumber.ZERO),
    productIdList: types.optional(types.number, MagicNumber.ZERO),
    variantCombination: types.optional(types.maybeNull(types.array(types.string)), []),
    searchedValue: types.optional(types.string, ''),
    totalProduct: types.optional(types.number, 0),
    homeDropFaqList: types.optional(types.array(FaqModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    bannerList: flow(function* (categoryId: string = '', page: number = 1, limit: number = 4) {
      try {
        let query = `page=${page}&limit=${limit}`;
        query = categoryId ? `${query}&category_id=${categoryId}` : query;

        const response = yield self.environment.api.get(
          `banners?${query}`,
        );
        const { results } = response.data;
        if (results && results.length) {
          results.forEach((list: any, index: number) => {
            if (list && list.status === 'false') {
              results.splice(index, MagicNumber.ONE);
            }
          });
        }
        self.bannerAll = results;
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
    async setProductIds(id?: number) {
      const prId = self.productIdList = id || self.productIdList;
      return prId.toString();
    },
    async clearSearch() {
      self.allProductSearch.replace([]);
    },
    async setProductItem(product: any) {
      self.productItem = product;
    },
    fetchProductList: flow(function* (
      categoryId: number = MagicNumber.ZERO,
      type: string = HomeCategory.LOAD,
      page: number = MagicNumber.ONE,
      limit: number = MagicNumber.TEN_FIVE,
      param?: string,
      sort_by?: string,
      sort_dir?: string,
      isAllProducts?: boolean
    ) {
      self.state = 'pending';
      try {
        self.isLoading = true;
        // self.productList.replace([]);
        let response;
        if (isAllProducts) {
          response = yield self.environment.api.get(
            `search?page=${page}&limit=${limit}${param ? '&' + param : ''}`,
          );
        } else {
          response = yield self.environment.api.get(
            `products?page=${page}&limit=${limit}${categoryId !== MagicNumber.ZERO ? `&category_id=${categoryId}` : ''}${param ? '&' + param : ''}`,
          );
        }
        let { results, page_total, current_page, data, total } = isAllProducts ? response.data : response.data;
        let products = isAllProducts ? data : results;
        if (typeof (response && response.data && response.data.total) !== 'string') {
          self.totalProduct = response && response.data && response.data.total;
        }
        let newResult = products.map((value: any) => {
          let val = {
            id: value.id,
            slug: value.slug,
            createdAt: value.createdAt,
            updatedAt: value.updatedAt,
            type: value.type,
            name: value.name,
            status: value.status,
            quantity: value.quantity.toString(),
            medias: value.medias,
            parent_product_id: value.parent_product_id,
          };
          if (type === HomeCategory.SCROLL)
            self.productList.push(val);
          return val;
        });
        if (newResult && newResult.length && type !== HomeCategory.SCROLL) {
          newResult.forEach((data: any) => {
            data.medias.sort((a: any, b: any) => (a.default === b.default) ? 0 : a.default ? -1 : 1);
          });
        }
        if (type === HomeCategory.LOAD)
          self.productList = newResult;
        self.currentProductPage = Number(current_page);
        if (isAllProducts) {
          if (page_total) {
            self.totalProductPages = total;
            self.isLoading = total !== Number(current_page);
          } else {
            self.totalProductPages = Math.ceil(total / limit);
            self.isLoading = false;
          }
        } else {
          if (page_total) {
            self.totalProductPages = page_total;
            self.isLoading = page_total !== Number(current_page);
            self.isLoading = false;
          } else {
            self.totalProductPages = Math.ceil(total / limit);
            self.isLoading = false;
          }
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    fetchCategory: flow(function* (flag?) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`categories/0`);
        const { data } = response.data;
        let allCategories = [...initialValue.categories, ...data];
        self.categories = cast(allCategories);
        self.state = 'done';
        return data;
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    fetchProductFilters: flow(function* () {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`products/filters`);
        const { data } = response.data;
        const categoriesFilter: any = [{
          id: 1,
          title: 'Categories',
          type: 'Open',
          Options: [{
            'value': 'Music',
            'title': 'Music',
            'id': 1,
          },
          {
            'value': 'Sport',
            'title': 'Sport',
            'id': 3,
          },
          {
            'value': 'Media',
            'title': 'Media',
            'id': 4,
          },
          {
            'value': 'Art',
            'title': 'Art',
            'id': 2,
          }
          ]
        }];
        let newResult = data.map((value: any) => {
          let val = {
            id: value.id,
            Options: value.Options,
            title: value.title,
            type: value.type,
          };
          return val;
        });
        self.categoriesFilters = categoriesFilter;
        self.allFilters = newResult;
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  })).actions((self) => ({
    clearError() {
      self.state = 'done';
      self.isLoading = true;
    },
    fetchProductDetail: flow(function* (id: string | number, status?: boolean, isVid?: boolean) {
      self.state = 'pending';
      self.isLoading = true;
      try {
        let url = '';
        if (isVid) {
          url = `products/detail/${id}`;
        } else {
          url = typeof (id) === 'number' ? `products/${id}` : `products/product/${id}`;
        }
        const response = yield self.environment.api.get(url);
        const { data } = response.data;
        if (data) {
          self.productIdList = data.id;
          if (!status) {
            if (data && data.medias.length) {
              self.productImage = data.medias[MagicNumber.ZERO];
            } else {
              self.productImage = null;
            }
            self.inStockValue = Number(data.quantity);
          }
          self.productDetails = {
            id: data.id,
            user_id: data.user_id,
            manufacturer: data.manufacturer,
            provenance: data.provenance,
            medias: data.medias,
            story: data.story,
            name: data.name,
            price: data.price,
            digitalContent: data.digitalContent ? data.digitalContent : digitalContentVal,
            seller_story: data.seller_story ? data.seller_story : [],
            sku: data.sku,
            attribute_data: data.attribute_data,
            availability: data.availability,
            manufacturerDate: data.manufacturer_date,
            quantity: Number(data.quantity),
            sale_start_date: data.sale_start_date,
            manufacturer_country: data.manufacturer_country,
            material: data.material,
            weight: data.weight,
            weight_unit: data.weight_unit,
            width: data.width,
            height: data.height,
            length: data.length,
            condition: data.condition,
            condition_description: data.condition_description,
            discount: data.discount,
            variants: Array.isArray(data.variants) ? data.variants : [],
            sale_price: Number(data.sale_price),
            comment: data.comment,
            sale_end_date: data.sale_end_date,
            currency_price: data.currency_price,
            currency_sale_price: data.currency_sale_price,
            variantCombinations: data.variantCombinations,
            in_cart: data.in_cart,
            dimension_unit: data.dimension_unit,
            sub_category: data.sub_category,
            in_wish: data.in_wish,
            type: data.type,
            auction: data.auction,
            order_items_id: data.order_items_id,
            is_private: data.is_private,
            url: data.url,
            createdAt: data.createdAt,
            drop_data: (data && data.drop_data) ? data.drop_data : null,
            sold_at: data.sold_at
          };
        }
        self.variantCombination = data.variantCombinations;
        self.state = 'done';
        self.isLoading = false;
        return self.productDetails;
      } catch (error) {
        self.isDetailLoad = true;
        self.isLoading = true;
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    fetchProductListHome: flow(function* (
      categoryData: any,
      type: string = HomeCategory.OTHERS,
      page: number = MagicNumber.ONE,
      limit: number = MagicNumber.FIFTEEN,
    ) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(
          `products?category_id=${categoryData.id}&page=${page}&limit=${limit}&show_in_home=true`,
        );
        const { results } = response.data;
        if (page === MagicNumber.ONE && type !== HomeCategory.ALL) {
          self.productList.replace([]);
        }
        let newResult = results.map((value: any) => {
          let val = {
            id: value.id,
            slug: value.slug,
            createdAt: value.createdAt,
            updatedAt: value.updatedAt,
            name: value.name,
            status: value.status,
            medias: value.medias,
            type: value.type
          };
          if (type !== HomeCategory.ALL) {
            self.productList.push(val);
            self.totalProductPages = response.data.page_total;
          }
          return val;
        });
        if (type === HomeCategory.ALL && newResult && newResult.length) {
          let list: any = [{
            first: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            second: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            third: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            fourth: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' }
          }, {
            first: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            second: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            third: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            fourth: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' }
          }, {
            first: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            second: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            third: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            fourth: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' }
          }, {
            first: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            second: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            third: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' },
            fourth: { filepath: '', id: '', type: '', filepathThumb: '', productType: '' }
          }];
          if (newResult && newResult.length) {
            newResult.forEach((product: any, index: number) => {
              if (index <= MagicNumber.THREE) {
                switch (index) {
                  case MagicNumber.ZERO: list[MagicNumber.ZERO].first.id = product.slug;
                    list[MagicNumber.ZERO].first.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ZERO].first.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ZERO].first.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ZERO].first.productType = product.type;
                    break;
                  case MagicNumber.ONE: list[MagicNumber.ZERO].second.id = product.slug;
                    list[MagicNumber.ZERO].second.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ZERO].second.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ZERO].second.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ZERO].second.productType = product.type;
                    break;
                  case MagicNumber.TWO: list[MagicNumber.ZERO].third.id = product.slug;
                    list[MagicNumber.ZERO].third.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ZERO].third.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ZERO].third.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ZERO].third.productType = product.type;
                    break;
                  default: list[MagicNumber.ZERO].fourth.id = product.slug;
                    list[MagicNumber.ZERO].fourth.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ZERO].fourth.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ZERO].fourth.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ZERO].fourth.productType = product.type;
                    break;
                }
              } else if (index > MagicNumber.THREE && index <= MagicNumber.SEVEN) {
                switch (index) {
                  case MagicNumber.FOUR: list[MagicNumber.ONE].first.id = product.slug;
                    list[MagicNumber.ONE].first.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ONE].first.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ONE].first.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ONE].first.productType = product.type;
                    break;
                  case MagicNumber.FIVE: list[MagicNumber.ONE].second.id = product.slug;
                    list[MagicNumber.ONE].second.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ONE].second.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ONE].second.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ONE].second.productType = product.type;
                    break;
                  case MagicNumber.SIX: list[MagicNumber.ONE].third.id = product.slug;
                    list[MagicNumber.ONE].third.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ONE].third.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ONE].third.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ONE].third.productType = product.type;
                    break;
                  default: list[MagicNumber.ONE].fourth.id = product.slug;
                    list[MagicNumber.ONE].fourth.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.ONE].fourth.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.ONE].fourth.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.ONE].fourth.productType = product.type;
                    break;
                }
              } else if (index > MagicNumber.SEVEN && index <= MagicNumber.ELEVEN) {
                switch (index) {
                  case MagicNumber.EIGHT: list[MagicNumber.TWO].first.id = product.slug;
                    list[MagicNumber.TWO].first.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.TWO].first.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.TWO].first.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.TWO].first.productType = product.type;
                    break;
                  case MagicNumber.NINE: list[MagicNumber.TWO].second.id = product.slug;
                    list[MagicNumber.TWO].second.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.TWO].second.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.TWO].second.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.TWO].second.productType = product.type;
                    break;
                  case MagicNumber.TEN: list[MagicNumber.TWO].third.id = product.slug;
                    list[MagicNumber.TWO].third.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.TWO].third.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.TWO].third.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.TWO].third.productType = product.type;
                    break;
                  default: list[MagicNumber.TWO].fourth.id = product.slug;
                    list[MagicNumber.TWO].fourth.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.TWO].fourth.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.TWO].fourth.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.TWO].fourth.productType = product.type;
                    break;
                }
              } else {
                switch (index) {
                  case MagicNumber.TWELVE: list[MagicNumber.THREE].first.id = product.slug;
                    list[MagicNumber.THREE].first.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.THREE].first.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.THREE].first.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.THREE].first.productType = product.type;
                    break;
                  case MagicNumber.THIRTEEN: list[MagicNumber.THREE].second.id = product.slug;
                    list[MagicNumber.THREE].second.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.THREE].second.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.THREE].second.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.THREE].second.productType = product.type;
                    break;
                  default: list[MagicNumber.THREE].third.id = product.slug;
                    list[MagicNumber.THREE].third.filepath = product.medias[MagicNumber.ZERO].filepath || '';
                    list[MagicNumber.THREE].third.filepathThumb = product.medias[MagicNumber.ZERO].filepathThumb || '';
                    list[MagicNumber.THREE].third.type = product.medias[MagicNumber.ZERO].type;
                    list[MagicNumber.THREE].third.productType = product.type;
                    break;
                }
              }
            });
            self.productListAllHome.push({
              category_name: categoryData.name,
              slug: categoryData.slug,
              id: categoryData.id,
              productList: list,
              description: categoryData.description
            });
            self.productListAllHome.sort(function (a, b) {
              return a.id - b.id;
            });
          }
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    fetchCategoryHome: flow(function* () {
      self.productListAllHome.replace([]);
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`categories/0`);
        const { data } = response.data;
        let allCategories = [...initialValue.categories, ...data];
        self.categories = cast(allCategories);
        self.state = 'done';
        self.isLoading = false;
        data.map(async (item: any) => {
          await self.fetchProductListHome(item, HomeCategory.ALL);
        });
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    searchProducts: flow(function* (searchText: string) {
      self.productListAllHome.replace([]);
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`search/keywords/${searchText}`);
        let newData = response.data.map((element: any) => {
          return {
            value: element.keyword,
            label: element.keyword,
            subLabel: element.entity === 'users' ? `in Collecters` : element.entity === 'feeds' ? 'in Feeds' : `in Collectibles`,
            count: element.count,
            name: 'All'
          };
        });
        self.allProductSearch = newData;
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  })).actions(self => ({
    setProfileImage(data: any) {
      self.productImage = data;
    }
  })).actions(self => ({
    setQuantity(data: any) {
      self.inStockValue = data;
    }
  }))
  .actions(self => ({
    emptyProductDetails() {
      self.productDetails = null;
      self.variantCombination = null;
    }
  }))
  .actions(self => ({
    setSearchedValue(data: any) {
      self.searchedValue = data;
    }
  }))
  .actions(self => ({
    reportComment: flow(function* (commentUserId: number, comment: string, slugUrl: string, reason: string) {
      const response = yield self.environment.api.post('users/report-content', {
        reportee_id: commentUserId,
        content: comment,
        report_comment: reason,
        type_id: slugUrl,
        type: 'product'
      });
      if (response.response)
        return response.response.data.data;
      else
        throw response.data.message;
    })
  }))
  .actions(self => ({
    reportChat: flow(function* (commentUserId: number, reason: string) {
      const response = yield self.environment.api.post('users/report-content', {
        reportee_id: commentUserId,
        // content: '',
        report_comment: reason,
        type_id: commentUserId,
        type: 'chat'
      });
      if (response.response)
        return response.response.data.data;
      else
        throw response.data.message;
    })
  }))
  .actions(self => ({
    getCollection: flow(function* (collectionId: number) {
      const response = yield self.environment.api.get(`collections/${collectionId}`);
      return response;
    })
  }))
  .actions(self => ({
    fetchCollectionProducts: flow(function* (
      searchQuery: string
    ) {
      const response = yield self.environment.api.get(
        `products?page=${MagicNumber.ONE}&limit=${MagicNumber.ONE}${searchQuery}`);
      return response;
    })
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getHomeDropById: flow(function* (id) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`drop/preview/${id}`);
        if (response && response.data && response.data.data && response.data.data.length) {
          let data: any = [];
           self.homeDropProductListCount = response.data.data[0].drop_pro.length;
          
          if (response.data.data[0].drop_pro.length) {
            let ind = 0;
            response.data.data[0].drop_pro.forEach((element: any, index: number) => {
              if (index > 0 && index % 3 === 0) {
                ind = ind + 1;
                data[ind] = [];
              }
              let newData = [element];
              if (index > 0) {
                data[ind].push(element);
              } else {
                data.push(newData);
              }
            });
          }
          self.homeDropProductList = data;
          self.homeDrop = response.data.data[0];
          return response.data.data[0];
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getHomeDrop: flow(function* () {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`drop/live`);
        if (response && response.data && response.data.data && response.data.data.length) {
          let data: any = [];
          self.homeDropProductListCount = response.data.data[0].drop_pro.length;
          if (response.data.data[0].drop_pro.length) {
            let ind = 0;
            response.data.data[0].drop_pro.forEach((element: any, index: number) => {
              if (index > 0 && index % 3 === 0) {
                ind = ind + 1;
                data[ind] = [];
              }
              let newData = [element];
              if (index > 0) {
                data[ind].push(element);
              } else {
                data.push(newData);
              }
            });
          }
          self.homeDropProductList = data;
          self.homeDrop = response.data.data[0];
          self.homeDropProductListmobile=response.data.data[0].drop_pro;
          return response.data.data[0];
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getUpcommingDrop: flow(function* (page: number = 1, limit: number = 100) {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`drop/drop?page=${page}&limit=${limit}`);
        if (response && response.data && response.data.results && response.data.results.length) {
          const responseFilterData: any = response.data.results.filter((el: any) => el.id !== 2);
          let data: any = [];
          if (responseFilterData.length) {
            let ind = 0;
            responseFilterData.forEach((element: any, index: number) => {
              if (index > 0 && index % 3 === 0) {
                ind = ind + 1;
                data[ind] = [];
              }
              let newData = [element];
              if (index > 0) {
                data[ind].push(element);
              } else {
                data.push(newData);
              }
            });
          }
          self.homeUpcommingListCount = responseFilterData.length;
          self.homeUpcommingDropList = data;
          self.homeUpcommingDropListmobile=responseFilterData;
          return response.data;
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }))
  .actions((self) => ({
    clearError() {
      self.state = 'done';
    },
    getDropFaq: flow(function* () {
      self.state = 'pending';
      try {
        const response = yield self.environment.api.get(`drop/faq`);
        if (response && response.data) {
          self.homeDropFaqList = response.data;
        }
        self.state = 'done';
      } catch (error) {
        self.state = 'error';
        throw error;
      }
    }),
  }));


type HomeStoreType = Instance<typeof HomeStoreModel>;
export interface HomeStore extends HomeStoreType { }
type HomeStoreSnapshotType = SnapshotOut<typeof HomeStoreModel>;
export interface HomeStoreSnapshot extends HomeStoreSnapshotType { }

import { types, Instance } from 'mobx-state-tree';
import { type } from 'node:os';
import { MagicNumber } from '../../constants';
export const CategoryModel = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  meta_title: types.maybeNull(types.string),
  meta_description: types.maybeNull(types.string),
  status: types.maybeNull(types.boolean),
  parent_id: types.maybeNull(types.string),
  filename: types.maybeNull(types.string),
});

export interface CategoryModelF {
  id: number,
  createdAt: string,
  updatedAt: string,
  slug: string,
  name: string,
  description: string,
  meta_title: string,
  meta_description: string,
  status: boolean,
  parent_id: string,
  filename: string,
};

type CategoryModelType = Instance<typeof CategoryModel>
export interface CategoryModelI extends CategoryModelType { }

export const MediaModel = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  entity_id: types.maybeNull(types.number),
  entity_type: types.maybeNull(types.string),
  filepath: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});


export const ProductAllCategoryModel = types.model({
  id: types.number,
  slug: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  status: types.maybeNull(types.boolean),
  quantity: types.maybeNull(types.string),
  parent_product_id: types.maybeNull(types.number),
  medias: types.optional(types.array(MediaModel), []),
});

export const ProductAllCategoryModelOption = types.model({
  filepath: types.string,
  filepathThumb: types.string,
  type: types.string,
  id: types.string,
  productType: types.string
});

export const ProductAllCategoryModel1 = types.model({
  first: types.maybe(ProductAllCategoryModelOption),
  second: types.maybe(ProductAllCategoryModelOption),
  third: types.maybe(ProductAllCategoryModelOption),
  fourth: types.maybe(ProductAllCategoryModelOption)
});

type ProductAllCategoryModelType = Instance<typeof ProductAllCategoryModel>
export interface ProductAllCategoryModelI extends ProductAllCategoryModelType { };

export const ProductListModel = types.model({
  id: types.number,
  productList: types.optional(types.array(ProductAllCategoryModel), []),
});

export const ProductListModelHome = types.model({
  id: types.number,
  category_name: types.string,
  productList: types.optional(types.array(ProductAllCategoryModel1), [])
});
export const ProductModelHome = types.model({
  id: types.number,
  category_name: types.string,
  slug: types.maybeNull(types.string),
  productList: types.optional(types.array(ProductAllCategoryModel1), []),
  description: types.string
});

export const ProductListModel1 = types.model({
  id: types.number,
  category_name: types.string,
  productList: types.optional(types.array(ProductAllCategoryModel), []),
});

type ProductListModelType = Instance<typeof ProductListModel>
export interface ProductListModelI extends ProductListModelType { };

export const BannerModel = types.model({
  full_count: types.maybeNull(types.string),
  id: types.number,
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  order: types.maybeNull(types.string),
  status: types.union(types.boolean, types.string),
  filename: types.maybeNull(types.string),
  link: types.maybeNull(types.string),
  sale_start: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  categories: types.maybeNull(types.array(types.string)),
  createdAt: types.maybeNull(types.string),
  app_path: types.maybeNull(types.string),
});

export const OptionsModel = types.model({
  id: types.number,
  title: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

export const FilterModel = types.model({
  Options: types.maybeNull(types.array(OptionsModel)),
  id: types.number,
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const FileModel = types.model({
  description: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
});

export const FileModelSeller = types.model({
  file: types.maybeNull(types.string),
  file_type: types.maybeNull(types.string),
  filepath: types.maybeNull(types.string),
  filepathThumb: types.maybeNull(types.string),
});

export const SellerData = types.model({
  email: types.maybeNull(types.string),
  firstname: types.maybeNull(types.string),
  lastname: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  profile_image_filename: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  username: types.maybeNull(types.string)
});

const Content = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  creator_message: types.maybeNull(types.string),
  discount_code: types.maybeNull(types.string),
  audios: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  documents: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  images: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  videos: types.optional(types.array(types.optional(types.maybe(FileModel), {})), []),
  product_id: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.string),
});

const SellerContent = types.model({
  seller_id: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  sellerInfo: types.maybeNull(SellerData),
  files: types.optional(types.array(types.optional(types.maybe(FileModelSeller), {})), [])
});

export const AttrModel = types.model({
  attribute_id: types.number,
  name: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
  options_id: types.maybeNull(types.number),
  details: types.model({})
});
export const ComModel = types.model({
  attribute: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  value: types.maybeNull(types.string),
});

export const MediaModels = types.model({});


export const VarModel = types.model({
  Color: types.maybeNull(types.string),
  Color_value: types.maybeNull(types.string),
  Size: types.maybeNull(types.string),
  Size_value: types.maybeNull(types.string),
  combination: types.optional(types.array(ComModel), []),
  createdAt: types.maybeNull(types.string),
  currency_price: types.maybeNull(types.string),
  currency_sale_price: types.maybeNull(types.string),
  discount: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  deletedAt: types.maybeNull(types.string),
  media: types.optional(types.array(types.maybeNull(MediaModel)), []),
  price: types.maybeNull(types.number),
  product_id: types.maybeNull(types.number),
  quantity: types.maybeNull(types.string),
  sale_price: types.maybeNull(types.number),
  sku: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  in_cart: types.maybeNull(types.boolean),
  in_wish: types.maybeNull(types.boolean),
});

export const subProfileItem = types.model({
  id: types.maybeNull(types.number),
  profile_image: types.maybeNull(types.string),
});

export const CommentsResultsItems = types.model({
  id: types.number,
  comment: types.maybeNull(types.string),
  comment_count: types.maybeNull(types.string),
  full_count: types.maybeNull(types.string),
  profile_image: types.maybeNull(types.string),
  sub_profilimage: types.optional(types.array(subProfileItem), []),
  sub_username: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  username: types.maybeNull(types.string),
  commentLength: MagicNumber.ONE_FIFTEEN,
});

export const CommentItem = types.model({
  current_page: types.maybeNull(types.number),
  limit: types.maybeNull(types.number),
  page_total: types.maybeNull(types.number),
  results: types.optional(types.array(CommentsResultsItems), []),
  total: types.maybe(types.string)
});

export const initialDetailModel = {
  id: 0,
  manufacturer: null,
  provenance: null,
  medias: types.optional(types.array(MediaModel), []),
  story: null,
  name: null,
  price: null,
  sku: null,
  attribute_data: [],
  availability: null,
  digitalContent: Content,
  manufacturerDate: null,
  quantity: null,
  sale_start_date: null,
  manufacturer_country: null,
  weight: null,
  weight_unit: null,
  width: null,
  height: null,
  length: null,
  condition: null,
  discount: null,
  variants: [],
  sale_price: null,
  comment: null,
  sale_end_date: null,
  currency_price: null,
  currency_sale_price: null,
  variantCombinations: [],
};

export const AuctionList = types.model({
  base_price: types.maybeNull(types.number),
  bidCount: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  currencyHighestBid: types.maybeNull(types.string),
  highestBidUserid: types.optional(types.maybeNull(types.number), 0),
  currency_base_price: types.maybeNull(types.string),
  currency_min_bid: types.maybeNull(types.string),
  currency_reserve_price: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  end_date_time: types.maybeNull(types.string),
  highestBid: types.optional(types.number, 0),
  id: types.maybeNull(types.number),
  min_bid: types.maybeNull(types.number),
  product_id: types.maybeNull(types.number),
  reserve_price: types.maybeNull(types.number),
  start_date_time: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string)
});

export const MediaElementModel = types.model({
  filepath: types.maybeNull(types.string),
  fullPath: types.maybeNull(types.string),
  thumbnail: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

export const DropContentModel = types.model({
  caption: types.maybeNull(types.string),
  about: types.maybeNull(types.string),
  collector_msg: types.maybeNull(types.string),
  heading: types.maybeNull(types.string),
  collector_image: types.maybeNull(types.string),
  featureImagePath: types.maybeNull(types.string),
  media_desc: types.maybeNull(types.string),
  imageUrl1Path: types.maybeNull(types.string),
  imageUrl2Path: types.maybeNull(types.string),
  imageUrl3Path: types.maybeNull(types.string),
  imageUrl4Path: types.maybeNull(types.string),
  imageUrl5Path: types.maybeNull(types.string),
  imageUrl6Path: types.maybeNull(types.string),
  quote: types.maybeNull(types.string),
  quote_by: types.maybeNull(types.string),
  show_image_product: types.maybeNull(types.boolean),
  show_quote_product: types.maybeNull(types.boolean),
  show_signature_product: types.maybeNull(types.boolean),
  show_video_product: types.maybeNull(types.boolean),
  signatureUrlPath: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  medias: types.optional(types.array(MediaElementModel), [])
});

export const DropModelDetails = types.model({
  createdAt: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  sale_end_date: types.optional(types.string, ''),
  sale_start_date: types.optional(types.string, ''),
  id: types.maybeNull(types.number),
  drop_content: types.optional(types.array(DropContentModel), []),
});

export const DetailModel = types.model({
  id: types.maybeNull(types.number),
  user_id: types.maybeNull(types.number),
  manufacturer: types.maybeNull(types.string),
  provenance: types.maybeNull(types.string),
  medias: types.optional(types.array(MediaModel), []),
  story: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  price: types.maybeNull(types.string),
  sku: types.maybeNull(types.string),
  attribute_data: types.optional(types.array(AttrModel), []),
  availability: types.maybeNull(types.string),
  digitalContent: Content,
  seller_story: types.optional(types.array(SellerContent), []),
  manufacturerDate: types.maybeNull(types.string),
  quantity: types.maybeNull(types.number),
  sale_start_date: types.maybeNull(types.string),
  manufacturer_country: types.maybeNull(types.string),
  material: types.maybeNull(types.string),
  weight: types.maybeNull(types.string),
  weight_unit: types.maybeNull(types.string),
  width: types.maybeNull(types.string),
  height: types.maybeNull(types.string),
  length: types.maybeNull(types.string),
  condition: types.maybeNull(types.string),
  condition_description: types.maybeNull(types.string),
  discount: types.maybeNull(types.number),
  variants: types.optional(types.array(VarModel), []),
  sale_price: types.maybeNull(types.number),
  comment: types.optional(types.maybe(CommentItem), {}),
  sale_end_date: types.maybeNull(types.string),
  currency_price: types.optional(types.string, ''),
  currency_sale_price: types.maybeNull(types.string),
  variantCombinations: types.optional(types.array(types.string), []),
  in_cart: types.maybeNull(types.boolean),
  is_private: types.maybeNull(types.boolean),
  dimension_unit: types.maybeNull(types.string),
  url: 'ood',
  type: types.maybeNull(types.string),
  sold_at: types.maybeNull(types.string),
  sub_category: types.optional(types.array(types.string), []),
  in_wish: types.maybeNull(types.boolean),
  order_items_id: types.optional(types.maybeNull(types.number), 0),
  auction: types.maybeNull(AuctionList),
  createdAt: types.string,
  drop_data: types.optional(types.maybeNull(DropModelDetails), {})
});

export const SearchModel = types.model({
  value: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  subLabel: types.maybeNull(types.string),
  count: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});

export const DropProductModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  product_image: types.maybeNull(types.string)
});

export const DropModel = types.model({
  createdAt: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  filePathApp:types.maybeNull(types.string),
  sale_end_date: types.optional(types.string, ''),
  sale_start_date: types.optional(types.string, ''),
  id: types.maybeNull(types.number),
  drop_pro: types.optional(types.array(DropProductModel), []),
  drop_content: types.optional(types.array(DropContentModel), []),
});

export const FaqModel = types.model({
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  answer: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
});



type MediaModelType = Instance<typeof MediaModel>;
export interface MediaModelI extends MediaModelType { }


export type BannerModelType = Instance<typeof BannerModel>;
export interface BannerModelI extends BannerModelType { }

// @ts-ignore
import ShowMoreText from 'react-show-more-text';
import { MouseEventHandler } from 'react';
import { USER_PLACEHOLDER } from '../../../../../constants/image';
import { SkeletonImage } from '../../../Atoms/SkeletonImage';
import { useHistory } from 'react-router';
import { FunctionalityType, getFeedTime, PAGE_ROUTES, UserType } from '../../../../../constants';
import { useStores } from '../../../../../models';
import { getActorCollectionID, isActorCommunityManager, isAdminId } from '../FeedListingUtil';

export const FeedItemUserReply = (props: {
  replyData: any,
  showConnectionLine: boolean,
  onProfileOpenedAsGuestCB?: () => void,
  onReportCB?: () => void,
  onDeleteCB?: () => void
}) => {

  const history = useHistory();
  const rootStore = useStores();
  const { userID } = rootStore.feedStreamStore;
  const { isLoggedIn, isFunctionalityAllowed } = rootStore.loginStore;

  const userId = props.replyData.user.id;
  const userName = `${props.replyData.user.data.name ?? `${props.replyData.user.data.firstname} ${props.replyData.user.data.lastname}`}`;
  const userImageURL = `${props.replyData.user.data.profileImage}`;
  const comment = props.replyData.data.text;
  const postTime = getFeedTime(new Date(props.replyData.created_at));

  const isPostUserAdmin = isAdminId(userId);
  const postUserCollectionID = getActorCollectionID(props.replyData.user);
  const isPostByCommunityManager = isActorCommunityManager(props.replyData.user);

  const goToUserProfile = () => {
    if (!isLoggedIn) {
      props.onProfileOpenedAsGuestCB?.();
      return;
    }
    if (isPostByCommunityManager && postUserCollectionID) {
      history.push(`${PAGE_ROUTES.ALL}?collection_id=${postUserCollectionID}`);
    } else {
      history.push(`${PAGE_ROUTES.PROFILE}${userId !== userID ? `/${userId}` : ''}`);
    }
  };

  const canGoToUserProfile = () => {
    return !isPostUserAdmin && !(isPostByCommunityManager && !postUserCollectionID);
  };

  return (
    <div className='reply-comment-section'>
      <div className='user-image'>
        <div className='image-box'>
          <SkeletonImage
            imageURL={userImageURL}
            classes='image-user'
            alt='user'
            placeholder={USER_PLACEHOLDER}
            onClick={canGoToUserProfile() ? () => { goToUserProfile(); } : undefined}
          />
        </div>
      </div>
      <div className='user-detail'>
        <div className='full-detail'>
          <div className='head-detail'>
            <h3 className='user-name' role={canGoToUserProfile() ? 'button' : ''}
              onClick={canGoToUserProfile() ? () => { goToUserProfile(); } : undefined}>{userName}</h3>
            <p className='time-detail'>{postTime}</p>
          </div>
          <ShowMoreText
            lines={3}
            more='Show more'
            less='Show less'
            className='post-pg'
            anchorClass='show-more-text'
            expanded={false}
          >
            <p className='user-comment height-auto'>
              {comment}
            </p>
          </ShowMoreText>
          <div className='reply-report-section'>
            {
              userId !== userID && !isPostUserAdmin && isFunctionalityAllowed(FunctionalityType.ReportComment) ?
                <a className='answer-text' onClick={props.onReportCB}>Report</a> : null
            }
            {
              isFunctionalityAllowed(FunctionalityType.DeleteComment) ?
                <>
                  {
                    isFunctionalityAllowed(FunctionalityType.ReportComment) ?
                      <div className='dot-round'></div> : null
                  }
                  <a className='answer-text' onClick={props.onDeleteCB}>Delete</a>
                </> : null
            }
          </div>
        </div>
      </div >
      {
        props.showConnectionLine ?
          <div className='reply-connection-line'></div>
          : null
      }
    </div>
  );
};

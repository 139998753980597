
import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useStores } from '../../../../../models';
import {EMPTY_CART} from './../../../../../constants';
import {AddCartSkeleton} from './AddCartSkeleton';
import './addCart.scss';

export const AddCart = () => {
  const rootStore = useStores();
  const {isLoading} = rootStore.cartStore;
  return (
    <>
      <div className='add-cart'>
        {isLoading ? (
        <AddCartSkeleton />
        ):(<div className='empty-cart text-center'>
            <img src={EMPTY_CART} alt='Empty Cart' />
            <h3>Your cart is empty!</h3>
            <p>You haven’t added anything yet. When you do they’ll show here.</p>
            <Link to='/' className='btn primary'>Let's go</Link>
          </div>
        )}
      </div>
    </>
  );
};


import {
  COLLECTION_ICON, LIKE_ICON, LIKE_ICON_ACTIVE, NO_COLLECTIBLES, NO_COLLECTORS,
  NO_POST,
  NO_SAVED_CARD, NO_SHIPPING_ADDRESS, NO_WISHLIST_ITEMS
} from '../../../../constants';

export const NoCollectorsFound = () => {
  return (
    <div className='empty-box collectors-empty-box'>
      <img src={NO_COLLECTORS} className='nopost-image' alt='post' />
      <p className='empty-text'>No result found!</p>
    </div>
  );
};

export const NoCollectiblesFound = () => {
  return (
    <div className='empty-box collectors-empty-box'>
      <img src={NO_COLLECTIBLES} className='nopost-image' alt='post' />
      <p className='empty-text'>No result found!</p>
    </div>
  );
};

export const NoCardFound = (props: {
  onClick?: () => void
}) => {
  return (
    <div className='no-card-saved'>
      <div className='no-card-saved-container'>
        <img src={NO_SAVED_CARD} className='nopost-image' alt='post' />
        <h2>No Card Saved!</h2>
        <p>You haven't saved any card yet. When you do they'll show here.</p>
        <button
          type='button'
          className='btn primary'
          onClick={props.onClick}
        >
          Add Card
        </button>
      </div>
    </div>
  );
};

export const NoAddressFound = (props: {
  onClick: () => void
}) => {
  return (
    <div className='no-shipping-address'>
      <div className='no-shipping-address-container'>
        <img src={NO_SHIPPING_ADDRESS} className='nopost-image' alt='post' />
        <h2>No Shipping Address Saved!</h2>
        <p>You haven’t saved any Shipping Address yet. When you do they’ll show here.</p>
        <button
          type='button'
          className='btn primary'
          onClick={props.onClick}
        >
          Add Shipping Address
        </button>
      </div>
    </div>
  );
};

export const NoWishlistItems = (props: {
  onClick: () => void
}) => {
  return (
    <div className='empty-wishlist-page'>
      <div className='empty-wishlist-container'>
        <img src={NO_WISHLIST_ITEMS} className='nopost-image' alt='post' />
        <h2>You haven't added any products yet.</h2>
        <p>Tap <img src={LIKE_ICON_ACTIVE} className='heart-icon'></img> to add products.</p>
        <button
          type='button'
          className='btn primary'
          onClick={props.onClick}
        >
          Browse
        </button>
      </div>
    </div>
  )
};

export const NoPosts = (props: {
  noPostsHeading?: string,
  noPostsMessage?: string,
  showCreatePost?: boolean,
  showClearFilter?: boolean,
  onCreatePostClick?: () => void,
  onClearFiltersClick?: () => void
}) => {
  return (
    <div className='empty-box post-empty-box'>
      <img src={NO_POST} className='nopost-image' alt='post' />
      {
        props.noPostsHeading !== '' ?
          <h2 className='no-card-text'>{props.noPostsHeading ?? 'Feed is empty!'}</h2> : null
      }
      {
        props.noPostsMessage !== '' ?
          <p className='empty-text'>{props.noPostsMessage ?? 'There is no post available to show.'}</p> : null
      }
      <div className='no-post-button'>
        {
          props.showCreatePost ?
            <button type='button' className='btn primary' onClick={props.onCreatePostClick}>Create Post</button> : null
        }
        {
          props.showClearFilter ?
            <button type='button' className='btn outline-primary' onClick={props.onClearFiltersClick}>Clear Filters</button> : null
        }
      </div>
    </div>
  );
};

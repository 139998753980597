import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import wrapperComponent from '../../Templates/wrapperComponent';
import { useStores } from '../../../../models';
import { MagicNumber } from './../../../../constants';
import { CheckoutSteps, errorToast } from './../../Atoms';
import { CartShippingAddress } from '../cart/cartShippingAddress/CartShippingAddress';
import { CardPaymentDetail } from '../cart/cardPaymentDetail/CardPaymentDetail';
import { PriceSummarySkeleton } from '../cart/priceSummary/PriceSummarySkeleton';
import '../cart/cart.scss';
import { useParams,useHistory } from 'react-router-dom'; 
import { BidsSummary } from './BidsSummary';
import { OrderSummary } from '../cart/orderSummary/OrderSummary';

export interface param {
  buy: string;
}

const Cart = observer(function Settings() {
  const [loader, setLoader] = useState<boolean>(false);
  const rootStore = useStores();
  const { savedAddressList } = rootStore.settingStore;
  const { currentBidStep, setBidCurrentStep, bid, changeBid } = rootStore.userStore;
  const { addPaymentCardInfo, addPaymentCardDetail } = rootStore.cartStore;
  const { saveCvc, setCvcElement } = rootStore.cardStore;
  const params: param = useParams();
  const componentWillUnmount = useRef(false);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
      setBidCurrentStep(MagicNumber.TWO);
      addPaymentCardInfo(null);
      addPaymentCardDetail({});
      changeBid('');
      saveCvc('');
      setCvcElement('');
    };
  }, []);

  useEffect(() => {
    if (!bid) {
      window.location.href = '/';
    }
  }, [currentBidStep]);

  const nextStep = (event: any) => {
    if (event === MagicNumber.THREE && !savedAddressList.length) {
      errorToast('Please add address for delivery');
      return;
    }
    setBidCurrentStep(event);
  };

  const prevStep = (event: any) => {
    setBidCurrentStep(event);
  };

  return (
    <>
      <div className='cart-page-wrapper'>
        <div className='filled-cart'>
          <div className='cart-header'>
            {currentBidStep > MagicNumber.THREE ? <h3>Bid Summary</h3> : <h3>Details</h3>}
            {currentBidStep < MagicNumber.FOUR ? <CheckoutSteps activeStep={currentBidStep} setPrevState={prevStep} buynow='j' /> : ''}
          </div>
          <div className='cart-outer'>
            <div className='cart-items'>
              {currentBidStep === MagicNumber.TWO ?
                <CartShippingAddress />
                : ''}
              {currentBidStep === MagicNumber.THREE && (
                <CardPaymentDetail
                  setPrevState={prevStep}
                />
              )}
              {currentBidStep === MagicNumber.FOUR && (
                <OrderSummary
                  buynow={params.buy}
                  setPrevState={prevStep}
                  isLoader={loader}
                />
              )}
            </div>
            <div className='detail-pay-right'>
              {loader ? (<PriceSummarySkeleton />) : (
                <BidsSummary
                  setNextStep={nextStep}
                  loader={loader}
                  hideActionButton={currentBidStep}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default wrapperComponent(true, false, true)(Cart);

import React, { useState, useEffect } from 'react';
import {toJS} from 'mobx';
import { Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStores } from '../../../../models';
import { errorToast } from '../../Atoms';
import { FollwerListSkeleton } from './../../Pages/profile/ProfileSkeleton';
import { FollowerUserList } from './../../Molecules/followList/FollowerUserList';
import { WHITE_CLOSE_ICON, SEARCH, MagicNumber } from '../../../../constants';
import './modal.scss';

type IProps = {
  openModal: (e: boolean) => void;
  isOpen: boolean;
  title: string;
  userId: number;
  followersMood: boolean;
};

export const FollowersList = ({ openModal, isOpen, title, userId, followersMood }: IProps) => {
  const rootStore = useStores();
  const [followersUserList, setFollowersUserList] = useState<any>([]);
  const [scrollLoader, setScrollLoader] = useState<boolean>(false);
  const [removeLoader, setRemoveLoader] = useState<boolean>(false);
  const [targetId, setTargetId] = useState<number>(0);
  const [searchResultStatus, checkSearchResultStatus] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [currentListPage, setCurrentListPage] = useState<number>(MagicNumber.ONE);
  const [search, setSearch] = useState('');
  const { getFollowersList, followersList, followersListTotalpage, removeFollower, getFollowCount, getUnfollowStatus } = rootStore.followerStore;

  const fetchFollowersList = async(id:number) => {
    setLoader(true);
    await getFollowersList(id);
    setFollowersUserList(toJS(followersList));
    setLoader(false);
  };

  useEffect(() => {
    fetchFollowersList(userId);
  }, []);

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setLoader(true);
    getFollowersList(userId, MagicNumber.ONE, MagicNumber.FIFTEEN, search).then(res => {
      const {results} = res;
      setFollowersUserList(results);
      setCurrentListPage(MagicNumber.ONE);
      setLoader(false);
      checkSearchResultStatus(true);
    }).catch(error => {
      errorToast(error);
    });
  };


  const fetchMoreData = () => {
    if (currentListPage < followersListTotalpage) {
      setScrollLoader(true);
      getFollowersList(userId, currentListPage + MagicNumber.ONE, MagicNumber.FIFTEEN, search && search).then(res => {
        const {results} = res;
        const updatedList = [...followersUserList, ...results];
        setFollowersUserList(updatedList);
        setCurrentListPage(currentListPage + MagicNumber.ONE);
        setScrollLoader(false);
      }).catch(error => {
        errorToast(error);
      });
    }
  };

  const removeFolloweUser = async(id: number) => {
    const filterList = followersUserList.filter((e:any) => e.id !== id);
    setRemoveLoader(true);
    setTargetId(id);
    await removeFollower(id).then(res=> {
      if(res && res.data && res.data.data === true) {
        setFollowersUserList(filterList);
        getUnfollowStatus(true);
      }
    });
    await getFollowCount(userId);
    setRemoveLoader(false);
  };

  const clearSearch = () => {
    if(searchResultStatus) {
      setCurrentListPage(MagicNumber.ONE);
      fetchFollowersList(userId);
      checkSearchResultStatus(false);
    };
    setSearch('');
  };

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        onHide={openModal}
        backdrop={false}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='follow-modal-dialog theme-modal-dialog modal-dialog-centered'
        contentClassName='theme-modal-content'
      >
        <Modal.Header>
          <div className='close-icon' onClick={() => openModal(false)}>
              <img src={WHITE_CLOSE_ICON} className='close-image' alt='close' />
          </div>
          <Modal.Title id='example-modal-sizes-title-sm'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='modal-search'>
            <form onSubmit={(e) => handleSubmit(e)}>
              <button 
                type='submit'
                className='search-icon'
                >
                <img
                  src={SEARCH}
                  alt='search'
                />
              </button>
              <input
                type='text'
                placeholder='Search...'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search && <span className='icon icon-cross clearSearch' onClick={clearSearch} />}
            </form>
          </div>
            <div id='scrollableDiv' className='follower-following-list'>
            <InfiniteScroll
            dataLength={followersUserList.length}
            hasMore={true}
            loader={<p></p>}
            next={fetchMoreData}
            scrollableTarget='scrollableDiv'
          >
            <>
              {followersUserList.length ? (
                followersUserList.map((item: any, key: number) => {
                  return (
                    <FollowerUserList
                      listItem={item}
                      currentUserId={userId}
                      userFollowersMood={followersMood}
                      removeUser={()=>{removeFolloweUser(item.id);}}
                      removeLoader={removeLoader}
                      targetId={targetId}
                    />
                  );
                })
              ):(loader ? <FollwerListSkeleton /> : <p className='no-list-results'>No result found!</p>)}
            </>
            {scrollLoader && <span className='loading'/>}
            </InfiniteScroll>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};


import React, { useContext, useState } from 'react';
import { Avatar, ChatContext } from 'stream-chat-react';
import { getCleanImage } from '../../assets';
import { MagicNumber } from '../../../../../../constants/MagicNumber';
import './MessagingChannelPreview.css';
import { RECEIVED_MESSAGE } from '../../../../../../constants';
import {useStores} from '../../../../../../models';
import { observer } from 'mobx-react';

const getAvatarGroup = (members) => {

  if (members.length === 1) {
    return <Avatar image={getCleanImage(members[0])} size={40} />;
  }

  if (members.length === MagicNumber.TWO) {
    return (
      <div className='channel-preview__avatars two'>
        <span>
          <Avatar image={getCleanImage(members[0])} shape='square' size={40} />
        </span>
        <span>
          <Avatar image={getCleanImage(members[1])} shape='square' size={40} />
        </span>
      </div>
    );
  }

  if (members.length === MagicNumber.THREE) {
    return (
      <div className='channel-preview__avatars three'>
        <span>
          <Avatar image={getCleanImage(members[0])} shape='square' size={40} />
        </span>
        <span>
          <Avatar image={getCleanImage(members[1])} shape='square' size={20} />
          <Avatar image={getCleanImage(members[2])} shape='square' size={20} />
        </span>
      </div>
    );
  }

  if (members.length >= MagicNumber.FOUR) {
    return (
      <div className='channel-preview__avatars'>
        <span>
          <Avatar image={getCleanImage(members[members.length - 1])} shape='square' size={20} />
          <Avatar image={getCleanImage(members[members.length - 2])} shape='square' size={20} />
        </span>
        <span>
          <Avatar image={getCleanImage(members[members.length - 3])} shape='square' size={20} />
          <Avatar image={getCleanImage(members[members.length - 4])} shape='square' size={20} />
        </span>
      </div>
    );
  }

  return null;
};

const getTimeStamp = (channel) => {
  let lastHours = channel.state.last_message_at?.getHours();
  let lastMinutes = channel.state.last_message_at?.getMinutes();
  let half = 'AM';

  if (lastHours === undefined || lastMinutes === undefined) {
    return '';
  }

  if (lastHours > 12) {
    lastHours = lastHours - 12;
    half = 'PM';
  }

  if (lastHours === 0) lastHours = 12;
  if (lastHours === 12) half = 'PM';

  if (lastMinutes.toString().length === 1) {
    lastMinutes = `0${lastMinutes}`;
  }

  return `${lastHours}:${lastMinutes} ${half}`;
};

const getChannelName = (members) => {
  const defaultName = '';

  if (!members.length || members.length === MagicNumber.ONE) {
    return members[0]?.user.name || defaultName;
  }

  return `${members[0]?.user.name || defaultName}, ${members[1]?.user.name || defaultName}`;
};

const MessagingChannelPreview = observer(function MessagingChannelPreview(props) {
  const rootStore = useStores();
  const { channel, latestMessage, setActiveChannel, setIsCreating } = props;
  const { channel: activeChannel, client } = useContext(ChatContext);
  const [currentChannel, setActiveChannels] = useState(activeChannel);

  const members = Object.values(channel.state.members).filter(
    ({ user }) => user.id !== client.userID,
  );

  rootStore.messageStore.updateCountAction(activeChannel.state.unreadCount);

  const readMessage = async (channel) => {
    rootStore.messageStore.updateCountAction(channel.state.unreadCount);
    channel.state.unreadCount = 0;
    await channel.markRead();
  }

  return (
    <div
      className={
        channel?.id === currentChannel?.id
          ? 'channel-preview__container selected'
          : 'channel-preview__container'
      }
      onClick={() => {
        setIsCreating(false);
        setActiveChannel(channel);
        readMessage(channel)
      }}
    >
      {getAvatarGroup(members)}
      <div className='channel-preview__content-wrapper'>
        <div className='channel-preview__content-top'>
          <p className='channel-preview__content-name'>
            {channel.data.name || getChannelName(members)}
          </p>
          {channel?.id !== activeChannel?.id && channel.state.unreadCount > MagicNumber.ZERO ? <p className='channel-preview__unread-message'><span className="round">{channel.state.unreadCount}</span></p> : ""}
        </div>
        <div className="channel-preview__content-bottom">
        <p className='channel-preview__content-message'>{latestMessage || 'Send a message'}</p>
         <p className='channel-preview__content-time'>
           {/* <img src={RECEIVED_MESSAGE} className="deliver-image" alt="search"/> */}{getTimeStamp(channel)}
          </p>
        </div>
      </div>
    </div>
  );
});

export default MessagingChannelPreview;

import nature_img1 from '../assets/images/nature1.png';
import nature_img2 from '../assets/images/nature2.png';
import nature_img3 from '../assets/images/nature3.png';

import adidas_logo from '../assets/images/adidas-logo.png';
import angels_logo from '../assets/images/angels-logo.png';
import arrow_down from '../assets/images/arrow-down.png';
import art_img1 from '../assets/images/art-img1.jpg';
import art_img2 from '../assets/images/art-img2.jpg';
import art_img3 from '../assets/images/art-img3.jpg';
import art_img4 from '../assets/images/art-img4.jpg';
import art_img5 from '../assets/images/art-img5.jpg';
import full_screen from '../assets/images/full-screen.svg';
import art_img6 from '../assets/images/art-img6.jpg';
import art_img7 from '../assets/images/art-img7.jpg';
import art_img8 from '../assets/images/art-img8.jpg';
import boss_logo from '../assets/images/boss-logo.png';
import collector_img1 from '../assets/images/collector-img1.jpg';
import collector_img2 from '../assets/images/collector-img2.jpg';
import collector_img3 from '../assets/images/collector-img3.jpg';
import collector_img4 from '../assets/images/collector-img4.jpg';
import collector_img5 from '../assets/images/collector-img5.jpg';
import collector_img6 from '../assets/images/collector-img6.jpg';
import collector_img7 from '../assets/images/collector-img7.jpg';
import collector_img8 from '../assets/images/collector-img8.jpg';
import collector_img9 from '../assets/images/collector-img9.jpg';
import collector_img10 from '../assets/images/collector-img1.jpg';
import collector_img11 from '../assets/images/collector-img11.jpg';
import collector_img12 from '../assets/images/collector-img12.jpg';
import collector_img13 from '../assets/images/collector-img13.jpg';
import collector_img14 from '../assets/images/collector-img14.jpg';
import collector_img15 from '../assets/images/collector-img15.jpg';
import delivery from '../assets/images/Delivery.png';
import facebook from '../assets/images/facebook.png';
import franklin_logo from '../assets/images/franklin-logo.png';
import gap_logo from '../assets/images/gap-logo.png';
import gaurantee from '../assets/images/gaurantee.png';
import homepage_bg from '../assets/images/homepage-bg.jpg';
import instagram from '../assets/images/instagram.png';
import left_arrow from '../assets/images/left-arrow.png';
import levis_logo from '../assets/images/levis-logo.png';
import linked_in from '../assets/images/linked-in.png';
import logo_white from '../assets/images/logo-white.png';
import logo from '../assets/images/logo.png';
import user_drop from '../assets/images/user-drop-icon.png';
import media_img1 from '../assets/images/media-img1.jpg';
import media_img2 from '../assets/images/media-img2.jpg';
import media_img3 from '../assets/images/media-img3.jpg';
import media_img4 from '../assets/images/media-img4.jpg';
import media_img5 from '../assets/images/media-img5.jpg';
import media_img6 from '../assets/images/media-img6.jpg';
import media_img7 from '../assets/images/media-img7.jpg';
import media_img8 from '../assets/images/media-img8.jpg';
import music_img1 from '../assets/images/music-img1.jpg';
import music_img2 from '../assets/images/music-img2.jpg';
import music_img3 from '../assets/images/music-img3.jpg';
import music_img4 from '../assets/images/music-img4.jpg';
import HD_Mobile from '../assets/images/HD_Mobile.jpg';
import music_img5 from '../assets/images/music-img5.jpg';
import music_img6 from '../assets/images/music-img6.jpg';
import music_img7 from '../assets/images/music-img7.jpg';
import music_img8 from '../assets/images/music-img8.jpg';
import nav_cart from '../assets/images/nav-cart.png';
import nav_notification from '../assets/images/nav-notification.png';
import nike_logo from '../assets/images/nike-logo.png';
import puma_logo from '../assets/images/puma-logo.png';
import rebook_logo from '../assets/images/rebook-logo.png';
import right_arrow from '../assets/images/right-arrow.png';
import search from '../assets/images/search.png';
import sports_img1 from '../assets/images/sports-img1.jpg';
import sports_img2 from '../assets/images/sports-img2.jpg';
import sports_img3 from '../assets/images/sports-img3.jpg';
import sports_img4 from '../assets/images/sports-img4.jpg';
import sports_img5 from '../assets/images/sports-img5.jpg';
import sports_img6 from '../assets/images/sports-img6.jpg';
import sports_img7 from '../assets/images/sports-img7.jpg';
import sports_img8 from '../assets/images/sports-img8.jpg';
import support from '../assets/images/support.png';
import twitter from '../assets/images/twitter.png';
import user_icon from '../assets/images/user-icon.png';
import user_placeholder_small from '../assets/images/user-icon-small.png';
import whatsapp from '../assets/images/whatsapp.png';
import back_arrow from '../assets/images/back-arrow.png';
import wishlist_icon from '../assets/images/wishlist-icon.png';
import wishlist_icon_active from '../assets/images/wishlist-icon-active.svg';
import wishlist_icon_responsive from '../assets/images/wishlist-icon-responsive.png';
import message_arrow from '../assets/images/message-icon.svg';
import cross_icon from '../assets/images/cross-icon.png';
import lock_icon from '../assets/images/lock-icon.png';
import lock_white_icon from '../assets/images/lock-white-icon.svg';
import video_icon_white from '../assets/images/video-icon-square.png';
import placeholder from '../assets/images/Placeholder.png';
import close_cicrle_white from '../assets/images/close-cicrle-white.png';
import Send_icon from '../assets/images/send-icon.png';
import Send_Icon_Black from '../assets/images/send-icon-black.png';
import Calendar_icon from '../assets/images/calendar-icon.png';
import icMusic_icon from '../assets/images/icMusic.png';
import icVideo_icon from '../assets/images/icVideo.png';
import icPdf_icon from '../assets/images/icPdf.png';
import white_close from '../assets/images/close-white.png';
import close_black from '../assets/images/close-black.svg';
import nav_search from '../assets/images/nav-search.svg';
import apple_icon from '../assets/images/apple-icon.svg';
import google_icon from '../assets/images/google-icon.png';
import password_show from '../assets/images/password-show.svg';
import password_delete_show from '../assets/images/password-delete-show.svg';
import ic_delete_account from '../assets/images/ic-delete-account.svg';
import delete_true from '../assets/images/delete_true.svg';
import white_arrow_icon from '../assets/images/arrow-right.svg';
import white_arrow from '../assets/images/white-right-arrow.png';
import white_arrow_down from '../assets/images/white_arrow_down.svg';
import back_btn_icon from '../assets/images/back-icon.svg';
import email_show from '../assets/images/email-icon.png';
import camera_icon from '../assets/images/camera-icon.png';
import sold_icon from '../assets/images/sold.png';
import pending_icon from '../assets/images/icon-pending.svg';
import collection_icon from '../assets/images/icon-collection.svg';
import setting_icon from '../assets/images/settings.png';
import password_hide from '../assets/images/password-hide.svg';
import password_delete_hide from '../assets/images/password-delete-hide.svg';
import profile_image from '../assets/images/profile-image.png';

import trash_icon from '../assets/images/trash.png';
import visa_icon from '../assets/images/visa.png';
import edit_icon from '../assets/images/edit-icon.png';
import master_icon from '../assets/images/icMaster@3x.png';
import american_icon from '../assets/images/icAmericanExpress@2x.png';
import stars_icon from '../assets/images/star-combined.png';
import signin_veri from '../assets/images/signin-verified.png';
import empty_cart from '../assets/images/empty-cart.png';
import filter_icon from '../assets/images/filter-icon.png';
import success_icon from '../assets/images/success-icon.png';
import reload_icon from '../assets/images/reload-icon.png';
import upload_icon from '../assets/images/upload-icon.png';
import menu_close_icon from '../assets/images/menu-close-icon.png';
import add_icon from '../assets/images/add-icon.png';
import star_icon from '../assets/images/star-icon.png';
import info_help from '../assets/images/info-help.png';
import success_checked from '../assets/images/success-checked.png';
import icon_media_image from '../assets/images/icon-media-image.jpg';
import product_placeholder from '../assets/images/product_place.jpeg';
import filter from '../assets/images/filter.svg';
import sort from '../assets/images/sort.png';
import discount_code_overlay from '../assets/images/DiscountCodeoverlay.png';
import comment_icon from '../assets/images/comment-icon.png';
import dot_drop_icon from '../assets/images/dot-drop-icon.svg';
import like_icon_active from '../assets/images/like-icon-active.svg';
import like_icon from '../assets/images/like-icon.png';
import share_icon from '../assets/images/share-icon.png';
import add_video_icon from '../assets/images/add-video-icon.png';
import image_icon from '../assets/images/image-icon.png';
import remove_icon from '../assets/images/remove-icon.png';
import text_icon from '../assets/images/text-icon.png';
import video_icon from '../assets/images/video-icon.png';
import video_play_icon from '../assets/images/video-play-icon.png';
import admin_profile_icon from '../assets/images/admin-image.png';
import copy_icon from '../assets/images/copy-icon.png';
import user_icon_opaque from '../assets/images/user-admin-icon.jpg';
import search_black_icon from '../assets/images/search-black-icon.png';
import message_edit from '../assets/images/message-edit.png';
import search_black from '../assets/images/search-black-icon.png';
import received_message from '../assets/images/message-received.png';
import no_post from '../assets/images/no-post.png';
import right_arrow_icon from '../assets/images/icon-arrow-right.svg';
import linked_icon from '../assets/images/linked-icon.png';
import no_collectibles from '../assets/images/collectibles-empty.png';
import no_collectors from '../assets/images/collectors-empty.png';
import no_saved_card from '../assets/images/no-card-saved.png';
import no_shipping_address from '../assets/images/shipping-address-empty.png';
import no_wishlist_items from '../assets/images/wishlist-empty.png';
import apple_store from '../assets/images/apple-store.png';
import google_store from '../assets/images/google-store.png';
import no_messaging from '../assets/images/no-messaging.svg';
import fedex_logo from '../assets/images/fedex-logo.png';
import ups_logo from '../assets/images/ups-logo.png';
import usps_logo from '../assets/images/usps-logo.png';
import track_icon from '../assets/images/track-icon.png';
import plus_pink_icon from '../assets/images/plus-pink-icon.svg';
import no_notification from '../assets/images/no-notification.png';
import productImg1 from '../assets/images/product-01.jpg';
import productImg2 from '../assets/images/product-02.jpg';
import productImg3 from '../assets/images/product-03.jpg';
import prev_Icon from '../assets/images/prev-icon.svg';
import next_Icon from '../assets/images/next-icon.svg';
import twitter_Icon from '../assets/images/twitter-icon.png';
import instagram_Icon from '../assets/images/instagram-icon.png';
import facebook_Icon from '../assets/images/facebook-icon.png';
import snapchat_Icon from '../assets/images/snapchat-icon.png';
import tiktok_Icon from '../assets/images/tiktok-icon.png';
import link_Icon from '../assets/images/link-icon.png';
import collector_Image from '../assets/images/collector-image.jpg';
import ood_Logo from '../assets/images/ood-logo.svg';
import collector_Product from '../assets/images/collector-product.png';
import sing_Img from '../assets/images/signature.png';
import dick_Img1 from '../assets/images/dick-img1.jpg';
import dick_Img2 from '../assets/images/dick-img2.jpg';
import dick_Img3 from '../assets/images/dick-img3.jpg';
import dick_Img4 from '../assets/images/dick-img4.jpg';
import dick_Img5 from '../assets/images/dick-img5.jpg';
import dick_Img6 from '../assets/images/dick-img6.jpg';
import quote_Start from '../assets/images/quote-start-icon.svg';
import quote_End from '../assets/images/quote-end-icon.svg';
import video_Icon from '../assets/images/video-play-icon.svg';
import collector_Video from '../assets/images/collector-video.mp4';
import collector_pl from '../assets/images/collector_pl.jpeg';
import ic_cart from '../assets/images/ic_cart.svg';
import ic_profile from '../assets/images/ic_profile.svg';
import ic_instagram from '../assets/images/ic_instagram.svg';
import ic_twitter from '../assets/images/ic_twitter.svg';
import ic_linkedin from '../assets/images/ic_linkedin.svg';


export const ADIDAS_LOGO = adidas_logo;
export const USER_PLACEHOLDER = user_icon;
export const USER_PLACEHOLDER_SMALL = user_placeholder_small;
export const ANGELS_LOGO = angels_logo;
export const ARROW_DOWN = arrow_down;
export const ART_IMG1 = art_img1;
export const ART_IMG2 = art_img2;
export const ART_IMG3 = art_img3;
export const ART_IMG4 = art_img4;
export const ART_IMG5 = art_img5;
export const ART_IMG6 = art_img6;
export const ART_IMG7 = art_img7;
export const ART_IMG8 = art_img8;
export const FULL_SCREEN = full_screen;
export const BOSS_LOGO = boss_logo;
export const COLLECTOR_IMG1 = collector_img1;
export const COLLECTOR_IMG2 = collector_img2;
export const COLLECTOR_IMG3 = collector_img3;
export const COLLECTOR_IMG4 = collector_img4;
export const COLLECTOR_IMG5 = collector_img5;
export const COLLECTOR_IMG6 = collector_img6;
export const COLLECTOR_IMG7 = collector_img7;
export const COLLECTOR_IMG8 = collector_img8;
export const COLLECTOR_IMG9 = collector_img9;
export const COLLECTOR_IMG10 = collector_img10;
export const COLLECTOR_IMG11 = collector_img11;
export const COLLECTOR_IMG12 = collector_img12;
export const COLLECTOR_IMG13 = collector_img13;
export const COLLECTOR_IMG14 = collector_img14;
export const COLLECTOR_IMG15 = collector_img15;
export const DELIVERY = delivery;
export const FACEBOOK = facebook;
export const FRANKLIN_LOGO = franklin_logo;
export const GAP_LOGO = gap_logo;
export const GAURANTEE = gaurantee;
export const HOMEPAGE_BG = homepage_bg;
export const INSTAGRAM = instagram;
export const LEFT_ARROW = left_arrow;
export const LEVIS_LOGO = levis_logo;
export const LINKED_IN = linked_in;
export const LOGO_WHITE = logo_white;
export const LOGO = logo;
export const USER_DROP = user_drop;
export const MEDIA_IMG1 = media_img1;
export const MEDIA_IMG2 = media_img2;
export const MEDIA_IMG3 = media_img3;
export const MEDIA_IMG4 = media_img4;
export const MEDIA_IMG5 = media_img5;
export const MEDIA_IMG6 = media_img6;
export const MEDIA_IMG7 = media_img7;
export const MEDIA_IMG8 = media_img8;
export const MUSIC_IMG1 = music_img1;
export const MUSIC_IMG2 = music_img2;
export const MUSIC_IMG3 = music_img3;
export const MUSIC_IMG4 = music_img4;
export const HD_Mobiles = HD_Mobile;
export const MUSIC_IMG5 = music_img5;
export const MUSIC_IMG6 = music_img6;
export const MUSIC_IMG7 = music_img7;
export const MUSIC_IMG8 = music_img8;
export const NAV_CART = nav_cart;
export const NAV_NOTIFICATIO = nav_notification;
export const NIKE_LOGO = nike_logo;
export const PUMA_LOGO = puma_logo;
export const REBOOK_LOGO = rebook_logo;
export const RIGHT_ARROW = right_arrow;
export const SEARCH = search;
export const SPORTS_IMG1 = sports_img1;
export const SPORTS_IMG2 = sports_img2;
export const SPORTS_IMG3 = sports_img3;
export const SPORTS_IMG4 = sports_img4;
export const SPORTS_IMG5 = sports_img5;
export const SPORTS_IMG6 = sports_img6;
export const SPORTS_IMG7 = sports_img7;
export const SPORTS_IMG8 = sports_img8;
export const SUPPORT = support;
export const TWITTER = twitter;
export const USER_ICON = user_icon;
export const WHATSAPP = whatsapp;
export const FRANKLIN_IMG = franklin_logo;
export const CROSS_ICON = cross_icon;
export const BLACK_ICON = back_arrow;
export const WISHLIST_ICON = wishlist_icon;
export const WISHLIST_ICON_RESPONSIVE = wishlist_icon_responsive;
export const WISHLIST_ICON_ACTIVE = wishlist_icon_active;

export const LOCK_ICON = lock_icon;

export const MESSAGE_ICON = message_arrow;
export const PLACEHOLDER = placeholder;
export const WHITE_CLOSE_ICON = close_cicrle_white;
export const APPLE_STORE = apple_store;
export const GOOGLE_STORE = google_store;
export const SEND_ICON = Send_icon;
export const CALENDAR_ICON = Calendar_icon;

export const PDF_ICON = icPdf_icon;
export const VIDEO_ICON = icVideo_icon;
export const MUSIC_ICON = icMusic_icon;
export const WHITE_CLOSE = white_close;
export const CLOSE_BLACK = close_black;
export const NAV_SEARCH = nav_search;
export const PASSWORD_SHOW = password_show;
export const PASSWORD_SHOW_delete = password_delete_show;
export const delete_icon=delete_true;
export const Delete_Account=ic_delete_account;
export const GOOGLE_ICON = google_icon;
export const APPLE_ICON = apple_icon;
export const WH_ARROW_ICON = white_arrow_icon;

export const WHITE_ARROW = white_arrow;
export const WHITE_ARROW_DOWN = white_arrow_down;
export const BACK_BTN_ICON = back_btn_icon;
export const EMAIL_SHOW = email_show;
export const CAMERA_ICON = camera_icon;
export const SOLD_ICON = sold_icon;
export const SETTING_ICON = setting_icon;
export const PASS_ICON = password_hide;
export const PASS_ICON_delete = password_delete_hide;
export const PROFILE_ICON = profile_image;
export const TRASH_ICON = trash_icon;
export const VISA_ICON = visa_icon;
export const EDIT_ICON = edit_icon;
export const MASTER_ICON = master_icon;
export const AMERICAN_ICON = american_icon;
export const STARS_ICON = stars_icon;
export const SIGNIN_VERI = signin_veri;
export const FILTER_ICON = filter_icon;
export const SUCCESS_ICON = success_icon;
export const EMPTY_CART = empty_cart;
export const RESEND_ICON = reload_icon;
export const UPLOAD_ICON = upload_icon;
export const MENU_ICON_CLOSE = menu_close_icon;
export const ADD_ICON = add_icon;
export const STAR_ICON = star_icon;
export const PENDING_ICON = pending_icon;
export const COLLECTION_ICON = collection_icon;
export const INFO_HELP = info_help;
export const SUCCESS_CHECKED = success_checked;
export const ICON_MEDIA = icon_media_image;
export const PRODUCT_PLACEHOLDER = product_placeholder;
export const FILTER = filter;
export const SORT_ICON = sort;
export const DISCOUNT_CODE_OVERLAY = discount_code_overlay;
export const COMMENT_ICON = comment_icon;
export const DOT_DROP_ICON = dot_drop_icon;
export const LIKE_ICON_ACTIVE = like_icon_active;
export const LIKE_ICON = like_icon;
export const SHARE_ICON = share_icon;
export const ADD_VIDEO_ICON = add_video_icon;
export const IMAGE_ICON = image_icon;
export const REMOVE_ICON = remove_icon;
export const TEXT_ICON = text_icon;
export const VIDEO_ICON_2 = video_icon;
export const VIDEO_ICON_WHITE = video_icon_white;

export const VIDEO_PLAY_ICON = video_play_icon;
export const ADMIN_PROFILE_ICON = admin_profile_icon;
export const COPY_ICON = copy_icon;
export const USER_PLACEHOLDER_OPAQUE = user_icon_opaque;
export const searchblackIcon = search_black_icon;
export const MESSAGE_EDIT = message_edit;
export const SEARCH_BLACK = search_black;
export const RECEIVED_MESSAGE = received_message;
export const BACK_ARROW = back_arrow;
export const NO_POST = no_post;

export const LINKED_ICON = linked_icon;

export const NO_COLLECTIBLES = no_collectibles;
export const NO_COLLECTORS = no_collectors;
export const NO_SAVED_CARD = no_saved_card;
export const NO_SHIPPING_ADDRESS = no_shipping_address;
export const NO_WISHLIST_ITEMS = no_wishlist_items;
export const NO_MESSAGING = no_messaging;
export const FEDEX_LOGO = fedex_logo;
export const UPS_LOGO = ups_logo;
export const USPS_LOGO = usps_logo;
export const TRACK_ICON = track_icon;
export const PLUS_PINK_ICON = plus_pink_icon;
export const NO_NOTIFICATION = no_notification;
export const PRODUCTIMG1 = productImg1;
export const PRODUCTIMG2 = productImg2;
export const PRODUCTIMG3 = productImg3;
export const PREV_ICON = prev_Icon;
export const NEXT_ICON = next_Icon;
export const RIGHT_ARROW_ICON = right_arrow_icon;
export const TWITTER_ICON = twitter_Icon;
export const INSTAGRAM_ICON = instagram_Icon;
export const FACEBOOK_ICON = facebook_Icon;
export const SNAPCHAT_ICON = snapchat_Icon;
export const TIKTOK_ICON = tiktok_Icon;
export const LINK_ICON = link_Icon;
export const COLLECTOR_IMAGE = collector_Image;
export const OOD_LOGO = ood_Logo;
export const COLLECTOR_PRODUCT = collector_Product;
export const SIGN_IMG = sing_Img;
export const DICK_IMG1 = dick_Img1;
export const DICK_IMG2 = dick_Img2;
export const DICK_IMG3 = dick_Img3;
export const DICK_IMG4 = dick_Img4;
export const DICK_IMG5 = dick_Img5;
export const DICK_IMG6 = dick_Img6;

export const QUOTE_START = quote_Start;

export const QUOTE_END = quote_End;
export const VIDEOPLAY_ICON = video_Icon;
export const COLLECTOR_VIDEO = collector_Video;
export const LOCK_WHITE_ICON = lock_white_icon;
export const SEND_ICON_BLACK = Send_Icon_Black;
export const COLLECTOR_PL = collector_pl;
export const HEADER_CART = ic_cart;
export const NAV_PROFILE = ic_profile;
export const INSTA_SVG = ic_instagram;
export const TWITTER_SVG = ic_twitter;
export const LINKDIN_SVG = ic_linkedin;

export const NATURE_IMG1 = nature_img1;
export const NATURE_IMG2 = nature_img2;
export const NATURE_IMG3 = nature_img3;


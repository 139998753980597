import React, { useEffect, useState } from 'react';
import { useStores } from '../../../../../models';
import { AMERICAN_ICON, getSavedCardAddress, MagicNumber, MASTER_ICON, STAR_ICON, VALIDATION_MESSAGES, VISA_ICON } from '../../../../../constants';
import { observer } from 'mobx-react-lite';
import { CardPaymentSkeleton } from './CardPaymentSkeleton';
import { NoCardFound } from '../../../Molecules/emptyScreens/EmptyScreens';

var stripe;
var cardCvcEle;

export const CardListing = observer(function CardListing(props) {

  const [loader, setLoader] = useState(false);
  const rootStore = useStores();
  const { getSavedCard, savedCard, setStripe,
    setCvcElement, cvcStatus
  } = rootStore.cardStore;

  const createCvcElement = async () => {
    var style = {
      base: {
        color: '#000',
        lineHeight: '22px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#f6f6f9'
        },
        backgroundColor: '#f6f6f9',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };
    const stripePromise = window.Stripe(process.env.REACT_APP_STRIPE_PUBISHABLE_KEY);
    stripe = stripePromise;
    setStripe(stripePromise);
    const elements = stripePromise.elements();
    const cardCvcElement = elements.create('cardCvc', { style: style });
    cardCvcElement.mount('#cvcelement');
    cardCvcEle = cardCvcElement;
    setCvcElement(cardCvcElement);
  }

  const loadStripeWindow = async () => {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(process.env.REACT_APP_STRIPE_PUBISHABLE_KEY);
      }
      window.document.body.appendChild(s);
    }
    setTimeout(() => {
      createCvcElement();
    }, MagicNumber.ONE_TH);
  }

  const fetchCard = async () => {
    setLoader(true);
    await getSavedCard();
    setLoader(false);
  }

  useEffect(() => {
    fetchCard();
  }, []);

  useEffect(() => {
    loadStripeWindow();
  }, { savedCard });

  const cardExpiry = (expiry) => {
    return ('0' + expiry).slice(-MagicNumber.TWO)
  }

  const cardImage = (brand) => {
    let cardIcon = '';
    if (brand === 'MasterCard') {
      cardIcon = MASTER_ICON;
    } else if (brand === 'Visa') {
      cardIcon = VISA_ICON
    } else if (brand === 'American Express') {
      cardIcon = AMERICAN_ICON
    }
    return <img src={cardIcon} alt="" className='logo-image' />;
  }

  const RenderStars = (props) => {
    const stars = new Array(props.count).fill(MagicNumber.ZERO);
    return (
      <p className="number">
        {stars.map(star => {
          return (
            <img src={STAR_ICON} alt="cart number" className="star-image" />
          )
        })}
      </p>
    )
  }

  return (
    <>
      {loader ? (<CardPaymentSkeleton />) : (
        savedCard && savedCard.cardData && savedCard.cardData.id ? <><div className="payment-detail-section active-payment">
          <div className="card-detail">
            <div className="full-info-card">
              <div className="card-logo">
                {cardImage(savedCard && savedCard.cardData && savedCard.cardData.brand)}
              </div>
              <div className="holder-name">{savedCard.cardData.account_holder_name}</div>
              <div className="card-digit">
                <div className="payment-number-type">
                  <RenderStars count={MagicNumber.FOUR} />
                  <RenderStars count={MagicNumber.FOUR} />
                  <RenderStars count={MagicNumber.FOUR} />
                  <p className="number">{savedCard.cardData.last_four_digit}</p>
                </div>
              </div>
              <div className="date-detail">Expiry Date: <span>{`${cardExpiry(savedCard.cardData.exp_month) || ''}/${cardExpiry(savedCard.cardData.exp_year) || ''}`}</span></div>
            </div>
          </div>
          <div className="cvc-detail">
            <label className="cvc-title">CVC</label>
            <div className="form-group" id="cvcelement">
            </div>
            {cvcStatus ? <label className="error-cvc">{VALIDATION_MESSAGES.EXPIRY_CVC}</label> : null}
          </div>
        </div>
          <div className="billing-address-panel">
            <div className="inner-box2">
              <div className="main-head" >Billing Address</div>
              <div className="data-text">
                {getSavedCardAddress(savedCard)}
              </div>
            </div>
          </div>
        </> : !loader ? <NoCardFound onClick={props.onAddCard} /> : null
      )}
    </>
  )
});


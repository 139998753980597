import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { WISHLIST_ICON, SETTING_ICON, PAGE_ROUTES} from '../../../../constants';

export const ProfileSkeleton = () => {
  return (
    <>
        <div className='search-user-detail-skeleton main-profile'>
            <div className='left-wrapper'>
                <div className='skeleton-profile-box'>
                    <Skeleton />
                </div>
                <div className='skeleton-profile-detail'>
                    <Skeleton count={4} />
                </div>
            </div>
            <div className='right-wrapper'>
                <div className='skeleton-action-box'>
                    <div className='right-item'>
                        <div className='icons'>
                        <Link to={PAGE_ROUTES.WISHLIST}><img src={WISHLIST_ICON} alt='wishlist' /></Link>
                        <Link to={PAGE_ROUTES.SETTING} className='ml-4'><img src={SETTING_ICON} alt='setting' /></Link>
                        </div>
                    </div>
                    <Skeleton/>
                </div>
            </div>
        </div>
    </>
  );
};

export const FollwerListSkeleton = () => {
    return (
      <>
          <div className='search-user-detail-skeleton follwing-list'>
              <div className='left-wrapper'>
                  <div className='skeleton-profile-box'>
                      <Skeleton count={6} />
                  </div>
                  <div className='skeleton-profile-detail'>
                      <Skeleton count={12} />
                  </div>
              </div>
          </div>
      </>
    );
  };

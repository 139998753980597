import { observer } from 'mobx-react';
import { Modal } from 'react-bootstrap';
import CLOSE_ICON from '../../../../assets/images/close-cicrle-white.png';
import FEDEX_LOGO from '../../../../assets/images/fedex-logo.png';
import UPS_LOGO from '../../../../assets/images/ups-logo.png';
import USPS_LOGO from '../../../../assets/images/usps-logo.png';
import './_shippedModal.scss';
import { FullPageLoader, Input } from '../../Atoms';
import { useState } from 'react';
import { HTTPSTATUSCODE, MagicNumber } from '../../../../constants';
import { useStores } from '../../../../models';
import { STATUS_CODES } from 'node:http';

type IProps = {
    openModal: (e: boolean) => void;
    isOpen?: boolean;
    qty: any;
    itemId: any;
    orderId: any
};

type ShipmentFieldIProps = {
    title: string;
    setFieldValue: (key: string, value: string) => void;
    notes?: string;
    carrierName?: string;
    carrierTitle?: string;
    trackingNo?: string;
    isSubmit?: boolean;
};

export const ShipmentField = observer(function ShipmentField({
    title,
    carrierTitle,
    trackingNo,
    notes,
    isSubmit,
    setFieldValue
}: ShipmentFieldIProps) {
    return (
        <div className="form-section">
            {title === 'other' ? <div className="form-group">
                <Input
                    id='carrier_title'
                    name='carrier_title'
                    type='text'
                    value={carrierTitle}
                    placeholder='Other Service Name*'
                    setFieldValue={setFieldValue}
                    maxLength={MagicNumber.FIFTY}
                />
                {isSubmit && carrierTitle === '' ? <p className='error-text'>Please enter other service name</p> : ''}
            </div> : ''}
            <div className="form-group">
                <Input
                    id='tracking_no'
                    name='tracking_no'
                    type='text'
                    value={trackingNo}
                    placeholder='Tracking Number*'
                    setFieldValue={setFieldValue}
                    maxLength={MagicNumber.FIFTY}
                />
                {isSubmit && trackingNo === '' ? <p className='error-text'>Please enter tracking number</p> : ''}
            </div>
            <div className="form-group">
                <Input
                    id='notes'
                    name='notes'
                    type='text'
                    value={notes}
                    placeholder='Add Information'
                    setFieldValue={setFieldValue}
                    maxLength={MagicNumber.TWO_FIFTY}
                />
            </div>
        </div>
    );
});

export const ShipmentModel = observer(function ShipmentModel({
    openModal,
    isOpen,
    qty,
    itemId,
    orderId
}: IProps) {

    const rootStore = useStores();
    const { addShipment } = rootStore.collectionStore;
    const [isSelectFedex, setFedex] = useState<boolean>(false);
    const [isSelectUps, setUps] = useState<boolean>(false);
    const [isSelectUsps, setUsps] = useState<boolean>(false);
    const [isSelectOther, setOther] = useState<boolean>(false);
    const [isSubmit, onSubmit] = useState<boolean>(false);
    const [isLoading, setLoader] = useState<boolean>(false);
    const [carrierName, setCarrierName] = useState<string>('');
    const [carrierTitle, setCarrierTitle] = useState<string>('');
    const [trackingNo, setTrackingNo] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [apiMessage, setApiMessage] = useState<string>('');

    const resetForm = async () => {
        setCarrierName('');
        setCarrierTitle('');
        setTrackingNo('');
        setNotes('');
        setFedex(false);
        setUps(false);
        setUsps(false);
        setOther(false);
        onSubmit(false);
    };

    const handleCheck = (event: any) => {
        resetForm();
        const { id } = event.target;
        setCarrierName(id);
        if (id === 'fedex') {
            setFedex(true);
            setCarrierTitle('Fedex');
        } else if (id === 'ups') {
            setUps(true);
            setCarrierTitle('UPS');
        } else if (id === 'usps') {
            setUsps(true);
            setCarrierTitle('USPS');
        } else {
            setOther(true);
        }
    };

    const setFieldValue = (key: string, value: string) => {
        if (key === 'carrier_title') {
            setCarrierTitle(value);
        } else if (key === 'tracking_no') {
            setTrackingNo(value);
        } else {
            setNotes(value);
        }
    };

    const handleHidePopup = () => {
        resetForm();
        openModal(false);
    };

    const handleSubmit = async () => {
        setLoader(true);
        const data = {
            order_id: orderId,
            order_item_id: itemId,
            qty: parseInt(qty),
            carrier_name: carrierName,
            carrier_title: carrierTitle,
            tracking_no: trackingNo,
            note: notes
        };
        if (carrierName !== '' && trackingNo !== '' && carrierTitle !== '') {
            onSubmit(false);
            const shipment = await addShipment(data);
            setLoader(false);
            if (shipment && shipment.status === HTTPSTATUSCODE.BAD_REQUEST) {
                setApiMessage(shipment.data.message);
            } else {
                handleHidePopup();
            }
        } else {
            setLoader(false);
            onSubmit(true);
        }
    };

    return (
        <>
            {isLoading && <FullPageLoader />}
            <Modal show={isOpen} onHide={() => openModal(false)} className="modal">
                <div className="shipped-carrier-page">
                    <div className="close-icon" onClick={handleHidePopup}>
                        <img src={CLOSE_ICON} className="close-image" alt="close" />
                    </div>
                    <h1 className="title-text">Select Carrier</h1>
                    <Modal.Body className="carrier-body">
                        <div className="carrier-box-section">
                            <div className="carrier-container">
                                <div className="carrier-box">
                                    <div className="carrier-radio-custom">
                                        <input className="form-check-input" onChange={(event) => handleCheck(event)} type="radio" name="files_type" id="fedex" value="text" />
                                        <label id="text" htmlFor="fedex" className="content-label">
                                            <div className="image-box">
                                                <img src={FEDEX_LOGO} className="carrier-logo" alt="close" />
                                            </div>
                                            <p className="detail-text">FEDEX</p>
                                        </label>
                                    </div>
                                </div>
                                {isSelectFedex ? <ShipmentField trackingNo={trackingNo} isSubmit={isSubmit} notes={notes} title='fedex' setFieldValue={setFieldValue} /> : ''}
                            </div>
                            <div className="carrier-container">
                                <div className="carrier-box">
                                    <div className="carrier-radio-custom">
                                        <input className="form-check-input" onChange={(event) => handleCheck(event)} type="radio" name="files_type" id="ups" value="text" />
                                        <label id="text" htmlFor="ups" className="content-label">
                                            <div className="image-box">
                                                <img src={UPS_LOGO}
                                                    className="carrier-logo" alt="close" />
                                            </div>
                                            <p className="detail-text">UPS</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {isSelectUps ? <ShipmentField title='ups' trackingNo={trackingNo}
                                isSubmit={isSubmit} notes={notes} setFieldValue={setFieldValue} /> : ''}
                            <div className="carrier-container">
                                <div className="carrier-box">
                                    <div className="carrier-radio-custom">
                                        <input className="form-check-input" onChange={(event) => handleCheck(event)} type="radio" name="files_type" id="usps" value="text" />
                                        <label id="text" htmlFor="usps" className="content-label">
                                            <div className="image-box">
                                                <img src={USPS_LOGO} className="carrier-logo" alt="close" />
                                            </div>
                                            <p className="detail-text">USPS</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {isSelectUsps ? <ShipmentField isSubmit={isSubmit} trackingNo={trackingNo} notes={notes} title='usps' setFieldValue={setFieldValue} /> : ''}
                            <div className="carrier-container">
                                <div className="carrier-box">
                                    <div className="carrier-radio-custom">
                                        <input className="form-check-input" onChange={(event) => handleCheck(event)} type="radio" name="files_type" id="other" value="text" />
                                        <label id="text" htmlFor="other" className="content-label">
                                            <p className="detail-text">Others</p>
                                        </label>
                                    </div>
                                </div>
                                {isSelectOther ? <ShipmentField isSubmit={isSubmit} trackingNo={trackingNo} title='other' notes={notes} carrierTitle={carrierTitle} setFieldValue={setFieldValue} /> : ''}
                            </div>
                            {isSubmit && carrierName === '' ? <p className='error-text page-error-text'>Please select any carrier</p> : ''}
                            {!isSubmit && apiMessage ? <p>{apiMessage}</p> : ''}
                        </div>
                        <div className="submit-button">
                            <button type="button"
                                className="btn primary"
                                onClick={handleSubmit}
                            >
                                Done
                            </button>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    );
});

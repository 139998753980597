import React, { useEffect, useState } from 'react';
import { Chat, Channel, ChannelList, MessageList, MessageInput, Window } from 'stream-chat-react';
import { useChecklist } from './ChecklistTasks';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { STREAM_TOKEN, MagicNumber, FEED_USER_ID, } from '../../../../constants';
import { Header } from '../../Molecules';
import 'stream-chat-react/dist/css/index.css';
import './chat.css';
import {
  CreateChannel,
  CustomMessage,
  MessagingChannelHeader,
  MessagingChannelList,
  MessagingChannelPreview,
  MessagingInput,
  MessagingThread,
} from './components';
import { chatClient } from '../../../../utils/stream';

const Chats = () => {
  const urlParams = new URLSearchParams(window.location.search);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const streamToken = Cookies.get(STREAM_TOKEN);
  const userId = Cookies.get(FEED_USER_ID)
  const userProfile = Cookies.get('user_profile');
  if (Cookies.get('user_data') !== undefined) {
    var userData = JSON.parse(Cookies.get('user_data'));
  }

  const apiKey = (process.env.REACT_APP_STREAM_API_KEY ?? '');
  const user = userId;
  const userToken = streamToken;
  const targetOrigin = process.env.REACT_APP_TARGET_ORIGIN;
  const filters = {
    $or: [
      {
        members: {
          $in: [user],
        },
      },
      {
        hidden: true,
      }
    ]
  }
  const sort = {
    last_message_at: MagicNumber.MINUS_ONE
  };
  const options = { watch: true };
  if (user !== undefined && userData !== undefined && !chatClient.connecting) {
    var userToConnect = { id: user, name: `${userData.firstname} ${userData.lastname}`, image: userProfile };
    chatClient.connectUser(userToConnect, userToken);
  }

  const [isCreating, setIsCreating] = useState(query.get('userId') ? true : false);
  const [isMobileNavVisible, setMobileNav] = useState(false);
  useChecklist(chatClient, targetOrigin);
  useEffect(() => {
    const mobileChannelList = document.querySelector('#mobile-channel-list');
    if (isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.add('show');
    } else if (!isMobileNavVisible && mobileChannelList) {
      mobileChannelList.classList.remove('show');
    }
  }, [isMobileNavVisible]);

  const toggleMobile = () => {
    if (window.innerWidth < 600) {
      setMobileNav(!isMobileNavVisible)
    }
  }

  return (
    <div className="chat-header">
      <Header></Header>
      <Chat client={chatClient} theme={`messaging light`}>
        <div className="chat-container">
          <div id='mobile-channel-list' onClick={toggleMobile}>
            <ChannelList
              filters={filters}
              sort={sort}
              options={options}
              List={(props) => (
                <MessagingChannelList {...props} onCreateChannel={() => {
                  setIsCreating(!isCreating)}} />
              )}
              Preview={(props) => <MessagingChannelPreview {...props} {...{ setIsCreating }} />}
            />
            <div className="no-data-preview">
              {isCreating && (
                <CreateChannel toggleMobile={toggleMobile} id={query.get('userId')}
                onClose={() => setIsCreating(false)} />
              )}
            </div>
          </div>
          <div>
            <Channel>
              {isCreating && (
                <CreateChannel toggleMobile={toggleMobile} id={query.get('userId')}
                 onClose={() => setIsCreating(false)} />
              )}
              <Window>
                <MessagingChannelHeader theme={'light'} toggleMobile={toggleMobile} />
                <MessageList
                  messageActions={[]}
                  Message={CustomMessage}
                  TypingIndicator={() => null}
                />
                <MessageInput focus Input={MessagingInput} />
              </Window>
              <MessagingThread />
            </Channel>
          </div>
        </div>
      </Chat>
    </div>
  );
};

export default Chats;

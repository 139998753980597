import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Modal, FieldError, Input } from '../../Atoms';
import { validateEmail } from '../../../../constants';
import Cookies from "js-cookie";
import { useEffect, useState } from 'react';

type IProps = {
    openModal: (e: boolean) => void;
    handleNotifyUser: (e: any) => void;
    isOpen?: boolean;
    heading?: string;
}

export const NotifyModal = observer(function Home({ openModal, isOpen, handleNotifyUser, heading }: IProps) {

    const [email, setUserEmail] = useState<string>('');

    useEffect(() => {
        let userEmail = Cookies.get('email');
        if (!userEmail) {
            return;
        }
        setUserEmail(userEmail)
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            openModal={openModal}
            header='Notify Me'
            isSmall
            isNotify
            subHeading={heading ? heading : "Get notified as soon as product is available."}>
            <Formik
                initialValues={{ email: email }}
                onSubmit={async values => {
                    await handleNotifyUser(values)
                }}
                validationSchema={validateEmail}
                render={({ handleSubmit, values, setFieldValue, errors, touched, dirty }) => (
                    <Form className="form-section">
                        <div className={`form-group ${errors.email && touched.email ? 'form-group-error' : ''}`}>
                            <Input
                                required
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={values.email}
                                setFieldValue={setFieldValue}
                            />
                            <FieldError name="email" />
                        </div>
                        <div className="submit-button">
                            <button type="button" className="btn primary" disabled={!errors ? true : false} onClick={(e: any) => handleSubmit(e)}>Submit</button>
                        </div>
                    </Form>
                )} />
        </Modal>

    )
});

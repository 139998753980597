import { FeedMedia } from './FeedMedia';
import { PostType } from '../FeedListingUtil';
import { BaseFeedItem, Props } from './BaseFeedItem';

export const FeedItem = (props: Props) => {

  const postID = props.feedItem.id ?? '';
  const postType = props.feedItem.feed_type ? PostType[props.feedItem.feed_type as keyof typeof PostType] : PostType.text;
  const postMediaURLs = props.feedItem.digital ?? [];

  const onViewFeed = () => {
    props.onViewFeedCB?.(postID);
  };

  return (
    <BaseFeedItem
      feedItem={props.feedItem}
      feed={props.feed}
      streamClient={props.streamClient}
      commentsDisabled={props.commentsDisabled}
      optionsDisabled={props.optionsDisabled}
      reactionsDisabled={props.reactionsDisabled}
      refreshItem={props.refreshItem}
      onDeleteCommentCB={props.onDeleteCommentCB}
      onDeleteFeedCB={props.onDeleteFeedCB}
      onDeleteReplyCB={props.onDeleteReplyCB}
      onReportPostCB={props.onReportPostCB}
      onReportCommentCB={props.onReportCommentCB}
      onReportReplyCB={props.onReportReplyCB}
      onShareFeedCB={props.onShareFeedCB}
      onViewFeedCB={props.onViewFeedCB}
      onViewFeedLikesCB={props.onViewFeedLikesCB}
      onViewFeedSharesCB={props.onViewFeedSharesCB}
      postUpdateAttemptedByGuestCB={props.postUpdateAttemptedByGuestCB}
    >
      <FeedMedia postType={postType} postMediaURLs={postMediaURLs}
        onViewFeedCB={onViewFeed} />
    </BaseFeedItem>
  );
};


import {useState} from 'react';
import DropDown from '../../../../assets/images/dropdown-icon.png';


const FaqCard = (props:any)=>{
    const {question, answer} = props.faq;
    const [cardExpand, setCardExpand] = useState(false);

    return (
        <>
            <div className='faq-wrapper'>
                <div className='question-section'>
                    <h6>{question}</h6>
                    <img src={DropDown} className={`dropdown ${cardExpand? 'retract' : '' }`} onClick={()=> setCardExpand(preState => !preState)} ></img>
                </div>
                <p className='answer-section'>{cardExpand ? answer : ''}</p>
            </div>
        </>
    );
};

export default FaqCard;

import { observer } from 'mobx-react-lite';
import { connect, StreamClient, StreamFeed } from 'getstream';
import { useStores } from '../../../../models';
import { useEffect, useState } from 'react';
import wrapperComponent from '../../Templates/wrapperComponent';
import { FeedItem } from './feedItem/FeedItem';
import './feed-listing.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import { errorToast, FullPageLoader, successToast } from '../../Atoms';
import { NO_POST, LOGO,FILTER_ICON } from '../../../../constants/image';
import { FunctionalityType, MagicNumber, PAGE_ROUTES, reportContentValidation, STREAM_TOKEN, UserType } from '../../../../constants';
import { CreatePost } from '../../Molecules/createPostModal/CreatePost';
import { CompleteProfileModal, SignInModal } from '../../Molecules';
import { SignupModal } from '../../Molecules/signupModal/signupModal';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactionsList } from '../../Molecules/reactionsListModal/ReactionsList';
import { SharePost } from '../../Molecules/sharePostModal/SharePost';
import { SharedFeedItem } from './feedItem/SharedFeedItem';
import { FeedItemSkeleton } from './feedItem/skeletons/FeedItemSkeleton';
import { FeedGroup, getLinkToFeedItem, getReactionTypeName, isAdminId, isFeedItemShared, PostType, ReactionType, RoleType } from './FeedListingUtil';
import { ConfirmationBox } from '../../Molecules/confirmationBoxModal/ConfirmationBox';
import { ConfirmationInfos } from '../../../../constants/ConfirmationInfos';
import { TextConfirmationBox } from '../../Molecules/confirmationBoxModal/TextConfirmationBox';
import { SearchParameters } from '../../../../data/SearchParameters';
import { NoPosts } from '../../Molecules/emptyScreens/EmptyScreens';

type Props = {
  userId?: string,
  feedGroup?: FeedGroup,
  style?: React.CSSProperties,
  noPostsHeading?: string,
  noPostsMessage?: string,
  filtersDisabled?: boolean,
  titleDisabled?: boolean,
  postCreationDisabled?: boolean,
  postCreationOnNoPostsDisabled?: boolean,
};

export enum ParameterType {
  search,
  role,
  feedType
}

const FeedListing = observer(function FeedListing(props: Props) {

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const setURLParameters = (params: URLSearchParams) => {
    if (!params) return;

    params.forEach((value, key) => {
      searchParameters.addParamater(key, value ?? undefined);
    });
  };

  const searchParameters = new SearchParameters();
  const pageQuery = useQuery();

  setURLParameters(pageQuery);

  const rootStore = useStores();
  const { getMorePosts, initializeStream, reportComment, deletePost, reportPost,
    filterPosts, clearTotalPostsCount, removeReaction, forceRemoveContent, userID } = rootStore.feedStreamStore;
  const { isLoggedIn, streamTokenData, isFunctionalityAllowed } = rootStore.loginStore;
  const { isUserDiscontinue, changeStatusUserDiscontinue, isLoading: isRetrievingProfile } = rootStore.profileStore;

  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;

  const [signInModalOpen, setSignInModalOpen] = useState<boolean>(false);
  const [isProfileOpen, openProfileModal] = useState<boolean>(false);
  const [isSignUpOpen, openSignUpModal] = useState<boolean>(false);
  const [isEmailBtn, disableEmailBtn] = useState<boolean>(false);

  const [feedList, setFeedList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(MagicNumber.ZERO);
  const [pageLimit] = useState<number>(MagicNumber.FIVE);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const [feedStream, setFeedStream] = useState<StreamFeed>();
  const [streamClient, setStreamClient] = useState<StreamClient>();

  const [isShowingReactionsList, setIfShowingReactionsList] = useState<boolean>(false);
  const [showingReactionType, setShowingReactionType] = useState<ReactionType>(ReactionType.like);

  const [isCreatingPost, setIfCreatingPost] = useState<boolean>(false);
  const [isSharingPost, setIfSharingPost] = useState<boolean>(false);
  const [isDeletingPost, setIsDeletingPost] = useState<boolean>(false);
  const [isReportingPost, setIsReportingPost] = useState<boolean>(false);
  const [isShowingDeletingPostConfirmation, setShowingDeletingPostConfirmation] = useState<boolean>(false);
  const [isShowingDeletingCommentConfirmation, setShowingDeletingCommentConfirmation] = useState<boolean>(false);
  const [isShowingReportPostConfirmation, setShowingReportPostConfirmation] = useState<boolean>(false);
  const [isShowingDiscardPostConfirmation, setShowingDiscardPostConfirmation] = useState<boolean>(false);

  const [deletingPostID, setDeletingPostID] = useState<string>('');
  const [deletingPostUserID, setDeletingPostUserID] = useState<string>('');

  const [filterCollapse, openFilterCollapse] = useState<boolean>(false);
  const [isRefreshingFeedItem, setIsRefreshingFeedItem] = useState<boolean>(false);

  const [deletingCommentID, setDeletingCommentID] = useState<string>('');
  const [deletingCommentUserID, setDeletingCommentUserID] = useState<string>('');
  const [deletingCommentPostID, setDeletingCommentPostID] = useState<string>('');
  const [deletingCommentPostUserName, setDeletingCommentPostUserName] = useState<string>('');
  const [isDeletingComment, setIsDeletingComment] = useState<boolean>(false);

  const [isShowingReportCommentConfirmation, setShowingReportCommentConfirmation] = useState<boolean>(false);
  const [reporteeUserId, setReporteeUserId] = useState<number>(MagicNumber.ZERO);
  const [reportedPostID, setReportedPostID] = useState<string>('');
  const [reportedContent, setReportedContent] = useState<string>('');

  const [searchFilter, setSearchFilter] = useState<string>(
    searchParameters.getParameterValue(ParameterType[ParameterType.search]) ??
    '');

  const [roleFilters, setRoleFilters] = useState<string[]>(
    searchParameters.getParameterValue(ParameterType[ParameterType.role]) ?
      (searchParameters.getParameterValue(ParameterType[ParameterType.role]) as string).split(',') : []
  );
  const [feedTypeFilters, setFeedTypeFilters] = useState<string[]>(
    searchParameters.getParameterValue(ParameterType[ParameterType.feedType]) ?
      (searchParameters.getParameterValue(ParameterType[ParameterType.feedType]) as string).split(',') : []
  );

  const [currentConfirmationInfo, setCurrentConfirmationInfo] = useState<any>({});

  const [currentFeedItemID, setCurrentFeedItemID] = useState<string>('');

  useEffect(() => {
    isUserDiscontinue && changeStatusUserDiscontinue();
  }, []);

  useEffect(() => {
    refreshFeed();
  }, [streamTokenData]);

  useEffect(() => {
    clearFeed();
    retrieveMoreFeed([], feedStream, streamClient, MagicNumber.ZERO);
    return () => {
      clearFeed();
    };
  }, [feedStream, roleFilters, feedTypeFilters, searchFilter]);


  useEffect(() => {
    setURLParameters(pageQuery);
    setSearchFilter(
      searchParameters.getParameterValue(ParameterType[ParameterType.search]) ??
      '');

    setRoleFilters(searchParameters.getParameterValue(ParameterType[ParameterType.role]) ?
      (searchParameters.getParameterValue(ParameterType[ParameterType.role]) as string).split(',') : []
    );

    setFeedTypeFilters(searchParameters.getParameterValue(ParameterType[ParameterType.feedType]) ?
      (searchParameters.getParameterValue(ParameterType[ParameterType.feedType]) as string).split(',') : []
    );
  }, [location]);

  const checkIfAllFiltersSelected = (roles: string[], feedTypes: string[]): boolean => {
    if (!roles.length && !feedTypes.length)
      return true;

    return checkIfAllRoleTypeFiltersSelected(roles) && checkIfAllFeedTypeFiltersSelected(feedTypes);
  };

  const checkIfAllRoleTypeFiltersSelected = (roles: string[]): boolean => {
    let filterMissing = false;
    Object.values(RoleType).forEach(role => {
      if (!roles.includes(role)) {
        filterMissing = true;
        return;
      }
    });

    return !filterMissing;
  }
;
  const checkIfAllFeedTypeFiltersSelected = (feedTypes: string[]): boolean => {
    let filterMissing = false;
    Object.values(PostType).forEach(feedType => {
      if (!feedTypes.includes(feedType)) {
        filterMissing = true;
        return;
      }
    });

    return !filterMissing;
  };

  const checkIfRoleIncludedInFilters = (role: RoleType): boolean => {
    return roleFilters.includes(RoleType[role]);
  };

  const checkIfFeedTypeIncludedInFilters = (feedType: PostType): boolean => {
    return feedTypeFilters.includes(PostType[feedType]);
  };

  const clearRoleAndFeedFilters = () => {
    searchParameters.removeParameter(ParameterType[ParameterType.role]);
    searchParameters.removeParameter(ParameterType[ParameterType.feedType]);
    history.push(pathName + searchParameters.getParametersString());
  };

  const clearAllFilters = () => {
    searchParameters.clear();
    history.push(pathName + searchParameters.getParametersString());
  };

  const toggleRoleFilter = (role: RoleType) => {
    if (isLoading) return;

    const updatedFilters = [...roleFilters];
    if (checkIfRoleIncludedInFilters(role)) {
      const value: string = RoleType[role];
      const index = updatedFilters.indexOf(value);
      if (index > -MagicNumber.ONE) {
        updatedFilters.splice(index, MagicNumber.ONE);
        searchParameters.addParamater(ParameterType[ParameterType.role], updatedFilters.join(','));
      }

    } else {
      updatedFilters.push(RoleType[role]);
      if (!checkIfAllFiltersSelected(updatedFilters, feedTypeFilters)) {
        searchParameters.addParamater(ParameterType[ParameterType.role], updatedFilters.join(','));
      }
      else {
        searchParameters.removeParameter(ParameterType[ParameterType.role]);
        searchParameters.removeParameter(ParameterType[ParameterType.feedType]);
      }
    }

    history.push(`${pathName}${searchParameters.getParametersString()}`);
  };

  const toggleFeedTypeFilter = (feedType: PostType) => {
    if (isLoading) return;

    const updatedFilters = [...feedTypeFilters];
    if (checkIfFeedTypeIncludedInFilters(feedType)) {
      const value: string = PostType[feedType];
      const index = updatedFilters.indexOf(value);
      if (index > -MagicNumber.ONE) {
        updatedFilters.splice(index, MagicNumber.ONE);
        searchParameters.addParamater(ParameterType[ParameterType.feedType], updatedFilters.join(','));
      }

    } else {
      updatedFilters.push(PostType[feedType]);
      if (!checkIfAllFiltersSelected(roleFilters, updatedFilters)) {
        searchParameters.addParamater(ParameterType[ParameterType.feedType], updatedFilters.join(','));
      }
      else {
        searchParameters.removeParameter(ParameterType[ParameterType.role]);
        searchParameters.removeParameter(ParameterType[ParameterType.feedType]);
      }
    }

    history.push(`${pathName}${searchParameters.getParametersString()}`);
  };

  const clearFeed = () => {
    setFeedList([]);
    setCurrentPage(MagicNumber.ZERO);
    setIsInitialLoad(true);
    clearTotalPostsCount();
  };

  const refreshFeed = async () => {
    const feedSlug = props.feedGroup ? FeedGroup[props.feedGroup] : FeedGroup[FeedGroup.timeline];
    await initializeStream(feedSlug)
      .then(response => {
        setStreamClient(response.client);
        setFeedStream(response.feed);
      })
      .catch(error => {
        errorToast(error);
      });
  };

  const retrieveMoreFeed = async (currentFeedList: any[], feedStream: StreamFeed | undefined, streamClient: StreamClient | undefined, page: number) => {
    if (checkIfAllFiltersSelected(roleFilters, feedTypeFilters) && !props.userId && !searchFilter) {
      if (!feedStream || isLoading) return;

      setIsLoading(true);
      getMorePosts(feedStream, page, pageLimit)
        .then((data: any[]) => {
          const updatedList = [...currentFeedList, ...data];

          setFeedList(updatedList);
          setCurrentPage(page);
        })
        .catch(error => {
          errorToast(error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsInitialLoad(false);
        });
    } else {
      if (!streamClient || isLoading) return;

      setIsLoading(true);
      filterPosts(streamClient, page, pageLimit, searchFilter, roleFilters.join(','), feedTypeFilters.join(','), props.userId ?? '')
        .then((data: any[]) => {
          const updatedList = [...currentFeedList, ...data];
          setFeedList(updatedList);
          setCurrentPage(page);
        }).catch(error => {
          errorToast(error);
        })
        .finally(() => {
          setIsLoading(false);
          setIsInitialLoad(false);
        });
    }
  };

  const onViewFeed = (feedId: string) => {
    history.push(getLinkToFeedItem(feedId));
  };

  const onDeletePost = async () => {
    if (isDeletingPost) return;

    setIsDeletingPost(true);

    if (isFunctionalityAllowed(FunctionalityType.DeletePost) && !isAdminId(deletingPostUserID)) {
      forceRemoveContent(deletingPostUserID, deletingPostID, 'feed', 'feed')
        .then(response => {
          refreshFeed();
        })
        .catch(error => {
          if (error.data && error.data.message)
            errorToast(error.data.message);
        })
        .finally(() => {
          setIsDeletingPost(false);
        });
    } else {
      deletePost(deletingPostUserID, deletingPostID)
        .then(response => {
          refreshFeed();
        })
        .catch(error => {
          if (error.data && error.data.message)
            errorToast(error.data.message);
        })
        .finally(() => {
          setIsDeletingPost(false);
        });
    }
  };

  const onDeleteComment = async () => {
    if (isDeletingComment || !streamClient) return;

    setIsDeletingComment(true);
    if (isFunctionalityAllowed(FunctionalityType.DeleteComment) && !isAdminId(deletingCommentUserID)) {
      forceRemoveContent(deletingCommentUserID, deletingCommentID, 'comment', deletingCommentPostUserName)
        .then(response => {
          setIsRefreshingFeedItem(!isRefreshingFeedItem);
        })
        .catch(error => {
          if (error && error.error && error.error.detail)
            errorToast(error.error.detail);
        })
        .finally(() => {
          setIsDeletingComment(false);
        });
    } else {
      removeReaction(streamClient, deletingCommentID)
        .then(response => {
          setIsRefreshingFeedItem(!isRefreshingFeedItem);
        })
        .catch(error => {
          if (error && error.error && error.error.detail)
            errorToast(error.error.detail);
        })
        .finally(() => {
          setIsDeletingComment(false);
        });
    }
  };

  const onReportPost = async (reason: string) => {
    if (isReportingPost) return;

    setIsReportingPost(true);
    await reportPost(reporteeUserId, reportedContent, reportedPostID, reason)
      .then(response => {
        successToast(response);
      }).catch(error => {
        errorToast(error);
        throw error;
      }).finally(() => {
        setIsReportingPost(false);
      });
  };

  const onReportComment = async (reason: string) => {
    if (isReportingPost) return;

    setIsReportingPost(true);
    await reportComment(reporteeUserId, reportedContent, reportedPostID, reason)
      .then(response => {
        successToast(response);
      })
      .catch(error => {
        errorToast(error);
        throw error;
      })
      .finally(() => {
        setIsReportingPost(false);
      });
  };

  const toggleFilterCollapse = () => {
    openFilterCollapse(!filterCollapse);
  };

  const handleCreatePost = () => {
    if (isLoggedIn) {
      setIfCreatingPost(true);
    } else {
      setSignInModalOpen(true);
    }
  };

  return (
    <div className='feed-main-section' style={props.style}>
      {
        !isInitialLoad && !isLoading && !feedList.length && !isRetrievingProfile ?
          <NoPosts
            noPostsHeading={props.noPostsHeading}
            noPostsMessage={props.noPostsMessage}
            showCreatePost={isLoggedIn && (!props.userId || props.userId === userID) && !props.postCreationOnNoPostsDisabled}
            showClearFilter={!checkIfAllFiltersSelected(roleFilters, feedTypeFilters)}
            onCreatePostClick={() => { setIfCreatingPost(true) }}
            onClearFiltersClick={() => { clearRoleAndFeedFilters() }}
          /> :
          <div className='feed-container'>
            <div className={`feed-left ${filterCollapse && 'open'} `}>
              <div className='responsive-filter-head'>
                <span className='icon-close' onClick={toggleFilterCollapse}></span>
              </div>
              {
                props.filtersDisabled ? null :
                  <div className='category-container'>
                    <div className='category-box'>
                      <div className='filter-section'>
                        <h2 className='title-head'>Filters</h2>
                        <div className='filter-button-section'>
                          <button type='button'
                            className={`btn outline-secondary ${checkIfAllFiltersSelected(roleFilters, feedTypeFilters) ? 'active-filter' : ''}`}
                            disabled={isLoading}
                            onClick={() => { clearAllFilters() }}
                          >All</button>
                          <button type='button'
                            className={`btn outline-secondary ${!checkIfAllFiltersSelected(roleFilters, feedTypeFilters) &&
                              checkIfRoleIncludedInFilters(RoleType.user) ? 'active-filter' : ''}`}
                            disabled={isLoading}
                            onClick={() => { toggleRoleFilter(RoleType.user) }}
                          >User Generated</button>
                          <button type='button'
                            className={`btn outline-secondary ${!checkIfAllFiltersSelected(roleFilters, feedTypeFilters) &&
                              checkIfRoleIncludedInFilters(RoleType.admin) ? 'active-filter' : ''}`}
                            disabled={isLoading}
                            onClick={() => { toggleRoleFilter(RoleType.admin) }}
                          >OOD Generated</button>
                          <button type='button'
                            className={`btn outline-secondary ${!checkIfAllFiltersSelected(roleFilters, feedTypeFilters) &&
                              checkIfFeedTypeIncludedInFilters(PostType.text) ? 'active-filter' : ''}`}
                            disabled={isLoading}
                            onClick={() => { toggleFeedTypeFilter(PostType.text) }}
                          >Text Only</button>
                          <button type='button'
                            className={`btn outline-secondary ${!checkIfAllFiltersSelected(roleFilters, feedTypeFilters) &&
                              checkIfFeedTypeIncludedInFilters(PostType.video) ? 'active-filter' : ''}`}
                            disabled={isLoading}
                            onClick={() => { toggleFeedTypeFilter(PostType.video) }}
                          >Video Only</button>
                          <button type='button'
                            className={`btn outline-secondary ${!checkIfAllFiltersSelected(roleFilters, feedTypeFilters) &&
                              checkIfFeedTypeIncludedInFilters(PostType.image) ? 'active-filter' : ''}`}
                            disabled={isLoading}
                            onClick={() => { toggleFeedTypeFilter(PostType.image) }}
                          >Image Only</button>
                        </div>
                      </div>
                      <div className='apply-button'>
                        <button type='button' className='btn primary' onClick={toggleFilterCollapse}>Apply</button>
                      </div>
                    </div>
                  </div>
              }
            </div>
            <div className='feed-right'>
              <div className='right-head'>
                <div className='head-left'>
                  {
                    props.titleDisabled ? null : <p className='title-text'>Latest Feeds</p>
                  }
                </div>
                {
                  !props.postCreationDisabled ?
                    <div className='head-right'>
                      <button type='button' className='btn primary' onClick={() => { handleCreatePost() }}>Create Post</button>
                      <div className='responsive-filter-icon'>
                        <img src={FILTER_ICON} onClick={toggleFilterCollapse} className='filter-icon' alt='icon' />
                      </div>
                    </div> : null
                }
              </div>

              <InfiniteScroll
                dataLength={feedList.length}
                hasMore={true}
                loader={null}
                next={() => { retrieveMoreFeed(feedList, feedStream, streamClient, currentPage + MagicNumber.ONE) }}
              >
                {
                  feedList.length ?
                    feedList.map(feedItem => {
                      if (!isFeedItemShared(feedItem)) {
                        return (
                          <FeedItem
                            feedItem={feedItem}
                            streamClient={streamClient}
                            feed={feedStream}
                            refreshItem={feedItem.id === deletingCommentPostID && isRefreshingFeedItem}
                            postUpdateAttemptedByGuestCB={() => {
                              setSignInModalOpen(!signInModalOpen);
                            }}
                            onViewFeedCB={onViewFeed}
                            onViewFeedLikesCB={(feedId) => {
                              setCurrentFeedItemID(feedId);
                              setShowingReactionType(ReactionType.like);
                              setIfShowingReactionsList(true);
                            }}
                            onViewFeedSharesCB={(feedId) => {
                              setCurrentFeedItemID(feedId);
                              setShowingReactionType(ReactionType.repost);
                              setIfShowingReactionsList(true);
                            }}
                            onShareFeedCB={(feedId) => {
                              setCurrentFeedItemID(feedId);
                              setIfSharingPost(true);
                            }}
                            onDeleteFeedCB={(feedUserId, feedId) => {
                              setDeletingPostUserID(feedUserId);
                              setDeletingPostID(feedId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_POST);
                              setShowingDeletingPostConfirmation(true);
                            }}
                            onReportPostCB={(feedId, userId, reportedContent) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(reportedContent);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_POST);
                              setShowingReportPostConfirmation(true);
                            }}
                            onReportCommentCB={(feedId, userId, comment) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(comment);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_COMMENT);
                              setShowingReportCommentConfirmation(true);
                            }}
                            onReportReplyCB={(feedId, userId, reply) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(reply);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_REPLY);
                              setShowingReportCommentConfirmation(true);
                            }}
                            onDeleteCommentCB={(feedId, userId, commentId, postUserName) => {
                              setDeletingCommentPostID(feedId);
                              setDeletingCommentUserID(userId);
                              setDeletingCommentPostUserName(postUserName);
                              setDeletingCommentID(commentId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_COMMENT);
                              setShowingDeletingCommentConfirmation(true);
                            }}
                            onDeleteReplyCB={(feedId, userId, replyId, postUserName) => {
                              setDeletingCommentPostID(feedId);
                              setDeletingCommentUserID(userId);
                              setDeletingCommentPostUserName(postUserName);
                              setDeletingCommentID(replyId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_REPLY);
                              setShowingDeletingCommentConfirmation(true);
                            }}
                          />
                        );
                      }
                      else {
                        return (
                          <SharedFeedItem
                            feedItem={feedItem}
                            streamClient={streamClient}
                            feed={feedStream}
                            refreshItem={feedItem.id === deletingCommentPostID && isRefreshingFeedItem}
                            onViewFeedCB={onViewFeed}
                            onViewFeedLikesCB={(feedId) => {
                              setCurrentFeedItemID(feedId);
                              setShowingReactionType(ReactionType.like);
                              setIfShowingReactionsList(true);
                            }}
                            onViewFeedSharesCB={(feedId) => {
                              setCurrentFeedItemID(feedId);
                              setShowingReactionType(ReactionType.repost);
                              setIfShowingReactionsList(true);
                            }}
                            onShareFeedCB={(feedId) => {
                              setCurrentFeedItemID(feedId);
                              setIfSharingPost(true);
                            }}
                            postUpdateAttemptedByGuestCB={() => {
                              setSignInModalOpen(!signInModalOpen);
                            }}
                            onDeleteFeedCB={(feedUserId, feedId) => {
                              setDeletingPostUserID(feedUserId);
                              setDeletingPostID(feedId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_POST);
                              setShowingDeletingPostConfirmation(true);
                            }}
                            onReportPostCB={(feedId, userId, reportedContent) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(reportedContent);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_POST);
                              setShowingReportCommentConfirmation(true);
                            }}
                            onReportCommentCB={(feedId, userId, comment) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(comment);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_COMMENT);
                              setShowingReportCommentConfirmation(true);
                            }}
                            onReportReplyCB={(feedId, userId, reply) => {
                              setReporteeUserId(parseInt(userId));
                              setReportedPostID(feedId);
                              setReportedContent(reply);
                              setCurrentConfirmationInfo(ConfirmationInfos.REPORT_REPLY);
                              setShowingReportCommentConfirmation(true);
                            }}
                            onDeleteCommentCB={(feedId, userId, commentId, postUserName) => {
                              setDeletingCommentPostID(feedId);
                              setDeletingCommentUserID(userId);
                              setDeletingCommentPostUserName(postUserName);
                              setDeletingCommentID(commentId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_COMMENT);
                              setShowingDeletingCommentConfirmation(true);
                            }}
                            onDeleteReplyCB={(feedId, userId, replyId, postUserName) => {
                              setDeletingCommentPostID(feedId);
                              setDeletingCommentUserID(userId);
                              setDeletingCommentPostUserName(postUserName);
                              setDeletingCommentID(replyId);
                              setCurrentConfirmationInfo(ConfirmationInfos.DELETE_REPLY);
                              setShowingDeletingCommentConfirmation(true);
                            }}
                          />
                        );
                      }
                    })
                    : null
                }
                {
                  isLoading || isInitialLoad || isRetrievingProfile ? <FeedItemSkeleton /> : null
                }
              </InfiniteScroll>
            </div>
          </div>
      }
      <ConfirmationBox
        isShowing={isShowingDeletingPostConfirmation || isShowingDiscardPostConfirmation || isShowingDeletingCommentConfirmation}
        title={currentConfirmationInfo.title}
        description={currentConfirmationInfo.description}
        cancelOptionName={currentConfirmationInfo.cancelOptionName}
        confirmOptionName={currentConfirmationInfo.confirmOptionName}
        onConfirmCB={async (confirmed) => {
          if (isShowingDeletingPostConfirmation) {
            if (confirmed) {
              if (!isLoggedIn) {
                setSignInModalOpen(!signInModalOpen);
                return false;
              }

              if (isShowingDeletingPostConfirmation) {
                await onDeletePost();
              }

              setShowingDeletingPostConfirmation(false);
              return true;
            }
            else {
              setShowingDeletingPostConfirmation(false);
              return true;
            }
          } else if (isShowingDiscardPostConfirmation) {
            setShowingDiscardPostConfirmation(false);

            if (confirmed) {
              setIfCreatingPost(false);
              return true;
            }
          }
          else if (isShowingDeletingCommentConfirmation) {
            if (confirmed) {
              await onDeleteComment();
            }
            setShowingDeletingCommentConfirmation(false);
            return true;
          }

          return confirmed;
        }}
      />
      <TextConfirmationBox
        isShowing={isShowingReportPostConfirmation || isShowingReportCommentConfirmation}
        title={currentConfirmationInfo.title}
        cancelOptionName={currentConfirmationInfo.cancelOptionName}
        confirmOptionName={currentConfirmationInfo.confirmOptionName}
        validationSchema={reportContentValidation}
        onConfirmCB={async (text, confirmed) => {
          if (confirmed) {
            if (!isLoggedIn) {
              setSignInModalOpen(true);
              return false;
            }

            let errorOccured = false;
            if (isShowingReportPostConfirmation)
              await onReportPost(text).catch(error => { errorOccured = true });
            else if (isShowingReportCommentConfirmation)
              await onReportComment(text).catch(error => { errorOccured = true });

            if (!errorOccured) {
              setShowingReportPostConfirmation(false);
              setShowingReportCommentConfirmation(false);
            }

            return !errorOccured;
          }
          else {
            setShowingReportPostConfirmation(false);
            setShowingReportCommentConfirmation(false);

            return true;
          }
        }}
      />
      <CreatePost
        isShowing={isCreatingPost}
        title={'Create Post'}
        onHideCB={() => {
          setIfCreatingPost(false);
        }}
        onPostUploaded={() => {
          setIfCreatingPost(false);
          refreshFeed();
        }}
        onPostDiscarding={() => {
          setCurrentConfirmationInfo(ConfirmationInfos.DISCARD_POST);
          setShowingDiscardPostConfirmation(true);
        }}
      />
      <SharePost
        feedId={currentFeedItemID ?? ''}
        isShowing={isSharingPost}
        streamClient={streamClient}
        onHideCB={() => {
          setCurrentFeedItemID('');
          setIfSharingPost(false);
        }}
        feedStream={feedStream}
        onPostSharedCB={() => {
          setCurrentFeedItemID('');
          setIfSharingPost(false);
          refreshFeed();
        }}
      />
      <ReactionsList
        isShowing={isShowingReactionsList}
        feedId={currentFeedItemID ?? ''}
        onHideCB={() => {
          setCurrentFeedItemID('');
          setIfShowingReactionsList(false);
        }}
        kind={ReactionType[showingReactionType]}
        listTitle={getReactionTypeName(showingReactionType)}
        streamClient={streamClient}
        feedStream={feedStream}
        onProfileOpenedAsGuestCB={() => { setSignInModalOpen(true); }}
      />
      <SignInModal
        openModal={setSignInModalOpen}
        isOpen={signInModalOpen}
        openProfileModal={openProfileModal}
        openSignUpModal={openSignUpModal}
        disableBtn={disableEmailBtn}
      />
      <CompleteProfileModal isOpen={isProfileOpen} openModal={openProfileModal} isEmailBtn={isEmailBtn} />
      <SignupModal openModal={openSignUpModal} isOpen={isSignUpOpen} />
    </div>
  );
});

export default wrapperComponent(true, false, true)(FeedListing);

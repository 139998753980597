import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './modal.scss';
import './_viewAllBids.scss';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../../models';
import { MagicNumber, PRODUCTIMG1, WHITE_CLOSE, SUCCESS_CHECKED } from '../../../../constants';
import { Input } from '../../Atoms';
import { useHistory } from 'react-router-dom';
import { AuctionConfirm } from './AuctionConfirm';
import NumberFormat from 'react-number-format';

type IProps = {
  openModal: any;
  isOpen: boolean;
  dropData?: any;
  productImage?: any;
};

export const PlaceBids = observer(function ViewAllBids({ openModal, isOpen, dropData, productImage }: IProps) {

  const history = useHistory();
  const rootStore = useStores();
  const { bid, changeBid, checkBid } = rootStore.userStore;
  const { productItem } = rootStore.homeStore;
  const [reservePrice, setReservePrice] = useState<boolean>(false);

  const addBids = () => {
    if (productItem && productItem.auction && productItem.auction.reserve_price && (parseInt(bid) < productItem.auction.reserve_price)) {
      history.push('/bids');
    } else {
      history.push('/bids');
    }
  };

  const handleInput = (value: string) => {
    if (value) {
      changeBid(parseInt(value));
    } else {
      changeBid('');
    }
  };

  const handleKeyPress = (evt: any) => {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > MagicNumber.THIRTY_ONE && (charCode < MagicNumber.FOURTY_EIGHT || charCode > MagicNumber.FIFTY_SEVEN)) {
      evt.preventDefault();
    }
    return true;
  };

  const toggleModal = () => {
    setReservePrice(!reservePrice);
  };

  const currencyF = () => {
    const l10nUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const amount = l10nUSD.format(parseInt(bid) || MagicNumber.ZERO);
    return amount.split('$')[MagicNumber.ONE];
  };

  const handleEditBid = (event: any) => {
    if (event && !event.value) {
      changeBid('');
    } else {
      changeBid((event.value).replace(/^0*(.+)/, '$1'));
    }
  };

  return (
    <>
      <Modal
        size='sm'
        show={isOpen}
        backdrop={false}
        onHide={() => openModal()}
        aria-labelledby='modal-sm'
        centered
        dialogClassName='view-bids-page modal-dialog-centered'
        contentClassName='view-bids-content'
      >
        <div className='view-bids-background place-bids-bg' id='drop-bid-bg'>
          <Modal.Header className='place-header'>
            <Modal.Title id='example-modal-sizes-title-sm'>
              <p className='name'>{dropData.title ? dropData.title : dropData}</p>
              <h1 className='title-text order-title place-title'>
                Place Your Bid
              </h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              {/* <Input
                type='text'
                id='yourBid'
                name='yourBid'
                placeholder='Your Bid'
                value={bid}
                setFieldValue={(name, value) => handleInput(value)}
                handleKeyPress={handleKeyPress}
              />
              <p className='dollar-sign'>$</p>
              <p className='select-label selected'>Your Bid</p> */}
              <div className='product-image-section'>
                <div className='image-bg'>
                  <img src={productImage}
                    onError={(e: any) => (e.target.onerror = null, e.target.src = PRODUCTIMG1)}
                    className='image-product' alt='product' />
                </div>
              </div>
              <div className='amount-product'>
                {/* <h3>
                  <span className='dollar-signs'>$</span>
                  <span>{currencyF()}</span>
                </h3> */}
                <span className='prefix'>$</span>
                <NumberFormat value={bid}
                  thousandSeparator={true}
                  defaultValue={0}
                  decimalScale={2}
                  isAllowed={(values: any) => {
                    const { floatValue } = values;
                    if (!floatValue) {
                      return true;
                    } else {
                      return floatValue <= 10000000000;
                    }
                  }}
                  onValueChange={(event) => handleEditBid(event)}
                />
                {checkBid <= (bid && bid !== '' ? parseFloat(bid) : 0) ? '' : <p className='error-msg'>Minimum bid is {checkBid}</p>}
              </div >
              <div className='action-button'>
                <button type='button' className='btn secondary' onClick={() => openModal()}>Cancel</button>
                <button type='button' disabled={checkBid > (bid && bid !== '' ? parseFloat(bid) : 0)} onClick={() => addBids()} className='btn outline-secondary'>Continue</button>
              </div>
            </div >
          </Modal.Body >
        </div >
      </Modal >
      {reservePrice && (
        <AuctionConfirm
          openModal={() => { toggleModal(); }}
          title='Confirm Bid'
          description={`Your bid (${currencyF()}) is below the reserve price added by seller which is unlikely to win the auction.
           Would you like to change your bid or continue with same bid amount?`}
          isOpen={reservePrice}
        />
      )}
    </>
  );
});

import { USER_PLACEHOLDER } from '../../../../../constants/image';
import { SkeletonImage } from '../../../Atoms/SkeletonImage';
import { FeedItemCommentInput } from './FeedItemCommentInput';

type Props = {
  profileImage: string
  onReplyCB: (reply: string) => Promise<boolean>
};

export const FeedItemReplyInput = (props: Props) => {
  return (
    <div className='write-reply-section'>
      <div className='user-box'>
        <SkeletonImage
          imageURL={props.profileImage}
          classes='user-image'
          alt='user'
          placeholder={USER_PLACEHOLDER}
        />
      </div>
      <FeedItemCommentInput placeholder='Enter your reply here…' onCommentCB={props.onReplyCB} />
    </div>
  );
};

import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';
import { MagicNumber } from '../../../../../../constants';
import { ReactionUserSkeleton } from '../../../../Molecules/reactionsListModal/skeletons/ReactionUserSkeleton';
import { FeedItemUserCommentSkeleton } from './FeedItemUserCommentSkeleton';

export const FeedItemSkeleton = () => {
  return (
    <div className='feed-user-detail'>
      <div className='about-user'>
        <div className='user-info'>
          <div className='user-img'>
            <Skeleton className='image-user' />
          </div>
          <div className='name-detail'>
            <p className='name'><Skeleton width={250} /></p>
            <p className='status'><Skeleton width={100} /></p>
          </div>
        </div>
      </div>
      <ReactionUserSkeleton />
      <div className='image-feed-section'>
        <Skeleton height={230} />
      </div>
      <div className='social-share-section'>
        <div className='share-container'>
          <div className='social-box'>
            <Skeleton className='media-icon' />
            <div className='active-status'>
              <Skeleton />
            </div>
          </div>
          <div className='social-box'>
            <Skeleton className='media-icon' />
            <div className='active-status'>
              <Skeleton />
            </div>
          </div>
        </div>
        <div className='share-container'>
          <Skeleton className='media-icon' />
        </div>
      </div>
      <InfiniteScroll
        dataLength={MagicNumber.ONE}
        hasMore={true}
        loader={null}
        next={() => { }}
      >
        <div className='feed-comment-section'>
          <h3 className='comment-title'><Skeleton width={150} height={18} /></h3>
          <FeedItemUserCommentSkeleton />
        </div>
      </InfiniteScroll>
    </div>
  );
};

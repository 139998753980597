import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MENU_ICON_CLOSE, USER_PLACEHOLDER, sharePostValidation, MagicNumber, COPY_ICON, ROUTE_PATH, PAGE_ROUTES, FEED_POST_RESTRICTIONS } from '../../../../constants';
import { useStores } from '../../../../models';
import { errorToast, FullPageLoader, successToast } from '../../Atoms';
import { SkeletonImage } from '../../Atoms/SkeletonImage';
import { FeedItem } from '../../Pages/feedListing/feedItem/FeedItem';
import { FeedItemSkeleton } from '../../Pages/feedListing/feedItem/skeletons/FeedItemSkeleton';
import { getLinkToFeedItem } from '../../Pages/feedListing/FeedListingUtil';
import './_share-post.scss';

type Props = {
  streamClient: any,
  feedStream: any,
  feedId: string,
  isShowing: boolean,
  onPostSharedCB: () => void,
  onHideCB?: () => void,
};

type FormValue = {
  description: string
};

export const SharePost = (props: Props) => {

  const rootStore = useStores();
  const { getPostDetail, sharePost, handleFeed } = rootStore.feedStreamStore;
  const { getProfileDetail, profileDetail } = rootStore.profileStore;
  const { isLoggedIn } = rootStore.loginStore;

  const [isRetrievingFeedData, setIfRetrievingFeedData] = useState<boolean>(false);
  const [isFeedDataInvalid, setIfFeedDataInvalid] = useState<boolean>(false);
  const [isSharing, setIfSharing] = useState<boolean>(false);
  const [feedItem, setFeedItem] = useState<any>(null);

  useEffect(() => {
    if (isLoggedIn)
      getProfileDetail();
  }, [isLoggedIn]);

  useEffect(() => {
    retrieveFeedDetail();
  }, [props.isShowing]);

  const retrieveFeedDetail = async () => {
    if (!props.streamClient || !props.feedId)
      return;

    setIfRetrievingFeedData(true);
    getPostDetail(props.streamClient, props.feedId)
      .then(results => {
        const feedItem = results[MagicNumber.ZERO];
        if (feedItem && !feedItem.isDeleted) {
          setIfFeedDataInvalid(false);
          setFeedItem(feedItem);
        }
        else {
          setIfFeedDataInvalid(true);
        }
      })
      .catch(error => {
        setIfFeedDataInvalid(true);
      })
      .finally(() => {
        setIfRetrievingFeedData(false);
      });
  };

  const getInitialPostValues = (): FormValue => {
    return {
      description: ''
    };
  };

  const onCopyLinkClick = () => {
    const link = `${window.location.origin}${getLinkToFeedItem(props.feedId)}`;
    navigator.clipboard.writeText(link);
    successToast(FEED_POST_RESTRICTIONS.COPIED_LINK_MESSAGE);
  };

  const onSubmit = (values: FormValue, { resetForm }: any) => {
    if (!props.streamClient || !props.feedId || isSharing) return;

    setIfSharing(true);
    sharePost(props.streamClient, props.feedId, values.description.trim())
      .then(response => {
        resetForm();
        props.onPostSharedCB?.();
        setIfSharing(false);
        handleFeed('repost', props.feedId);
      })
      .catch(error => {
        errorToast(error);
        setIfSharing(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={getInitialPostValues()}
        onSubmit={onSubmit}
        validateOnMount={false}
      // validationSchema={sharePostValidation}
      >
        {formProps => (
          <Form onSubmit={formProps.handleSubmit}>
            <Modal
              size='sm'
              show={props.isShowing}
              onHide={props.onHideCB}
              backdrop={false}
              aria-labelledby='modal-sm'
              centered
              dialogClassName='feed-reaction-dialog modal-dialog-centered'
              contentClassName='feed-modal-content'
            >
              <div className='feed-share-page'>
                <Modal.Header className='share-header'>
                  <div className='close-icon'>
                    <img src={MENU_ICON_CLOSE} className='close-image'
                      alt='close' role='button' onClick={props.onHideCB} />
                  </div>
                  <Modal.Title className='contact-text' id='example-modal-sizes-title-sm'>
                    <h3 className='head-title'>Share</h3>
                  </Modal.Title>
                </Modal.Header>
                <div className='share-body'>
                  <div className='about-user'>
                    <div className='user-img-box'>
                      <SkeletonImage
                        classes='user-icon'
                        alt='user'
                        imageURL={profileDetail.profile_image ?? USER_PLACEHOLDER}
                        placeholder={USER_PLACEHOLDER}
                      />
                    </div>
                    <p className='user-detail'>{`${profileDetail.firstname} ${profileDetail.lastname}`}</p>
                  </div>
                  {
                    !isRetrievingFeedData && isFeedDataInvalid ? null :
                      <div className='type-user'>
                        <textarea name='description'
                          className='form-control'
                          placeholder='Write Text...'
                          onChange={formProps.handleChange}
                        ></textarea>
                      </div>
                  }
                  {
                    !isRetrievingFeedData && isFeedDataInvalid ? null :
                      <div className='share-feed-section'>
                        <div className='feed-box'>
                          <div className='feed-comment-section'>
                            {
                              isRetrievingFeedData ? <FeedItemSkeleton /> : null
                            }
                            {
                              !isRetrievingFeedData && !isFeedDataInvalid && feedItem ?
                                <>
                                  <FeedItem
                                    feedItem={feedItem}
                                    streamClient={props.streamClient}
                                    feed={props.feedStream}
                                    commentsDisabled
                                    reactionsDisabled
                                    optionsDisabled
                                  />
                                  <div className='link-share-section'>
                                    <div className='input-section'>
                                      <p className='form-control' >Or share this post via public link</p>
                                      <div className='copy-icon'>
                                        <img src={COPY_ICON} className='copy-image' alt='copy' />
                                      </div>
                                    </div>
                                    <div className='copy-button'>
                                      <button type='button' className='btn primary' onClick={onCopyLinkClick}>Copy</button>
                                    </div>
                                  </div>
                                </> : null
                            }

                          </div>
                        </div>
                      </div>
                  }
                  {
                    !isRetrievingFeedData && isFeedDataInvalid ?
                      <div className='share-no-post-section'>
                        <p className='nodata'>{FEED_POST_RESTRICTIONS.POST_DOES_NOT_EXIST}</p>
                      </div> : null
                  }
                  <div className='share-bg'>
                    {
                      !isRetrievingFeedData && !isFeedDataInvalid && feedItem ?
                        <div className='share-button'>
                          <button type='button' className='btn primary' onClick={e => { formProps.handleSubmit(); }}>Share</button>
                        </div> : null
                    }
                  </div>
                </div>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  );
};

import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../../../../models';
import { MagicNumber, PAGE_ROUTES } from '../../../../../../constants';
import { PENDING_ICON } from '../../../../../../constants/image';
import { errorToast } from '../../../../Atoms';
import { SoldItem } from './SoldItem';
import { useHistory } from 'react-router';
import { ParameterType } from '../../../orderDetail/OrderDetail';
import { GridList } from '../../../../Molecules/gridList/GridList';

export const SoldItems = observer(() => {
  const rootStore = useStores();
  const history = useHistory();

  const { inProcess, getMoreSoldItems } = rootStore.soldItemStore;
  const [soldItemsList, setSoldItemsList] = useState<any[]>([]);

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(MagicNumber.ONE);
  const [totalPages, setTotalPages] = useState<number>(MagicNumber.ONE);
  const [totalItems, setTotalItems] = useState<number>(MagicNumber.ZERO);
  const [pageLimit] = useState<number>(MagicNumber.EIGHT);

  useEffect(() => {
    retrieveSoldItems();
  }, []);

  const retrieveSoldItems = () => {
    if (currentPageNumber > totalPages)
      return;

    getMoreSoldItems(currentPageNumber, pageLimit)
      .then(response => {
        const { results, page_total, total } = response;

        const updatedList = [...soldItemsList, ...results];

        setSoldItemsList(updatedList);
        setCurrentPageNumber(currentPageNumber + MagicNumber.ONE);
        setTotalPages(page_total);
        setTotalItems(total ? parseInt(total) : updatedList.length);
      }).catch(error => {
        errorToast(error);
      });
  };

  const onPendingItemClicked = (orderId: number, itemId: number, collectionId: number) => {
    let query = `${ParameterType[ParameterType.orderId]}=${orderId ?? MagicNumber.ZERO}&${ParameterType[ParameterType.itemId]}=${itemId ?? MagicNumber.ZERO}`;
    query = collectionId ? `${query}&${ParameterType[ParameterType.collectionId]}=${collectionId}` : query;
    history.push(`${PAGE_ROUTES.ORDER_DETAIL}?${query}`);
  };

  const getProductImage = (item: any) => {
    if (item.product_image)
      return item.product_image;

    if (item.medias && item.medias.length) {
      const media = item.medias[MagicNumber.ZERO];
      return media.type === 'image' ? media.filepath : media.filepathThumb;
    }

    return '';
  };

  const GetItemUI = (item: any, index: number) => {
    return (
      <SoldItem
        src={getProductImage(item)}
        key={`pro_${index}`}
        onClickCB={() => { onPendingItemClicked(item.order_id, item.id, item.collection_product_id); }}
      />
    );
  };

  const NoItems = () => {
    return (
      <>
        <img src={PENDING_ICON} className='sold-image' alt='pending purchase' />
        <p>You have no sold items.</p>
        <a className='btn primary' href='/'>Browse</a>
      </>
    );
  };

  return (
    <GridList
      isLoading={inProcess}
      totalItems={totalItems}
      NoItemsComponent={<NoItems />}
      getGridItemComponent={GetItemUI}
      items={soldItemsList}
      onScrollEnd={retrieveSoldItems}
    />
  );
});



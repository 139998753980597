import React, { useEffect, useState, FC } from 'react';
import { getPlatformInfo, LOCK_WHITE_ICON, MagicNumber } from '../../../../constants';
import './_NewHome.scss';

type IProps = {
    data: any;
    status?: boolean;
    handleNotifyMe?: () => void;
    executeScroll?: () => void;
};

const DropTime = ({ data, status, handleNotifyMe, executeScroll }: IProps) => {

    const [isStartDrop, setStartDrop] = useState<boolean>(false);
    const [timerDays, setDays] = useState<string>();
    const [timerHours, setHours] = useState<string>();
    const [timerMinutes, setMinutes] = useState<string>();

    const splitDate = (date: any) => {
        if (date) {
            const first = date / MagicNumber.TEN;
            const second = date % MagicNumber.TEN;
            return `${Math.floor(first)},${second}`;
        } else {
            return `${Math.floor(0)},${0}`;
        }
    };

    const handleDropDate = (startOrEndDates: any) => {
        let diffTime = new Date(startOrEndDates).valueOf() - new Date().valueOf();
        let days = diffTime / (MagicNumber.TWENTYFR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONE_TH);
        let hours = (days % MagicNumber.ONE) * MagicNumber.TWENTYFR;
        let minutes = (hours % MagicNumber.ONE) * MagicNumber.SIXTY;
        let secs = (minutes % MagicNumber.ONE) * MagicNumber.SIXTY;
        [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
        let day_;
        let hour_;
        let minute_;
        if (days > MagicNumber.ZERO) {
            day_ = Math.abs(days) > MagicNumber.ONE ? `${Math.abs(days)}days` : `${Math.abs(days)}day`;
        }
        if (hours > MagicNumber.ZERO) {
            hour_ = Math.abs(hours) > MagicNumber.ONE ? `${Math.abs(hours)}hrs` : `${Math.abs(hours)}hr`;
        }
        if (minutes > MagicNumber.ZERO) {
            minute_ = Math.abs(minutes) > MagicNumber.ONE ? `${Math.abs(minutes)}mins` : `${Math.abs(minutes)}min`;
        }
        if (minutes === MagicNumber.ZERO && secs > MagicNumber.ZERO) {
            minute_ = `1min`;
        }
    };

    const dateCountDownTimer = (data: any, status: boolean) => {
        var upgradeTime: string;
        let flag = MagicNumber.ZERO;
        if (status) {
            upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
        } else {
            upgradeTime = data && data.sale_start_date ? data.sale_start_date : new Date();
        }
        const timer = () => {
            let diffTime = new Date(upgradeTime).valueOf() - new Date().valueOf();
            let days = diffTime / (MagicNumber.TWENTYFR * MagicNumber.SIXTY * MagicNumber.SIXTY * MagicNumber.ONE_TH);
            let hours = (days % MagicNumber.ONE) * MagicNumber.TWENTYFR;
            let minutes = (hours % MagicNumber.ONE) * MagicNumber.SIXTY;
            let secs = (minutes % MagicNumber.ONE) * MagicNumber.SIXTY;
            [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)];
            let day_: any = 0;
            let hour_: any = 0;
            let minute_: any = 0;
            if (days > MagicNumber.ZERO) {
                day_ = Math.abs(days) > MagicNumber.NINE ? Math.abs(days) : `0${Math.abs(days)}`;
            }
            if (hours > MagicNumber.ZERO) {
                hour_ = Math.abs(hours) > MagicNumber.NINE ? Math.abs(hours) : `0${Math.abs(hours)}`;
            }
            if (minutes > MagicNumber.ZERO) {
                minute_ = Math.abs(minutes) > MagicNumber.NINE ? Math.abs(minutes) : `0${Math.abs(minutes)}`;
            }
            if (minutes === MagicNumber.ZERO && secs > MagicNumber.ZERO) {
                minute_ = `01`;
            }
            setDays(day_ ? (day_).toString() : '00');
            setHours(hour_ ? (hour_).toString() : '00');
            setMinutes(minute_ ? (minute_).toString() : '00');
            if (Math.sign(days) === -MagicNumber.ONE) {
                clearInterval(countdownTimer);
            }
        };
        var countdownTimer = setInterval(() => {
            let startOrEndDatess = data && data.sale_start_date ? data.sale_start_date : new Date();
            const saleStartDate = new Date(startOrEndDatess).getTime();
            const todatedate = new Date().getTime();
            if (saleStartDate > todatedate) {
                upgradeTime = startOrEndDatess;
                setStartDrop(false);
            } else {
                upgradeTime = data && data.sale_end_date ? data.sale_end_date : new Date();
                setStartDrop(true);
            }
            timer();
            flag = flag + MagicNumber.ONE;
            handleDropDate(upgradeTime);
        }, MagicNumber.ONE_TH);
    };

    const checkAvailabitity = async (data: any) => {
        let startOrEndDates = data && data.sale_start_date ? data.sale_start_date : new Date();
        const saleStartDate = new Date(startOrEndDates).getTime();
        const todatedate = new Date().getTime();
        let status = false;
        if (saleStartDate > todatedate) {
            setStartDrop(false);
            status = false;
        } else {
            startOrEndDates = data && data.sale_end_date ? data.sale_end_date : new Date();
            setStartDrop(true);
            status = true;
        }
        handleDropDate(startOrEndDates);
        dateCountDownTimer(data, status);
    };

    useEffect(() => {
        checkAvailabitity(data);
    }, [data]);

    return (
        <div className='drop-start-container'>
            {status ? <h2 className='title-drop'>Drop {isStartDrop ? 'ends' : 'Starts'}</h2> :
                <h2 className='title-drop'>Drop ends</h2>}
            <div className='counter-section'>
                <div className='count-box'>
                    <div className='box-left'>
                        <div className='box-contain'>
                            <div className='active-box'>
                                <div className='calendar-detail'>
                                    <p>Days</p>
                                </div>
                                <div className='count-nowrap'>
                                    <div
                                        className={splitDate(timerDays).split(',')[0] !== '0' ? 'number-box' : 'number-box inactive-number'}
                                    >{splitDate(timerDays).split(',')[0]}</div>
                                    <div className={splitDate(timerDays).split(',')[0] !== '0' || (splitDate(timerDays).split(',')[1] !== '0') ? 'number-box' : 'number-box inactive-number'}
                                    >{splitDate(timerDays).split(',')[1]}</div>
                                    <div className='calendar-detail'>
                                        <p>Days</p>
                                    </div>
                                </div>
                                <div className='divider-box'></div>
                            </div>
                            <div className='active-box'>
                                <div className='calendar-detail'>
                                    <p>Hours</p>
                                </div>
                                <div className='count-nowrap'>
                                    <div className={splitDate(timerHours).split(',')[0] !== '0' ? 'number-box' : 'number-box inactive-number'}
                                    >{splitDate(timerHours).split(',')[0]}</div>
                                    <div className={(splitDate(timerHours).split(',')[0] !== '0' || splitDate(timerHours).split(',')[1] !== '0') ? 'number-box' : 'number-box inactive-number'}
                                    >
                                        {splitDate(timerHours).split(',')[1]}</div>
                                    <div className='calendar-detail'>
                                        <p>Hours</p>
                                    </div>
                                </div>
                                <div className='divider-box'></div>
                            </div>
                            <div className='active-box'>
                                <div className='calendar-detail'>
                                    <p>Minutes</p>
                                </div>
                                <div className='count-nowrap'>
                                    <div className={splitDate(timerMinutes).split(',')[0] !== '0' ? 'number-box' : 'number-box inactive-number'}>
                                        {splitDate(timerMinutes).split(',')[0]}</div>
                                    <div className={(splitDate(timerMinutes).split(',')[0] !== '0' || splitDate(timerMinutes).split(',')[1] !== '0') ? 'number-box' : 'number-box inactive-number'}>
                                        {splitDate(timerMinutes).split(',')[1]}</div>
                                    <div className='calendar-detail'>
                                        <p>Minutes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='box-right'>
                        <div className='divider'></div>
                        {(getPlatformInfo() === 'desktop')? <>
                        <div className='button-section'>
                            <button type='button'
                                className='btn primary'
                                onClick={executeScroll}
                            >View Items
                                {!isStartDrop ? <img src={LOCK_WHITE_ICON} className='lock-image' alt='lock' /> : ''}
                            </button>
                            {!isStartDrop ? <button type='button'
                                className='btn secondary'
                                onClick={handleNotifyMe}
                            >Notify Me</button> : ''}
                        </div> </>:''}       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropTime;
